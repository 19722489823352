import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService } from "../../../App/Services/AppService";
import Types from '../../../App/AppTypes';
import { LearningPathwayPagerVM } from '../../Components/LearningPathwayComponents/LearningPathwayPagerVM';

@NeoModel
export default class LearningPathwayVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  /// Properties
  public learningPagerVm = new LearningPathwayPagerVM(this.taskRunner)


  public pathwayId: number | null = null;
  ///methods
  public async initialise() {
  }
}