import { NeoModel, Validation } from '@singularsystems/neo-core';
import UserProfileBase from '../Base/UserProfileBase';

@NeoModel

export default class UserProfileLookup extends UserProfileBase {

    public organisationLevelName: string = "Unknown";

    public fullName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New user profile lookup";
        } else {
            return "User Profile Lookup";
        }
    }
}