import { Attributes, List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import CircuitUsers from './CircuitUsers';

@NeoModel
export default class DistrictUsers extends ModelBase {

    public districtId: number = 0;

    @Rules.Required()
    public provinceId: number = 0;

    @Rules.Required()
    @Rules.StringLength(50)
    public districtName: string = "";

    @Rules.Required()
    @Rules.StringLength(500)
    public districtDetails: string = "";

    public circuits = new List(CircuitUsers);

    public isExpanded: boolean = false

    //Client Only
    public provinceName: string = "";

    public organisationId: number = 0;

    @Attributes.NoTracking()
    public moveToProvinceId: number = 0;
    
    @Attributes.NoTracking()
    public usersCount: number = 0;

    @Attributes.NoTracking()
    public usersCountIncldChildren: number = 0;
}