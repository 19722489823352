export enum QuestionType {
  /// <summary>
  /// NotSet
  /// </summary>
  NotSet = 1,

  /// <summary>
  /// MultipleChoice
  /// </summary>
  MultipleChoice = 2,

  /// <summary>
  /// TrueFalse
  /// </summary>
  TrueFalse = 3,

  /// <summary>
  /// YesNo
  /// </summary>
  YesNo = 4,

  /// <summary>
  /// MultipleSelect
  /// </summary>
  MultipleSelect = 5,

  /// <summary>
  /// LikertScale
  /// </summary>
  LikertScale = 6,

  /// <summary>
  /// Ruberic
  /// </summary>
  // Ruberic = 7,
}