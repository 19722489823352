import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { LearningObjectType } from '../LearningObjects/LearningObjectType.enum';

@NeoModel
export default class ModerationObjectLookup extends ModelBase {

  public objectId: number = 0;

  public title: string = "";
  public titleApproved: boolean = false;

  public purpose: string = "";

  public purposeApproved: boolean = false;

  public richText: string = "";

  public richTextApproved: boolean = false;
  public errorMessage: string = "";

  public objectIsCompleted: boolean = false;

  public objectTypeId: LearningObjectType = LearningObjectType.Information;

  public objectTags: List<ModerationObjectTagsSummary> = new List(ModerationObjectTagsSummary);

  public order: number = 0;

  public allObjectTagsApproved: boolean = true;
  public allContentApproved: boolean = true;
  public allQuestionsApproved: boolean = true;

  public contentList: List<ObjectContentSummary> = new List(ObjectContentSummary);
  public approvedQuizContent = false;

  public quizContent = new ObjectContentSummary();
  public questionList: List<ObjectQuestionSummary> = new List(ObjectQuestionSummary);

  public objectNotes: string = "";
}

@NeoModel
export class ModerationObjectTagsSummary extends ModelBase {

  public learningObjectTag3Id: number = 0;

  public tag3Id: number = 0;

  public tag3Name: string = "";

  public approved: boolean = false;

  public errorMessage: string = "";
}

@NeoModel
export class ObjectContentSummary extends ModelBase {

  public contentId: number = 0;

  public contentName: string = "";

  public approved: boolean = false;

  public contentNotes: string = "";

}

@NeoModel
export class ObjectQuestionSummary extends ModelBase {

  public questionId: number = 0;

  public question: string = "";

  public approved: boolean = false;

  public questionNotes: string = "";

  public hasContent: boolean = false;

  public questionContent: ObjectContentSummary = new ObjectContentSummary();

  public questionContentApproved: boolean = false;
}