import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import SchoolManagementTeam from '../../Models/Organisations/SchoolManagementTeam';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'

export interface ISchoolManagementTeamApiClient extends Data.IUpdateableApiClient<SchoolManagementTeam, number> {

    // Client only properties / methods
}

@injectable()
export default class SchoolManagementTeamApiClient extends Data.UpdateableApiClient<SchoolManagementTeam, number> implements ISchoolManagementTeamApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/SchoolManagementTeam`);
    }

    // Client only properties / methods
}