import { AppServices } from '@singularsystems/neo-core';
import { Routing as NeoRouting } from '@singularsystems/neo-core';
import Types from './AuthorisationTypes';
import * as Roles from './Models/Security/Roles';
import * as ModerationRoles from './Models/Enums/ModerationRoles.enum';
import * as DomainRoles from '../App/Models/Enums/DomainRoles.enum';
import * as UserMaintenanceRoles from '../Authorisation/Models/Enums/UserMaintenanceRoles.enum';
import UserGroupsView from './Views/UserGroupsView';
import AuthorisationDataCache from './Services/AuthorisationDataCache';
import UserGroupsVM from './Views/UserGroupsVM';
import { UserRolesHub } from './Services/ApiClients/UserRolesHub';
import AuthorisationApiClient from './Services/ApiClients/AuthorisationApiClient';
import AssignedRolesApiClient from './Services/ApiClients/AssignedRolesApiClient';
import MembershipsApiClient from './Services/ApiClients/MembershipsApiClient';
import ResourcesApiClient from './Services/ApiClients/ResourcesApiClient';
import UserGroupsApiClient from './Services/ApiClients/UserGroupsApiClient';
import UsersApiClient from './Services/ApiClients/UsersApiClient';
import UsersView from '../Identity/Views/UserMaintenanceView';
import { UserTypes } from '../App/Models/Enums/UserTypes.enums';
import UserModerationMaintenanceView from '../Authorisation/Views/UserModerationMaintenanceView';
import UserRoleApiClient from './Services/ApiClients/UserRoleApiClient';
import ReportedPostsView from '../Forum/Views/ReportedPostsView';
import UserGroupQueryApiClient from './Services/ApiClients/UserGroupQueryApiClient';
import BadgeView from '../Learning/Views/Badges/BadgeView';
import * as  BadgeRoles from '../Learning/Models/Security/BadgeRoles';
import * as  ReportedPostRoles  from '../Forum/Models/Security/ForumsRoles';
import SystemSettingsView from '../Identity/Views/Settings/SystemSettingsView';
import ImportUserOrganisationView from '../Identity/Views/Import/ImportUserOrganisationView';
import { ImportHierarchyRoles } from '../Organisations/Models/Security/OrganisationRoles';

export interface IAppRoute extends NeoRouting.IMenuRoute {
    userType?: UserTypes 
    children?: IAppRoute[]
    denyRole?: string
   }

// Modules
const authorisationAppModule = new AppServices.Module("Authorisation", container => {
    // Api Clients
    container.bind(Types.ApiClients.AuthorisationApiClient).to(AuthorisationApiClient).inSingletonScope();
    container.bind(Types.ApiClients.AssignedRolesApiClient).to(AssignedRolesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.MembershipsApiClient).to(MembershipsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ResourcesApiClient).to(ResourcesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.UserGroupsApiClient).to(UserGroupsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.UsersApiClient).to(UsersApiClient).inSingletonScope();
    container.bind(Types.ApiClients.UserRolesHub).to(UserRolesHub).inSingletonScope();
    container.bind(Types.ApiClients.UserRoleApiClient).to(UserRoleApiClient).inSingletonScope();
    container.bind(Types.ApiClients.UserGroupQueryApiClient).to(UserGroupQueryApiClient).inSingletonScope();

    // Services
    container.bind(Types.Services.AuthorisationDataCache).to(AuthorisationDataCache).inSingletonScope();

    // View Models
    container.bind(Types.ViewModels.UserGroupsViewModel).to(UserGroupsVM).inTransientScope();
});

const authorisationTestModule = new AppServices.Module("Authorisation", container => {
    // bind test types
});

// Routes
const SecurityRoute: IAppRoute =
{
    name: "User Access", path: "/security/useraccess", component: UserGroupsView, icon: "fa-user-shield", userType: UserTypes.Admin,  role: Roles.UserAccess.Access
};
const UserMantenance: IAppRoute =
{
    name: "User Maintenance", path: "/security/usermaintenance", component: UsersView, icon: "users", userType: UserTypes.Admin, role: UserMaintenanceRoles.UserMaintenance.Access
};
const UserModeration: IAppRoute =
{
    name: "Moderation Admin", path: "/security/moderation", component: UserModerationMaintenanceView, icon: "band-aid", userType: UserTypes.Admin, role: ModerationRoles.Moderation.ModeratorAdmin
};
const ReportedPosts: IAppRoute = {
    name: "Reported Posts", path: "/posts/reportedposts", component: ReportedPostsView, icon: "flag", userType: UserTypes.Admin, role: ReportedPostRoles.ReportedPost.Admin
};
const Badges: IAppRoute = {
    name: "Badges", path: "/Learning/Badges", component: BadgeView, icon: "certificate", allowAnonymous: false, userType: UserTypes.Admin, role: BadgeRoles.Badge.Admin
};
const SystemSettings: IAppRoute = {
    name: "Settings", path: "/System/Settings", component: SystemSettingsView, icon: "cogs", allowAnonymous: false, userType: UserTypes.Admin, role: DomainRoles.SystemPermission.SystemSettings
};

const ImportUserOrg: IAppRoute = {
    name: "User Org", path: "/System/Import", component: ImportUserOrganisationView, icon: "female", allowAnonymous: false, userType: UserTypes.Admin, role: ImportHierarchyRoles.UserOrg
};

const AuthorisationMaintenance = [
    SecurityRoute,
    UserMantenance,
    UserModeration,
    ReportedPosts,
    Badges,
    SystemSettings,
    ImportUserOrg
];

export {
    authorisationAppModule,
    authorisationTestModule,
    SecurityRoute,
    UserMantenance,
    AuthorisationMaintenance,
    UserModeration,
    SystemSettings,
    ImportUserOrg
}
