import { NeoModel, Rules, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class UpdateNotificationSettingsCommand extends ValueObject {

    @Rules.StringLength(150)
    public systemEmailAddress: string = "";

    @Rules.StringLength(20)
    public systemWhatsAppNumber: string = "";

    public allowEmailVerification: boolean = false;

    public allowSmsVerification: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Update Notification Settings Command";
    }
}