import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import SelfAssessmentLookup from '../../Models/Lookups/SelfAssessmentLookup';
import SelfAssessmentSearchCriteria from '../../Models/Criteria/SelfAssessmentSearchCriteria';
import SelfAssessmentOptions from '../../Models/Questions/Assessment/SelfAssessmentOptions';
import SelfAssessmentTagLookup from '../../Models/Lookups/SelfAssessmentTagLookup';
import ScalesLookup from '../../Models/Lookups/ScalesLookup';
import SelfAssessmentQuestionTagLookup from '../../Models/Lookups/SelfAssessmentQuestionTagLookup';
import SelfAssessmentQuestionDragableModel from '../../Models/Lookups/SelfAssessmentQuestionDragableModel';
import SelfAssessmentQuestionLookup from '../../Models/Lookups/SelfAssessmentQuestionLookup';
import { QuestionType } from '../../Views/Assessment/SelfAssessmentQuestionComponent';
import QuestionQuestionTypeLookup from '../../Models/Lookups/QuestionQuestionTypeLookup';
import SelfAssessmentModerationLookup from '../../Models/Lookups/SelfAssessmentModerationLookup';
import SelfAssessmentQuestionModerationLookup from '../../Models/Lookups/SelfAssessmentQuestionModerationLookup';
import AssessmentQuestionModerateableTagLookup from '../../Models/Lookups/AssessmentQuestionModerateableTagLookup';

export interface ISelfAssessmentApiClient {
    /**
     * Save Self Assessment
     * @param selfAssessment self Assessment
     * @param userId User id
     * @returns Self Assessment Object
     */
    SaveSelfAssessment(selfAssessment: Model.PartialPlainObject<SelfAssessmentLookup>): AxiosPromise<Model.PlainTrackedObject<SelfAssessmentLookup>>;

    /**
   * update Self Assessment
   * @param selfAssessWithTagList self Assessment
   * @param userId User id
   * @returns Self Assessment Object
   */
    updateSelfAssessment(selfAssessWithTagList: Model.PartialPlainObject<SelfAssessmentLookup>): AxiosPromise<Model.PlainTrackedObject<SelfAssessmentLookup>>;

    /**
   * delete Self AssessmentTag2
   * @param selfAssessementId self Assessment
   * @param tag2Id User id
   * @returns Self Assessment Object
   */
    deleteSelfAssessmentTag2(selfAssessementId: number, tag2Id: number): AxiosPromise<boolean>;

    /**
  * getPagedSelfAssessmentLookup
  * @param request User id
  * @returns Self Assessment List
  */
    getPagedSelfAssessmentLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SelfAssessmentSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SelfAssessmentLookup>>>;

    /**
   * delete Self Assessment
   * @param selfAssessementId self Assessment
   * @returns  true or false
   */
    deleteSelfAssessment(selfAssessmentId: number): AxiosPromise<boolean>;

    /**
   * get Self Assessment
   * @param selfAssessementId self Assessment
   * @returns  true or false
   */
    getSelfAssessment(selfAssessmentId: number): AxiosPromise<Model.PartialPlainObject<SelfAssessmentLookup>>;

    /**
    * get moderateable Self Assessment
    * @param selfAssessementId self Assessment
    * @returns  SelfAssessmentModerationLookup
    */
    getModerateableSelfAssessment(selfAssessmentId: number): AxiosPromise<Model.PartialPlainObject<SelfAssessmentModerationLookup>>

    /**
     * Return scales for likert Question
     */
    getScales(): AxiosPromise<Array<Model.PlainObject<ScalesLookup>>>;

    /**
   * delete Self Assessment Question
   * @param selfAssessmentQuestionId self Assessment Question
   * @returns  true or false
   */
    DeleteQuestion(selfAssessmentQuestionId: number): AxiosPromise<boolean>;

    /**
  * Gets a list of self assessment questions to be used in the drag and drop
  * @param selfAssessmentId The page request
  * @returns List of linked Tag2s and Tag1s
  */
    getSelfAssessmentQuestionDNDObjects(selfAssessmentId: number): AxiosPromise<Array<SelfAssessmentQuestionDragableModel>>;

    /**
 * Gets a list of self assessment for self assessment
 * @param selfAssessmentId The page request
 * @returns List of questions 
 */
    getSelfAssessmentQuestionLookupList(selfAssessmentId: number): AxiosPromise<Array<Model.PlainTrackedObject<SelfAssessmentQuestionLookup>>>;

    /**
    * Gets a list of self assessment for self assessment
    * @param selfAssessmentId The page request
    * @returns List of questions 
    */
    GetSelfAssessmentModerationQuestionLookupList(selfAssessmentId: number): AxiosPromise<Array<Model.PlainTrackedObject<SelfAssessmentQuestionModerationLookup>>>

    /**
  * update Self Assessment Question Order
  * @param selfAssessmentId The page request
  * @returns true or false
  */
    UpdateSelfAssessmentQuestionOrder(selfAssessmentQuestionId: number, questionOrder: number): AxiosPromise<boolean>;

    /**
    * update Self Assessment Tags
    * @param selfAssessmentId The page request
    * @returns true or false
    */
    getSelfAssessmentTagList(selfAssessmentId: number): AxiosPromise<Array<Model.PlainObject<SelfAssessmentTagLookup>>>;

    /**
     * Save Self Assessment Question
     * @param selfAssessmentId selfAssessment Id
     * @param useId user Id
     * @returns true or false
     */
    SaveSelfAssessmentQuestion(selfAssessment: Model.PartialPlainObject<SelfAssessmentQuestionLookup>): AxiosPromise<Model.PlainTrackedObject<SelfAssessmentQuestionLookup>>;

    /**
    * Save Self Assessment Question
    * @param selfAssessmentId selfAssessment Id
    * @param useId user Id
    * @returns true or false
    */
    getSelfAssessmentOptionsMulti(questionId: number): AxiosPromise<Array<Model.PartialPlainObject<SelfAssessmentOptions>>>

    /**
     * check Question Question Type
     * @param selfAssessmentId selfAssessment Id
     * @param question TypeId question Type
     * @returns true or false
     */
    checkQuestionQuestionType(selfAssessmentId: number, questionTypeId: QuestionType): AxiosPromise<QuestionQuestionTypeLookup>;

    /**
    * Submit assessment  for moderation
    * @param selfAssessmentId selfAssessment Id
    * @param userGuid TypeId question Type
    * @returns true or false
    */
    submitAssessmentForModeration(assessmentId: number): AxiosPromise<boolean>;

    /**
    * Get Assessment Question Tag 3
    * @param selfAssessmentId selfAssessment Id
    * @returns AssessmentQuestionModerateableTagLookup
    */
    getSelfAssessmentQuestionModerationTagLookup(assessmentId: number): AxiosPromise<AssessmentQuestionModerateableTagLookup>

    getSelfAssessmentQuestion(questionId: number): AxiosPromise<Model.PlainObject<SelfAssessmentQuestionLookup>>
}

@injectable()
export default class SelfAssessmentApiClient extends Data.ApiClientBase implements ISelfAssessmentApiClient {
    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/SelfAssessment`);
    }

    public SaveSelfAssessment(selfAssessment: Model.PartialPlainObject<SelfAssessmentLookup>): AxiosPromise<Model.PlainTrackedObject<SelfAssessmentLookup>> {
        return this.axios.post(`${this.apiPath}/CreateSelfAssessment/`, selfAssessment);
    }

    public updateSelfAssessment(selfAssessWithTagList: Model.PartialPlainObject<SelfAssessmentLookup>): AxiosPromise<Model.PlainTrackedObject<SelfAssessmentLookup>> {
        return this.axios.put(`${this.apiPath}/UpdateSelfAssessment/`, selfAssessWithTagList);
    }

    public SaveSelfAssessmentQuestion(selfAssessment: Model.PartialPlainObject<SelfAssessmentQuestionLookup>): AxiosPromise<Model.PlainTrackedObject<SelfAssessmentQuestionLookup>> {
        return this.axios.post(`${this.apiPath}/SaveSelfAssessmentQuestion`, selfAssessment);
    }

    public deleteSelfAssessmentTag2(selfAssessementId: number, tag2Id: number): AxiosPromise<boolean> {

        return this.axios.get(`${this.apiPath}/DeleteSelfAssessementT2/${selfAssessementId}/${tag2Id}`);
    }

    public getPagedSelfAssessmentLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SelfAssessmentSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SelfAssessmentLookup>>> {
        return this.axios.get(`${this.apiPath}/SelfAssessmentsPaged?${Utils.getQueryString(request)}`);
    }

    public deleteSelfAssessment(selfAssessmentId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/DeleteSelfAssessment/${selfAssessmentId}`);
    }

    public getSelfAssessment(selfAssessmentId: number): AxiosPromise<Model.PartialPlainObject<SelfAssessmentLookup>> {
        return this.axios.get(`${this.apiPath}/GetSelfAssessment/${selfAssessmentId}`);
    }

    public getModerateableSelfAssessment(selfAssessmentId: number): AxiosPromise<Model.PartialPlainObject<SelfAssessmentModerationLookup>> {
        return this.axios.get(`${this.apiPath}/GetModerateableSelfAssessment/${selfAssessmentId}`);
    }

    public getSelfAssessmentOptions(sentimentId: number, numberOfScales: number): AxiosPromise<Array<Model.PartialPlainObject<SelfAssessmentOptions>>> {
        return this.axios.get(`${this.apiPath}/GetSelfAssessmentOptions/${sentimentId}/${numberOfScales}`);
    }

    public getSelfAssessmentOptionsMulti(questionId: number): AxiosPromise<Array<Model.PartialPlainObject<SelfAssessmentOptions>>> {
        return this.axios.get(`${this.apiPath}/GetSelfAssessmentOptionsMulti/${questionId}`);
    }

    public getSelfAssessmentQuestionTagSelectList(selfAssessmentId: number): AxiosPromise<Array<Model.PlainObject<SelfAssessmentQuestionTagLookup>>> {
        return this.axios.get(`${this.apiPath}/GetSelfAssessmentQuestionFilteredTagList/${selfAssessmentId}`);
    }

    public getScales(): AxiosPromise<Array<Model.PlainObject<ScalesLookup>>> {
        return this.axios.get(`${this.apiPath}/GetScales`);
    }

    public DeleteQuestion(selfAssessmentQuestionId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/DeleteQuestion/${selfAssessmentQuestionId}`);
    }
    public getSelfAssessmentQuestionDNDObjects(selfAssessmentId: number): AxiosPromise<Array<SelfAssessmentQuestionDragableModel>> {
        return this.axios.get(`${this.apiPath}/GetSelfAssessmentQuestionDNDObjects/${selfAssessmentId}`);
    }

    public getSelfAssessmentQuestionLookupList(selfAssessmentId: number): AxiosPromise<Array<Model.PlainTrackedObject<SelfAssessmentQuestionLookup>>> {
        return this.axios.get(`${this.apiPath}/GetSelfAssessmentQuestionLookupList/${selfAssessmentId}`);
    }

    public GetSelfAssessmentModerationQuestionLookupList(selfAssessmentId: number): AxiosPromise<Array<Model.PlainTrackedObject<SelfAssessmentQuestionModerationLookup>>> {
        return this.axios.get(`${this.apiPath}/GetSelfAssessmentModerationQuestionLookupList/${selfAssessmentId}`);
    }

    public UpdateSelfAssessmentQuestionOrder(selfAssessmentQuestionId: number, questionOrder: number): AxiosPromise<boolean> {
        return this.axios.post(`${this.apiPath}/UpdateSelfAssessmentQuestionOrder/${selfAssessmentQuestionId}/${questionOrder}`);
    }

    public getSelfAssessmentTagList(selfAssessmentId: number): AxiosPromise<Array<Model.PlainObject<SelfAssessmentTagLookup>>> {
        return this.axios.get(`${this.apiPath}/getSelfAssessmentTagList/${selfAssessmentId}`);
    }

    public checkQuestionQuestionType(selfAssessmentId: number, questionTypeId: QuestionType): AxiosPromise<QuestionQuestionTypeLookup> {
        return this.axios.get(`${this.apiPath}/checkQuestionQuestionType/${selfAssessmentId}/${questionTypeId}`);
    }

    public submitAssessmentForModeration(assessmentId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/SubmitAssessmentForModeration/${assessmentId}`);
    }

    public getSelfAssessmentQuestionModerationTagLookup(assessmentId: number): AxiosPromise<AssessmentQuestionModerateableTagLookup> {
        return this.axios.get(`${this.apiPath}/GetSelfAssessmentQuestionModerationTagLookup/${assessmentId}`);
    }

    public resubmitAssessmentForModeration(selfAssessementId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/ResubmitSelfAssessmentForModeration/${selfAssessementId}`);
    }

    public getSelfAssessmentQuestion(questionId: number): AxiosPromise<Model.PlainObject<SelfAssessmentQuestionLookup>> {
        return this.axios.get(`${this.apiPath}/SelfAssessmentQuestion/${questionId}`);
    }
}