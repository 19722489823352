import { Data, NeoModel } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { AppService, Types } from "../../App/Services/AppService";
import LearningModuleSearchCriteria from "../Models/SearchCriteria/LearningModuleSearchCriteria";
import LearningModuleLookup from "../../Learning/Models/Lookups/LearningModuleLookup";
import { UserLearningStatus } from "../../Learning/Models/UserLearning/UsersLearningStatus.enum";
import Card from "../../Template/components/Card/Card";
import CardBody from "../../Template/components/Card/CardBody";
import CardHeader from "../../Template/components/Card/CardHeader";
import GridContainer from "../../Template/components/Grid/GridContainer";
import GridItem from "../../Template/components/Grid/GridItem";
import TDPUser from "../../Identity/Models/Security/TDPUser";
import { UserTypes } from "../../App/Models/Enums/UserTypes.enums";

interface IUserModulePagerProps {
  GoToModule: (moduleId: number, preview: number) => void;
}
@NeoModel
export class UserModulePagerVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private dashboardApiClient = AppService.get(Types.Dashboard.ApiClients.DashboardQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
    this.userActivityPageManager.refreshData();
    this.initialise();
  }


  /// Learning Module Pager
  public UserActivityCriteria = new LearningModuleSearchCriteria();
  public userActivityPageManager = new Data.PageManager(this.UserActivityCriteria, LearningModuleLookup, this.dashboardApiClient.getUserActivity, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "learningModuleTitle",
    sortAscending: true,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });
}

@observer
export default class UserModulePager extends React.Component<IUserModulePagerProps> {
  private authService = AppService.get(Types.Neo.Security.AuthenticationService);
  constructor(props: IUserModulePagerProps) {
    super(props)
    this.viewModel = new UserModulePagerVM();
  }
  private viewModel: UserModulePagerVM;

  public render() {
    const user = this.authService.user as TDPUser;
    return (
      <div>
        <Card>
          <CardHeader color="success" stats >
            <GridContainer>
              <GridItem md={6}>
                <h4 className="card-title-white">My Modules</h4>
                <p className="card-category-white">
                  Your recent activity.
                </p>
              </GridItem>
              <GridItem md={6}>
                <div style={{ float: "right" }}>
                  <Neo.FormGroup prepend={<label className="mr-1">Show Completed Modules</label>} className="completed-checkbox" bind={this.viewModel.UserActivityCriteria.meta.completed} suppressLabel onChange={() => this.viewModel.userActivityPageManager.refreshData()} />
                  <Neo.FormGroup prepend={<label className="recent-label">Show Recent Modules</label>} bind={this.viewModel.UserActivityCriteria.meta.recent} suppressLabel onChange={() => this.viewModel.userActivityPageManager.refreshData()} />
                </div>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Neo.Pager
              pageManager={this.viewModel.userActivityPageManager}
              pageControlProps={{
                firstText: "",
                prevText: "Prev",
                nextText: "Next",
                lastText: "",
                alignment: "center",
                autoHide: false,
                pageSizeLabel: "Show"
              }}>
              <NeoGrid.Grid<LearningModuleLookup>>
                {(learningModule: LearningModuleLookup, learningModuleMeta) => (
                  <NeoGrid.Row >
                    <NeoGrid.Column label="Title" display={learningModuleMeta.learningModuleTitle} />
                    <NeoGrid.Column label="Learning Objective" display={learningModuleMeta.description} />
                    <NeoGrid.Column label="Status" display={learningModuleMeta.status} select={{ itemSource: Data.StaticDataSource.fromEnum(UserLearningStatus), renderAsText: true }} />
                    <NeoGrid.ButtonColumn label="Actions" >
                      <Neo.Button variant="info" isOutline icon="eye" disabled={user.userType === UserTypes.Unverified.toString()} text="Continue" tooltip="Continue" onClick={() => { this.props.GoToModule(learningModule.learningModuleId, 0) }} />
                    </NeoGrid.ButtonColumn>
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </Neo.Pager>
          </CardBody>
        </Card>

      </div>
    )
  }
}