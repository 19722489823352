import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import OrgMaintenanceVM from './OrgMaintenanceVM';
import { observer } from 'mobx-react';
import CircuitComponent from '../../../Components/CircuitsComponent';
import DistrictComponent from '../../../Components/DistrictsComponent';
import SchoolComponent from '../../../Components/SchoolsComponent';
import ProvinceComponent from '../../../Components/ProvincesComponent';
import OrganisationComponent from '../../../Components/OrganisationsComponent';
import "../../../Styles/Hierarcy.scss";

export enum OrgMaintenanceTypes {
    organisations = "Organisations",
    circuits = "Circuits",
    districts = "Districts",
    schools = "Schools",
    provinces = "Provinces",
    schoolManagementTeams = "School Management Teams",
}

@observer
export default class OrgMaintenanceView extends Views.ViewBase<OrgMaintenanceVM> {

    constructor(props: unknown) {
        super("OrgMaintenance", OrgMaintenanceVM, props);
    }

    public render() {
        return (
            <div className="OrgMaintenance">
                <Neo.TabContainer selectedTab={this.viewModel.meta.selectedTab}>
                    <Neo.Tab header={OrgMaintenanceTypes.organisations} icon="fa-sitemap" onInitialise={() => this.viewModel.organisationVM.initialise()}>
                        <OrganisationComponent viewModal={this.viewModel.organisationVM} />
                    </Neo.Tab>
                    <Neo.Tab header={OrgMaintenanceTypes.provinces} icon="fa-map-marker" onDisplayed={() => this.viewModel.provinceVM.SetCriteria(this.viewModel.organisationVM.selectedOrganisationId)}>
                        <ProvinceComponent viewModal={this.viewModel.provinceVM} />
                    </Neo.Tab>
                    <Neo.Tab header={OrgMaintenanceTypes.districts} icon="fa-map-pin" onDisplayed={() => this.viewModel.districtVM.SetCriteria(this.viewModel.provinceVM.selectedProvinceId)}>
                        <DistrictComponent viewModal={this.viewModel.districtVM} />
                    </Neo.Tab>
                    <Neo.Tab header={OrgMaintenanceTypes.circuits} icon="fa-street-view" onDisplayed={() => this.viewModel.circuitVM.SetCriteria(this.viewModel.districtVM.filterDistrictId)}>
                        <CircuitComponent viewModal={this.viewModel.circuitVM} />
                    </Neo.Tab>
                    <Neo.Tab header={OrgMaintenanceTypes.schools} icon="fa-school" onDisplayed={() => this.viewModel.schoolVM.SetCriteria(this.viewModel.circuitVM.filterCircuitId)}>
                        <SchoolComponent viewModal={this.viewModel.schoolVM} />
                    </Neo.Tab>
                    {/* <Neo.Tab header={OrgMaintenanceTypes.schoolManagementTeams} icon="users" onInitialise={() => this.viewModel.schoolManagementTeamVM.initialise()}>
                        <SchoolManagementTeamComponent viewModal={this.viewModel.schoolManagementTeamVM} />
                    </Neo.Tab> */}
                </Neo.TabContainer>
            </div>
        );
    }
}