import { Data, List, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { Types } from '../../../Identity/IdentityTypes';
import { AppService } from '../../../Identity/CommonIdentityTypes';
import UserProfileLookup from "../../../Identity/Models/Users/Lookups/UserProfileLookup";
import BadgeLookup from "../../Models/Badges/BadgeLookup";
import BadgeSearchCriteria from "../../Models/Criteria/BadgeSearchCriteria";
import { BadgeType } from "../../Models/Badges/BadgeType.enum";
import { NotificationDuration } from "../../../App/Models/Enums/NotificationDuration.enum";
import LearningModuleSearchCriteria from "../../Models/Criteria/LearningModuleSearchCriteria";
import LearningModuleLookup from "../../Models/Lookups/LearningModuleLookup";
import Tag2 from "../../Models/Tags/Tag2";
import { LearningPathwayPagerVM } from "../../Components/LearningPathwayComponents/LearningPathwayPagerVM";
import BadgeModule from "../../Models/Badges/BadgeModule";
import LearningPathwaySearchCriteria from "../../Models/Criteria/LearningPathwaySearchCriteria";
import LearningPathwayPagerLookup from "../../Models/Lookups/LearningPathways/LearningPathwayPagerLookup";
import BadgeLearningPathway from "../../Models/Badges/BadgeLearningPathway";
import { BadgeAction } from "../../Models/Badges/BadgeAction.enum.";
import { BadgeSubAction } from "../../Models/Badges/BadgeSubAction.enum";

@NeoModel
export default class BagdeVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private badgeApiClient = AppService.get(Types.Learning.ApiClients.BadgeQueryApiClient),
        private learningPathwayApiClient = AppService.get(Types.Learning.ApiClients.LearningPathwayQueryApiClient),
        public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
        public tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient)) {
        super(taskRunner);
    }

    public currentUser = new UserProfileLookup();
    public badgeCriteria = new BadgeSearchCriteria();
    public selectedBadge = new BadgeLookup();
    public selectedBadgeId: number = 0;
    public showSearchCriteria: boolean = true;
    public hideBadgeModuleGrid: boolean = false;
    public editAddBadgeCard: boolean = true;
    public blobUrl: string = "";
    //Learning Module Stuff
    public learningModuleCriteria = new LearningModuleSearchCriteria();
    public learningModuleTag2FilteredList = new List(Tag2);
    public selectedLearningModuleList: number[] = [];
    public learningPagerVm = new LearningPathwayPagerVM(this.taskRunner)

    //Learning Pathway Stuff
    public selectedLearningPathwayList: number[] = [];

    public allowedExtensions: string[] = [".jpg", ".jpeg", ".png", ".JPG",];
    public allowedImageExtensions: string = "";

    public async initialise() {
        this.badgeLookupPageManager.refreshData();
        this.allowedImageExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
    }

    public badgeLookupPageManager = new Data.PageManager(this.badgeCriteria, BadgeLookup, this.badgeApiClient.getPagedBadgeLookup, {
        pageSize: 5,
        pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
        sortBy: "badgeId",
        sortAscending: false,
        initialTaskRunner: this.taskRunner,
        allowSort: true,
        taskRunner: this.taskRunner,
    });

    public showCriteria() {
        this.showSearchCriteria = true;
        this.hideBadgeModuleGrid = false;
        if (this.selectedBadgeId !== 0) {
            this.badgeCriteria.badgeId = 0;
            this.badgeLookupPageManager.pageSize = 10;
            this.badgeLookupPageManager.refreshData();
        }
    }

    public searchBadges() {
        this.badgeLookupPageManager.refreshData();
        this.selectedBadgeId = 0;
        this.selectedBadge = new BadgeLookup();
        this.editAddBadgeCard = true;
    }

    public async addBadge() {
        this.resetBadge();
        this.selectedBadgeId = 0;
        this.editAddBadgeCard = false;
        this.showSearchCriteria = false;
        this.hideBadgeModuleGrid = true;
    }

    public clearBadgeSearchFilters() {
        this.badgeCriteria.badgeTitle = "";
        this.badgeCriteria.badgeId = 0;
        this.badgeCriteria.activeBadge = true;
        this.badgeCriteria.badgeApproved = true;
        this.badgeCriteria.keyword = "";
        this.badgeLookupPageManager.refreshData();
    }

    public async showDeleteBadge(badge: BadgeLookup) {
        await ModalUtils.showYesNoDismissible("Delete Badge " + badge.badgeTitle,
            "Are you sure you want to delete this Badge? ", () => this.deleteBadge(badge.badgeId));
    }

    public async deleteBadge(badgeId: number) {
        this.taskRunner.run(async () => {
            const response = await this.taskRunner.waitFor(this.badgeApiClient.deleteBadge(badgeId));
            if (response.data === false) {
                this.notifications.addDanger("Delete unsuccessful", "This Badge had already been assigned and cannot be deleted", NotificationDuration.Standard);
            }
            else {
                this.badgeLookupPageManager.refreshData();
                this.notifications.addSuccess("Badge Deleted", null, NotificationDuration.Standard);
            }
        });
    }

    public async resetBadge() {
        this.selectedBadge = new BadgeLookup();
        this.selectedBadge.badgeModules = new List(BadgeModule);
        this.selectedBadge.badgeLearningPathways = new List(BadgeLearningPathway);
        this.selectedLearningModuleList = [];
        this.selectedLearningPathwayList = [];
        this.clearFilters();
        this.clearBadgeSearchFilters();
        this.clearPathwayFilters();
    }

    public async setSelectedBadge(badgeId: number) {
        this.selectedBadge = new BadgeLookup();
        this.selectedBadge.badgeModules = new List(BadgeModule);
        this.selectedBadge.badgeLearningPathways = new List(BadgeLearningPathway);
        this.learningModuleCriteria.keyword = "";
        this.learningModuleCriteria.learningModuleTag1Id = 0;
        this.learningModuleCriteria.learningModuleTag2Id = 0;
        this.selectedLearningModuleList = [];
        this.selectedLearningPathwayList = [];
        this.learningPathwayCriteria.ids = [];
        this.learningModuleCriteria.ids = [];
        const response = await this.taskRunner.waitFor(this.badgeApiClient.getBadgeById(badgeId));
        this.selectedBadgeId = badgeId;
        this.selectedBadge.set(response.data);
        this.badgeCriteria.badgeId = badgeId;

        if (this.selectedBadge.badgeLearningPathways.length > 0) {
            this.selectedBadge.badgeLearningPathways.forEach(element => {
                this.learningPathwayCriteria.ids.push(element.learningPathwayId!);
                this.addPathwayToBadge(element.learningPathwayId!);
            });
            this.learningPathwayPagerManager.refreshData();
        }

        if (this.selectedBadge.badgeModules.length > 0) {
            this.selectedBadge.badgeModules.forEach(element => {
                this.learningModuleCriteria.ids.push(element.learningModuleId!);
                this.addModuleToBadge(element.learningModuleId!);
            });
            this.learningModulePageManager.refreshData();
        }

        this.showBadgeImage();
        this.badgeLookupPageManager.refreshData();
        this.editAddBadgeCard = false;
        this.showSearchCriteria = false;
    }

    public async saveBadge() {
        var newBadge = this.selectedBadge;
        if (newBadge.parameter > 0 && !(newBadge.badgeSubAction === BadgeSubAction.SubActionParameterList || newBadge.badgeAction === BadgeAction.VerifyUserAccount)) {
            newBadge.badgeType = BadgeType.BasicBadge;
        }
        else {
            newBadge.badgeType = BadgeType.AchievementBadge;
        }
        this.taskRunner.run(async () => {

            this.selectedLearningModuleList.forEach(learningModule => {
                var badgeModule = new BadgeModule();
                badgeModule.learningModuleId = learningModule;
                badgeModule.badgeId = newBadge.badgeId;
                newBadge.badgeModules.push(badgeModule);
            });
            this.selectedLearningPathwayList.forEach(learningPathway => {
                var badgePathway = new BadgeLearningPathway();
                badgePathway.learningPathwayId = learningPathway;
                badgePathway.badgeId = newBadge.badgeId;
                newBadge.badgeLearningPathways.push(badgePathway);
            });
            if (newBadge.badgeId === 0) {
                var responseNew = await this.badgeApiClient.createBadge(newBadge.toJSObject({ includeClean: true }));
                if (!responseNew.data.errorEncountered) {
                    this.setSelectedBadge(responseNew.data.badgeLookup.badgeId);
                    this.notifications.addSuccess("Badge Created", "Badge created successfully", NotificationDuration.Standard);
                }
                else {
                    this.notifications.addDanger("Error", responseNew.data.errorMessage, NotificationDuration.Standard);
                }
            }
            else {
                var responseUpdate = await this.badgeApiClient.updateBadge(newBadge.toJSObject({ includeClean: true }));
                if (!responseUpdate.data.errorEncountered) {
                    this.setSelectedBadge(responseUpdate.data.badgeLookup.badgeId);
                    this.notifications.addSuccess("Badge Updated", "Badge updated successfully", NotificationDuration.Standard);
                }
                else {
                    this.notifications.addDanger("Error", responseUpdate.data.errorMessage, NotificationDuration.Standard);
                }
            }

        });
    }

    public async deleteBadgeImage() {
        if (this.selectedBadgeId === 0) {
            this.selectedBadge.badgeImageName = "";
            this.notifications.addSuccess("Image removed", null, NotificationDuration.Standard);
        }
        else {
            var response = await this.badgeApiClient.removeBadgeImage(this.selectedBadgeId);
            if (response.data) {
                this.selectedBadge.badgeImageName = "";
                this.notifications.addSuccess("Image removed", null, NotificationDuration.Standard);
            }
        }
    }

    public async showBadgeImage(fileDescriptor: string = "") {
        if (fileDescriptor !== "") {
            var fileUploadUrl = await this.badgeApiClient.getBadgeImageByDescriptorId(fileDescriptor);
            this.blobUrl = fileUploadUrl.data;
        }
        else {
            var fileUrl = await this.badgeApiClient.getBadgeImageByDescriptorId(this.selectedBadge.mediaObject?.fileDescriptorId as string);
            this.blobUrl = fileUrl.data;
        }
    }

    //Learning Modules Stuff for Achievement Badges
    public searchModules = () => {
        this.learningModuleCriteria.ids = [];
        this.learningModulePageManager.refreshData();
    }

    public learningModulePageManager = new Data.PageManager(this.learningModuleCriteria, LearningModuleLookup, this.learningPathwayApiClient.getPagedLearningModuleLookup, {
        pageSize: 5,
        pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
        sortBy: "learningModuleTitle",
        sortAscending: true,
        initialTaskRunner: this.taskRunner,
        allowSort: true,
        taskRunner: this.taskRunner,
    });

    public async filtertag2ListSearch(tag1Id: number | undefined) {
        if (tag1Id) {
            const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id))).data;
            this.learningModuleTag2FilteredList.set(tag2List);
        }
        this.learningModuleCriteria.learningModuleTag2Id = 0;
    }

    public clearFilters() {
        this.learningModuleCriteria.keyword = "";
        this.learningModuleCriteria.title = "";
        this.learningModuleCriteria.learningModuleTag1Id = 0;
        this.learningModuleCriteria.learningModuleTag2Id = 0;
        this.learningModuleCriteria.ids = [];
        this.learningModulePageManager.refreshData();
    }

    public isSelected(id: number) {
        return this.selectedLearningModuleList.filter(c => c === id).length > 0;
    }

    public addModuleToBadge(learningModule: number) {
        this.selectedLearningModuleList.push(learningModule);
    }

    public removeModule(learningModule: number) {
        this.selectedLearningModuleList.remove(learningModule);
        var foundModule = this.selectedBadge.badgeModules.find(c => c.learningModuleId === learningModule)
        if (foundModule) {
            this.selectedBadge.badgeModules.remove(foundModule);
        }
    }

    //  Learning Pathway Stuff
    public learningPathwayCriteria = new LearningPathwaySearchCriteria();
    public learningPathwayPagerManager = new Data.PageManager(this.learningPathwayCriteria, LearningPathwayPagerLookup, this.learningPathwayApiClient.getBasePagedLearningPathways, {
        pageSize: 15,
        pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
        sortBy: "learningPathwayId",
        sortAscending: false,
        initialTaskRunner: this.taskRunner,
        allowSort: true,
        taskRunner: this.taskRunner,
    });

    public searchPathways() {
        this.learningPathwayPagerManager.refreshData();
    }

    public clearPathwayFilters() {
        this.learningPathwayCriteria.keyword = "";
        this.learningPathwayCriteria.ids = [];
        this.learningPathwayPagerManager.refreshData();
    }

    public isPathwaySelected(id: number) {
        return this.selectedLearningPathwayList.filter(c => c === id).length > 0;
    }

    public addPathwayToBadge(learningPathway: number) {
        this.selectedLearningPathwayList.push(learningPathway);
    }

    public removePathway(learningPathway: number) {
        this.selectedLearningPathwayList.remove(learningPathway);
        var foundPathway = this.selectedBadge.badgeLearningPathways.find(c => c.learningPathwayId === learningPathway)
        if (foundPathway) {
            this.selectedBadge.badgeLearningPathways.remove(foundPathway);
        }
    }

    public canSaveBadge() {
        if (this.selectedBadge.badgeTitle === ""
            || this.selectedBadge.badgeDescription === ""
            || this.selectedBadge.badgeAction === null
            || this.selectedBadge.badgeImageName === "") {
            return false;
        }
        //if they selected a sub action with a list, we want to ensure there is something in the list or if sub action is parameter, we want to ensure there is something in the parameter.
        else if (this.selectedBadge.badgeAction !== null && this.selectedBadge.badgeSubAction !== null) {
            if (this.selectedBadge.badgeAction === BadgeAction.ModuleComplete && this.selectedBadge.badgeSubAction === BadgeSubAction.SubActionList && this.selectedLearningModuleList.length === 0) {
                return false;
            }
            if (this.selectedBadge.badgeAction === BadgeAction.ModuleComplete && this.selectedBadge.badgeSubAction === BadgeSubAction.SubActionParameterList && this.selectedLearningModuleList.length === 0 && this.selectedBadge.parameter === 0) {
                return false;
            }
            if (this.selectedBadge.badgeAction === BadgeAction.LearningPathwayComplete && this.selectedBadge.badgeSubAction === BadgeSubAction.SubActionList && this.selectedLearningPathwayList.length === 0) {
                return false;
            }
            if (this.selectedBadge.badgeAction === BadgeAction.LearningPathwayComplete && this.selectedBadge.badgeSubAction === BadgeSubAction.SubActionParameterList && this.selectedLearningPathwayList.length === 0 && this.selectedBadge.parameter === 0) {
                return false;
            }
            if (this.selectedBadge.badgeSubAction === BadgeSubAction.SubActionParameter && this.selectedBadge.parameter === 0) {
                return false;
            }
        }
        else if ((this.selectedBadge.badgeAction !== BadgeAction.VerifyUserAccount
            //&& this.selectedBadge.badgeAction !== BadgeAction.UserBirthday
        ) && this.selectedBadge.badgeSubAction === null) {
            return false;
        }
        else {
            return true;
        }
        return true;
    }
}