import { Attributes, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';
import { LearningObjectType } from '../LearningObjects/LearningObjectType.enum';
import Tag1 from '../Tags/Tag1';
import Tag2 from '../Tags/Tag2';
import Tag3 from '../Tags/Tag3';
import ModeratableFieldBase from './ModeratableFieldBase';

@NeoModel
export class ModeratableLongStringField extends ModeratableFieldBase {

    @Required("Required Field.")
    public value: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.value) {
            return "New moderatable Long string field";
        } else {
            return this.value;
        }
    }
}

@NeoModel
export class ModeratableShortStringField extends ModeratableFieldBase {

    @Rules.StringLength(255)
    @Required("Required Field.")
    public value: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.value) {
            return "New moderatable short string field";
        } else {
            return this.value;
        }
    }
}

@NeoModel
export class ModeratableIntField extends ModeratableFieldBase {

    @Attributes.Integer()
    public value: number | null = 5;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New moderatable int field";
        } else {
            return "Moderatable Int Field";
        }
    }
}
@NeoModel
export class ModeratableTag1 extends ModeratableFieldBase {

    @Attributes.ChildObject(Tag1)
    @Required("Required Field.")
    public value: Tag1 | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New moderatable tag 1";
        } else {
            return "Moderatable Tag 1";
        }
    }
}
@NeoModel
export class ModeratableTag2 extends ModeratableFieldBase {

    constructor() {
        super()
        this.value = new Tag2();
    }

    @Attributes.ChildObject(Tag2)
    @Required("Required Field.")
    public value: Tag2;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New moderatable tag 2";
        } else {
            return "Moderatable Tag 2";
        }
    }
}
@NeoModel
export class ModeratableTag3 extends ModeratableFieldBase {

    constructor() {
        super()
        this.value = new Tag3();
    }

    @Attributes.ChildObject(Tag3)
    public value: Tag3;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New moderatable tag 3";
        } else {
            return "Moderatable Tag 3";
        }
    }
}
@NeoModel
export class ModeratableLearningObjectType extends ModeratableFieldBase {

    public value: LearningObjectType | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New moderatable learning object type";
        } else {
            return "Moderatable Learning Object Type";
        }
    }
}