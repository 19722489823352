import { Attributes, Misc, ModelBase, NeoModel } from '@singularsystems/neo-core';
import LearningModule from './LearningModule';
import LearningObjectBase from '../LearningObjects/LearningObjectBase';

@NeoModel
export default class LearningModuleObject extends ModelBase {

    constructor() {
        super()
        this.learningObject = new LearningObjectBase();
        this.learningModule = new LearningModule()
    }

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public learningModuleId: number | null = null;

    // @Attributes.ChildObject(LearningModule)
    public learningModule: LearningModule | null = null;

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public learningObjectId: number | null = null;

    @Attributes.ChildObject(LearningObjectBase)
    public learningObject: LearningObjectBase | null = null;

    public finalQuiz: boolean = false;

    public objectName: string = "LO Test"

    @Attributes.Integer()
    public order: number = 0;

    // Client only properties / methods

}