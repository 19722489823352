import { Data, Model, } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import DeleteTitleCommand from '../../Models/Commands/DeleteTitleComand';
import { PlainObject, PartialPlainObject } from '@singularsystems/neo-core/dist/Model';
import UpdateTitleCommand from '../../Models/Commands/UpdateTitleCommand';
export interface ITitleCommandApiClient {

    /**
     * This method will return a sepcific Titles
     * @returns Race list
     */
    saveList(model: PartialPlainObject<UpdateTitleCommand>[]): AxiosPromise<PlainObject<UpdateTitleCommand>[]>;
    delete(command: Model.PartialPlainObject<DeleteTitleCommand>): AxiosPromise<number>;
}

@injectable()
export default class TitleCommandApiClient extends Data.ApiClientBase implements ITitleCommandApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/TitleCommand`);
    }

    /*
     *Save Title(s)
     */
    public saveList(titles: Array<Model.PlainTrackedObject<UpdateTitleCommand>>): AxiosPromise<Array<Model.PlainTrackedObject<UpdateTitleCommand>>> {
        return this.axios.post(`${this.apiPath}/saveList`, titles);
    }

    /*
     *Delete Title
     */
    public delete(command: Model.PartialPlainObject<DeleteTitleCommand>): AxiosPromise<number> {
        return this.axios.post(`${this.apiPath}/delete/`, command);
    }

}