import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../ForumTypes';
import PostLookup from '../../../Models/Lookups/PostLookup';

export interface IPostUpdateApiClient {

    /** 
     * Creates a post
     * @param post Post to create
     * @returns Post
     */
    createPostAsync(post: Model.PlainObject<PostLookup>): AxiosPromise<any>;

    /** 
     * Updates a Post
     * @param post Post to create
     * @returns Post
     */
    updatePostAsync(post: Model.PartialPlainObject<PostLookup>): AxiosPromise<any>;

    /** 
     * Reports a Post
     * @param post Post to report
     * @returns IActionResult
     */
    reportPostAsync(post: Model.PartialPlainObject<PostLookup>): AxiosPromise<any>;

    /** 
     * Clears a report on a Post
     * @param post Post to clear report
     * @returns IActionResult
     */
    clearReportOnPostAsync(post: Model.PartialPlainObject<PostLookup>): AxiosPromise<any>;

    /** 
     * Deletes a Post with a reason
     * @param post Post to clear report
     * @param reason reason
     * @returns IActionResult
     */
    deletePostAsync(post: Model.PartialPlainObject<PostLookup>): AxiosPromise<any>;

    // Client only properties / methods
}

@injectable()
export default class PostUpdateApiClient extends Data.ApiClientBase implements IPostUpdateApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.ForumApi.ApiPath}/PostUpdate`);
    }

    public createPostAsync(post: Model.PlainObject<PostLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/CreatePost`, post);
    }

    public updatePostAsync(post: Model.PartialPlainObject<PostLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/UpdatePost`, post);
    }

    public reportPostAsync(post: Model.PartialPlainObject<PostLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/ReportPost`, post);
    }

    public clearReportOnPostAsync(post: Model.PartialPlainObject<PostLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/ClearReportPost`, post);
    }

    public deletePostAsync(post: Model.PartialPlainObject<PostLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/DeletePost`, post);
    }

    // Client only properties / methods
}