import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import UserModuleCriteria from './UserModuleCriteria';

@NeoModel
export default class UserDashboardSetting extends ModelBase {

  public dashboardSettingId: number = 0;

  public userId: number = 0;

  @Attributes.Date()
  @Rules.Required()
  public lastActiveDate: Date | null = null;

  @Attributes.ChildObject(UserModuleCriteria)
  public userModuleCriteria: UserModuleCriteria = new UserModuleCriteria();

  @Attributes.Nullable()
  public userModuleCriteriaId: number | null = null;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New user dashboard setting";
    } else {
      return "User Dashboard Setting";
    }
  }
}