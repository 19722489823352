import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import SelfAssessmentModerationVM from "./SelfAssessmentModerationVM";
import "../../../App/Styles/Modules.scss";
import SelfAssessmentLookup from "../../Models/Lookups/SelfAssessmentLookup";
import SelfAssesmentQuestionLookup from "../../Models/Lookups/SelfAssessmentQuestionLookup";
import { Data } from "@singularsystems/neo-core";
import { ModerationState } from "../../Models/LearningObjects/ModerationState.enum";
import RichTextEditor from "../../Components/RichTextEditor";
import { Sentiment } from "../../Models/Questions/Sentiment.enum";
import { LearningContentMediaType } from "../../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import { QuestionType } from "../Assessment/SelfAssessmentQuestionComponent";
import { GetRandomCardColor, OpenLink } from '../../../App/HelperClasses/GlobalHelpers';
import Card from "../../../Template/components/Card/Card";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardIcon from "../../../Template/components/Card/CardIcon";
import CardBody from "../../../Template/components/Card/CardBody";
import GridContainer from "../../../Template/components/Grid/GridContainer";
import GridItem from "../../../Template/components/Grid/GridItem";
import ForumDrawer from "../../../Forum/Components/ForumComponents/ForumDrawer";
import FabButton from "../../../Template/components/CustomButtons/FabButton";
import CardFooter from "../../../Template/components/Card/CardFooter";

export enum selfAssessmentTabs {
    selfAssessments = "Self Assessments",
    questionOrdering = "Question Ordering"
}

export enum SelfAssessmentModerationState {
    SubmittedForModeration = 3,
    UnderModeration = 4,
    Review = 5,
    ResubmittedForModeration = 7,
}

@observer
export default class SelfAssessmentModerationView extends Views.ViewBase<SelfAssessmentModerationVM>{
    constructor(props: unknown) {
        super("SelfAssessments", SelfAssessmentModerationVM, props);
    }

    public render() {
        return (
            <div className="pv-5">
                <div>
                    <Card>
                        <CardHeader>
                            <CardIcon color="success">
                                <i className={`fas fa-search fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Self-Assessments</h4>
                        </CardHeader>
                        <CardBody>
                            <div hidden={this.viewModel.hideCriteria} onKeyPress={(event) => {
                                if (event.charCode === 13) {
                                    this.viewModel.SearchSelfAssessments();
                                    event.preventDefault();
                                }
                            }}>
                                <Neo.GridLayout md={1} >
                                    <Neo.Input className="cerebri-regular font-12 mb-2" placeholder="Search for a Self Assessment by Title" bind={this.viewModel.selfAssessmentCriteria.meta.keyword} />
                                </Neo.GridLayout>
                                <Neo.GridLayout md={1} style={{ marginBottom: "15px" }} >
                                    <Neo.FormGroup label="Moderation State" bind={this.viewModel.selfAssessmentCriteria.meta.ModerationState} select={{
                                        itemSource: Data.StaticDataSource.fromEnum(SelfAssessmentModerationState),
                                        allowNulls: true,
                                        onItemSelected: (item) => {
                                            this.viewModel.SearchSelfAssessments();
                                        }
                                    }} />
                                    <Neo.Button autoFocus className="mt-1" variant="info" isOutline text="Search Self-Assessments" icon="fa fa-search" onClick={() => { this.viewModel.SearchSelfAssessments() }} />
                                </Neo.GridLayout>
                            </div>
                            <div className="row mb-2" hidden={!this.viewModel.hideCriteria}>
                                <div className="col-md-10">
                                    <Neo.Button variant="info" isOutline icon="import" onClick={() => { this.viewModel.showCriteria() }} >Show Search Criteria</Neo.Button>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <Neo.Pager pageManager={this.viewModel.SelfAssessmentLookupPageManager}
                                                pageControlProps={{
                                                    firstText: "",
                                                    prevText: "Prev",
                                                    nextText: "Next",
                                                    lastText: "",
                                                    alignment: "center",
                                                    autoHide: false,
                                                    pageSizeLabel: "Show"
                                                }}>
                                                <NeoGrid.Grid<SelfAssessmentLookup> >
                                                    {(assessment: SelfAssessmentLookup, assessmentMeta) => (
                                                        <NeoGrid.RowGroup expandProperty={assessmentMeta.isExpanded}>
                                                            <NeoGrid.Row className={this.viewModel.selectedSelfAssessmentId === assessment.selfAssessmentId ? "selected-item-css" : "bg-none"}>
                                                                <NeoGrid.Column label="Self Assessment Title" display={assessmentMeta.selfAssessmentTitle} />
                                                                <NeoGrid.Column label="Self Assessment Description" input={{ rows: 2 }} display={assessmentMeta.selfAssessmentDescription} />
                                                                <NeoGrid.Column label="Created By" display={assessmentMeta.createdByUser} />
                                                                <NeoGrid.ButtonColumn>
                                                                    <Neo.Button hidden={assessment.moderationState !== ModerationState.SubmittedForModeration} isOutline variant="info"
                                                                        disabled={assessment.moderationState !== ModerationState.SubmittedForModeration} onClick={() => this.viewModel.StartModeration(assessment.selfAssessmentId)}>
                                                                        Start Moderation
                                                                    </Neo.Button>
                                                                    <Neo.Button hidden={assessment.moderationState !== ModerationState.Review} isOutline variant="info"
                                                                        disabled={true}>
                                                                        Under Review
                                                                    </Neo.Button>
                                                                    <Neo.Button hidden={assessment.moderationState !== ModerationState.Moderated} isOutline variant="info"
                                                                        disabled={true}>
                                                                        Moderated
                                                                    </Neo.Button>
                                                                    <Neo.Button hidden={assessment.moderationState !== ModerationState.UnderModeration && assessment.moderationState !== ModerationState.ResubmittedForModeration}
                                                                        isOutline variant="info" onClick={() => this.viewModel.GetModerationSummary(assessment.selfAssessmentId)}
                                                                        tooltip="Click to see if Self-Assessment Moderation can be marked as successfully completed or to see review info.">
                                                                        Update Moderation
                                                                    </Neo.Button>
                                                                </NeoGrid.ButtonColumn>
                                                                <NeoGrid.ButtonColumn>
                                                                    <Neo.Button isOutline variant="success" onClick={() => this.viewModel.SetSelectedModerateableAssessment(assessment.selfAssessmentId)}>
                                                                        Select
                                                                    </Neo.Button>
                                                                </NeoGrid.ButtonColumn>
                                                            </NeoGrid.Row>
                                                            <NeoGrid.ChildRow>
                                                                <NeoGrid.Grid items={assessment.selfAssessmentQuestions} initialSort={"questionOrder"}>
                                                                    {(question: SelfAssesmentQuestionLookup, questions) => (
                                                                        <NeoGrid.RowGroup>
                                                                            <NeoGrid.Row key={question.selfAssessmentQuestionId} className={this.viewModel.selectedQuestionId === question.selfAssessmentQuestionId ? "selected-item-css" : "bg-none"}>
                                                                                <NeoGrid.Column label="Question" display={questions.plainText} />
                                                                                <NeoGrid.Column label="Question Type" display={questions.questionType} />
                                                                                <NeoGrid.Column label="Question Order" width="150px" display={questions.questionOrder} />
                                                                                <NeoGrid.Column label="Linked Resource" width="150px" display={questions.linkedToResource} />
                                                                                <NeoGrid.ButtonColumn>
                                                                                    <Neo.Button disabled={this.viewModel.selectedSelfAssessmentId !== question.selfAssessmentId} isOutline variant="success" onClick={() => { this.viewModel.SelectQuestionForModeration(question.selfAssessmentQuestionId) }}>
                                                                                        Select
                                                                                    </Neo.Button>
                                                                                </NeoGrid.ButtonColumn>
                                                                            </NeoGrid.Row>
                                                                        </NeoGrid.RowGroup>
                                                                    )}
                                                                </NeoGrid.Grid>
                                                            </NeoGrid.ChildRow>
                                                        </NeoGrid.RowGroup>
                                                    )}
                                                </NeoGrid.Grid>
                                            </Neo.Pager>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <section>
                    <div>
                        <Card hidden={this.viewModel.hideSAInfo}>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-chalkboard fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">{`Self Assessment Info: ${this.viewModel.moderateableSelfAssessment.selfAssessmentTitle.value}`}</h4>
                            </CardHeader>
                            <CardBody>
                                <Neo.Form model={this.viewModel.moderateableSelfAssessment}
                                    showSummaryModal>
                                    {(item, meta) => (
                                        <GridContainer>
                                            <GridItem md={6} sm={12} xs={12}>
                                                <Neo.FormGroupFloating input={{ rows: 1 }} label="Title" bind={meta.selfAssessmentTitle.value?.meta.value} readOnly={this.viewModel.moderationStarted} autoFocus /><b />
                                            </GridItem>
                                            <GridItem md={4}>
                                            </GridItem>
                                            <GridItem md={2} sm={12} xs={12}>
                                                <Neo.FormGroup hidden={this.viewModel.moderationStarted} className="mt-2" label="Approve Title?" bind={item.selfAssessmentTitle.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveQuestionContentModerationState(false)} autoFocus /><b />
                                            </GridItem>
                                            <GridItem md={6} sm={12} xs={12}>
                                                <Neo.FormGroupFloating label="Description" bind={item.selfAssessmentDescription.meta.value} readOnly={this.viewModel.moderationStarted} autoFocus /><b />
                                            </GridItem>
                                            <GridItem md={4}>
                                            </GridItem>
                                            <GridItem md={2} sm={12} xs={12}>
                                                <Neo.FormGroup hidden={this.viewModel.moderationStarted} className="mt-2" label="Approve Description?" bind={item.selfAssessmentDescription.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveQuestionContentModerationState(false)} autoFocus /><b />
                                            </GridItem>
                                        </GridContainer>
                                    )}
                                </Neo.Form>

                                <NeoGrid.Grid items={this.viewModel.moderateableSelfAssessment.selfAssessmentTags2} >
                                    {(assessmentTag, assessmentTagMeta) => (
                                        <NeoGrid.Row>
                                            <NeoGrid.Column autoFocus width={"36%"} size={10} label="Category Tag" display={assessmentTagMeta.tag1Name} />
                                            <NeoGrid.Column width={"36%"} size={10} disabled={true} label="Sub-Category Tag" autoFocus={false} display={assessmentTagMeta.tag2Name} />
                                            <NeoGrid.Column width={"30%"} size={10} hidden={this.viewModel.moderationStarted} label="Approve Tags?" autoFocus={false} bind={assessmentTagMeta.approvedByModerator} onChange={() => this.viewModel.SaveQuestionContentModerationState(false)} />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                                {
                                    !this.viewModel.showAssessmentModerationNotes ||
                                    <Neo.Card className="moderation-notes">
                                        <RichTextEditor bind={this.viewModel.moderateableSelfAssessment.meta.moderationNotes} viewModel={this.viewModel.richTextEditorVmModule} labelText="Moderation Notes" />
                                    </Neo.Card>
                                }
                                <Neo.Button hidden={this.viewModel.moderationStarted} text="Save Moderation Progress" isOutline variant="success" icon="save" className="mt-2" onClick={() => this.viewModel.SaveQuestionContentModerationState(true)} />
                                <Neo.Button hidden={this.viewModel.moderationStarted} text={!this.viewModel.showAssessmentModerationNotes ? "Moderation Notes" : "Hide Moderation Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.viewModel.showAssessmentModerationNotes = !this.viewModel.showAssessmentModerationNotes} />
                            </CardBody>
                        </Card>
                    </div>
                </section>

                <section>
                    <div>
                        <Card hidden={this.viewModel.hideSAQInfo}>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fa fa-book fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">{`Question Info: ${this.viewModel.moderateableSelfAssessment.selfAssessmentTitle.value}`}</h4>
                            </CardHeader>
                            <CardBody>
                                <Neo.Form model={this.viewModel.selfAssessmentModerateableQuestion}
                                    showSummaryModal>
                                    {(item, meta) => (
                                        <GridContainer>
                                            <GridItem md={10} sm={12} xs={12}>
                                                {!this.viewModel.hideSAQInfo &&
                                                    <RichTextEditor viewModel={this.viewModel.richTextEditorVmSAQuestion} bind={item.questionText.meta.value} labelText={"Question"} readOnly={this.viewModel.moderationStarted} />
                                                }
                                            </GridItem>
                                            <GridItem md={2} sm={12} xs={12}>
                                                <Neo.FormGroup hidden={this.viewModel.moderationStarted} className="mt-7" label="Approve Question?" bind={item.questionText.meta.ApprovedByModerator} autoFocus onChange={() => this.viewModel.SaveQuestionContentModerationState(false)} /><b />
                                            </GridItem>

                                            <GridItem md={6} sm={12} xs={12}>
                                                <Neo.FormGroupFloating label="Tag 3" display={item.meta.moderatableTag3Name} autoFocus />
                                            </GridItem>
                                            <GridItem md={4}>
                                            </GridItem>
                                            <GridItem md={2} sm={12} xs={12}>
                                                <Neo.FormGroup hidden={this.viewModel.moderationStarted} className="mt-2" label="Approve Tag 3?" bind={item.tag3.meta.ApprovedByModerator} autoFocus onChange={() => this.viewModel.SaveQuestionContentModerationState(false)} /><b />
                                            </GridItem>
                                            <GridItem md={6} sm={12} xs={12}>
                                                {(this.viewModel.selectedQuestionTypeId === (QuestionType.LikertScale as number)) &&
                                                    <Neo.FormGroupFloating input={{ rows: 1 }} label="Sentiment" display={item.meta.sentiment} select={{
                                                        itemSource: Data.StaticDataSource.fromEnum(Sentiment),
                                                        renderAsText: true
                                                    }} />
                                                }
                                            </GridItem>
                                            <GridItem md={6}>
                                            </GridItem>

                                            <GridItem md={10} sm={12} xs={12}>
                                                <NeoGrid.Grid className="mb-20 mt-20" items={item.selfAssessmentOptionList}>
                                                    {(option, OptionMeta) => (
                                                        <NeoGrid.Row>
                                                            <NeoGrid.Column label="Option" width="400px" placeholder="Option" bind={option.meta.option} />
                                                            <NeoGrid.Column bind={option.meta.points} width="150px" disabled={true} />
                                                        </NeoGrid.Row>
                                                    )}
                                                </NeoGrid.Grid>
                                            </GridItem>
                                            <GridItem md={2} sm={12} xs={12}>
                                                <Neo.FormGroup hidden={this.viewModel.moderationStarted} label="Approve options and points?" bind={this.viewModel.selfAssessmentModerateableQuestion.selfAssessmentOptions.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveQuestionContentModerationState(false)} />
                                            </GridItem>
                                        </GridContainer>
                                    )}
                                </Neo.Form>
                                {
                                    !this.viewModel.showAssessmentQuestionModerationNotes ||
                                    <Neo.Card className="moderation-notes">
                                        <RichTextEditor bind={this.viewModel.selfAssessmentModerateableQuestion.meta.moderationNotes} viewModel={this.viewModel.richTextEditorVmSAQuestionMN} labelText="Moderation Notes" />
                                    </Neo.Card>
                                }
                                <Neo.Button hidden={this.viewModel.moderationStarted} text="Save Moderation Progress" isOutline variant="success" icon="save" className="mt-2" onClick={() => this.viewModel.SaveQuestionContentModerationState(true)} />
                                <Neo.Button hidden={this.viewModel.moderationStarted} text={!this.viewModel.showAssessmentQuestionModerationNotes ? "Question Notes" : "Hide Question Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.viewModel.showAssessmentQuestionModerationNotes = !this.viewModel.showAssessmentQuestionModerationNotes} />

                            </CardBody>
                        </Card>
                    </div>
                </section>

                <section>
                    {!this.viewModel.showLinkedResource &&
                        <div>
                            <Card hidden={this.viewModel.showLinkedResource}>
                                <CardHeader icon>
                                    <CardIcon color="success">
                                        <i className={`fas fa-file fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">Question Linked Resource</h4>
                                </CardHeader>
                                <CardBody>
                                    <Neo.Form >
                                        <GridContainer>
                                            <GridItem md={6} sm={12} xs={12}>
                                                <Neo.FormGroupFloating bind={this.viewModel.questionLearningContent.learningContent.meta.title} readOnly={this.viewModel.moderationStarted} /><b />
                                            </GridItem>
                                            <GridItem md={6}>
                                            </GridItem>
                                            <GridItem md={6} sm={12} xs={12}>
                                                <Neo.FormGroupFloating display={this.viewModel.questionLearningContent.meta.mediaTypeFetched}
                                                    select={{
                                                        itemSource: Data.StaticDataSource.fromEnum(LearningContentMediaType),
                                                        renderAsText: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem md={6}>
                                            </GridItem>
                                            <GridItem md={6} sm={12} xs={12}>
                                                <Neo.FormGroupFloating label="Alternative URL" placeholder="www.urlExample.com" display={this.viewModel.questionLearningContent.learningContent.meta.externalResourceLocation}
                                                    hidden={this.viewModel.questionLearningContent.learningContent.mediaType === LearningContentMediaType.YouTube
                                                    } />
                                            </GridItem>
                                            <GridItem md={6}>
                                            </GridItem>
                                            <GridItem md={6} sm={12} xs={12}>
                                                <Neo.FormGroupFloating label="URL Key" display={this.viewModel.questionLearningContent.learningContent.meta.externalResourceLocationKey} hidden={this.viewModel.questionLearningContent.learningContent.mediaType !== LearningContentMediaType.YouTube} /><b />
                                            </GridItem>
                                            <GridItem md={6}>
                                            </GridItem>
                                            <GridItem md={12}>
                                                <Neo.Button hidden={this.viewModel.questionLearningContent.learningContent.mediaObject === null} text={"Download"} isOutline icon={this.viewModel.questionLearningContent.learningContent.mediaObject?.icon} variant="info"
                                                    onClick={() => this.viewModel.DownloadFileGet(this.viewModel.questionLearningContent.learningContent.mediaObject?.fileDescriptorId as string, this.viewModel.questionLearningContent.mediaTypeFetched as number, this.viewModel.questionLearningContent.learningContent.externalResourceLocation as string)} />
                                            </GridItem>
                                            <GridItem md={12}>
                                                <Neo.Button hidden={this.viewModel.questionLearningContent.mediaTypeFetched !== LearningContentMediaType.Link} text={"Open link"} isOutline icon="link" variant="info"
                                                    onClick={() => OpenLink(this.viewModel.questionLearningContent.learningContent.externalResourceLocation as string)} />
                                            </GridItem>
                                            <GridItem md={12}>
                                                <Neo.Button tooltip="Click to open link in new window" hidden={this.viewModel.questionLearningContent.learningContent.mediaType !== LearningContentMediaType.YouTube}
                                                    isOutline icon="link" variant="info" text="Open link" onClick={() => OpenLink(this.viewModel.questionLearningContent.learningContent.externalResourceLocationKey as string)} />
                                            </GridItem>
                                            <GridItem md={6} sm={12} xs={12}>
                                                <NeoGrid.Grid className="mb-10 mt-10" items={this.viewModel.fileDescriptorList}>
                                                    {(file, fileMeta) => (
                                                        <NeoGrid.Row>
                                                            <NeoGrid.Column label="File Name" display={fileMeta.fileName} />
                                                            <NeoGrid.Column label="File Type" display={fileMeta.mimeType} />
                                                        </NeoGrid.Row>
                                                    )}
                                                </NeoGrid.Grid>
                                            </GridItem>
                                            <GridItem md={4}>
                                            </GridItem>
                                            <GridItem md={2} sm={12} xs={12}>
                                                <Neo.FormGroup hidden={this.viewModel.moderationStarted} label="Approve Content?" bind={this.viewModel.questionLearningContent.meta.approvedByModerator} onChange={() => this.viewModel.SaveQuestionContentModerationState(false)} />
                                            </GridItem>
                                        </GridContainer>
                                    </Neo.Form>
                                    {
                                        !this.viewModel.showAssessmentQuestionContentModerationNotes ||
                                        <Neo.Card className="moderation-notes">
                                            <RichTextEditor bind={this.viewModel.questionLearningContent.meta.selfAssessmentContentModerationNotes} viewModel={this.viewModel.richTextEditorVmSAContentQuestionMN} labelText="Moderation Notes" />
                                        </Neo.Card>
                                    }
                                    <Neo.Button hidden={this.viewModel.moderationStarted} text="Save Moderation Progress" isOutline variant="success" icon="save" className="mt-2" onClick={() => this.viewModel.SaveQuestionContentModerationState(true)} />
                                    <Neo.Button hidden={this.viewModel.moderationStarted} text={!this.viewModel.showAssessmentQuestionContentModerationNotes ? "Content Notes" : "Hide Content Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.viewModel.showAssessmentQuestionContentModerationNotes = !this.viewModel.showAssessmentQuestionContentModerationNotes} />

                                </CardBody>
                            </Card>
                        </div>
                    }
                </section>

                <Neo.Modal title="Start Moderation - Please Confirm" size="xl"
                    show={this.viewModel.showAcceptModal}
                    onClose={() => this.viewModel.showAcceptModal = false}
                    acceptButton={{
                        text: "Accept", variant: "info", icon: "share",
                        onClick: () => this.viewModel.AcceptSelfAssessmentForModeration()
                    }}
                    closeButton={{
                        text: "Close", variant: "secondary", icon: "times", onClick: () => this.viewModel.DeclineSelfAssessmentForModeration()
                    }} >
                    <Neo.Loader task={this.viewModel.taskRunner}>
                        <div>
                            <p>By clicking "Accept" you:<br />
                                • Accept Responsibility to Start and Complete the Moderation Process of this Self-Assessment</p>
                        </div>
                    </Neo.Loader>
                </Neo.Modal>

                <ForumDrawer
                    show={this.viewModel.showReviewSummaryDrawer}
                    onClose={() => this.viewModel.showReviewSummaryDrawer = false}
                    isMaximised={this.viewModel.showReviewSummaryDrawerIsMaximised}
                    maximiseWindow={() => this.viewModel.showReviewSummaryDrawerIsMaximised = !this.viewModel.showReviewSummaryDrawerIsMaximised}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="warning">
                                <i className={`fa fa-info fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Review Summary for: {this.viewModel.moderationSummary.title}</h4>
                        </CardHeader>
                        <CardBody>
                            <Card hidden={this.viewModel.moderationSummary.baseAssessmentApproved}>
                                <CardHeader icon>
                                    <CardIcon color="danger">
                                        <i className={`fa fa-clipboard fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text"> {this.viewModel.moderationSummary.title}</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-category-white">
                                        The Following properties are not approved on this assessment:
                                    </p>
                                    <ul>
                                        {this.viewModel.moderationSummary.titleApproved || <li style={{ fontSize: "15px" }}>Title</li>}
                                        {this.viewModel.moderationSummary.descriptionApproved || <li style={{ fontSize: "15px" }}>Description</li>}
                                    </ul>
                                    <div hidden={this.viewModel.moderationSummary.moduleNotes.length === 0}>
                                        <RichTextEditor bind={this.viewModel.moderationSummary.meta.moduleNotes} readOnly={true} labelText="Assessment notes" />
                                    </div>
                                </CardBody>
                            </Card>

                            <Card hidden={this.viewModel.moderationSummary.tagsApproved}>
                                <CardHeader icon>
                                    <CardIcon color="danger">
                                        <i className={`fa fa-tags fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">Review Assessment Tags</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-category-white">
                                        The Following tags are not approved on your assessment:
                                    </p>
                                    <ul>
                                        {
                                            this.viewModel.moderationSummary.assessmentTags.map((tag) => (
                                                <li key={tag.tag1Id} style={{ fontSize: "15px" }} hidden={tag.approved}>{tag.errorMessage} </li>
                                            ))
                                        }
                                    </ul>
                                </CardBody>
                            </Card>

                            <Card hidden={this.viewModel.moderationSummary.assessmentQuestionsApproved}>
                                <CardHeader icon>
                                    <CardIcon color="danger">
                                        <i className={`fa fa-question fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">Review Assessment Questions</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-category-white">
                                        The following questions are not approved:
                                    </p>

                                    {this.viewModel.moderationSummary.assessmentQuestions.map((question) => (
                                        <Card hidden={question.assessmentQuestionApproved} key={question.question}>
                                            <CardHeader >
                                                <CardIcon color={GetRandomCardColor()}>
                                                    <i className={`fa fa-eye fa-2x`} />
                                                </CardIcon>
                                                <RichTextEditor bind={question.meta.question} readOnly labelText="Question" />
                                            </CardHeader>
                                            <CardBody>
                                                <p className="card-category-white">
                                                    The Following properties are not approved on this question:
                                                </p>
                                                <ul>
                                                    {question.questionApproved || <li style={{ fontSize: "15px" }}>Question Text</li>}
                                                    {question.tag3Approved || <li style={{ fontSize: "15px" }}>Tag 3</li>}
                                                    {question.optionsApproved || <li style={{ fontSize: "15px" }}>Question Options</li>}
                                                </ul>
                                                <div hidden={this.viewModel.moderationSummary.moduleNotes.length === 0}>
                                                    <RichTextEditor bind={this.viewModel.moderationSummary.meta.moduleNotes} readOnly={true} labelText="Question notes" />
                                                </div>
                                            </CardBody>

                                            <Card hidden={question.questionContentApproved}>
                                                <CardHeader >
                                                    <CardIcon color={GetRandomCardColor()}>
                                                        <i className={`fa fa-video fa-2x`} />
                                                    </CardIcon>
                                                    <CardBody>
                                                        <p className="card-category-white">
                                                            The Content on this question has not been approved:
                                                        </p>
                                                        <p>Content Title : {question.questionContent.contentTitle}</p>
                                                    </CardBody>
                                                </CardHeader>
                                            </Card>
                                        </Card>
                                    ))}

                                </CardBody>
                            </Card>
                        </CardBody>
                        <CardFooter>
                            <Neo.Button text="Submit for Review" variant="info" icon="share" onClick={() => this.viewModel.SendSelfAssessmentForReview()} />
                        </CardFooter>
                    </Card>
                </ForumDrawer>

                <Neo.Modal title="Complete Self-Assessment Moderation"
                    show={this.viewModel.showAssessmentCompleteModerationModal}
                    onClose={() => this.viewModel.showAssessmentCompleteModerationModal = false}
                    acceptButton={{
                        text: "Complete Moderation", variant: "info", icon: "share",
                        onClick: () => this.viewModel.CompleteModeration()
                    }}
                    closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times", onClick: () => this.viewModel.showAssessmentCompleteModerationModal = false
                    }} >
                    <Neo.Loader task={this.viewModel.taskRunner}>
                        <p className="card-category-white">
                            This Self Assessment and all its questions and content is approved.  Would you like to complete moderation and mark Self Assessment as fully moderated?
                        </p>

                    </Neo.Loader>
                </Neo.Modal>
                <FabButton icon="eye" tooltip="Click to view moderation summary" hidden={this.viewModel.selectedSelfAssessmentId === 0} onClick={() => this.viewModel.GetModerationSummary(this.viewModel.selectedSelfAssessmentId)} />

            </div >
        )
    }
}