import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import ModuleModerationVM from './ModuleModerationVM';
import { observer } from 'mobx-react';
import LearningModuleLookup from '../../Models/Lookups/LearningModuleLookup';
import { ModerationState } from '../../Models/LearningObjects/ModerationState.enum';
import { Data } from '@singularsystems/neo-core';
import { LearningObjectType } from '../../Models/LearningObjects/LearningObjectType.enum';
import { LearningContentMediaType } from '../../Models/LearningObjects/LearningContent/LearningContentMediaType.enum';
import RichTextEditor from '../../Components/RichTextEditor';
import ModeratableContentFileComponent from '../../Components/ModeratableContentComponent';
import { QuestionType } from '../../Models/Questions/QuestionType.enum';
import { OpenLink } from '../../../App/HelperClasses/GlobalHelpers';
import Card from '../../../Template/components/Card/Card';
import CardHeader from '../../../Template/components/Card/CardHeader';
import CardIcon from '../../../Template/components/Card/CardIcon';
import CardBody from '../../../Template/components/Card/CardBody';
import GridContainer from '../../../Template/components/Grid/GridContainer';
import GridItem from '../../../Template/components/Grid/GridItem';
import ForumDrawer from '../../../Forum/Components/ForumComponents/ForumDrawer';
import CardFooter from '../../../Template/components/Card/CardFooter';
import FabButton from '../../../Template/components/CustomButtons/FabButton';

export enum ModuleModerationState {
  SubmittedForModeration = 3,
  UnderModeration = 4,
  Review = 5,
  ResubmittedForModeration = 7,
  Deactivated = 8
}

@observer
export default class ModuleModerationView extends Views.ViewBase<ModuleModerationVM> {

  constructor(props: unknown) {
    super("Moderation", ModuleModerationVM, props);
  }

  public render() {
    return (
      <div>
        <div>
          <Card>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`fas fa-search fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">Modules</h4>
            </CardHeader>
            <CardBody>
              <div hidden={this.viewModel.hideCriteria} onKeyPress={(event) => {
                if (event.charCode === 13) {
                  this.viewModel.searchModules();
                  event.preventDefault();
                }
              }}>

                <Neo.GridLayout md={1} >
                  <Neo.Input className="cerebri-regular font-12 mb-2" placeholder="Search for a Module by Title" bind={this.viewModel.criteria.meta.keyword} />
                </Neo.GridLayout>
                <Neo.GridLayout md={1} style={{ marginBottom: "15px" }} >
                  <Neo.FormGroup label="Moderation State" bind={this.viewModel.criteria.meta.moderationState} select={{
                    itemSource: Data.StaticDataSource.fromEnum(ModuleModerationState),
                    allowNulls: true,
                    onItemSelected: (item) => {
                      this.viewModel.searchModules();
                    }
                  }} />
                  <Neo.Button autoFocus className="mt-1" variant="info" isOutline text="Search Modules" icon="fa fa-search" onClick={() => { this.viewModel.SearchModules() }} />

                </Neo.GridLayout>
              </div>
              <div className="row mb-2" hidden={!this.viewModel.hideCriteria}>
                <div className="col-md-10">
                  <Neo.Button variant="info" isOutline icon="import" onClick={() => { this.viewModel.showCriteria() }} >Show Search Criteria</Neo.Button>
                </div>
              </div>
              <p>*Please note, you cannot moderate your own work.</p>
              <div className="row ">
                <div className="col-md-12">
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <Neo.Pager pageManager={this.viewModel.LearningModulePageManager}
                        pageControlProps={{
                          firstText: "",
                          prevText: "Prev",
                          nextText: "Next",
                          lastText: "",
                          alignment: "center",
                          autoHide: false,
                          pageSizeLabel: "Show"

                        }}>
                        <NeoGrid.Grid<LearningModuleLookup>>
                          {(learningModule: LearningModuleLookup, learningModuleMeta) => (
                            <NeoGrid.Row className={this.viewModel.selectedLearningModuleId === learningModule.learningModuleId ? "selected-item-css" : "bg-none"}>
                              <NeoGrid.Column label="Title" display={learningModuleMeta.learningModuleTitle} />
                              <NeoGrid.Column label="Learning Objective" display={learningModuleMeta.description} />
                              <NeoGrid.Column label="Created By" display={learningModuleMeta.createdByUserInformation} />
                              <NeoGrid.Column label="Created Date" display={learningModuleMeta.createdDate} />
                              <NeoGrid.Column display={learningModuleMeta.moderationState} select={{
                                itemSource: Data.StaticDataSource.fromEnum(ModerationState),
                                renderAsText: true
                              }} />
                              <NeoGrid.Column label="Moderated By" display={learningModuleMeta.moderatedByFullName} />
                              <NeoGrid.ButtonColumn>
                                <Neo.Button hidden={learningModule.moderationState !== ModerationState.SubmittedForModeration} isOutline variant="info" disabled={learningModule.moderationState !== ModerationState.SubmittedForModeration} onClick={() => this.viewModel.StartModeration(learningModule.learningModuleId)}>
                                  Start Moderation
                                </Neo.Button>
                                <Neo.Button hidden={learningModule.moderationState !== ModerationState.Review} isOutline variant="info"
                                  disabled={true}>
                                  Under Review
                                </Neo.Button>
                                <Neo.Button hidden={learningModule.moderationState !== ModerationState.Moderated} isOutline variant="info"
                                  disabled={true}>
                                  Moderated
                                </Neo.Button>
                                <Neo.Button hidden={learningModule.moderationState !== ModerationState.UnderModeration && learningModule.moderationState !== ModerationState.ResubmittedForModeration} isOutline variant="info"
                                  onClick={() => this.viewModel.CheckModerationState(learningModule.learningModuleId)}
                                  tooltip="Click to see if Module Moderation can be marked as successfully completed or to see review info.">
                                  Update Moderation
                                </Neo.Button>
                                <Neo.Button hidden={learningModule.moderationState !== ModerationState.Deactivated} isOutline variant="info"
                                  disabled={learningModule.moderationState !== ModerationState.Deactivated} onClick={() => this.viewModel.ReactivateModule(learningModule.learningModuleId)}>
                                  Reactivate
                                </Neo.Button>
                              </NeoGrid.ButtonColumn>
                              <NeoGrid.ButtonColumn>
                                <Neo.Button isOutline variant="success" onClick={() => this.viewModel.SetSelectedLearningModule(learningModule.learningModuleId)}>
                                  Select
                                </Neo.Button>
                              </NeoGrid.ButtonColumn>
                            </NeoGrid.Row>
                          )}
                        </NeoGrid.Grid>
                      </Neo.Pager>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <section>
          <div>
            <Card hidden={this.viewModel.hideModuleInfo}>
              <CardHeader icon>
                <CardIcon color="success">
                  <i className={`fas fa-chalkboard fa-2x`}></i>
                </CardIcon>
                <h4 className="Card-icon-header-text">{`Module Info: ${this.viewModel.selectedLearningModule.title.value}`}</h4>
              </CardHeader>
              <CardBody>
                <Neo.Form model={this.viewModel.selectedLearningModule}
                  showSummaryModal>
                  {(item, meta) => (
                    <GridContainer>
                      <GridItem md={6} sm={12} xs={12}>
                        <Neo.FormGroupFloating input={{ rows: 1 }} label="Title" bind={meta.title.value?.meta.value} autoFocus readOnly={this.viewModel.moderationStarted} />
                      </GridItem>
                      <GridItem md={4}>
                      </GridItem>
                      <GridItem md={2} sm={12} xs={12}>
                        <Neo.FormGroup hidden={this.viewModel.moderationStarted} label="Approve Title?" bind={item.title.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveLearningContentModeration(false)} autoFocus />
                      </GridItem>
                      <GridItem md={6} sm={12} xs={12}>
                        <Neo.FormGroupFloating label="CPTD Points" bind={item.cptdPoints?.meta.value} autoFocus readOnly={this.viewModel.moderationStartedAndHasRole} />
                      </GridItem>
                      <GridItem md={4}>
                      </GridItem>
                      <GridItem md={2} sm={12} xs={12}>
                        <Neo.FormGroup hidden={this.viewModel.moderationStarted} label="Approve CPTD Points?" bind={item.cptdPoints.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveLearningContentModeration(false)} autoFocus />
                      </GridItem>
                      <GridItem md={6} sm={12} xs={12}>
                        <Neo.FormGroupFloating input={{ rows: 2 }} label="Learning Objective" bind={item.description?.meta.value} autoFocus readOnly={this.viewModel.moderationStarted} /><b />
                      </GridItem>
                      <GridItem md={4}>
                      </GridItem>
                      <GridItem md={2} sm={12} xs={12}>
                        <Neo.FormGroup hidden={this.viewModel.moderationStarted} label="Approve Learning Objective?" bind={item.description.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveLearningContentModeration(false)} autoFocus /><b />
                      </GridItem>
                    </GridContainer>
                  )}
                </Neo.Form>

                <NeoGrid.Grid items={this.viewModel.selectedLearningModule.learningModuleTags} >
                  {(moduleTag, moduleTagMeta) => (
                    <NeoGrid.Row>
                      <NeoGrid.Column width={"36%"} autoFocus label="Category Tag" display={moduleTagMeta.tag1Name} />
                      <NeoGrid.Column width={"36%"} disabled={true} label="Sub-Category Tag" autoFocus={false} display={moduleTagMeta.tag2Name} />
                      <NeoGrid.Column width={"30%"} hidden={this.viewModel.moderationStarted} label="Approve Tags?" autoFocus={false} bind={moduleTagMeta.approved} onChange={() => this.viewModel.SaveLearningContentModeration(false)} />
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
                {
                  this.viewModel.showModuleNotes ||
                  <Neo.Card className="moderation-notes">
                    <RichTextEditor onBlur={() => this.viewModel.SaveLearningContentModeration(true)} bind={this.viewModel.selectedLearningModule.meta.moderationNotes} labelText="Module Moderation Notes" />
                  </Neo.Card>
                }
                <Neo.Button hidden={this.viewModel.moderationStarted} text="Save Moderation Progress" isOutline variant="success" icon="save" className="mt-2" onClick={() => this.viewModel.SaveLearningContentModeration(true)} />
                <Neo.Button hidden={this.viewModel.moderationStarted} text={this.viewModel.showModuleNotes ? "Module Notes" : "Hide Module Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.viewModel.showModuleNotes = !this.viewModel.showModuleNotes} />
              </CardBody>
            </Card>
          </div>
        </section>
        <div>
          <Card hidden={this.viewModel.hideModuleInfo}>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`fa fa-book fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">Module Learning Objects</h4>
            </CardHeader>
            <CardBody>
              <NeoGrid.Grid items={this.viewModel.learningObjectList} initialSort="order" >
                {(learningObject, learningObjectMeta) => (
                  <NeoGrid.RowGroup expandProperty={learningObjectMeta.isExpanded}>
                    <NeoGrid.Row className={this.viewModel.selectedlearningObjectId === learningObject.learningObjectId ? "selected-item-css" : "bg-none"}>
                      <NeoGrid.Column label="Title" display={learningObject.learningObjectTitle!.meta.value} />
                      <NeoGrid.Column label="Purpose" display={learningObject.learningObjectPurpose!.meta.value} />
                      <NeoGrid.Column label="Object Type" display={learningObjectMeta.learningObjectTypeId} select={{
                        itemSource: Data.StaticDataSource.fromEnum(LearningObjectType),
                        renderAsText: true
                      }} />
                      <NeoGrid.ButtonColumn>
                        <Neo.Button icon="select" isOutline variant="success" onClick={() => this.viewModel.setSelectedLearningObject(learningObject)}>
                          Select
                        </Neo.Button>
                      </NeoGrid.ButtonColumn>
                    </NeoGrid.Row>
                    <NeoGrid.ChildRow>
                      {learningObject.learningObjectTypeId === LearningObjectType.Information &&
                        <NeoGrid.Grid items={learningObject.learningContent}>
                          {(content, contentMeta) => (
                            <NeoGrid.Row key={content.learningContent.title} className={this.viewModel.selectedLearningContentId === content.learningContentId ? "selected-item-css" : "bg-none"}>
                              <NeoGrid.Column label="Title" display={content.learningContent.meta.title} />
                              <NeoGrid.Column display={content.learningContent.mediaObject?.meta.fileName} />
                              <NeoGrid.Column label="Media Type" display={content.meta.mediaTypeFetched}
                                select={{
                                  itemSource: Data.StaticDataSource.fromEnum(LearningContentMediaType),
                                  renderAsText: true
                                }}
                              />
                              <NeoGrid.Column label="File Type" display={content.learningContent.mediaObject?.meta.extension} />
                              <NeoGrid.ButtonColumn>
                                <Neo.Button isOutline disabled={this.viewModel.selectedlearningObjectId !== content.learningObjectId} variant="success" onClick={() => { this.viewModel.SetSelectedContent(content) }} >
                                  Select
                                </Neo.Button>
                              </NeoGrid.ButtonColumn>
                              <NeoGrid.ButtonColumn>
                                <Neo.Button hidden={content.learningContent.mediaObject === null || content.learningContent.mediaObject.fileDescriptorId === null} text={"Download"} isOutline icon={content.learningContent.mediaObject?.icon} variant="info"
                                  onClick={() => this.viewModel.DownloadFileGet(content.learningContent.mediaObject?.fileDescriptorId as string, content.mediaTypeFetched as number, content.learningContent.externalResourceLocation as string)} />
                                <Neo.Button text="Open link" hidden={content.learningContent.mediaObject?.fileDescriptorId !== null} onClick={() => OpenLink(content.learningContent.externalResourceLocation)} />

                                <Neo.Button tooltip={content.learningContent.externalResourceLocation?.toString()} hidden={content.mediaTypeFetched !== LearningContentMediaType.Link} text={"Open link"} isOutline icon="link" variant="info"
                                  onClick={() => OpenLink(content.learningContent.externalResourceLocation as string)} />
                                <Neo.Button tooltip={content.learningContent.externalResourceLocationKey?.toString()} hidden={content.mediaTypeFetched !== LearningContentMediaType.YouTube} text={"Open Youtube"} isOutline icon="link" variant="info"
                                  onClick={() => OpenLink(content.learningContent.externalResourceLocationKey as string)} />
                              </NeoGrid.ButtonColumn>
                            </NeoGrid.Row>
                          )}
                        </NeoGrid.Grid>
                      }
                    </NeoGrid.ChildRow>
                    {(learningObject.learningObjectTypeId === LearningObjectType.Quiz || learningObject.learningObjectTypeId === LearningObjectType.FinalQuiz) &&
                      <NeoGrid.ChildRow>
                        <NeoGrid.Grid items={learningObject.quizQuestions} initialSortAscending={true} initialSort={"questionOrder"}>
                          {(question, questionMeta) => (
                            <NeoGrid.Row className={this.viewModel.selectedQuestionId === question.questionId ? "selected-item-css" : "bg-none"}>
                              <NeoGrid.Column label="Question Id" hidden={true} display={questionMeta.questionId} />
                              <NeoGrid.Column label="Question" >
                                <div className={this.viewModel.selectedQuestionId === question.questionId ? "selected-item-box" : "bg-none"}>
                                  <RichTextEditor bind={questionMeta.text} readOnly />
                                </div>
                              </NeoGrid.Column>
                              <NeoGrid.Column label="Question Type" display={questionMeta.questionType} />
                              <NeoGrid.Column label="Question Order" width="150px" display={questionMeta.questionOrder} />
                              <NeoGrid.Column label="Link To Resource" width="150px" display={questionMeta.linkedToResource} />
                              <NeoGrid.ButtonColumn>
                                <Neo.Button isOutline disabled={this.viewModel.selectedlearningObjectId !== question.learningObjectId} variant="success" onClick={() => { this.viewModel.setSelectedQuestion(question.questionId) }} >
                                  Select
                                </Neo.Button>
                              </NeoGrid.ButtonColumn>
                            </NeoGrid.Row>
                          )}
                        </NeoGrid.Grid>
                      </NeoGrid.ChildRow>
                    }
                  </NeoGrid.RowGroup>
                )}
              </NeoGrid.Grid>
            </CardBody>
          </Card>
        </div>

        <section>
          <div>
            <Card hidden={this.viewModel.hideLOCard}>
              <CardHeader icon>
                <CardIcon color="success">
                  <i className={`fas fa-plus fa-2x`}></i>
                </CardIcon>
                <h4 className="Card-icon-header-text">{`Learning Object: ${this.viewModel.selectedLearningObject.learningObjectTitle!.value}`}</h4>
              </CardHeader>
              <CardBody>
                <Neo.Form>
                  <GridContainer>
                    <GridItem md={6} sm={12} xs={12}>
                      <Neo.FormGroupFloating label="Learning Object Type" display={this.viewModel.selectedLearningObject.meta.learningObjectTypeId} select={{ itemSource: Data.StaticDataSource.fromEnum(LearningObjectType), }} /><br />
                    </GridItem>
                    <GridItem md={6}>
                    </GridItem>
                    <GridItem md={6} sm={12} xs={12}>
                      <Neo.FormGroupFloating label="Title" formNoValidate input={{ rows: 1 }} bind={this.viewModel.selectedLearningObject.learningObjectTitle!.meta.value} readOnly={this.viewModel.moderationStarted} /><br />
                    </GridItem>
                    <GridItem md={4}>
                    </GridItem>
                    <GridItem md={2} sm={12} xs={12}>
                      <Neo.FormGroup label="Approve Title?" hidden={this.viewModel.moderationStarted} className="mt-2" bind={this.viewModel.selectedLearningObject.learningObjectTitle!.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveLearningContentModeration(false)} autoFocus /><b />
                    </GridItem>
                    <GridItem md={6} sm={12} xs={12}>
                      <Neo.FormGroupFloating label="Purpose" formNoValidate input={{ rows: 2 }} bind={this.viewModel.selectedLearningObject.learningObjectPurpose!.meta.value} readOnly={this.viewModel.moderationStarted} /><br />
                    </GridItem>
                    <GridItem md={4}>
                    </GridItem>
                    <GridItem md={2} sm={12} xs={12}>
                      <Neo.FormGroup label="Approve Purpose?" hidden={this.viewModel.moderationStarted} className="mt-2" bind={this.viewModel.selectedLearningObject.learningObjectPurpose!.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveLearningContentModeration(false)} autoFocus /><b />
                    </GridItem>
                    <GridItem md={10} sm={12} xs={12}>
                      <div hidden={this.viewModel.hideLOCard}>
                        <RichTextEditor key="Modules" readOnly={this.viewModel.moderationStarted} bind={this.viewModel.selectedLearningObject.learningObjectText!.meta.value}
                          htmlContent={this.viewModel.meta.curLearningObjectTextHtml}
                          onChange={() => { this.viewModel.selectedLearningObject.learningObjectTextHtml = this.viewModel.curLearningObjectTextHtml }} labelText="Rich Text" />
                      </div>
                    </GridItem>
                    <GridItem md={2} sm={12} xs={12}>
                      <Neo.FormGroup label="Approve Rich Text?" hidden={this.viewModel.moderationStarted} className="mt-7" bind={this.viewModel.selectedLearningObject.learningObjectText!.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveLearningContentModeration(false)} autoFocus /><b />
                    </GridItem>
                    <NeoGrid.Grid items={this.viewModel.loModerateableTag3List} hidden={this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Quiz || this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz}>
                      {(tag3, tag3Meta) => (
                        <NeoGrid.Row>
                          <NeoGrid.Column label="Tag3" width={"72%"} display={tag3Meta.tag3Name} />
                          <NeoGrid.Column label="Approve?" hidden={this.viewModel.moderationStarted} bind={tag3Meta.ApprovedByModerator} width={"30%"} onChange={() => this.viewModel.SaveLearningContentModeration(false)} />
                        </NeoGrid.Row>
                      )}
                    </NeoGrid.Grid>
                  </GridContainer>
                  {
                    !this.viewModel.showObjectNotes ||
                    <Neo.Card className="moderation-notes">
                      <RichTextEditor onBlur={() => this.viewModel.SaveLearningContentModeration(true)} bind={this.viewModel.selectedLearningObject.meta.moderationNotes} labelText="Learning Object Moderation Notes" />
                    </Neo.Card>
                  }

                  <Neo.Button hidden={this.viewModel.moderationStarted} text="Save Moderation Progress" isOutline className="mt-2" variant="success" icon="save" onClick={() => this.viewModel.SaveLearningContentModeration(true)} />
                  <Neo.Button hidden={this.viewModel.moderationStarted} text={!this.viewModel.showObjectNotes ? "Learning Object Notes" : "Hide Learning Object Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.viewModel.showObjectNotes = !this.viewModel.showObjectNotes} />
                </Neo.Form>
              </CardBody>
            </Card>
          </div>
        </section>
        <section>
          {this.viewModel.selectedLearningContentId === 0 ||
            <ModeratableContentFileComponent fileDescriptorList={this.viewModel.fileDescriptorList} learningContent={this.viewModel.selectedLearningContent} hidden={false}
              onSave={() => this.viewModel.SaveLearningContentModeration(true)} moderationState={this.viewModel.moderationStarted} />}
        </section>
        <div>
          <Card hidden={this.viewModel.hideQuestionInfo}>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`fas fa-question fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">Question Info</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem md={10} sm={12} xs={12}>
                  <div hidden={this.viewModel.hideQuestionInfo}>
                    {this.viewModel.hideQuestionInfo ||
                      <RichTextEditor onBlur={() => this.viewModel.SaveLearningContentModeration(true)} key="Question Text" bind={this.viewModel.selectedQuestion.question.meta.value}
                        labelText="Question" readOnly={this.viewModel.moderationStarted} htmlContent={this.viewModel.meta.curLearningQuestionTextHtml}
                        onChange={() => { this.viewModel.selectedQuestion.textHtml = this.viewModel.curLearningQuestionTextHtml }} />
                    }
                  </div>
                </GridItem>
                <GridItem md={2} sm={12} xs={12}>
                  <Neo.FormGroup hidden={this.viewModel.moderationStarted} className="mt-7" label="Approve Question?" bind={this.viewModel.selectedQuestion.question!.meta.ApprovedByModerator} onChange={() => this.viewModel.SaveLearningContentModeration(false)} autoFocus /><b />
                </GridItem>
                <GridItem md={6} sm={12} xs={12}>
                  <Neo.FormGroupFloating display={this.viewModel.selectedQuestion.meta.questionTypeId}
                    select={{
                      itemSource: Data.StaticDataSource.fromEnum(QuestionType),
                      renderAsText: true
                    }}
                  />
                </GridItem>
                <GridItem md={12} sm={12} xs={12}>
                  <NeoGrid.Grid className="mb-20 mt-20" items={this.viewModel.selectedQuestion.optionList}>
                    {(option, OptionMeta) => (
                      <NeoGrid.Row>
                        <NeoGrid.Column label="Option" width="400px" placeholder="Option" display={OptionMeta.option} />
                        <NeoGrid.Column display={OptionMeta.correctAnswerInd} width="150px" disabled={false} />
                      </NeoGrid.Row>
                    )}
                  </NeoGrid.Grid>
                </GridItem>

                <Neo.Card className="moderation-notes" hidden={this.viewModel.showQuestionNotes}>
                  {this.viewModel.selectedQuestion.moderation === null ||
                    <RichTextEditor key="Question Notes" onBlur={() => this.viewModel.SaveLearningContentModeration(true)} bind={this.viewModel.selectedQuestion.moderation!.meta.moderationNotes} labelText="Question Moderation Notes" />
                  }
                </Neo.Card>

              </GridContainer>
              <Neo.Button hidden={this.viewModel.moderationStarted} text="Save Moderation Progress" isOutline className="mt-2" variant="success" icon="save" onClick={() => this.viewModel.SaveLearningContentModeration(true)} />
              <Neo.Button hidden={this.viewModel.moderationStarted} text={this.viewModel.showQuestionNotes ? "Question Notes" : "Hide Question Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.viewModel.showQuestionNotes = !this.viewModel.showQuestionNotes} />

              {
                /// Question content
                !this.viewModel.showQuestionContent ||
                <div>
                  <Card>
                    <CardHeader icon>
                      <CardIcon color="success">
                        <i className={`fas fa-question fa-2x`} />
                      </CardIcon>
                      <h4 className="Card-icon-header-text">Question Content</h4>
                    </CardHeader>
                    <CardBody>
                      <Neo.Form >
                        <GridContainer>
                          <GridItem md={6} sm={12} xs={12}>
                            <Neo.FormGroupFloating bind={this.viewModel.selectedQuestion.questionContent.learningContent.meta.title} readOnly={this.viewModel.moderationStarted} /><b />
                          </GridItem>
                          <GridItem md={6}>
                          </GridItem>
                          <GridItem md={6} sm={12} xs={12}>
                            <Neo.FormGroupFloating display={this.viewModel.selectedQuestion.questionContent.meta.mediaTypeFetched}
                              select={{
                                itemSource: Data.StaticDataSource.fromEnum(LearningContentMediaType),
                                renderAsText: true
                              }}
                            />
                          </GridItem>
                          <GridItem md={6}>
                          </GridItem>
                          <GridItem md={6} sm={12} xs={12}>
                            <Neo.FormGroupFloating disabled={true} label="Alternative URL" placeholder="www.urlExample.com" display={this.viewModel.selectedQuestion.questionContent.learningContent.meta.externalResourceLocation}
                              hidden={this.viewModel.selectedQuestion.questionContent.learningContent.mediaType === LearningContentMediaType.YouTube
                              } />
                          </GridItem>
                          <GridItem md={6}>
                          </GridItem>
                          <GridItem md={6} sm={12} xs={12}>
                            <Neo.FormGroupFloating disabled={true} label="URL Key" display={this.viewModel.selectedQuestion.questionContent.learningContent.meta.externalResourceLocationKey} hidden={this.viewModel.selectedQuestion.questionContent.learningContent.mediaType !== LearningContentMediaType.YouTube} /><b />
                          </GridItem>
                          <GridItem md={6}>
                          </GridItem>
                          <GridItem md={12}>
                            <Neo.Button hidden={this.viewModel.selectedQuestion.questionContent.learningContent.mediaObject === null || this.viewModel.selectedQuestion.questionContent.learningContent.mediaObject.fileDescriptorId === null} text={"Download"} isOutline icon={this.viewModel.selectedQuestion.questionContent.learningContent.mediaObject?.icon} variant="info"
                              onClick={() => this.viewModel.DownloadFileGet(this.viewModel.selectedQuestion.questionContent.learningContent.mediaObject?.fileDescriptorId as string, this.viewModel.selectedQuestion.questionContent.mediaTypeFetched as number, this.viewModel.selectedQuestion.questionContent.learningContent.externalResourceLocation as string)} />
                            <Neo.Button text="Open link" hidden={this.viewModel.selectedQuestion.questionContent.learningContent.mediaObject?.fileDescriptorId !== null}
                              onClick={() => OpenLink(this.viewModel.selectedQuestion.questionContent.learningContent.externalResourceLocation)} />

                          </GridItem>
                          <GridItem md={12}>
                            <Neo.Button hidden={this.viewModel.selectedQuestion.questionContent.mediaTypeFetched !== LearningContentMediaType.Link} text={"Open link"} isOutline icon="link" variant="info"
                              onClick={() => OpenLink(this.viewModel.selectedQuestion.questionContent.learningContent.externalResourceLocation as string)} />
                          </GridItem>
                          <GridItem md={12}>
                            <Neo.Button tooltip="Click to open link in new window" hidden={this.viewModel.selectedQuestion.questionContent.learningContent.mediaType !== LearningContentMediaType.YouTube}
                              isOutline icon="link" variant="info" text="Open link" onClick={() => OpenLink(this.viewModel.selectedQuestion.questionContent.learningContent.externalResourceLocationKey as string)} />
                          </GridItem>
                          <GridItem md={6} sm={12} xs={12}>
                            <NeoGrid.Grid className="mb-10 mt-10" items={this.viewModel.fileDescriptorList}>
                              {(file, fileMeta) => (
                                <NeoGrid.Row>
                                  <NeoGrid.Column label="File Name" display={fileMeta.fileName} />
                                  <NeoGrid.Column label="File Type" display={fileMeta.mimeType} />
                                </NeoGrid.Row>
                              )}
                            </NeoGrid.Grid>
                          </GridItem>
                          <GridItem md={4}>
                          </GridItem>
                          <GridItem md={2} sm={12} xs={12}>
                            <Neo.FormGroup hidden={this.viewModel.moderationStarted} className="mt-2" label="Approve Content?" bind={this.viewModel.selectedQuestion.questionContent.learningContent.moderation.meta.approvedByModerator} onChange={() => this.viewModel.SaveLearningContentModeration(false)} />
                          </GridItem>
                          <GridItem md={12}>
                            {
                              this.viewModel.showQuestionContentNotes ||
                              <Neo.Card className="moderation-notes">
                                <RichTextEditor key="Content Question notes" onBlur={() => this.viewModel.SaveLearningContentModeration(true)} bind={this.viewModel.selectedQuestion.questionContent.learningContent.moderation.meta.moderationNotes} viewModel={this.viewModel.richTextEditorVmQuestionContentNotes} labelText="Question Content Moderation Notes" />
                              </Neo.Card>
                            }
                          </GridItem>

                          <Neo.Button hidden={this.viewModel.moderationStarted} text="Save Moderation Progress" isOutline className="mt-2" variant="success" icon="save" onClick={() => this.viewModel.SaveLearningContentModeration(true)} />
                          <Neo.Button text={this.viewModel.showQuestionContentNotes ? "Question Content Notes" : "Hide Question Content Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.viewModel.showQuestionContentNotes = !this.viewModel.showQuestionContentNotes} />
                        </GridContainer>
                      </Neo.Form>

                    </CardBody>
                  </Card>
                </div>
              }
            </CardBody>
          </Card>
        </div>

        {/* Modals */}
        <Neo.Modal title="Start Moderation - Please Confirm" size="xl"
          show={this.viewModel.showAcceptModal}
          onClose={() => this.viewModel.showAcceptModal = false}
          acceptButton={{
            text: "Accept", variant: "info", icon: "share",
            onClick: () => this.viewModel.AcceptModuleForModeration()
          }}
          closeButton={{
            text: "Close", variant: "secondary", icon: "times", onClick: () => this.viewModel.DeclineModuleForModeration()
          }} >
          <Neo.Loader task={this.viewModel.taskRunner}>
            <div>
              <p>By clicking "Accept" you: <br />
                • Accept Responsibility to Start and Complete the Moderation Process of this Module<br />
                • Accept Responsibility for confirmation of the CPTD points for this Module</p>
            </div>
          </Neo.Loader>
        </Neo.Modal>

        <ForumDrawer show={this.viewModel.showModuleReviewSummaryModal} onClose={() => this.viewModel.showModuleReviewSummaryModal = false} isMaximised={this.viewModel.reviewIsMaximised}
          maximiseWindow={() => this.viewModel.reviewIsMaximised = !this.viewModel.reviewIsMaximised}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <i className={`fa fa-info fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">Review Summary for: {this.viewModel.summaryLookup.moduleTitle}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  {/* The review summary for module */}
                  <Card hidden={this.viewModel.summaryLookup.moduleIsComplete}>
                    <CardHeader icon>
                      <CardIcon color="danger">
                        <i className={`fa fa-clipboard fa-2x`}></i>
                      </CardIcon>
                      <h4 className="Card-icon-header-text"> {this.viewModel.summaryLookup.moduleTitle}</h4>
                    </CardHeader>
                    <CardBody>
                      <h4>The Following properties are not approved on your module:</h4>
                      <ul>
                        {this.viewModel.summaryLookup.moduleTitleApproved || <li style={{ fontSize: "20px" }}>Title</li>}
                        {this.viewModel.summaryLookup.moduleCPTDPointsApproved || <li style={{ fontSize: "20px" }}>CPTD Points</li>}
                        {this.viewModel.summaryLookup.moduleLearningObjectiveApproved || <li style={{ fontSize: "20px" }}>Learning objective</li>}
                      </ul>
                      <div hidden={this.viewModel.summaryLookup.moduleNotes.length === 0}>
                        <RichTextEditor key="Module notes" bind={this.viewModel.summaryLookup.meta.moduleNotes} readOnly={true} labelText="Module notes" />
                      </div>
                      {/* Module Tags */}
                      <GridItem md={12}>
                        <Card hidden={this.viewModel.summaryLookup.allTagsApproved}>
                          <CardHeader icon>
                            <CardIcon color="danger">
                              <i className={`fa fa-tags fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Review Module Tags</h4>
                          </CardHeader>
                          <CardBody>
                            <h4>The Following tags are not approved on your module:</h4>
                            <ul>
                              {
                                this.viewModel.summaryLookup.moduleTags.map((tag) => (
                                  <li key={tag.tag1Id} style={{ fontSize: "20px" }} hidden={tag.approved}>{tag.errorMessage} </li>
                                ))
                              }
                            </ul>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </CardBody>
                  </Card>
                </GridItem>
                {/* Module Objects */}
                <GridItem md={12}>
                  {
                    <Card hidden={this.viewModel.summaryLookup.allObjectsApproved}>
                      <CardHeader icon>
                        <CardIcon color="primary">
                          <i className={`fa fa-info fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Review Learning Objects for Module</h4>
                      </CardHeader>
                      <CardBody>
                        {
                          this.viewModel.summaryLookup.objectList.map((object) => (
                            <Card hidden={object.objectIsCompleted} key={object.objectId}>
                              <CardHeader icon>
                                <CardIcon color="warning">
                                  <i className={`fa fa-eye fa-2x`} />
                                </CardIcon>
                                <h4 className="Card-icon-header-text">{object.title}</h4>
                              </CardHeader>
                              <CardBody> {(object.titleApproved && object.purposeApproved && object.richTextApproved) || <h4>The Following properties are not approved on this Object:</h4>}
                                <ul>
                                  {object.titleApproved || <li style={{ fontSize: "20px" }}>Title</li>}
                                  {object.purposeApproved || <li style={{ fontSize: "20px" }}>Purpose</li>}
                                  {object.richTextApproved || <li style={{ fontSize: "20px" }}>Object Rich Text</li>}
                                </ul>
                                <div hidden={object.objectNotes.length === 0}>
                                  <RichTextEditor bind={object.meta.objectNotes} readOnly={true} labelText="Object Notes" />
                                </div>

                                <GridItem md={12}>
                                  <Card hidden={object.allObjectTagsApproved}>
                                    <CardHeader icon>
                                      <CardIcon color="danger">
                                        <i className={`fa fa-tags fa-2x`}></i>
                                      </CardIcon>
                                      <h4 className="Card-icon-header-text">Review Object Tags</h4>
                                    </CardHeader>
                                    <CardBody>
                                      <h4>The following tags are not approved on your learning object:</h4>
                                      <ul>
                                        {
                                          object.objectTags.map((tag3) => (
                                            <li key={tag3.tag3Id} style={{ fontSize: "20px" }} hidden={tag3.approved}>{tag3.errorMessage} </li>
                                          ))
                                        }
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </GridItem>

                                <GridItem md={12}>
                                  <div hidden={object.allContentApproved}>
                                    <Card hidden={object.objectTypeId !== LearningObjectType.Information}>
                                      <CardHeader icon>
                                        <CardIcon color="danger">
                                          <i className={`fa fa-tags fa-2x`}></i>
                                        </CardIcon>
                                        <h4 className="Card-icon-header-text">Review Learning Object Content</h4>
                                      </CardHeader>
                                      <CardBody>
                                        <h4>The following content is not approved on your learning object:</h4>
                                        <ul>
                                          {
                                            object.contentList.map((content) => (
                                              <div key={content.contentId}>
                                                <div hidden={content.contentNotes.length === 0}>
                                                  <RichTextEditor bind={content.meta.contentNotes} readOnly={true} labelText="Content Notes" />
                                                </div>
                                                <div>
                                                  <li key={content.contentId} style={{ fontSize: "20px" }} hidden={content.approved}>{content.contentName} </li>
                                                </div>
                                              </div>
                                            ))
                                          }
                                        </ul>
                                      </CardBody>
                                    </Card>
                                  </div>
                                </GridItem>
                                <GridItem md={12}>
                                  <Card hidden={object.objectTypeId === LearningObjectType.Information}>
                                    <CardHeader icon>
                                      <CardIcon color="danger">
                                        <i className={`fa fa-tags fa-2x`}></i>
                                      </CardIcon>
                                      <h4 className="Card-icon-header-text">Review Learning Object Quiz Content</h4>
                                    </CardHeader>
                                    <CardBody>
                                      <h4>The following content is not approved on your learning object: </h4>
                                      <ul>
                                        {
                                          <div key={object.quizContent.contentId}>
                                            <div hidden={object.quizContent.contentNotes.length === 0}>
                                              <RichTextEditor bind={object.quizContent.meta.contentNotes} readOnly={true} labelText="Content Notes" />
                                            </div>
                                            <div>
                                              <li key={object.quizContent.contentId} style={{ fontSize: "20px" }} hidden={object.quizContent.approved}>{object.quizContent.contentName} </li>
                                            </div>
                                          </div>
                                        }
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </GridItem>
                                <GridItem md={12}>
                                  <Card hidden={object.objectTypeId === LearningObjectType.Information} >
                                    <CardHeader icon>
                                      <CardIcon color="danger">
                                        <i className={`fa fa-tags fa-2x`}></i>
                                      </CardIcon>
                                      <h4 className="Card-icon-header-text">Review Questions</h4>
                                    </CardHeader>
                                    <CardBody>
                                      <h4>The Following Questions are not approved on your quiz:</h4>
                                      <ul>
                                        {
                                          object.questionList.map((questions) => (
                                            <div key={questions.questionId}>
                                              <div hidden={questions.questionNotes.length === 0}>
                                                <RichTextEditor bind={questions.meta.questionNotes} readOnly={true} labelText="Question Notes" />
                                              </div>
                                              <li key={questions.questionId} style={{ fontSize: "20px" }} hidden={questions.approved && !questions.questionContentApproved}>
                                                <RichTextEditor bind={questions.meta.question} readOnly={true} />
                                              </li>
                                              <li hidden={!questions.hasContent}>
                                                <h4>The Content on the this question is not approved </h4>
                                                <p>Content Title:  {questions.questionContent.contentName}</p>
                                              </li>
                                              <div hidden={questions.questionContent.meta.contentNotes.value === ""}>
                                                <RichTextEditor bind={questions.questionContent.meta.contentNotes} readOnly={true} labelText="Question content Notes" />
                                              </div>
                                            </div>
                                          ))
                                        }
                                      </ul>

                                    </CardBody>
                                  </Card>
                                </GridItem>
                              </CardBody>
                            </Card>
                          ))
                        }
                      </CardBody>
                    </Card>
                  }
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Neo.Button onClick={() => this.viewModel.SendModuleForReview()} variant="success" icon="share" text="Submit for Review" />
            </CardFooter>
          </Card>
        </ForumDrawer>

        <Neo.Modal title="Complete Moderation" size="xl"
          show={this.viewModel.showCompleteModeration}
          onClose={() => this.viewModel.showCompleteModeration = false}
          acceptButton={{
            text: "Complete Moderation", variant: "info", icon: "share",
            onClick: () => this.viewModel.CompleteModeration()
          }}
          closeButton={{
            text: "Cancel", variant: "secondary", icon: "times", onClick: () => this.viewModel.showCompleteModeration = false
          }} >
          <Neo.Loader task={this.viewModel.taskRunner}>
            <h5>This Module and all its content is approved.Would you like to complete moderation and mark module as fully moderated?</h5>
          </Neo.Loader>
        </Neo.Modal>

        <FabButton icon="eye" tooltip="Click to view moderation summary" hidden={this.viewModel.selectedLearningModuleId === 0} onClick={() => this.viewModel.CheckModerationState(this.viewModel.selectedLearningModuleId)} />

      </div >
    );
  }
}