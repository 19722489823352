import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import { ModerationState } from '../LearningObjects/ModerationState.enum';

@NeoModel
export default class LearningObjectModerateableTagLookup extends ModelBase {

  public learningObjectId: number = 0;

  public learningObjectTag3Id: number = 0;

  public tag3Id: number = 0;

  public tag3Name: string = "";

  public fieldState: ModerationState = ModerationState.Unmoderated;

  public ApprovedByModerator: boolean = false;
}