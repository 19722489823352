import { Data, List, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService } from "../../../../App/Services/AppService";
import Types from '../../../../App/AppTypes';
import School from "../../../Models/Organisations/School";
import { SchoolImportModalVM } from '../../../Components/SchoolImportModal'
import SchoolSearchCriteria from "../../../Models/Criteria/SchoolSearchCriteria";
import SchoolLookup from "../../../Models/Lookups/SchoolLookup";

@NeoModel
export default class SchoolVM2 extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private schoolApiClient = AppService.get(Types.Organisations.ApiClients.SchoolApiClient),
        private circuitApiClient = AppService.get(Types.Organisations.ApiClients.CircuitApiClient),
        private schoolQueryApiClient = AppService.get(Types.Organisations.ApiClients.SchoolQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    public async setCrit(schoolGroupStartName: string, schoolGroupEndName: string, schoolGroupStartId: number, schoolGroupEndId: number, circuitId: number){
        this.criteria.schoolGroupStartName = schoolGroupStartName;
        this.criteria.schoolGroupEndName = schoolGroupEndName;
        this.schoolGroupStartId = schoolGroupStartId;
        this.schoolGroupEndId = schoolGroupEndId;
        this.criteria.circuitId = circuitId;
        this.LoadSchoolList();
    }

    public schoolList = new List(SchoolLookup);
    public schoolImportModalVM!: SchoolImportModalVM
    public selectedSchoolId: number = 0;
    public selectedSchool = new School();
    public circuitList = new Data.ApiClientDataSource(this.circuitApiClient.get)
    public criteria = new SchoolSearchCriteria();
    public showEditModal: boolean = false;
    public showConfirmDeleteModal: boolean = false;
    public schoolGroupStartId: number = 0;
    public schoolGroupEndId: number = 0;

    public async LoadSchoolList() {
        const getGroupedSchoolListResponse = await this.taskRunner.waitFor(this.schoolQueryApiClient.getGroupedSchoolList(this.criteria));
        if (getGroupedSchoolListResponse.data !== null) {
            this.schoolList.set(getGroupedSchoolListResponse.data);
        }
    }

    public async showSchoolEditModal(schoolId: number) {
        if (schoolId !== 0) {
            this.circuitList = new Data.ApiClientDataSource(this.circuitApiClient.get);
            this.selectedSchoolId = schoolId
            const response = await this.taskRunner.waitFor(this.schoolApiClient.getById(schoolId));
            this.selectedSchool.set(response.data);
        }
        this.circuitList = new Data.ApiClientDataSource(this.circuitApiClient.get);
        this.showEditModal = true;
    }

    public async closeEditModal() {
        this.selectedSchool = new School();
        this.showEditModal = false;
    }

    public async showDeleteModal(schoolId: number) {
        this.selectedSchoolId = schoolId;
        this.showConfirmDeleteModal = true
    }

    public async saveSchool() {
        this.taskRunner.run(async () => {
            this.showEditModal = false
            const school = this.selectedSchool
            if (this.selectedSchoolId !== 0) {
                await this.schoolApiClient.put(school.schoolId, school.toQueryObject());
            } else {
                await this.schoolApiClient.post(school.toQueryObject());
            }
            this.selectedSchoolId = 0;
            this.selectedSchool = new School();
            // if we are making changes to the first or last schools in a group, we need to reset the criteria else it will fetch the wrong info. 
            if (school.schoolId === this.schoolGroupStartId) {
                this.criteria.schoolGroupStartName = school.schoolName;
            }
            else if (school.schoolId === this.schoolGroupEndId) {
                this.criteria.schoolGroupEndName = school.schoolName;
            }
            this.LoadSchoolList();
            this.notifications.addSuccess("School saved", "School saved successfully", 4);
        })
    }

    public async deleteSchool() {
        this.taskRunner.run(async () => {
            this.showConfirmDeleteModal = false;
            await this.schoolApiClient.delete(this.selectedSchoolId);
            this.LoadSchoolList();
            this.notifications.addSuccess("School Deleted", "School Deleted", 4);
        })
    }
}