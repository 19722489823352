import { NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { AppService, Types } from "../IdentityTypes";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
import { AxiosResponse } from "axios";
import IntroductionContentLookup from "../Models/Lookups/IntroductionContentLookup";

interface IIntroductionAdminComponentProps {
}

@NeoModel
export class IntroductionAdminComponentVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private introductionAdminApiClient = AppService.get(Types.Identity.ApiClients.IntroductionAdminApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        protected navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
        super(taskRunner);
    }

    //Properties    
    public introductionObject: IntroductionContentLookup = new IntroductionContentLookup();
    public hasAccepted: boolean = false;

    //Methods
    public async initialise() {
        await this.taskRunner.run(async () => {
            var resp = await this.introductionAdminApiClient.get();
            if (this.isValidResponse(resp)) {
                this.introductionObject.set(resp.data);                
            }
        });
    }

    private isValidResponse(response: AxiosResponse): boolean {
        var result = false;
        if (response.status === 200) {
            result = true;
        }
        return result;
    }

    public async update() {
        await this.taskRunner.run(async () => {
            this.introductionObject.createdOn = new Date();
            var resp = await this.introductionAdminApiClient.update(this.introductionObject.toJSObject());
            if (this.isValidResponse(resp)) {
                this.notifications.addSuccess(`Introduction Updated!`, null, NotificationDuration.Standard);
                if (!resp.data) {
                    this.introductionObject.set(resp.data);
                }
            } else {
                this.notifications.addDanger(`Introduction Could not be updated due to technical error!`, null, NotificationDuration.Standard);
            }
        });
    }
}

@observer
export default class IntroductionAdminComponent extends React.Component<IIntroductionAdminComponentProps>{

    private viewModel: IntroductionAdminComponentVM = new IntroductionAdminComponentVM();

    constructor(props: IIntroductionAdminComponentProps) {
        super(props);
        this.viewModel = new IntroductionAdminComponentVM();
    }

    public componentDidMount() {
        this.viewModel.initialise();
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fa fa-address-card fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Edit Terms and Conditions</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <label>The users will see this website embbeded in a IFRAME before they can proceed using the website. After you update
                                        this introduction <b>all users</b> will view the introduction again.</label>
                                </div>
                                <div>
                                    <Neo.Button variant="success" text="Update Introduction" onClick={() => this.viewModel.update()} />
                                </div>
                                <div>
                                    <Neo.FormGroup label="Date and time last modified" readOnly bind={this.viewModel.introductionObject.meta.createdOn} dateProps={{ formatString: "dd MMM yyyy HH:mm" }} />
                                    <Neo.FormGroup label="Introduction URL" bind={this.viewModel.introductionObject.meta.introductionUrl} />                                    
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
        );
    }
}