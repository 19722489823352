import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import Card from '../../../Template/components/Card/Card';
import CardHeader from '../../../Template/components/Card/CardHeader';
import CardIcon from '../../../Template/components/Card/CardIcon';
import CardBody from '../../../Template/components/Card/CardBody';
import SystemSettingsVM from './SystemSettingsVM';
import TermsAndConditionsAdminComponent from "../../Components/TermsAndConditionsAdminComponent";
import IntroductionAdminComponent from "../../Components/IntroductionAdminComponent";
import NotificationSettingComponent from '../../Components/SystemSettings/NotificationSetting';
import RaceComponent from '../../Components/RacesComponent';
import TitleComponent from '../../Components/TitlesComponent';

export enum SystemSettingsTypes {
    Introduction = "Start",
    TermsAndConditions = "Terms and Conditions",
    IntroductionMessage = "Introduction Settings",
    Notifications = "Notification Settings",
    Races = "Races",
    Titles = "Titles",
}

@observer
export default class SystemSettingsView extends Views.ViewBase<SystemSettingsVM> {

    constructor(props: unknown) {
        super("SystemSettings", SystemSettingsVM, props);
    }

    public render() {
        return (
            <div className="OrgMaintenance">
                <Neo.TabContainer selectedTab={this.viewModel.meta.selectedTab}>
                    <Neo.Tab header={SystemSettingsTypes.Introduction} icon="cogs">
                        <div className="pv-5">
                            <div>
                                <Card>
                                    <CardHeader icon>
                                        <CardIcon color="success">
                                            <i className={`fas fa-cogs fa-2x`}></i>
                                        </CardIcon>
                                        <h4 className="Card-icon-header-text">System Settings</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <p>Please choose a tab to navigate to.</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Neo.Tab>
                    <Neo.Tab header={SystemSettingsTypes.TermsAndConditions} icon="address-card">
                        <TermsAndConditionsAdminComponent />
                    </Neo.Tab>
                    <Neo.Tab header={SystemSettingsTypes.IntroductionMessage} icon="address-card">
                        <IntroductionAdminComponent />
                    </Neo.Tab>
                    <Neo.Tab header={SystemSettingsTypes.Notifications} icon="bell">
                        <NotificationSettingComponent />
                    </Neo.Tab>
                    <Neo.Tab header={SystemSettingsTypes.Races} icon="fa-user-circle" onInitialise={() => this.viewModel.raceVM.LoadRaceList()}>
                        <RaceComponent viewModel={this.viewModel.raceVM} />
                    </Neo.Tab>
                    <Neo.Tab header={SystemSettingsTypes.Titles} icon="female" onInitialise={() => this.viewModel.titleVM.LoadTitleList()}>
                        <TitleComponent viewModel={this.viewModel.titleVM} />
                    </Neo.Tab>
                </Neo.TabContainer>
            </div>
        );
    }
}