import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import ChangePasswordVM from './ChangePasswordVM';
import { observer } from 'mobx-react';
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import CardIcon from '../../Template/components/Card/CardIcon';
import CardBody from '../../Template/components/Card/CardBody';
import TDPUser from '../Models/Security/TDPUser';
import { AppService } from '../../App/Services/AppService';
import { Types } from '../../Identity/IdentityTypes';
import { UserTypes } from '../../App/Models/Enums/UserTypes.enums';

@observer
export default class ChangePasswordView extends Views.ViewBase<ChangePasswordVM> {
    private authService = AppService.get(Types.Neo.Security.AuthenticationService);

    constructor(props: unknown) {
        super("Change Password", ChangePasswordVM, props);
    }

    public render() {
        const user = this.authService.user as TDPUser;        
        return (
            <div>
                <Card>
                    <CardHeader icon>
                        <CardIcon color="success">
                            <i className={`fa fa-lock fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Manage Password</h4>
                    </CardHeader>
                    <CardBody>
                        <div className="row client-component-header-mb-5 ">
                            <div className="col-md-5">
                                <h2 className="h2-bold">Change Password</h2>
                            </div>
                            <div className="col-md-5">
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-3 col-xs-6">
                                <i className={`${"fa fa-user-lock"} ${"float-right"}`} />
                            </div>
                            <div className="col-md-4 col-xs-6">
                                <Neo.FormGroup hidden={user.userType === UserTypes.Unverified.toString()} bind={this.viewModel.passwordModel.meta.oldPassword} input={{ type: "password" }} />
                                <Neo.FormGroup hidden={user.userType === UserTypes.Unverified.toString()} bind={this.viewModel.passwordModel.meta.newPassword} input={{ type: "password" }} />
                                <Neo.FormGroup hidden={user.userType === UserTypes.Unverified.toString()} bind={this.viewModel.passwordModel.meta.newPasswordCofirm} input={{ type: "password" }} />
                                <Neo.Button text={"Change Password"} hidden={user.userType === UserTypes.Unverified.toString()} variant={"success"} icon={"check"} style={{ float: 'right' }}
                                    onClick={() => this.viewModel.changePassword()} />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}