import { List, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import Organisation from "../../../Models/Organisations/Organisation";
import { AppService } from "../../../../App/Services/AppService";
import Types from '../../../../App/AppTypes';
import SchoolGroupLookup from "../../../Models/Lookups/SchoolGroupLookup";
import District from "../../../Models/Organisations/District";
import Province from "../../../Models/Organisations/Province";
import Circuit from "../../../Models/Organisations/Circuit";
import { NotificationDuration } from "../../../../App/Models/Enums/NotificationDuration.enum";

@NeoModel
export default class OrganisationVM2 extends Views.ViewModelBase {

    public OrganistationList = new List(Organisation);
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private organisationApiClient = AppService.get(Types.Organisations.ApiClients.OrganisationQueryApiClient),
        private districtQueryApiClient = AppService.get(Types.Organisations.ApiClients.DistrictQueryApiClient),
        public provinceQueryApiClient = AppService.get(Types.Organisations.ApiClients.ProvinceQueryApiClient),
        public circuitQueryApiClient = AppService.get(Types.Organisations.ApiClients.CircuitQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    public groupedSchoolList = new List(SchoolGroupLookup);
    public showMoveProvince: boolean = false;
    public showMoveDistrict: boolean = false;

    public async initialise() {
        this.LoadOrganisationList();
    }

    public async LoadOrganisationList() {
        const getOrganisationListResponse = await this.taskRunner.waitFor(this.organisationApiClient.getOrganisations());
        if (getOrganisationListResponse.data !== null) {
            this.OrganistationList.set(getOrganisationListResponse.data);
        }
    }

    public async moveDistrictToProvince(district: District, province: Province, provinceList: Province[]) {
        //Save first
        this.districtQueryApiClient.updateDistrictProvince(district.districtId, district.moveToProvinceId);
        this.notifications.addSuccess("District Moved", null, NotificationDuration.Short);
        province.districts.removeWithoutTracking(district);
        //Find the new province, district.moveToProvinceId
        var ProvinceMove = provinceList.find(c => c.provinceId === district.moveToProvinceId)
        if (ProvinceMove) {
            ProvinceMove.districts.push(district);
        }
    }

    public async moveCircuitToDistrict(circuit: Circuit, district: District, districtList: District[]) {
        //Save first
        this.circuitQueryApiClient.UpdateCircuitDistrictId(circuit.circuitId, circuit.moveToDistrictId);
        this.notifications.addSuccess("Circuit Moved", null, NotificationDuration.Short);
        district.circuits.removeWithoutTracking(circuit);
        //Find the new district, circuit.districtId
        var DistrictMove = districtList.find(c => c.districtId === circuit.moveToDistrictId)
        if (DistrictMove) {
            DistrictMove.circuits.push(circuit);
        }
    }

    //leave here till we confirm if client it happy with proposed solution
    // public getProvinceList() {
    //     const provinces: Province[] = [];
    //     this.OrganistationList.forEach(o => provinces.push(...o.provinces));
    //     return provinces;
    // }

    // public getDistrictList(provinceList: District[]) {
    //     const provinces: Province[] = [];
    //     this.OrganistationList.forEach(o => provinces.push(...o.provinces));
    //     const districts: District[] = [];
    //     provinces.forEach(o => districts.push(...o.districts));
    //     return districts;
    // }
}