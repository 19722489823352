export enum Security {
}

export enum NoReportAccess {
    NoReports = "Reporting.No Report Access.No Reports",
}
/*
export enum ContentConsumption {
    ModulesCompletedPerTeacher = "Reporting.Content Consumption.Modules Completed Per Teacher",
    ModulesSelectedPerTeacher = "Reporting.Content Consumption.Modules Modules Selected Per Teacher",
    QuestionnairesCompleted = "Reporting.Content Consumption.Questionnaires Completed",
    WorkGroupsCreatedPerTeacher = "Reporting.Content Consumption.Work Groups Created Per Teacher",
    FinalQuiz = "Reporting.Content Consumption.Final Quiz",
    SelfAssessmentsReport = "Reporting.Content Consumption.Self Assessments Report",
    TeacherActivityEngagement = "Reporting.Content Consumption.Teacher Activity Engagement"
}

export enum ContentCreation {
    ModulesCreated = "Reporting.Content Creation.Modules Created",
}

export enum CPTDInformation {
    CPTDPoints = "Reporting.CPTD Information.CPTD Points",
}*/