import { Attributes, Misc, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import UserSelfAssessment from './UserSelfAssessment';
import Tag3 from '../Tags/Tag3';

@NeoModel
export default class UserSelfAssessmentQuestion extends ModelBase {

  public userSelfAssessmentQuestionId: number = 0;

  @Attributes.Serialisation(Misc.SerialiseType.NotNull)
  public userSelfAssessmentId: number | null = null;

  public userSelfAssessment: UserSelfAssessment = new UserSelfAssessment();

  public selfAssessmentQuestionId: number = 0;

  @Attributes.Serialisation(Misc.SerialiseType.NotNull)
  public tag3Id: number | null = 0;

  public tag3: Tag3 = new Tag3();

  public option: string = "";

  @Attributes.Integer()
  public optionValue: number = 0;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.option) {
      return "New user self assessment question";
    } else {
      return this.option;
    }
  }
}