import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { LearningObjectType } from '../LearningObjects/LearningObjectType.enum';
import QuizQuestionConsumptionLookup from './QuizQuestionConsumptionLookup';
import ContentConsumptionLookup from './ContentConsumptionLookup';

@NeoModel
export default class QuizConsumptionLookup extends ModelBase {

  public learningObjectId: number = 0;

  public title: string = "";

  public purpose: string = "";

  public text: string = "";

  public textHtml: string = "";

  public questions = new List(QuizQuestionConsumptionLookup);

  public contentList = new List(ContentConsumptionLookup);

  public learningObjectType: LearningObjectType = LearningObjectType.Information;

  @Attributes.Integer()
  public order: number = 0;

  public randomiseOrder: boolean = false;
  // Client only properties / methods

  @Attributes.NoTracking()
  public quizCompleted: boolean = false;

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.title) {
      return "New quiz consumption lookup";
    } else {
      return this.title;
    }
  }
}