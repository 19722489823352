import { Data, List, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../App/Services/AppService";
import SchoolSearchCriteria from "../Models/Criteria/SchoolSearchCriteria";
import SchoolLookup from "../Models/Lookups/SchoolLookup";
import { SchoolImportModalVM } from "./SchoolImportModal";
import District from "../Models/Organisations/District";
import Province from "../Models/Organisations/Province";
import Circuit from "../Models/Organisations/Circuit";
import Organisation from "../Models/Organisations/Organisation";

@NeoModel
export default class SchoolSearchComponentVM extends Views.ViewModelBase {
    //Properties
    public schoolImportModalVM!: SchoolImportModalVM
    public circuits = new List(Circuit);
    public districts = new List(District);
    public provinces = new List(Province);
    public organisations = new List(Organisation);

    //School Paging Manager
    public criteria = new SchoolSearchCriteria();
    public showEditModal: boolean = false;
    public showConfirmDeleteModal: boolean = false;
    public selectedSchool: SchoolLookup = new SchoolLookup();
    public hasSelectedSchool: boolean = false;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private schoolApiClient = AppService.get(Types.Organisations.ApiClients.SchoolApiClient),
        private circuitApiClient = AppService.get(Types.Organisations.ApiClients.CircuitApiClient),
        private districtApiClient = AppService.get(Types.Organisations.ApiClients.DistrictApiClient),
        private provinceApiClient = AppService.get(Types.Organisations.ApiClients.ProvinceApiClient),
        private organisationApiClient = AppService.get(Types.Organisations.ApiClients.OrganisationApiClient),
        private schoolQueryApiClient = AppService.get(Types.Organisations.ApiClients.SchoolQueryApiClient)) {
        super(taskRunner);
    }

    async initialise() {
        var results = await this.taskRunner.waitForAll({
            circuits: this.circuitApiClient.get(),
            districts: this.districtApiClient.get(),
            provinces: this.provinceApiClient.get(),
            organisations: this.organisationApiClient.get()
        });

        this.circuits.set(results.circuits.data);
        this.districts.set(results.districts.data);
        this.provinces.set(results.provinces.data);
        this.organisations.set(results.organisations.data);
        this.clearFilters();
    }

    public schoolLookupPageManager = new Data.PageManager(this.criteria, SchoolLookup, this.schoolQueryApiClient.getSchoolListPaged, {
        pageSize: 5,
        pageSizeOptions: [5],
        sortBy: "schoolName",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner,
    });

    public async SetCriteria(circuitId: number) {
        await this.taskRunner.run(async () => {
            if (circuitId !== 0) {
                this.criteria.circuitId = circuitId;
                this.schoolLookupPageManager.refreshData(true, this.taskRunner);
            } else {
                this.schoolLookupPageManager.refreshData();
            }
        });
    }

    public async clearFilters() {
        this.criteria.meta.circuitId.value = null;
        this.criteria.meta.schoolName.value = "";
        await this.taskRunner.run(async () => {
            this.schoolLookupPageManager.reset();
            this.schoolLookupPageManager.refreshData();
        })
    }
}