import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../LearningTypes';
import LearningPathwayImportResult from '../../Models/LearningPathways/LearningImport/LearningPathwayImportResult';

export interface ILearningPathwayImportApiClient {

    /** 
     * Uploads an excel file with participant data.
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @returns A file key to be passed into CheckImportFile().
     */
    getUploadLearningPathEmailFileUrl(): string;

    /** 
     * Processes an import for a previously uploaded file.
     * @param key Key returned from upload educator file.
     * @returns Import Result file.
     */
    doImport(key: string): AxiosPromise<Model.PlainObject<LearningPathwayImportResult>>;

    // Client only properties / methods
}

@injectable()
export default class LearningPathwayImportApiClient extends Data.ApiClientBase implements ILearningPathwayImportApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/LearningPathwayImport`);
    }

    public getUploadLearningPathEmailFileUrl(): string {
        return `${this.apiPath}/uploadFile`;
    }

    public doImport(key: string): AxiosPromise<Model.PlainObject<LearningPathwayImportResult>> {
        return this.axios.post(`${this.apiPath}/doImport/${encodeURIComponent(key)}`);
    }

    // Client only properties / methods
}