import { Attributes, List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import { OrganisationType } from '../Enums/OrganisationType.enum';
import ProvinceUsers from './ProvinceUsers';

@NeoModel
export default class OrganisationUsers extends ModelBase {

    public organisationId: number = 0;

    @Rules.Required("Organisation name is required")
    @Rules.StringLength(100)
    public organisationName: string = "";

    @Rules.StringLength(50)
    public registrationNumber: string = "";

    @Rules.StringLength(50)
    public contactEmail: string = "";

    public organtisationType: OrganisationType = 0;

    public provinces = new List(ProvinceUsers);

    public isExpanded: boolean = false    
    
    @Attributes.NoTracking()
    public usersCount: number = 0;

    @Attributes.NoTracking()
    public usersCountIncldChildren: number = 0;

    // Client only properties / methods
    public toString(): string {
        if (this.isNew){
            return "New Organisation"
        }
        else{
            return this.organisationName;
        }
    }

}