import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Province from '../../Models/Organisations/Province';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';

export interface IProvinceQueryApiClient {
    /**
     * Filter Province by OrganisationId
     * @param organisationId organisationId
     * @returns filterList
     */
    filterProvinceByOrganisation(organisationId: number): AxiosPromise<Model.PlainTrackedObject<Province>[]>;

    /**
     * This method will check if a specific province has linked districts, if so, we dont allow delete
     * @param provinceId The page request
     * @returns boolean
     */
    checkProvinceLinks(provinceId: number): AxiosPromise<boolean>;

    /** 
     * Will fetch a list of provinces
     * @returns A list of provinces
     */
    getProvinces(): AxiosPromise<Array<Model.PlainTrackedObject<Province>>>;

    // Client only properties / methods
}

@injectable()
export default class ProvinceQueryApiClient extends Data.ApiClientBase implements IProvinceQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/ProvinceQuery`);
    }

    public getProvinces(): AxiosPromise<Array<Model.PlainTrackedObject<Province>>> {
        return this.axios.get(`${this.apiPath}/Provinces`);
    }

    public filterProvinceByOrganisation(organisationId: number): AxiosPromise<Model.PlainTrackedObject<Province>[]> {
        return this.axios.get(`${this.apiPath}/FilterProvinceList/?organisationId=${organisationId}`);
    }

    public checkProvinceLinks(provinceId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/CheckProvinceLinks/${provinceId}`);
    }

}