import { AppService } from '../App/Services/AppService';
import { CommonTypes } from './CommonIdentityTypes';
import AppTypes from '../App/AppTypes';

// Merge the shared type symbols with this module's type symbols
const Types = {
    ...AppTypes,
    Identity: {
        ApiClients: {
            ...CommonTypes.ApiClients
        },
        Services: {
            ...CommonTypes.Services,
        },
        ViewModels: {
            ...CommonTypes.ViewModels,
        },
        Components: {
            ...CommonTypes.Components,
        }
    }
}

export { AppService, Types };