import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';
import { ModerationState } from '../LearningObjects/ModerationState.enum';
import Tag2 from '../Tags/Tag2';

@NeoModel
export default class LearningModuleTagLookup extends ModelBase {

    public learningModuleId: number = 0;

    @Required("Tag 1 is Required.")
    public tag1Id: number = 0;

    public tag1Name: string = "";

    @Required("Tag 2 is Required.")
    public tag2Id: number = 0;

    public tag2Name: string = "";

    public approved: boolean = false;

    public fieldState: ModerationState = ModerationState.Unmoderated

    // Client only properties / methods

    public filteredTag2List = new List(Tag2);
}