import { Attributes, NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class SchoolSearchCriteria extends ValueObject {

    public schoolId: number | null = null;

    public schoolName: string = "";

    public circuitId: number | null = null

    public ids: number[] | null = null;

    @Attributes.Integer()
    public pageIndex: number = 0;

    public keyword: string = "";

    @Attributes.Integer()

    public pageSize: number = 10;

    public schoolGroupStartName: string = "";

    public schoolGroupEndName: string = "";

    public schoolGroupStartId: number = 0;

    public schoolGroupEndId: number = 0;
    
    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "School Search Criteria";
    }
}