import { Attributes, ITaskRunner, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Identity/IdentityTypes';
import { ModeratableLongStringField, ModeratableShortStringField } from '../Moderation/ModeratableFieldValues';
import LearningObjectTag3 from '../Tags/LearningObjectTag3';
import LearningObjectContentLookup from './LearningObjectContentLookup';
import QuizQuestionLookup from './QuizQuestionLookup';
import { truncateLongString } from '../../../App/HelperClasses/GlobalHelpers';

@NeoModel
export default class LearningObjectModerationLookup extends ModelBase {

  constructor() {
    super()
    this.learningObjectTitle = new ModeratableLongStringField();
    this.learningObjectText = new ModeratableLongStringField();
    this.learningObjectPurpose = new ModeratableShortStringField();
  }
  public learningObjectId: number = 0;

  public learningModuleId: number = 0;

  public learningObjectTypeId: number = 0;

  @Attributes.ChildObject(ModeratableShortStringField)
  public learningObjectPurpose: ModeratableShortStringField | null = null;

  @Attributes.ChildObject(ModeratableLongStringField)
  public learningObjectTitle: ModeratableLongStringField | null = null;

  @Attributes.ChildObject(ModeratableLongStringField)
  public learningObjectText: ModeratableLongStringField | null = null;

  public learningObjectTextHtml: string = "";

  @Attributes.Integer()
  public order: number = 0;

  public learningContent = new List(LearningObjectContentLookup);

  public learningObjectTag3s = new List(LearningObjectTag3);

  public moderationNotes: string = "";

  // Client only properties / methods


  @Attributes.OnChanged<LearningObjectModerationLookup>(c => c.fetchLearningContent)
  public isExpanded: boolean = false;

  public quizQuestions = new List(QuizQuestionLookup);

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New learning object moderation lookup";
    } else {
      return "Learning Object Moderation Lookup";
    }
  }

  private async fetchLearningContent() {
    this.taskRunner.run(async () => {
      if (this.learningContent.length === 0) {
        const getlearningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentByObject(this.learningObjectId);
        if (getlearningContent.data !== null) {
          this.learningContent.set(getlearningContent.data);
        }
      }
      if (this.quizQuestions.length === 0) {
        const quizQuestion = await AppService.get(Types.Learning.ApiClients.QuizLearningObjectApiClient).GetQuizQuestions(this.learningObjectId);
        if (quizQuestion.data !== null) {
          this.quizQuestions.set(quizQuestion.data);
          if (quizQuestion.data !== null) {
            this.quizQuestions.set(quizQuestion.data);
            if (this.quizQuestions.length > 0) {
              this.quizQuestions.forEach(question => {
                question.plainText = truncateLongString(JSON.parse(question.text).blocks[0].text);
              });
            }
          }
        }
      }
    })
  }

  @Attributes.NoTracking()
  public taskRunner: ITaskRunner = AppService.get(Types.Neo.TaskRunner)
}