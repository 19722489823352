import { Attributes, NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class SACEEducatorSearchCriteria extends ValueObject {

    public educatorId: number | null = null;

    public firstname: string = "";

    public surname: string = "";

    public ids: number[] | null = null;

    @Attributes.Integer()
    public pageIndex: number = 0;

    public keyword: string = "";

    @Attributes.Integer()

    public pageSize: number = 10;
    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Educator Search Criteria";
    }
}