import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../ForumTypes';
import TopicLookup from '../../../Models/Lookups/TopicLookup';

export interface ITopicUpdateApiClient {

    /**
     * Creates a topic
     * @param topic Topic to create
     * @returns Topic
     */
    createTopicAsync(topic: Model.PartialPlainObject<TopicLookup>): AxiosPromise<any>;

    /**
     * Creates a topic
     * @param topic Topic to create
     * @returns Topic
     */
    updateTopicAsync(topic: Model.PartialPlainObject<TopicLookup>): AxiosPromise<any>;

    /**
     * Reports a topic
     * @param topic Topic to report
     * @returns IActionResult
     */
    reportTopicAsync(topic: Model.PartialPlainObject<TopicLookup>): AxiosPromise<any>;

    /**
     * Clears a report on a topic
     * @param topic Topic to clear report
     * @returns IActionResult
     */
    clearReportOnTopicAsync(topic: Model.PartialPlainObject<TopicLookup>): AxiosPromise<any>;

    /**
     * Deletes a topci with a reason
     * @param topic Topic to clear report
     * @param reason reason
     * @returns IActionResult
     */
    deleteTopicAsync(topic: Model.PartialPlainObject<TopicLookup>, reason: string): AxiosPromise<any>;

    /** 
     * Delete topic by id.
     * @param topicId topicId
     * @returns success ind.
     */
    deleteTopicByIdAsync(topicId: number): AxiosPromise<boolean>;
    // Client only properties / methods
}

@injectable()
export default class TopicUpdateApiClient extends Data.ApiClientBase implements ITopicUpdateApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.ForumApi.ApiPath}/TopicUpdate`);
    }

    public createTopicAsync(topic: Model.PartialPlainObject<TopicLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/CreateTopic`, topic);
    }

    public updateTopicAsync(topic: Model.PartialPlainObject<TopicLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/UpdateTopic`, topic);
    }

    public reportTopicAsync(topic: Model.PartialPlainObject<TopicLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/ReportTopic`, topic);
    }

    public clearReportOnTopicAsync(topic: Model.PartialPlainObject<TopicLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/ClearReportTopic`, topic);
    }

    public deleteTopicAsync(topic: Model.PartialPlainObject<TopicLookup>, reason: string): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/DeleteTopic`, topic);
    }

    public deleteTopicByIdAsync(topicId: number): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/DeleteTopicById/${topicId}`);
    }
    // Client only properties / methods
}