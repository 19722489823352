import { ValidationDisplayMode } from '@singularsystems/neo-core/dist/Validation/Misc';
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import UserModerationTag2Lookup from '../../Learning/Models/Lookups/UserModerationTag2Lookup';
import UserWithGuidLookup from '../Models/Lookups/UserWithGuidLookup';
import UsersVM from "./UserModerationMaintenanceVM";
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import CardIcon from '../../Template/components/Card/CardIcon';
import CardBody from '../../Template/components/Card/CardBody';
import ForumDrawer from '../../Forum/Components/ForumComponents/ForumDrawer';
import CardFooter from '../../Template/components/Card/CardFooter';
import Tag1 from '../../Learning/Models/Tags/Tag1';
import Tag2 from '../../Learning/Models/Tags/Tag2';

@observer
export default class UserModerationMaintenanceView extends Views.ViewBase<UsersVM>{
    constructor(props: unknown) {
        super("Users", UsersVM, props);
    }

    private divRef = React.createRef<HTMLDivElement>();
    private scrollOnUpdate: boolean = false;

    componentDidUpdate() {
        if (this.scrollOnUpdate) {
            if (this.divRef.current) {
                var scroll = this.divRef.current.scrollHeight;
                this.divRef.current.scrollTop = scroll;
            }
            this.scrollOnUpdate = false;
        }
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`icon fa fa-user-shield fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Moderation Admin</h4>
                            </CardHeader>
                            <CardBody>
                                <Neo.Button text="Show Unmoderated Tags" onClick={() => this.viewModel.showTagsNotModerated()} />
                                <div className="row">
                                    <div className="col md-12">
                                        <div className="row mt-2">
                                            <div className="col-md-12">
                                                <Neo.Pager
                                                    pageManager={this.viewModel.userRoleLookupPageManager}
                                                    pageControlProps={{
                                                        firstText: "",
                                                        prevText: "Prev",
                                                        nextText: "Next",
                                                        lastText: "",
                                                        alignment: "center",
                                                        autoHide: false,
                                                        pageSizeLabel: "Show"
                                                    }}>
                                                    {User => (
                                                        <NeoGrid.Grid<UserWithGuidLookup> className="table-hover" keyProperty="userId">
                                                            {(user: UserWithGuidLookup, userMeta) => (
                                                                <NeoGrid.Row key={user.userGuid}>
                                                                    <NeoGrid.Column display={userMeta.preferredName} disabled />
                                                                    <NeoGrid.Column display={userMeta.lastName} disabled />
                                                                    <NeoGrid.ButtonColumn>
                                                                        <Neo.Button icon="edit" isOutline variant="success" onClick={() => this.viewModel.editUserModal(user.userGuid)}>Edit</Neo.Button>
                                                                    </NeoGrid.ButtonColumn>
                                                                </NeoGrid.Row>
                                                            )}
                                                        </NeoGrid.Grid>
                                                    )}
                                                </Neo.Pager>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>
                <section>
                    <Neo.Modal title="Tags Not Under Moderation" size="xl"
                        show={this.viewModel.showTags}
                        onClose={() => this.viewModel.closeTagsNotModerated()}
                        closeButton={{
                            text: "Close", variant: "danger", icon: "times", isOutline: true
                        }} >
                        <Neo.Loader task={this.viewModel.taskRunner}>
                            <NeoGrid.Grid<Tag1> items={this.viewModel.tagsNoModerator} className="table-hover" keyProperty="tag1Id"  >
                                {(tag: Tag1, tagMeta) => (
                                    <NeoGrid.RowGroup expandProperty={tagMeta.isExpanded}>
                                        <NeoGrid.Row key={tag.tag1Id}>
                                            <NeoGrid.Column label="Category(T1)" bind={tagMeta.tagName} readOnly />
                                        </NeoGrid.Row>
                                        <NeoGrid.ChildRow>
                                            <NeoGrid.Grid items={tag.tags2} keyProperty="tag2Id">
                                                {(tag2: Tag2, tag2Meta) => (
                                                    <NeoGrid.RowGroup >
                                                        <NeoGrid.Row key={tag2.tag2Id}>
                                                            <NeoGrid.Column label="Sub-Category(T2)" bind={tag2Meta.tagName} readOnly />
                                                        </NeoGrid.Row>
                                                    </NeoGrid.RowGroup>
                                                )}
                                            </NeoGrid.Grid>
                                        </NeoGrid.ChildRow>
                                    </NeoGrid.RowGroup>
                                )}
                            </NeoGrid.Grid>
                        </Neo.Loader>
                    </Neo.Modal >
                </section>
                <ForumDrawer onClose={() => this.viewModel.showEditModal = false} show={this.viewModel.showEditModal} isMaximised={this.viewModel.editModalIsMaximised} maximiseWindow={() => this.viewModel.editModalIsMaximised = !this.viewModel.editModalIsMaximised}>
                    <div ref={this.divRef} >
                        <div>
                            <Card>
                                <CardHeader icon>
                                    <CardIcon color="success">
                                        <i className={`icon fa fa-user-shield fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">User Information</h4>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <Neo.GridLayout md={2} lg={3}>
                                            <Neo.FormGroup display={this.viewModel.userProfile.meta.firstName} />
                                            <Neo.FormGroup display={this.viewModel.userProfile.meta.surname} />
                                            <Neo.FormGroup display={this.viewModel.userProfile.meta.email} />
                                            <Neo.FormGroup display={this.viewModel.userProfile.meta.isActive} />
                                        </Neo.GridLayout>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <section>
                            {/* Module Tags Create and Info */}
                            <div>
                                <Card>
                                    <CardHeader icon>
                                        <CardIcon color="success">
                                            <i className={`icon fa fa-tags fa-2x`}></i>
                                        </CardIcon>
                                        <h4 className="Card-icon-header-text">Add Tags For Moderation</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Neo.Form validationDisplayMode={ValidationDisplayMode.Always}>
                                            <NeoGrid.Grid className="mb-10" items={this.viewModel.userTags} initialSort={"tag2Id"} >
                                                {(userTag, userTagMeta) => (
                                                    <NeoGrid.Row>
                                                        <NeoGrid.Column width={800} size={10} label="Category Tag">
                                                            <Neo.AutoCompleteDropDown
                                                                bind={userTagMeta.tag1Id}
                                                                bindDisplay={userTagMeta.tag1Name}
                                                                displayMember="tagName"
                                                                itemSource={this.viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                                                                onItemSelected={(item, values) => { this.viewModel.filtertag2List(userTag, item?.tag1Id) }}
                                                                loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                                                                minCharacters={2}
                                                            />
                                                        </NeoGrid.Column>
                                                        <NeoGrid.Column width={800} size={10} hidden={userTag.tag1Id === 0}
                                                            label="Sub-Category Tag" bind={userTagMeta.tag2Id} autoFocus={false}
                                                            select={{ items: userTag.filteredTag2List }} />
                                                        <NeoGrid.Column hidden={userTag.tag1Id !== 0}>
                                                            <span>Select a Tag 1</span>
                                                        </NeoGrid.Column>
                                                        <NeoGrid.ButtonColumn showDelete />
                                                    </NeoGrid.Row>
                                                )}
                                            </NeoGrid.Grid>
                                            <Neo.Button onClick={() => {
                                                this.scrollOnUpdate = true;
                                                this.viewModel.userTags.push(new UserModerationTag2Lookup());
                                                this.forceUpdate();
                                            }}>Add</Neo.Button>
                                        </Neo.Form>
                                        <br />
                                        <br />
                                    </CardBody>
                                    <CardFooter plain>
                                        <Neo.Button style={{ float: "right" }} text="Save" variant="success" icon="check" onClick={() => this.viewModel.saveUser()} />
                                    </CardFooter>
                                </Card>
                            </div>
                        </section>
                    </div>
                </ForumDrawer>

            </div>
        )
    }
}