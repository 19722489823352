import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import Title from '../../Models/Educators/Title';
export interface ITitleQueryApiClient {

    /**
     * This method will return a sepcific Titles with all its children
     * @returns Title list
     */
    GetTitleList(): AxiosPromise<Array<Model.PlainTrackedObject<Title>>>;

      /**
     * This method will check if a specific title is linked  SACE Educators, if so, we dont allow delete
     * @param titleId The page request
     * @returns boolean
     */
    checkTitleLinks(titleId: number): AxiosPromise<boolean>;

    // Client only properties / methods
}

@injectable()
export default class TitleQueryApiClient extends Data.ApiClientBase implements ITitleQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/TitleQuery`);
    }

    public GetTitleList(): AxiosPromise<Array<Model.PlainTrackedObject<Title>>> {
        return this.axios.get(`${this.apiPath}/Titles`);
    }

    public  checkTitleLinks(titleId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/CheckTitleLinks/${titleId}`);
    }
}