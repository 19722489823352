import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class Tag1SearchCriteria extends ValueObject {

    public tag1Id: number = 0
    public tag2Id: number = 0
    public tag3Id: number = 0
    public tagName: string = "";
    public keyword: string = ""
}