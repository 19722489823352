import { EnumHelper } from "@singularsystems/neo-core";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import SchoolLookup from "../Models/Lookups/SchoolLookup";
import { SchoolSector } from "../Models/Organisations/Enums/SchoolSector.enum";
import PagerTextFilter from "../../App/Components/PagerTextFilter"
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
import SchoolSearchComponentVM from "./SchoolSearchComponentVM";

interface ISchoolSearchComponentProps {
    onSelected: (school: SchoolLookup) => void;
}

@observer
export default class SchoolSearchComponent extends React.Component<ISchoolSearchComponentProps>{
    private viewModel: SchoolSearchComponentVM;
    constructor(props: ISchoolSearchComponentProps) {
        super(props);
        this.viewModel = new SchoolSearchComponentVM();
    }

    public componentDidMount() {
        this.viewModel.initialise();
    }

    public render() {
        const viewModel = this.viewModel;
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-school fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Schools</h4>
                            </CardHeader>
                            <CardBody>
                                <PagerTextFilter filterProperty={viewModel.criteria.meta.schoolName} pageManager={viewModel.schoolLookupPageManager} taskRunner={viewModel.taskRunner} label="Search for a School" />

                                <div className="row ">
                                    <div className="col-md-12">
                                        <Neo.Pager
                                            pageManager={viewModel.schoolLookupPageManager}
                                            pageControlProps={{
                                                firstText: "",
                                                prevText: "Prev",
                                                nextText: "Next",
                                                lastText: "",
                                                alignment: "center",
                                                // show the page controls even if the amount of items is less than a single page size
                                                autoHide: false,
                                                pageSizeLabel: "Show"
                                            }}>
                                            <NeoGrid.Grid<SchoolLookup> className="table-hover">
                                                {(school: SchoolLookup, schoolMeta) => (
                                                    <NeoGrid.Row>
                                                        <NeoGrid.Column display={schoolMeta.schoolName} />
                                                        <NeoGrid.Column display={schoolMeta.schoolSectorId} select={{ items: EnumHelper.asList(SchoolSector), renderAsText: true }} />
                                                        <NeoGrid.Column sort={false} display={schoolMeta.circuitName} />
                                                        <NeoGrid.Column sort={false} display={schoolMeta.distictName} />
                                                        <NeoGrid.Column sort={false} display={schoolMeta.provinceName} />
                                                        <NeoGrid.ButtonColumn><Neo.Button text="Select" onClick={() => this.props.onSelected(school)} /></NeoGrid.ButtonColumn>
                                                    </NeoGrid.Row>
                                                )}
                                            </NeoGrid.Grid>
                                        </Neo.Pager>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
        )
    }
}