import AppTypes from '../App/AppTypes';
import { AppService } from '../App/Services/AppService';
import { DashboardExportedTypes } from './DashboardExportedTypes';

const Types = {
  ...AppTypes,
  Dashboard: {
    ApiClients: {
      ...DashboardExportedTypes.ApiClients
    },
    Services: {
      ...DashboardExportedTypes.Services,
    }
  }
}

export { AppService, Types }