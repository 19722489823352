import QuestionLookupBase from './QuestionLookupBase';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';
import { Attributes, List, NeoModel, Validation } from '@singularsystems/neo-core';
import { Sentiment } from "../Questions/Sentiment.enum";
import SelfAssessmentOptions from "../Questions/Assessment/SelfAssessmentOptions";
import { ModerationState } from '../LearningObjects/ModerationState.enum';
@NeoModel
export default class SelfAssessmentQuestionLookup extends QuestionLookupBase {

    public selfAssessmentQuestionId: number = 0;

    public selfAssessmentId: number = 0;

    public selfAssessmentOptions: string = "";

    @Required("Tag3 Id is required")
    @Attributes.Display("Tag3")
    public tag3Id: number = 0;

    public sentiment: Sentiment = Sentiment.NotSet;

    public numberOfScales: number = 4;

    public plainText: string = "";

    public selfAssessmentOptionList = new List(SelfAssessmentOptions);

    public selfAssessmentQuestionModerationNotes: string = "";

    public fieldState: ModerationState = ModerationState.Unmoderated;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.tag3Id === 0, "Topic is Required.");
    }
}