import React from "react";
import { Neo } from "@singularsystems/neo-react";
import { Report, NeoReport } from "@singularsystems/neo-reporting";
import { ReportCategory } from "../ReportCategory";
import { AppService, Types } from "../../../ReportingTypes"
import { List } from "@singularsystems/neo-core";
import Province from "../../../../Organisations/Models/Organisations/Province";
import District from "../../../../Organisations/Models/Organisations/District";
import SelfAssessmentResultsCriteria from "./SelfAssessmentResultsCriteria";
import { Layout } from "@singularsystems/neo-canvas-grid";
import TDPUser from "../../../../Identity/Models/Security/TDPUser";
import UserProfileLookup from "../../../../Identity/Models/Users/Lookups/UserProfileLookup";

@NeoReport('SelfAssessmentResults', { category: ReportCategory.Consumption })
export default class SelfAssessmentsReport extends Report<SelfAssessmentResultsCriteria> {

    private user: TDPUser | null = null;
    public currentUser: UserProfileLookup = new UserProfileLookup();
    public isAdmin: boolean = false;

    constructor(
        private taskRunner = AppService.get(Types.Neo.TaskRunner),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
        public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
        private tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
        private tag3ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag3QueryApiClient),
        private userGroupQueryApiClient = AppService.get(Types.Authorisation.ApiClients.UserGroupQueryApiClient),
        private organisationsCache = AppService.get(Types.Organisations.Services.OrganisationDataCache)) {
        super(SelfAssessmentResultsCriteria);
        this.user = authenticationService.user;
        this.initialise();
    }

    public async initialise() {
        await this.taskRunner.run(async() => this.getCurrentUser());
    }

    private async getCurrentUser() {
        if (this.user !== null) {
            let response = await this.userQueryApiClient.getRegisteredUser(this.user?.guid);
            this.currentUser.set(response.data);
            this.isAdmin = (await this.userGroupQueryApiClient.userIsAdminGroup(this.user.guid)).data;

            if (this.isAdmin === false) {
                this.populateFields(this.currentUser)
            }
        }
    }

    private async populateFields(currentUser: UserProfileLookup) {
        if (currentUser.organisationlevel.organisationId != null) {
            this.criteria.organisationId = currentUser.organisationlevel.organisationId;
            this.filterProvinceList(this.criteria.organisationId);
            this.criteria.OrganisationDisabled = true;
        }
        if (currentUser.organisationlevel.provinceId != null) {
            this.criteria.provinceId = currentUser.organisationlevel.provinceId;
            this.filterDistrictList(this.criteria.provinceId);
        }
        if (currentUser.organisationlevel.districtId != null) {
            this.criteria.districtId = currentUser.organisationlevel.districtId;
        }
    }

    public async clearAllSelectedCriteria() {
        this.criteria.provinceId = null;
        this.criteria.filteredProvinceList = new List(Province);
        this.criteria.districtId = null;
        this.criteria.filteredDistrictList = new List(District);
    }

    public async filterProvinceList(organisationId: number) {
        await this.clearAllSelectedCriteria();
        var response = await this.organisationsCache.provinceList.getDataAsync();
        this.criteria.filteredProvinceList.update(response.filter(o => o.organisationId === organisationId));
    }

    public async filterDistrictList(provinceId: number) {
        var response = await this.organisationsCache.districtList.getDataAsync();
        this.criteria.filteredDistrictList.update(response.filter(o => o.provinceId === provinceId));
    }

    public async filterAssessmentTag2ListSearch(tag1Id: number) {
        if (tag1Id) {

            const tag2List = await (await this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id)).data;
            this.criteria.subCategoryList.set(tag2List);
        }
    }

    // public async filterTagThreeList(tag2Id: number) {
    //     const tag3List = await (await this.tag3ApiQueryClient.getTag3ListByTag2Id(tag2Id)).data;
    //     this.criteria.topicList.set(tag3List);
    // }

    public renderCriteria() {
        return <Neo.GridLayout md={2} sm={1}>
            <Neo.FormGroupInline bind={this.criteria.meta.startDate} />
            <Neo.FormGroupInline bind={this.criteria.meta.endDate} />
            <Neo.FormGroupInline bind={this.criteria.meta.organisationId}
                isDisabled={this.criteria.OrganisationDisabled && !this.isAdmin}
                select={{
                    itemSource: this.organisationsCache.organisationList,
                    onItemSelected: (item) => {
                        this.filterProvinceList(item?.organisationId as number)
                    }
                }} />
            <Neo.FormGroupInline bind={this.criteria.meta.provinceId}
                isDisabled={this.currentUser.organisationlevel.provinceId != null && !this.isAdmin}
                select={{
                    items: this.criteria.filteredProvinceList,
                    allowNulls: true,
                    onItemSelected: (item) => {
                        this.filterDistrictList(item?.provinceId as number)
                    }
                }} />
            <Neo.FormGroupInline bind={this.criteria.meta.districtId}
                isDisabled={this.currentUser.organisationlevel.districtId != null && !this.isAdmin}
                select={{
                    items: this.criteria.filteredDistrictList,
                    allowNulls: true,
                }} />
                 <Neo.FormGroupInline bindContext={this.criteria.meta.categoryId}>
                <Neo.AutoCompleteDropDown
                    bind={this.criteria.meta.categoryId}
                    bindDisplay={this.criteria.meta.CategoryName}
                    displayMember="tagName"
                    itemSource={this.tag1QueryApiClient.getTag1DropDownFilter}
                    onItemSelected={(item) => { this.filterAssessmentTag2ListSearch(item?.tag1Id as number) }}
                    loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                    minCharacters={2}
                />
            </Neo.FormGroupInline>
            <Neo.FormGroupInline bind={this.criteria.meta.subCategoryId}
                select={{
                    items: this.criteria.subCategoryList
                }} />
        </Neo.GridLayout>
    }

    getDefaultGridLayout(criteria: SelfAssessmentResultsCriteria) {
        return {
            "root": {
                "columns": [
                    {
                        "key": "organisation",
                        "width": 190,

                    },
                    {
                        "key": "province",
                        "width": 130
                    },
                    {
                        "key": "school",
                        "width": 130
                    },
                    {
                        "key": "answer",
                        "width": 90
                    },
                    {
                        "key": "tag",
                        "width": 120
                    }
                ]
            }
        } as Layout.IGridLayout;
    }

}