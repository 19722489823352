import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import ModerationObjectLookup from './ModerationObjectLookup';

@NeoModel
export default class ModerationSummaryLookup extends ModelBase {

  public moduleId: number = 0;

  public moduleTitle: string = "";

  public moduleTitleApproved: boolean = false;

  public moduleLearningObjective: string = "";

  public moduleLearningObjectiveApproved: boolean = false;

  public moduleCPTDPoints: number | null = null;

  public moduleCPTDPointsApproved: boolean = false;

  public moduleTags: List<ModerationModuleTagsSummary> = new List(ModerationModuleTagsSummary);

  public allTagsApproved: boolean = true;

  public allObjectsApproved: boolean = true;

  public moduleSummaryMessage: string = "";

  public moduleIsComplete: boolean = false;

  public objectList: List<ModerationObjectLookup> = new List(ModerationObjectLookup)

  public passedModeration = false;

  public moduleNotes = "";
}

@NeoModel
export class ModerationModuleTagsSummary extends ModelBase {

  public tag1Id: number = 0;

  public tag2Id: number = 0;

  public tag1Name: string = "";

  public tag2Name: string = "";

  public approved: boolean = false;

  public errorMessage: string = "";
}