import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class LearningModuleSearchCriteria extends ValueObject {

    public title: string = "";

    public createdBy: string = "";

    public filterByCreated: boolean = true;

    public keyword: string = "";

    public learningModuleTag2Id: number = 0;

    public learningModuleTag3Id: number = 0;

    public learningModuleTag1Id: number = 0;

    public learningModuleTag1Name: string = "";

    public learningModuleId: number | null = null;

    public moderationState: number | null = null;

    public stateSelectedUnknown: boolean = false;

    public stateSelectedUnmoderated: boolean = true;

    public stateSelectedSubmittedForModeration: boolean = false;

    public stateSelectedUnderModeration: boolean = false;

    public stateSelectedReview: boolean = false;

    public stateSelectedModerated: boolean = false;

    public stateSelectedDeactivated: boolean = false;

    public stateSelectedResubmittedForModeration: boolean = false;

    public ids: number[] = [];

    public isSuperAdmin: boolean = false;
}