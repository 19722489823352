import { Routing as NeoRouting } from "@singularsystems/neo-core";
import { UserTypes } from "../App/Models/Enums/UserTypes.enums";
import SelfAssessmentView from "./Views/SelfAssessmentView";

export interface IAppRoute extends NeoRouting.IMenuRoute {
  userType?: UserTypes
  children?: IAppRoute[]
  denyRole?: string
}

export const DashboardRoutes: IAppRoute = {
  /// add Routes here
  name: "Questionnaires", path: "/Assessments/Overview", component: SelfAssessmentView, icon: "clipboard", allowAnonymous: false,
}

const PureRoutes: NeoRouting.IRoute[] = [];

export { PureRoutes }