import { Data } from "@singularsystems/neo-core";
import { LifeTime } from "../../App/Services/AppDataService";
import { AppService, Types } from "../OrganisationTypes";

export default class OrganisationsDataCache extends Data.CachedDataService {
    constructor(
        appDataCache = AppService.get(Types.App.Services.AppDataCache),
        private orgApiClient = AppService.get(Types.Organisation.ApiClients.OrganisationQueryApiClient),
        private provApiClient = AppService.get(Types.Organisation.ApiClients.ProvinceQueryApiClient),
        private districtApiClient = AppService.get(Types.Organisation.ApiClients.DistrictQueryApiClient),
        private circuitApiClient = AppService.get(Types.Organisation.ApiClients.CircuitQueryApiClient),
        private schoolApiClient = AppService.get(Types.Organisation.ApiClients.SchoolQueryApiClient),
        private schoolManagementTeamApiClient = AppService.get(Types.Organisation.ApiClients.SchoolManagementTeamQueryApiClient)) {
            super(appDataCache)
        }

        public organisationList = this.register(this.orgApiClient.getOrganisations, LifeTime.Short);
        public provinceList = this.register(this.provApiClient.getProvinces, LifeTime.Short);
        public districtList = this.register(this.districtApiClient.getDistrictOnlyList, LifeTime.Short);
        public circuitList = this.register(this.circuitApiClient.getCircuitOnlyList, LifeTime.Short);
        public schoolList = this.register(this.schoolApiClient.getSchoolOnlyList, LifeTime.Short);
        public schoolManagementTeamList = this.register(this.schoolManagementTeamApiClient.getSchoolManagementTeamList, LifeTime.Short);
}