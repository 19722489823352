import { Data, List, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { Types } from '../../Identity/IdentityTypes';
import { AppService } from '../../Identity/CommonIdentityTypes';
import LearningModuleSearchCriteria from "../Models/Criteria/LearningModuleSearchCriteria";
import LearningModuleLookup from "../Models/Lookups/LearningModuleLookup";
import LearningModuleTagLookup from "../Models/Lookups/LearningModuleTagLookup";
import { LearningContentFileComponentVM } from "../Components/LearningContentFileComponent";
import LearningObjectTagLookup from "../Models/Lookups/LearningObjectTagLookup";
import LearningObjectDragableModel from "../Models/Lookups/LearningObjectDragableModel";
import LearningObjectLookup from "../Models/Lookups/LearningObjectLookup";
import LearningModuleWithTagsLookup from "../Models/Lookups/LearningModuleWithTagsLookup";
import LearningObjectContentLookup from "../Models/Lookups/LearningObjectContentLookup";
import FileLookup from "../Models/Lookups/FileLookup";
import UserProfileLookup from "../../Identity/Models/Users/Lookups/UserProfileLookup";
import TDPUser from "../../Identity/Models/Security/TDPUser";
import { QuizLearningObjectComponentVM } from "../Components/QuizLearningObjectComponent";
import QuizLearningObjectLookup from "../Models/Lookups/QuizLearningObjectLookup";
import { LearningObjectType } from "../Models/LearningObjects/LearningObjectType.enum";
import QuizQuestionLookup from "../Models/Lookups/QuizQuestionLookup";
import OptionLookup from "../Models/Lookups/OptionLookup";
import Tag2 from "../Models/Tags/Tag2";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum";
import { learningObjectTabs } from "./LearningModuleView";
import { RichTextEditorVM } from "../Components/RichTextEditor";
import { ModerationState } from "../Models/LearningObjects/ModerationState.enum";
import { truncateLongString } from '../../App/HelperClasses/GlobalHelpers';
import { LearningContentMediaType } from "../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import LearningObjectSearchCriteria from "../Models/Criteria/LearningObjectSearchCriteria";
import ModerationSummaryLookup, { ModerationModuleTagsSummary } from "../Models/Moderation/ModerationSummaryLookup";
import ModerationObjectLookup, { ModerationObjectTagsSummary, ObjectContentSummary, ObjectQuestionSummary } from "../Models/Moderation/ModerationObjectLookup";
import { Learning } from "../Models/Security/LearningRoles";
import ModuleConsumptionLookup from '../Models/ContentCosumption/ModuleConsumptionLookup';
// import debounce from 'lodash.debounce';

@NeoModel
export default class LearningModuleVM extends Views.ViewModelBase {
   
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
        private tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        private userGroupQueryApiClient = AppService.get(Types.Authorisation.ApiClients.AuthorisationApiClient),
        private moderationApiClient = AppService.get(Types.Learning.ApiClients.ModerationQueryApiClient),
        private quizLearningObjectApiClient = AppService.get(Types.Learning.ApiClients.QuizLearningObjectApiClient),
        public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
        private updateForumApiClient = AppService.get(Types.Forum.ApiClients.ForumUpdateApiClient),
        private identityService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
        private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService)) {
        super(taskRunner);
        this.user = identityService.user;
        this.currentUser = new UserProfileLookup();
    }

    // Properties
    public isAdminGroup = false;
    // User Info
    public currentUser: UserProfileLookup;
    private user: TDPUser | null = null;

    public htmlContent: string = "";

    public loDragAndDropList: LearningObjectDragableModel[] = [];
    public questionDragAndDropList: LearningObjectDragableModel[] = [];
    public hideCriteria: boolean = false;
    public canViewAllContent: boolean = false;

    // Module Properties
    public selectedLearningModuleId: number = 0;
    public selectedLearningModule = new LearningModuleWithTagsLookup();
    public learningObjectFilteredTag3List = new List(LearningObjectTagLookup);
    public learningModuleTag2FilteredList = new List(Tag2);
    public searchCriteriaClear: boolean = false;
    public selectedTab = learningObjectTabs.loDetails;
    public moduleConsumptionLookup=new ModuleConsumptionLookup();

    //Module Paging Manager
    public criteria = new LearningModuleSearchCriteria();
    public hideModuleNotes = true;
    public hideObjectNotes = true;
    public richTextEditorVmModule = new RichTextEditorVM(this.taskRunner);
    public richTextEditorVmLearningObject = new RichTextEditorVM(this.taskRunner);
    public showModuleReviewSummaryModal = false;
    public reviewIsMaximised = true;
    public moduleReviewSummary = '';

    // Learning Object
    public learningObjectList = new List(LearningObjectLookup);
    //List of learning objects that they can add to a module
    public learningObjectToAddList = new List(LearningObjectLookup);
    public selectedLearningObject = new LearningObjectLookup();
    public selectedLearningObjectId: number = 0;
    public hideLearningModuleGrid: boolean = false;
    public canCreateNewLearningObject: boolean = true;
    public contentRichTextvm = new RichTextEditorVM(this.taskRunner)

    // Quiz Learning Object
    public quizLearningObject = new QuizLearningObjectLookup();
    public QuizLearningObjectComponentVM = new QuizLearningObjectComponentVM(this.taskRunner);
    public hideMediaUpload = true;
    public selectedQuestionId: number = 0;

    //Learning Content Properties
    public selectedContentTypeId: number = 0;
    public selectedMediaType: number = 0;
    public LearningContentFileComponentVM = new LearningContentFileComponentVM(this.taskRunner);
    public newLearningContent = new LearningObjectContentLookup();
    public newQuestionLearningContent = new LearningObjectContentLookup();
    public selectedLearningContentId: number = 0;
    public showAddContentModalForNewLearningObject: boolean = false;
    public showAddObjectToModule: boolean = false;
    public isMaximised: boolean = true;

    // bindings
    public hideEditModuleCard = true;
    public hideLearningObjectDrag = true;
    public hideEditTagsCard = true;
    public hideEditLearningObjectCard = true;
    public hideLearningObjectCard = true;
    public hideQuestionDragAndDrop = true;
    public hideUpdate = true;
    public hideQuizCard = false;
    public showModerationStateFilter: boolean = true;

    public canChangeModeratedModules: boolean = false;
    //Called on ComponentDidMount
    public async initialise() {
        await this.getCurrentUser();
        this.criteria.meta.createdBy.value = this.currentUser.userGuid;
        this.LearningModuleLookupPageManager.refreshData();
        this.canViewAllContent =  this.authorisationService.hasRole(Learning.ViewAllContent);
        const response = await this.userGroupQueryApiClient.userGroupQuery.userIsAdminGroup(this.currentUser.userGuid);
        this.isAdminGroup = response.data;
        this.canChangeModeratedModules = this.authorisationService.hasRole(Learning.ChangeModeratedModules);
    }

    private async getCurrentUser() {
        if (this.user != null) {
            let response = await this.userQueryApiClient.getRegisteredUser(this.user?.guid);
            let userData = response.data;
            this.currentUser.set(userData);
        }
    }

    public LearningModuleLookupPageManager = new Data.PageManager(this.criteria, LearningModuleLookup, this.learningModuleApiClient.getPagedLearningModuleLookup, {
        pageSize: 15,
        pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
        sortBy: "learningModuleId",
        sortAscending: false,
        initialTaskRunner: this.taskRunner,
        allowSort: true,
        taskRunner: this.taskRunner,
    });

    // Shows the add new or edit Module card
    public async showEditModuleCard(learningModuleId: number) {
        this.resetVMModule();
        this.criteria.learningModuleId = null;
        this.hideQuestionDragAndDrop = true;
        if (learningModuleId === 0) {
            this.selectedLearningObject = new LearningObjectLookup();
            this.selectedLearningObjectId = 0;
            this.hideUpdate = true;
            this.hideEditTagsCard = true;
            this.hideEditModuleCard = false;
            this.hideLearningObjectCard = true;
            this.newLearningContent = new LearningObjectContentLookup();
            this.hideCriteria = true;
            this.newQuestionLearningContent = new LearningObjectContentLookup();
            this.quizLearningObject = new QuizLearningObjectLookup();
            this.hideLearningModuleGrid = true;
        }
        else {
            this.refreshModule(learningModuleId)
            var response = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningModuleDNDObjects(learningModuleId));
            this.loDragAndDropList = response.data;
            this.hideCriteria = true;
            this.hideEditTagsCard = false;
            this.hideEditModuleCard = false;
            this.hideLearningObjectDrag = false;
            this.hideEditLearningObjectCard = false;
            this.hideUpdate = false;
            this.hideLearningObjectCard = true;
        }
    }

    // Resets all the Module and objects Properties on Vm
    public resetVMModule() {
        this.selectedLearningModuleId = 0;
        this.selectedLearningObjectId = 0;
        this.selectedLearningModule = new LearningModuleWithTagsLookup();
        this.learningObjectList = new List(LearningObjectLookup);
        this.selectedLearningObject = new LearningObjectLookup();
    }

    public async DeactivateModule(learningModuleId: number) {
        this.taskRunner.run(async () => {
            const response = await this.moderationApiClient.deactivateModule(learningModuleId);
            if (response.status === 200) {
                if (!response.data) {
                    this.notifications.addDanger("Deactivate unsuccessful", "Module cannot be deactivated", NotificationDuration.Standard);
                }
                else {
                    this.notifications.addSuccess("Module Deactivated", null, NotificationDuration.Standard);
                }
            } else {
                this.notifications.addDanger("Deactivate unsuccessful", "Module cannot be deactivated. Unknown response received.", NotificationDuration.Standard);
            }
        });
    }

    public async deleteLearningModule(learningModuleId: number) {
        this.taskRunner.run(async () => {
            const response = await this.taskRunner.waitFor(this.learningModuleApiClient.deleteLearningModule(learningModuleId));
            if (response.data === false) {
                this.notifications.addDanger("Delete unsuccessful", "Module cannot be deleted as it has already been moderated", NotificationDuration.Standard);
            }
            else {
                this.LearningModuleLookupPageManager.refreshData();
                this.notifications.addSuccess("Module Deleted", null, NotificationDuration.Standard);
            }
        });
    }

    public async showDeleteLearningModule(learningModule: LearningModuleLookup, learningModuleId: number) {
        await ModalUtils.showYesNoDismissible("Delete Module " + learningModule.learningModuleTitle,
            "Are you sure you want to delete this Module? ", () => this.deleteLearningModule(learningModuleId));
    }

    public async deleteLearningModuleTag2(moduleTag: LearningModuleTagLookup) {
        var canDelete = await this.CanDeleteCTag2(moduleTag.tag2Id);
        if (canDelete) {
            if (moduleTag.isNew) {
                this.selectedLearningModule.learningModuleTags.remove(moduleTag);
            }
            else {
                await ModalUtils.showYesNoDismissible("Delete Topic(T1), Sub-Category(T2)",
                    "Are you sure you want to delete this Topic(T1) -> " + moduleTag.tag1Name + ", Sub-Category(T2) -> " + moduleTag.tag2Name + ", from this Module?", () => this.deleteModalTag2(moduleTag));
            }
        }
        else {
            ModalUtils.showMessage("Can't delete Tags", "The selected tags have learning objects containing Linked tag 3's. Please remove before deleting.", 4);
        }
    }

    public async CanDeleteCTag2(Tag2Id: number) {
        var canDelete = true;
        for (const lo of this.learningObjectList) {
            const response = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningObjectTagList(lo.learningObjectId));
            var count = response.data.filter(c => c.tag2Id === Tag2Id).length;
            if (count !== 0) {
                canDelete = false;
                break;
            }
        }
        return canDelete;
    }

    public async deleteModalTag2(moduleTag: LearningModuleTagLookup) {
        const response = await this.learningModuleApiClient.deleteLearningModuleTag2(moduleTag.learningModuleId, moduleTag.tag2Id)
        if (response.data) {
            this.selectedLearningModule.learningModuleTags.remove(moduleTag);
            this.showEditModuleCard(moduleTag.learningModuleId);
            this.notifications.addSuccess("Module Tag Deleted", "Module Tags Delete ", NotificationDuration.Standard);
        } else {
            this.notifications.addDanger("Delete unsuccessful", "Tag cannot be deleted as it has already been moderated", NotificationDuration.Standard);
        }
    }

    public async filtertag2List(moduleTag: LearningModuleTagLookup, tag1Id?: number | undefined) {
        if (tag1Id) {
            const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id as number))).data;
            moduleTag.filteredTag2List.update(tag2List);
        }
    }

    public async filtertag2ListSearch(tag1Id: number | undefined) {
        if (tag1Id) {
            const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id))).data;
            this.learningModuleTag2FilteredList.set(tag2List);
        }
        this.criteria.learningModuleTag2Id = 0;
    }

    public canSaveModule() {
        if (this.selectedLearningModule.learningModuleTags.length === 0) {
            return true;
        } else if (this.selectedLearningModule.learningModuleTags.length > 0 && this.selectedLearningModule.learningModuleTags[0].tag2Id === 0) {
            return true;
        }
        return false;
    }

    public async saveModule() {
        var Module = this.selectedLearningModule;
        if (this.selectedLearningModuleId !== 0) {
            Module.learningModuleId = this.selectedLearningModuleId;
            this.taskRunner.run(async () => {
                var response = await this.learningModuleApiClient.updateLearningModule(Module.toJSObject());
                if (response.data) {
                    this.LearningModuleLookupPageManager.refreshData();
                    this.refreshModule(response.data.learningModuleId);
                    this.notifications.addSuccess("Learning Module Updated", "Learning Module Updated", 4);
                }
                else {
                    this.notifications.addDanger("Learning Module Update Failed", "Learning Module Update Failed", 4);
                }
            });
        } else {
            this.taskRunner.run(async () => {
                var response = await this.learningModuleApiClient.createLearningModule(Module.toJSObject());
                if (response.data) {
                    await this.updateForumApiClient.createGlobalModuleConversationAsync(response.data.learningModuleId);
                    this.LearningModuleLookupPageManager.pageSize = 1;
                    this.LearningModuleLookupPageManager.refreshData();
                    this.refreshModule(response.data.learningModuleId);
                    this.notifications.addSuccess("Learning Module Saved", "Learning Module Saved", NotificationDuration.Standard);
                    this.hideUpdate = false;
                }
                else {
                    this.notifications.addDanger("Learning Module Save Failed", "Learning Module Save Failed", NotificationDuration.Standard);
                }
            });
        }
        this.hideUpdate = true;
    }

    public async updateLearningObject(autoUpdate: boolean) {
        var loObject = this.selectedLearningObject;
        var finalQuizExists = false;
        this.learningObjectList.forEach(element => {
            if (element.learningObjectTypeId === LearningObjectType.FinalQuiz && loObject.learningObjectTypeId === LearningObjectType.FinalQuiz) {
                finalQuizExists = true;
            }
        });

        if (this.selectedLearningObject.learningObjectChangedTypeId !== loObject.learningObjectTypeId && loObject.learningObjectTypeId === LearningObjectType.FinalQuiz && finalQuizExists) {
            this.selectedLearningObject.learningObjectTypeId = LearningObjectType.Quiz;
            this.notifications.addDanger("Final Quiz Existing", "There is already a final quiz existing for this module", NotificationDuration.Standard);
            return;
        }

        if (loObject.learningObjectTypeId === LearningObjectType.FinalQuiz && loObject.learningObjectId === 0 && finalQuizExists) {
            this.selectedLearningObject.learningObjectTypeId = LearningObjectType.Quiz;
            this.notifications.addDanger("Final Quiz Existing", "There is already a final quiz existing for this module", NotificationDuration.Standard);
            return;
        }

        if (this.selectedLearningObject.learningObjectTypeId === LearningObjectType.Quiz || this.selectedLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz) {
            await this.learningModuleApiClient.updateQuizLearningObject(loObject.toJSObject());
        }
        else {
            await this.learningModuleApiClient.updateInformationLearningObject(loObject.toJSObject());
        }

        var responseDnd = await this.learningModuleApiClient.getLearningModuleDNDObjects(this.selectedLearningModuleId);
        this.loDragAndDropList = responseDnd.data;
        this.refreshLOList();
        if (!autoUpdate) {
            this.notifications.addSuccess("Learning Object updated", "Learning Object updated", NotificationDuration.Standard);
            this.showAddContentModalForNewLearningObject = true;
        }
    }

    public async refreshModule(moduleId: number) {
        const learningModule = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningModuleById(moduleId));
        this.selectedLearningModuleId = moduleId;
        this.selectedLearningModule.set(learningModule.data);

        this.criteria.learningModuleId = this.selectedLearningModuleId;
        this.LearningModuleLookupPageManager.refreshData();
        var response = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningModuleDNDObjects(moduleId));
        this.loDragAndDropList = response.data;
        const responseList = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningObjectTagSelectList(moduleId));
        this.learningObjectFilteredTag3List.set(responseList.data);
        this.refreshLOList();
        this.hideLearningModuleGrid = false;
    }

    /// visiblity bindings
    public showTagCards() {
        if (this.selectedLearningModuleId === 0) {
            this.hideEditTagsCard = false;
            this.hideUpdate = false;
        }
        this.hideEditTagsCard = false;
    }

    public showLearningObjectCard() {
        this.selectedLearningObjectId = 0;
        this.selectedLearningObject = new LearningObjectLookup();
        this.newLearningContent = new LearningObjectContentLookup();
        this.hideMediaUpload = true;
        this.selectedLearningContentId = 0;
        this.LearningContentFileComponentVM.fileDescriptorList = new List(FileLookup);
        this.hideLearningObjectCard = false;
        this.quizLearningObject = new QuizLearningObjectLookup();
        this.hideQuizCard = false;
        this.hideQuestionDragAndDrop = true;
        this.QuizLearningObjectComponentVM.showLinkedResource = false;
        this.canCreateNewLearningObject = true;
    }

    public async showQuizCard() {
        var response = await this.taskRunner.waitFor(this.quizLearningObjectApiClient.getQuizQuestionOrder(this.selectedLearningObject.learningObjectId));
        this.quizLearningObject = new QuizLearningObjectLookup();
        this.selectedLearningContentId = 0;
        this.quizLearningObject.learningModuleId = this.selectedLearningModuleId;
        this.quizLearningObject.learningObjectTitle = this.selectedLearningObject.learningObjectTitle;
        this.quizLearningObject.learningObjectId = this.selectedLearningObject.learningObjectId;
        this.quizLearningObject.learningObjectTypeId = this.selectedLearningObject.learningObjectTypeId;
        this.QuizLearningObjectComponentVM.optionLookup = new List(OptionLookup);
        this.newQuestionLearningContent = new LearningObjectContentLookup();
        this.QuizLearningObjectComponentVM.fileDescriptorList = new List(FileLookup);
        this.hideQuizCard = true;
        this.hideMediaUpload = true;
        this.QuizLearningObjectComponentVM.showLinkedResource = false;
        if (response.data) {
            this.quizLearningObject.quizQuestion.questionOrder = (response.data as number);
        }
    }

    public setSelectedContentType(id: number) {
        this.selectedContentTypeId = id;
        this.selectedLearningObject.learningObjectTypeId = id;
        if (!(id === LearningObjectType.Quiz || id === LearningObjectType.FinalQuiz)) {
            this.hideQuizCard = false;
        }
    }

    public setSelectedContent(content: LearningObjectContentLookup) {
        this.hideMediaUpload = false;
        this.LearningContentFileComponentVM.fileDescriptorList = new List(FileLookup);
        this.selectedLearningContentId = content.learningContentId;
        this.newLearningContent.set(content.toJSObject());
        this.newLearningContent.meta.learningContent.value.mediaType = this.newLearningContent.mediaTypeFetched;
        if (this.newLearningContent.meta.learningContent.value.mediaType === LearningContentMediaType.Link
            || this.newLearningContent.meta.learningContent.value.mediaType === LearningContentMediaType.YouTube
            || this.newLearningContent.meta.learningContent.value.mediaType === LearningContentMediaType.Conversation) {
            this.LearningContentFileComponentVM.fileDescriptorList.set([]);
            this.LearningContentFileComponentVM.selectedMediaType = this.newQuestionLearningContent.mediaTypeFetched;
        }
        else {
            var fileDescriptor = new FileLookup();
            fileDescriptor.fileDescriptorId = this.newLearningContent.learningContent.mediaObject?.fileDescriptorId as string;
            fileDescriptor.fileName = this.newLearningContent.learningContent.mediaObject?.fileName as string;
            fileDescriptor.mediaTypeId = this.newLearningContent.mediaTypeFetched;
            fileDescriptor.mimeType = this.newLearningContent.learningContent.mediaObject?.extension as string;
            this.LearningContentFileComponentVM.fileDescriptorList.push(fileDescriptor);
            this.LearningContentFileComponentVM.selectedMediaType = this.newQuestionLearningContent.mediaTypeFetched;
        }
    }

    public async setSelectedQuestion(question: QuizQuestionLookup) {
        this.selectedQuestionId = question.questionId;
        this.quizLearningObject = new QuizLearningObjectLookup();
        this.newQuestionLearningContent = new LearningObjectContentLookup();
        this.quizLearningObject.learningModuleId = this.selectedLearningModuleId;
        this.quizLearningObject.FinalQuizInd = this.selectedLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz;
        this.quizLearningObject.learningObjectTitle = this.selectedLearningObject.learningObjectTitle;
        this.quizLearningObject.learningObjectId = this.selectedLearningObject.learningObjectId;
        this.quizLearningObject.learningObjectTypeId = this.selectedLearningObject.learningObjectTypeId ?? 0;
        this.QuizLearningObjectComponentVM.optionLookup = question.options;
        this.quizLearningObject.quizQuestion = question;
        if (question.contentId > 0) {
            const questionLearningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentById(question.contentId);
            if (questionLearningContent != null) {
                this.QuizLearningObjectComponentVM.showLinkedResource = true;
                this.newQuestionLearningContent.set(questionLearningContent.data);
                if (this.newQuestionLearningContent.meta.learningContent.value.mediaType === LearningContentMediaType.Link
                    || this.newQuestionLearningContent.meta.learningContent.value.mediaType === LearningContentMediaType.YouTube) {
                    this.QuizLearningObjectComponentVM.fileDescriptorList.set([]);
                    this.QuizLearningObjectComponentVM.selectedMediaType = this.newQuestionLearningContent.mediaTypeFetched
                }
                else {
                    this.QuizLearningObjectComponentVM.fileDescriptorList.set([]);
                    var fileDescriptor = new FileLookup();
                    fileDescriptor.fileDescriptorId = this.newQuestionLearningContent.learningContent.mediaObject?.fileDescriptorId as string;
                    fileDescriptor.fileName = this.newQuestionLearningContent.learningContent.mediaObject?.fileName as string;
                    fileDescriptor.mediaTypeId = this.newQuestionLearningContent.mediaTypeFetched;
                    fileDescriptor.mimeType = this.newQuestionLearningContent.learningContent.mediaObject?.extension as string;
                    this.QuizLearningObjectComponentVM.fileDescriptorList.push(fileDescriptor);
                    this.QuizLearningObjectComponentVM.selectedMediaType = this.newQuestionLearningContent.mediaTypeFetched;
                }
            }
        }
        else {
            this.QuizLearningObjectComponentVM.fileDescriptorList.set([]);
            this.QuizLearningObjectComponentVM.showLinkedResource = false;
        }
        this.hideQuizCard = true;
        this.hideLearningObjectCard = true;
    }

    public checkForDuplicateTag2s(id: number) {
        var tag2s = this.selectedLearningModule.learningModuleTags.filter(c => c.tag2Id === id);
        if (tag2s.length > 1) {
            ModalUtils.showMessage("This is a Duplicate Tag2", "Please Remove Duplicate Tag", 4);
        }
    }

    public checkForDuplicateTag3s(id: number) {
        var tag3s = this.selectedLearningObject.listOfTag3s.filter(c => c.tag3Id === id);
        if (tag3s.length > 1) {
            this.selectedLearningObject.listOfTag3s.remove(tag3s[1]);
            ModalUtils.showMessage("Duplicate Tag3", "Duplicate tag has been removed.", 4);
        }
    }

    public setSelectedMediaType(id: number) {
        this.selectedMediaType = id;
    }

    public async setSelectedObject(learningObjectId: number) {
        this.selectedQuestionId = 0;
        this.quizLearningObject = new QuizLearningObjectLookup();
        this.newQuestionLearningContent = new LearningObjectContentLookup();
        this.QuizLearningObjectComponentVM.fileDescriptorList = new List(FileLookup);
        this.LearningContentFileComponentVM.fileDescriptorList = new List(FileLookup);
        this.hideMediaUpload = true;
        this.newLearningContent = new LearningObjectContentLookup();
        this.selectedLearningContentId = 0;
        this.selectedLearningObjectId = learningObjectId;
        this.selectedLearningObject = new LearningObjectLookup();
        var lOObject = this.learningObjectList.find(c => c.learningObjectId === learningObjectId);
        var loTags = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningObjectTagList(learningObjectId));
        lOObject?.listOfTag3s.set(loTags.data);

        const responseList = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningObjectTagSelectList(this.selectedLearningModuleId));
        this.learningObjectFilteredTag3List.set(responseList.data);

        this.selectedLearningObject = lOObject as LearningObjectLookup;
        this.hideLearningObjectCard = false;
        this.selectedLearningObject.learningObjectChangedTypeId = lOObject!.learningObjectTypeId;

        if (this.selectedLearningObject.learningObjectTypeId === LearningObjectType.Quiz || this.selectedLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz) {
            this.questionDragAndDropList = [];
            this.fetchQuestionsForDnd(learningObjectId);
            const learningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentByObject(this.selectedLearningObject.learningObjectId);
            this.selectedLearningObject.learningContent.set(learningContent.data);
            if (this.selectedLearningObject.learningContent !== null) {
                if (!!this.selectedLearningObject.learningContent.length) {
                    this.newLearningContent.set(this.selectedLearningObject.learningContent[0].toJSObject());
                    this.setSelectedContent(this.newLearningContent);
                }
            }
        }
        else {
            this.hideQuestionDragAndDrop = true;
        }

        const getlearningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentByObject(this.selectedLearningObject.learningObjectId);
        if (getlearningContent.data !== null && getlearningContent.data.length > 0) {
            this.newLearningContent.set(getlearningContent.data[0]);
        }
        this.QuizLearningObjectComponentVM.showLinkedResource = false;
        this.quizLearningObject = new QuizLearningObjectLookup();
        this.hideQuizCard = false;
    }

    public async deleteModuleLearningObjectPopUp(learningObjectName: string, learningObjectId: number, learningModuleName: string) {
        await ModalUtils.showYesNoDismissible("Delete Learning Object " + learningObjectName + " from Module " + learningModuleName,
            "Are you sure you want to delete this Learning Object from this Module? ", () => this.deleteAndRefreshObjects(learningObjectId));
    }

    public async deleteAndRefreshObjects(learningObjectId: number) {
        this.learningModuleApiClient.deleteLOLMLink(this.selectedLearningModuleId, learningObjectId);
        this.learningObjectList.remove(this.learningObjectList.find(c => c.learningObjectId === learningObjectId)!)
    }

    public async deleteConfirmed(learningContentToDelete: LearningObjectContentLookup) {
        await this.taskRunner.waitFor(this.learningModuleApiClient.deleteLearningContent(learningContentToDelete.learningContent.learningContentId));
        this.learningObjectList.find(c => c.learningObjectId === learningContentToDelete.learningObjectId)!.learningContent.remove(learningContentToDelete);
        this.notifications.addSuccess("Learning Content Removed", "Learning Content has been removed", 4);
    }

    public async deleteLCFromLO(learningContentToDelete: LearningObjectContentLookup) {
        await ModalUtils.showYesNoDismissible("Delete " + learningContentToDelete.learningContentId,
            "Are you sure you want to delete this Learning Content? ", () => this.deleteConfirmed(learningContentToDelete));
    }

    public async deleteQuestionConfirmed(quizQuestionLookup: QuizQuestionLookup, learningObjectId: number) {
        await this.taskRunner.waitFor(this.quizLearningObjectApiClient.DeleteQuestion(quizQuestionLookup.questionId, quizQuestionLookup.learningObjectId));
        var questionToRemove = this.learningObjectList.find(c => c.learningObjectId === learningObjectId)!.quizQuestions.find(c => c.questionId === quizQuestionLookup.questionId)!;
        this.learningObjectList.find(c => c.learningObjectId === learningObjectId)?.quizQuestions.remove(questionToRemove);
    }

    public async deleteQuizQuestion(questionToDelete: QuizQuestionLookup, learningObjectId: number) {
        await ModalUtils.showYesNoDismissible("Delete " + questionToDelete.questionOrder,
            "Are you sure you want to delete this Question? ", () => this.deleteQuestionConfirmed(questionToDelete, learningObjectId));
    }

    public async searchModules() {
        this.hideLearningModuleGrid = false;
        this.LearningModuleLookupPageManager.refreshData();
        this.selectedLearningModuleId = 0;
        this.selectedLearningModule = new LearningModuleWithTagsLookup();
        this.hideEditTagsCard = true;
        this.hideEditModuleCard = true;
        this.hideLearningObjectDrag = true;
        this.hideEditLearningObjectCard = true;
        this.hideQuestionDragAndDrop = true;
        this.hideUpdate = true;
    }

    public myContent: boolean = false;
    public updateCriteriaCreatedBy() {
        if (this.criteria.meta.createdBy.value === '') {
            this.criteria.meta.createdBy.value = this.currentUser.userGuid;
            this.myContent = true;
        } else {
            this.criteria.meta.createdBy.value = '';
            this.myContent = false;
        }
    }

    public clearModuleSearchFilters() {
        this.criteria.title = "";
        this.criteria.meta.createdBy.value = this.currentUser.userGuid;
        this.criteria.filterByCreated = true;
        this.criteria.keyword = "";
        this.criteria.learningModuleId = null;
        this.criteria.learningModuleTag2Id = 0;
        this.criteria.learningModuleTag1Id = 0;
        this.criteria.learningModuleTag3Id = 0;
        this.criteria.learningModuleTag1Name = "";
        this.criteria.stateSelectedUnknown = false;
        this.criteria.stateSelectedUnmoderated = true;
        this.criteria.stateSelectedSubmittedForModeration = false;
        this.criteria.stateSelectedUnderModeration = false;
        this.criteria.stateSelectedReview = false;
        this.criteria.stateSelectedModerated = false;
        this.criteria.stateSelectedResubmittedForModeration = false;
        this.criteria.stateSelectedDeactivated=false;
        this.myContent = true;
        this.LearningModuleLookupPageManager.refreshData();
    }

    public checkForCriteriaSet() {
        return this.searchCriteriaClear;
    }

    public canSaveLO() {
        var loObject = this.selectedLearningObject
        if (loObject.learningObjectTypeId === null || loObject.learningObjectTypeId === 0) {
            return false;
        }
        else if (loObject.learningObjectTypeId === LearningObjectType.Information && loObject.listOfTag3s.length === 0) {
            return false;
        } else if (loObject.learningObjectTitle === "" || loObject.learningObjectPurpose === "" || (loObject.learningObjectText === "" || JSON.parse(loObject.learningObjectText).blocks[0].text === "")) {
            return false;
        }
        else {
            return true;
        }
    }

    public async checkCreateUpdateLO() {
        var loObject = this.selectedLearningObject
        loObject.learningModuleId = this.selectedLearningModuleId;
        var canUpdateOrCreate = false;
        if (loObject.learningObjectTypeId === null || loObject.learningObjectTypeId === 0) {
            canUpdateOrCreate = false;
        }
        else if (loObject.learningObjectTypeId === LearningObjectType.Information && loObject.listOfTag3s.length === 0) {
            canUpdateOrCreate = false;
        } else if (loObject.learningObjectTitle === "" || loObject.learningObjectPurpose === "" || (loObject.learningObjectText === "" || JSON.parse(loObject.learningObjectText).blocks.length === 0)) {
            canUpdateOrCreate = false;
        }
        else {
            canUpdateOrCreate = true;
        }

        if (canUpdateOrCreate && loObject.learningObjectId > 0) {
            await this.updateLearningObject(true);
        }
        else if (canUpdateOrCreate && loObject.learningObjectId === 0) {
            await this.createNewLO(true);
        }
    }

    // Example of debouncing, also this took long, so keep it here : ' ) plis
    // public async checkCreateUpdateLO() {
    //     await debounce(() =>  this.debouncedcheckCreateUpdateLO(), 1500)();
    // }

    public async createNewLO(autoSave: boolean) {
        var loObject = this.selectedLearningObject;
        loObject.learningModuleId = this.selectedLearningModuleId;
        var response = null;
        if (loObject.learningObjectTypeId === LearningObjectType.Information) {
            response = await this.taskRunner.run(async () => this.learningModuleApiClient.createLearningObject(loObject.toJSObject()));
            this.selectedLearningObject.learningObjectId = response.data.learningObjectId;
            if (!autoSave) {
                this.showAddContentModalForNewLearningObject = true;
            }
        }
        else {
            loObject.finalQuizInd = loObject.learningObjectTypeId === LearningObjectType.FinalQuiz
            var finalQuizExists = false;
            this.learningObjectList.forEach(element => {
                if (element.learningObjectTypeId === LearningObjectType.FinalQuiz && loObject.learningObjectTypeId === LearningObjectType.FinalQuiz) {
                    finalQuizExists = true;
                }
            });

            if (loObject.learningObjectId === 0 && finalQuizExists) {
                this.notifications.addDanger("Final Quiz Existing", "There is already a final quiz existing for this module", NotificationDuration.Standard);
                return;
            }

            response = await this.taskRunner.run(async () => this.learningModuleApiClient.createQuizLearningObject(loObject.toJSObject()));
            if (loObject.learningObjectTypeId === LearningObjectType.FinalQuiz) {
                response.data.learningObjectType = LearningObjectType.FinalQuiz;
            }
        }
        this.resetLearningModuleVm();
        await this.taskRunner.waitFor(this.refreshLOList());
        this.selectedLearningObjectId = response.data.learningObjectId;
        this.selectedLearningObject.learningObjectId = response.data.learningObjectId;
        await this.setSelectedObject(this.selectedLearningObjectId);
        var responseDnd = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningModuleDNDObjects(this.selectedLearningModuleId));
        this.loDragAndDropList = responseDnd.data;
        this.canCreateNewLearningObject = false;
        if (!autoSave) {
            this.notifications.addSuccess("Learning Object Saved", "Learning Object has been saved", NotificationDuration.Standard);
        }
    }

    public async refreshLOList() {
        const LoList = (await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningObjectLookup(this.selectedLearningModuleId))).data;
        this.learningObjectList.set(LoList);
        this.learningObjectList.forEach(async element => {
            element.linkedToResource = (await this.learningModuleApiClient.checkHasLinkedResource(element.learningObjectId)).data ? "Yes" : "No";
        });
    }

    private resetLearningModuleVm() {
        if (this.selectedLearningContentId === 0) {
            this.newLearningContent = new LearningObjectContentLookup();
            this.LearningContentFileComponentVM.fileDescriptorList = new List(FileLookup);
            this.selectedContentTypeId = 0;
        }
        this.hideEditLearningObjectCard = true;
        this.hideUpdate = true;
    }

    public showCriteria() {
        this.hideCriteria = false;
        if (this.selectedLearningModuleId !== 0) {
            this.criteria.learningModuleId = null;
            this.LearningModuleLookupPageManager.pageSize = 10;
            this.LearningModuleLookupPageManager.refreshData();
        }
    }

    //used to track Dnd changes
    public setDNDItems(items: Array<LearningObjectDragableModel>) {
        this.loDragAndDropList = items;
        this.loDragAndDropList.forEach(element => {
            const item = items.find(c => c.id === element.id);
            const newIndex = items.findIndex(c => c.id === element.id);
            if (item?.id === element.id) {
                element.order = newIndex;
            }
        });
    }

    public setQuestionDNDItems(items: Array<LearningObjectDragableModel>) {
        this.questionDragAndDropList = items;
        this.questionDragAndDropList.forEach(element => {
            const item = items.find(c => c.id === element.id);
            const newIndex = items.findIndex(c => c.id === element.id);
            if (item?.id === element.id) {
                element.order = newIndex;
            }
        });
    }

    public async saveLoOrderChanges() {
        for (const dndObject of this.loDragAndDropList) {
            if (dndObject.finalQuizInd) {
                dndObject.order = this.loDragAndDropList.push()
            }
            await this.learningModuleApiClient.updateLearningObjectOrder(dndObject.id, dndObject.order)
        }
        this.refreshLOList();
        var response = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningModuleDNDObjects(this.selectedLearningModuleId));
        this.loDragAndDropList = response.data;
        this.notifications.addSuccess("Learning Objects updated", "Learning Objects updated", NotificationDuration.Standard);
    }

    public async saveQuestionOrderChanges() {
        for (const dndObject of this.questionDragAndDropList) {
            await this.learningModuleApiClient.updateQuestionOrder(dndObject.id, dndObject.order)
        }
        this.refreshLOList();
        this.notifications.addSuccess("Question Order Updated", null, NotificationDuration.Standard);
    }

    public deleteT3FromLo(listOfTag3s: List<LearningObjectTagLookup>, tag3: LearningObjectTagLookup) {
        this.selectedLearningObject.listOfTag3s.removeWithoutTracking(tag3);
    }

    public onAddMediaClick() {
        this.hideMediaUpload = false;
        this.newLearningContent = new LearningObjectContentLookup();
        this.selectedLearningContentId = 0;
        this.LearningContentFileComponentVM.fileDescriptorList = new List(FileLookup);
    }

    public async fetchQuestionsForDnd(learningObjectId: number) {
        var response = await this.taskRunner.waitFor(this.learningModuleApiClient.getQuestionDNDObjects(learningObjectId));
        if (response.data.length > 0) {
            response.data.forEach(element => {
                element.name = truncateLongString(JSON.parse(element.name).blocks[0].text);
            });
            this.hideQuestionDragAndDrop = false;
            return this.questionDragAndDropList = response.data;
        }
        else {
            this.hideQuestionDragAndDrop = true;
            return this.questionDragAndDropList;
        }
    }

    public submitForModeration = async (learningModuleId: number) => {
        var response = await this.learningModuleApiClient.submitModuleForModeration(learningModuleId, this.user!.guid);
        if (!response.data) {
            this.notifications.addDanger("Learning Module Could not be Submitted", "This module does not contain a final quiz, please add a final quiz and try again", NotificationDuration.Long);
        }
        else {
            this.hideEditModuleCard = true;
            this.hideLearningObjectDrag = true;
            this.hideEditTagsCard = true;
            this.hideEditLearningObjectCard = true;
            this.hideLearningObjectCard = true;
            this.clearModuleSearchFilters();
            this.resetVMModule();
            this.notifications.addSuccess("Learning Module Submitted", "Submitted for Moderation. You will be notified via email once module has been moderated", NotificationDuration.Standard);
        }
    }

    public resubmitModuleForModeration = async (moduleId: number) => {
        var response = await this.learningModuleApiClient.resubmitModuleForModeration(moduleId, this.user!.guid);
        if (response.data) {
            this.hideEditModuleCard = true;
            this.hideLearningObjectDrag = true;
            this.hideEditTagsCard = true;
            this.hideEditLearningObjectCard = true;
            this.hideLearningObjectCard = true;
            this.clearModuleSearchFilters();
            this.resetVMModule();
            this.notifications.addSuccess("Learning Module Resubmitted", "Resubmitted for Moderation. You will be notified via email once module has been moderated", NotificationDuration.Standard);
        } else {
            this.notifications.addDanger("Resubmition failed", "Could not Resubmit", NotificationDuration.Standard);
        }
    }

    /// Gets and set the Download path from Blob storage
    public downloadFileGet(this: any, fileDescriptorId: string, mediaTypeId: number, link: string) {
        this.taskRunner.run(async () => {
            var response = await this.learningModuleApiClient.getFileDownloadURL(fileDescriptorId, mediaTypeId);
            if (response.data) {
                window.open(response.data.toString(), '_blank');
            }
        });
    }

    //Fetch a list of all the possible learning objects they can add based on their tag1 as on the module. 
    public showLearningObjectToAdd = async () => {
        //list of possible tag 3s they can pick from
        const responseList = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningObjectTagSelectList(this.selectedLearningModuleId));
        this.learningObjectFilteredTag3List.set(responseList.data);
        this.showAddObjectToModule = true;
        this.learningObjectFilteredTag3List.forEach(tag3 => {
            this.learningObjectSearchCriteria.tag3Ids.push(tag3.tag3Id);
        });
        this.learningObjectSearchCriteria.learningModuleId = this.selectedLearningModuleId;
        this.LearningObjectLookupPageManager.refreshData();
    }

    public learningObjectSearchCriteria = new LearningObjectSearchCriteria();

    public LearningObjectLookupPageManager = new Data.PageManager(this.learningObjectSearchCriteria, LearningObjectLookup, this.learningModuleApiClient.getPagedLearningObjectLookup, {
        pageSize: 15,
        pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
        sortBy: "learningModuleId",
        sortAscending: false,
        initialTaskRunner: this.taskRunner,
        allowSort: true,
        taskRunner: this.taskRunner,
    });

    public searchObjects = async () => {
        this.learningObjectFilteredTag3List.forEach(tag3 => {
            this.learningObjectSearchCriteria.tag3Ids.push(tag3.tag3Id);
        });
        this.LearningObjectLookupPageManager.refreshData();
    }

    public clearObjectsFilters = async () => {
        this.learningObjectSearchCriteria.Title = "";
        this.learningObjectSearchCriteria.keyword = "";
        this.learningObjectFilteredTag3List.forEach(tag3 => {
            this.learningObjectSearchCriteria.tag3Ids.push(tag3.tag3Id);
        });
        this.learningObjectSearchCriteria.learningModuleId = this.selectedLearningModuleId;
        this.learningObjectSearchCriteria.tag3Id = null;
        this.LearningObjectLookupPageManager.refreshData();
    }

    public async addObjectToModule(learningModuduleId: number, learningObject: LearningObjectLookup) {
        this.taskRunner.run(async () => {
            var response = await (this.learningModuleApiClient.addExistingLOToModule(learningModuduleId, learningObject.toJSObject()));
            //Add Learning Object to Selected Learning Module
            if (response.data) {
                this.notifications.addSuccess("Learning object added successfully", null, NotificationDuration.Standard);
                this.showAddObjectToModule = false;
                var responseDnd = await this.learningModuleApiClient.getLearningModuleDNDObjects(this.selectedLearningModuleId);
                this.loDragAndDropList = responseDnd.data;
                this.refreshLOList();
            }
            else {
                this.notifications.addDanger("Learning Object Failed to add", "Learning Object already exists on Module", NotificationDuration.Standard);
                this.showAddObjectToModule = false;
            }
        });
    }

    public summaryLookup = new ModerationSummaryLookup();

    public CheckModerationState = async (moduleId: number) => {
        this.taskRunner.run(async () => {
            this.summaryLookup = new ModerationSummaryLookup();
            this.selectedLearningModuleId = moduleId;

            //get the module Moderation Info
            const learningModule = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningModuleById(moduleId));

            ///set the module summary info 
            this.summaryLookup.moduleId = learningModule.data.learningModuleId;
            this.summaryLookup.moduleTitle = learningModule.data.title.value;
            this.summaryLookup.moduleTitleApproved = learningModule.data.title.fieldState === ModerationState.Moderated ? true : false;
            this.summaryLookup.moduleCPTDPoints = learningModule.data.cptdPoints.value;
            this.summaryLookup.moduleCPTDPointsApproved = learningModule.data.cptdPoints.fieldState === ModerationState.Moderated ? true : false;
            this.summaryLookup.moduleLearningObjective = learningModule.data.description.value;
            this.summaryLookup.moduleLearningObjectiveApproved = learningModule.data.description.fieldState === ModerationState.Moderated ? true : false;
            this.summaryLookup.moduleNotes = learningModule.data.moderationNotes;
            /// Check that the tags are moderated 
            learningModule.data.learningModuleTags.forEach(tagLookUp => {
                var tagSummary = new ModerationModuleTagsSummary();
                tagSummary.tag1Id = tagLookUp.tag1Id;
                tagSummary.tag2Id = tagLookUp.tag2Id;
                tagSummary.tag1Name = tagLookUp.tag1Name;
                tagSummary.tag2Name = tagLookUp.tag2Name;
                tagSummary.approved = tagLookUp.fieldState === ModerationState.Moderated ? true : false;
                tagSummary.errorMessage = tagSummary.approved ? "" : `Category Tag: ${tagLookUp.tag1Name} with Sub-Category Tag: ${tagLookUp.tag2Name} is not approved.`
                this.summaryLookup.allTagsApproved = !tagSummary.approved ? false : true;
                this.summaryLookup.moduleTags.push(tagSummary);
            });
            this.summaryLookup.moduleIsComplete = this.summaryLookup.allTagsApproved && learningModule.data.title.fieldState === ModerationState.Moderated && learningModule.data.cptdPoints.fieldState === ModerationState.Moderated && learningModule.data.description.fieldState === ModerationState.Moderated ? true : false;

            ///Get the learning Objects for this module.
            await this.moderationApiClient.getLearningObjectLookup(moduleId).then(async (learningObjectList) => {

                for (let index = 0; index < learningObjectList.data.length; index++) {
                    const learningObject = learningObjectList.data[index];

                    /// Create a Moderation summary for an object.
                    const object = new ModerationObjectLookup();
                    object.objectId = learningObject.learningObjectId;
                    object.objectTypeId = learningObject.learningObjectTypeId;
                    object.title = learningObject.learningObjectTitle!.value;
                    object.titleApproved = learningObject.learningObjectTitle!.fieldState === ModerationState.Moderated;
                    object.objectNotes = learningObject.moderationNotes;
                    object.purpose = learningObject.learningObjectPurpose!.value;
                    object.purposeApproved = learningObject.learningObjectPurpose!.fieldState === ModerationState.Moderated;
                    object.richText = learningObject.learningObjectText!.value;
                    object.richTextApproved = learningObject.learningObjectText!.fieldState === ModerationState.Moderated;
                    object.objectIsCompleted = object.richTextApproved && object.purposeApproved && object.titleApproved;

                    if (object.objectTypeId === LearningObjectType.Information) {

                        /// Check the tags moderation state for the current object.
                        const loTag3s = await this.moderationApiClient.getLearningObjectTag3s(learningObject.learningObjectId);
                        loTag3s.data.forEach(tag3 => {
                            var objectTag = new ModerationObjectTagsSummary();
                            objectTag.tag3Id = tag3.tag3Id;
                            objectTag.tag3Name = tag3.tag3Name;
                            objectTag.approved = tag3.fieldState === ModerationState.Moderated;
                            objectTag.errorMessage = `${tag3.tag3Name} `

                            if (!objectTag.approved) {
                                object.allObjectTagsApproved = false;
                                object.objectIsCompleted = false;
                            }
                            object.objectTags.push(objectTag);
                        });

                        /// Now check the content 
                        const learningContentList = await this.learningModuleApiClient.getLearningContentByObject(learningObject.learningObjectId);
                        learningContentList.data.forEach(content => {
                            var ObjectContent = new ObjectContentSummary();
                            ObjectContent.contentId = content.learningContentId;
                            ObjectContent.contentName = content.learningContent.title;
                            ObjectContent.approved = content.learningContent.moderation.moderationState === ModerationState.Moderated ? true : false;
                            ObjectContent.contentNotes = content.learningContent.moderation.moderationNotes;
                            if (!ObjectContent.approved) {
                                object.allContentApproved = false;
                                object.objectIsCompleted = false;
                            }
                            object.contentList.push(ObjectContent);
                        });
                    } else {
                        const quizQuestionList = await this.quizLearningObjectApiClient.GetQuizQuestions(learningObject.learningObjectId);
                        const quizLearningContentList = await this.learningModuleApiClient.getLearningContentByObject(learningObject.learningObjectId);

                        if (quizLearningContentList.data.length > 0) {
                            object.quizContent.contentName = quizLearningContentList.data[0].learningContent.title;
                            object.quizContent.contentId = quizLearningContentList.data[0].learningContent.learningContentId;
                            object.quizContent.contentNotes = quizLearningContentList.data[0].learningContent.moderation.moderationNotes;
                            object.approvedQuizContent = quizLearningContentList.data[0].learningContent.moderation.moderationState === ModerationState.Moderated ? true : false;;
                        }

                        for (let index = 0; index < quizQuestionList.data.length; index++) {
                            const question = quizQuestionList.data[index];
                            await this.moderationApiClient.getQuizQuestion(question.questionId, learningObject.learningObjectId).then(async (questionInfo) => {
                                var questionSummary = new ObjectQuestionSummary();
                                questionSummary.question = questionInfo.data.question.value;
                                questionSummary.questionId = questionInfo.data.questionId;
                                questionSummary.approved = questionInfo.data.question.fieldState === ModerationState.Moderated ? true : false;
                                if (questionInfo.data.moderation !== null) {
                                    if (questionInfo.data.moderation!.moderationNotes !== "") {
                                        questionSummary.questionNotes = questionInfo.data.moderation!.moderationNotes;
                                    } else {
                                        questionSummary.questionNotes = "";
                                    }
                                }

                                if (!questionSummary.approved) {
                                    object.allQuestionsApproved = false;
                                    object.objectIsCompleted = false;
                                }

                                if (questionInfo.data.linkedContentId !== 0 && questionInfo.data.linkedContentId !== null) {
                                    const learningContent = await this.learningModuleApiClient.getLearningContentById(questionInfo.data.linkedContentId);
                                    questionSummary.hasContent = true;
                                    var questionContent = new ObjectContentSummary();
                                    questionContent.contentId = learningContent.data.learningContentId;
                                    questionContent.contentName = learningContent.data.learningContent.title;
                                    questionContent.approved = learningContent.data.learningContent.moderation.moderationState === ModerationState.Moderated ? true : false;
                                    questionContent.contentNotes = learningContent.data.learningContent.moderation.moderationNotes;
                                    if (!questionContent.approved) {
                                        questionSummary.questionContentApproved = false;
                                        object.objectIsCompleted = false;
                                    }
                                    questionSummary.questionContent = questionContent;
                                }

                                object.questionList.push(questionSummary);
                            });
                        }
                    }

                    this.summaryLookup.objectList.push(object);
                    if (object.objectIsCompleted === false) {
                        this.summaryLookup.allObjectsApproved = false;
                    }
                }
            }).finally(() => {
                this.summaryLookup.passedModeration = this.summaryLookup.allObjectsApproved && this.summaryLookup.allTagsApproved && this.summaryLookup.moduleIsComplete ? true : false;
                this.showModuleReviewSummaryModal = true;
            });
        });
    }
}