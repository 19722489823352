import { ModelBase, NeoModel, Utils, Validation } from '@singularsystems/neo-core';
import { ReminderFrequency } from './ReminderFrquency.enum';

@NeoModel
export default class UserReminder extends ModelBase {

    public reminderId: number = 0;

    public userGuid: string = Utils.emptyGuid();

    public reminderFrequencyId: ReminderFrequency | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}