import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import Tag1 from '../../Models/Tags/Tag1';
import Tag1SearchCriteria from '../../Models/Criteria/Tag1SearchCriteria';
import Tag1Lookup from '../../Models/Lookups/Tag1Lookup';
export interface ITag1QueryApiClient {

    /** 
     * This method will return a sepcific tag1 based on the circuitId with children
     * @returns Tag1 List with Children
     */
    getTag1List(): AxiosPromise<Array<Model.PlainTrackedObject<Tag1>>>;

    /** 
     * This method will return a sepcific tag1 based on the tag1Id with children
     * @param tag1Id The page request
     * @returns Get Tag with Children
     */
    getTag1(tag1Id: number): AxiosPromise<Model.PlainTrackedObject<Tag1>>;

    /** 
     * This method will return the tags1
     * @param request the paged search criteria request
     * @returns A collection of Tag1 that match the given criteria
     */
    getTag1ListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<Tag1SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<Tag1Lookup>>>;

    /** 
     * Used for Tag1 auto Complete
     * @param filter filter
     * @returns Tag1
     */
    getTag1DropDownFilter(filter: string): AxiosPromise<Array<Model.PlainObject<Tag1>>>;

    // Client only properties / methods
}

@injectable()
export default class Tag1QueryApiClient extends Data.ApiClientBase implements ITag1QueryApiClient {

    public getTag1List(): AxiosPromise<Array<Model.PlainTrackedObject<Tag1>>> {
        return this.axios.get(`${this.apiPath}/Tags`);
    }

    public getTag1(tag1Id: number): AxiosPromise<Model.PlainTrackedObject<Tag1>> {
        return this.axios.get(`${this.apiPath}/Tag/${tag1Id}`);
    }

    public getTag1ListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<Tag1SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<Tag1Lookup>>> {
        return this.axios.get(`${this.apiPath}/TagsPaged?${Utils.getQueryString(request)}`);
    }

    public getTag1DropDownFilter(filter: string): AxiosPromise<Array<Model.PlainObject<Tag1>>> {
        return this.axios.get(`${this.apiPath}/GetTag1DropDownFilter/filtered?filter=${encodeURIComponent(filter)}`);
    }

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/Tag1Query`);
    }
}