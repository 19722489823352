import { Data, List, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { NotificationDuration } from "../../../App/Models/Enums/NotificationDuration.enum";
import { AppService } from "../../../App/Services/AppService";
import { Types } from '../../../Identity/IdentityTypes';
import TDPUser from "../../../Identity/Models/Security/TDPUser";
import LearningModuleSearchCriteria from "../../../Dashboard/Models/SearchCriteria/LearningModuleSearchCriteria";
import LearningPathway from "../../Models/LearningPathways/LearningPathway";
import LearningPathwayModule from "../../Models/LearningPathways/LearningPathwayModule";
import { LearningPathwayType } from "../../Models/LearningPathways/LearningPathwayType.enum";
import LearningModuleLookup from "../../Models/Lookups/LearningModuleLookup";
import Tag2 from "../../Models/Tags/Tag2";
import Tag3 from "../../Models/Tags/Tag3";
import { UserLearningStatus } from "../../Models/UserLearning/UsersLearningStatus.enum";

@NeoModel
export class LearningPathwayEditorVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
    public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
    private tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
    public tag3ApiClient = AppService.get(Types.Learning.ApiClients.Tag3QueryApiClient),
    private dashboardApiClient = AppService.get(Types.Dashboard.ApiClients.DashboardQueryApiClient),
    private learningPathwayApiClient = AppService.get(Types.Learning.ApiClients.LearningPathwayQueryApiClient),
    private identityService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
    super(taskRunner);
    this.user = identityService.user;
  }

  //Properties
  public learningModuleTag2FilteredList = new List(Tag2);
  public learningModuleTag3FilteredList = new List(Tag3);
  public learningPathway = new LearningPathway();
  public selectedLearningModuleList = new List(LearningModuleLookup);
  public isMaximised = true;

  // User Info
  private user: TDPUser | null = null;

  /// Learning Module Pager
  public criteria = new LearningModuleSearchCriteria();

  public learningModulePageManager = new Data.PageManager(this.criteria, LearningModuleLookup, this.dashboardApiClient.getPagedLearningModuleLookup, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "learningModuleTitle",
    sortAscending: true,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });

  public SearchModules = () => {
    this.learningModulePageManager.refreshData();
  }

  public ClearFilters = () => {
    this.criteria.title = "";
    this.criteria.learningModuleId = null;
    this.criteria.learningModuleTag2Id = 0;
    this.criteria.learningModuleTag1Id = 0;
    this.criteria.learningModuleTag3Id = 0;
    this.criteria.learningModuleTag1Name = "";
    this.learningModulePageManager.reset();
  }

  public async SaveLearingPathway() {
    var learningPathway = this.learningPathway;
    learningPathway.learningPathwayType = LearningPathwayType.Mine;
    learningPathway.ownerGuid = this.user!.guid
    this.selectedLearningModuleList.forEach(learningModule => {
      var learningPathwayModel = new LearningPathwayModule();
      learningPathwayModel.learningModuleId = learningModule.learningModuleId;
      learningPathwayModel.learningPathwayId = learningPathway.learningPathwayId;
      learningPathwayModel.status = UserLearningStatus.Awaiting;
      learningPathwayModel.lastQuizMark = 0;
      learningPathway.learningPathwayModules.push(learningPathwayModel);
    });
    if (learningPathway.learningPathwayId === 0) {
      await this.learningPathwayApiClient.saveLearningPathway(learningPathway.toJSObject(), this.user!.guid);
    } else {
      await this.learningPathwayApiClient.saveLearningPathway(learningPathway.toJSObject(), this.user!.guid);
    }
    this.notifications.addSuccess("Save Success", `Learning Pathway Saved`, NotificationDuration.Standard);
  }

  public async FilterTag2ListSearch(tag1Id: number | undefined) {
    if (tag1Id) {
      const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id))).data;
      this.learningModuleTag2FilteredList.set(tag2List);
    }
    this.criteria.learningModuleTag2Id = 0;
  }

  public async FilterModuleTag3ListSearch(tag2Id: number | undefined) {
    this.learningModuleTag3FilteredList = new List(Tag3);
    if (tag2Id) {
      const tag3List = (await this.taskRunner.waitFor(this.tag3ApiClient.getTag3ListByTag2Id(tag2Id))).data;
      this.learningModuleTag3FilteredList.set(tag3List);
    }
    this.criteria.learningModuleTag3Id = 0;
  }

  public AddModuleToPathway(learningModule: LearningModuleLookup) {
    this.selectedLearningModuleList.push(learningModule);
  }

  public RemoveModule(learningModule: LearningModuleLookup) {
    this.selectedLearningModuleList.remove(learningModule);
  }

  public IsSelected(id: number) {
    return this.selectedLearningModuleList.filter(c => c.learningModuleId === id).length > 0;
  }

  public async getLearningPathway(id: number) {
    var response = await this.taskRunner.waitFor(this.learningPathwayApiClient.getLearningPathway(id));
    this.learningPathway.set(response.data);
  }
}