import { Data, List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService } from '../../Identity/IdentityTypes';
import { Types } from '../../Learning/LearningTypes';
import SelfAssessmentLookup from '../../Learning/Models/Lookups/SelfAssessmentLookup';
import Tag2 from '../../Learning/Models/Tags/Tag2';
import SelfAssessmentSearchCriteria from '../Models/SearchCriteria/SelfAssessmentSearchCriteria';
import UserSelfAssessmentLookup from "../Models/UserAssessmentLookup";

@NeoModel
export default class SelfAssessmentVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private dashboardApiClient = AppService.get(Types.Dashboard.ApiClients.DashboardQueryApiClient),
    public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
    private tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  public async initialise() {
    this.assessmentPagerMananger.refreshData();
    this.userAssessmentPagerMananger.refreshData();
  }

  public assessmentTag2FilteredList = new List(Tag2);
  public assessmentCriteria = new SelfAssessmentSearchCriteria();
  public userAssessmentCriteria = new SelfAssessmentSearchCriteria();

  public assessmentPagerMananger = new Data.PageManager(this.assessmentCriteria, SelfAssessmentLookup, this.dashboardApiClient.getAvailableAssessmentsPaged, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "selfAssessmentId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });

  public ClearAssessmentFilters = () => {
    this.assessmentCriteria.SelfAssessmentTitle = "";
    this.assessmentCriteria.FilterByCreated = true;
    this.assessmentCriteria.keyword = "";
    this.assessmentCriteria.SelfAssessmentId = null;
    this.assessmentCriteria.SelfAssessmentTag2Id = 0;
    this.assessmentCriteria.SelfAssessmentTag1Id = 0;
    this.assessmentCriteria.SelfAssessmentTag1Name = "";
    this.assessmentCriteria.StateSelectedUnknown = false;
    this.assessmentCriteria.StateSelectedUnmoderated = true;
    this.assessmentCriteria.StateSelectedSubmittedForModeration = false;
    this.assessmentCriteria.StateSelectedUnderModeration = false;
    this.assessmentCriteria.StateSelectedReview = false;
    this.assessmentCriteria.StateSelectedModerated = false;
    this.assessmentCriteria.StateSelectedResubmittedForModeration = false;
    this.assessmentPagerMananger.refreshData();
  }

  public async FilterAssessmentTag2ListSearch(tag1Id: number | undefined) {
    this.assessmentCriteria.SelfAssessmentTag2Id = 0;
    if (tag1Id) {
      const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id))).data;
      this.assessmentTag2FilteredList.set(tag2List);
    }
  }

  public userAssessmentPagerMananger = new Data.PageManager(this.userAssessmentCriteria, UserSelfAssessmentLookup, this.dashboardApiClient.getUserAssessmentsPaged, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "selfAssessmentId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });
}