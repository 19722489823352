import React from 'react';
import { observer } from 'mobx-react';
import { AppServices, Misc } from '@singularsystems/neo-core';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';

import * as Roles from '../Models/Security/Roles';

import ResourceRoleTree from "../Components/ResourceRoleTree";

import '../Styles/Authorisation.scss';

import UserGroupsVM, { UserGroupsViewState } from './UserGroupsVM';
import UserGroupLookup from '../Models/UserGroupLookup';
import { IReactionDisposer, reaction } from 'mobx';
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import CardIcon from '../../Template/components/Card/CardIcon';
import CardBody from '../../Template/components/Card/CardBody';

class UserGroupsViewParams {
    public userGroup = {};
    public tab = {};
}

@observer
export default class UserGroupsView extends Views.ViewBase<UserGroupsVM, UserGroupsViewParams> {

    public static params = new UserGroupsViewParams();
    public lastTab = "Members";

    constructor(props: unknown) {
        super("User Access", UserGroupsVM, props);

        this.setupReaction();
    }

    public tabChangeReactionDisposer?: IReactionDisposer;

    private setupReaction() {
        this.tabChangeReactionDisposer = reaction(() => {
            return {
                selectedTab: this.viewModel.selectedTab,
            }
        }, async (result) => {
            if (this.viewParams.tab.value !== result.selectedTab) {
                this.viewParams.tab.value = result.selectedTab;
                this.lastTab = result.selectedTab;
            }
        });
    }

    public dispose() {
        if (this.tabChangeReactionDisposer) this.tabChangeReactionDisposer();
    }

    public async viewParamsUpdated() {
        if (this.viewParams.userGroup.value) {
            if (this.viewParams.userGroup.value === "new") {
                if (!this.viewModel.userGroup.isNew) {
                    this.viewModel.addUserGroup();
                }
                this.viewModel.viewState = UserGroupsViewState.ManageItem;
            }
            else {
                await this.viewModel.loadUserGroup(this.viewParams.userGroup.asNullableInt()!);


                if (this.viewModel.userGroup.userGroupId === this.viewParams.userGroup.asNullableInt()) {
                    // set the description for the crumb trail
                    this.viewParams.userGroup.description = this.viewModel.userGroup.userGroupName;

                    if (this.viewParams.tab.value) {
                        this.viewModel.selectedTab = this.viewParams.tab.description = this.viewParams.tab.asString();
                    } else {
                        this.viewParams.tab.value = this.lastTab;
                    }
                } else {
                    // nothing found, so clear the view param
                    this.viewParams.userGroup.value = null;
                }
            }
        } else {
            this.viewModel.viewState = UserGroupsViewState.ManageList;
        }
    }

    private authorisationService = Misc.Globals.appService.get(AppServices.NeoTypes.Security.AuthorisationService);

    private canUpdateUserGroupName() {
        return this.authorisationService.hasRole(Roles.UserAccess.Update) && !this.viewModel.userGroup.isAdministratorGroup;
    }

    private canDeleteUserGroup() {
        return this.authorisationService.hasRole(Roles.UserAccess.Update) && !this.viewModel.userGroup.isAdministratorGroup;
    }

    private canUpdateMembers() {
        return this.authorisationService.hasRole(Roles.UserAccess.Update);
    }

    private back() {
        this.viewParams.userGroup.value = null;
    }

    private manageUserGroup(item: UserGroupLookup) {
        this.viewParams.userGroup.value = item.userGroupId;
    }

    private addUserGroup() {
        this.viewParams.userGroup.value = "new";
    }

    public render() {
        return (
            <React.Fragment>
                <div className="pt-4">
                    {/* UPPER BUTTONS */}
                    <div className="toolbar row">
                        <div className="col-11 col-md-10">
                            {(this.viewModel.viewState !== UserGroupsViewState.ManageList) &&
                                <div>
                                    <Neo.Button variant={'primary'} isOutline icon="caret-left"
                                        onClick={() => this.back()} >
                                        Back to groups
                                    </Neo.Button>
                                </div>}
                        </div>
                    </div>
                    {this.viewModel.viewState === UserGroupsViewState.ManageList &&

                        <div>
                            <Card>
                                <CardHeader icon>
                                    <CardIcon color="success">
                                        <i className={`icon fa fa-user-shield fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">User groups</h4>
                                </CardHeader>
                                <CardBody>
                                    {this.authorisationService.hasRole(Roles.UserAccess.Update) &&
                                        <Neo.Button variant={'primary'} icon="plus"
                                            onClick={() => this.addUserGroup()}>
                                            Add new group
                                        </Neo.Button>}
                                    <div className="manage-user-group-list mt-4">
                                        {/* LOADING MESSAGE */}
                                        {!this.viewModel.userGroupsLoaded && <div className="loading">
                                            <h5>Loading...</h5> </div>}
                                        {/* NO USER GROUPS MESSAGE */}
                                        {this.viewModel.userGroupsLoaded && this.viewModel.userGroups.length === 0 &&
                                            <div className="add-first-message">
                                                {this.authorisationService.hasRole(Roles.UserAccess.Update) &&
                                                    <h5>No user groups, click 'Add new group' to add your first user group</h5>}
                                                {!this.authorisationService.hasRole(Roles.UserAccess.Update) &&
                                                    <h5>No user groups, and you do not have sufficient security privileges to add user groups</h5>}
                                            </div>}
                                        {/* USER GROUPS GRID */}
                                        {this.viewModel.userGroups.length > 0 &&
                                            <div className="user-groups-grid">
                                                <NeoGrid.Grid
                                                    items={this.viewModel.userGroups} >
                                                    {item => (
                                                        <NeoGrid.Row>
                                                            <NeoGrid.Column display={item.meta.userGroupName} />
                                                            <NeoGrid.Column display={item.meta.memberCount} />
                                                            <NeoGrid.Column display={item.meta.roleCountDisplay} className="number-col" />
                                                            <NeoGrid.ButtonColumn>
                                                                <Neo.Button variant="success" icon="edit" onClick={() => this.manageUserGroup(item)} >
                                                                    <span className="d-none d-lg-inline">{this.authorisationService.hasRole(Roles.UserAccess.Update) ? "Manage" : "View"}</span>
                                                                </Neo.Button>
                                                                {this.canDeleteUserGroup() &&
                                                                    <Neo.Button variant="danger" icon="trash" onClick={() => this.viewModel.deleteUserGroup(item)} >
                                                                        <span className="d-none d-lg-inline">Delete</span>
                                                                    </Neo.Button>}
                                                            </NeoGrid.ButtonColumn>
                                                        </NeoGrid.Row>
                                                    )}
                                                </NeoGrid.Grid>
                                            </div>}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    }
                    {/* MANAGE USER GROUP */}
                    {this.viewModel.viewState === UserGroupsViewState.ManageItem &&
                        <div>
                            <Card>
                                <CardHeader icon>
                                    <CardIcon color="success">
                                        <i className={`icon fa fa-user-shield fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">User groups</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="manage-user-group">
                                        <Neo.Form model={this.viewModel.userGroup}
                                            onSubmit={() => this.viewModel.save()} >
                                            <div className="row">
                                                <div className="col-10">
                                                    <Neo.FormGroupInline
                                                        bind={this.viewModel.userGroup.meta.userGroupName}
                                                        editorProps={{
                                                            readOnly: !this.canUpdateUserGroupName(),
                                                            autoFocus: this.viewModel.userGroup.isNew,
                                                            placeholder: "New user group"
                                                        }} />
                                                </div>
                                                <div className="col-2">
                                                    {this.authorisationService.hasRole(Roles.UserAccess.Update) &&
                                                        <Neo.Button
                                                            isSubmit
                                                            className={this.viewModel.userGroup.isDirty || this.viewModel.resourceRoles.isDirty ? "primary-color-pulse" : ""}
                                                            disabled={this.viewModel.canSave()}
                                                            variant="primary" icon="check" >
                                                            <span className="d-none d-lg-inline">Save</span>
                                                        </Neo.Button>}
                                                </div>
                                            </div>
                                        </Neo.Form>
                                        {!this.viewModel.userGroup.isNew &&
                                            <Neo.TabContainer selectedTab={this.viewModel.meta.selectedTab}>
                                                {/* GROUP MEMBERS */}
                                                <Neo.Tab header={"Members (" + this.viewModel.groupMemberships.length + ")"} icon="users" name="Members" >
                                                    <div className="group-members">
                                                        {/* ADD MEMBER */}
                                                        {this.canUpdateMembers() &&
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <Neo.AutoCompleteDropDown
                                                                        bind={this.viewModel.memberFindCriteria.meta.addUserGuid}
                                                                        bindDisplay={this.viewModel.memberFindCriteria.meta.addCombinedName}
                                                                        displayMember="fullName"
                                                                        valueMember="userGuid"
                                                                        itemSource={this.viewModel.userQueryApiClient.findAdminUsers}
                                                                        loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                                                                        minCharacters={2}
                                                                        menuPosition="fixed"
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <Neo.Button variant="primary" icon="user-plus"
                                                                        disabled={this.viewModel.isSaving}
                                                                        onClick={() => this.viewModel.addMember()} >
                                                                        <span className="d-none d-lg-inline">Add</span>
                                                                    </Neo.Button>
                                                                </div>
                                                            </div>}
                                                        {/* MEMBER LIST */}
                                                        {this.viewModel.groupMemberships.length > 0 &&
                                                            <NeoGrid.Grid
                                                                items={this.viewModel.groupMemberships} >
                                                                {item => (
                                                                    <NeoGrid.Row>
                                                                        <NeoGrid.Column display={item.meta.memberName} />
                                                                        <NeoGrid.Column display={item.meta.userName} />
                                                                        <NeoGrid.ButtonColumn hidden={!this.canUpdateMembers}>
                                                                            <Neo.Button variant="danger" icon="user-times"
                                                                                onClick={() => this.viewModel.removeMember(item)} >
                                                                                <span className="d-none d-lg-inline">Remove</span>
                                                                            </Neo.Button>
                                                                        </NeoGrid.ButtonColumn>
                                                                    </NeoGrid.Row>)}
                                                            </NeoGrid.Grid>}
                                                    </div>
                                                </Neo.Tab>
                                                <Neo.Tab header={"Roles (" + this.viewModel.assignedRolesLength + ")"} icon="user-lock" name="Roles">
                                                    {/* LOADING ROLES MESSAGE */}
                                                    {this.viewModel.rolesTask.isBusy &&
                                                        <div className="add-first-message"> <h4>Loading available roles...</h4> </div>}
                                                    {/* NO ROLES MESSAGE */}
                                                    {!this.viewModel.rolesTask.isBusy && this.viewModel.resourceRoles.length === 0 &&
                                                        <div className="add-first-message"> <h4>No roles available, add authorisation to your resources.</h4> </div>}
                                                    <div className="row">
                                                        <div className="group-roles">
                                                            <Neo.Loader task={this.viewModel.rolesTask}>
                                                                <ResourceRoleTree items={this.viewModel.resourceRoles} enabled={!this.viewModel.userGroup.isAdministratorGroup} />
                                                            </Neo.Loader>
                                                        </div>
                                                        <div className="role-tools">
                                                            <Neo.Button size="sm" icon="undo" data-tip="Undo role changes" hidden={!this.viewModel.resourceRoles.isDirty}
                                                                onClick={() => this.viewModel.resetAssignedRoles()} >

                                                            </Neo.Button>
                                                        </div>
                                                    </div>
                                                </Neo.Tab>
                                            </Neo.TabContainer>}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}