import { List, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../App/Services/AppService";
import Organisation from "../Models/Organisations/Organisation";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum"

@NeoModel
export default class OrganisationsComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private organisationApiClient = AppService.get(Types.Organisations.ApiClients.OrganisationApiClient),
        private organisationQueryApiClient = AppService.get(Types.Organisations.ApiClients.OrganisationQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    public organisationList = new List(Organisation);
    public enableEdit: boolean = false;
    public selectedOrganisationId: number = 0;

    public async initialise() {
        this.LoadOrganisationList();
    }

    public async LoadOrganisationList() {
        const getOrganisationListResponse = await this.taskRunner.waitFor(this.organisationApiClient.get());
        if (getOrganisationListResponse.data !== null) {
            this.organisationList.set(getOrganisationListResponse.data);
        }
    }

    public async saveOrganisations() {
        this.taskRunner.run(async () => {
            const response = await this.organisationApiClient.saveList(this.organisationList.toJSArray());
            this.organisationList.update(response.data)
            this.notifications.addSuccess("Organisations saved", "", NotificationDuration.Short);
        })
        this.enableEdit = false;
    }

    public async deleteOrganisation(organisationId: number) {
        this.taskRunner.run(async () => {
            await this.organisationApiClient.delete(organisationId);
            this.LoadOrganisationList();
            this.notifications.addSuccess("Organisation Deleted", null, NotificationDuration.Short);
        })
    }

    public async deleteOrganisationPopUp(organisationName: string, organisationId: number) {
        const response = await this.taskRunner.waitFor(this.organisationQueryApiClient.checkOrganisaionLinks(organisationId));
        if (response.data) {
            ModalUtils.showMessage("Cannot Delete " + organisationName, "This organisation has provinces linked to it and cannot be deleted", 3)
        }
        else {
            await ModalUtils.showYesNoDismissible("Delete Organisation " + organisationName,
                "Are you sure you want to delete this Organisation? ", () => this.deleteOrganisation(organisationId))

        }
    }

    public async setSelectedOrganisation(organisationId: number) {
        // Unselect Circuit
        if (this.selectedOrganisationId === organisationId) {
            this.selectedOrganisationId = 0;
        }
        // Set Selected Circuit
        else if (organisationId) {
            this.selectedOrganisationId = organisationId;
        } else {
            this.selectedOrganisationId = 0
        }
    }
}