import React from "react";
import { observer } from "mobx-react";
import Card from '../../Template/components/Card/Card';
import CardBody from '../../Template/components/Card/CardBody';
import CardFooter from '../../Template/components/Card/CardFooter';
import CardAvatar from '../../Template/components/Card/CardAvatar';
import { Tooltip } from "@material-ui/core";
import Confetti from 'react-confetti';
import { AppService } from "../../Dashboard/DashboardTypes";
import Types from "../../App/AppTypes";
import { Views } from "@singularsystems/neo-react";
import { NeoModel } from "@singularsystems/neo-core";

interface IBadgeAwardProps {
  badgeAwardMessage: string;
  badgeTitle: string;
  earnedDate: string;
  imageSrc: string;
  badgeId: number;
}

@NeoModel
export class BadgeAwardVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner)) {
    super(taskRunner);
  }
  public showConfetti = false;

  /// use this to set a timeout or delay
  private delay = (ms: number) => new Promise(result => setTimeout(result, ms))

  public celebrate = async () => {
    this.showConfetti = true;
    await this.delay(3000);
    this.showConfetti = false;
  }
}

@observer
export default class BadgeAward extends React.Component<IBadgeAwardProps> {
  private viewModel = new BadgeAwardVM();
  constructor(props: IBadgeAwardProps) {
    super(props)
    this.viewModel = new BadgeAwardVM();
    this.cardRef = React.createRef();
  }

  // use this to get the current size of the component
  public cardRef: React.RefObject<HTMLDivElement>
  render() {
    const containerRect = this.cardRef;
    return (
      <div ref={containerRect}>
        <Card testimonial className="testimonial-Badge" >
          <Confetti hidden={!this.viewModel.showConfetti} width={this.cardRef.current?.clientWidth} height={this.cardRef.current?.clientHeight} />
          <div style={{ marginTop: 30 }} >
            <i className=" fa fa-award fa-3x"></i>
            <h4 className="">{this.props.badgeTitle}</h4>
          </div>
          <CardBody>
            <h5 className="card-award-title">
              {this.props.badgeAwardMessage}
            </h5>
          </CardBody>
          <CardFooter testimonial>
            <h6 className="card-award-title">{this.props.earnedDate}</h6>
            <CardAvatar profile testimonialFooter className="badge-square-image">
              <Tooltip
                id="tooltip-top"
                title="Click me"
                placement="bottom"
              >
                <img style={{ minWidth: "130px", minHeight: "130px" }} className="zoom" src={this.props.imageSrc} alt="..." onClick={() => this.viewModel.celebrate()} />
              </Tooltip>
            </CardAvatar>
          </CardFooter>
        </Card>
      </div>
    )
  }
}