import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'
import School from '../../Models/Organisations/School';

export interface ISchoolApiClient extends Data.IUpdateableApiClient<School, number> {

    // Client only properties / methods
}

@injectable()
export default class SchoolApiClient extends Data.UpdateableApiClient<School, number> implements ISchoolApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/School`);
    }

    // Client only properties / methods
}