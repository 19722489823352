import { Attributes, ModelBase, Rules, Validation } from '@singularsystems/neo-core';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';
import ModerationObject from '../../Moderation/ModerationObject';
import { LearningContentMediaType } from './LearningContentMediaType.enum';

export interface ILearningContent {
    learningContentId: number
}

export default abstract class LearningContentBase extends ModelBase {

    constructor() {
        super()
        this.moderation = new ModerationObject();
        this.mediaType = LearningContentMediaType.Unsupported;
    }
    public learningContentId: number = 0;

    public learningObjectId: number = 0;

    @Rules.StringLength(100)
    @Required("Title Is required")
    public title: string = "";

    @Attributes.ChildObject(ModerationObject)
    public moderation: ModerationObject;

    @Attributes.Integer()
    public orderNumber: number = 0;

    @Rules.Required("Please choose a media Type")
    public mediaType: LearningContentMediaType;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}