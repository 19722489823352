import AppTypes from '../App/AppTypes';
import { AppService } from '../App/Services/AppService';
import { ForumExportedTypes } from './ForumExportedTypes';

const Types = {
    ...AppTypes,
    Forum: {
        ApiClients: {
            ...ForumExportedTypes.ApiClients
        },
        Services: {
            ...ForumExportedTypes.Services
        }
    }
}

export {AppService, Types}