import { Attributes, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import LearningContentBase from '../../Models/LearningObjects/LearningContent/LearningContentBase';
import MediaObject from '../../Models/LearningObjects/LearningContent/MediaObject';
import { validURL } from '../../../App/HelperClasses/GlobalHelpers'
import { LearningContentMediaType } from './LearningContent/LearningContentMediaType.enum';

@NeoModel
export default class LearningContentCombinedObject extends LearningContentBase {

    @Attributes.ChildObject(MediaObject)
    public mediaObject: MediaObject = new MediaObject();

    @Rules.StringLength(2088)

    public externalResourceLocation: string = "";

    @Rules.StringLength(100)
    public externalResourceLocationKey: string | null = "";

    public displayResource: string = "";

    public conversationId: number = 0;

    public initialPost: string = "";

    // Client only properties / methods

    public allowedFileExtensions: string | null = "";

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => validURL(c.externalResourceLocation as string) === false, "Please Enter a valid url");
        rules.failWhen(c => ((c.externalResourceLocation === null || c.externalResourceLocation.length === 0) && c.mediaType === LearningContentMediaType.SCORM), "Alternative URL is required for SCORM files")
    }

    public toString(): string {
        if (this.isNew) {
            return "New learning content";
        } else {
            return "Learning Content ";
        }
    }

}