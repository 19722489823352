import { Attributes, Misc, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { TopicTypes } from '../Enums/TopicTypes.enum';
import ForumEntity from './ForumEntity';

@NeoModel
export default class Topic extends ModelBase {

    public topicId: number = 0;

    @Rules.Required()
    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public forumId: number | null = null;

    @Attributes.ChildObject(ForumEntity)
    public forum: ForumEntity | null = null;

    @Rules.Required()
    @Rules.StringLength(500)
    public topicName: string = "";

    @Rules.Required()
    public topicType: TopicTypes = TopicTypes.Unspecified;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.topicName) {
            return "New topic";
        } else {
            return this.topicName;
        }
    }
}