import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import ContentConsumptionLookup from './ContentConsumptionLookup';
import QuizQuestionConsumptionLookup from './QuizQuestionConsumptionLookup';

@NeoModel
export default class InformationObjectConsumptionLookup extends ModelBase {

  public learningObjectId: number = 0;

  public title: string = "";

  public purpose: string = "";

  public objectText: string = "";

  public contentList = new List(ContentConsumptionLookup);

  public learningPathwayId: number = 0;

  public learningPathwayTitle: string = "";

  @Attributes.Integer()
  public order: number = 0;

  // Client only properties / methods

  public question = new QuizQuestionConsumptionLookup();

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.title) {
      return "New information object consumption lookup";
    } else {
      return this.title;
    }
  }
}