import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import PersalEducatorImportResult from '../../Models/Commands/PersalEducatorImportResult';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'

export interface IPersalEducatorImportApiClient {

    /** 
     * Uploads an excel file with participant data.
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @returns A file key to be passed into CheckImportFile().
     */
    getUploadEducatorFileUrl(): string;

    /** 
     * Processes an import for a previously uploaded file.
     * @param key Key returned from upload educator file.
     * @returns Import Result file.
     */
    doImport(key: string): AxiosPromise<Model.PlainObject<PersalEducatorImportResult>>;

    /** 
    * Returns the date time of the last import.
    * @returns date time of the last import.
    */
    lastImportDate(): AxiosPromise<Date>;

    // Client only properties / methods
}

@injectable()
export default class PersalEducatorImportApiClient extends Data.ApiClientBase implements IPersalEducatorImportApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/PersalEducatorImport`);
    }

    public getUploadEducatorFileUrl(): string {
        return `${this.apiPath}/uploadFile`;
    }

    public doImport(key: string): AxiosPromise<Model.PlainObject<PersalEducatorImportResult>> {
        return this.axios.post(`${this.apiPath}/do-import/${encodeURIComponent(key)}`);
    }

    public lastImportDate(): AxiosPromise<Date> {
        return this.axios.get(`${this.apiPath}/last-import-date`);
    }
    // Client only properties / methods
}