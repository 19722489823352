import { Attributes, Misc, Utils, Validation, ValueObject } from '@singularsystems/neo-core';
import { ModerationState } from '../LearningObjects/ModerationState.enum';

export default abstract class ModeratableFieldBase extends ValueObject {

    public modifiedBy: string = Utils.emptyGuid();

    @Attributes.Date(Misc.TimeZoneFormat.None)
    public modifiedDate: Date = new Date();

    public moderatedBy: string | null = null;

    @Attributes.Date(Misc.TimeZoneFormat.None)
    public moderatedDate: Date | null = null;

    public fieldState: ModerationState = ModerationState.Unmoderated;

    // Client only properties / methods

    public ApprovedByModerator: boolean = false;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Moderatable Field Base";
    }

}