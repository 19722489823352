import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import ImportUserOrganisationVM from "./ImportUserOrganisationVM";

import UserDataImportModal from "../../Components/UserDataImportModal";
import UserOrgLevelMaintenanceComponent from "../../Components/UserOrgLevelMaintenanceComponent";
import Card from "../../../Template/components/Card/Card";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardIcon from "../../../Template/components/Card/CardIcon";
import CardBody from "../../../Template/components/Card/CardBody";

@observer
export default class ImportUserOrganisationView extends Views.ViewBase<ImportUserOrganisationVM>{

    constructor(props: unknown) {
        super("Import User Organisation", ImportUserOrganisationVM, props);
        this.viewModel.LoadOrganisationList();
    }

    public async ShowUserImportModal() {
        this.viewModel.userDataImportModalVM.show()
    }

    public async CloseUserImportModal() {
        this.viewModel.userDataImportModalVM.showUserImportModal = false;
    }

    private Ellipsis(stringin: string) {
        const maxLen = 15;
        if (stringin) {
            if (stringin.length > maxLen) {
                return stringin.substr(0, maxLen) + "..";
            } else {
                return stringin;
            }
        } else {
            return "";
        }
    }

    public render() {
        return (
            <div>
                <Neo.Loader task={this.viewModel.taskRunner}>
                    <Neo.Button variant="success" isOutline icon="refresh" onClick={() => this.viewModel.LoadOrganisationList()} >Refresh List</Neo.Button>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fa fa-sitemap fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Organisations</h4>
                            </CardHeader>
                            <CardBody>
                                <NeoGrid.Grid items={this.viewModel.OrganistationList} initialSortAscending={false} initialSort={"organisationId"}>
                                    {(organisation, organisastionMeta) => (
                                        <NeoGrid.RowGroup expandProperty={organisastionMeta.isExpanded}>
                                            <NeoGrid.Row onMouseEnter={() => this.viewModel.hoverOrganisationId = organisation.organisationId} onMouseLeave={() => this.viewModel.hoverOrganisationId = -1}                                     >
                                                <NeoGrid.Column label="Name" display={organisastionMeta.organisationName} />
                                                <NeoGrid.Column display={organisastionMeta.usersCount} label="Organization Count" />
                                                <NeoGrid.Column display={organisastionMeta.usersCountIncldChildren} label="Province Count" />
                                                <NeoGrid.ButtonColumn label="Import" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                    hidden={!(this.viewModel.hoverOrganisationId === organisation.organisationId)}
                                                    disabled={!(this.viewModel.isAdmin ||
                                                        ((this.viewModel.currentUserOrgLevel.organisationId === organisation.organisationId || this.viewModel.currentUserOrgLevel.organisationId === null) &&
                                                        (this.viewModel.importRole && this.viewModel.organisationRole)))}
                                                    onClick={() => this.viewModel.importUsersOrganisation(organisation)} >Import {organisation.organisationName} Users</Neo.Button></NeoGrid.ButtonColumn>

                                                <NeoGrid.ButtonColumn label="Edit" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                    hidden={!(this.viewModel.hoverOrganisationId === organisation.organisationId)}
                                                    onClick={() => this.viewModel.userOrgLevelMaintenanceComponentVM.editOrganisation(organisation)}
                                                    disabled={!(organisation.usersCount > 0 &&
                                                        (this.viewModel.isAdmin ||
                                                        (this.viewModel.currentUserOrgLevel.organisationId === null || this.viewModel.currentUserOrgLevel.organisationId === organisation.organisationId)) &&
                                                        (this.viewModel.editRole && this.viewModel.organisationRole))} >Edit {organisation.organisationName} Users</Neo.Button></NeoGrid.ButtonColumn>
                                            </NeoGrid.Row>
                                            <NeoGrid.ChildRow>
                                                <NeoGrid.Grid items={organisation.provinces} initialSortAscending={true} initialSort={"provinceName"}>
                                                    {(province, provinceMeta) => (
                                                        <NeoGrid.RowGroup expandProperty={provinceMeta.isExpanded}>
                                                            <NeoGrid.Row onMouseEnter={() => this.viewModel.hoverProvinceId = province.provinceId} onMouseLeave={() => this.viewModel.hoverProvinceId = -1} >
                                                                <NeoGrid.Column display={provinceMeta.provinceName} />
                                                                <NeoGrid.Column display={provinceMeta.usersCount} label="Province Count" />
                                                                <NeoGrid.Column display={provinceMeta.usersCountIncldChildren} label="District Count" />
                                                                <NeoGrid.Column label="Import" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                                    hidden={!(this.viewModel.hoverProvinceId === province.provinceId)}
                                                                    disabled={!(this.viewModel.isAdmin ||
                                                                        ((this.viewModel.currentUserOrgLevel.provinceId === provinceMeta.provinceId.value || this.viewModel.currentUserOrgLevel.provinceId === null) &&
                                                                        (this.viewModel.currentUserOrgLevel.organisationId === organisation.organisationId || this.viewModel.currentUserOrgLevel.organisationId === null) &&
                                                                        (this.viewModel.importRole && this.viewModel.provinceRole)))}
                                                                    onClick={() => this.viewModel.importUsersProvince(organisation, province)} >Import {province.provinceName} Users</Neo.Button></NeoGrid.Column>

                                                                <NeoGrid.ButtonColumn label="Edit" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                                    hidden={!(this.viewModel.hoverProvinceId === province.provinceId)}
                                                                    onClick={() => this.viewModel.userOrgLevelMaintenanceComponentVM.editProvince(organisation, province)}
                                                                    disabled={!(province.usersCount > 0 && 
                                                                        (this.viewModel.isAdmin ||
                                                                        ((this.viewModel.currentUserOrgLevel.provinceId === null || this.viewModel.currentUserOrgLevel.provinceId === province.provinceId) &&
                                                                        (this.viewModel.currentUserOrgLevel.organisationId === organisation.organisationId || this.viewModel.currentUserOrgLevel.organisationId === null) &&
                                                                        (this.viewModel.editRole && this.viewModel.provinceRole))))}>Edit {province.provinceName} Users</Neo.Button></NeoGrid.ButtonColumn>
                                                            </NeoGrid.Row>
                                                            <NeoGrid.ChildRow>
                                                                <NeoGrid.Grid items={province.districts} initialSortAscending={true} initialSort={"districtName"}>
                                                                    {(district, districtMeta) => (
                                                                        <NeoGrid.RowGroup expandProperty={districtMeta.isExpanded}>
                                                                            <NeoGrid.Row onMouseEnter={() => this.viewModel.hoverDistrictId = district.districtId} onMouseLeave={() => this.viewModel.hoverDistrictId = -1} >
                                                                                <NeoGrid.Column display={districtMeta.districtName} />
                                                                                <NeoGrid.Column display={districtMeta.usersCount} label="District Count" />
                                                                                <NeoGrid.Column display={districtMeta.usersCountIncldChildren} label="Circuit Count" />
                                                                                <NeoGrid.ButtonColumn label="Import" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                                                    hidden={!(this.viewModel.hoverDistrictId === district.districtId)}
                                                                                    disabled={!(this.viewModel.isAdmin ||
                                                                                        ((this.viewModel.currentUserOrgLevel.districtId === null || this.viewModel.currentUserOrgLevel.districtId === district.districtId) &&
                                                                                        (this.viewModel.currentUserOrgLevel.provinceId === null || this.viewModel.currentUserOrgLevel.provinceId === district.provinceId) &&
                                                                                        (this.viewModel.currentUserOrgLevel.organisationId === null || this.viewModel.currentUserOrgLevel.organisationId === district.organisationId) &&
                                                                                        (this.viewModel.importRole && this.viewModel.districtRole)))}
                                                                                    onClick={() => this.viewModel.importUsersDistrict(organisation, province, district)} >Import {this.Ellipsis(district.districtName)} District Users</Neo.Button></NeoGrid.ButtonColumn>

                                                                                <NeoGrid.ButtonColumn label="Edit" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                                                    hidden={!(this.viewModel.hoverDistrictId === district.districtId)}
                                                                                    onClick={() => this.viewModel.userOrgLevelMaintenanceComponentVM.editDistrict(organisation, province, district)}
                                                                                    disabled={!(district.usersCount > 0 && 
                                                                                        (this.viewModel.isAdmin ||
                                                                                        ((this.viewModel.currentUserOrgLevel.districtId === null || this.viewModel.currentUserOrgLevel.districtId === district.districtId) &&
                                                                                        (this.viewModel.currentUserOrgLevel.provinceId === null || this.viewModel.currentUserOrgLevel.provinceId === district.provinceId) &&
                                                                                        (this.viewModel.currentUserOrgLevel.organisationId === null || this.viewModel.currentUserOrgLevel.organisationId === district.organisationId) &&
                                                                                        (this.viewModel.editRole && this.viewModel.districtRole))))}> Edit {this.Ellipsis(districtMeta.districtName.value)} Users</Neo.Button></NeoGrid.ButtonColumn>
                                                                            </NeoGrid.Row>
                                                                            <NeoGrid.ChildRow>
                                                                                <NeoGrid.Grid items={district.circuits} initialSortAscending={true} initialSort={"circuitName"}>
                                                                                    {(circuit, circuitMeta) => (

                                                                                        <NeoGrid.Row onMouseEnter={() => this.viewModel.hoverCircuitId = circuit.circuitId} onMouseLeave={() => this.viewModel.hoverCircuitId = -1} >
                                                                                            <NeoGrid.Column display={circuitMeta.circuitName} />
                                                                                            <NeoGrid.Column display={circuitMeta.usersCount} label="Circuit Count" />
                                                                                            <NeoGrid.Column display={circuitMeta.usersCountIncldChildren} label="School Count" />
                                                                                            <NeoGrid.ButtonColumn label="Import" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                                                                hidden={!(this.viewModel.hoverCircuitId === circuit.circuitId)}
                                                                                                disabled={!(this.viewModel.isAdmin ||
                                                                                                    ((this.viewModel.currentUserOrgLevel.districtId === null || this.viewModel.currentUserOrgLevel.districtId === district.districtId) &&
                                                                                                    (this.viewModel.currentUserOrgLevel.circuitId === null || this.viewModel.currentUserOrgLevel.circuitId === circuit.circuitId) &&
                                                                                                    (this.viewModel.currentUserOrgLevel.provinceId === null || this.viewModel.currentUserOrgLevel.provinceId === district.provinceId) &&
                                                                                                    (this.viewModel.currentUserOrgLevel.organisationId === null || this.viewModel.currentUserOrgLevel.organisationId === circuit.organisationId) &&
                                                                                                    (this.viewModel.importRole && this.viewModel.circuitRole)))}
                                                                                                onClick={() => this.viewModel.importUsersCircuit(organisation, province, district, circuit)} >Import {this.Ellipsis(circuit.circuitName)} Circuit Users</Neo.Button></NeoGrid.ButtonColumn>

                                                                                            <NeoGrid.ButtonColumn label="Edit" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                                                                hidden={!(this.viewModel.hoverCircuitId === circuit.circuitId)}
                                                                                                onClick={() => this.viewModel.userOrgLevelMaintenanceComponentVM.editCircuit(organisation, province, district, circuit)}
                                                                                                disabled={!(circuit.usersCount > 0 && 
                                                                                                    (this.viewModel.isAdmin || 
                                                                                                    ((this.viewModel.currentUserOrgLevel.districtId === null || this.viewModel.currentUserOrgLevel.districtId === district.districtId) &&
                                                                                                    (this.viewModel.currentUserOrgLevel.circuitId === null || this.viewModel.currentUserOrgLevel.circuitId === circuit.circuitId) &&
                                                                                                    (this.viewModel.currentUserOrgLevel.provinceId === null || this.viewModel.currentUserOrgLevel.provinceId === district.provinceId) &&
                                                                                                    (this.viewModel.currentUserOrgLevel.organisationId === null || this.viewModel.currentUserOrgLevel.organisationId === circuit.organisationId) &&
                                                                                                    (this.viewModel.editRole && this.viewModel.circuitRole))))}>Edit {this.Ellipsis(circuit.circuitName)} Users</Neo.Button></NeoGrid.ButtonColumn>
                                                                                        </NeoGrid.Row>
                                                                                    )}
                                                                                </NeoGrid.Grid>
                                                                            </NeoGrid.ChildRow>
                                                                        </NeoGrid.RowGroup>
                                                                    )}
                                                                </NeoGrid.Grid>
                                                            </NeoGrid.ChildRow>
                                                        </NeoGrid.RowGroup>
                                                    )}
                                                </NeoGrid.Grid>
                                            </NeoGrid.ChildRow>
                                        </NeoGrid.RowGroup>
                                    )}
                                </NeoGrid.Grid>

                                {this.viewModel.OrganistationList.length === 0 &&
                                    <div className="col-md-12 p-0 text-center">
                                        <p>No Records</p>
                                    </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Neo.Loader>

                {/* {Modals } */}
                <UserDataImportModal viewModal={this.viewModel.userDataImportModalVM} usersImported={this.CloseUserImportModal.bind(this)} />
                <UserOrgLevelMaintenanceComponent viewModel={this.viewModel.userOrgLevelMaintenanceComponentVM} orgLevel={this.viewModel.editOrgLevel} orgLevelName={this.viewModel.orgLevelName} />
            </div>
        );
    }
}
