import { Attributes, Misc, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { RichTextEditorVM } from '../../../Learning/Components/RichTextEditor';
import Topic from './Topic';

@NeoModel
export default class Post extends ModelBase {

    public postId: number = 0;

    @Rules.Required()
    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public topicId: number | null = null;

    @Attributes.ChildObject(Topic)
    public topic: Topic | null = null;

    @Rules.StringLength(4096)
    public body: string = "";

    // Client only properties / methods

    @Attributes.NoTracking()
    public viewModel = new RichTextEditorVM();

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.body) {
            return "New post";
        } else {
            return this.body;
        }
    }
}