import { Data } from '@singularsystems/neo-core';
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import PagerTextFilter from "../../App/Components/PagerTextFilter";
import { UserTypes } from "../../App/Models/Enums/UserTypes.enums";
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import CardIcon from '../../Template/components/Card/CardIcon';
import CardBody from '../../Template/components/Card/CardBody';
import SelectOrgLevelComponent from '../Components/SelectOrgLevelComponent';
import UserProfileLookup from "../Models/Users/Lookups/UserProfileLookup";
import UsersVM from "./UserMaintenanceVM";
import ForumDrawer from '../../Forum/Components/ForumComponents/ForumDrawer';
import CardFooter from '../../Template/components/Card/CardFooter';

@observer
export default class UserMaintenanceView extends Views.ViewBase<UsersVM>{
    constructor(props: unknown) {
        super("Users", UsersVM, props);
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`icon fa fa-user-shield fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">User Maintenance</h4>
                                <Neo.Button className="report-action-button btn btn-secondary" onClick={() => this.viewModel.downloadUserData()}>Excel</Neo.Button>
                            </CardHeader>
                            <CardBody>

                                <div className="row">
                                    <div className="col-md-12">
                                        <PagerTextFilter
                                            filterProperty={this.viewModel.criteria.meta.keyword}
                                            pageManager={this.viewModel.userLookupPageManager}
                                            taskRunner={this.viewModel.taskRunner} label="Search" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col md-12">
                                        <div className="row mt-2">
                                            <div className="col-md-12">
                                                <Neo.Pager
                                                    pageManager={this.viewModel.userLookupPageManager}
                                                    pageControlProps={{
                                                        firstText: "",
                                                        prevText: "Prev",
                                                        nextText: "Next",
                                                        lastText: "",
                                                        alignment: "center",
                                                        autoHide: false,
                                                        pageSizeLabel: "Show"
                                                    }}>
                                                    {User => (
                                                        <NeoGrid.Grid<UserProfileLookup> className="table-hover" keyProperty="userId">
                                                            {(user: UserProfileLookup, userMeta) => (
                                                                <NeoGrid.Row key={user.userGuid}>
                                                                    <NeoGrid.Column display={userMeta.firstName} disabled />
                                                                    <NeoGrid.Column display={userMeta.surname} disabled />
                                                                    <NeoGrid.Column display={userMeta.idNumber} disabled />
                                                                    <NeoGrid.Column display={userMeta.email} disabled />
                                                                    <NeoGrid.Column display={userMeta.persalNumber} disabled />
                                                                    <NeoGrid.Column display={userMeta.saceNumber} disabled />
                                                                    <NeoGrid.Column display={userMeta.organisationLevelName} disabled sort={false} />
                                                                    <NeoGrid.Column display={userMeta.userType} select={{ itemSource: Data.StaticDataSource.fromEnum(UserTypes) }} disabled />
                                                                    <NeoGrid.Column display={userMeta.isActive} disabled />
                                                                    <NeoGrid.ButtonColumn>
                                                                        <Neo.Button icon="edit" isOutline variant="success" onClick={() => this.viewModel.editUserModal(user.userGuid, user.userType, user.isActive)}>Edit</Neo.Button>
                                                                    </NeoGrid.ButtonColumn>
                                                                </NeoGrid.Row>
                                                            )}
                                                        </NeoGrid.Grid>
                                                    )}
                                                </Neo.Pager>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>
                <ForumDrawer show={this.viewModel.showEditModal} onClose={() => this.viewModel.showEditModal = false} isMaximised={this.viewModel.EditIsMaximised} maximiseWindow={() => this.viewModel.EditIsMaximised = !this.viewModel.EditIsMaximised}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="success">
                                <i className={`icon fa fa-user-shield fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">User Maintenance</h4>
                        </CardHeader>
                        <CardBody>
                            <Neo.GridLayout md={2} lg={3}>
                                <Neo.FormGroup bind={this.viewModel.userProfile.meta.firstName} autoFocus />
                                <Neo.FormGroup bind={this.viewModel.userProfile.meta.surname} autoFocus />
                                <Neo.FormGroup bind={this.viewModel.userProfile.meta.idNumber} disabled autoFocus />
                                <Neo.FormGroup bind={this.viewModel.userProfile.meta.email} autoFocus />
                                <Neo.FormGroup bind={this.viewModel.userProfile.meta.phoneNumber} autoFocus />
                                <Neo.FormGroup bind={this.viewModel.userProfile.meta.saceNumber} disabled autoFocus />
                                <Neo.FormGroup bind={this.viewModel.userProfile.meta.persalNumber} disabled autoFocus />
                                <Neo.FormGroup display={this.viewModel.userProfile.meta.organisationLevelName} disabled ><Neo.Button isOutline={true} variant="info" style={{ float: "right" }} onClick={() => this.viewModel.showOrgSelector()}>Change</Neo.Button> </Neo.FormGroup>
                                <Neo.FormGroup bind={this.viewModel.userProfile.meta.userType} select={{ itemSource: Data.StaticDataSource.fromEnum(UserTypes) }} />
                                <Neo.FormGroup bind={this.viewModel.userProfile.meta.isActive} autoFocus > <Neo.Button disabled={this.viewModel.userProfile.meta.userType.value !== UserTypes.Admin} isOutline={true} variant="info" style={{ float: "right" }} onClick={() => this.viewModel.resetPassword(this.viewModel.userProfile)}>Reset Password</Neo.Button> </Neo.FormGroup>
                            </Neo.GridLayout>
                        </CardBody>
                        <CardFooter>
                            <Neo.Button variant="success" text="Save User" isOutline icon="save" onClick={() => this.viewModel.saveUser()} />
                        </CardFooter>
                    </Card>
                </ForumDrawer>

                <SelectOrgLevelComponent showOrgLevelModal={this.viewModel.showOrgLevelModal}
                    closeMethod={() => { this.viewModel.closeSelectOrgLevelModal() }} originalOrgLevel={this.viewModel.orginalOrgLevel} viewModel={this.viewModel.selectOrgLevelComponentVM} optionalText=""></SelectOrgLevelComponent>
            </div>
        )
    }
}