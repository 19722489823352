import { Attributes, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import Types from '../../../App/AppTypes';
import { AppService } from "../../../App/Services/AppService";
import { RaceComponentVM } from '../../Components/RacesComponent';
import { TitleComponentVM } from '../../Components/TitlesComponent';
import { SystemSettingsTypes } from './SystemSettingsView';

@NeoModel
export default class SystemSettingsVM extends Views.ViewModelBase {

    @Attributes.OnChanged<SystemSettingsVM>(c => c.onTabChanged)
    public selectedTab = SystemSettingsTypes.Introduction;
    public raceVM: RaceComponentVM;
    public titleVM: TitleComponentVM;
    private racesFetched: boolean = false;
    private titlesFetched: boolean = false;
    constructor(taskRunner = AppService.get(Types.Neo.TaskRunner)) {
        super(taskRunner);
        this.raceVM = new RaceComponentVM(this.taskRunner);
        this.titleVM = new TitleComponentVM(this.taskRunner);
    }

    public async onTabChanged() {
        // Only one tab for now. Leave method in.
        if (this.selectedTab === SystemSettingsTypes.Races && !this.racesFetched) {
            this.racesFetched = true;
        }
        else if (this.selectedTab === SystemSettingsTypes.Titles && !this.titlesFetched) {
            this.titlesFetched = true;
        }
    }
}