import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';
import { BadgeType } from '../../Learning/Models/Badges/BadgeType.enum';
import MediaObject from '../../Learning/Models/LearningObjects/LearningContent/MediaObject';

@NeoModel
export default class DashboardBadgeLookup extends LookupBase {

    public badgeId: number = 0;

    public badgeType: BadgeType | null = null;

    public badgeTitle: string = "";

    public badgeDescription: string = "";

    public badgeAwardMessage: string = "";

    @Attributes.Date()
    public earnedDated: Date = new Date();

    @Attributes.ChildObject(MediaObject)
    public mediaObject: MediaObject | null = null;

    // Client only properties / methods

    public blobUrl: string = "";
}