import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import QuizLearningObjectLookup from '../../Models/Lookups/QuizLearningObjectLookup';
import QuizQuestionLookup from '../../Models/Lookups/QuizQuestionLookup';

export interface IQuizLearningObjectApiClient  {

    /**
     * Test
     * @param quizLearningObject learnig Object
     * @param LearningObject learnig Object
     * @param userId User id
     * @returns Test
     */
    CreateQuizLearningObjectWithQuestion(learningObject: Model.PartialPlainObject<QuizLearningObjectLookup>, userId: string): AxiosPromise<number>;

    /***
     * Get all the questions for the quiz by learning object id
     * @param learningObjectId
     * @return list of questions
     */
    GetQuizQuestions(learningObjectId: number): AxiosPromise<Array<Model.PlainObject<QuizQuestionLookup>>>

     /***
     *Deletes a  quia question
     * @param questionId question Id
     * @param learningObjectId
     * @return true or false
     */
    DeleteQuestion(questionId: number, learningObjectId: number):  AxiosPromise<boolean>;

    /***
     *Get Quiz Question Order
     * @param learningObjectId
     * @return number
     */
    getQuizQuestionOrder(learningObjectId: number):  AxiosPromise<number>;
}

@injectable()
export default class QuizLearningObjectApiClient extends Data.ApiClientBase implements  IQuizLearningObjectApiClient {
    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/QuizLearningObject`);
    }

    public CreateQuizLearningObjectWithQuestion(learningObject: Model.PartialPlainObject<QuizLearningObjectLookup>, userId: string): AxiosPromise<number> {
        return this.axios.post(`${this.apiPath}/CreateQuizLearningObject/${userId}`, learningObject);
    }

    public GetQuizQuestions(learningObjectId: number):  AxiosPromise<Array<Model.PlainObject<QuizQuestionLookup>>> {
        return this.axios.post(`${this.apiPath}/GetQuizQuesionList/${learningObjectId}`,);
    }

    public DeleteQuestion(questionId: number, learningObjectId: number):  AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/DeleteQuestion/${questionId}/${learningObjectId}`)    
    }

    public getQuizQuestionOrder(learningObjectId: number):  AxiosPromise<number>
    {
        return this.axios.get(`${this.apiPath}/GetQuizQuestionOrder/${learningObjectId}`)    
    }
}