import { Attributes, DateUtils, List, Misc, Model, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import Circuit from '../../../../Organisations/Models/Organisations/Circuit';
import District from '../../../../Organisations/Models/Organisations/District';
import Province from '../../../../Organisations/Models/Organisations/Province';
import School from '../../../../Organisations/Models/Organisations/School';
import SchoolManagementTeam from '../../../../Organisations/Models/Organisations/SchoolManagementTeam';


@NeoModel
export default class LoginsAndRegistrationsCriteria extends Model.CriteriaBase {

    @Attributes.Integer()
    @Attributes.Nullable()
    public id: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public organisationId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public provinceId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public districtId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public circuitId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public schoolId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public schoolManagementTeamId: number | null = null;

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public startDate: Date = DateUtils.monthStart(new Date());

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public endDate: Date = new Date();

    // Client only properties / methods
    
    @Attributes.NoTracking()
    public OrganisationDisabled: boolean = false;

    @Attributes.NoTracking()
    public userName: string = ""

    @Attributes.NoTracking()
    public filteredProvinceList: List<Province> = new List(Province);

    @Attributes.NoTracking()
    public filteredDistrictList: List<District> = new List(District);

    @Attributes.NoTracking()
    public filteredCircuitList: List<Circuit> = new List(Circuit);

    @Attributes.NoTracking()
    public filteredSchoolList: List<School> = new List(School);

    @Attributes.NoTracking()
    public filteredSchoolManagementTeamList: List<SchoolManagementTeam> = new List(SchoolManagementTeam);

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Logins And Registrations Criteria";
    }
}