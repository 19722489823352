import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';
import { ModerationState } from '../LearningObjects/ModerationState.enum';
import Tag2 from '../Tags/Tag2';

@NeoModel
export default class SelfAssessmentTagLookup extends ModelBase {
    public selfAssessmentTag2Id: number = 0;

    public selfAssessmentId: number = 0;
    
    @Required("Tag 1 is Required.")
    public tag1Id: number = 0;

    public tag1Name: string = "";

    @Required("Tag 2 is Required.")
    public tag2Id: number = 0;

    public tag2Name: string = "";

    public fieldState: ModerationState = ModerationState.Unmoderated;

    public approvedByModerator: boolean = false;
 
    // Client only properties / methods

    
    public filteredTag2List = new List(Tag2);
}