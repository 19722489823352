import { AppServices } from '@singularsystems/neo-core';
import { IAuthorisationApiClient } from './Services/ApiClients/AuthorisationApiClient';
import { IUserGroupQueryApiClient } from './Services/ApiClients/UserGroupQueryApiClient';

// Symbols to expose outside of this module
export const AuthorisationSharedTypes = {
    ApiClients: {
        AuthorisationApiClient: new AppServices.ServiceIdentifier<IAuthorisationApiClient>("Authorisation.ApiClients.AuthorisationApiClient"),
        UserGroupQueryApiClient: new AppServices.ServiceIdentifier<IUserGroupQueryApiClient>("Authorisation.ApiClients.UserGroupQueryApiClient")
    }
}