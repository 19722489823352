import { NeoModel, Security } from '@singularsystems/neo-core';
import { User } from 'oidc-client';
import { UserTypes } from '../../../App/Models/Enums/UserTypes.enums';

interface ITDPUserClaims {
    sub: string;
    "given_name": string;
    UserType: any;
    hasAccepted: string;
}

@NeoModel
export default class TDPUser extends Security.OidcUser {

    constructor(private rawUser: User) {
        super(rawUser);

    }

    public get fullName(): string {
        return this.givenName + " " + this.familyName;
    }

    public get claims(): ITDPUserClaims {
        return this.userData.profile as unknown as ITDPUserClaims;
    }

    public get userType(): any {
        return this.claims.UserType as UserTypes;
    }

    public get guid() {
        return this.claims.sub;
    }

    public get hasAccepted(): boolean {
        var res = this.claims.hasAccepted?.toLowerCase().trim() ?? "true";
        return res === "true";
    }

    public isUserType(userType: UserTypes) {
        if (!userType) {
            return false;
        } else {
            if (Array.isArray(this.userType)) {
                return this.userType.some(ut => ut === userType || ut === String(UserTypes.Admin));
            } else {
                return this.userType === String(userType) //|| this.userType === String(UserTypes.Admin)
            }
        }
    }

    public isAdmin() {
        return this.isUserType(UserTypes.Admin);
    }

    public isVerified() {
        return this.isUserType(UserTypes.Verified) || this.isUserType(UserTypes.Admin);
    }

    public isUnVerified() {
        return this.isUserType(UserTypes.Unverified) || this.isUserType(UserTypes.Verified) || this.isUserType(UserTypes.Admin);
    }
}