import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../CommonIdentityTypes';
import Types from '../../../App/AppTypes';
import IntroductionContentLookup from '../../Models/Lookups/IntroductionContentLookup';

export interface IIntroductionReadApiClient {

    /** 
     * Gets the latest Terms and Conditions.
     * @returns IntroductionContentLookup.
     */
    get(): AxiosPromise<Model.PlainObject<IntroductionContentLookup>>;

    /** 
     * Hides the latest terms and conditions on behalf of a user.
     * @returns IntroductionContentLookup as result.
     */
    hide(): AxiosPromise<Model.PlainObject<IntroductionContentLookup>>;

    /** 
     * Returns is the user has accepted the terms and conditions.
     * @returns bool.
     */
    hasHidden(): AxiosPromise<boolean>;

    // Client only properties / methods
}

@injectable()
export default class IntroductionReadApiClient extends Data.ApiClientBase implements IIntroductionReadApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/IntroductionRead`);
    }

    public get(): AxiosPromise<Model.PlainObject<IntroductionContentLookup>> {
        return this.axios.get(`${this.apiPath}`);
    }

    public hide(): AxiosPromise<Model.PlainObject<IntroductionContentLookup>> {
        return this.axios.put(`${this.apiPath}/hide`);
    }

    public hasHidden(): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/has-hidden`);
    }

    // Client only properties / methods
}