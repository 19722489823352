import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../IdentityTypes';
import UpdateNotificationSettingsCommand from '../../../Models/SystemSettings/Commands/UpdateNotificationSettingsCommand';

export interface ISystemSettingsCommandApiClient {

    /** 
     * Updates the Notification systems settings.
     * @param command command.
     * @returns bool.
     */
    updateNotificationSettings(command: Model.PartialPlainObject<UpdateNotificationSettingsCommand>): AxiosPromise<any>;

    // Client only properties / methods
}

@injectable()
export default class SystemSettingsCommandApiClient extends Data.ApiClientBase implements ISystemSettingsCommandApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/SystemSettingsCommand`);
    }

    public updateNotificationSettings(command: Model.PartialPlainObject<UpdateNotificationSettingsCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/UpdateNotificationSettings`, command);
    }

    // Client only properties / methods
}