import { ModelBase, NeoModel, Validation, List } from '@singularsystems/neo-core';
import SelfAssessmentTagLookup from './SelfAssessmentTagLookup';
@NeoModel
  export default class SelfAssessmentModerationProgressLookup extends ModelBase {

    public selfAssessmentId: number = 0

    public selfAssessmentTitleApproved: boolean = false;

    public selfAssessmentDescriptionApproved: boolean = false;

    public selfAssessmentModerationNotes: string = "";

    public selfAssessmentQuestionDnDModerationNotes: string = "";

    public selfAssessmentTags2 = new List(SelfAssessmentTagLookup);    

    public selfAssessmentTitle: string = "";

    public selfAssessmentDescription: string = "";

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
  }