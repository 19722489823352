import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
  export default class ScalesLookup extends ModelBase {

    public numberOfScales: number = 0;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
  }