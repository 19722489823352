import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { LearningContentMediaType } from '../LearningObjects/LearningContent/LearningContentMediaType.enum';
import LearningContentCombinedObject from '../LearningObjects/LearningContentCombinedObject';

@NeoModel
export default class LearningObjectContentLookup extends ModelBase {

    constructor() {
        super();
        this.learningContent = new LearningContentCombinedObject();
    }

    public learningObjectId: number = 0;

    public learningContentId: number = 0;

    @Attributes.ChildObject(LearningContentCombinedObject)
    public learningContent: LearningContentCombinedObject;

    @Rules.Required()
    public mediaTypeFetched: number = LearningContentMediaType.Unsupported;

    // Client only properties / methods

    public selfAssessmentContentModerationNotes: string = "";

    public approvedByModerator: boolean = false;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.mediaTypeFetched === LearningContentMediaType.Unsupported, "Please choose a media type")
    }

    public toString(): string {
        if (this.isNew) {
            return "New learning object content lookup";
        } else {
            return "Learning Object Content Lookup";
        }
    }
}