import { Attributes, NeoModel, Validation } from '@singularsystems/neo-core';
import OrgLevelLookup from './OrgLevelLookup';

@NeoModel

export default class OrglevelUserCountLookup extends OrgLevelLookup {

    @Attributes.Integer()
    @Attributes.Display("User count")
    public userCount: number = 0;

    @Attributes.Integer()
    @Attributes.Display("User count including children")
    public userCountIncldChildren: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New orglevel user count lookup";
        } else {
            return "Orglevel User Count Lookup";
        }
    }
}