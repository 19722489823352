import { Attributes, ModelBase, Validation } from '@singularsystems/neo-core';

export default abstract class OrgLevelLookup extends ModelBase {

    @Attributes.Nullable()
    public organisationId: number | null = null;

    @Attributes.Nullable()
    public provinceId: number | null = null;

    @Attributes.Nullable()
    public districtId: number | null = null;

    @Attributes.Nullable()
    public circuitId: number | null = null;

    @Attributes.Nullable()
    public schoolId: number | null = null;

    @Attributes.Nullable()
    public schoolManagementTeamId: number | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}