import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import RichTextEditor from "../../../Learning/Components/RichTextEditor";
import Card from "../../../Template/components/Card/Card";
import CardBody from "../../../Template/components/Card/CardBody";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardIcon from "../../../Template/components/Card/CardIcon";
import PostLookup from "../../Models/Lookups/PostLookup";
import ReportModalView from "./ReportModalView";
import GridItem from "../../../Template/components/Grid/GridItem";
import ROConversationComponentVM from "./ROConversationComponentVM";

interface IROConversationComponentProps {
  topicId: number;
  title: string;
  show: IPropertyInstance;
  isMaximised?: IPropertyInstance;
}

@observer
export default class ROConversationComponent extends React.Component<IROConversationComponentProps>{
  constructor(props: IROConversationComponentProps) {
    super(props);
    this.viewModel = new ROConversationComponentVM();
    this.viewModel.loadPosts(this.props.topicId);
    this.viewModel.getCurrentUser();
  }

  private viewModel = new ROConversationComponentVM();

  public render() {
    return (
      <div>
        <Card>
          <CardHeader>
            <CardIcon color="primary">
              <i className={`icon fas fa-comments fa-2x`}></i>
            </CardIcon>
            <h4 className="Card-icon-header-text">{this.props.title} Conversations</h4>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col align-self-end mb-2">
                <Neo.Button variant="warning" icon="sync-alt" isOutline onClick={() => this.viewModel.loadPosts(this.viewModel.postCriteria.topicId)}>Refresh</Neo.Button>
              </div>
            </div>
            <Neo.Pager
              pageManager={this.viewModel.postPageManager}
              pageControlProps={{
                firstText: "",
                prevText: "Prev",
                nextText: "Next",
                lastText: "",
                alignment: "center",
                autoHide: false,
                pageSizeLabel: "Show"
              }}>
              {post => (
                <NeoGrid.Grid<PostLookup> className="table-hover" keyProperty="postId">
                  {(post: PostLookup, postMeta) => (
                    <NeoGrid.Row key={post.postId}>
                      <NeoGrid.Column style={{ border: "none" }}>
                        <div className="row">
                          <div style={{ width: "40px" }}>
                            <div className="custom-avatar" style={{ background: this.viewModel.setUserColor(post.createdByGuid), width: "40px" }}>
                              <span >{post.postedBy[0]}</span>
                            </div>
                          </div>
                          <div className={post.isReported ? "col-md-11 initial-reported-text" : "col-md-11"}>
                            <span style={{ color: "#656c7a", fontWeight: 700 }}>{post.postedBy} </span>
                            <span style={{ fontWeight: 300, color: "grey" }}>- {post.timeAgo}</span>
                            {post.body.length === 0 ||
                              <div>
                                <RichTextEditor key={post.postId} bind={postMeta.body} readOnly={this.viewModel.disableEdit || this.viewModel.selectedPost?.postId !== post.postId} />
                                <Neo.Button onClick={() => { this.viewModel.loadPosts(this.props.topicId); this.viewModel.disableEdit = true }} text="Cancel" icon="times" variant="danger" isOutline hidden={this.viewModel.disableEdit || this.viewModel.selectedPost?.postId !== post.postId} />
                              </div>}
                            <div>
                              <span onClick={() => { this.viewModel.viewReplies(post) }} hidden={!post.hasChildren} className="reply-btn">{"View Messages |"} </span>
                              <span onClick={() => this.viewModel.reportPost(post)} className="report-btn"> Report </span>
                            </div>
                          </div>
                          <div key={post.postId + 2} className="col-md-12" hidden={this.viewModel.hideReply}>
                          </div>
                          <div key={post.postId + 1} className="col-md-12" hidden={this.viewModel.hideDeletePost}>
                            {
                              this.viewModel.selectedPost !== post ||
                              <div style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }} >
                                <p>Please give a reason for deleting this post</p>
                                <GridItem md={12}>
                                  <Neo.FormGroupFloating label="Reason" bind={this.viewModel.selectedPost?.meta.deletedReason} input={{ rows: 3 }} />
                                </GridItem>
                              </div>
                            }
                          </div>
                          <div key={post.postId} className="col-md-12" hidden={this.viewModel.HideMessages}>
                            {
                              this.viewModel.selectedPost !== post ||
                              this.viewModel.postReplies.map((reply, index) => (
                                <div key={reply.postId}>
                                  <div className="row" style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }}>
                                    <div>
                                      <div className="custom-avatar" style={{ background: this.viewModel.setUserColor(reply.createdByGuid), width: "40px" }}>
                                        <span >{reply.postedBy[0]}</span>
                                      </div>
                                    </div>
                                    <div className={reply.isReported ? "col-md-11 reported-text" : "col-md-11"}>
                                      <span style={{ color: "#656c7a", fontWeight: 700 }}>{reply.postedBy} </span>
                                      <span style={{ fontWeight: 300, color: "grey" }}>- {post.timeAgo}</span>
                                      {reply.body.length === 0 ||
                                        <RichTextEditor bind={reply.meta.body} readOnly />
                                      }
                                      <div>
                                        <span onClick={() => this.viewModel.reportPost(reply)} className="report-btn"> Report</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div key={reply.postId + 1} className="col-md-12" hidden={this.viewModel.hideChildReply}>
                                  </div>
                                  <div key={post.postId + 1} className="col-md-12" hidden={this.viewModel.hideReplyDeletePost}>
                                    {
                                      this.viewModel.selectedPost !== post ||
                                      <div style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }} >
                                        <p>Please give a reason for deleting this post</p>
                                        <GridItem md={12}>
                                          <Neo.FormGroupFloating label="Reason" bind={this.viewModel.selectedChildPost?.meta.deletedReason} input={{ rows: 3 }} />
                                        </GridItem>
                                      </div>
                                    }
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </NeoGrid.Column>
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
              )}
            </Neo.Pager>
          </CardBody>
        </Card>
        <ReportModalView viewModel={this.viewModel.reportModalVM} />
      </div >
    )
  }
}