import { Attributes, List, LookupBase, NeoModel, Rules } from '@singularsystems/neo-core';
import { QuestionType } from '../../Learning/Models/Questions/QuestionType.enum';
import { Sentiment } from '../../Learning/Models/Questions/Sentiment.enum';
import SelfAssessmentQuestionLookup from '../../Learning/Models/ContentCosumption/SelfAssessmentQuestionsLookup';
@NeoModel
export default class UserAssessmentLookup extends LookupBase {

    public selfAssessmentId: number = 0;

    public userSelfAssessmentId: number = 0;

    @Rules.StringLength(250)
    public selfAssessmentTitle: string = "";

    @Rules.StringLength(500)
    public selfAssessmentDescription: string = "";

    public questionTypeId: QuestionType = QuestionType.NotSet;

    public sentiment: Sentiment = Sentiment.NotSet;

    public tag2Id: number = 0;

    public selfAssessmentQuestions = new List(SelfAssessmentQuestionLookup);

    @Attributes.Date()
    public completedDate: Date = new Date();

    @Attributes.Integer()
    public assessmentScore: number = 0;

    // Client only properties / methods
}