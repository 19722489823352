import QuestionLookupBase from './QuestionLookupBase';
import { NeoModel, Validation } from '@singularsystems/neo-core';


@NeoModel
export default class SelfAssessmentQuestionModerationProgressLookup extends QuestionLookupBase {

    public selfAssessmentQuestionId : number = 0; 

    public questionTextApprovedByModerator : boolean = false;

    public selfAssessmentOptionsApprovedByModerator : boolean = false;

    public tag3ApprovedByModerator : boolean = false;

    public moderationNotes: string = "";    

    public contentApprovedByModerator: boolean = false;

    public contentModerationNotes: string = "";

    public learningContentId: number = 0;

    public questionText: string = "";

    public questionTextHtml: string = "";

    public contentTitle: string = "";

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}