import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../LearningTypes';
import UserLearningModuleLookup from '../../Models/UserLearning/UserLearningModuleLookup';

@NeoModel
export default class ModulePreviewVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private contentConsumptionApiClient = AppService.get(Types.Learning.ApiClients.ContentConsumptionQueryApiClient)) {

        super(taskRunner);
    }

    public module = new UserLearningModuleLookup();
    public PreviewModule = async (moduleId: number) => {
      const response = await this.contentConsumptionApiClient.getUserModulebyIdForPreview(moduleId);
      if (response.data) {
        this.module.set(response.data);
        this.showPreviewDrawer = true;
      }
    }
  
    public showPreviewDrawer = false;
    public previewDrawerMaximised = true;

    public async initialise() {

    }
}