import { Data, List, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../App/Services/AppService";
import DistrictSearchCriteria from "../Models/Criteria/DistrictSearchCriteria";
import DistrictLookup from "../Models/Lookups/DistrictLookup";
import District from "../Models/Organisations/District";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum"

@NeoModel
export default class DistrictComponentVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private districtApiClient = AppService.get(Types.Organisations.ApiClients.DistrictApiClient),
        private districtQueryApiClient = AppService.get(Types.Organisations.ApiClients.DistrictQueryApiClient),
        private provinceApiClient = AppService.get(Types.Organisations.ApiClients.ProvinceApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
        this.autoDispose(this.criteria.onAnyPropertyChanged(() => this.districtLookupPageManager.refreshData(true, this.taskRunner)))
    }

    //Properties 
    public DistrictList = new List(District);
    public selectedDistrictId: number = 0;
    public filterDistrictId: number = 0;
    public selectedDistrict = new District();
    //Drop Downs 
    public provinceList = new Data.ApiClientDataSource(this.provinceApiClient.get);

    //District Paging Manager
    public criteria = new DistrictSearchCriteria();
    public showEditModal: boolean = false;

    public districtLookupPageManager = new Data.PageManager(this.criteria, DistrictLookup, this.districtQueryApiClient.getDistrictListPaged, {
        pageSize: 10,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sortBy: "districtName",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner
    });

    public async saveDistrict() {
        this.taskRunner.run(async () => {
            this.showEditModal = false
            const district = this.selectedDistrict
            await this.districtApiClient.save(district.toJSObject())
            this.selectedDistrictId = 0;
            this.selectedDistrict = new District();
            this.districtLookupPageManager.refreshData()
            this.notifications.addSuccess("District saved", null, NotificationDuration.Short);
        })
    }

    public async deleteDistrict(districtId: number) {
        this.taskRunner.run(async () => {
            await this.districtApiClient.delete(districtId);
            this.districtLookupPageManager.refreshData();
            this.notifications.addSuccess("District Deleted", null, NotificationDuration.Short);
        })
    }

    public async showDistrictEditModal(districtId: number) {
        if (districtId !== 0) {
            this.selectedDistrictId = districtId
            const response = await this.taskRunner.waitFor(this.districtApiClient.getById(districtId));
            this.selectedDistrict.set(response.data)
        }
        this.showEditModal = true;
    }

    public async closeEditModal() {
        this.selectedDistrict = new District();
        this.selectedDistrictId = 0;
        this.showEditModal = false;
    }

    public async deleteDistrictPopUp(districtName: string, districtId: number) {
        const response = await this.taskRunner.waitFor(this.districtQueryApiClient.checkDistrictLinks(districtId));
        if (response.data) {
            ModalUtils.showMessage("Cannot Delete " + districtName, "This district has circuits linked to it and cannot be deleted", 3)
        }
        else {
            await ModalUtils.showYesNoDismissible("Delete District " + districtName,
                "Are you sure you want to delete this district? ", () => this.deleteDistrict(districtId))
        }
    }

    public async onProvinceSeleted(provinceId: number) {
        if (provinceId) {
            this.criteria.provinceId = provinceId as number;
            this.districtLookupPageManager.refreshData(true, this.taskRunner);
        }
        else {
            this.criteria.provinceId = 0;
            this.districtLookupPageManager.refreshData(true, this.taskRunner);
        }
    }

    public async SetCriteria(provinceId: number) {
        this.criteria.provinceId = provinceId;
        this.districtLookupPageManager.refreshData(true, this.taskRunner);
    }

    public async setSelectedDistrict(districtId: number) {
        // Unselect District
        if (this.filterDistrictId === districtId) {
            this.filterDistrictId = 0;
        }
        // Select District
        else if (districtId) {
            this.filterDistrictId = districtId;
        } else {
            this.filterDistrictId = 0
        }
    }

    public clearFilters() {
        this.criteria.meta.provinceId.value = 0;
        this.criteria.meta.districtName.value = "";
    }
}
