import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class IntroductionContentLookup extends ModelBase {

    public introductionUrl: string = "";

    @Attributes.Date()
    public createdOn: Date = new Date(0,1,1,0,0,0);

    // Client only properties / methods
}