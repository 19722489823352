import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import EducatorLookup from '../../Models/Lookups/SACEEducatorLookup';
import EducatorSearchCriteria from '../../Models/Criteria/SACEEducatorSearchCriteria';

export interface ISACEEducatorQueryApiClient {

    /** 
     * This method will return the educators
     * @param educatorId The Id of the specific educator to fetch
     * @returns A collection of educators that match the given criteria
     */
    getEducator(educatorId: number): AxiosPromise<Model.PlainObject<EducatorLookup>>;

    /** 
     * This method will return the educators
     * @returns A collection of educators that match the given criteria
     */
    getEducators(): AxiosPromise<Array<Model.PlainObject<EducatorLookup>>>;

    /** 
     * This method will return the educators
     * @param request the paged search criteria request
     * @returns A collection of educators that match the given criteria
     */
    getEducatorsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<EducatorSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<EducatorLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class SACEEducatorQueryApiClient extends Data.ApiClientBase implements ISACEEducatorQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/SACEEducatorQuery`);
    }

    public getEducator(educatorId: number): AxiosPromise<Model.PlainObject<EducatorLookup>> {
        return this.axios.get(`${this.apiPath}/Educator?educatorId=${educatorId}`);
    }

    public getEducators(): AxiosPromise<Array<Model.PlainObject<EducatorLookup>>> {
        return this.axios.get(`${this.apiPath}/Educators`);
    }

    public getEducatorsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<EducatorSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<EducatorLookup>>> {
        return this.axios.get(`${this.apiPath}/EducatorsPaged?${Utils.getQueryString(request)}`);
    }

    // Client only properties / methods
}