import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { LearningObjectType } from '../LearningObjects/LearningObjectType.enum';
import { UserLearningStatus } from '../UserLearning/UsersLearningStatus.enum';

@NeoModel
export default class ModuleObjectsConsumptionLookup extends ModelBase {

  public learningObjectId: number = 0;

  public title: string = "";

  public purpose: string = "";

  public learningObjectType: LearningObjectType | null = null;

  public order: number = 0;

  public userLearningStatus: UserLearningStatus = UserLearningStatus.Awaiting;

  public randomiseOrder: boolean = false;
  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.title) {
      return "New module objects consumption lookup";
    } else {
      return this.title;
    }
  }
}