import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import PreviewLearningObjectVM from './PreviewLearningObjectVM';
import { observer } from 'mobx-react';
import Card from '../../../Template/components/Card/Card';
import CardHeader from '../../../Template/components/Card/CardHeader';
import CardIcon from '../../../Template/components/Card/CardIcon';
import CardBody from '../../../Template/components/Card/CardBody';
import GridContainer from '../../../Template/components/Grid/GridContainer';
import GridItem from '../../../Template/components/Grid/GridItem';
import ImageCard from '../../../App/Components/ImageCard';
import CardFooter from '../../../Template/components/Card/CardFooter';
import RichTextEditor from '../../Components/RichTextEditor';
import { GetButtonText, GetContentImage } from '../../../App/HelperClasses/GlobalHelpers';
import { LearningContentMediaType } from '../../Models/LearningObjects/LearningContent/LearningContentMediaType.enum';
import ContentModal from '../../Components/ContentModal';

class PreviewLearningObjectViewParams {
    public objectId = { required: true, isQuery: true };
}

@observer
export default class PreviewLearningObjectView extends Views.ViewBase<PreviewLearningObjectVM, PreviewLearningObjectViewParams> {

    constructor(props: unknown) {
        super("Preview Learning Object", PreviewLearningObjectVM, props);
    }

    public static params = new PreviewLearningObjectViewParams();

    protected async viewParamsUpdated() {
        await this.viewModel.GetObject(this.viewParams.objectId.value as number);
    }

    public render() {
        return (
            <div>
                <Card>
                    <CardHeader icon >
                        <CardIcon color="success">
                            <i className={`icon fa fa-book fa-2x`}></i>
                        </CardIcon>

                        <h4 className="Card-icon-header-text">{this.viewModel.learningObject.title}</h4>
                    </CardHeader>
                    <CardBody>
                        <h4 className="module-tiles">Activity Purpose</h4>
                        <p className="module-description">{this.viewModel.learningObject.purpose}</p>
                        <br />
                        <h4 className="module-tiles">Activity Text</h4>
                        <RichTextEditor bind={this.viewModel.learningObject.meta.objectText} labelText="" readOnly />
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="info">
                                    <i className="fa fa-images fa-2x" />
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Content</h4>
                            </CardHeader>
                            <CardBody >
                                <GridContainer >
                                    {
                                        this.viewModel.learningObject.contentList.map((content, index) => (
                                            <GridItem key={index} xs={12} sm={12} md={2}>
                                                <ImageCard image={GetContentImage(content.mediaType as number)} className="image-card-learning-object" imageClassName="media-image">
                                                    <CardBody >
                                                        <p className="module-description">{content.title}</p>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <GridContainer>
                                                            <GridItem md={12} sm={12} xs={12}>
                                                                <Neo.Button icon="eye" variant="success" text={GetButtonText(content)} isOutline style={{ float: 'left' }} onClick={() => this.viewModel.ViewContent(content)} />
                                                            </GridItem>
                                                            <GridItem md={12} sm={12} xs={12}>
                                                                <Neo.Button isOutline hidden={content.mediaType === LearningContentMediaType.Conversation} variant="warning" text="Download" icon="download" onClick={() => this.viewModel.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, true)} />
                                                            </GridItem>
                                                        </GridContainer>
                                                    </CardFooter>
                                                </ImageCard>
                                            </GridItem >
                                        ))
                                    }
                                </GridContainer>
                                <ContentModal title="content" show={this.viewModel.showContent} blobUrl={this.viewModel.contentBlobUrl} onClose={() => { this.viewModel.showContent = false }} content={this.viewModel.currentContent} taskRunner={this.viewModel.taskRunner} alternateUrl={""} />
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>

            </div>
        );
    }
}