import { Attributes, Misc, ModelBase, NeoModel } from '@singularsystems/neo-core';
import BadgeBase from './BadgeBase';
import LearningModule from '../LearningModules/LearningModule';

@NeoModel
export default class BadgeModule extends ModelBase {

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public badgeId: number | null = null;

    @Attributes.ChildObject(BadgeBase)
    public badgeBase: BadgeBase | null = null;

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public learningModuleId: number | null = null;

    @Attributes.ChildObject(LearningModule)
    public learningModule: LearningModule | null = null;

    // Client only properties / methods
}