import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class GroupMembershipLookup extends ModelBase {

    public membershipId : number = 0;

    public userGroupId : number = 0;

    public userId : number = 0;

    public memberName : string = "";

    public userName : string = "";

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString() {
        if (this.isNew || !this.memberName) {
            return "New group membership lookup"
        } else {
            return this.memberName;
        }
    }
}