import { Attributes, DateUtils, List, Misc, Model, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import Tag2 from '../../../../Learning/Models/Tags/Tag2';
import District from '../../../../Organisations/Models/Organisations/District';
import Province from '../../../../Organisations/Models/Organisations/Province';

@NeoModel
export default class SelfAssessmentResultsCriteria extends Model.CriteriaBase {

    @Attributes.Integer()
    @Attributes.Nullable()
    public organisationId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public provinceId:number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public districtId: number | null = null;

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public startDate: Date = DateUtils.monthStart(new Date());

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public endDate: Date = new Date();

    @Attributes.Integer()
    @Attributes.Nullable()
    public categoryId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public subCategoryId: number | null = null;

    @Attributes.NoTracking()
    public OrganisationDisabled: boolean = false;

    // Client only properties / methods

    @Attributes.NoTracking()
    public CategoryName: string = '';
    
    @Attributes.NoTracking()
    public filteredProvinceList: List<Province> = new List(Province);

    @Attributes.NoTracking()
    public filteredDistrictList: List<District> = new List(District);

    @Attributes.NoTracking()
    public subCategoryList: List<Tag2> = new List(Tag2);

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Self Assessment Results Criteria";
    }
}