import { Data, Model, Utils } from '@singularsystems/neo-core';
import Axios, { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { Types } from '../../../Identity/IdentityTypes';
import UserWithGuidLookup from '../../Models/Lookups/UserWithGuidLookup';
import UserRoleSearchCriteria from '../../Models/Criteria/UserRoleSearchCriteria';
import { AppService } from '../../../Identity/IdentityTypes';

export interface IUserRoleApiClient {

    /** 
     * GetUsersByRoleNameAsync.
     * @param role role
     * @returns List of IClientUser
     */
    getUsersByRoleNameAsync(roleName: Model.PartialPlainNonTrackedObject<string>): AxiosPromise<Array<Model.PlainTrackedObject<UserWithGuidLookup>>>;
    // Client only properties / methods

    getPagedUsersByRoleNameAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<UserRoleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserWithGuidLookup>>>;

    
    /** 
     * GetUserByGuid.
     * @param userGuid userGuid
     * @returns UserWithGuidLookup
     */
     getUserByGuid(userGuid: string): AxiosPromise<Model.PlainObject<UserWithGuidLookup>>;

}

@injectable()
export default class UserRoleApiClient extends Data.ApiClientBase implements IUserRoleApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.AuthorisationApi.ApiPath}/UserRole`);
        // this.apiPath = `${config.authorisationServerApiPath}/UserRole`;
    }

    public getUsersByRoleNameAsync(roleName: Model.PartialPlainNonTrackedObject<string>): AxiosPromise<Array<Model.PlainTrackedObject<UserWithGuidLookup>>> {
        return Axios.get(`${this.apiPath}/GetUsersByRoleName/${encodeURIComponent(roleName.toString())}`);
    }

    public getPagedUsersByRoleNameAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<UserRoleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserWithGuidLookup>>> {
        return Axios.get(`${this.apiPath}/GetPagedUsersByRoleName?${Utils.getQueryString(request)}`);
    }

    public getUserByGuid(userGuid: string): AxiosPromise<Model.PlainObject<UserWithGuidLookup>> {
        return this.axios.get(`${this.apiPath}/GetUserByGuid/${encodeURIComponent(userGuid)}`);
    }
    // Client only properties / methods
}