import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class NotificationSettings extends ModelBase {

  @Rules.StringLength(150)
  public systemEmailAddress: string = "";

  @Rules.StringLength(20)
  public systemWhatsAppNumber: string = "";

  public allowEmailVerification: boolean = false;

  public allowSmsVerification: boolean = false;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.systemEmailAddress) {
      return "New notification settings";
    } else {
      return this.systemEmailAddress;
    }
  }
}