import { NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { AppService, Types } from "../IdentityTypes";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
import RichTextEditor from "../../Learning/Components/RichTextEditor";
import TermsAndConditionLookup from "../Models/Lookups/TermsAndConditionLookup";
import { AxiosResponse } from "axios";
import Home from "../../App/Views/Security/Home";

interface ITermsAndConditionsAdminComponentProps {
}

@NeoModel
export class TermsAndConditionsAdminComponentVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private termsAndConditionsAdminApiClient = AppService.get(Types.Identity.ApiClients.TermsAndConditionsAdminApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        protected navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
        super(taskRunner);
    }

    //Properties    
    public termsAndConditions: TermsAndConditionLookup = new TermsAndConditionLookup();
    public hasAccepted: boolean = false;

    //Methods
    public async initialise() {
        await this.taskRunner.run(async () => {
            var resp = await this.termsAndConditionsAdminApiClient.get();
            if (this.isValidResponse(resp)) {
                this.termsAndConditions = new TermsAndConditionLookup();
                this.termsAndConditions.createdOn = new Date(resp.data.createdOn);
                this.termsAndConditions.termsAndConditions = resp.data.termsAndConditions;
                if (this.termsAndConditions.termsAndConditions.length === 0) {
                    this.termsAndConditions.termsAndConditions = "{\"blocks\":[{\"key\":\"90d9i\",\"text\":\"There are no Terms And Conditions setup in the system. Please inform the administrator.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":167,\"style\":\"color-rgb(0,0,0)\"},{\"offset\":0,\"length\":159,\"style\":\"bgcolor-white\"},{\"offset\":0,\"length\":167,\"style\":\"fontfamily-Verdana\"},{\"offset\":0,\"length\":167,\"style\":\"fontsize-14\"},{\"offset\":109,\"length\":4,\"style\":\"BOLD\"},{\"offset\":139,\"length\":20,\"style\":\"ITALIC\"},{\"offset\":159,\"length\":8,\"style\":\"bgcolor-rgb(255,255,255)\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}";
                }
            }
        });
    }

    public async checkAndRedirectToHome() {
        if (this.hasAccepted) {
            await this.authenticationService.signinSilent();
            this.navigation.navigateToView(Home);
        }
    }

    private isValidResponse(response: AxiosResponse): boolean {
        var result = false;
        if (response.status === 200) {
            result = true;
        }
        return result;
    }

    public async update() {
        await this.taskRunner.run(async () => {
            this.termsAndConditions.createdOn = new Date();
            var resp = await this.termsAndConditionsAdminApiClient.update(this.termsAndConditions.toJSObject());
            if (this.isValidResponse(resp)) {
                this.notifications.addSuccess(`Terms and Conditions Updated!`, null, NotificationDuration.Standard);
                if (!resp.data) {
                    this.termsAndConditions.set(resp.data);
                }
            } else {
                this.notifications.addDanger(`Terms and Conditions Could not be updated due to technical error!`, null, NotificationDuration.Standard);
            }
        });
    }
}

@observer
export default class TermsAndConditionsAdminComponent extends React.Component<ITermsAndConditionsAdminComponentProps>{

    private viewModel: TermsAndConditionsAdminComponentVM = new TermsAndConditionsAdminComponentVM();

    constructor(props: ITermsAndConditionsAdminComponentProps) {
        super(props);
        this.viewModel = new TermsAndConditionsAdminComponentVM();
    }

    public componentDidMount() {
        this.viewModel.initialise();
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fa fa-address-card fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Edit Terms and Conditions</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <label>The users will need to accept these terms and conditions before they can proceed using the website. After you update
                                        these terms <b>all users</b> will need to accept the new terms again.</label>
                                </div>
                                <div>
                                    <Neo.Button variant="success" text="Update Terms and Conditions" onClick={() => this.viewModel.update()} />
                                </div>
                                <div>
                                    <Neo.FormGroup label="Date and time last modified" readOnly bind={this.viewModel.termsAndConditions.meta.createdOn} dateProps={{ formatString: "dd MMM yyyy HH:mm" }} />
                                    {this.viewModel.termsAndConditions.termsAndConditions.length === 0 ||  <RichTextEditor bind={this.viewModel.termsAndConditions.meta.termsAndConditions} /> }
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
        );
    }
}