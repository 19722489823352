import { Data, List, Misc, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService } from "../../App/Services/AppService";
import { Types } from '../../Identity/IdentityTypes';
import BadgeSearchCriteria from "../../Learning/Models/Criteria/BadgeSearchCriteria";
import SelfAssessmentLookup from "../../Learning/Models/Lookups/SelfAssessmentLookup";
import DashboardBadgeLookup from "../Models/DashboardBadgeLookup";
import SelfAssessmentSearchCriteria from "../Models/SearchCriteria/SelfAssessmentSearchCriteria";
import UserDashboardSetting from "../Models/UserDashboardSetting";
import UserSelfAssessmentLookup from "../Models/UserAssessmentLookup";
import LearningModuleSearchCriteria from "../Models/SearchCriteria/LearningModuleSearchCriteria";
import Tag2 from "../../Learning/Models/Tags/Tag2";
import LearningModuleLookup from "../../Learning/Models/Lookups/LearningModuleLookup";
import Tag3 from "../../Learning/Models/Tags/Tag3";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum";
import UserLearningModuleLookup from "../../Learning/Models/UserLearning/UserLearningModuleLookup";
import LearningPathwaySearchCriteria from "../Models/SearchCriteria/LearningPathwaySearchCriteria";
import LearningPathwayLookup from "../Models/LearningPathwayLookup";
import UserReminder from "../../Learning/Models/Reminders/UserReminder";
import IntroductionContentLookup from "../../Identity/Models/Lookups/IntroductionContentLookup";

@NeoModel
export default class DashboardVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
    private learningPathwayApiClient = AppService.get(Types.Learning.ApiClients.LearningPathwayQueryApiClient),
    public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
    public tag3ApiClient = AppService.get(Types.Learning.ApiClients.Tag3QueryApiClient),
    private contentConsumptionApiClient = AppService.get(Types.Learning.ApiClients.ContentConsumptionQueryApiClient),
    private dashboardApiClient = AppService.get(Types.Dashboard.ApiClients.DashboardQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private badgeApiClient = AppService.get(Types.Learning.ApiClients.BadgeQueryApiClient),
    private introductionReadApiClient = AppService.get(Types.Identity.ApiClients.IntroductionReadApiClient),
    private authService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
    super(taskRunner);
    this.HeaderStartUpAction();
  }

  public HeaderStartUpAction = async () => {
    this.taskRunner.run(async () => {
      await this.getUserIntroductionSetting();
      await this.getUserDashboardSetting();
      await this.UpdateUserDashboardSettings();
      await this.setUserAssessmentCount();
      await this.GetMessageCount();
      await this.GetUserBadgeCount();
      await this.GetBadgeCount();
      await this.GetTopicCount();
      await this.setAssessmentCount();
      await this.userReminderSettings();
    })
  }

  // introduction dialog
  public showIntroductionDialog: boolean = false;
  public userHideIntroduction: boolean = false;
  public introductionObject: IntroductionContentLookup = new IntroductionContentLookup();

  // modules
  public showModuleSearch = false;
  public modulesIsMaximised = true;
  public selectedModelId = 0;
  public confirmStartNewModule = false;
  public moduleCriteria = new LearningModuleSearchCriteria();
  public learningModuleTag2FilteredList = new List(Tag2);
  public learningModuleTag3FilteredList = new List(Tag3);
  public assessmentTag2FilteredList = new List(Tag2);

  public cptdPointsEarned = 0;
  public showPreviewDrawer = false;
  public previewDrawerMaximised = true;
  public confirmModuleAgain = false;

  //reminders
  public showReminderFrequencyModal = false;
  public selectedReminderFrequency = null;
  public userReminder = new UserReminder();


  // Introduction Code
  public getUserIntroductionSetting = async () => {
    if (!this.authService.hasSeenIntroduction) {
      var resp = await this.introductionReadApiClient.hasHidden();
      if (resp.status === 200) {
        this.userHideIntroduction = resp.data;
        if (!this.userHideIntroduction) {
          var resp2 = await this.introductionReadApiClient.get();
          if (resp2.status === 200) {
            this.introductionObject.set(resp2.data);
            if (this.introductionObject.introductionUrl !== "") {
              this.showIntroductionDialog = true;
            }
          }
        }
      }
    }
  }

  // End Introduction Code

  public selectModuleForStart = async (module: LearningModuleLookup) => {
    this.showModuleSearch = false;
    this.selectedModelId = module.learningModuleId;
    if (module.userHasDoneModule) {
      this.confirmModuleAgain = true
      const modalResult = await ModalUtils.showYesNo("Are you sure", "You have already done this module, would you like to start it again?");
      if (modalResult === Misc.ModalResult.Yes) {
        this.confirmStartNewModule = true;
      }
    } else {
      this.confirmStartNewModule = true;
    }
  }

  public startNewModule = async (id: number) => {
    await this.dashboardApiClient.startNewModule(id);
  }

  public ClearModuleFilters = () => {
    this.moduleCriteria.keyword = "";
    this.moduleCriteria.learningModuleId = 0;
    this.moduleCriteria.learningModuleTag2Id = 0;
    this.moduleCriteria.learningModuleTag1Id = 0;
    this.moduleCriteria.learningModuleTag3Id = 0;
    this.moduleCriteria.learningModuleTag1Name = "";
    this.learningModulePageManager.refreshData();
  }

  public ClearAssessmentFilters = () => {
    this.assessmentCriteria.SelfAssessmentTitle = "";
    this.assessmentCriteria.FilterByCreated = true;
    this.assessmentCriteria.SelfAssessmentTag1Id = 0;
    this.assessmentCriteria.SelfAssessmentTag2Id = 0;
    this.assessmentCriteria.keyword = "";
    this.assessmentCriteria.SelfAssessmentId = null;
    this.assessmentCriteria.SelfAssessmentTag2Id = 0;
    this.assessmentCriteria.SelfAssessmentTag1Id = 0;
    this.assessmentCriteria.SelfAssessmentTag1Name = "";
    this.assessmentCriteria.StateSelectedUnknown = false;
    this.assessmentCriteria.StateSelectedUnmoderated = true;
    this.assessmentCriteria.StateSelectedSubmittedForModeration = false;
    this.assessmentCriteria.StateSelectedUnderModeration = false;
    this.assessmentCriteria.StateSelectedReview = false;
    this.assessmentCriteria.StateSelectedModerated = false;
    this.assessmentCriteria.StateSelectedResubmittedForModeration = false;
    this.assessmentPagerManager.refreshData();
  }

  public async FilterModuleTag2ListSearch(tag1Id: number | undefined) {
    this.learningModuleTag2FilteredList = new List(Tag2);
    if (tag1Id) {
      const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id))).data;
      this.learningModuleTag2FilteredList.set(tag2List);
    }
    this.moduleCriteria.learningModuleTag2Id = 0;
  }

  public async FilterModuleTag3ListSearch(tag2Id: number | undefined) {
    this.learningModuleTag3FilteredList = new List(Tag3);
    if (tag2Id) {
      const tag3List = (await this.taskRunner.waitFor(this.tag3ApiClient.getTag3ListByTag2Id(tag2Id))).data;
      this.learningModuleTag3FilteredList.set(tag3List);
    }
    this.moduleCriteria.learningModuleTag3Id = 0;
  }

  public ViewModules = async () => {

    this.learningModulePageManager.refreshData();
    this.showModuleSearch = true;
  }

  public async FilterAssessmentTag2ListSearch(tag1Id: number | undefined) {
    this.assessmentCriteria.SelfAssessmentTag2Id = 0;
    if (tag1Id) {
      const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id))).data;
      this.assessmentTag2FilteredList.set(tag2List);
    }
    this.moduleCriteria.learningModuleTag2Id = 0;
  }

  public learningModulePageManager = new Data.PageManager(this.moduleCriteria, LearningModuleLookup, this.dashboardApiClient.getPagedLearningModuleLookup, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "learningModuleTitle",
    sortAscending: true,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });

  /// User Dashboard Settings Properties and Methods
  public userDashboardSetting = new UserDashboardSetting();
  public UpdateUserDashboardSettings = async () => {
    const response = await this.dashboardApiClient.updateUserLastActiveDate();
    if (response.data) {
      this.userDashboardSetting.set(response.data);
    }
  }

  public getUserDashboardSetting = async () => {
    const response = await this.dashboardApiClient.getUserDashboardSetting();

    const cptdPointsResponse = await this.dashboardApiClient.getUserCptdPoints();
    this.cptdPointsEarned = cptdPointsResponse.data;
    this.userDashboardSetting.set(response.data);
    if (this.userDashboardSetting.userModuleCriteria !== null) {
      if (this.userDashboardSetting.userModuleCriteria.keyWord !== "") {
        this.moduleCriteria.meta.title.value = this.userDashboardSetting.userModuleCriteria?.keyWord;
      }

      if (this.userDashboardSetting.userModuleCriteria.tag1Id !== 0) {
        const tag1Response = await this.tag1QueryApiClient.getTag1(this.userDashboardSetting.userModuleCriteria.tag1Id);
        this.moduleCriteria.meta.learningModuleTag1Name.value = tag1Response.data.tagName;
        this.moduleCriteria.meta.learningModuleTag1Id.value = this.userDashboardSetting.userModuleCriteria.tag1Id;

        await this.FilterModuleTag2ListSearch(this.moduleCriteria.learningModuleTag1Id);
        if (this.userDashboardSetting.userModuleCriteria.tag2Id !== 0) {
          this.moduleCriteria.meta.learningModuleTag2Id.value = this.userDashboardSetting.userModuleCriteria.tag2Id;
          await this.FilterModuleTag3ListSearch(this.moduleCriteria.learningModuleTag2Id);
        }
        if (this.userDashboardSetting.userModuleCriteria.tag3Id !== 0) {
          this.moduleCriteria.meta.learningModuleTag3Id.value = this.userDashboardSetting.userModuleCriteria.tag3Id;
        }
      }
    }
  }

  public saveModuleSearchCriteria = async () => {
    this.userDashboardSetting.userModuleCriteria.keyWord = this.moduleCriteria.title;
    this.userDashboardSetting.userModuleCriteria.tag1Id = this.moduleCriteria.learningModuleTag1Id;
    this.userDashboardSetting.userModuleCriteria.tag2Id = this.moduleCriteria.learningModuleTag2Id;
    this.userDashboardSetting.userModuleCriteria.tag3Id = this.moduleCriteria.learningModuleTag3Id;
    const response = await this.dashboardApiClient.saveUserModuleDashboardCriteria(this.userDashboardSetting.toJSObject())
    this.userDashboardSetting.set(response.data);
    this.notifications.addSuccess("Saved", "Saved Search", NotificationDuration.Short);
  }

  public clearModuleSearchCriteria = async () => {
    this.userDashboardSetting.userModuleCriteria.keyWord = "";
    this.userDashboardSetting.userModuleCriteria.tag1Id = 0;
    this.userDashboardSetting.userModuleCriteria.tag2Id = 0;
    this.userDashboardSetting.userModuleCriteria.tag3Id = 0;
    const response = await this.dashboardApiClient.saveUserModuleDashboardCriteria(this.userDashboardSetting.toJSObject())
    this.userDashboardSetting.set(response.data);
    this.moduleCriteria.title = "";
    this.moduleCriteria.learningModuleTag1Id = 0;
    this.moduleCriteria.learningModuleTag1Name = '';
    this.moduleCriteria.learningModuleTag2Id = 0;
    this.moduleCriteria.learningModuleTag3Id = 0;
    this.learningModulePageManager.refreshData();
  }

  /// Assessments Properties and Methods
  public userAssessmentCount: number | null = null;
  public assessmentCount: number | null = null;
  public showAssessmentDrawer = false;
  public assessmentDrawerMaximised = true;
  public AvailableAssessmentList = new List(SelfAssessmentLookup);
  public assessmentCriteria = new SelfAssessmentSearchCriteria();
  public userAssessmentCriteria = new SelfAssessmentSearchCriteria();

  public assessmentPagerManager = new Data.PageManager(this.assessmentCriteria, SelfAssessmentLookup, this.dashboardApiClient.getAvailableAssessmentsPaged, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "selfAssessmentId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });

  public userAssessmentPagerManager = new Data.PageManager(this.userAssessmentCriteria, UserSelfAssessmentLookup, this.dashboardApiClient.getUserAssessmentsPaged, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "selfAssessmentId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });

  private setUserAssessmentCount = async () => {
    const response = await this.taskRunner.waitFor(this.dashboardApiClient.getTotalUserSelfAssessments());
    this.userAssessmentCount = response.data;
  }

  private setAssessmentCount = async () => {
    const response = await this.taskRunner.waitFor(this.dashboardApiClient.getAvailableAssessmentCount());
    this.assessmentCount = response.data;
  }

  public GetAvailableAssessments = async () => {
    this.userAssessmentPagerManager.refreshData();
    this.assessmentPagerManager.refreshData();
    this.showAssessmentDrawer = true;
  }

  /// Conversation Info 
  public conversationDrawer = false;
  public conversationDrawerMaximised = false;
  public newMessageCount: number | null = null;
  public topicCount: number | null = null;

  private GetMessageCount = async () => {
    const response = await this.taskRunner.waitFor(this.dashboardApiClient.getNewMessageCount());
    if (response.data) {
      this.newMessageCount = response.data;
    } else {
      this.newMessageCount = 0;
    }
  }

  public GetTopicCount = async () => {
    const response = await this.taskRunner.waitFor(this.dashboardApiClient.getParticipatedTopicCount());
    if (response.data) {
      this.topicCount = response.data;
    } else {
      this.topicCount = 0;
    }
  }

  public GetConversations = async () => {
    this.conversationDrawer = true;
  }

  /// Badge info 
  public badgeDrawer = false;
  public badgeDrawerMaximised = true;
  public userBadgeCount: number | null = null;
  public badgeCount: number | null = null;

  // public userBadgePageCriteria = new BadgeSearchCriteria();

  public availableBadgePageCriteria = new BadgeSearchCriteria();
  public selectedBadge = new DashboardBadgeLookup();
  public selectedAvailableBadge = new DashboardBadgeLookup();
  public showBadgeInfo = false;
  public showAvailableBadgeInfo = false;

  private GetUserBadgeCount = async () => {
    const response = await this.taskRunner.waitFor(this.dashboardApiClient.getUserBadgeCount());
    this.userBadgeCount = response.data;
  }

  private GetBadgeCount = async () => {
    const response = await this.taskRunner.waitFor(this.dashboardApiClient.getAvailableBadgeCount());
    this.badgeCount = response.data;
  }

  public UserBadges: List<DashboardBadgeLookup> | null = null;

  public GetUserBadges = async () => {
    const response = await this.dashboardApiClient.getUserBadges();
    this.UserBadges = new List(DashboardBadgeLookup);
    this.UserBadges.set(response.data);
  }

  public availableBadgesPagerMananger = new Data.PageManager(this.availableBadgePageCriteria, DashboardBadgeLookup, this.dashboardApiClient.getAvailableBadgesPaged, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "badgeId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
    afterFetch: this.afterFetch.bind(this)
  });

  public ViewBadges = async () => {
    await this.GetUserBadges()
    await this.availableBadgesPagerMananger.refreshData();
    this.badgeDrawer = true;
  }

  private afterFetch(data: DashboardBadgeLookup[]) {
    data.forEach(async badge => {
      const blobUrlResp = await this.badgeApiClient.getBadgeImageByDescriptorId(badge.mediaObject?.fileDescriptorId as string);
      badge.blobUrl = blobUrlResp.data;
    });
  }

  public ViewBadgeInfo = async (badge: DashboardBadgeLookup) => {
    this.selectedBadge = badge;
    this.badgeDrawerMaximised = true;
    this.showBadgeInfo = true;
  }

  public ViewAvailableBadgeInfo = async (badge: DashboardBadgeLookup) => {
    this.selectedAvailableBadge = badge;
    this.badgeDrawerMaximised = true;
    this.showAvailableBadgeInfo = true;
  }

  public module = new UserLearningModuleLookup();

  public PreviewModule = async (moduleId: number) => {
    const response = await this.contentConsumptionApiClient.getUserModulebyIdForPreview(moduleId);
    if (response.data) {
      this.module.set(response.data);
      this.showPreviewDrawer = true;
    }
  }

  public pathwayCriteria = new LearningPathwaySearchCriteria();
  public userPathwayPagerManager = new Data.PageManager(this.pathwayCriteria, LearningPathwayLookup, this.dashboardApiClient.getUserPathways, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "learningPathwayId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });

  public userReminderSettings = async () => {
    const response = await this.dashboardApiClient.getUserReminderSettings();
    if (response.data) {
      this.userReminder.set(response.data);
    }
  }

  public async setReminder() {
    this.showReminderFrequencyModal = true;
    var reminder = new UserReminder();
    reminder.reminderFrequencyId = this.selectedReminderFrequency;
    await this.dashboardApiClient.createUserReminder(reminder.toJSObject());
    this.showReminderFrequencyModal = false;
    await this.userReminderSettings();
  }

  public async removeReminder() {
    const response = await this.taskRunner.run(() => this.dashboardApiClient.deleteUserReminder(this.userReminder.reminderId));
    if (response.data) {
      this.notifications.addSuccess("Reminder removed", null, NotificationDuration.Short);
    }
    this.userReminder = new UserReminder();
  }
}