import { Data, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../App/Services/AppService";
import SchoolSearchCriteria from "../Models/Criteria/SchoolSearchCriteria";
import SchoolLookup from "../Models/Lookups/SchoolLookup";
import School from "../Models/Organisations/School";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum"
import { SchoolImportModalVM } from "./SchoolImportModal";

@NeoModel
export default class SchoolComponentVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private schoolApiClient = AppService.get(Types.Organisations.ApiClients.SchoolApiClient),
        private circuitApiClient = AppService.get(Types.Organisations.ApiClients.CircuitApiClient),
        private schoolQueryApiClient = AppService.get(Types.Organisations.ApiClients.SchoolQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
        this.schoolImportModalVM = new SchoolImportModalVM();
    }

    //Properties
    public schoolImportModalVM!: SchoolImportModalVM
    public selectedSchoolId: number = 0;
    public selectedSchool = new School();

    //DropDowns
    public circuitList = new Data.ApiClientDataSource(this.circuitApiClient.get)

    //School Paging Manager
    public criteria = new SchoolSearchCriteria();
    public showEditModal: boolean = false;
    public showConfirmDeleteModal: boolean = false;

    public schoolLookupPageManager = new Data.PageManager(this.criteria, SchoolLookup, this.schoolQueryApiClient.getSchoolListPaged, {
        pageSize: 10,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sortBy: "schoolName",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner
    });


    public async SetCriteria(circuitId: number) {
        if (circuitId !== 0) {
            this.criteria.circuitId = circuitId;
            this.schoolLookupPageManager.refreshData(true, this.taskRunner);
        } else {
            this.schoolLookupPageManager.refreshData();
        }

    }
    //Methods 

    public async saveSchool() {
        this.taskRunner.run(async () => {
            this.showEditModal = false
            const school = this.selectedSchool
            await this.schoolApiClient.save(school.toJSObject());
            this.selectedSchoolId = 0;
            this.selectedSchool = new School();
            this.schoolLookupPageManager.refreshData()
            this.notifications.addSuccess("School saved", null, NotificationDuration.Short);
        })
    }

    public async deleteSchool(schooldId: number) {
        this.taskRunner.run(async () => {
            await this.schoolApiClient.delete(schooldId);
            this.schoolLookupPageManager.refreshData();
            this.notifications.addSuccess("School deleted", null, NotificationDuration.Short);
        })
    }

    public async showSchoolEditModal(schoolId: number) {
        if (schoolId !== 0) {
            this.selectedSchoolId = schoolId
            const response = await this.taskRunner.waitFor(this.schoolApiClient.getById(schoolId));
            this.selectedSchool.set(response.data)
        }
        this.showEditModal = true;
    }

    public async closeEditModal() {
        this.selectedSchool = new School();
        this.selectedSchoolId = 0;
        this.showEditModal = false;
    }

    public async ShowSchoolImportModal() {
        this.schoolImportModalVM.show()
    }

    public async CloseSchoolImportModal() {
        this.schoolImportModalVM.showSchoolImportModal = false;
    }

    public clearFilters() {
        this.criteria.meta.circuitId.value = null;
        this.criteria.meta.schoolName.value = "";
        this.schoolLookupPageManager.reset();
        this.schoolLookupPageManager.refreshData();
    }
}