import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class ChangePasswordModel extends ModelBase {
    
    @Attributes.Display("Current Password")
    public oldPassword: string = ""; 

    @Attributes.Display("New Password")    
    public newPassword: string = ""; 

    @Attributes.Display("Confirm New Password")
    public newPasswordCofirm: string = "";
    // Client only properties / methods    

    private passwordRegEx: RegExp = new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$');

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);  

        rules.failWhen(c => c.oldPassword === "", "You have not supplied the current password.");
        rules.failWhen(c => c.newPassword === "", "You have not supplied a new password.");
        rules.failWhen(c => c.newPasswordCofirm === "", "You have not supplied the confirm password.");
        
        rules.failWhen(c => !this.passwordRegEx.test( c.newPassword), "Your password is not complex enough. You need at least 8 charcters and 1 number and one special character.");        

        rules.failWhen(c => c.newPasswordCofirm !== c.newPassword, "Your new passwords do not match");
    }

}