import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Organisation from '../../Models/Organisations/Organisation';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';

export interface IOrganisationQueryApiClient {

    /** 
     * This method will return a sepcific organisation based on the organisationId with all its children
     * @returns Organisation list with childreny
     */
    getOrganisations(): AxiosPromise<Array<Model.PlainTrackedObject<Organisation>>>;

    /** 
     * This method will return a sepcific organisation based on the organisationId with all its children
     * @param organisationId The page request
     * @returns Organisation list with children
     */
    getOrganisation(organisationId: number): AxiosPromise<Model.PlainTrackedObject<Organisation>>;

    // Client only properties / methods
}

@injectable()
export default class OrganisationQueryApiClient extends Data.ApiClientBase implements IOrganisationQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/OrganisationQuery`);
    }

    public getOrganisations(): AxiosPromise<Array<Model.PlainTrackedObject<Organisation>>> {
        return this.axios.get(`${this.apiPath}/Organisations`);
    }

    public getOrganisation(organisationId: number): AxiosPromise<Model.PlainTrackedObject<Organisation>> {
        return this.axios.get(`${this.apiPath}/Organisation/${organisationId}`);
    }

    public checkOrganisaionLinks(organisationId: number): AxiosPromise<Model.PartialPlainObject<Organisation>[]> {
        return this.axios.get(`${this.apiPath}/CheckOrganisationLinks/${organisationId}`);
    }

    // Client only properties / methods
}