import { Data, List, ModalUtils, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { truncateLongString } from '../../../App/HelperClasses/GlobalHelpers';
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration.enum';
import { AppService, Types } from '../../../App/Services/AppService';
import TDPUser from '../../../Identity/Models/Security/TDPUser';
import UserProfileLookup from '../../../Identity/Models/Users/Lookups/UserProfileLookup';
import SelfAssessmentSearchCriteria from '../../Models/Criteria/SelfAssessmentSearchCriteria';
import { ModerationState } from '../../Models/LearningObjects/ModerationState.enum';
import ScalesLookup from '../../Models/Lookups/ScalesLookup';
import SelfAssessmentLookup from '../../Models/Lookups/SelfAssessmentLookup';
import SelfAssessmentModerationLookup from '../../Models/Lookups/SelfAssessmentModerationLookup';
import SelfAssessmentQuestionDragableModel from '../../Models/Lookups/SelfAssessmentQuestionDragableModel';
import SelfAssessmentQuestionLookup from '../../Models/Lookups/SelfAssessmentQuestionLookup';
import SelfAssessmentQuestionTagLookup from '../../Models/Lookups/SelfAssessmentQuestionTagLookup';
import SelfAssessmentTagLookup from '../../Models/Lookups/SelfAssessmentTagLookup';
import SelfAssessmentModerationSummary, { AssessmentQuestion, AssessmentQuestionContent, AssessmentTagsSummary } from '../../Models/Moderation/SelfAssessmentModerationSummary';
import { Learning } from '../../Models/Security/LearningRoles';
import Tag2 from '../../Models/Tags/Tag2';
import { QuestionType } from './SelfAssessmentQuestionComponent';

export enum selfAssessmentTabs {
  selfAssessments = "Self Assessments",
  questionOrdering = "Question Ordering"
}

@NeoModel
export default class AssessmentVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
    private tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
    private selfAssessmentApiClient = AppService.get(Types.Learning.ApiClients.SelfAssessmentApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService),
    private identityService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
    super(taskRunner);
    this.user = identityService.user;
    this.currentUser = new UserProfileLookup()
    this.canChangeModeratedAssessment = this.authorisationService.hasRole(Learning.ChangeModeratedAssessment);
  }

  /// Assessments Section

  ///Properties
  public selectedAssessment = new SelfAssessmentLookup();
  public assessmentCriteria = new SelfAssessmentSearchCriteria();
  public assessmentTag2FilteredList = new List(Tag2);
  public selectedAssessmentId = 0;
  public questionsDragAndDropList: SelfAssessmentQuestionDragableModel[] = [];
  public selectedQuestionId: number = 0;
  public selectedQuestion = new SelfAssessmentQuestionLookup();
  public hideSearchArea = false;
  public currentUser: UserProfileLookup;
  private user: TDPUser | null = null;
  public selfAssessmentQuestionFilteredTag3List = new List(SelfAssessmentQuestionTagLookup);
  public scalesList = new List(ScalesLookup);

  ///Bindings
  public hideAssessmentCard = true;
  public myContent: boolean = false;
  public selectedTab = selfAssessmentTabs.selfAssessments;
  public hideEditAssessmentCard = true;
  public hideEditTagsCard = true;
  public hideUpdate = true;
  public hideQuestionComponent = true;
  public disableEdit = false;
  public canChangeModeratedAssessment = false;

  /// Methods

  public CreateNewAssessment() {
    this.selectedAssessmentId = 0;
    this.selectedAssessment = new SelfAssessmentLookup();
    this.selectedQuestion = new SelfAssessmentQuestionLookup();
    this.selectedQuestionId = 0;
    this.hideAssessmentCard = false;
    this.hideEditTagsCard = true;
    this.hideQuestionComponent = true;
    this.disableEdit = false;
  }

  /// Pager and filters
  public async filterTag2ListSearch(tag1Id: number | undefined) {
    if (tag1Id) {
      const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id))).data;
      this.assessmentTag2FilteredList.set(tag2List);
    }
    this.assessmentCriteria.SelfAssessmentTag2Id = 0;
  }

  public selfAssessmentPageManager = new Data.PageManager(this.assessmentCriteria, SelfAssessmentLookup, this.selfAssessmentApiClient.getPagedSelfAssessmentLookup, {
    pageSize: 15,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "selfAssessmentId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
    fetchInitial: true,
  });

  public searchAssessments() {
    this.selfAssessmentPageManager.refreshData();
  }

  public clearSearch() {
    this.selectedAssessment = new SelfAssessmentLookup();
    this.selectedAssessmentId = 0;
    this.hideEditAssessmentCard = true;
    this.hideAssessmentCard = true;
    this.hideQuestionComponent = true;
    this.hideEditTagsCard = true;
    this.selectedQuestionId = 0;
    this.selectedQuestion = new SelfAssessmentQuestionLookup();
    this.assessmentCriteria.SelfAssessmentTitle = "";
    this.assessmentCriteria.meta.Createdby.value = this.currentUser.userGuid;
    this.assessmentCriteria.FilterByCreated = true;
    this.assessmentCriteria.keyword = "";
    this.assessmentCriteria.SelfAssessmentId = null;
    this.assessmentCriteria.SelfAssessmentTag2Id = 0;
    this.assessmentCriteria.SelfAssessmentTag1Id = 0;
    this.assessmentCriteria.SelfAssessmentTag1Name = "";
    this.assessmentCriteria.StateSelectedUnknown = false;
    this.assessmentCriteria.StateSelectedUnmoderated = true;
    this.assessmentCriteria.StateSelectedSubmittedForModeration = false;
    this.assessmentCriteria.StateSelectedUnderModeration = false;
    this.assessmentCriteria.StateSelectedReview = false;
    this.assessmentCriteria.StateSelectedModerated = false;
    this.assessmentCriteria.StateSelectedResubmittedForModeration = false;
    this.myContent = true;
    this.selfAssessmentPageManager.refreshData();
  }

  public UpdateCriteriaCreatedBy() {
    if (this.assessmentCriteria.meta.Createdby.value === '') {
      this.assessmentCriteria.meta.Createdby.value = this.currentUser.userGuid;
      this.myContent = true;
    } else {
      this.assessmentCriteria.meta.Createdby.value = '';
      this.myContent = false;
    }
  }

  public async selectAssessment(assessmentId: number) {
    this.taskRunner.run(async () => {
      await this.refreshSelfAssessment(assessmentId)
      await this.fetchQuestionsForDragAndDrop(assessmentId);
      this.hideSearchArea = true;
      this.hideAssessmentCard = false;
      this.hideEditTagsCard = false;
      this.hideQuestionComponent = true;
    })
  }

  public async showDeleteSelfAssessment(selfAssessment: SelfAssessmentLookup, selfAssessmentId: number) {
    await ModalUtils.showYesNoDismissible("Delete Self Assessment " + selfAssessment.selfAssessmentTitle,
      "Are you sure you want to delete this Self Assessment? ", () => this.deleteSelfAssessment(selfAssessmentId));
  }

  public async deleteSelfAssessment(selfAssessmentId: number) {
    this.taskRunner.run(async () => {
      const response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.deleteSelfAssessment(selfAssessmentId));
      if (response.data === false) {
        this.notifications.addDanger("Delete unsuccessful", "SelfAssessment cannot be deleted as it has already been moderated", NotificationDuration.Standard);
      }
      else {
        this.selfAssessmentPageManager.refreshData();
        this.notifications.addSuccess("Self Assessment Deleted", null, NotificationDuration.Standard);
      }
    });
  }

  public async fetchQuestionsForDragAndDrop(selfAssessmentId: number) {
    var responseDnd = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentQuestionDNDObjects(selfAssessmentId));
    if (responseDnd.data.length > 0) {
      responseDnd.data.forEach(element => {
        element.text = truncateLongString(JSON.parse(element.text).blocks[0].text);
      });
      return this.questionsDragAndDropList = responseDnd.data;
    }
    else {
      return this.questionsDragAndDropList;
    }
  }

  public async saveQuestionOrderChanges() {
    for (const selfsDnd of this.questionsDragAndDropList) {
      await this.selfAssessmentApiClient.UpdateSelfAssessmentQuestionOrder(selfsDnd.id, selfsDnd.order);
    }
    this.questionsDragAndDropList = [];
    this.fetchQuestionsForDragAndDrop(this.selectedAssessment.selfAssessmentId);
    this.selfAssessmentPageManager.refreshData();
    this.notifications.addSuccess("Self Assessment Questions updated", "Self Assessment Questions updated", NotificationDuration.Standard);
  }

  //used to track Dnd changes
  public setDNDItems(items: Array<SelfAssessmentQuestionDragableModel>) {
    this.questionsDragAndDropList = items;
    this.questionsDragAndDropList.forEach(element => {
      const item = items.find(c => c.id === element.id);
      const newindex = items.findIndex(c => c.id === element.id);
      if (item?.id === element.id) {
        element.order = newindex;
      }
    });
  }

  public showTagCards() {
    if (this.selectedAssessment.selfAssessmentId === 0) {
      this.hideEditTagsCard = false;
      this.hideUpdate = false;
    }
    this.hideEditTagsCard = false;
  }

  public async filterTag2List(selfAssessmentTag: SelfAssessmentTagLookup, tag1Id?: number | undefined) {
    if (tag1Id) {
      const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id as number))).data;
      selfAssessmentTag.filteredTag2List.update(tag2List);
    }
  }

  public checkForDuplicateTag2s(id: number) {
    var tag2s = this.selectedAssessment.selfAssessmentTags2.filter(c => c.tag2Id === id);
    if (tag2s.length > 1) {
      ModalUtils.showMessage("This is a Duplicate Tag2", "Please Remove Duplicate Tag", 4);
    }
  }

  public async deleteSelfAssessmentTag2(selfAssessmentTag: SelfAssessmentTagLookup) {
    var canDelete = await this.CanDeleteCTag2(selfAssessmentTag.tag2Id);
    if (canDelete) {
      if (selfAssessmentTag.isNew) {
        this.selectedAssessment.selfAssessmentTags2.remove(selfAssessmentTag);
      }
      else {
        await ModalUtils.showYesNoDismissible("Delete Topic(T1), Sub-Category(T2)",
          "Are you sure you want to delete this Topic(T1) -> " + selfAssessmentTag.tag1Name + ", Sub-Category(T2) -> " + selfAssessmentTag.tag2Name + ", from this Self Assessment?", () => this.deleteTag2(selfAssessmentTag));
      }
    }
    else {
      ModalUtils.showMessage("Can't delete Tags", "The selected tags have Self Assessment Question containing Linked tag 3's. Please remove before deleting.", 4);
    }
  }

  public async deleteTag2(selfAssessmentTag: SelfAssessmentTagLookup) {
    const response = await this.selfAssessmentApiClient.deleteSelfAssessmentTag2(selfAssessmentTag.selfAssessmentId, selfAssessmentTag.tag2Id);
    if (response.data) {
      this.selectedAssessment.selfAssessmentTags2.remove(selfAssessmentTag);
      this.notifications.addSuccess("Self Assessment Tag Deleted", "Self Assessment Tags Delete ", NotificationDuration.Standard);
    } else {
      this.notifications.addDanger("Delete unsuccessful", "Tag cannot be deleted as it has already been moderated", NotificationDuration.Standard);
    }
  }

  public async CanDeleteCTag2(Tag2Id: number) {
    var canDelete = true;
    const response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentTagList(this.selectedAssessmentId));
    var tag = response.data.filter(c => c.tag2Id === Tag2Id).length;
    if (tag !== 0) {
      canDelete = false;
    }
    return canDelete;
  }

  public async saveSelfAssessment() {
    var SelfAssessment = this.selectedAssessment;
    SelfAssessment.selfAssessmentTags2.set(this.selectedAssessment.selfAssessmentTags2.toJSArray());
    if (this.selectedAssessment.selfAssessmentId !== 0) {
      this.taskRunner.run(async () => {
        var response = await this.selfAssessmentApiClient.updateSelfAssessment(SelfAssessment.toJSObject({ includeClean: true }));
        if (response.data) {
          this.selfAssessmentPageManager.refreshData();
          await this.refreshSelfAssessment(response.data.selfAssessmentId);
          this.notifications.addSuccess("Self Assessment Updated", "Self Assessment Updated", 4);
        }
        else {
          this.notifications.addDanger("Self Assessment Update Failed", "Self Assessment Update Failed", 4);
        }
      });
    } else {
      this.taskRunner.run(async () => {
        var response = await this.selfAssessmentApiClient.SaveSelfAssessment(SelfAssessment.toJSObject({ includeClean: true }));
        if (response.data) {
          this.selfAssessmentPageManager.refreshData();
          await this.refreshSelfAssessment(response.data.selfAssessmentId);
          this.notifications.addSuccess("Self Assessment Saved", "Self Assessment Saved", NotificationDuration.Standard);
          this.hideUpdate = false;
          this.hideSearchArea = true;
        }
        else {
          this.notifications.addDanger("Self Assessment Save Failed", "Self Assessment Save Failed", NotificationDuration.Standard);
        }
      });
    }
    this.hideUpdate = true;
  }

  public async refreshSelfAssessment(selfAssessmentId: number) {
    const responseList = await this.selfAssessmentApiClient.getSelfAssessmentQuestionTagSelectList(selfAssessmentId);
    this.selfAssessmentQuestionFilteredTag3List.set(responseList.data);
    this.selectedAssessmentId = selfAssessmentId;
    var response = await this.selfAssessmentApiClient.getSelfAssessment(selfAssessmentId);
    this.selectedAssessment.set(response.data);
    if (this.selectedAssessment.moderationState === ModerationState.Moderated
      || this.selectedAssessment.moderationState === ModerationState.UnderModeration
      || this.selectedAssessment.moderationState === ModerationState.ResubmittedForModeration) {
      if (!this.canChangeModeratedAssessment) {
        this.disableEdit = true;
      }
    } else {
      this.disableEdit = false;
    }
    this.assessmentCriteria.SelfAssessmentId = this.selectedAssessment.selfAssessmentId;
    this.selfAssessmentPageManager.refreshData();
  }

  public selfAssessmentValid() {
    var SelfAssessment = this.selectedAssessment;
    if (SelfAssessment.selfAssessmentTags2.length > 0
      && ((SelfAssessment.questionTypeId === QuestionType.LikertScale as number && SelfAssessment.sentiment > 0)
        || (SelfAssessment.questionTypeId === QuestionType.MultipleChoice as number))) {
      return false;
    }
    else {
      return true;
    }
  }

  public addNewQuestion() {
    this.selectedQuestionId = 0;
    this.selectedQuestion = new SelfAssessmentQuestionLookup();
    this.hideQuestionComponent = false;
  }

  public async setSelectedQuestion(questionId: number) {
    // await this.selectAssessment(this.selectedAssessmentId);
    this.taskRunner.run(async () => {
      this.hideQuestionComponent = true;
      this.selectedQuestionId = questionId;
      this.hideQuestionComponent = false;
    })
  }

  public saveQuestion(question: SelfAssessmentQuestionLookup) {
    this.taskRunner.run(async () => {
      const response = await this.selfAssessmentApiClient.SaveSelfAssessmentQuestion(question.toJSObject({ includeClean: true }));
      if (response.data) {
        this.notifications.addSuccess("Question Saved", "Self-Assessment question saved Successfully", NotificationDuration.Standard);
        this.hideQuestionComponent = true;
        this.selectedQuestionId = 0;
        this.selectedQuestion = new SelfAssessmentQuestionLookup();

        this.selfAssessmentPageManager.refreshData();
        await this.refreshSelfAssessment(this.selectedAssessmentId);
        this.selectedQuestionId = response.data.selfAssessmentQuestionId;
        this.hideQuestionComponent = false;
      }
    });
  }

  public async deleteSelfAssessmentQuestion(questionToDelete: SelfAssessmentQuestionLookup) {
    await ModalUtils.showYesNoDismissible("Delete " + questionToDelete.selfAssessmentQuestionId,
      "Are you sure you want to delete this Question? ", () => this.deleteQuestionConfirmed(questionToDelete));
  }

  public async deleteQuestionConfirmed(question: SelfAssessmentQuestionLookup) {
    await this.taskRunner.waitFor(this.selfAssessmentApiClient.DeleteQuestion(question.selfAssessmentQuestionId));
    this.selfAssessmentPageManager.refreshData();
  }

  ///Moderation Section

  /// Properties
  public assessmentModerationSummary = new SelfAssessmentModerationSummary();
  public selfAssessmentModerationLookup = new SelfAssessmentModerationLookup();

  ///Bindings
  public hideModerationFilters = true;

  ///Methods

  public submitForModeration = async (assessmentId: number) => {
    await this.selfAssessmentApiClient.submitAssessmentForModeration(assessmentId);
    this.clearSearch();
    this.selfAssessmentPageManager.refreshData();
    // this.searchSelfAssessments();
    // this.hideCriteria = false;
    this.notifications.addSuccess("Assessment Submitted", "Submitted for Moderation. You will be notified via email once assessment has been moderated", NotificationDuration.Standard);
  }

  public resubmitSelfAssessmentForModeration = async (assessmentId: number) => {
    await this.selfAssessmentApiClient.resubmitAssessmentForModeration(assessmentId);
    this.notifications.addSuccess("Assessment Resubmitted", "Resubmitted for Moderation. You will be notified via email once assessment has been moderated", NotificationDuration.Standard);
    this.selfAssessmentPageManager.refreshData();
  }

  public showReviewSummary = false;
  public reviewDrawerIsMax = true;

  public setSelfAssessmentModerationLookup = async (id: number) => {
    this.selectedAssessmentId = id;
    this.selfAssessmentModerationLookup = new SelfAssessmentModerationLookup();
    var response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getModerateableSelfAssessment(id));
    this.selfAssessmentModerationLookup.set(response.data);
    this.selfAssessmentModerationLookup.selfAssessmentDescription.meta.ApprovedByModerator.value = this.selfAssessmentModerationLookup.selfAssessmentDescription.fieldState === ModerationState.Moderated;
    this.selfAssessmentModerationLookup.selfAssessmentTitle.meta.ApprovedByModerator.value = this.selfAssessmentModerationLookup.selfAssessmentTitle.fieldState === ModerationState.Moderated;
    this.selfAssessmentModerationLookup.selfAssessmentTags2.forEach(element => {
      element.meta.approvedByModerator.value = element.fieldState === ModerationState.Moderated;
    });
    var responseQuestion = await this.taskRunner.waitFor(this.selfAssessmentApiClient.GetSelfAssessmentModerationQuestionLookupList(id));
    this.selfAssessmentModerationLookup.selfAssessmentQuestions.set(responseQuestion.data);
    this.selfAssessmentModerationLookup.selfAssessmentQuestions.forEach(element => {
      element.questionText.meta.ApprovedByModerator.value = element.questionText.fieldState === ModerationState.Moderated;
      element.tag3.meta.ApprovedByModerator.value = element.tag3.fieldState === ModerationState.Moderated;
      element.selfAssessmentOptions.meta.ApprovedByModerator.value = element.selfAssessmentOptions.fieldState === ModerationState.Moderated;
    });
    this.fetchQuestionsForDragAndDrop(this.selectedAssessmentId);
  }

  public getModerationSummary = async (selfAssessmentId: number) => {
    this.taskRunner.run(async () => {
      await this.setSelfAssessmentModerationLookup(selfAssessmentId);

      const assessmentInfo = this.selfAssessmentModerationLookup;
      /// Set the assessment moderation info.
      this.assessmentModerationSummary = new SelfAssessmentModerationSummary();
      this.assessmentModerationSummary.selfAssessmentId = assessmentInfo.selfAssessmentId;
      this.assessmentModerationSummary.title = assessmentInfo.selfAssessmentTitle.value;
      this.assessmentModerationSummary.titleApproved = assessmentInfo.selfAssessmentTitle.fieldState === ModerationState.Moderated;
      this.assessmentModerationSummary.description = assessmentInfo.selfAssessmentDescription.value;
      this.assessmentModerationSummary.descriptionApproved = assessmentInfo.selfAssessmentDescription.fieldState === ModerationState.Moderated;
      this.assessmentModerationSummary.baseAssessmentApproved = this.assessmentModerationSummary.titleApproved && this.assessmentModerationSummary.descriptionApproved;

      /// Check that the assessment tags are moderated 
      assessmentInfo.selfAssessmentTags2.forEach(tagLookUp => {
        var tagSummary = new AssessmentTagsSummary();
        tagSummary.tag1Id = tagLookUp.tag1Id;
        tagSummary.tag2Id = tagLookUp.tag2Id;
        tagSummary.tag1Name = tagLookUp.tag1Name;
        tagSummary.tag2Name = tagLookUp.tag2Name;
        tagSummary.approved = tagLookUp.fieldState === ModerationState.Moderated;
        tagSummary.errorMessage = tagSummary.approved ? "" : `Category Tag: ${tagLookUp.tag1Name} with Sub-Category Tag: ${tagLookUp.tag2Name} is not approved.`;
        this.assessmentModerationSummary.assessmentTags.push(tagSummary);
        if (!tagSummary.approved) {
          this.assessmentModerationSummary.tagsApproved = false;
        }
      });

      /// Check that the questions are moderated
      assessmentInfo.selfAssessmentQuestions.forEach(async (assessmentQuestion) => {

        var questionSummary = new AssessmentQuestion();
        questionSummary.questionId = assessmentQuestion.selfAssessmentQuestionId;
        questionSummary.question = assessmentQuestion.questionText.value;
        questionSummary.questionApproved = assessmentQuestion.questionText.fieldState === ModerationState.Moderated;
        questionSummary.tag3Approved = assessmentQuestion.tag3.fieldState === ModerationState.Moderated;
        questionSummary.optionsApproved = assessmentQuestion.selfAssessmentOptions.fieldState === ModerationState.Moderated;
        questionSummary.questionNotes = assessmentQuestion.moderationNotes;
        questionSummary.assessmentQuestionApproved = questionSummary.questionApproved && questionSummary.tag3Approved && questionSummary.optionsApproved;
        if (!questionSummary.assessmentQuestionApproved) {
          this.assessmentModerationSummary.assessmentQuestionsApproved = false;
        }
        if (assessmentQuestion.contentId !== null) {

          const content = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentById(assessmentQuestion.contentId);
          // this.questionLearningContent.set(questionLearningContent.data);

          var questionContent = new AssessmentQuestionContent();
          questionContent.contentId = content.data.learningContentId;
          questionContent.contentTitle = content.data.learningContent.title;
          questionContent.contentApproved = content.data.learningContent.moderation.moderationState === ModerationState.Moderated;

          questionSummary.questionContent = questionContent;
          if (!questionContent.contentApproved) {
            questionSummary.questionContentApproved = false;
          }
        }
        this.assessmentModerationSummary.assessmentQuestions.push(questionSummary);
      })

      this.showReviewSummary = true;
    })
  }

}