
import * as React from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import "../../Styles/ModuleDragAndDrop.scss";
import PathwayModuleDragAndDrop from '../../Models/LearningPathways/PathwayModuleDragAndDrop';
import { Neo } from '@singularsystems/neo-react';
import { UserLearningStatus } from '../../Models/UserLearning/UsersLearningStatus.enum';
import { truncateString } from '../../../App/HelperClasses/GlobalHelpers';
import Card from '../../../Template/components/Card/Card';
import CardHeader from '../../../Template/components/Card/CardHeader';
import CardIcon from '../../../Template/components/Card/CardIcon';
import CardBody from '../../../Template/components/Card/CardBody';
import CardFooter from '../../../Template/components/Card/CardFooter';

interface Props {
  items: PathwayModuleDragAndDrop[];
  setDNDItems: (items: Array<PathwayModuleDragAndDrop>) => void;
  showParticipantInfo: (id: number) => void;
  GoToModule: (moduleId: number) => void;
}
export default class ModuleDragAndDropComponent extends React.Component<Props> {
  render() {
    const items = this.props.items;
    return (

      <RLDD layout="vertical" items={items} itemRenderer={this.itemRenderer} onChange={this.handleRLDDChange} />

    );
  }
  private itemRenderer = (item: PathwayModuleDragAndDrop, index: number): JSX.Element => {
    return (
      <div className="item" >
        <Card>
          <CardHeader icon>
            <CardIcon color="warning">
              <h3 >{index + 1}</h3>
            </CardIcon>
            <Neo.Button disabled style={{ float: "right" }} variant="danger" hidden={!item.isDeactivated ? true : false} text="This Module is Deactivated"
              tooltip="This module is deactivated and there may be a newer version of this module available." isOutline />
            <h4 className="Card-icon-header-text-dnd">{truncateString(item.moduleTitle, 60)}</h4>
          </CardHeader>
          <CardBody>

            <span>{item.learningObjective}</span>
          </CardBody>
          <CardFooter>
            <div style={{ float: 'left' }}>
              <h5 hidden={item.status !== UserLearningStatus.Awaiting} className="dnd-progress">Awaiting</h5>
              <h5 hidden={item.status !== UserLearningStatus.InProgress} className="dnd-progress">In Progress</h5>
              <h5 hidden={item.status !== UserLearningStatus.Completed} className="dnd-progress">Completed</h5>
            </div>
            <div style={{ float: 'right' }}>
              <Neo.Button icon="eye " text="Go To Module" isOutline variant="success" onClick={() => { this.props.GoToModule(item.id) }} />
              <Neo.Button hidden={!item.invitedPathway} tooltip="Participant Info" icon="users" onClick={() => this.props.showParticipantInfo(item.moduleId)} />
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  };

  private handleRLDDChange = (reorderedItems: Array<PathwayModuleDragAndDrop>) => {
    this.props.setDNDItems(reorderedItems)
  };
}
