import { NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import TDPUser from "../../Identity/Models/Security/TDPUser";
import { UserTypes } from "../Models/Enums/UserTypes.enums";
import { AppService, Types } from "../Services/AppService";

@NeoModel
export class UserStatusVM extends Views.ViewModelBase {
    constructor(
        public taskRunner = AppService.get(Types.Neo.TaskRunner)) {
        super();
    }

    public isHidden: boolean = true;
}

@observer
export default class UserStatus extends React.Component {
    private authService = AppService.get(Types.Neo.Security.AuthenticationService);
    public viewModel = new UserStatusVM();
    constructor(props: any) {
        super(props);
        this.viewModel = new UserStatusVM();
        this.logoutClicked = this.logoutClicked.bind(this);
    }

    private logoutClicked(e: React.MouseEvent) {
        e.preventDefault();

        this.authService.beginSignOut();
    }

    private helpLink() {
        window.open('http://nicpd.online/help', '_blank');
    }

    public render() {
        const user = this.authService.user as TDPUser;

        return (
            user &&
            <div className="app-user">
                <Neo.Button variant="link" className="link-help-btn" tooltip={"Need help getting started?"} onClick={() => this.helpLink()} >
                    <i className="fa fa-question-circle fa-1x" />
                    <b> HELP</b>
                </Neo.Button>

                <div className="app-user-icon" onClick={() => this.viewModel.isHidden = !this.viewModel.isHidden}>
                    <i className="fa fa-user" />
                    <div className="app-user-card" hidden={this.viewModel.isHidden} >
                        <div className="card-arrow" />
                        <div className="card ">
                            <div className="card-body bg-dark text-light">
                                <h5 className="mb-0">{user.fullName}</h5>
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item" ><div style={{ textAlign: "center" }}><img alt="" src="https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/60/000000/external-badge-award-vitaliy-gorbachev-fill-vitaly-gorbachev-1.png" /></div></li>
                                {(user.userType === String(UserTypes.Admin)) &&
                                    <li className="list-group-item">
                                        <Neo.Button isOutline disabled={true} className="primary-color-pulse" variant="success" icon="fa-user-shield" >You are an Admin User</Neo.Button>

                                    </li>
                                }
                                <li className="list-group-item"><a href="/Identity/Profile">Profile</a></li>
                                <li hidden={user.userType !== UserTypes.Admin.toString()} className="list-group-item"><a href="/Identity/ChangePassword">Change Password</a></li>
                                <li className="list-group-item"><a href="/" onClick={this.logoutClicked}>Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}