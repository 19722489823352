import { Data, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../ForumTypes';
import TopicLookup from '../../../Models/Lookups/TopicLookup';
import TopicLookUpCriteria from '../../../Models/Criteria/TopicLookupCriteria'
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration.enum';


@NeoModel
export default class GlobalConversationVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private topicQueryApiClient = AppService.get(Types.Forum.ApiClients.TopicQueryApiClient),
    private topicUpdateApiClient = AppService.get(Types.Forum.ApiClients.TopicUpdateApiClient),
    private moduleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  public selectedTopic = new TopicLookup();

  public forumId = 0;
  public moduleTitle = ""
  public topicCriteria = new TopicLookUpCriteria();
  public showConversation = false;

  public globalModuleTopicPager = new Data.PageManager(this.topicCriteria, TopicLookup, this.topicQueryApiClient.getGlobalTopicsPaged, {
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 30],
    sortBy: "createdOn",
    sortAscending: false,
    fetchInitial: false,
    initialTaskRunner: this.taskRunner,
  });

  public async GetModuleById(id: number) {
    const response = await this.moduleApiClient.getLearningModuleById(id);
    this.moduleTitle = response.data.title.value;
  }

  public async CreateTopic(topic: TopicLookup) {
    topic.forumId = this.forumId;
    const response = await this.topicUpdateApiClient.createTopicAsync(topic.toJSObject());
    if (response.data) {
      this.globalModuleTopicPager.refreshData();
    }
  }

  public async ViewTopicConversation(topic: TopicLookup) {
    this.selectedTopic.set(topic.toJSObject());
    this.showConversation = true;
  }

  public backToTopics() {
    this.globalModuleTopicPager.refreshData();
    this.selectedTopic = new TopicLookup();
    this.showConversation = false;
  }

  public showConfirmDelete = false;
  public topicMarkedForDelete = new TopicLookup();
  public deletedReason = "";
  public DeleteTopic(topic: TopicLookup) {
    this.deletedReason = "";
    if (topic.isNew) {
      this.globalModuleTopicPager.data.remove(topic);
    } else {
      this.topicMarkedForDelete.set(topic.toJSObject());
      this.showConfirmDelete = true;
    }
  }

  public async ConfirmDeleteTopic() {
    const response = await this.topicUpdateApiClient.deleteTopicByIdAsync(this.topicMarkedForDelete.topicId)
    if (response.data) {
      this.notifications.addSuccess("Topic Deleted", `Topic Deleted`, NotificationDuration.Standard);
      this.globalModuleTopicPager.refreshData();
      this.topicMarkedForDelete = new TopicLookup();
      this.showConfirmDelete = false;
    } else {
      this.notifications.addSuccess("Topic Deleted failed", `Deleted failed`, NotificationDuration.Standard);
    }
  }
}