import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import SelfAssessmentVM from './SelfAssessmentVM';
import { observer } from 'mobx-react';
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import CardBody from '../../Template/components/Card/CardBody';
import NavPills from '../../Template/components/Panels/NavPills';
import CardIcon from '../../Template/components/Card/CardIcon';
import GridContainer from '../../Template/components/Grid/GridContainer';
import GridItem from '../../Template/components/Grid/GridItem';
import SelfAssessmentLookup from '../../Learning/Models/Lookups/SelfAssessmentLookup';
import { Tooltip } from '@material-ui/core';
import SelfAssessmentConsumptionView from '../../Learning/Views/ContentConsumption/SelfAssements/SelfAssessmentConsumptionView';
import UserSelfAssessmentLookup from "../Models/UserAssessmentLookup";
import ReviewSelfAssessmentView from '../../Learning/Views/Assessment/ReviewSelfAssessmentView';

@observer
export default class SelfAssessmentView extends Views.ViewBase<SelfAssessmentVM> {

  constructor(props: unknown) {
    super("Self Assessment", SelfAssessmentVM, props);
  }

  public GoToSelfAssessment = (id: number) => {
    this.navigation.navigateToView(SelfAssessmentConsumptionView, { selfAssementId: id, });
  }

  public ReviewSelfAssessment = (id: number) => {
    this.navigation.navigateToView(ReviewSelfAssessmentView, { userAssessmentId: id, });
  }

  public render() {
    const viewModel = this.viewModel
    return (
      <div>
        <Card>
          <NavPills
            color="rose"
            tabs={[
              {
                tabButton: "Available Questionnaires",
                tabIcon: "clipboard fa-3x",
                tabContent: (
                  <div>
                    <Card>
                      <CardHeader icon>
                        <CardIcon color="success">
                          <i className={`icon fa fa-clipboard fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Available Questionnaires</h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <Neo.FormGroupFloating label="Search for Questionnaires by title" bind={this.viewModel.assessmentCriteria.meta.keyword} />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6} >
                            <label>Category Tag</label>
                            <Neo.AutoCompleteDropDown
                              bind={this.viewModel.assessmentCriteria.meta.SelfAssessmentTag1Id}
                              bindDisplay={this.viewModel.assessmentCriteria.meta.SelfAssessmentTag1Name}
                              displayMember="tagName"
                              itemSource={this.viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                              onItemSelected={(item) => { this.viewModel.FilterAssessmentTag2ListSearch(item?.tag1Id) }}
                              loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                              minCharacters={2}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Neo.FormGroup onSubmit={() => { viewModel.assessmentPagerMananger.refreshData() }} label="Sub-Category Tag (based on Category Tag)" bind={viewModel.assessmentCriteria.meta.SelfAssessmentTag2Id} select={{
                              items: viewModel.assessmentTag2FilteredList,
                              allowNulls: true
                            }} />
                          </GridItem>
                          <GridItem xs={2} sm={2} md={4}>
                            <Neo.Button isOutline variant="info" text="Search" icon="search" type="submit" onClick={() => viewModel.assessmentPagerMananger.refreshData()} />
                            <Neo.Button isOutline text="Clear Search" variant="warning" icon="eraser" onClick={() => viewModel.ClearAssessmentFilters()} />
                          </GridItem>
                        </GridContainer>
                        <Neo.Pager pageManager={this.viewModel.assessmentPagerMananger}
                          pageControlProps={{
                            firstText: "",
                            prevText: "Prev",
                            nextText: "Next",
                            lastText: "",
                            alignment: "center",
                            autoHide: false,
                            pageSizeLabel: "Show"
                          }}>
                          <NeoGrid.Grid<SelfAssessmentLookup> >
                            {(assessment: SelfAssessmentLookup, assessmentMeta) => (
                              <NeoGrid.Row >
                                <NeoGrid.Column label="Title" display={assessmentMeta.selfAssessmentTitle} />
                                <NeoGrid.Column label="Description">
                                  <Tooltip
                                    id="tooltip-top"
                                    title={assessment.selfAssessmentDescription}
                                    placement="top"
                                  >
                                    <p>{assessment.selfAssessmentDescription} </p>
                                  </Tooltip>
                                </NeoGrid.Column>
                                <NeoGrid.ButtonColumn label="Actions" >
                                  <Neo.Button icon="eye" isOutline variant="success" text="Start Questionnaire" tooltip="Start" onClick={() => this.GoToSelfAssessment(assessment.selfAssessmentId)} />
                                </NeoGrid.ButtonColumn>
                              </NeoGrid.Row>
                            )}
                          </NeoGrid.Grid>
                        </Neo.Pager>
                      </CardBody>
                    </Card>
                  </div>
                ),
              },
              {
                tabButton: "Past Recommendations",
                tabIcon: "user fa-3x",
                tabContent: (
                  <div>
                    <Card>
                      <CardHeader icon>
                        <CardIcon color="success">
                          <i className={`icon fa fa-user fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Past Recommendations</h4>
                      </CardHeader>
                      <CardBody>
                        <Neo.Pager pageManager={this.viewModel.userAssessmentPagerMananger}
                          pageControlProps={{
                            firstText: "",
                            prevText: "Prev",
                            nextText: "Next",
                            lastText: "",
                            alignment: "center",
                            autoHide: false,
                            pageSizeLabel: "Show"
                          }}>
                          <NeoGrid.Grid<UserSelfAssessmentLookup> >
                            {(assessment: UserSelfAssessmentLookup, assessmentMeta) => (
                              <NeoGrid.Row >
                                <NeoGrid.Column label="Title" display={assessmentMeta.selfAssessmentTitle} />
                                <NeoGrid.Column label="Description">
                                  <Tooltip
                                    id="tooltip-top"
                                    title={assessment.selfAssessmentDescription}
                                    placement="top"
                                  >
                                    <p>{assessment.selfAssessmentDescription} </p>
                                  </Tooltip>
                                </NeoGrid.Column>
                                <NeoGrid.Column label="Completed on" display={assessmentMeta.completedDate} dateProps={{ formatString: "dd MMM yyyy HH:mm" }} />
                                <NeoGrid.ButtonColumn label="Actions" >
                                  <Neo.Button icon="eye" isOutline variant="success" text="View Recommendations" tooltip="Start" onClick={() => this.ReviewSelfAssessment(assessment.userSelfAssessmentId)} />
                                </NeoGrid.ButtonColumn>
                              </NeoGrid.Row>
                            )}
                          </NeoGrid.Grid>
                        </Neo.Pager>
                      </CardBody>
                    </Card>
                  </div>
                ),
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}