import { List, Model, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import OrganisationUsers from "../../../Organisations/Models/OrganisationUsers/OrganisationUsers";
import { AppService, Types } from "../../../App/Services/AppService";
import OrglevelUserCountLookup from "../../Models/Users/Lookups/OrglevelUserCountLookup";
import SchoolGroupLookup from "../../../Organisations/Models/Lookups/SchoolGroupLookup";
import UserProfileLookup from "../../Models/Users/Lookups/UserProfileLookup";
import OrgLevel from "../../Models/Users/OrgLevel";
import { UserOrgLevelMaintenanceComponentVM } from "../../Components/UserOrgLevelMaintenanceComponent";
import { UserDataImportModalVM } from "../../Components/UserDataImportModal";
import CircuitUsers from "../../../Organisations/Models/OrganisationUsers/CircuitUsers";
import ProvinceUsers from "../../../Organisations/Models/OrganisationUsers/ProvinceUsers";
import DistrictUsers from "../../../Organisations/Models/OrganisationUsers/DistrictUsers";
import { ImportHierarchyRoles } from "../../../Organisations/Models/Security/OrganisationRoles";
import TDPUser from "../../Models/Security/TDPUser";
import { UserMaintenance } from "../../../Authorisation/Models/Enums/UserMaintenanceRoles.enum";



@NeoModel
export default class ImportUserOrganisationVM extends Views.ViewModelBase {

    public OrganistationList = new List(OrganisationUsers);
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private organisationApiClient = AppService.get(Types.Organisations.ApiClients.OrganisationQueryApiClient),
        public userApiClient = AppService.get(Types.Identity.ApiClients.UserProfileApiClient),
        public userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        private userGroupQueryApiClient = AppService.get(Types.Authorisation.ApiClients.UserGroupQueryApiClient),
        public authorisationService = AppService.get(Types.Neo.Security.AuthorisationService)) {
        super(taskRunner);
        this.userProfile = new UserProfileLookup();
    }

    public currentUserCount: number = 0;
    public userProfile: UserProfileLookup;
    public groupedSchoolList = new List(SchoolGroupLookup);
    public showMoveProvince: boolean = false;
    public showMoveDistrict: boolean = false;
    public userDataImportModalVM: UserDataImportModalVM = new UserDataImportModalVM(this.taskRunner);
    public hoverOrganisationId: number = -1;
    public hoverProvinceId: number = -1;
    public hoverDistrictId: number = -1;
    public hoverCircuitId: number = -1;

    public userOrgLevelMaintenanceComponentVM: UserOrgLevelMaintenanceComponentVM = new UserOrgLevelMaintenanceComponentVM(this.taskRunner);
    public editOrgLevel: OrgLevel = new OrgLevel();
    public orgLevelName: string = "";
    public organisationRole: boolean = false;
    public provinceRole: boolean = false;
    public districtRole: boolean = false;
    public circuitRole: boolean = false;
    public completeRole: boolean = false;
    public currentUserOrgLevel: OrgLevel = new OrgLevel();

    public importRole: boolean = false;
    public editRole: boolean = false;
    public isAdmin: boolean = false;

    private authService = AppService.get(Types.Neo.Security.AuthenticationService);

    public async initialise() {

        const user = this.authService.user as TDPUser;
        var userResult = await this.userQueryApiClient.getRegisteredUser(user.guid);
        this.isAdmin = (await this.userGroupQueryApiClient.userIsAdminGroup(user.guid)).data;

        if (this.isAdmin === true) {
            this.completeRole = true;
            this.organisationRole = true;
            this.provinceRole = true;
            this.districtRole = true;
            this.circuitRole = true;
        }

        if (userResult.status === 200) {
            var userProfile = userResult.data;
            this.currentUserOrgLevel = userProfile.organisationlevel;
        }

        if (this.currentUserOrgLevel.organisationId === null) {
            this.completeRole = true;
            this.organisationRole = true;
            this.provinceRole = true;
            this.districtRole = true;
            this.circuitRole = true;
        }

        if (this.currentUserOrgLevel.organisationId !== null && this.currentUserOrgLevel.provinceId === null) {
            this.completeRole = false;
            this.organisationRole = true;
            this.provinceRole = true;
            this.districtRole = true;
            this.circuitRole = true;
        }

        if (this.currentUserOrgLevel.organisationId !== null && this.currentUserOrgLevel.provinceId !== null && this.currentUserOrgLevel.districtId === null) {
            this.completeRole = false;
            this.organisationRole = false;
            this.provinceRole = true;
            this.districtRole = true;
            this.circuitRole = true;
        }

        if (this.currentUserOrgLevel.organisationId !== null && this.currentUserOrgLevel.provinceId !== null && this.currentUserOrgLevel.districtId !== null && this.currentUserOrgLevel.circuitId === null) {
            this.completeRole = false;
            this.organisationRole = false;
            this.provinceRole = false;
            this.districtRole = true;
            this.circuitRole = true;
        }

        if (this.currentUserOrgLevel.organisationId !== null && this.currentUserOrgLevel.provinceId !== null && this.currentUserOrgLevel.districtId !== null && this.currentUserOrgLevel.circuitId !== null) {
            this.completeRole = false;
            this.organisationRole = false;
            this.provinceRole = false;
            this.districtRole = false;
            this.circuitRole = true;
        }

        if (this.isAdmin === true) {
            this.completeRole = true;
            this.organisationRole = true;
            this.provinceRole = true;
            this.districtRole = true;
            this.circuitRole = true;
        }

        this.importRole = this.authorisationService.hasRole(ImportHierarchyRoles.ImportUsers);
        this.editRole = this.authorisationService.hasRole(UserMaintenance.Update);
    }

    public async GetUserCountOrgLevel(OrgUserCountList: Array<Model.PartialPlainObject<OrglevelUserCountLookup>>, organisationId: number | null = null, provinceId: number | null = null, districtId: number | null = null, circuitId: number | null = null, schoolId: number | null = null, schoolManagementTeamId: number | null = null): Promise<OrglevelUserCountLookup> {
        var orgLevel = new OrgLevel();
        orgLevel.organisationId = organisationId;
        orgLevel.provinceId = provinceId;
        orgLevel.districtId = districtId;
        orgLevel.circuitId = circuitId;
        orgLevel.schoolId = schoolId;
        orgLevel.schoolManagementTeamId = schoolManagementTeamId;
        var filteredList = OrgUserCountList.filter(o => o.organisationId === organisationId && o.provinceId === provinceId && o.districtId === districtId && o.circuitId === circuitId && o.schoolId === schoolId && o.schoolManagementTeamId === schoolManagementTeamId);
        if (filteredList.length === 1) {
            var output = new OrglevelUserCountLookup();
            output.userCount = filteredList[0].userCount as (number);
            output.userCountIncldChildren = filteredList[0].userCountIncldChildren as number;
            return output;
        } else {
            // alert("filteredList.length exceeds 1. " + filteredList.length.toString());
        }
        output = new OrglevelUserCountLookup();
        return output;
    }

    public async LoadOrganisationList() {

        const results = await this.taskRunner.waitForAll({
            getOrganisationListResponse: this.organisationApiClient.getOrganisations(),
            getOrgLevelUserCountResponse: this.userQueryApiClient.getRegisteredUserCountAtAllOrgLevels(),
        });


        if (results.getOrganisationListResponse.data !== null && results.getOrgLevelUserCountResponse != null) {
            var orgLevelUserLookup = results.getOrgLevelUserCountResponse.data;
            await this.taskRunner.run(async () => {
                var provTotal: number = 0;
                var distTotal: number = 0;
                var circTotal: number = 0;
                var orgTotal: number = 0;

                this.OrganistationList = new List(OrganisationUsers);
                for (const element of results.getOrganisationListResponse.data) {
                    var orgUser = new OrganisationUsers();
                    orgUser.organisationId = element.organisationId;
                    orgUser.organisationName = element.organisationName;
                    orgUser.organtisationType = element.organtisationType;
                    orgUser.registrationNumber = element.registrationNumber;
                    var result2 = await this.GetUserCountOrgLevel(orgLevelUserLookup, element.organisationId, null, null, null, null, null);
                    orgUser.usersCount = result2.userCount;
                    orgUser.usersCountIncldChildren = result2.userCountIncldChildren;
                    orgTotal = orgUser.usersCount;
                    orgUser.provinces = new List(ProvinceUsers);

                    for (const prov of element.provinces) {
                        var provUser = new ProvinceUsers();
                        provUser.provinceCode = prov.provinceCode;
                        provUser.provinceName = prov.provinceName;
                        provUser.provinceId = prov.provinceId;
                        provUser.districts = new List(DistrictUsers);
                        result2 = await this.GetUserCountOrgLevel(orgLevelUserLookup, element.organisationId, prov.provinceId, null, null, null, null);
                        provUser.usersCount = result2.userCount;
                        provUser.usersCountIncldChildren = result2.userCountIncldChildren;
                        provTotal = provUser.usersCount;
                        provUser.organisationId = prov.organisationId;

                        for (const dist of prov.districts) {
                            var distUser = new DistrictUsers();
                            distUser.districtId = dist.districtId;
                            distUser.districtName = dist.districtName;
                            distUser.districtDetails = dist.districtDetails
                            distUser.provinceId = provUser.provinceId;
                            result2 = await this.GetUserCountOrgLevel(orgLevelUserLookup, element.organisationId, prov.provinceId, dist.districtId, null, null, null);
                            distUser.usersCount = result2.userCount;
                            distUser.usersCountIncldChildren = result2.userCountIncldChildren;
                            distTotal = distUser.usersCount;
                            distUser.organisationId = provUser.organisationId;
                            for (const circ1 of dist.circuits) {
                                var circUser = new CircuitUsers();
                                circUser.districtId = dist.districtId;
                                circUser.circuitId = circ1.circuitId;
                                circUser.circuitName = circ1.circuitName;
                                circUser.description = circ1.description;
                                result2 = await this.GetUserCountOrgLevel(orgLevelUserLookup, element.organisationId, prov.provinceId, dist.districtId, circ1.circuitId, null, null);
                                circUser.usersCount = result2.userCount;
                                circUser.usersCountIncldChildren = result2.userCountIncldChildren;
                                circUser.organisationId = distUser.organisationId;
                                circTotal = circUser.usersCount;
                                if (circTotal !== 0) {
                                    distTotal += circTotal;
                                }
                                circUser.usersCountIncldChildren = 0;
                                distUser.circuits.push(circUser);
                            }

                            if (distTotal !== 0) {
                                provTotal += distTotal;
                            }
                            distUser.usersCountIncldChildren = distTotal - distUser.usersCount;
                            provUser.districts.push(distUser);
                        }

                        if (provTotal !== 0) {
                            orgTotal += provTotal;
                        }
                        provUser.usersCountIncldChildren = provTotal - provUser.usersCount;
                        orgUser.provinces.push(provUser);
                    }

                    orgUser.usersCountIncldChildren = orgTotal - orgUser.usersCount;
                    this.OrganistationList.push(orgUser);
                }
            });
        }
    }

    // IMPORTS

    public async importUsersOrganisation(organisation: OrganisationUsers) {
        this.userDataImportModalVM.ClearOrgLevels();
        this.userDataImportModalVM.orgLevel.organisationId = organisation.organisationId;
        this.userDataImportModalVM.orgLevelName = "Organisation '" + organisation.organisationName + "'";
        this.ShowMe();
    }

    public async importUsersProvince(organisation: OrganisationUsers, province: ProvinceUsers) {
        this.userDataImportModalVM.ClearOrgLevels();
        this.userDataImportModalVM.orgLevel.organisationId = organisation.organisationId;
        this.userDataImportModalVM.orgLevel.provinceId = province.provinceId;
        this.userDataImportModalVM.orgLevelName = "Province '" + province.provinceName + "'";
        this.ShowMe();
    }

    public async importUsersDistrict(organisation: OrganisationUsers, province: ProvinceUsers, district: DistrictUsers) {
        this.userDataImportModalVM.ClearOrgLevels();
        this.userDataImportModalVM.orgLevel.organisationId = organisation.organisationId;
        this.userDataImportModalVM.orgLevel.provinceId = province.provinceId;
        this.userDataImportModalVM.orgLevel.districtId = district.districtId;
        this.userDataImportModalVM.orgLevelName = "District '" + district.districtName + "'";
        this.ShowMe();
    }

    public async importUsersCircuit(organisation: OrganisationUsers, province: ProvinceUsers, district: DistrictUsers, circuit: CircuitUsers) {
        this.userDataImportModalVM.ClearOrgLevels();
        this.userDataImportModalVM.orgLevel.organisationId = organisation.organisationId;
        this.userDataImportModalVM.orgLevel.provinceId = province.provinceId;
        this.userDataImportModalVM.orgLevel.districtId = district.districtId;
        this.userDataImportModalVM.orgLevel.circuitId = circuit.circuitId;
        this.userDataImportModalVM.orgLevelName = "Circuit '" + circuit.circuitName + "'";
        this.ShowMe();
    }

    // EDITS

    // private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    public async ShowUserImportModal() {

        this.userDataImportModalVM.show()
    }

    private ShowMe() {
        this.userDataImportModalVM.show();
    }

    public async CloseUserImportModal() {
        this.userDataImportModalVM.showUserImportModal = false;
    }
}