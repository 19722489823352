import { AppServices } from '@singularsystems/neo-core';
import ForumQueryApiClient from './ApiClients/Forums/Query/ForumQueryApiClient';
import PostQueryApiClient from './ApiClients/Forums/Query/PostQueryApiClient';
import TopicQueryApiClient from './ApiClients/Forums/Query/TopicQueryApiClient';
import PostUpdateApiClient from './ApiClients/Forums/Update/PostUpdateApiClient';
import TopicUpdateApiClient from './ApiClients/Forums/Update/TopicUpdateApiClient';
import ForumIntegrationQueryApiClient from './ApiClients/Integrations/ForumIntegrationQueryApiClient';
import ForumIntegrationUpdateApiClient from './ApiClients/Integrations/ForumIntegrationUpdateApiClient';
import ForumUpdateApiClient from './ApiClients/Forums/Update/ForumUpdateApiClient';

export const ForumExportedTypes = {
    ApiClients: {
        // Add                                                                                                   
        ForumIntegrationQueryApiClient: new AppServices.ServiceIdentifier<ForumIntegrationQueryApiClient>("Forum.ApiClient.ForumIntegrationQueryApiClient"),
        TopicQueryApiClient: new AppServices.ServiceIdentifier<TopicQueryApiClient>("forum.ApiClient.TopicQueryApiClient"),
        ForumQueryApiClient: new AppServices.ServiceIdentifier<ForumQueryApiClient>("forum.ApiClient.ForumQueryApiClient"),
        PostQueryApiClient: new AppServices.ServiceIdentifier<PostQueryApiClient>("forum.ApiClient.PostQueryApiClient"),
        ForumUpdateApiClient: new AppServices.ServiceIdentifier<ForumUpdateApiClient>("forum.ApiClient.ForumUpdateApiClient"),

        // Forum Update
        PostUpdateApiClient: new AppServices.ServiceIdentifier<PostUpdateApiClient>("forum.ApiClient.PostUpdateApiClient"),
        TopicUpdateApiClient: new AppServices.ServiceIdentifier<TopicUpdateApiClient>("forum.ApiClient.TopicUpdateApiClient"),
        ForumIntegrationUpdateApiClient: new AppServices.ServiceIdentifier<ForumIntegrationUpdateApiClient>("forum.ApiClient.ForumIntegrationUpdateApiClient"),

    },
    Services: {
        // Add Services here
    }
}