import { Data } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../ForumTypes';

export interface IForumIntegrationUpdateApiClient {

    /** 
     * Creates a Public Conversation as an activity (Learning Content) belonging to a learning object. Check to see if one exists already first.
     * @param learningObjectId The Learning object to link the forum to.
     * @returns The created forum.
     */
    createConversationForLearningActivityAsync(learningObjectId: number, learningModuleId: number): AxiosPromise<any>;

    /** 
     * Creates a private Conversation about a learning pathway. If the pathway has a parent, the pathway must be linked to the parent instead.
     * In both cases, check to see if one exists.
     * @param learningPathwayId The Learning Module to link the forum to.
     * @returns The created forum.
     */
    createConversationForLearningPathwayAsync(learningPathwayId: number): AxiosPromise<any>;

    /** 
     * Creates a Global Conversation about a learning module. Check to see if one exists already first.
     * @param learningModuleId The Learning Module to link the forum to.
     * @returns The created forum.
     */
    createConversationForLearningModule(learningModuleId: number): AxiosPromise<any>;

    // Client only properties / methods
}

@injectable()
export default class ForumIntegrationUpdateApiClient extends Data.ApiClientBase implements IForumIntegrationUpdateApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.ForumApi.ApiPath}/ForumIntegrationUpdate`);
    }

    public createConversationForLearningActivityAsync(learningObjectId: number, learningModuleId: number): AxiosPromise<any> {
        return this.axios.get(`${this.apiPath}/CreateConversationForLearningActivity/${learningObjectId}/${learningModuleId}`);
    }

    public createConversationForLearningPathwayAsync(learningPathwayId: number): AxiosPromise<any> {
        return this.axios.get(`${this.apiPath}/CreateConversationForLearningPathway/${learningPathwayId}`);
    }

    public createConversationForLearningModule(learningModuleId: number): AxiosPromise<any> {
        return this.axios.get(`${this.apiPath}/CreateConversationForLearningModule/${learningModuleId}`);
    }

    // Client only properties / methods
}