import { NeoModel } from '@singularsystems/neo-core';
import { ReportingServiceBase } from '@singularsystems/neo-reporting';
import { injectable } from 'inversify';
import CPTDPointsReport from '../Models/Reports/CPTDPoints/CPTDPointsReport';
// import FinalQuizReport from '../Models/Reports/FinalQuizResult/FinalQuizResultReport';
import LoginsAndRegistrationsReport from '../Models/Reports/LoginsAndRegistrations/LoginsAndRegistrationsReport';
import LoginsPerTeacherReport from '../Models/Reports/LoginsPerTeacher/LoginsPerTeacherReport';
import ModulesCompletedReport from '../Models/Reports/ModulesCompletedPerTeacher/ModulesCompletedReport';
import ModulesCreatedReport from '../Models/Reports/ModulesCreated/ModulesCreatedReport';
import ModulesSelectedReport from '../Models/Reports/ModulesSelectedPerTeacher/ModulesSelectedReport';
// import QuizzesCompletedReport from '../Models/Reports/QuestionnairesCompleted/QuizzesCompletedReport';
import SelfAssessmentsReport from '../Models/Reports/SelfAssessments/SelfAssessmentResultsReport';
import TeacherActivityEngagementReport from '../Models/Reports/TeacherActivityEngagement/TeacherActivityEngagementReport';
import PLCCreatedPerTeacherReport from '../Models/Reports/PLCCreated/PLCCreatedPerTeacherReport';
import BadUserProfilesReport from '../Models/Reports/BadUserProfiles/BadUserProfilesReport';
import ModulesCompletedSummaryReport from '../Models/Reports/ModulesCompletedSummaryPerTeacher/ModulesCompletedSummaryReport';


@injectable()
@NeoModel
export default class ReportingService extends ReportingServiceBase {

    public getReports() {
        return [
            //// Add your reports here
            CPTDPointsReport,
            // FinalQuizReport,
            LoginsAndRegistrationsReport,
            LoginsPerTeacherReport,
            ModulesCompletedReport,
            ModulesCompletedSummaryReport,
            ModulesCreatedReport,
            ModulesSelectedReport,
            // QuizzesCompletedReport,
            BadUserProfilesReport,
            SelfAssessmentsReport,
            TeacherActivityEngagementReport,
            PLCCreatedPerTeacherReport,
        ];
    }
}