import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import ModuleConsumptionVM from './ModuleConsumptionVM';
import { observer } from 'mobx-react';
import ModuleObjectsConsumptionLookup from '../../Models/ContentCosumption/ModuleObjectsConsumptionLookup';
import { GetContentImage, truncateString } from '../../../App/HelperClasses/GlobalHelpers';
import { LearningObjectType } from '../../Models/LearningObjects/LearningObjectType.enum';
import RichTextEditor from '../../Components/RichTextEditor';
import ContentModal from '../../Components/ContentModal';
import { QuestionType } from '../../Models/Questions/QuestionType.enum';
import OptionLookup from '../../Models/Lookups/OptionLookup';
import Home from '../../../App/Views/Security/Home';
import GridContainer from '../../../Template/components/Grid/GridContainer';
import GridItem from '../../../Template/components/Grid/GridItem';
import Card from '../../../Template/components/Card/Card';
import CardHeader from '../../../Template/components/Card/CardHeader';
import CardIcon from '../../../Template/components/Card/CardIcon';
import CardBody from '../../../Template/components/Card/CardBody';
import CardFooter from '../../../Template/components/Card/CardFooter';
import ImageCard from '../../../App/Components/ImageCard';
import '../../../Forum/Styles/ForumDrawerStyles.scss';
import { LearningContentMediaType } from '../../Models/LearningObjects/LearningContent/LearningContentMediaType.enum';
import FabButton from '../../../Template/components/CustomButtons/FabButton';
import ForumDrawer from '../../../Forum/Components/ForumComponents/ForumDrawer';
import ConversationComponent from '../../../Forum/Components/ForumComponents/ConversationComponent';
import BadgeAwardModal from '../../Components/BadgeAwardModal';
import Confetti from 'react-confetti';
import GlobalConversation from '../../../Forum/Components/ForumComponents/Global/GlobalConversation';

class ModuleConsumptionViewViewParams {
  public moduleId = { required: true, isQuery: true };
  public preview = { required: true, isQuery: true };
  public moduleOnly = { required: false, isQuery: true };
}

@observer
export default class ModuleConsumptionView extends Views.ViewBase<ModuleConsumptionVM, ModuleConsumptionViewViewParams> {

  constructor(props: unknown) {
    super("Module Consumption", ModuleConsumptionVM, props);
  }

  public static params = new ModuleConsumptionViewViewParams();

  protected async viewParamsUpdated() {
    this.viewModel.moduleId = this.viewParams.moduleId.value as number;
    await this.taskRunner.run(async () => this.viewModel.GetModule(this.viewModel.moduleId, this.viewParams.moduleOnly.value as string));
    this.viewModel.isPreview = this.viewParams.preview.value === "1";
  }

  public GoToDashboard = () => {
    this.navigation.navigateToView(Home);
  }

  public nextBadge = async () => {
    const response = await this.viewModel.showNextBadge();
    if (response === 0 && this.viewModel.allDone) {
      this.viewModel.showBadgeAward = false;
      this.viewModel.showFinalQuizPassModal = true;
    }
  }

  public async selectObject(lo: ModuleObjectsConsumptionLookup) {
    await this.viewModel.SaveLastLearningObject(lo);
    await this.viewModel.SelectObject(lo);
    window.scrollTo(0, 0);
  }

  public async nextActivity() {
    await this.viewModel.saveUserModule();
    window.scrollTo(0, 0);
  }

  public render() {
    const viewModel = this.viewModel;
    return (
      <div>
        {/* Main Module Info */}
        <Confetti hidden={!viewModel.showBadgeAward} />
        <div hidden={!viewModel.hideInfoObjectInfo || !viewModel.hideQuizObjectInfo}>
          <Card>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`icon fa fa-chalkboard fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">{this.viewModel.module.moduleTitle}</h4>
              <GridContainer>
                <GridItem md={8}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Neo.Button onClick={() => { this.GoToDashboard() }} icon="backward" text="Back to Modules" tooltip="Go back to the dashboard" variant="info" isOutline />
                </GridItem>
                <GridItem md={4}>
                  <Neo.Button onClick={() => this.viewModel.downloadPdf(this.viewModel.module.learningModuleId)}
                    icon="download" text="Download Text Only" tooltip="Download a PDF version of this module."
                    variant="primary" isOutline />
                  <Neo.Button onClick={() => this.viewModel.downloadZip(this.viewModel.module.learningModuleId)}
                    icon="downloadZip"
                    text="Download All Files"
                    tooltip="Download a ZIP version of this module with all content inside." variant="primary" isOutline />
                   <Neo.Button disabled style={{ float: "right" }} variant="danger" hidden={!this.viewModel.module.isDeactivated ? true : false} text="This Module is Deactivated"
                      tooltip="This module is deactivated and there may be a newer version of this module available." isOutline /> 
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <h4 className="module-tiles">Learning Objective</h4>
              <p className="module-description">{this.viewModel.module.learningObjective}</p>
              <Card>
                <CardHeader icon>
                  <CardIcon color="info">
                    <i className={`icon fa fa-tasks fa-2x`}></i>
                  </CardIcon>
                  <h4 className="Card-icon-header-text">Activities</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    {this.viewModel.module === undefined ||
                      this.viewModel.module.learningObjectList.map((lo: ModuleObjectsConsumptionLookup, index) => (
                        <GridItem md={3} key={lo.learningObjectId}>
                          <div>
                            <Card>
                              <CardHeader color={lo.learningObjectType === LearningObjectType.Information ? "success" : lo.learningObjectType === LearningObjectType.Quiz ? "warning" : "danger"} plain >
                                <h4 className="card-title-white-small">({lo.order})  {lo.title}</h4>
                              </CardHeader>
                              <CardBody>
                                <p className="module-description">{truncateString(lo.purpose, 150)}</p>
                              </CardBody>
                              <CardFooter>
                                <div hidden={this.viewModel.isPreview} style={{ float: 'left' }}>
                                  <p>{this.viewModel.GetStatusText(lo)}</p>
                                </div>
                                <div style={{ float: 'right' }} hidden={this.viewModel.isPreview}>
                                  <Neo.Button disabled={this.viewModel.AllowNextV2(lo)}
                                    variant={lo.learningObjectType === LearningObjectType.Information ? "success" : lo.learningObjectType === LearningObjectType.Quiz ? "warning" : "danger"}
                                    text={lo.learningObjectType === LearningObjectType.Information ? "View Activity" : lo.learningObjectType === LearningObjectType.Quiz ? "Start Quiz" : "Final Quiz"}
                                    onClick={() => { this.selectObject(lo) }} isOutline />
                                </div>
                              </CardFooter>
                            </Card>
                          </div>
                        </GridItem>
                      ))
                    }
                  </GridContainer>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
          <FabButton color="primary" tooltip="Conversations" icon="comments" onClick={() => this.viewModel.openForumDrawer()} />
        </div>

        {/* Info Object */}
        <div hidden={viewModel.hideInfoObjectInfo}>
          <GridContainer>
            <GridItem md={12}>
              <Card>
                <CardHeader icon >
                  <CardIcon color="success">
                    <i className={`icon fa fa-book fa-2x`}></i>
                  </CardIcon>
                  <h4 className="Card-icon-header-text">{viewModel.infoLearningObject.title}</h4>
                  <GridContainer>
                    <GridItem md={9} sm={8} xs={8}>
                      <Neo.Button text={this.viewModel.module.moduleTitle} icon="backward" onClick={() => this.viewModel.backToModule()} />
                    </GridItem>
                    <GridItem xs={4} md={3} sm={4}>
                      <Neo.Button text="Prev Activity" variant="warning" isOutline icon="backward" onClick={() => this.viewModel.GoToPrevActivity(false)} />
                      <Neo.Button text="Next Activity" variant="success" isOutline icon="forward" onClick={() => this.nextActivity()} />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <h4 className="module-tiles">Activity Purpose</h4>
                  <p className="module-description">{this.viewModel.infoLearningObject.purpose}</p>
                  <br />
                  <h4 className="module-tiles">Activity Text</h4>
                  {
                    !this.viewModel.infoLearningObject.objectText.length || <RichTextEditor viewModel={this.viewModel.quizRichTextVm} bind={this.viewModel.infoLearningObject.meta.objectText} labelText="" readOnly />
                  }
                  <Card hidden={viewModel.infoLearningObject.contentList.length === 0}>
                    <CardHeader icon>
                      <CardIcon color="info">
                        <i className="fa fa-images fa-2x" />
                      </CardIcon>
                      <h4 className="Card-icon-header-text">Media</h4>
                    </CardHeader>
                    <CardBody >
                      <GridContainer>
                        {
                          viewModel.infoLearningObject.contentList.map((content, index) => (
                            <GridItem xs={12} sm={12} md={2} lg={2} key={content.learningContentId}>
                              <ImageCard image={GetContentImage(content.mediaType as number)} className="image-card-learning-object" imageClassName="media-image">
                                <CardBody>
                                  <div className="card-hover-under">
                                  </div>
                                  <p className="module-description">{content.title}</p>
                                </CardBody>
                                <CardFooter>
                                  <Neo.Button icon="eye" variant="success" isOutline tooltip={this.viewModel.GetButtonText(content)} text={this.viewModel.GetButtonText(content)} onClick={() => this.viewModel.ViewContent(content)} />
                                  <Neo.Button hidden={content.mediaType === LearningContentMediaType.Conversation
                                    || content.mediaType === LearningContentMediaType.Link
                                    || content.mediaType === LearningContentMediaType.YouTube}
                                    isOutline
                                    tooltip="Download"
                                    variant="warning"
                                    icon="download" onClick={() => this.viewModel.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, true)} />
                                </CardFooter>
                              </ImageCard>
                            </GridItem >
                          ))
                        }
                      </GridContainer>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <FabButton color="primary" tooltip="Complete Activity" icon="check" onClick={() => this.nextActivity()} />
        </div>

        <div hidden={viewModel.hideQuizObjectInfo}>
          <Card>
            <CardHeader icon>
              <CardIcon color={this.viewModel.quizLearningObject.learningObjectType === LearningObjectType.Quiz ? "warning" : "danger"}>
                <i className="fa fa-question fa-2x" />
              </CardIcon>
              <h4 className="Card-icon-header-text">{this.viewModel.quizLearningObject.title}</h4>
              <GridContainer>
                <GridItem md={9} sm={8} xs={8}>
                  <Neo.Button text="Back to module" icon="clipboard" onClick={() => this.viewModel.backToModule()} />
                </GridItem>
                <GridItem xs={4} md={3} sm={4}>
                  <Neo.Button text="Prev Activity" variant="warning" isOutline icon="backward" onClick={() => this.viewModel.GoToPrevActivity(true)} />
                  <Neo.Button hidden={this.viewModel.quizLearningObject.learningObjectType === LearningObjectType.FinalQuiz} text="Next Activity" variant="success" isOutline icon="forward"
                    onClick={() => {
                      this.viewModel.completeQuizModal = true;
                      this.viewModel.GoToNextFormQuiz = true;
                    }} />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <h4 className="module-tiles">Activity Purpose</h4>
              <p className="module-description">{this.viewModel.quizLearningObject.purpose}</p>
              <br />
              <h4 className="module-tiles">Activity Text</h4>
              {
                !this.viewModel.quizLearningObject.text.length || <RichTextEditor viewModel={this.viewModel.quizRichTextVm} bind={this.viewModel.quizLearningObject.meta.text} labelText="" readOnly />
              }
              {!this.viewModel.quizLearningObject.questions.length ||
                <Card>
                  <CardHeader plain color="info">
                    <h4 className="card-title-white">Question</h4>
                  </CardHeader>

                  <CardBody>
                    <div style={{ float: 'right' }}>
                      <GridContainer>
                        <div hidden={this.viewModel.currentIndex === 0}>
                          <GridItem md={2}>
                            <Neo.Button text="Previous Question" icon="backward" variant="warning" isOutline onClick={() => { this.viewModel.currentIndex -= 1 }} />
                          </GridItem>
                        </div>
                        <div hidden={this.viewModel.currentIndex + 1 === this.viewModel.questionListCount}>
                          <GridItem md={2}>
                            <Neo.Button text="Next Question" icon="forward" isOutline variant="success" onClick={() => { this.viewModel.currentIndex += 1 }} />
                          </GridItem>
                        </div>
                        <div hidden={this.viewModel.currentIndex + 1 !== this.viewModel.questionListCount}>
                          <GridItem md={2}>
                            <Neo.Button text="Complete Quiz" icon="save" isOutline variant="success" onClick={() => { this.viewModel.completeQuizModal = true }} />
                          </GridItem>
                        </div>
                      </GridContainer>
                    </div>
                    <h4 className="module-tiles">Question {this.viewModel.currentIndex + 1} of {this.viewModel.questionListCount}</h4>
                    <div hidden={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.MultipleSelect}>
                      {
                        this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.MultipleSelect || <RichTextEditor labelText="Question:" viewModel={this.viewModel.multiSelectRichTextVm} bind={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].meta.questionText} htmlContent={this.viewModel.meta.curQuestionHtml} readOnly key="questions" />
                      }
                      <h4 className="module-tiles">Multiple Select</h4>
                      <NeoGrid.Grid items={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList}>
                        {(option, optionMeta) => (
                          <NeoGrid.Row>
                            <NeoGrid.Column display={optionMeta.option} />
                            <NeoGrid.Column label="Select Options" bind={optionMeta.correctAnswerInd} />
                          </NeoGrid.Row>
                        )}
                      </NeoGrid.Grid>
                    </div>
                    <div hidden={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.MultipleChoice}>
                      {
                        this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.MultipleChoice || <RichTextEditor labelText="Question:" viewModel={this.viewModel.multiChoiceRichTextVm} bind={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].meta.questionText} readOnly key="questions" />
                      }
                      <h4 className="module-tiles">Multiple Choice</h4>

                      <NeoGrid.Grid items={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList}>
                        {(option, optionMeta) => (
                          <NeoGrid.Row>
                            <NeoGrid.Column display={optionMeta.option} />
                            <NeoGrid.Column label="Choose and answer" bind={optionMeta.correctAnswerInd} onChange={() => {
                              this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                element.correctAnswerInd = false;
                              });
                              optionMeta.correctAnswerInd.value = !optionMeta.correctAnswerInd.value
                            }} />
                          </NeoGrid.Row>
                        )}
                      </NeoGrid.Grid>
                    </div>
                    <div hidden={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.YesNo}>
                      {
                        this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.YesNo || <RichTextEditor labelText="Question:" viewModel={this.viewModel.yesNoRichTextVm} bind={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].meta.questionText} readOnly key="questions" />
                      }
                      <h4 className="module-tiles">Yes or No Questions</h4>
                      <br />
                      <div className="row">
                        {
                          this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.map((option: OptionLookup) => (
                            <div className="col-md-1 " key={option.option}>

                              <Neo.Button isOutline hidden={option.option !== "Yes"} size="lg" text="Yes" variant={option.correctAnswerInd ? "success" : "warning"} onClick={() => {
                                this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                  element.correctAnswerInd = false;
                                });
                                option.correctAnswerInd = !option.correctAnswerInd;
                              }} />
                              <Neo.Button hidden={option.option !== "No"} isOutline size="lg" text="No" variant={option.correctAnswerInd ? "success" : "warning"} onClick={() => {
                                this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                  element.correctAnswerInd = false;
                                });
                                option.correctAnswerInd = !option.correctAnswerInd;
                              }} />

                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div hidden={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.TrueFalse}>
                      {
                        this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.TrueFalse ||
                        <RichTextEditor labelText="Question:" viewModel={this.viewModel.trueFalseRichTextVm} bind={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].meta.questionText} readOnly key="questions" />
                      }
                      <h4 className="module-tiles">True or false Questions</h4>
                      <div className="row">
                        {
                          this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.map((option: OptionLookup) => (
                            <div className="col-md-1" key={option.option}>

                              <Neo.Button hidden={option.option !== "True"} isOutline size="lg" text="True" variant={option.correctAnswerInd ? "success" : "warning"} onClick={() => {
                                this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                  element.correctAnswerInd = false;
                                });
                                option.correctAnswerInd = !option.correctAnswerInd;
                              }} />
                              <Neo.Button hidden={option.option !== "False"} isOutline size="lg" text="False" variant={option.correctAnswerInd ? "success" : "warning"} onClick={() => {
                                this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                  element.correctAnswerInd = false;
                                });
                                option.correctAnswerInd = !option.correctAnswerInd;
                              }} />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </CardBody>
                </Card>
              }
              {!viewModel.showQuestionContent ||
                <Card hidden={viewModel.quizLearningObject.questions[this.viewModel.currentIndex].contentList.toJSArray({ includeClean: true }).length === 0}>
                  <CardHeader icon>

                    <CardIcon color="info">
                      <i className="fa fa-images fa-2x" />
                    </CardIcon>
                    <h4 className="Card-icon-header-text">Question Content</h4>
                  </CardHeader>
                  <CardBody >
                    <GridContainer>
                      {
                        viewModel.quizLearningObject.questions[this.viewModel.currentIndex].contentList.map((content, index) => (
                          <GridItem xs={12} sm={12} md={2} lg={2} key={content.learningContentId}>
                            <ImageCard image={GetContentImage(content.mediaType as number)} className="image-card-learning-object" imageClassName="media-image">
                              <CardBody>
                                <div className="card-hover-under">
                                </div>
                                <p className="module-description">{content.title}</p>
                              </CardBody>
                              <CardFooter>
                                <Neo.Button icon="eye" variant="success" isOutline text={this.viewModel.GetButtonText(content)} onClick={() => this.viewModel.ViewContent(content)} />
                                <Neo.Button hidden={content.mediaType === LearningContentMediaType.Conversation
                                  || content.mediaType === LearningContentMediaType.Link
                                  || content.mediaType === LearningContentMediaType.YouTube}
                                  isOutline variant="warning"
                                  icon="download"
                                  onClick={() => this.viewModel.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, true)} />
                              </CardFooter>
                            </ImageCard>
                          </GridItem >
                        ))
                      }
                    </GridContainer>
                  </CardBody>
                </Card>
              }
              <br />
              <Card hidden={!viewModel.showQuizQuestionContent}>
                <CardHeader icon>
                  <CardIcon color="info">
                    <i className="fa fa-images fa-2x" />
                  </CardIcon>
                  <h4 className="Card-icon-header-text">Quiz Content</h4>
                </CardHeader>
                <CardBody >
                  <GridContainer>
                    {
                      viewModel.quizLearningObject.contentList.map((content, index) => (
                        <GridItem xs={12} sm={12} md={2} lg={2} key={content.learningContentId}>
                          <ImageCard image={GetContentImage(content.mediaType as number)} className="image-card-learning-object" imageClassName="media-image">
                            <CardBody>
                              <div className="card-hover-under">
                              </div>
                              <p className="module-description">{content.title}</p>
                            </CardBody>
                            <CardFooter>
                              <Neo.Button tooltip={this.viewModel.GetButtonText(content)} icon="eye" variant="success" isOutline text={this.viewModel.GetButtonText(content)} onClick={() => this.viewModel.ViewContent(content)} />
                              <Neo.Button hidden={content.mediaType === LearningContentMediaType.Conversation || content.mediaType === LearningContentMediaType.Link || content.mediaType === LearningContentMediaType.YouTube} isOutline tooltip="Download" variant="warning" icon="download" onClick={() => this.viewModel.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, true)} />
                            </CardFooter>
                          </ImageCard>
                        </GridItem >
                      ))
                    }
                  </GridContainer>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
          <FabButton hidden={this.viewModel.currentIndex + 1 !== this.viewModel.questionListCount} color="primary" tooltip="Complete Quiz" icon="check" onClick={() => this.viewModel.completeQuizModal = true} />
        </div>

        <ContentModal title="Content" show={this.viewModel.showContent} blobUrl={this.viewModel.contentBlobUrl} onClose={(show) => { this.viewModel.showContent = false }} content={this.viewModel.content} taskRunner={this.viewModel.taskRunner} alternateUrl={this.viewModel.alternateUrl} />
        {
          !this.viewModel.showQuizContent || <ContentModal title="Content" show={this.viewModel.showQuizContent} blobUrl={this.viewModel.contentBlobUrl} onClose={(show) => { this.viewModel.showQuizContent = false }} content={this.viewModel.quizLearningObject.contentList[0]} taskRunner={this.viewModel.taskRunner} alternateUrl={this.viewModel.alternateUrl} />
        }

        {!this.viewModel.showBadgeAward ||
          <BadgeAwardModal badgeAwardMessage={viewModel.badgeAwarded.badgeAwardMessage} badgeAwardDescription={viewModel.badgeAwarded.badgeDescription}
            badgeTitle={viewModel.badgeAwarded.badgeTitle} imageSrc={viewModel.badgeAwarded.blobUrl} onClose={() => { this.nextBadge() }} showBadgeAward={viewModel.showBadgeAward} />
        }

        <Neo.Modal title="Finish Quiz"
          show={this.viewModel.completeQuizModal}
          onClose={() => this.viewModel.completeQuizModal = false}
          acceptButton={{
            text: "Submit Quiz", variant: "info", icon: "save", isOutline: true,
            onClick: () => this.viewModel.CompleteQuiz()
          }}>
          <div>
            <p>Confirm quiz submission</p>
            <span>Complete quiz and calculate your mark.</span>
          </div>
        </Neo.Modal>

        <Neo.Modal size="lg" title="Well Done Quiz Completed!" show={this.viewModel.showPassModal} onClose={() => this.viewModel.showPassModal = false}
          acceptButton={{
            text: "Next Activity", variant: "info", icon: "forward", isOutline: true,
            onClick: () => this.viewModel.saveUserModule(true)
          }}>
          <div>
            <span>You scored {Math.round(this.viewModel.quizMark)}%</span>
            <div hidden={this.viewModel.moduleScoreLookup.objectsToReview.length === 0}>
              <span>You answered wrong on the below questions:</span>
              <NeoGrid.Grid items={this.viewModel.moduleScoreLookup.objectsToReview}>
                {(question, questionMeta) => (
                  <NeoGrid.Row>
                    <NeoGrid.Column label="Wrong Question" display={questionMeta.title} />
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </div>
          </div>
        </Neo.Modal>

        <Neo.Modal title="Well done final quiz passed!" show={this.viewModel.showFinalQuizPassModal} onClose={() => { this.viewModel.showFinalQuizPassModal = false }}
          acceptButton={{
            text: "Go to dashboard", variant: "info", icon: "backward", isOutline: true,
            onClick: () => this.GoToDashboard()
          }}>
          <div>
            <p>Congratulations you have passed the Final Quiz! CPTD points have been allocated to your profile accordingly.</p>
            <span>You scored {this.viewModel.quizMark}%</span><br />
          </div>
        </Neo.Modal>

        <Neo.Modal size="xl" title="Quiz Failed." show={this.viewModel.showFailModal} onClose={() => this.viewModel.showFailModal = false} >
          <div>
            <span>You scored {Math.round(this.viewModel.quizMark)}%, required pass mark: 100% </span>
            <span>Please review the following Questions and Activities:</span><br />
            <div>
              <NeoGrid.Grid items={this.viewModel.moduleScoreLookup.objectsToReview}>
                {(object, objectMeta) => (
                  <NeoGrid.Row>
                    <NeoGrid.Column label="Wrong Question" display={objectMeta.title} />
                    <NeoGrid.Column label="Activity to review" display={objectMeta.objectText} />
                    <NeoGrid.ButtonColumn>
                      <Neo.Button text="Go to Activity" isOutline icon="eye" onClick={() => this.viewModel.SelectObject(this.viewModel.module.learningObjectList.find(c => c.learningObjectId === object.learningObjectId)!)} />
                    </NeoGrid.ButtonColumn>
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </div>
          </div>
        </Neo.Modal>
        <ForumDrawer show={this.viewModel.showForumModal} onClose={() => this.viewModel.closeForumModal()} isMaximised={this.viewModel.isMaximised} maximiseWindow={() => this.viewModel.isMaximised = !this.viewModel.isMaximised}>
          <GlobalConversation learningModuleId={this.viewModel.moduleId} forumId={this.viewModel.moduleForumId} />
        </ForumDrawer>

        <ForumDrawer show={this.viewModel.showActivityConversation} onClose={() => this.viewModel.CloseForumDrawer()} isMaximised={this.viewModel.isMaximised} maximiseWindow={() => this.viewModel.isMaximised = !this.viewModel.isMaximised}>
          {/* <GlobalConversation learningModuleId={this.viewModel.moduleId} /> */}
          <ConversationComponent topicId={this.viewModel.activityForumId} title={"Learning Activity"} moduleName={this.viewModel.moduleName} show={this.viewModel.meta.showTopicConversation} fromLearningActivity={true} />
        </ForumDrawer>

      </div>
    );
  }
}