import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from '../../LearningTypes';
import { AxiosPromise } from 'axios';

export interface IDownloadContentApiClient {

    /** 
     * GetDocumentFromLearningModuleAsync
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @param learningModuleId learningModuleId
     * @returns bool
     */
    getDocumentFromLearningModuleAsyncUrl(learningModuleId: number): string;

    /** 
     * GetZipFromLearningModuleAsync
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @param learningModuleId learningModuleId
     * @returns bool
     */    
    getZipFromLearningModuleAsyncUrl(learningModuleId: number): AxiosPromise<Blob>;

    // Client only properties / methods
}

@injectable()
export default class DownloadContentApiClient extends Data.ApiClientBase implements IDownloadContentApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/DownloadContent`);
    }

    public getDocumentFromLearningModuleAsyncUrl(learningModuleId: number): string {
        return `${this.apiPath}/GetDocumentFromLearningModule/${learningModuleId}`;
    }

    public getZipFromLearningModuleAsyncUrl(learningModuleId: number): AxiosPromise<Blob> {
        var resp =  this.axios.get(`${this.apiPath}/GetZipFromLearningModule/${learningModuleId}`,{responseType: "blob" });       
        return resp;
        // The old way of calling this was for the consumer to call the endpoint url directly.
        // This is the old method signature: getZipFromLearningModuleAsyncUrl(learningModuleId: number): string;
        // This is the old body.
        // return `${this.apiPath}/GetZipFromLearningModule/${learningModuleId}`;
    }

    // Client only properties / methods
}
