
import { Data, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration.enum';
import { AppService, Types } from '../../../ForumTypes';
import TopicLookUpCriteria from '../../../Models/Criteria/TopicLookupCriteria';
import { ForumIntegrationTypes } from '../../../Models/Enums/ForumLearningTypes.enum';
import TopicLookup from '../../../Models/Lookups/TopicLookup';

@NeoModel
export default class PLCModuleConversationVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private topicQueryApiClient = AppService.get(Types.Forum.ApiClients.TopicQueryApiClient),
    private topicUpdateApiClient = AppService.get(Types.Forum.ApiClients.TopicUpdateApiClient),
    private moduleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
    private learningPathwayApiClient = AppService.get(Types.Learning.ApiClients.LearningPathwayQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  public forumId = 0;
  public moduleTitle = "";
  public pathwayTitle = "";
  public selectedTopic = new TopicLookup();
  public topicCriteria = new TopicLookUpCriteria();
  public showConversation = false;

  public PLCModuleTopicPager = new Data.PageManager(this.topicCriteria, TopicLookup, this.topicQueryApiClient.getPathwayModuleTopicsPaged, {
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 30],
    sortBy: "createdOn",
    sortAscending: false,
    fetchInitial: false,
    initialTaskRunner: this.taskRunner,
  });

  public async GetModuleById(id: number) {
    const response = await this.moduleApiClient.getLearningModuleById(id);
    this.moduleTitle = response.data.title.value;
  }

  public async GetPathwayById(id: number) {
    const response = await this.learningPathwayApiClient.getLearningPathway(id);
    this.pathwayTitle = response.data.title;
  }

  public async CreateTopic(topic: TopicLookup) {
    topic.forumId = this.forumId;
    topic.forumIntegrationTypes = ForumIntegrationTypes.LearningModulePrivate;
    const response = await this.topicUpdateApiClient.createTopicAsync(topic.toJSObject());
    if (response.data) {
      this.PLCModuleTopicPager.refreshData();
    }
  }

  public backToTopics() {
    this.PLCModuleTopicPager.refreshData();
    this.selectedTopic = new TopicLookup();
    this.showConversation = false;
  }

  public showConfirmDelete = false;
  public topicMarkedForDelete = new TopicLookup();
  public deletedReason = "";
  public DeleteTopic(topic: TopicLookup) {
    this.deletedReason = "";
    if (topic.isNew) {
      this.PLCModuleTopicPager.data.remove(topic);
    } else {
      this.topicMarkedForDelete.set(topic.toJSObject());
      this.showConfirmDelete = true;
    }
  }

  public async ConfirmDeleteTopic() {
    const response = await this.topicUpdateApiClient.deleteTopicByIdAsync(this.topicMarkedForDelete.topicId)
    if (response.data) {
      this.notifications.addSuccess("Topic Deleted", `Topic Deleted`, NotificationDuration.Standard);
      this.PLCModuleTopicPager.refreshData();
      this.topicMarkedForDelete = new TopicLookup();
      this.showConfirmDelete = false;
    } else {
      this.notifications.addSuccess("Topic Deleted failed", `Deleted failed`, NotificationDuration.Standard);
    }
  }
}