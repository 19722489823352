import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UpdateRaceCommand extends ModelBase {

    public raceId: number = 0;

    @Rules.Required()
    public raceName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.raceName) {
            return "New Race";
        } else {
            return this.raceName;
        }
    }
}