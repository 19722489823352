import { AppServices, Security } from '@singularsystems/neo-core'
import { INotificationServiceConfig } from '@singularsystems/neo-notifications';
import { injectable } from 'inversify';
import { UserManagerSettings } from 'oidc-client';
import { IAuthorisationConfig } from '../../Authorisation/Models/IAuthorisationConfig';
import { IReportingConfig } from '@singularsystems/neo-reporting';

/** 
 * this enum stores the json property names of the apis that will be found in config.json
 * the names are stored in this enum so that they can be re-used throughout the app and will only need to be changed once if the name of an api changes
 */
export enum ApiNames {
    IdentityServer = "identity",
    Core = "core",
    NotificationServer = "notificationServer",
    Organisation = "organisation",
    Learning = "learning",
    Authorisation = "authorisation",
    Reporting = "reporting",
    Forum = "forum",
    Dashboard = "dashboard",
}


/** this class represents an api config setting found in config.json
 * the api url is constructed based on the logic below
 */
export class ApiConfig {
    private baseUrl: string = "";
    private apiUrl: string = "";

    public get BasePath(): string { return this.baseUrl; }
    public get ApiPath(): string { return this.apiUrl; }


    constructor(private baseUrlTemplate: string, private apiPath: string) {
        const domain = window.location.hostname;

        // Naive approach assuming subdomain is on the host, and no 2nd, 3rd etc level subdomains
        // (Improve later to support http://domain.com and http://second.first.domain.com patterns)
        const subdomain = domain.split('.')[0];

        // Embed the domain or subdomain for multi-tenancy (Only one of these should be present in the URL template)
        this.baseUrl = this.baseUrlTemplate.replace("{domain}", domain);
        this.baseUrl = this.baseUrl.replace("{subdomain}", subdomain);
        this.apiUrl = `${this.baseUrl}${this.apiPath}`;
    }
}

@injectable()
export class AppConfig extends AppServices.ConfigModel implements IAuthorisationConfig {

    public userManagerSettings!: UserManagerSettings;
    public apiPath: string = "";
    private identityServer = { client_id: "", url: "" };
    private apis: { [key: string]: { baseUrl: string, apiPath: string } } = {};
    private siteMode: string = "";

    // this property will be populated with the api configurations found in config.json
    private apiConfigs: { [key: string]: ApiConfig } = {};

    // The apis that are usable by the app
    public get IdentityServerApi(): ApiConfig {
        return this.GetApiConfig(ApiNames.IdentityServer);
    }

    public get AuthorisationApi(): ApiConfig {
        return this.GetApiConfig(ApiNames.Authorisation);
    }

    public get authorisationServerApiPath() {
        return this.AuthorisationApi.ApiPath;
    }

    public get NotificationServerApi(): ApiConfig {
        return this.GetApiConfig(ApiNames.NotificationServer);
    }

    public get OrganisationApi(): ApiConfig {
        return this.GetApiConfig(ApiNames.Organisation);
    }

    public get LearningApi(): ApiConfig {
        return this.GetApiConfig(ApiNames.Learning);
    }

    public get DashboardApi(): ApiConfig {
        return this.GetApiConfig(ApiNames.Dashboard);
    }

    public get ForumApi(): ApiConfig {
        return this.GetApiConfig(ApiNames.Forum);
    }

    public get ReportingApi(): ApiConfig {
        return this.GetApiConfig(ApiNames.Reporting);
    }

    public get SiteMode() : string {
        return this.siteMode;
    } 

    public get notificationServerConfig(): INotificationServiceConfig {
        return {
            basePath: this.NotificationServerApi.BasePath,
            apiPath: this.NotificationServerApi.BasePath + "/api",
            popupHideTime: 4,
            appId: 1,
            allowBodyHtml: false
        }
    }

    /**
    * Transforms property values loaded from config.json
    */
    public initialise() {
        this.populateApisFromConfigFile();
        this.apiPath = this.apiPath.replace("{domain}", window.location.hostname);
        this.identityServer.url = this.identityServer.url.replace("{domain}", window.location.hostname);
        this.loadUserManagerSettings();
    }

    private loadUserManagerSettings() {
        this.userManagerSettings = {
            client_id: this.identityServer.client_id,
            redirect_uri: window.location.origin + '/OidcLoginRedirect',
            response_type: 'code',
            scope: "openid profile api1 NotificationService Authorisation Domain IdentityServerApi",
            authority: this.identityServer.url,
            silent_redirect_uri: window.location.origin + '/OidcSilentLoginRedirect',
            monitorSession: false,
            metadata: Security.OidcAuthService.createIdentityServerMetadata(this.identityServer.url)
        }
    }

    private populateApisFromConfigFile() {
        for (const apiName of Object.keys(this.apis)) {
            if (this.apis.hasOwnProperty(apiName)) {
                const api = this.apis[apiName];
                this.apiConfigs[apiName] = new ApiConfig(api.baseUrl, api.apiPath);
            }
        }
    }

    /** 
    * check if the api name listed in the 'ApiNames' enum exists
    * if it does exist, just return the record, or throw an error
    */
    public GetApiConfig(apiName: ApiNames): ApiConfig {
        if (this.apiConfigs.hasOwnProperty(apiName.toString())) {
            return this.apiConfigs[apiName];
        } else {
            throw new Error(`API Configuration '${apiName}' could not be found`);
        }
    }

    public get reportingConfig(): IReportingConfig {
        return {
            basePath: this.ReportingApi.BasePath,
            apiPath: this.ReportingApi.ApiPath,
            notificationDuration: 4,
            showPIIDownloadWarning: true,
        }
    }
}