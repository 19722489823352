import { EnumHelper } from "@singularsystems/neo-core";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import Card from "../../Template/components/Card/Card";
import CardBody from "../../Template/components/Card/CardBody";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import { OrganisationType } from "../Models/Enums/OrganisationType.enum";

import OrganisationsComponentVM from "./OrganisationComponentVM"

interface IOrganisationsComponentProps {
    viewModal: OrganisationsComponentVM;
}

@observer
export default class OrganisationView extends React.Component<IOrganisationsComponentProps>{
    public render() {

        const viewModel = this.props.viewModal;

        return (
            <div>
                <div>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="success">
                                <i className={`fa fa-sitemap fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">{viewModel.selectedOrganisationId ?
                                "Selected Organisation: " + viewModel.organisationList.find(c => c.organisationId === viewModel.selectedOrganisationId)?.organisationName : "Organisation"}</h4>
                        </CardHeader>
                        <CardBody>
                            <p> <Neo.Button variant="success" isOutline={viewModel.enableEdit ? false : true} icon="edit"
                                onClick={() => viewModel.enableEdit = !viewModel.enableEdit} >Edit Organisations</Neo.Button></p>
                            <NeoGrid.Grid items={viewModel.organisationList} initialSortAscending={false} initialSort={"organisationId"} showAddButton={viewModel.enableEdit}>
                                {(organisation, organisationMeta) => (
                                    <NeoGrid.Row className={viewModel.selectedOrganisationId === organisation.organisationId ? "selected-item-css" : "bg-none"}>
                                        <NeoGrid.ButtonColumn>
                                            <Neo.Button tooltip="Click to filter Province by this Organisation" icon="fa-map-marker" variant={viewModel.selectedOrganisationId === organisation.organisationId ? "info" : "success"} isOutline
                                                onClick={() => {
                                                    viewModel.setSelectedOrganisation(organisation.organisationId);
                                                }} />
                                        </NeoGrid.ButtonColumn>
                                        <NeoGrid.Column label="Name" bind={organisationMeta.organisationName} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.Column label="Reg Number" bind={organisationMeta.registrationNumber} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.Column label="Email" bind={organisationMeta.contactEmail} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.Column bind={organisationMeta.organtisationType} select={{ items: EnumHelper.asList(OrganisationType) }} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.ButtonColumn showDelete={organisation.organisationId === 0}>
                                            <Neo.Button disabled={!viewModel.enableEdit} hidden={organisation.organisationId === 0} icon="trash" variant="danger" onClick={() => viewModel.deleteOrganisationPopUp(organisation.organisationName, organisation.organisationId)} />
                                        </NeoGrid.ButtonColumn>
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>
                            {viewModel.organisationList.length === 0 &&
                                <div className="col-md-12 p-0 text-center">
                                    <p>No Records</p>
                                </div>}
                            <Neo.Form model={viewModel.organisationList} onSubmit={() => viewModel.saveOrganisations()} showSummaryModal>
                                <div className="my-4 saveButton">
                                    <Neo.Button hidden={!viewModel.enableEdit} disabled={!viewModel.organisationList.isDirty} isSubmit variant="warning" icon="check">Save</Neo.Button>
                                </div>
                            </Neo.Form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }
}