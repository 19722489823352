import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class NewMembership extends ModelBase {

    public userGroupId : number = 0;

    public userId : number = 0;

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString() {
        if (this.isNew) {
            return "New new membership"
        } else {
            return "New Membership";
        }
    }
}