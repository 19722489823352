
export enum LearningContentMediaType {
    Unsupported = 1,
    Video = 2,
    Document = 3,
    Link = 4,
    SCORM = 5,
    Image = 6,
    Sound = 7,
    Conversation = 8,
    YouTube = 9,
}