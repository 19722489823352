
import * as React from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import "../../Styles/VerticalDragAndDrop.scss";
import LearningObjectDragableModel from '../../Models/Lookups/LearningObjectDragableModel';

interface Props {
  items: LearningObjectDragableModel[];
  setDNDItems: (items: Array<LearningObjectDragableModel>) => void;
}

export default class VerticalDragAndDropComponent extends React.Component<Props> {
  render() {
    const items = this.props.items;
    return (
      <div className="vertical">
        <div className="row ">
          <div className="col-md-12">
            <RLDD cssClasses="example-list-container" layout="vertical" items={items} itemRenderer={this.itemRenderer} onChange={this.handleRLDDChange} />
          </div>
          {/* <div className="col-md-4 description-box">
            <p className="description">Drag And Drop items to reorder them. Remember to save</p>
          </div> */}
        </div>
      </div>
    );
  }

  private itemRenderer = (item: LearningObjectDragableModel, index: number): JSX.Element => {
    return (
      <div >
        <div className="item" style={{ color: item.finalQuizInd ? '#fe6701' : '#4daf50', borderColor: item.finalQuizInd ? '#fe6701' : '#4daf50' }}>
          <div className="row">
            <div className="col-md-4">
              <div className="title" style={{ color: item.finalQuizInd ? '#fe6701' : '#4daf50' }}>
                {item.name}
              </div>
            </div>

            <div className="col-md-4" >
              <div className="title">
                Order:  {index + 1}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  private handleRLDDChange = (reorderedItems: Array<LearningObjectDragableModel>) => {
    this.props.setDNDItems(reorderedItems)
  };
}
