


import { NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from "@singularsystems/neo-react";
import { AppService } from "../Services/AppService";
import { Types } from '../../Identity/IdentityTypes';
import React from "react";
import { observer } from "mobx-react";

interface IDeleteModalProps {
  viewModel: DeleteModalVm;
  Title: string;
  show: boolean;
  OnDelete: () => void;
  onClose: (show: boolean) => void;
}

@NeoModel
export class DeleteModalVm extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner)) {
    super(taskRunner)
  }
}

@observer
export default class DeleteModal extends React.Component<IDeleteModalProps>{

  public render() {
    const props = this.props;
    return (
      <Neo.Modal title={props.Title} size="sm"
        show={props.show}
        onClose={() => { props.onClose(props.show) }}
        acceptButton={{
          text: "Delete", variant: "danger", icon: "trash",
          onClick: () => props.OnDelete()
        }} closeButton={{
          text: "Cancel", variant: "secondary", icon: "times"
        }} >
        <Neo.Loader task={props.viewModel.taskRunner}>
          <div>
            <p>Please Confirm Delete</p>
          </div>
        </Neo.Loader>
      </Neo.Modal>
    )
  }
}