import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../ForumTypes';
import TopicLookup from '../../../Models/Lookups/TopicLookup';
import TopicSearchCriteria from '../../../Models/Lookups/TopicSearchCriteria';
import TopicLookupCriteria from '../../../Models/Criteria/TopicLookupCriteria';

export interface ITopicQueryApiClient {

    /**
     * Creates a topic
     * @param topicId TopicId to get
     * @returns Topic
     */
    getTopicById(topicId: number): AxiosPromise<Model.PlainObject<TopicLookup>>;

    /**
     * Get Topics by Creator Guid
     * @param createdBy Created By Guid
     * @returns List of TopicLookup
     */
    getTopicsByCreatorAsync(createdBy: string): AxiosPromise<Array<Model.PlainObject<TopicLookup>>>;

    /**
     * Get Topics by Forum
     * @param forumId forumId
     * @returns List of TopicLookup
     */
    getTopicsByForumAsync(forumId: number): AxiosPromise<Array<Model.PlainObject<TopicLookup>>>;

    /**
     * Get Topics by Forum
     * @param forumId forumId
     * @returns List of TopicLookup
     */
    getLearningObjectTopicByForumId(forumId: number): AxiosPromise<Model.PlainObject<TopicLookup>>;

    /**
     * Get Topics by Posters Guid
     * @param postedBy The Guid of the person in any posts in the topic.
     * @returns List of TopicLookup where the person has posted in
     */
    getTopicsByPosterAsync(postedBy: string): AxiosPromise<Array<Model.PlainObject<TopicLookup>>>;

    /**
     * Get Topics by Criteria
     * @param request request
     * @returns List of TopicLookup
     */
    getPagedTopicsByCriteriaAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>>;

    /**
     * Gets a paged list of topics for a global module conversation
     * @param request request.
     * @returns Topic List.
     */
    getGlobalTopicsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>>;

    /**
     * Gets a paged list of topics for a global module conversation
     * @param request request.
     * @returns Topic List.
     */
    getPathwayTopicsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>>;

    /** 
     * Gets a paged list of topics for a module withing a pahtway.
     * @param request request.
     * @returns Topic List.
     */
    getPathwayModuleTopicsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class TopicQueryApiClient extends Data.ApiClientBase implements ITopicQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.ForumApi.ApiPath}/TopicQuery`);
    }

    public getTopicById(topicId: number): AxiosPromise<Model.PlainObject<TopicLookup>> {
        return this.axios.get(`${this.apiPath}/GetTopicById/${topicId}`);
    }

    public getTopicsByCreatorAsync(createdBy: string): AxiosPromise<Array<Model.PlainObject<TopicLookup>>> {
        return this.axios.get(`${this.apiPath}/GetTopicsByCreator/${encodeURIComponent(createdBy)}`);
    }

    public getTopicsByForumAsync(forumId: number): AxiosPromise<Array<Model.PlainObject<TopicLookup>>> {
        return this.axios.get(`${this.apiPath}/GetTopicsByForumAsync/${forumId}`);
    }

    public getLearningObjectTopicByForumId(forumId: number): AxiosPromise<Model.PlainObject<TopicLookup>> {
        return this.axios.get(`${this.apiPath}/GetLearningObjectTopicByForumId/${forumId}`);
    }

    public getTopicsByPosterAsync(postedBy: string): AxiosPromise<Array<Model.PlainObject<TopicLookup>>> {
        return this.axios.get(`${this.apiPath}/GetTopicsByPoster/${encodeURIComponent(postedBy)}`);
    }

    public getPagedTopicsByCriteriaAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>> {
        return this.axios.get(`${this.apiPath}/GetPagedTopicsByCriteriaAsync?${Utils.getQueryString(request)}`);
    }

    public getGlobalTopicsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>> {
        return this.axios.get(`${this.apiPath}/GlobalTopicsPaged?${Utils.getQueryString(request)}`);
    }

    public getPathwayTopicsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>> {
        return this.axios.get(`${this.apiPath}/PathwayTopicsPaged?${Utils.getQueryString(request)}`);
    }

    public getPathwayModuleTopicsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>> {
        return this.axios.get(`${this.apiPath}/PathwayModuleTopicsPaged?${Utils.getQueryString(request)}`);
    }

    // Client only properties / methods
}