
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import React from "react";
import { observer } from "mobx-react";
import LearningModuleLookup from "../../Models/Lookups/LearningModuleLookup";
import { LearningPathwayEditorVM } from './LearningPathwayEditorVM'
import "../../../App/Styles/LearningPathWay.scss";
import GridContainer from "../../../Template/components/Grid/GridContainer";
import GridItem from "../../../Template/components/Grid/GridItem";
import Card from "../../../Template/components/Card/Card";
import CardIcon from "../../../Template/components/Card/CardIcon";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardBody from "../../../Template/components/Card/CardBody";
import CardFooter from "../../../Template/components/Card/CardFooter";
import ForumDrawer from '../../../Forum/Components/ForumComponents/ForumDrawer';
import { Tooltip } from '@material-ui/core';

interface ILearningPathwayEditorProps {
  show: boolean;
  learningPathwayId?: number | null;
  onClose: (show: boolean) => void;
  GoToModule: (moduleId: number, preview: boolean) => void;
}

@observer
export default class LearningPathwayEditor extends React.Component<ILearningPathwayEditorProps>{
  constructor(props: ILearningPathwayEditorProps) {
    super(props);
    this.viewModel = new LearningPathwayEditorVM();
    if (props.learningPathwayId) {
      this.viewModel.getLearningPathway(props.learningPathwayId as number);
    }
  }

  private async save() {
    await this.viewModel.SaveLearingPathway();
    this.props.onClose(this.props.show);
  }

  private viewModel = new LearningPathwayEditorVM();
  public render() {
    const viewModel = this.viewModel;
    const showModal = this.props.show;

    return (
      <div onKeyPress={(event) => { if (event.charCode === 13) { viewModel.SearchModules(); event.preventDefault() } }}>
        <ForumDrawer show={showModal} onClose={this.props.onClose} isMaximised={viewModel.isMaximised} maximiseWindow={() => viewModel.meta.isMaximised.value = !viewModel.isMaximised} >

          <Card>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`icon fa fa-road fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">New Learning Pathway</h4>
              <Neo.FormGroupFloating required label="Learning Pathway Name" bind={viewModel.learningPathway.meta.title} placeholder="Title" />
            </CardHeader>
            <CardBody>
              <Card>
                <CardHeader icon>
                  <CardIcon color="info">
                    <i className={`icon fa fa-search fa-2x`}></i>
                  </CardIcon>
                  <h4 className="Card-icon-header-text">Add Modules</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <label>Search</label>
                      <Neo.FormGroupFloating label="Search module by title" bind={this.viewModel.criteria.meta.title} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <label>Category Tag</label>
                      <Neo.AutoCompleteDropDown
                        bind={this.viewModel.criteria.meta.learningModuleTag1Id}
                        bindDisplay={viewModel.criteria.meta.learningModuleTag1Name}
                        displayMember="tagName"
                        itemSource={viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                        onItemSelected={(item) => { viewModel.FilterTag2ListSearch(item?.tag1Id) }}
                        loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                        minCharacters={2}
                        typeToSearchPrompt="Start typing for search options"
                        menuPosition="fixed"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Neo.FormGroup label="Sub-Category Tag (based on Category Tag)" bind={viewModel.criteria.meta.learningModuleTag2Id} select={{
                        items: viewModel.learningModuleTag2FilteredList,
                        allowNulls: true,
                        displayMember: "tagName",
                        onItemSelected: (item) => viewModel.FilterModuleTag3ListSearch(item?.tag2Id)
                      }} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Neo.FormGroup label="Topic (Based on Sub-category Tag)" bind={viewModel.criteria.meta.learningModuleTag3Id} select={{
                        items: viewModel.learningModuleTag3FilteredList,
                        allowNulls: true
                      }} />
                    </GridItem>
                    <GridItem xs={2} sm={2} md={4}>
                      <Neo.Button isOutline variant="info" text="Search" icon="search" type="submit" onSubmit={() => viewModel.learningModulePageManager.refreshData()} onClick={() => viewModel.learningModulePageManager.refreshData()} />
                      <Neo.Button isOutline text="Clear Search" variant="warning" icon="eraser" onClick={() => viewModel.ClearFilters()} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Neo.Pager
                        pageManager={viewModel.learningModulePageManager}
                        pageControlProps={{
                          firstText: "",
                          prevText: "Prev",
                          nextText: "Next",
                          lastText: "",
                          alignment: "center",
                          autoHide: false,
                          pageSizeLabel: "Show"
                        }}>
                        <NeoGrid.Grid<LearningModuleLookup>>
                          {(learningModule: LearningModuleLookup, learningModuleMeta) => (
                            <NeoGrid.Row className={viewModel.IsSelected(learningModule.learningModuleId) ? "selected-item-css" : "bg-none"}>
                              <NeoGrid.Column label="Title" display={learningModuleMeta.learningModuleTitle} />
                              <NeoGrid.Column label="Learning Objective" display={learningModuleMeta.description} />
                              <NeoGrid.ButtonColumn label="Actions" >
                                <Tooltip id="tooltip-top" title="Preview" placement="top">
                                  <Neo.Button variant="info" isOutline icon="eye" tooltip="Preview" onClick={() => this.props.GoToModule(learningModule.learningModuleId, true)} />
                                </Tooltip>
                                <Tooltip id="tooltip-top" title="Add" placement="top">
                                  <Neo.Button variant="success" isOutline hidden={viewModel.IsSelected(learningModule.learningModuleId)} icon="plus " tooltip="Add" onClick={() => { viewModel.AddModuleToPathway(learningModule) }} />
                                </Tooltip>
                                <Tooltip id="tooltip-top" title="Remove" placement="top">
                                  <Neo.Button variant="danger" isOutline hidden={!viewModel.IsSelected(learningModule.learningModuleId)} icon="times " tooltip="Remove" onClick={() => { viewModel.RemoveModule(learningModule) }} />
                                </Tooltip>
                              </NeoGrid.ButtonColumn>
                            </NeoGrid.Row>
                          )}
                        </NeoGrid.Grid>
                      </Neo.Pager>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </CardBody>
            <CardFooter>
              <div style={{ float: "right" }}>
                <Neo.Button text="Save" isOutline={true} variant="success" isSubmit={false} disabled={(viewModel.learningPathway.title.length === 0 || viewModel.selectedLearningModuleList.length === 0) || (viewModel.learningPathway.learningPathwayId > 0 && (viewModel.learningPathway.meta.title.value.length === 0))} onClick={() => { this.save() }} />
                <Neo.Button text="Close" isOutline={true} variant="danger" onClick={() => this.props.onClose(this.props.show)} />

              </div>
            </CardFooter>
          </Card>
        </ForumDrawer>

        {/* <Neo.Modal title="Learning Pathway" size="xl"
          show={showModal}
          onClose={() => { this.props.onClose(showModal) }}
          closeButton={{ isOutline: true, variant: "danger" }}
          acceptButton={{ text: "Save", isOutline: true, variant: "success", isSubmit: false, disabled: (viewModel.learningPathway.meta.title.value.length === 0 || viewModel.selectedLearningModuleList.length === 0) || (viewModel.learningPathway.learningPathwayId > 0 && (viewModel.learningPathway.meta.title.value.length === 0)), onClick: () => { this.save() } }}>
          <Neo.Loader task={viewModel.taskRunner}>
            
          </Neo.Loader>

        </Neo.Modal > */}

      </div >
    )
  }
}