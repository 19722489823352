import { Components, NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from '@singularsystems/neo-react';
import { AppService } from "../../App/Services/AppService";
import SchoolImportResult from "../Models/Organisations/SchoolsImport/SchoolImportResult";
import Types from '../../App/AppTypes';
import React from "react";
import { observer } from "mobx-react";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";

interface ISchoolImportModalProps {
    viewModal: SchoolImportModalVM;
    schoolsImported: () => void;
}

@NeoModel
export class SchoolImportModalVM extends Views.ViewModelBase {

    public importResult?: SchoolImportResult;

    public schoolsImported!: () => void;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private importApiClient = AppService.get(Types.Organisations.ApiClients.SchoolImportApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    public showSchoolImportModal = false;

    public fileManager = new Components.FileManager(this.importApiClient.getUploadOrganisationProvinceFileUrl(),
        {
            afterUpload: this.afterUpload.bind(this),
            onFilesSelected: this.onUploadFilesSelected.bind(this),
            allowedExtensions: [".xlsx", ".xls"]
        });

    private onUploadFilesSelected(fileList: File[] | FileList) {
        this.fileManager.uploadFiles(fileList);
    }

    private afterUpload(fileKey: string) {
        const self = this;

        this.taskRunner.run("Importing file", async () => {

            const result = (await self.importApiClient.doImport(fileKey)).data;
            self.importResult = SchoolImportResult.fromJSObject<SchoolImportResult>(result);

            this.schoolsImported();
            this.showSchoolImportModal = false;
        });
    }

    public uploadRequested() {
        this.fileManager.showFileDialog();
    }

    public show() {
        this.showSchoolImportModal = true;
    }
}

@observer
export default class SchoolImportModal extends React.Component<ISchoolImportModalProps> {

    public render() {

        const viewModel = this.props.viewModal;

        viewModel.schoolsImported = this.props.schoolsImported;

        return (
            <Neo.Modal size="xl"
                title={`Schools Import`}
                show={viewModel.showSchoolImportModal}
                onClose={() => { viewModel.showSchoolImportModal = false; }}
                closeButton={{
                    text: "Cancel", variant: "secondary", icon: "times"
                }} >
                <div>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fa folder fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">File selection</h4>
                            </CardHeader>
                            <CardBody>
                                <Neo.Loader task={viewModel.taskRunner}>
                                    <Neo.FileDropArea fileManager={viewModel.fileManager}>
                                        To upload files, drop file into this block or <Neo.Button variant="primary" onClick={() => viewModel.uploadRequested()}>Click here</Neo.Button>
                                        <Neo.FileUploadProgress fileManager={viewModel.fileManager} />
                                    </Neo.FileDropArea>
                                </Neo.Loader>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Neo.Modal>
        )
    }
}