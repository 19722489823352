import { List, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { injectable } from "inversify";
import { observer } from "mobx-react";
import React from "react";
import { AppService, Types } from "../../App/Services/AppService";
import Title from "../Models/Educators/Title";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum"
import DeleteTitleCommand from '../Models/Commands/DeleteTitleComand';
import UpdateTitleCommand from '../Models/Commands/UpdateTitleCommand';
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";

interface ITitleComponentProps {
    viewModel: TitleComponentVM
}

@injectable()
@NeoModel
export class TitleComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private titleApiClient = AppService.get(Types.Identity.ApiClients.TitleCommandApiClient),
        private titleQueryApiClient = AppService.get(Types.Identity.ApiClients.TitleQueryApiClient),
        private saceEducatorQueryApiClient = AppService.get(Types.Identity.ApiClients.SACEEducatorQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    //Properties
    public titleList = new List(Title);
    public TitleCommands = new List(UpdateTitleCommand);
    public enableEdit: boolean = false;

    //Methods
    public async initialise() {
        this.LoadTitleList();
    }

    public async LoadTitleList() {
        const getTitleListResponse = await this.taskRunner.waitFor(this.titleQueryApiClient.GetTitleList());
        if (getTitleListResponse.data !== null) {
            this.titleList.set(getTitleListResponse.data);
        }
    }

    public async saveTitles() {
        this.taskRunner.run(async () => {
            this.TitleCommands.set([]);
            this.titleList.forEach(item => {
                if (item.isDirty || item.isNew) {
                    var titleCommand = new UpdateTitleCommand();
                    titleCommand.titleId = item.titleId;
                    titleCommand.titleName = item.titleName;
                    this.TitleCommands.push(titleCommand);
                }
            });
            await this.titleApiClient.saveList(this.TitleCommands.toJSArray());
            this.LoadTitleList();
            this.notifications.addSuccess("Titles saved", null, NotificationDuration.Short);
        });
        this.enableEdit = false;
    }

    public async deleteTitlePopUp(titleName: string, titleId: number) {
        const response = await this.taskRunner.waitFor(this.titleQueryApiClient.checkTitleLinks(titleId));
        if (response.data) {
            ModalUtils.showMessage("Cannot Delete " + titleName, "This title has SACE Eductor(s) linked to it and cannot be deleted", 3)
        }
        else {
            await ModalUtils.showYesNoDismissible("Delete Title " + titleName,
                "Are you sure you want to delete this Title? ", () => this.delete(titleId))
        }
    }

    public async delete(titleId: number) {
        this.taskRunner.run(async () => {
            var titleCommand = new DeleteTitleCommand();
            titleCommand.titleId = titleId;
            await this.titleApiClient.delete(titleCommand.toJSObject());
            this.LoadTitleList();
            this.notifications.addSuccess("Title Deleted", null, NotificationDuration.Short);
        })
    }
}

@observer
export default class TitleView extends React.Component<ITitleComponentProps>{

    public render() {
        const viewModel = this.props.viewModel;

        return (
            <div className="pv-5">
                <section>
                    <div className="row ">
                        <div className="col-md-12">
                            <div>
                                <Card>
                                    <CardHeader icon>
                                        <CardIcon color="success">
                                            <i className={`fas fa-female fa-2x`}></i>
                                        </CardIcon>
                                        <h4 className="Card-icon-header-text">Titles</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <NeoGrid.Grid items={viewModel.titleList} initialSortAscending={false} initialSort={"titleId"} showAddButton={!viewModel.enableEdit}>
                                            {(title, titlemeta) => (
                                                <NeoGrid.Row>
                                                    <NeoGrid.Column label="Name" bind={titlemeta.titleName} />
                                                    <NeoGrid.ButtonColumn>
                                                        <Neo.Button icon="trash" variant="danger" onClick={() => viewModel.deleteTitlePopUp(title.titleName, title.titleId)} />
                                                    </NeoGrid.ButtonColumn>

                                                </NeoGrid.Row>
                                            )}
                                        </NeoGrid.Grid>

                                        {viewModel.titleList.length === 0 &&
                                            <div className="col-md-12 p-0 text-center">
                                                <p>No Records</p>
                                            </div>}

                                        <Neo.Form model={viewModel.titleList} onSubmit={() => viewModel.saveTitles()} showSummaryModal>
                                            <div className="my-4 titleSave">
                                                <Neo.Button disabled={!viewModel.titleList.isDirty} isSubmit variant="warning" icon="check">Save</Neo.Button>
                                            </div>
                                        </Neo.Form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}