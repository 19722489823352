import { Attributes, List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import Tag3Lookup from "../../Models/Lookups/Tag3Lookup";

@NeoModel
export default class Tag2Lookup extends LookupBase {

    public tag1Id: number = 0;

    public tag2Id: number = 0;

    public tagName: string = "";

    public tag3Id: number = 0;

    public tagDescription: string = "";

    @Attributes.Observable()
    public isExpanded: boolean = false;

    public tags3 = new List(Tag3Lookup);

}