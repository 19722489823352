import { Attributes, ModelBase, NeoModel, Validation, List } from '@singularsystems/neo-core';
import SelfAssessmentTagLookup from './SelfAssessmentTagLookup';
import { ModerationState } from '../LearningObjects/ModerationState.enum';
import { ModeratableLongStringField, ModeratableShortStringField } from '../Moderation/ModeratableFieldValues';
import SelfAssessmentQuestionModerationLookup from './SelfAssessmentQuestionModerationLookup';
import { Sentiment } from '../Questions/Sentiment.enum';
import { QuestionType } from '../Questions/QuestionType.enum';

@NeoModel
  export default class SelfAssessmentModerationLookup extends ModelBase {

    constructor() {
        super();
        this.selfAssessmentTitle = new ModeratableShortStringField();
        this.selfAssessmentDescription = new ModeratableLongStringField();
    }

    public selfAssessmentId: number = 0;

    @Attributes.ChildObject(ModeratableShortStringField)
    public selfAssessmentTitle: ModeratableShortStringField;

    @Attributes.ChildObject(ModeratableLongStringField)
    public selfAssessmentDescription: ModeratableLongStringField;

    public selfAssessmentQuestions = new List(SelfAssessmentQuestionModerationLookup);

    @Attributes.Observable()
    public isExpanded: boolean = false;
    
    public selfAssessmentTags2 = new List(SelfAssessmentTagLookup);    

    public createdBy: string = ""; 

    public createdByUser: string = "";

    public tag2Id: number = 0;

    public moderationNotes: string = ""

    public selfAssessmentQuestionDnDModerationNotes: string = ""

    public moderationState: ModerationState | null = null;

    public sentiment: Sentiment = Sentiment.NotSet;

    public questionTypeId: QuestionType = QuestionType.NotSet;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
  }