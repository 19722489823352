import * as React from 'react';
import { Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import LearningPathwayView from '../../../Learning/Views/LearningPathways/LearningPathwayView';
import ModuleConsumptionView from '../../../Learning/Views/ContentConsumption/ModuleConsumptionView';
import PathwayModuleConsumptionView from '../../../Learning/Views/ContentConsumption/PathwayModuleConsumptionView';
import SelfAssessmentConsumptionView from '../../../Learning/Views/ContentConsumption/SelfAssements/SelfAssessmentConsumptionView';
import ReviewSelfAssessmentView from '../../../Learning/Views/Assessment/ReviewSelfAssessmentView';
import Dashboard from '../../../Dashboard/Components/Dashboard';
import UserProfileView from '../../../Identity/Views/UserProfileView';

@observer
export default class Home extends Views.ViewBase {

    constructor(props: unknown) {
        super("Dashboard", Views.EmptyViewModel, props);
    }

    private GoToPathwayView = (id: number) => {
        this.navigation.navigateToView(LearningPathwayView, { pathwayId: id });
    }

    private GoToPathwayModule = (moduleId: number, pathwayId: number) => {
        this.navigation.navigateToView(PathwayModuleConsumptionView, { moduleId: moduleId, pathwayId: pathwayId });
    }

    private GoToModuleView = (id: number, preview: number) => {
        this.navigation.navigateToView(ModuleConsumptionView, { moduleId: id, preview: preview });
    }

    public GoToSelfAssessment = (id: number) => {
        this.navigation.navigateToView(SelfAssessmentConsumptionView, { selfAssementId: id, });
    }

    public GoToProfile() {
        this.navigation.navigateToView(UserProfileView);
    }

    public ReviewSelfAssessment = (id: number) => {
        this.navigation.navigateToView(ReviewSelfAssessmentView, { userAssessmentId: id, });
    }

    public render() {
        return (
            <div>
                <Dashboard
                    ReviewSelfAssessment={(id) => this.ReviewSelfAssessment(id)}
                    GotoSelfAssessment={(id: number) => this.GoToSelfAssessment(id)}
                    GoToModule={(moduleId, preview) => this.GoToModuleView(moduleId, preview)}
                    GoToPathway={(id) => this.GoToPathwayView(id)}
                    GoToProfile={() => this.GoToProfile()}
                    GoToPathwayModule={(moduleId, pathwayId) => this.GoToPathwayModule(moduleId, pathwayId)} />
            </div>
        )
    }
}