import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';

export enum LifeTime {
    Short = 30,
    Long = 240
}

@injectable()
export class AppDataService extends Data.CachedDataService {

}