import { Attributes, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class TermsAndConditionLookup extends ModelBase {

    @Rules.StringLength(8000)
    public termsAndConditions: string = "";

    @Attributes.Date()
    public createdOn: Date = new Date();

    // Client only properties / methods
}