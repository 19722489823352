import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class SelfAssessmentSearchCriteria extends ValueObject {
  public SelfAssessmentTitle: string = "";

  public FilterByCreated: boolean = true;

  public keyword: string = "";

  public SelfAssessmentTag3Id: number = 0;

  public SelfAssessmentTag2Id: number = 0;

  public SelfAssessmentTag1Id: number = 0;

  public SelfAssessmentTag1Name: string = "";

  public SelfAssessmentId: number | null = null;

  public Createdby: string = "";

  public ModerationState: number | null = null;

  public StateSelectedUnknown: boolean = false;

  public StateSelectedUnmoderated: boolean = true;

  public StateSelectedSubmittedForModeration: boolean = false;

  public StateSelectedUnderModeration: boolean = false;

  public StateSelectedReview: boolean = false;

  public StateSelectedModerated: boolean = false;

  public StateSelectedResubmittedForModeration: boolean = false;
}