import { Data, List, NeoModel, ModalUtils } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { AppService } from "../../../App/Services/AppService";
import Types from '../../../App/AppTypes';
import React from "react";
import { observer } from "mobx-react";
import LearningObjectContentLookup from "../../Models/Lookups/LearningObjectContentLookup";
import SelfAssessmentVM from "../Assessment/SelfAssessmentVM";
import { ValidationDisplayMode } from "@singularsystems/neo-core/dist/Validation/Misc";
import SelfAssessmentQuestionLookup from "../../Models/Lookups/SelfAssessmentQuestionLookup";
import SelfAssessmentLookup from "../../Models/Lookups/SelfAssessmentLookup";
import OptionLookup from "../../Models/Lookups/OptionLookup";
import MediaObject from "../../Models/LearningObjects/LearningContent/MediaObject";
import FileLookup from "../../Models/Lookups/FileLookup";
import { LearningContentMediaType } from "../../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import FileDescriptor from "../../Models/Files/FileDescriptor";
import { Sentiment } from "../../Models/Questions/Sentiment.enum";
import SelfAssessmentOptions from "../../Models/Questions/Assessment/SelfAssessmentOptions";
import "../../../App/Styles/Modules.scss";
import LearningContentCombinedObject from "../../Models/LearningObjects/LearningContentCombinedObject";
import RichTextEditor from "../../Components/RichTextEditor";
import { ModerationState } from "../../Models/LearningObjects/ModerationState.enum";
import { OpenLink } from '../../../App/HelperClasses/GlobalHelpers';
import Card from "../../../Template/components/Card/Card";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardIcon from "../../../Template/components/Card/CardIcon";
import CardBody from "../../../Template/components/Card/CardBody";

interface ISelfAssessmentQuestionComponent {
    hidden: boolean;
    viewModel: SelfAssessmentQuestionComponentVM;
    selfAssessmentVM: SelfAssessmentVM;
    selectedLearningObject: SelfAssessmentLookup;
    learningContent: LearningObjectContentLookup;
    selfAssessmentQuestion: SelfAssessmentQuestionLookup;
    selfAssessmentOptions: List<SelfAssessmentOptions>;
}

export enum QuestionType {
    /// <summary>
    /// NotSet
    /// </summary>
    NotSet = 1,

    /// <summary>
    /// MultipleChoice
    /// </summary>
    MultipleChoice = 2,

    /// <summary>
    /// LikertScale
    /// </summary>
    LikertScale = 6,
}

@NeoModel
export class SelfAssessmentQuestionComponentVM extends Views.ViewModelBase {
    public optionLookup = new List(OptionLookup);
    public selectedQuestionTypeId = 0;
    public questionRichText = "";
    public showLinkedResource = false;
    public selectedSentimentId = 0;
    public selectedNumberOfScales = 0

    public disableSaveQuestion = false;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private apiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
        private selfAssessmentApiClient = AppService.get(Types.Learning.ApiClients.SelfAssessmentApiClient)) {
        super(taskRunner);
        this.fileDescriptorList = new List(FileLookup);
        this.optionLookup = new List(OptionLookup);
    }

    // Properties
    public selectedMediaType: number = 0;
    public fileDescriptorList = new List(FileLookup);

    public async getFileLookup(fileDescriptorId: string, mediaTypeId: number) {
        this.fileDescriptorList = new List(FileLookup);
        const fileLookUp = (await this.taskRunner.waitFor(this.apiClient.getFileByDescriptorId(fileDescriptorId, mediaTypeId))).data;
        if (fileLookUp) {
            for (let index = 0; index < fileLookUp.length; index++) {
                var element = fileLookUp[index];
                var fileDescriptor = new FileLookup();
                fileDescriptor.fileDescriptorId = element.fileDescriptorId as string;
                fileDescriptor.fileName = element.fileName as string;
                fileDescriptor.mediaTypeId = element.mediaTypeId as number;
                fileDescriptor.mimeType = element.mimeType as string;
                this.fileDescriptorList.push(fileDescriptor);
            }
        }
    }

    public async setSelectedQuestionType(id: number, selfAssessmentId: number) {
        this.selectedQuestionTypeId = id;
        var response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.checkQuestionQuestionType(selfAssessmentId, id as QuestionType));

        if (response.data.exists === true) {
            this.notifications.addDanger("Error", "The selected self assessment can only have " + response.data.questionType + " questions.Please select " + response.data.questionType + " Question Type");
            return;
        }
    }

    public canUpdateAssessmentQuestion(selfAssessmentQuestion: SelfAssessmentQuestionLookup) {
        if (selfAssessmentQuestion.selfAssessmentOptions.length === 0) {
            return true;
        }
        return false;
    }

}

@observer
export class SelfAssessmentQuestionComponent extends React.Component<ISelfAssessmentQuestionComponent> {

    private apiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient);
    private selfAssessmentApiClient = AppService.get(Types.Learning.ApiClients.SelfAssessmentApiClient);
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications);

    public AddLinkedResource() {
        this.props.viewModel.showLinkedResource = true;
    }

    public CancelLinkedResource() {
        this.props.viewModel.showLinkedResource = false;
    }

    private allowedExtensions: string[] = [];
    public showFiles: boolean = false;
    private afterUpload(fileDescriptor: FileDescriptor) {
        this.props.viewModel.taskRunner.run("Importing file", async () => {
            var mediaObject = new MediaObject();
            mediaObject.fileDescriptorId = fileDescriptor.fileDescriptorId;
            mediaObject.fileName = fileDescriptor.fileName;
            this.props.learningContent.learningContent.mediaObject = mediaObject;
            this.props.learningContent.learningObjectId = 0;
            this.props.viewModel.getFileLookup(fileDescriptor.fileDescriptorId, this.props.selfAssessmentVM.selectedMediaType);
            this.notifications.addSuccess("File has been uploaded", "You can now save your learning Content", 4);
        });
    }

    private async deleteFile(fileName: string, fileDescriptorId: string) {
        await ModalUtils.showYesNoDismissible("Delete File " + fileName,
            "Are you sure you want to delete this File? ", () => this.deleteConfirmed(fileDescriptorId, this.props.viewModel.selectedMediaType as number));
    }

    private async deleteConfirmed(fileDescriptorId: string, mediaType: number) {
        await this.props.viewModel.taskRunner.waitFor(this.apiClient.deleteFile(fileDescriptorId, mediaType));
    }

    private setSelectedMediaType(id: number) {
        this.allowedExtensions = [""];
        this.props.viewModel.selectedMediaType = id;
        this.setAllowedExtensions(this.props.viewModel.selectedMediaType);
    }

    private setAllowedExtensions(mediaTypeId: number) {
        switch (mediaTypeId) {
            case LearningContentMediaType.Document: {
                this.allowedExtensions = [".docx", ".pptx", ".ppt", ".xlsx", ".xls", ".pdf"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.Image: {
                this.allowedExtensions = [".jpg", ".jpeg", ".png", ".JPG",];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.Sound: {
                this.allowedExtensions = [".MP3", ".MP4", ".wav", ".MPEG-4", ".mp4", ".mp3", ".ogg"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.Video: {
                this.allowedExtensions = [".avi", ".mov", ".qt", ".wmv", ".MTS", ".M2TS", ".TS", ".mp4", ".mp3", ".m4v"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.SCORM: {
                this.allowedExtensions = [".xml", ".zip"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
        }
    }

    private ShowFileExtensionsTypeBinding(mediaType: number) {
        if (mediaType !== LearningContentMediaType.Link
            && mediaType !== LearningContentMediaType.YouTube
            && this.props.learningContent.learningContent.allowedFileExtensions !== "") {
            return false;
        }
        else {
            return true;
        }
    }

    private async saveSelfAssessmentQuestion() {
        var selfAssessment = this.props.selfAssessmentVM.selfAssessment;
        var question = JSON.parse(this.props.selfAssessmentQuestion.text);
        if (question.blocks[0].text === null || question.blocks[0].text === '') {
            this.notifications.addDanger("Error", "Question is required");
            return;
        }

        this.props.selfAssessmentVM.newQuestionLearningContent = new LearningObjectContentLookup();
        this.props.selfAssessmentVM.newQuestionLearningContent.learningContent = new LearningContentCombinedObject();

        this.props.selfAssessmentQuestion.selfAssessmentId = selfAssessment.selfAssessmentId;
        this.props.selfAssessmentQuestion.selfAssessmentOptionList = this.props.selfAssessmentOptions;
        this.props.selfAssessmentQuestion.newLearningContent = this.props.learningContent;
        this.props.selfAssessmentQuestion.newLearningContent.learningContent.mediaType = this.props.learningContent.meta.mediaTypeFetched.value;
        var response = await this.selfAssessmentApiClient.SaveSelfAssessmentQuestion(this.props.selfAssessmentQuestion.toJSObject({ includeClean: true }));
        if (response.data.contentId === 0) {
            this.props.selfAssessmentQuestion.newLearningContent.learningContent = new LearningContentCombinedObject();
        }
        if (response.data) {
            this.props.selfAssessmentQuestion.set(response.data);
        }
        var responseDnd = await this.selfAssessmentApiClient.getSelfAssessmentQuestionDNDObjects(selfAssessment.selfAssessmentId);
        this.props.selfAssessmentVM.questionsDragAndDropList = responseDnd.data;
        this.props.selfAssessmentVM.selfAssessmentLookupPageManager.refreshData();

        this.props.selfAssessmentVM.setSelectedSelfAssesmentQuestion(this.props.selfAssessmentQuestion);
        this.notifications.addSuccess("Self Assessment Question ", "Self Assessment Question has been saved", 4);
    }

    public checkHiddenStateDownload() {
        // hidden if not in review
        var state = false;
        if (this.props.selectedLearningObject.moderationState === ModerationState.Review && this.props.learningContent.mediaTypeFetched === LearningContentMediaType.Link) {
            state = true;
        }
        else if (this.props.selectedLearningObject.moderationState === ModerationState.Review && this.props.learningContent.mediaTypeFetched === LearningContentMediaType.YouTube) {
            state = true;
        }
        else if (this.props.selectedLearningObject.moderationState === ModerationState.Review && (this.props.learningContent.mediaTypeFetched === LearningContentMediaType.Video
            || this.props.learningContent.mediaTypeFetched === LearningContentMediaType.Sound
            || this.props.learningContent.mediaTypeFetched === LearningContentMediaType.Image
            || this.props.learningContent.mediaTypeFetched === LearningContentMediaType.Document
            || this.props.learningContent.mediaTypeFetched === LearningContentMediaType.SCORM
        )) {
            state = false;
        }
        else {
            state = true;
        }
        return state;
    }

    public setHiddenStateLink() {
        var state = false;
        if (this.props.selectedLearningObject.moderationState === ModerationState.Review && this.props.learningContent.mediaTypeFetched === LearningContentMediaType.Link) {
            state = false;
        }
        else {
            state = true;
        }
        return state;
    }


    public setHiddenStateYoutube() {
        var state = false;
        if (this.props.selectedLearningObject.moderationState === ModerationState.Review && this.props.learningContent.mediaTypeFetched === LearningContentMediaType.YouTube) {
            state = false;
        }
        else {
            state = true;
        }
        return state;
    }

    public render() {
        const selfAssessmentQuestion = this.props.selfAssessmentQuestion;
        const learningContent = this.props.learningContent;
        var fileTitle = "Question Linked Resource";

        return (
            <div>
                <Card hidden={this.props.hidden}>
                    <CardHeader icon>
                        <CardIcon color="success">
                            <i className={`fas fa-file fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">{this.props.selfAssessmentVM.selfAssessmentQuestion.selfAssessmentQuestionId === 0 ? "New Self Assessment Question" : "Self Assessment Question: "}</h4>
                    </CardHeader>
                    <CardBody>
                        <section>
                            <RichTextEditor viewModel={this.props.selfAssessmentVM.richTextEditorVmSAQuestion} bind={selfAssessmentQuestion.meta.text} labelText={"Question"} />
                        </section>
                        <section>
                            {/*Create Question for Selected self Assessment*/}
                            {this.props !== null &&
                                <Neo.Form validationDisplayMode={ValidationDisplayMode.AfterBlur}>
                                    <Neo.GridLayout className="mb-10">
                                        <Neo.FormGroupFloating bind={selfAssessmentQuestion.meta.questionTypeId}
                                            select={{
                                                itemSource: Data.StaticDataSource.fromEnum(QuestionType),
                                                onItemSelected: (item) => {
                                                    this.props.viewModel.setSelectedQuestionType(item?.id as number, this.props.selfAssessmentVM.selfAssessment.selfAssessmentId)
                                                }
                                            }} disabled={true} /><br />
                                    </Neo.GridLayout>
                                    {
                                        (this.props.viewModel.selectedQuestionTypeId === (QuestionType.LikertScale as number)) &&
                                        <Neo.GridLayout>
                                            <Neo.FormGroupFloating bind={selfAssessmentQuestion.meta.sentiment}
                                                select={{
                                                    itemSource: Data.StaticDataSource.fromEnum(Sentiment),
                                                }} disabled={true} />
                                        </Neo.GridLayout>
                                    }
                                    {(this.props.viewModel.selectedQuestionTypeId === (QuestionType.LikertScale as number)) &&
                                        <Neo.GridLayout>
                                            <Neo.FormGroupFloating readOnly={true} label="Number Of Scales" formNoValidate bind={selfAssessmentQuestion.meta.numberOfScales} />
                                        </Neo.GridLayout>
                                    }
                                    <Neo.GridLayout>
                                        <Neo.FormGroupFloating label="Topic (Tag 3)*" select={{
                                            items: this.props.selfAssessmentVM.selfAssessmentQuestionFilteredTag3List,
                                            displayMember: "tag3Name",
                                            valueMember: "tag3Id",
                                            allowNulls: false
                                        }} formNoValidate bind={selfAssessmentQuestion.meta.tag3Id} /><br />
                                    </Neo.GridLayout>
                                    <Neo.GridLayout className="mb-10">
                                    </Neo.GridLayout>
                                    {
                                        (this.props.viewModel.selectedQuestionTypeId === (QuestionType.LikertScale as number)) &&
                                        <Neo.GridLayout md={1} lg={2}>
                                            <NeoGrid.Grid className="mb-20 mt-20" items={this.props.selfAssessmentOptions}>
                                                {(option, OptionMeta) => (
                                                    <NeoGrid.Row>
                                                        <NeoGrid.Column label="Option" width="400px" placeholder="Option" bind={OptionMeta.option} />
                                                        <NeoGrid.Column bind={OptionMeta.points} width="150px" disabled={true} />
                                                        <NeoGrid.ButtonColumn showDelete hidden={true} />
                                                    </NeoGrid.Row>
                                                )}
                                            </NeoGrid.Grid>
                                        </Neo.GridLayout>
                                    }

                                    {
                                        (this.props.viewModel.selectedQuestionTypeId === (QuestionType.MultipleChoice as number)) &&
                                        <Neo.GridLayout md={1} lg={2}>
                                            <NeoGrid.Grid showAddButton className="mb-20 mt-20" items={this.props.selfAssessmentOptions}>
                                                {(option, OptionMeta) => (
                                                    <NeoGrid.Row>
                                                        <NeoGrid.Column label="Option" width="400px" placeholder="Option" bind={OptionMeta.option} />
                                                        <NeoGrid.Column bind={OptionMeta.points} width="150px" />
                                                        <NeoGrid.ButtonColumn showDelete />
                                                    </NeoGrid.Row>
                                                )}
                                            </NeoGrid.Grid>
                                        </Neo.GridLayout>
                                    }
                                </Neo.Form>
                            }
                        </section>
                        <section>
                            <div hidden={this.props.selfAssessmentVM.selfAssessment.moderationState === ModerationState.Moderated}>
                                <Neo.Button onClick={() => this.AddLinkedResource()} disabled={selfAssessmentQuestion.contentId > 0} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success">
                                    Add Linked Resource
                                </Neo.Button>
                                {this.props.viewModel.showLinkedResource &&
                                    <Neo.Button onClick={() => this.CancelLinkedResource()} disabled={selfAssessmentQuestion.contentId > 0} isOutline className="mt-2 mr-2" icon="fa-danger" variant="success">
                                        Cancel Linked Resource
                                    </Neo.Button>
                                }
                                {!this.props.viewModel.showLinkedResource &&
                                    <>
                                        <Neo.Button onClick={() => this.saveSelfAssessmentQuestion()}
                                            disabled={selfAssessmentQuestion.tag3Id === 0 ||
                                                selfAssessmentQuestion.text.length === 0 ||
                                                this.props.selfAssessmentOptions.length === 0 ||
                                                this.props.selfAssessmentOptions.filter(c => c.option.length === 0).length > 0 ||
                                                this.props.selfAssessmentOptions.sum(c => c.points) === 0}
                                            isOutline className="mt-2 mr-2" icon="fa-save" variant="success">
                                            Update Question
                                        </Neo.Button>

                                        <Neo.Button onClick={() => this.props.selfAssessmentVM.showSelfAssessmentCard()} disabled={this.props.selfAssessmentVM.selectedSelfAssessmentId === 0} className="mt-2 mr-2"
                                            icon="fa-plus" variant="success" isOutline
                                            hidden={(selfAssessmentQuestion.isDirty || this.props.selfAssessmentVM.selfAssessment.selfAssessmentId === 0) && this.props.selfAssessmentVM.selfAssessment.moderationState !== ModerationState.Review}>
                                            Add Question
                                        </Neo.Button>
                                    </>
                                }
                                {
                                    !this.props.selfAssessmentVM.showAssessmentQuestionModerationNotes ||
                                    <Neo.Card className="moderation-notes" hidden={this.props.selfAssessmentVM.selfAssessment.moderationState !== ModerationState.Moderated}>
                                        <RichTextEditor bind={selfAssessmentQuestion.meta.selfAssessmentQuestionModerationNotes} viewModel={this.props.selfAssessmentVM.richTextEditorVmSAQuestionMN} labelText="Question Moderation Notes" readOnly={true} />
                                    </Neo.Card>
                                }
                                <Neo.Button hidden={this.props.selectedLearningObject.moderationState !== ModerationState.Review} text={!this.props.selfAssessmentVM.showAssessmentQuestionModerationNotes ? "Question Notes" : "Hide Question Notes"}
                                    isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.props.selfAssessmentVM.showAssessmentQuestionModerationNotes = !this.props.selfAssessmentVM.showAssessmentQuestionModerationNotes} />
                            </div>

                        </section>
                        <section>
                            {(this.props.viewModel.showLinkedResource === true || this.props.viewModel.fileDescriptorList.length > 0) &&
                                <div>
                                    <Card hidden={this.props.hidden}>
                                        <CardHeader icon>
                                            <CardIcon color="success">
                                                <i className={`fas fa-file fa-2x`}></i>
                                            </CardIcon>
                                            <h4 className="Card-icon-header-text">{fileTitle}</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <Neo.Form validationDisplayMode={ValidationDisplayMode.AfterBlur}>
                                                <Neo.GridLayout>
                                                    <Neo.FormGroupFloating bind={learningContent.learningContent.meta.title} /><b />
                                                    <Neo.FormGroupFloating bind={learningContent.meta.mediaTypeFetched} disabled={this.props.selfAssessmentQuestion.contentId > 0} label="Media Type"
                                                        select={{
                                                            itemSource: Data.StaticDataSource.fromEnum(LearningContentMediaType),
                                                            onItemSelected: (item) => {
                                                                this.setSelectedMediaType(learningContent.meta.mediaTypeFetched.value as number)
                                                            }
                                                        }}
                                                    /><b />
                                                    <Neo.FormGroupFloating label="Alternative URL" placeholder="www.urlExample.com" bind={learningContent.learningContent.meta.externalResourceLocation}
                                                        hidden={learningContent.learningContent.mediaType === LearningContentMediaType.YouTube
                                                        } /><b />
                                                    <Neo.FormGroupFloating label="URL Key" bind={learningContent.learningContent.meta.externalResourceLocationKey} hidden={learningContent.mediaTypeFetched !== LearningContentMediaType.YouTube} /><b />

                                                    <Neo.Button hidden={this.checkHiddenStateDownload()}
                                                        text={"Download"} isOutline icon={learningContent.learningContent.mediaObject?.icon} variant="info"
                                                        onClick={() => this.props.selfAssessmentVM.DownloadFileGet(learningContent.learningContent.mediaObject?.fileDescriptorId as string, learningContent.mediaTypeFetched as number, learningContent.learningContent.externalResourceLocation as string)} />
                                                    <Neo.Button hidden={this.setHiddenStateLink()}
                                                        text={"Open link"} isOutline icon="link" variant="info"
                                                        onClick={() => OpenLink(learningContent.learningContent.externalResourceLocation as string)} />
                                                    <Neo.Button tooltip="Click to open link in new window"
                                                        hidden={this.setHiddenStateYoutube()}
                                                        isOutline icon="link" variant="info" text="Open link" onClick={() => OpenLink(learningContent.learningContent.externalResourceLocationKey as string)} />
                                                </Neo.GridLayout>
                                            </Neo.Form>
                                            <Neo.FormGroupFloating hidden={this.ShowFileExtensionsTypeBinding(learningContent.learningContent.mediaType as number)}
                                                display={learningContent.learningContent.meta.allowedFileExtensions} /> <b />
                                            <Neo.FileContext
                                                uploadEndpoint={learningContent.learningContent.mediaType === LearningContentMediaType.Video ? this.apiClient.getUploadBigVideoFileToBlobUrl() : (learningContent.learningContent.mediaType === LearningContentMediaType.SCORM ? this.apiClient.getUploadBigSCORMFileToBlobUrl() : this.apiClient.getUploadFileToBlobUrl(learningContent.learningContent.mediaType as number))}
                                                afterUpload={this.afterUpload.bind(this)}
                                                allowedExtensions={this.allowedExtensions}>
                                                <Neo.FileDropArea className="mb-10" hidden={learningContent.learningContent.mediaType === LearningContentMediaType.Link
                                                    || learningContent.learningContent.mediaType === LearningContentMediaType.YouTube
                                                    || this.props.viewModel.fileDescriptorList.length !== 0
                                                    || selfAssessmentQuestion.contentId > 0}>
                                                    <Neo.FileUploadButton disabled={selfAssessmentQuestion.contentId > 0} >Select a file</Neo.FileUploadButton> or drop a file here.
                                                    <Neo.FileUploadProgress variant={"info"} />
                                                </Neo.FileDropArea>
                                            </Neo.FileContext>
                                            <Neo.GridLayout md={2} lg={3} className="mt-5">
                                                <NeoGrid.Grid className="mb-10 mt-10" items={this.props.viewModel.fileDescriptorList}>
                                                    {(file, fileMeta) => (
                                                        <NeoGrid.Row>
                                                            <NeoGrid.Column label="File Name" display={fileMeta.fileName} />
                                                            <NeoGrid.Column label="File Type" display={fileMeta.mimeType} />
                                                            {/* <NeoGrid.ButtonColumn showDelete onClick={() => this.deleteFile(fileMeta.fileName.value, fileMeta.fileDescriptorId.value)} /> */}
                                                        </NeoGrid.Row>
                                                    )}
                                                </NeoGrid.Grid>
                                            </Neo.GridLayout>
                                        </CardBody>
                                    </Card>
                                </div>
                            }
                            {
                                (!this.props.selfAssessmentVM.showAssessmentQuestionContentModerationNotes) ||
                                <Neo.Card className="moderation-notes">
                                    <RichTextEditor bind={learningContent.meta.selfAssessmentContentModerationNotes} viewModel={this.props.selfAssessmentVM.test} labelText="Content Moderation Notes" readOnly={true} />
                                </Neo.Card>
                            }
                            {(this.props.viewModel.showLinkedResource || this.props.viewModel.fileDescriptorList.length > 0) &&
                                <Neo.Button hidden={this.props.selectedLearningObject.moderationState === ModerationState.Moderated}
                                    onClick={() => this.saveSelfAssessmentQuestion()} disabled={this.props.selfAssessmentVM.selfAssessmentQuestionValid(selfAssessmentQuestion)}
                                    isOutline className="mt-2 mr-2" icon="fa-save" variant="success">
                                    Update Question
                                </Neo.Button>
                            }
                            {this.props.viewModel.showLinkedResource &&
                                <Neo.Button onClick={() => this.props.selfAssessmentVM.showSelfAssessmentCard()} disabled={this.props.selfAssessmentVM.selectedSelfAssessmentId === 0} className="mt-2 mr-2"
                                    icon="fa-plus" variant="success" isOutline
                                    hidden={this.props.selfAssessmentVM.selfAssessment.selfAssessmentId === 0 && this.props.selfAssessmentVM.selfAssessment.moderationState !== ModerationState.Review
                                        && this.props.selfAssessmentVM.selfAssessment.moderationState !== ModerationState.Moderated}>
                                    Add Question
                                </Neo.Button>
                            }
                            <Neo.Button hidden={this.props.selectedLearningObject.moderationState !== ModerationState.Review || (this.props.selectedLearningObject.moderationState === ModerationState.Review &&
                                !this.props.selfAssessmentVM.showAssessmentQuestionContentModerationNotes)} text={!this.props.selfAssessmentVM.showAssessmentQuestionContentModerationNotes ? "Content Notes" : "Hide Content Notes"}
                                isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2"
                                onClick={() => this.props.selfAssessmentVM.showAssessmentQuestionContentModerationNotes = !this.props.selfAssessmentVM.showAssessmentQuestionContentModerationNotes} />
                        </section>
                    </CardBody>
                </Card>
            </div>
        );
    }
}