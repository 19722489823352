
export enum ModerationState {
    Unknown = 1,
    Unmoderated = 2,
    SubmittedForModeration = 3,
    UnderModeration = 4,
    Review = 5,
    Moderated = 6,
    ResubmittedForModeration = 7,
    Deactivated = 8,
}