import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../ForumTypes';
import ForumLookup from '../../Models/Lookups/ForumLookup';

export interface IForumIntegrationQueryApiClient {

    /**
     * Get the conversation as a learning activity.
     * @param learningObjectId learningObjectId
     * @returns ForumLookup
     */
    getConversationForLearningActivity(learningObjectId: number): AxiosPromise<Model.PlainObject<ForumLookup>>;

    /**
     * Get the conversation for the learning module.
     * @param learningModuleId learningModuleId
     * @returns ForumLookup
     */
    getConversationForLearningModule(learningModuleId: number): AxiosPromise<Model.PlainObject<ForumLookup>>;

    /**
     * Get the conversation for the learning pathway.
     * @param learningPathwayId learningPathwayId
     * @returns ForumLookup
     */
    getConversationForLearningPathway(learningPathwayId: number): AxiosPromise<Model.PlainObject<ForumLookup>>;

    /** 
     * Get the conversation for a module within a learning pathway.
     * @param learningPathwayId learningPathwayId
     * @param learningModuleId learningModuleId
     * @returns ForumLookup
     */
    getModuleConversationForLearningPathway(learningPathwayId: number, learningModuleId: number): AxiosPromise<Model.PlainObject<ForumLookup>>;

    // Client only properties / methods
}

@injectable()
export default class ForumIntegrationQueryApiClient extends Data.ApiClientBase implements IForumIntegrationQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.ForumApi.ApiPath}/ForumIntegrationQuery`);
    }

    public getConversationForLearningActivity(learningObjectId: number): AxiosPromise<Model.PlainObject<ForumLookup>> {
        return this.axios.get(`${this.apiPath}/GetConversationForLearningActivity/${learningObjectId}`);
    }

    public getConversationForLearningModule(learningModuleId: number): AxiosPromise<Model.PlainObject<ForumLookup>> {
        return this.axios.get(`${this.apiPath}/GetConversationForLearningModule/learningModuleId?learningModuleId=${learningModuleId}`);
    }

    public getConversationForLearningPathway(learningPathwayId: number): AxiosPromise<Model.PlainObject<ForumLookup>> {
        return this.axios.get(`${this.apiPath}/GetConversationForLearningPathway/learningPathwayId?learningPathwayId=${learningPathwayId}`);
    }

    public getModuleConversationForLearningPathway(learningPathwayId: number, learningModuleId: number): AxiosPromise<Model.PlainObject<ForumLookup>> {
        return this.axios.get(`${this.apiPath}/GetModuleConversationForLearningPathway?learningPathwayId=${learningPathwayId}&learningModuleId=${learningModuleId}`);
    }

    // Client only properties / methods
}