import { Validation } from "@singularsystems/neo-core";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import CircuitLookup from "../Models/Lookups/CircuitLookup";
import PagerTextFilter from "../../App/Components/PagerTextFilter"
import CircuitComponentVM from "./CircuitsComponentVM"
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";

interface ICircuitComponentProps {
    viewModal: CircuitComponentVM;
}

@observer
export default class CircuitView extends React.Component<ICircuitComponentProps>{
    public render() {
        const viewModel = this.props.viewModal;
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fa fa-street-view fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">{viewModel.filterCircuitId ?
                                    "Selected Circuit: " + viewModel.circuitLookupPageManager.data.find(c => c.circuitId === viewModel.filterCircuitId)?.circuitName : "Circuit"}</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="row mb-2">
                                    <div className="col-md-10">
                                        <Neo.Button variant="success" isOutline icon="import"
                                            onClick={() => viewModel.showCircuitEditModal(0)} >Add New Circuit</Neo.Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <PagerTextFilter filterProperty={viewModel.criteria.meta.circuitName} pageManager={viewModel.circuitLookupPageManager} taskRunner={viewModel.taskRunner} label="Search for a Circuit" />
                                        <Neo.FormGroup bind={viewModel.criteria.meta.districtId}
                                            select={{
                                                itemSource: viewModel.districtList,
                                                allowNulls: true,
                                            }} label="Choose a District" placeholder="Filter by District"
                                            append={<Neo.Button tooltip="Clear Search" size="sm" tabIndex={-1} icon="eraser" onClick={() => viewModel.clearFilters()}></Neo.Button>}></Neo.FormGroup>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12">
                                        <Neo.Pager
                                            pageManager={viewModel.circuitLookupPageManager}
                                            pageControlProps={{
                                                firstText: "",
                                                prevText: "Prev",
                                                nextText: "Next",
                                                lastText: "",
                                                alignment: "center",
                                                // show the page controls even if the amount of items is less than a single page size
                                                autoHide: false,
                                                pageSizeLabel: "Show"
                                            }}>
                                            <NeoGrid.Grid<CircuitLookup> >
                                                {(circuit: CircuitLookup, circuitMeta) => (
                                                    <NeoGrid.Row className={viewModel.filterCircuitId === circuit.circuitId ? "selected-item-css" : "bg-none"}>
                                                        <NeoGrid.ButtonColumn>
                                                            <Neo.Button tooltip="Click to filter Schools by this Circuit" icon="fa-school" variant={viewModel.filterCircuitId === circuit.circuitId ? "info" : "success"} isOutline onClick={() => {
                                                                viewModel.setSelectedCircuit(circuit.circuitId);
                                                            }} />
                                                        </NeoGrid.ButtonColumn>
                                                        <NeoGrid.Column display={circuitMeta.circuitName} />
                                                        <NeoGrid.Column display={circuitMeta.description} />
                                                        <NeoGrid.Column display={circuitMeta.districtId} select={{ itemSource: viewModel.districtList }} />
                                                        <NeoGrid.ButtonColumn>
                                                            <Neo.Button icon="edit" isOutline variant="success" onClick={() => viewModel.showCircuitEditModal(circuit.circuitId)}>Edit</Neo.Button>
                                                            <Neo.Button icon="trash" variant="danger" onClick={() => viewModel.deleteCircuitPopUp(circuit.circuitName, circuit.circuitId)} />
                                                        </NeoGrid.ButtonColumn>
                                                    </NeoGrid.Row>
                                                )}
                                            </NeoGrid.Grid>
                                        </Neo.Pager>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>


                <Neo.Modal title={viewModel.selectedCircuitId !== 0 ? "Edit Circuit" : "Create Circuit"} size="xl"
                    show={viewModel.showEditModal}
                    formProps={{
                        model: viewModel.selectedCircuit,
                        validationDisplayMode: Validation.DisplayMode.Always,
                        showSummaryModal: true
                    }}
                    onClose={() => viewModel.closeEditModal()}
                    acceptButton={{
                        text: "Save", variant: "success", icon: "check",
                        onClick: () => viewModel.saveCircuit()
                    }} closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times"
                    }} >
                    {() => (viewModel.selectedCircuit &&
                        <div>
                            <Neo.GridLayout md={2} lg={3}>
                                <Neo.FormGroup bind={viewModel.selectedCircuit.meta.circuitName} autoFocus />
                                <Neo.FormGroup bind={viewModel.selectedCircuit.meta.description} autoFocus />
                                <Neo.FormGroup label="District" bind={viewModel.selectedCircuit.meta.districtId} select={{ itemSource: viewModel.districtList }} />
                            </Neo.GridLayout>
                        </div>
                    )}
                </Neo.Modal>
            </div>
        )
    }
}