import { Data } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../IdentityTypes';

export interface IOneTimePasswordApiClient {

    /** 
     * Generates a new OTP and emails or sms's to user.
     * @param request The generate otp criteria.
     * @returns ActionResult.
     */
    generateAndSendOTPAsync(userSelection: boolean, userGuid: string): AxiosPromise<any>;

    // Client only properties / methods
}

@injectable()
export default class OneTimePasswordApiClient extends Data.ApiClientBase implements IOneTimePasswordApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/OneTimePassword`);
    }

    public generateAndSendOTPAsync(userSelection: boolean, userGuid: string): AxiosPromise<any> {
        return this.axios.get(`${this.apiPath}/generate/${userSelection}/${userGuid}`);
    }

    // Client only properties / methods
}