import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../IdentityTypes';
import TermsAndConditionLookup from '../../Models/Lookups/TermsAndConditionLookup';

export interface ITermsAndConditionsAcceptApiClient {

    /** 
     * Gets the latest Terms and Conditions.
     * @returns TermsAndConditionLookup.
     */
    get(): AxiosPromise<Model.PlainObject<TermsAndConditionLookup>>;

    /** 
     * Accepts the latest terms and conditions on behalf of a user.
     * @returns TermsAndConditionLookup as result.
     */
    accept(): AxiosPromise<Model.PlainObject<TermsAndConditionLookup>>;

    /** 
     * Returns is the user has accepted the terms and conditions.
     * @returns bool
     */
    hasAccepted(): AxiosPromise<boolean>;

    // Client only properties / methods
}

@injectable()
export default class TermsAndConditionsAcceptApiClient extends Data.ApiClientBase implements ITermsAndConditionsAcceptApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/TermsAndConditionsAccept`);
    }

    public get(): AxiosPromise<Model.PlainObject<TermsAndConditionLookup>> {
        return this.axios.get(`${this.apiPath}/`);
    }

    public accept(): AxiosPromise<Model.PlainObject<TermsAndConditionLookup>> {
        return this.axios.put(`${this.apiPath}/accept`,null);
    }

    public hasAccepted(): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/has-accepted`);
    }

    // Client only properties / methods
}