import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import Circuit from '../../Models/Organisations/Circuit';
import CircuitLookup from '../../Models/Lookups/CircuitLookup';
import CircuitSearchCriteria from '../../Models/Criteria/CircuitSearchCriteria';

export interface ICircuitQueryApiClient {

    /** 
     * This method will return a list of circuits
     * @returns Circuit List with Children
     */
    getCircuitOnlyList(): AxiosPromise<Array<Model.PlainTrackedObject<Circuit>>>;

    /** 
     * This method will return a specific circuit based on the circuitId with children
     * @returns Circuit List with Children
     */
    getCircuitList(): AxiosPromise<Model.PlainTrackedObject<Circuit>>;

    /** 
     * This method will return a specific circuit based on the circuitId with children
     * @param circuitId The page request
     * @returns Circuit List with Children
     */
    getCircuit(circuitId: number): AxiosPromise<Model.PlainTrackedObject<Circuit>>;

    /** 
     * This method will return the circuits
     * @param request the paged search criteria request
     * @returns A collection of circuits that match the given criteria
     */
    getCircuitListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<CircuitSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<CircuitLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class CircuitQueryApiClient extends Data.ApiClientBase implements ICircuitQueryApiClient {
    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/CircuitQuery`);
    }

    public getCircuitOnlyList(): AxiosPromise<Array<Model.PlainTrackedObject<Circuit>>> {
        return this.axios.get(`${this.apiPath}/CircuitOnlyList`);
    }

    public getCircuitList(): AxiosPromise<Model.PlainTrackedObject<Circuit>> {
        return this.axios.get(`${this.apiPath}/Circuits`);
    }

    public getCircuit(circuitId: number): AxiosPromise<Model.PlainTrackedObject<Circuit>> {
        return this.axios.get(`${this.apiPath}/Circuit/${circuitId}`);
    }

    public getCircuitListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<CircuitSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<CircuitLookup>>> {
        return this.axios.get(`${this.apiPath}/CircuitsPaged?${Utils.getQueryString(request)}`);
    }

    public getCircuitByDistrict(districtId: number): AxiosPromise<Model.PartialPlainObject<Circuit>[]> {
        return this.axios.get(`${this.apiPath}/CircuitsByDistrict/${districtId}`);
    }

    public checkCircuitLinks(circuitId: number): AxiosPromise<Model.PartialPlainObject<Circuit>[]> {
        return this.axios.get(`${this.apiPath}/CheckCircuitLinks/${circuitId}`);
    }

    public UpdateCircuitDistrictId(circuitId: number, districtId: number): AxiosPromise<Model.PartialPlainObject<Circuit>[]> {
        return this.axios.get(`${this.apiPath}/UpdateDistrictProvince/${circuitId}/${districtId}`);
    }
}