import { Validation } from "@singularsystems/neo-core";
import { observer } from "mobx-react";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import React from "react";
import DistrictLookup from "../Models/Lookups/DistrictLookup";
import PagerTextFilter from "../../App/Components/PagerTextFilter"
import DistrictComponentVM from "./DistrictComponentVM"
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";

interface IDistrictComponentProps {
    viewModal: DistrictComponentVM;
}

@observer
export default class DistrictView extends React.Component<IDistrictComponentProps>{
    public render() {
        const viewModel = this.props.viewModal;

        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-map-marker-alt fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">{viewModel.filterDistrictId ?
                                    "Selected District: " + viewModel.districtLookupPageManager.data.find(c => c.districtId === viewModel.filterDistrictId)?.districtName : "Districts"}</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="row mb-2">
                                    <div className="col-md-10">
                                        <Neo.Button variant="success" isOutline icon="import"
                                            onClick={() => viewModel.showDistrictEditModal(0)} >Add New District</Neo.Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <PagerTextFilter filterProperty={viewModel.criteria.meta.districtName} pageManager={viewModel.districtLookupPageManager} taskRunner={viewModel.taskRunner} label="Search for a District" />
                                        <Neo.FormGroup bind={viewModel.criteria.meta.provinceId}
                                            select={{
                                                itemSource: viewModel.provinceList,
                                                allowNulls: true,
                                            }} label="Choose a Province" placeholder="Filter by Province"
                                            append={<Neo.Button tooltip="Clear Search" size="sm" tabIndex={-1} icon="eraser" onClick={() => viewModel.clearFilters()}></Neo.Button>}>
                                        </Neo.FormGroup>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12">
                                        <Neo.Pager
                                            pageManager={viewModel.districtLookupPageManager}
                                            pageControlProps={{
                                                firstText: "",
                                                prevText: "Prev",
                                                nextText: "Next",
                                                lastText: "",
                                                alignment: "center",
                                                // show the page controls even if the amount of items is less than a single page size
                                                autoHide: false,
                                                pageSizeLabel: "Show"
                                            }}>
                                            <NeoGrid.Grid<DistrictLookup> >
                                                {(district: DistrictLookup, districtMeta) => (
                                                    <NeoGrid.Row className={viewModel.filterDistrictId === district.districtId ? "selected-item-css" : "bg-none"}>
                                                        <NeoGrid.ButtonColumn>
                                                            <Neo.Button tooltip="Click to filter Circuits by this District" icon="fa-street-view" variant={viewModel.filterDistrictId === district.districtId ? "info" : "success"} isOutline onClick={() => {
                                                                viewModel.setSelectedDistrict(district.districtId);
                                                            }} />
                                                        </NeoGrid.ButtonColumn>
                                                        <NeoGrid.Column display={districtMeta.districtName} />
                                                        <NeoGrid.Column display={districtMeta.districtDetails} />
                                                        <NeoGrid.Column display={districtMeta.provinceId} select={{ itemSource: viewModel.provinceList }} />
                                                        <NeoGrid.ButtonColumn>
                                                            <Neo.Button icon="edit" isOutline variant="success" onClick={() => viewModel.showDistrictEditModal(district.districtId)}>Edit</Neo.Button>
                                                            <Neo.Button icon="trash" variant="danger" onClick={() => viewModel.deleteDistrictPopUp(district.districtName, district.districtId)} />
                                                        </NeoGrid.ButtonColumn>
                                                    </NeoGrid.Row>
                                                )}
                                            </NeoGrid.Grid>
                                        </Neo.Pager>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>

                <Neo.Modal title={viewModel.selectedDistrictId !== 0 ? "Edit District" : "Create District"} size="xl"
                    show={viewModel.showEditModal}
                    onClose={() => viewModel.closeEditModal()}
                    formProps={{
                        model: viewModel.selectedDistrict,
                        validationDisplayMode: Validation.DisplayMode.Always,
                        showSummaryModal: true
                    }}
                    acceptButton={{
                        text: "Save", variant: "primary", icon: "check",
                        onClick: () => viewModel.saveDistrict()
                    }} closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times"
                    }} >
                    {() => (viewModel.selectedDistrict &&
                        <div>
                            <Neo.GridLayout md={2} lg={3}>
                                <Neo.FormGroup bind={viewModel.selectedDistrict.meta.districtName} autoFocus />
                                <Neo.FormGroup bind={viewModel.selectedDistrict.meta.districtDetails} autoFocus />
                                <Neo.FormGroup label="Province" bind={viewModel.selectedDistrict.meta.provinceId} select={{ itemSource: viewModel.provinceList }} />
                            </Neo.GridLayout>
                        </div>
                    )}
                </Neo.Modal>
            </div>
        )
    }
}