import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import District from '../../Models/Organisations/District';
import DistrictLookup from '../../Models/Lookups/DistrictLookup';
import DistrictSearchCriteria from '../../Models/Criteria/DistrictSearchCriteria'
export interface IDistrictQueryApiClient {

    /** 
     * This method will return a list of districts without its children
     * @returns District list with children
     */
    getDistrictOnlyList(): AxiosPromise<Array<Model.PlainTrackedObject<District>>>;

    /**
     * This method will return a specific district with all its children
     * @returns District list with children
     */
    getDistrict(districtId: number): AxiosPromise<Model.PlainTrackedObject<District>>;


        /**
     * This method will return a specific district based on the districtId with all its children
     * @param districtId The page request
     * @returns District list with children
     */
    getDistricts(): AxiosPromise<Model.PlainTrackedObject<District>>;

    /** 
     * This method will return the districts
     * @param request the paged search criteria request
     * @returns A collection of districts that match the given criteria
     */
    getDistrictListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<DistrictSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<DistrictLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class DistrictQueryApiClient extends Data.ApiClientBase implements IDistrictQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/DistrictQuery`);
    }

    public getDistrictOnlyList(): AxiosPromise<Array<Model.PlainTrackedObject<District>>> {
        return this.axios.get(`${this.apiPath}/DistrictList`);
    }

    public getDistricts(): AxiosPromise<Model.PlainTrackedObject<District>> {
        return this.axios.get(`${this.apiPath}/Districts`);
    }

    public getDistrict(districtId: number): AxiosPromise<Model.PlainTrackedObject<District>> {
        return this.axios.get(`${this.apiPath}/District/${districtId}`);
    }

    public getDistrictsByProvince(provinceId: number): AxiosPromise<Array<Model.PlainObject<District>>> {
        return this.axios.get(`${this.apiPath}/DistrictsByProvince/${provinceId}`);
    }

    public checkDistrictLinks(districtId: number): AxiosPromise<Model.PartialPlainObject<District>[]> {
        return this.axios.get(`${this.apiPath}/CheckDistrictLinks/${districtId}`);
    }

    public getDistrictListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<DistrictSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<DistrictLookup>>> {
        return this.axios.get(`${this.apiPath}/DistrictsPaged?${Utils.getQueryString(request)}`);
    }

    public updateDistrictProvince(districtId: number, provinceId: number): AxiosPromise<Model.PartialPlainObject<District>[]> {
        return this.axios.get(`${this.apiPath}/UpdateDistrictProvince/${districtId}/${provinceId}`);
    }
}