import AppTypes from '../App/AppTypes';
import { AppService } from '../App/Services/AppService';

import { OrganisationExportedTypes } from './OrganisationExportedTypes';

const Types = {
    ...AppTypes,
    Organisation: {
        ApiClients: {
            ...OrganisationExportedTypes.ApiClients
        },
        Services: {
            ...OrganisationExportedTypes.Services,
        }
    }
}

export { AppService, Types }