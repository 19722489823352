import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../IdentityTypes';
import OrglevelUserCountLookup from '../../Models/Users/Lookups/OrglevelUserCountLookup';
import OrgLevelUserProfileSearchCriteria from '../../Models/Users/Lookups/OrgLevelUserProfileSearchCriteria';
import UserProfileLookup from '../../Models/Users/Lookups/UserProfileLookup';
import UserProfileLookupSearchCriteria from '../../Models/Users/Lookups/UserProfileLookupSearchCriteria';
import UserProfileSearchCriteria from '../../Models/Users/Lookups/UserProfileSearchCriteria';
import OrgLevel from '../../Models/Users/OrgLevel';

export interface IUserProfileQueryApiClient {

    /**
     * This method will return the educators.
     * @param userId The Id of the specific user to fetch.
     * @returns A collection of users that match the given criteria.
     */
    getRegisteredUser(userId: string): AxiosPromise<Model.PlainObject<UserProfileLookup>>;

    getRegisteredUsers(): AxiosPromise<Array<Model.PartialPlainObject<UserProfileLookup>>>;

    /**
     * This method will return the users at an org level.
     * @param guidList List of Guids.
     * @returns A collection of users that match the given criteria.
     */
    getRegisteredUsersByIdList(guidList: string[]): AxiosPromise<Array<Model.PlainObject<UserProfileLookup>>>;

    /**
     * This method will return the users.
     * @param request the paged search criteria request.
     * @returns A collection of users that match the given criteria.
     */
    getRegisteredUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<UserProfileSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserProfileLookup>>>;

    /** 
     * This method will return the users at or below the current user in the org level hierarchy.
     * Need to be an admin user.
     * @param request the paged search criteria request.
     * @returns A collection of users that match the given criteria.
     */
     getPagedRegisteredUserLookupAtCurrentUserOrgLevelAndBelowAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<UserProfileLookupSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserProfileLookup>>>;

    /**
     * This method will return the users at the organisation level.
     * @param request the paged search criteria request.
     * @returns A collection of users that match the given criteria.
     */
    getPagedRegisteredUserLookupAtOrgLevel(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<OrgLevelUserProfileSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserProfileLookup>>>;

    /**
     * This method will the state of the user verification.
     * @param userId the userId.
     * @returns bolean value
     */
    getUserVerificationState(userId: string): AxiosPromise<boolean>;

    getRegisteredUserCountAtOrgLevel(orgLevel: OrgLevel): AxiosPromise<Model.PlainObject<number>>

    getRegisteredUsersAtOrgLevel(orgLevel: OrgLevel): AxiosPromise<Array<Model.PartialPlainObject<UserProfileLookup>>>

    getRegisteredUserCountAtAllOrgLevels(): AxiosPromise<Array<Model.PartialPlainObject<OrglevelUserCountLookup>>>;

    /**
     * Returns the top 50 users where the user name or email address starts with the search text.
     * @param searchText Search text.
     * @returns List of user names.
     */
    findUsers(searchText: string): AxiosPromise<Array<Model.PlainObject<UserProfileLookup>>>;

    /**
     * Gets a list of admin users based on search
     * @param searchText Search text.
     * @returns List of user names.
     */
    findAdminUsers(searchText: string): AxiosPromise<Array<Model.PlainObject<UserProfileLookup>>>;

    getUserExcelExportToken(): AxiosPromise<string>;

    /** 
     * This method will return an excel export of user data.
     * @returns An excel export.
     */
    getUserExcelExport(token: string): string;

    // Client only properties / methods
}

@injectable()
export default class UserProfileQueryApiClient extends Data.ApiClientBase implements IUserProfileQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/UserProfileQuery`);
    }

    public getRegisteredUserCountAtOrgLevel(orgLevel: OrgLevel): AxiosPromise<Model.PlainObject<number>> {
        return this.axios.put(`${this.apiPath}/registeredusercountatorglevel`, orgLevel);
    }

    public getRegisteredUsersAtOrgLevel(orgLevel: OrgLevel): AxiosPromise<Array<Model.PartialPlainObject<UserProfileLookup>>> {
        return this.axios.put(`${this.apiPath}/registeredusersatorglevel`, orgLevel);
    }

    public getRegisteredUser(userId: string): AxiosPromise<Model.PlainObject<UserProfileLookup>> {
        return this.axios.get(`${this.apiPath}/RegisteredUser?userId=${encodeURIComponent(userId)}`);
    }

    public getRegisteredUsers(): AxiosPromise<Array<Model.PartialPlainObject<UserProfileLookup>>> {
        return this.axios.get(`${this.apiPath}/RegisteredUsers`);
    }

    public getRegisteredUsersByIdList(guidList: string[]): AxiosPromise<Array<Model.PlainObject<UserProfileLookup>>> {
        return this.axios.post(`${this.apiPath}/RegisteredUsersByIdList`, guidList);
    }

    public getRegisteredUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<UserProfileSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserProfileLookup>>> {
        return this.axios.get(`${this.apiPath}/RegisteredUsersPaged?${Utils.getQueryString(request)}`);
    }

    public getPagedRegisteredUserLookupAtCurrentUserOrgLevelAndBelowAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<UserProfileLookupSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserProfileLookup>>> {
        return this.axios.get(`${this.apiPath}/RegisteredUsersCurrentOrgLevelPaged?${Utils.getQueryString(request)}`);
    }

    public getPagedRegisteredUserLookupAtOrgLevel(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<OrgLevelUserProfileSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserProfileLookup>>> {
        return this.axios.get(`${this.apiPath}/registereduserspagedatotglevel?${Utils.getQueryString(request)}`);
    }

    public getUserVerificationState(userId: string): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/CheckUserVerification/${userId}`);
    }

    public getRegisteredUserCountAtAllOrgLevels(): AxiosPromise<Array<Model.PartialPlainObject<OrglevelUserCountLookup>>> {
        return this.axios.get(`${this.apiPath}/registeredusercountatallorglevels`);
    }

    public findUsers(searchText: string): AxiosPromise<Array<Model.PlainObject<UserProfileLookup>>> {
        return this.axios.get(`${this.apiPath}/findUsers?searchText=${encodeURIComponent(searchText)}`);
    }

    public findAdminUsers(searchText: string): AxiosPromise<Array<Model.PlainObject<UserProfileLookup>>> {
        return this.axios.get(`${this.apiPath}/findAdminUsers?searchText=${encodeURIComponent(searchText)}`);
    }
    public getUserExcelExportToken(): AxiosPromise<string>{
        return this.axios.get(`${this.apiPath}/getUserExcelExportToken`);
    }

    public getUserExcelExport(token: string): string {
        return (`${this.apiPath}/getUserExcelExport?token=${encodeURIComponent(token)}`);
    }

    // Client only properties / methods
}