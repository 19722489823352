import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import { SchoolSector } from "../../../Models/Organisations/Enums/SchoolSector.enum";
import { EnumHelper } from "@singularsystems/neo-core";
import { SchoolStatus } from "../../../Models/Organisations/Enums/SchoolStatus.enum";
import { ValidationDisplayMode } from "@singularsystems/neo-core/dist/Validation/Misc";
import { SchoolPhase } from "../../../Models/Organisations/Enums/SchoolPhase.enum";
import SchoolVM from "./SchoolVM";
import Card from "../../../../Template/components/Card/Card";
import CardHeader from "../../../../Template/components/Card/CardHeader";
import CardIcon from "../../../../Template/components/Card/CardIcon";
import CardBody from "../../../../Template/components/Card/CardBody";

class SearchSchoolViewParams {
    public searchString = { required: false, isQuery: true };
    public schoolGroupStartName = { required: false, isQuery: true };
    public schoolGroupEndName = { required: false, isQuery: true };
    public schoolGroupStartId = { required: false, isQuery: true };
    public schoolGroupEndId = { required: false, isQuery: true };
    public circuitId = { required: false, isQuery: true };
}

@observer
export default class SchoolView2 extends Views.ViewBase<SchoolVM, SearchSchoolViewParams>{
    constructor(props: unknown) {
        super("Schools", SchoolVM, props)
    }

    // Don't forget to add this static field to your view.
    public static params = new SearchSchoolViewParams();

    protected viewParamsUpdated() {
        this.viewModel.setCrit((this.viewParams.schoolGroupStartName.value as string), (this.viewParams.schoolGroupEndName.value as string), (this.viewParams.schoolGroupStartId.value as number), (this.viewParams.schoolGroupEndId.value as number), (this.viewParams.circuitId.value as number));
        if (this.viewParams.searchString.value !== "") {
            if (this.viewModel.criteria !== null) {
                this.viewModel.criteria.keyword = (this.viewParams.searchString.value as string);
            }
        }
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-school fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Schools</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="row ">
                                    <div className="col-md-12">
                                        <NeoGrid.Grid items={this.viewModel.schoolList}>
                                            {(school, schoolMeta) => (
                                                <NeoGrid.Row>
                                                    <NeoGrid.Column display={schoolMeta.schoolName} />
                                                    <NeoGrid.Column readOnly display={schoolMeta.schoolStatusId} select={{ items: EnumHelper.asList(SchoolStatus) }} />
                                                    <NeoGrid.Column display={schoolMeta.schoolSectorId} select={{ items: EnumHelper.asList(SchoolSector) }} />
                                                    <NeoGrid.Column display={schoolMeta.circuitId} select={{ itemSource: this.viewModel.circuitList }} />
                                                    <NeoGrid.ButtonColumn>
                                                        <Neo.Button icon="edit" isOutline variant="success" onClick={() => this.viewModel.showSchoolEditModal(school.schoolId)}>
                                                            Edit
                                                        </Neo.Button>
                                                        <Neo.Button icon="trash" variant="danger" onClick={() => this.viewModel.showDeleteModal(school.schoolId)} />
                                                    </NeoGrid.ButtonColumn>
                                                </NeoGrid.Row>
                                            )}
                                        </NeoGrid.Grid>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>

                <Neo.Modal title={this.viewModel.selectedSchoolId !== 0 ? "Edit School" : "Create School"} size="xl"
                    show={this.viewModel.showEditModal}
                    onClose={() => this.viewModel.closeEditModal()}
                    acceptButton={{
                        text: "Save", variant: "warning", icon: "check",
                        onClick: () => this.viewModel.saveSchool()
                    }} closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times"
                    }} >
                    <Neo.Loader task={this.viewModel.taskRunner}>
                        <Neo.Form model={this.viewModel.selectedSchool} validationDisplayMode={ValidationDisplayMode.Always}
                            showSummaryModal>
                            {(item, meta) => (
                                <div>
                                    <Neo.GridLayout md={2} lg={3}>
                                        <Neo.FormGroup bind={meta.schoolName} autoFocus />
                                        <Neo.FormGroup bind={meta.schoolSector} select={{ items: EnumHelper.asList(SchoolSector) }} />
                                        <Neo.FormGroup bind={meta.schoolStatus} select={{ items: EnumHelper.asList(SchoolStatus) }} />
                                        <Neo.FormGroup bind={meta.schoolPhase} select={{ items: EnumHelper.asList(SchoolPhase) }} />
                                        <Neo.FormGroup bind={meta.streetAddressLine1} />
                                        <Neo.FormGroup bind={meta.streetAddressLine2} />
                                        <Neo.FormGroup bind={meta.streetAddressLine3} />
                                        <Neo.FormGroup bind={meta.streetAddressPostalCode} />
                                        <Neo.FormGroup bind={meta.postalAddressLine1} />
                                        <Neo.FormGroup bind={meta.postalAddressLine2} />
                                        <Neo.FormGroup bind={meta.postalAddressLine3} />
                                        <Neo.FormGroup bind={meta.postalAddressPostalCode} />
                                        <Neo.FormGroup bind={meta.suburb} />
                                        <Neo.FormGroup bind={meta.townCity} />
                                        <Neo.FormGroup bind={meta.townshipVillage} />
                                        <Neo.FormGroup label="Circuit" bind={meta.circuitId} select={{ itemSource: this.viewModel.circuitList }} />
                                    </Neo.GridLayout>
                                </div>
                            )}
                        </Neo.Form>
                    </Neo.Loader>
                </Neo.Modal>

                <Neo.Modal title="Delete School" size="lg"
                    show={this.viewModel.showConfirmDeleteModal}
                    onClose={() => this.viewModel.showConfirmDeleteModal = false}
                    acceptButton={{
                        text: "Delete", variant: "danger", icon: "trash",
                        onClick: () => this.viewModel.deleteSchool()
                    }} closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times"
                    }} >
                    <Neo.Loader task={this.viewModel.taskRunner}>
                        <div>
                            <p>Please Confirm Delete</p>
                        </div>
                    </Neo.Loader>
                </Neo.Modal>
            </div>
        )
    }
}