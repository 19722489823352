import React from "react";
import { observer } from "mobx-react";
import Drawer from '@material-ui/core/Drawer';

interface IForumModalProps {
  show: boolean;
  onClose: (show: boolean) => void;
  isMaximised: boolean;
  maximiseWindow: () => void;
  anchor?: ("bottom" | "right" | "left" | "top")
}

@observer
export default class ForumDrawer extends React.Component<IForumModalProps>{
  public render() {
    const props = this.props;
    let window_class = this.props.isMaximised ? "Drawer-Maximised" : "Drawer-Minimised"
    return (
      <React.Fragment>
        <Drawer anchor={props.anchor ? props.anchor : "bottom"} open={props.show}>
          <div style={{ float: "right" }}>
            <i className="far fa-times-circle fa-2x " style={{ marginTop: 10, marginRight: 10 }} onClick={() => { props.onClose(props.show) }} />
            <i className="far fa-window-maximize fa-2x" style={{ float: "right" }} onClick={() => { props.maximiseWindow() }}></i>
          </div>
          <div className={window_class}>
            <div style={{ marginLeft: 30, marginRight: 30 }}>
              {props.children === null || props.children}
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    )
  }
}
