import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import React from 'react';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 50,
  },
  fabLeft: {
    position: 'fixed',
    bottom: theme.spacing(2),
    //  right: theme.spacing(2),
    zIndex: 50,
  },
}));

interface IFabButtonProps {
  onClick: () => void;
  hidden?: boolean;
  tooltip?: string
  icon: string;
  left?: boolean;
  color?: ("primary" | "secondary" | "default" | "inherit")
}

/// this is a sticky button
export default function FabButton(props: IFabButtonProps) {
  const classes = useStyles();
  return (
    <div hidden={props.hidden}>
      <Tooltip id="tooltip-top" title={props.tooltip === undefined ? "" : props!.tooltip} placement="top" >
        <Fab color={props.color === undefined ? "primary" : props.color} aria-label="add" className={props.left === true ? classes.fabLeft : classes.fab} onClick={() => props.onClick()} >
          <i className={`fa fa-${props.icon} fa-2x`} />
        </Fab>
      </Tooltip>
    </div>
  );
}