import { Attributes, ITaskRunner, List, LookupBase , NeoModel, Utils } from '@singularsystems/neo-core';
import LearningModuleObject from '../LearningModules/LearningModuleObject';
import { UserLearningStatus } from '../../Models/UserLearning/UsersLearningStatus.enum';
import LearningPathwayParticipantModule from './LearningPathways/LearningPathwayParticipantModule';
import { AppService } from '../../../Identity/IdentityTypes';
import Types from '../../../App/AppTypes';
import { ModerationState } from '../LearningObjects/ModerationState.enum';

@NeoModel
export default class LearningModuleLookup extends LookupBase {
    public learningModuleId: number = 0;

    public learningModuleTitle: string = "";

    public description: string = "";

    @Attributes.Date()
    public createdDate: Date = new Date();

    public createdBy: string = Utils.emptyGuid();

    public createdByUserInformation: string = "";

    public  moderatedByFullName: string = "";

    public learningModuleObjects = new List(LearningModuleObject);

    @Attributes.Integer()
    public order: number = 0;

    public status: UserLearningStatus = UserLearningStatus.Awaiting;

    @Attributes.Date()
    public completedDate: Date | null = null;

    @Attributes.Float()
    public lastQuizMark: number = 0;

    public learningPathwayId: number = 0;

    public tag3: string = "";

    public tag2: string = "";

    public tag1: string = "";

    public moderationState: ModerationState | null = null;

    public underMorderationBy: string | null = null;

    public userHasDoneModule: boolean = false;
    // Client only properties / methods

    @Attributes.Observable()
    @Attributes.OnChanged<LearningModuleLookup>(c => c.fetchPathwayParticipantModules)
    public isExpanded: boolean = false;

    @Attributes.ChildObject(LearningPathwayParticipantModule)
    public participantModule: List<LearningPathwayParticipantModule> = new List(LearningPathwayParticipantModule)

    private async fetchPathwayParticipantModules() {
        this.taskRunner.run(async () => {
            if (this.participantModule.length === 0) {
                const response = await AppService.get(Types.Learning.ApiClients.LearningPathwayQueryApiClient).getLearningPathwayParticipantModules(this.learningPathwayId, this.learningModuleId);
                if (response.data !== null) {
                    this.participantModule.update(response.data);
                }
            }
        })
    }
    
    // @Attributes.NoTracking()
    public taskRunner: ITaskRunner = AppService.get(Types.Neo.TaskRunner)
}

