/// to be deleted after testing
import { Data, List, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { NotificationDuration } from "../../../App/Models/Enums/NotificationDuration.enum";
import { AppService } from "../../../App/Services/AppService";
import { Types } from '../../../Identity/IdentityTypes'
import TDPUser from "../../../Identity/Models/Security/TDPUser";
import UserProfileLookup from "../../../Identity/Models/Users/Lookups/UserProfileLookup";
import { ForumIntegrationTypes } from "../../Models/Enums/ForumLearningTypes.enum";
import Post from "../../Models/Forums/Post";
import PostLookup from "../../Models/Lookups/PostLookup";
import PostSearchCriteria from "../../Models/Lookups/PostSearchCriteria";
import { ReportModalVM } from "./ReportModalView";

@NeoModel
export default class ConversationComponentVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private postUpdateApiClient = AppService.get(Types.Forum.ApiClients.PostUpdateApiClient),
    private postQueryApiClient = AppService.get(Types.Forum.ApiClients.PostQueryApiClient),
    private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
    public forumIntegrationQueryApiClient = AppService.get(Types.Forum.ApiClients.ForumIntegrationQueryApiClient)) {
    super(taskRunner);
    this.user = this.authenticationService.user;
    this.currentUser = new UserProfileLookup();
  }

  public disableEdit = true;
  public postCriteria = new PostSearchCriteria();
  public user: TDPUser | null = null;
  public currentUser: UserProfileLookup = new UserProfileLookup();

  public selectedPost: PostLookup | null = null;
  public postReplies = new List(PostLookup);
  public postBody = new Post();
  public replyBody = new Post();
  public selectedChildPost = new PostLookup();
  public childReplyBody = new Post();
  public deletedReason = ""

  public hideDeletePost = true;
  public hideReplyDeletePost = true;
  public HideMessages = true;
  public hideReply = true;
  public hideChildReply = true;
  public topicId = 0;
  public integrationType: ForumIntegrationTypes = ForumIntegrationTypes.Unspecified;

  public postPageManager = new Data.PageManager(this.postCriteria, PostLookup, this.postQueryApiClient.getPagedPostsByCriteriaAsync, {
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 30],
    sortBy: "createdOn",
    sortAscending: false,
    fetchInitial: false,
    initialTaskRunner: this.taskRunner,
  });

  public async initialise() {
    await this.getCurrentUser();
  }

  public async loadPosts(topicId: number) {
    this.topicId = topicId;
    this.postCriteria.topicId = topicId;
    await this.postPageManager.refreshData().then(() => {
      if (this.postPageManager.data.length > 0) {
        var firstPost = this.postPageManager.data[0];
        this.integrationType = firstPost.forumLearningType;
      }
      if (this.selectedPost !== null) {
        var post = this.postPageManager.data.find(c => c.postId === this.selectedPost!.postId);
        if (post) {
          this.selectedPost = post;
        }
      }
    });
  }

  public async getCurrentUser() {
    if (this.user !== null) {
      let response = await this.userQueryApiClient.getRegisteredUser(this.user?.guid);
      let userData = response.data;
      this.currentUser.set(userData);
    }
  }

  public setUserColor(userGuid: string) {
    return this.currentUser.userGuid === userGuid ? "#4daf50" : "#00B0F0";
  }
  public postId: number | number = 0;

  public async openMessage(postId: number) {
    this.postId = postId;
  }

  public async sendMessage(message: string, topicId: number) {
    this.taskRunner.run(async () => {
      if (message !== '') {
        var newPost = new PostLookup();
        newPost.body = message;
        newPost.isActive = true;
        newPost.topicId = topicId;
        newPost.createdByGuid = this.user!.guid
        await this.postUpdateApiClient.createPostAsync(newPost.toJSObject())
        this.postBody = new Post();
        await this.loadPosts(topicId);
        this.notifications.addSuccess("Message sent", ``, NotificationDuration.Standard);
      }
    });
  }

  public async sendReply(message: string, topicId: number) {
    this.taskRunner.run(async () => {
      if (message !== '') {
        var newPost = new PostLookup();
        newPost.body = message;
        newPost.isActive = true;
        newPost.topicId = topicId;
        newPost.parentId = this.selectedPost!.postId;
        newPost.createdByGuid = this.user!.guid
        await this.postUpdateApiClient.createPostAsync(newPost.toJSObject())
        this.postBody = new Post();
        this.replyBody = new Post();
        this.childReplyBody = new Post();
        this.hideReply = true;
        this.loadPosts(topicId);
        const response = await this.postQueryApiClient.getPostReplies(this.selectedPost!.postId, topicId);
        this.postReplies.set(response.data);
        this.HideMessages = false;
        this.notifications.addSuccess("Reply sent", `Message sent`, NotificationDuration.Standard);
      }
    });
  }

  public async sendChildReply(message: string, topicId: number) {
    this.taskRunner.run(async () => {
      if (message !== '') {
        var newPost = new PostLookup();
        newPost.body = message;
        newPost.isActive = true;
        newPost.topicId = topicId;
        newPost.parentId = this.selectedChildPost!.postId;
        newPost.createdByGuid = this.user!.guid
        await this.postUpdateApiClient.createPostAsync(newPost.toJSObject())
        this.childReplyBody = new Post();
        this.postBody = new Post();
        this.replyBody = new Post();
        this.hideChildReply = true;
        await this.loadPosts(topicId);
        const response = await this.postQueryApiClient.getPostReplies(this.selectedPost!.postId, topicId);
        this.postReplies.set(response.data);
        this.HideMessages = false;
        this.notifications.addSuccess("Reply sent", `Message sent`, NotificationDuration.Standard);
      }
    });
  }

  public getDayDifference = (postedDate: Date) => {
    let newDate = new Date()
    let date = newDate.getDate();
    return Math.floor((Math.abs(postedDate.getDate() - date)) / (1000 * 60 * 60 * 24));
  }

  public viewReplies = async (post: PostLookup, isReply: boolean) => {
    this.selectedPost = new PostLookup();
    this.HideMessages = true;

    this.postReplies = new List(PostLookup);
    if (post.hasChildren === true) {
      const response = await this.postQueryApiClient.getPostReplies(post.postId, this.topicId);
      this.postReplies.set(response.data);
      this.selectedPost = post;
      this.HideMessages = false;
    }
    else {
      this.selectedPost = post;
      this.hideReply = false;
    }
    this.hideReply = !isReply;
  }

  public reportModalVM = new ReportModalVM(this.taskRunner);
  public async reportPost(post: PostLookup) {
    this.reportModalVM.show(post);
  }

  public viewChildReplies = async (post: PostLookup) => {
    this.selectedChildPost = post;
    this.hideChildReply = false;
  }

  public delete = async (topicId: number) => {
    await this.taskRunner.run(async () => {
      await this.postUpdateApiClient.deletePostAsync(this.selectedPost!.toJSObject());
      this.selectedPost = null
      await this.loadPosts(topicId);
      this.hideDeletePost = true;
    }).then(() => {
      this.notifications.addSuccess("Post Deleted", `Post has been deleted`, NotificationDuration.Standard);
    })
  }

  public deleteReply = async (topicId: number) => {
    await this.taskRunner.run(async () => {
      await this.postUpdateApiClient.deletePostAsync(this.selectedChildPost!.toJSObject());
      this.selectedChildPost = new PostLookup();
      await this.loadPosts(topicId);
      const response = await this.postQueryApiClient.getPostReplies(this.selectedPost!.postId, topicId);
      this.postReplies.set(response.data);
      this.hideReplyDeletePost = true;
    }).then(() => {
      this.notifications.addSuccess("Post Deleted", `Post has been deleted`, NotificationDuration.Standard);
    })
  }

  public cancelDelete = () => {
    this.hideDeletePost = true;
    this.selectedPost = null
  }

  public showDeleteModal = (post: PostLookup) => {
    this.selectedPost = post;
    this.hideDeletePost = false;
  }

  public showReplyDeleteModal = (post: PostLookup) => {
    this.selectedChildPost = post;
    this.hideReplyDeletePost = false;
  }

  public selectForEdit = (post: PostLookup) => {
    this.selectedPost = post
    this.disableEdit = false;
  }

  public updatePost = async (post: PostLookup) => {
    this.taskRunner.run(async () => {
      const response = await this.postUpdateApiClient.updatePostAsync(post.toJSObject());
      if (response.data) {
        this.loadPosts(this.topicId);
      }
    }).then(() => {
      this.notifications.addSuccess("Post Updated", `Post has been updated`, NotificationDuration.Standard);
      this.disableEdit = true;
    })
  }
}