import { Attributes, List, ModelBase, NeoModel, Rules, Utils, Validation } from '@singularsystems/neo-core';
import UserSelfAssessmentQuestion from './UserSelfAssessmentQuestion';
@NeoModel
export default class UserSelfAssessment extends ModelBase {

  public userSelfAssessmentId: number = 0;

  public selfAssessmentId: number = 0;

  public userGuid: string = Utils.emptyGuid();

  @Attributes.Date()
  public completedDate: Date = new Date();

  public userSelfAssessmentQuestions = new List(UserSelfAssessmentQuestion);

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.userGuid) {
      return "New user self assessment";
    } else {
      return this.userGuid;
    }
  }
}