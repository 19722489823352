import React from "react";
import { observer } from "mobx-react";
import Card from '../../Template/components/Card/Card';
import CardBody from '../../Template/components/Card/CardBody';
import CardFooter from '../../Template/components/Card/CardFooter';
import CardAvatar from '../../Template/components/Card/CardAvatar';
import { Neo } from "@singularsystems/neo-react";
import GridContainer from '../../Template/components/Grid/GridContainer';
import GridItem from '../../Template/components/Grid/GridItem';

interface IBadgeAwardModalProps {
  badgeAwardMessage: string;
  badgeAwardDescription: string;
  badgeTitle: string;
  earnedDate?: string;
  imageSrc: string;
  onClose: () => void;
  showBadgeAward: boolean;
}

@observer
export default class BadgeAwardModal extends React.Component<IBadgeAwardModalProps> {
  render() {
    return (
      <div onClick={(event) => this.props.onClose()}>
        <Neo.Modal title="" size="lg" className={"badge-Award-Modal"}
          show={this.props.showBadgeAward}
          onClose={() => this.props.onClose()}
          closeButton={false}
          acceptButton={false}>
          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card testimonial className="testimonial-Badge">
                  <div style={{ marginTop: 30 }} >
                    <i className=" fa fa-award fa-3x"></i>
                    <h4 className="card-award-description">{this.props.badgeTitle}</h4>
                  </div>
                  <CardBody>
                    <h5 className="card-award-title">
                      {this.props.badgeAwardMessage}
                    </h5>
                  </CardBody>
                  <CardFooter testimonial>
                    <h6 className="card-award-title">{this.props.badgeAwardDescription}</h6>
                    <CardAvatar profile testimonialFooter className="badge-square-modal">
                      <img src={this.props.imageSrc} alt="..." onClick={() => this.props.onClose()}/>
                    </CardAvatar>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </Neo.Modal >

      </div>
    )
  }
}