import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import Types from '../../../../App/AppTypes';
import CircuitComponentVM from '../../../Components/CircuitsComponentVM';
import DistrictComponentVM from '../../../Components/DistrictComponentVM';
import SchoolComponentVM from '../../../Components/SchoolComponentVM';
import ProvinceComponentVM from '../../../Components/ProvinceComponentVM';
import { SchoolManagementTeamComponentVM } from '../../../Components/SMTsComponent';
import OrganisationsComponentVM from '../../../Components/OrganisationComponentVM';
import { AppService } from '../../../OrganisationTypes';
import { OrgMaintenanceTypes } from './OrgMaintenanceView';

@NeoModel
export default class OrgMaintenanceVM extends Views.ViewModelBase {

    public selectedTab = OrgMaintenanceTypes.organisations;
    public circuitVM: CircuitComponentVM;
    public districtVM: DistrictComponentVM;
    public schoolVM: SchoolComponentVM;
    public provinceVM: ProvinceComponentVM;
    public schoolManagementTeamVM: SchoolManagementTeamComponentVM;
    public organisationVM: OrganisationsComponentVM;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner)) {
        super(taskRunner);
        this.circuitVM = new CircuitComponentVM(this.taskRunner);
        this.districtVM = new DistrictComponentVM(this.taskRunner);
        this.schoolVM = new SchoolComponentVM(this.taskRunner);
        this.provinceVM = new ProvinceComponentVM(this.taskRunner);
        this.schoolManagementTeamVM = new SchoolManagementTeamComponentVM(this.taskRunner);
        this.organisationVM = new OrganisationsComponentVM(this.taskRunner);
    }

    public async initialise() {
    }
}