import { AppServices } from '@singularsystems/neo-core';
import { IUserProfileQueryApiClient } from './ApiClients/Queries/UserProfileQueryApiClient';
import { IUserProfileApiClient } from './ApiClients/Maintenance/UserProfileApiClient';

import { ISACEEducatorImportApiClient } from './ApiClients/Imports/SACEEducatorImportApiClient';
import { ISACEEducatorQueryApiClient } from './ApiClients/Queries/SACEEducatorQueryApiClient';
import { IPersalEducatorImportApiClient } from './ApiClients/Imports/PersalEducatorImportApiClient';
import { IPersalEducatorQueryApiClient } from './ApiClients/Queries/PersalEducatorQueryApiClient';
import { ITitleCommandApiClient } from './ApiClients/Maintenance/TitleCommandApiClient';
import { ITitleQueryApiClient } from './ApiClients/Queries/TitleQueryApiClient';
import { IRaceQueryApiClient } from './ApiClients/Queries/RaceQueryApiClient';
import { IRaceCommandApiClient } from './ApiClients/Maintenance/RaceCommandApiClient';
import { IUserDataImportApiClient } from './ApiClients/Imports/UserDataImportApiClient';
import { IOneTimePasswordApiClient } from './ApiClients/Security/OneTimePasswordApiClient';
import { IAuthenticationService } from '../Identity/Services/Security/OidcAuthenticationService';
import { ITermsAndConditionsAcceptApiClient } from '../Identity/ApiClients/TermsAndConditions/TermsAndConditionsAcceptApiClient';
import { ITermsAndConditionsAdminApiClient } from '../Identity/ApiClients/TermsAndConditions/TermsAndConditionsAdminApiClient';
import { IIntroductionReadApiClient } from '../Identity/ApiClients/Introduction/IntroductionReadApiClient';
import { IIntroductionAdminApiClient } from '../Identity/ApiClients/Introduction/IntroductionAdminApiClient';
import { ISystemsSettingsQueryApiClient } from './ApiClients/SystemSettings/Query/SystemsSettingsQueryApiClient';
import { ISystemSettingsCommandApiClient } from './ApiClients/SystemSettings/Command/SystemSettingsCommandApiClient';

// Symbols to expose outside of this module
export const IdentitySharedTypes = {
    ApiClients: {
        UserProfileQueryApiClient: new AppServices.ServiceIdentifier<IUserProfileQueryApiClient>("Identity.ApiClients.UserProfileQueryApiClient"),
        UserProfileApiClient: new AppServices.ServiceIdentifier<IUserProfileApiClient>("Identity.ApiClients.UserProfileApiClient"),
        SACEEducatorImportApiClient: new AppServices.ServiceIdentifier<ISACEEducatorImportApiClient>("Identity.ApiClients.SACEEducatorImportApiClient"),
        SACEEducatorQueryApiClient: new AppServices.ServiceIdentifier<ISACEEducatorQueryApiClient>("Identity.ApiClients.SACEEducatorQueryApiClient"),

        PersalEducatorImportApiClient: new AppServices.ServiceIdentifier<IPersalEducatorImportApiClient>("Identity.ApiClients.PersalEducatorImportApiClient"),
        PersalEducatorQueryApiClient: new AppServices.ServiceIdentifier<IPersalEducatorQueryApiClient>("Identity.ApiClients.PersalEducatorQueryApiClient"),

        UserDataImportApiClient: new AppServices.ServiceIdentifier<IUserDataImportApiClient>("Identity.ApiClients.UserDataImportApiClient"),

        TitleCommandApiClient: new AppServices.ServiceIdentifier<ITitleCommandApiClient>("Identity.ApiClients.TitleCommandApiClient"),
        TitleQueryApiClient: new AppServices.ServiceIdentifier<ITitleQueryApiClient>("Identity.ApiClients.TitleQueryApiClient"),
        RaceQueryApiClient: new AppServices.ServiceIdentifier<IRaceQueryApiClient>("Identity.ApiClients.RaceQueryApiClient"),
        RaceCommandApiClient: new AppServices.ServiceIdentifier<IRaceCommandApiClient>("Identity.ApiClient.RaceCommandApiClient"),

        OneTimePasswordApiClient: new AppServices.ServiceIdentifier<IOneTimePasswordApiClient>("Identity.ApiClient.OneTimePasswordApiClient"),
        TermsAndConditionsAcceptApiClient: new AppServices.ServiceIdentifier<ITermsAndConditionsAcceptApiClient>("Identity.ApiClient.TermsAndConditionsAcceptApiClient"),
        TermsAndConditionsAdminApiClient: new AppServices.ServiceIdentifier<ITermsAndConditionsAdminApiClient>("Identity.ApiClient.TermsAndConditionsAdminApiClient"),
        IntroductionReadApiClient: new AppServices.ServiceIdentifier<IIntroductionReadApiClient>("Identity.ApiClient.IntroductionReadApiClient"),
        IntroductionAdminApiClient: new AppServices.ServiceIdentifier<IIntroductionAdminApiClient>("Identity.ApiClient.IntroductionAdminApiClient"),
        SystemsSettingsQueryApiClient: new AppServices.ServiceIdentifier<ISystemsSettingsQueryApiClient>("Identity.ApiClient.SystemsSettingsQueryApiClient"),
        SystemsSettingsCommandApiClient: new AppServices.ServiceIdentifier<ISystemSettingsCommandApiClient>("Identity.ApiClient.SystemsSettingsCommandApiClient"),
    },
    Services: {
        TDPAuthenticationService: AppServices.NeoTypes.Security.AuthenticationService.asType<IAuthenticationService>(),
    },
    ViewModels: {
    },
    Components: {
    }
}