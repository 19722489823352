import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'
import Province from '../../Models/Organisations/Province';

export interface IProvinceApiClient extends Data.IUpdateableApiClient<Province, number> {

    // Client only properties / methods
}

@injectable()
export default class ProvinceApiClient extends Data.UpdateableApiClient<Province, number> implements IProvinceApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/Province`);
    }

    // Client only properties / methods
}