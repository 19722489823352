import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class LearningObjectTagLookup extends ModelBase {

    public learningObjectTag3Id: number = 0;

    public learningModuleId: number = 0;    

    public learningObjectId: number = 0;
    
    @Rules.Required()
    public tag3Id: number = 0;

    public tag3Name: string = "";

    public tag2Id: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.tag3Name) {
            return "New learning object tag lookup";
        } else {
            return this.tag3Name;
        }
    }
}