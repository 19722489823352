import { Attributes } from '@singularsystems/neo-core';
import { QuestionType } from '../Questions/QuestionType.enum';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';
import { ModelBase, Validation } from '@singularsystems/neo-core';
import LearningObjectContentLookup from './LearningObjectContentLookup';

export default class QuestionLookupBase extends ModelBase {

  constructor() {
    super()
    this.questionTypeId = QuestionType.NotSet;
  }  
  // public questionId: number = 0;

  @Attributes.Display("Question")
  public text: string = "";

  public textHtml: string = "";

  public questionOrder: number = 0;

  @Required("Please choose a Question Type")
  public questionTypeId: QuestionType;

  public questionType: String = "";

  public contentId: number = 0;

  //@Attributes.ChildObject(LearningObjectContentLookup)
  public newLearningContent: LearningObjectContentLookup = new LearningObjectContentLookup();

  @Attributes.Display("Linked To Resource")
  public linkedToResource: string = ""

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.failWhen(c => c.text === '', "Question text required.");
  }
}