import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ChangePasswordLookup extends ModelBase {   
    public currentPassword: string = ""; 
    
    public newPassword: string = ""; 

    // Client only properties / methods    

}