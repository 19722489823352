import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { LearningContentMediaType } from '../LearningObjects/LearningContent/LearningContentMediaType.enum';
import MediaObject from '../LearningObjects/LearningContent/MediaObject';

@NeoModel
export default class ContentConsumptionLookup extends ModelBase {

  public learningContentId: number = 0;

  public learningObjectId: number = 0;

  public title: string = "";

  @Attributes.Integer()
  public orderNumber: number = 0;


  public mediaType: LearningContentMediaType | null = null;

  @Attributes.ChildObject(MediaObject)
  public mediaObject: MediaObject | null = null;

  public externalResourceLocation: string = "";

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.title) {
      return "New content consumption lookup";
    } else {
      return this.title;
    }
  }
}