import { Attributes, NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class CircuitSearchCriteria extends ValueObject {

    public circuitId: number = 0

    public circuitName: string = "";

    public description: string = "";

    public districtId: number = 0;

    public ids: number[] | null = null;

    @Attributes.Integer()
    public pageIndex: number = 0;

    public keyword: string = "";

    @Attributes.Integer()
    public pageSize: number = 10;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Circuit Search Criteria";
    }
}