import { Attributes, List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import { BadgeType } from './BadgeType.enum';
import { BadgeAction } from './BadgeAction.enum.';
import { BadgeSubAction } from './BadgeSubAction.enum';
import BadgeModule from './BadgeModule';
import BadgeLearningPathway from './BadgeLearningPathway';
import MediaObject from '../LearningObjects/LearningContent/MediaObject';

@NeoModel
export default class BadgeLookup extends ModelBase {

    public badgeId: number = 0;

    public badgeType: BadgeType | null = null;

    @Rules.Required()
    public badgeTitle: string = "";

    @Rules.Required()
    public badgeDescription: string = "";

    public badgeAwardMessage: string = "";

    @Attributes.Date()
    public createdDate: Date | null = new Date();

    public emailNotification: boolean = false;

    public badgeApproved: boolean = false;

    public activeBadge: boolean = false;

    @Rules.Required()
    public badgeAction: BadgeAction | null = null;

    public badgeSubAction: BadgeSubAction | null = null;

    @Attributes.Integer()
    public parameter: number = 0;

    public badgeModules = new List(BadgeModule);

    public badgeLearningPathways = new List(BadgeLearningPathway);

    @Rules.Required()
    @Attributes.ChildObject(MediaObject)
    public mediaObject: MediaObject | null = null;

    public badgeImageName: string = "";

    // Client only properties / methods

    public blobUrl: string = "";
}