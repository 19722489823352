import { Attributes, List, NeoModel } from '@singularsystems/neo-core';
import ModeratableBase from '../Moderation/ModeratableBase';
import { ModeratableIntField, ModeratableLongStringField, ModeratableShortStringField } from '../Moderation/ModeratableFieldValues';
import LearningModuleObject from './LearningModuleObject';

@NeoModel
export default class LearningModule extends ModeratableBase {

    constructor() {
        super()
        this.title = new ModeratableShortStringField();
        this.description = new ModeratableLongStringField();
        this.learningModuleObjects = new List(LearningModuleObject);
        this.cptdPoints = new ModeratableIntField();
    }

    public learningModuleId: number = 0;

    @Attributes.ChildObject(ModeratableShortStringField)
    public title: ModeratableShortStringField;

    @Attributes.ChildObject(ModeratableLongStringField)
    public description: ModeratableLongStringField;

    public learningModuleObjects = new List(LearningModuleObject);
    

    @Attributes.Display("Continuous Professional Teacher Development Points")
    @Attributes.ChildObject(ModeratableIntField)
    public cptdPoints: ModeratableIntField;


    // Client only properties / methods
}