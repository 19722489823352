import { Data, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService } from "../../App/Services/AppService";
import { Types } from '../../Forum/ForumTypes'
import PostLookup from '../Models/Lookups/PostLookup';
import PostSearchCriteria from '../Models/Lookups/PostSearchCriteria';

@NeoModel
export default class ReportedPostsVM extends Views.ViewModelBase {

    public criteria = new PostSearchCriteria();
    public posts = new PostLookup();
    public selectedPost = new PostLookup();
    public clearModal: boolean = false;
    public deleteModal: boolean = false;
    public deletedReason = new PostLookup();

    public topicId = 0
    public showConversationDrawer = false;
    public isMaximised = false;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private postApiClient = AppService.get(Types.Forum.ApiClients.PostQueryApiClient),
        private postUpdateApiClient = AppService.get(Types.Forum.ApiClients.PostUpdateApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);
    }

    public postPageManager = new Data.PageManager(this.criteria, PostLookup, this.postApiClient.getReportedPosts, {
        pageSize: 10,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sortBy: "createdOn",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner,
    });

    public clearPostModal(post: PostLookup) {
        this.selectedPost = post;
        this.clearModal = true;
    }

    public async clearPost() {
        this.selectedPost.isReported = false;
        await this.postUpdateApiClient.clearReportOnPostAsync(this.selectedPost.toJSObject());
        this.postPageManager.refreshData();
        this.clearModal = false;
    }

    public deletePostModal(post: PostLookup) {
        this.selectedPost = post;
        this.deleteModal = true;
    }

    public async deletePost(reason: string) {
        this.selectedPost.deletedReason = reason;
        await this.postUpdateApiClient.deletePostAsync(this.selectedPost.toJSObject());
        this.postPageManager.refreshData();
        this.deleteModal = false;
        this.deletedReason.meta.deletedReason.value = '';
    }

    public showConversation = async (post: PostLookup) => {
        this.topicId = post.topicId;
        this.showConversationDrawer = true;
    }

    public CloseDrawer() {
        this.showConversationDrawer = false;
    }
}