import { Data, List, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../App/Services/AppService";
import Province from "../Models/Organisations/Province";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum"
import { OrgMaintenanceTypes } from "../Views/Maintenance/Organisations/OrgMaintenanceView";
import DropDownModel from "../../App/Models/Demo/DropDownModel";

@NeoModel
export default class ProvinceComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private provinceApiClient = AppService.get(Types.Organisations.ApiClients.ProvinceApiClient),
        private organisationApiClient = AppService.get(Types.Organisations.ApiClients.OrganisationApiClient),
        private provinceQueryApiClient = AppService.get(Types.Organisations.ApiClients.ProvinceQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    //Properties
    public provinceList = new List(Province);
    public enableEdit: boolean = false;
    public organisationList = new Data.ApiClientDataSource(this.organisationApiClient.get)
    public selectedTab = OrgMaintenanceTypes.organisations;
    public selectedProvinceId: number = 0;
    public organisationDropDown = new DropDownModel();

    //Methods

    public async SetCriteria(organisationId: number) {
        this.organisationDropDown.selectedItem = organisationId;
        this.LoadProvinceList(this.organisationDropDown.selectedItem)
    }

    public async LoadProvinceList(organisationId: number) {
        if (organisationId === 0) {
            const getProvinceListResponse = (await this.taskRunner.waitFor(this.provinceApiClient.get()));
            this.provinceList.set(getProvinceListResponse.data);
        }
        else {
            const getProvinceListResponse = (await this.taskRunner.waitFor(this.provinceApiClient.get()));
            this.provinceList.set(getProvinceListResponse.data.filter(c => c.organisationId === organisationId));
        }
    }

    public async saveProvinces() {
        this.taskRunner.run(async () => {
            const response = await this.provinceApiClient.saveList(this.provinceList.toJSArray());
            this.provinceList.update(response.data)
            this.notifications.addSuccess("Provinces saved", null, NotificationDuration.Short);
        })
        this.enableEdit = false;
    }

    public async deleteProvince(provinceId: number) {
        this.taskRunner.run(async () => {
            await this.organisationApiClient.delete(provinceId);
            this.LoadProvinceList(0);
            this.notifications.addSuccess("Province Deleted", null, NotificationDuration.Short);
        })
    }

    public async deleteProvincePopUp(provinceName: string, provinceId: number) {
        const response = await this.taskRunner.waitFor(this.provinceQueryApiClient.checkProvinceLinks(provinceId));
        if (response.data) {
            ModalUtils.showMessage("Cannot Delete " + provinceName, "This province has districts linked to it and cannot be deleted", 3)
        }
        else {
            await ModalUtils.showYesNoDismissible("Delete Province " + provinceName,
                "Are you sure you want to delete this Province? ", () => this.deleteProvince(provinceId))
        }
    }

    public async setSelectedProvince(provinceId: number) {
        // Unselect Province
        if (this.selectedProvinceId === provinceId) {
            this.selectedProvinceId = 0;
        }
        // Select Province
        else if (provinceId) {
            this.selectedProvinceId = provinceId;
        } else {
            this.selectedProvinceId = 0
        }
    }

    public clearFilters() {
        this.organisationDropDown.selectedItem = 0;
        this.LoadProvinceList(0);
    }
}