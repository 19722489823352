import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import ReportedPostsVM from './ReportedPostsVM';
import { observer } from 'mobx-react';
import PostLookup from '../Models/Lookups/PostLookup';
import RichTextEditor from '../../Learning/Components/RichTextEditor';
import GridContainer from '../../Template/components/Grid/GridContainer';
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import CardIcon from '../../Template/components/Card/CardIcon';
import GridItem from '../../Template/components/Grid/GridItem';
import CardBody from '../../Template/components/Card/CardBody';
import ForumDrawer from '../Components/ForumComponents/ForumDrawer';
import ROConversationComponent from '../Components/ForumComponents/ROConversationComponent';

@observer
export default class ReportedPostsView extends Views.ViewBase<ReportedPostsVM> {

    constructor(props: unknown) {
        super("Reported Posts", ReportedPostsVM, props);
    }

    public render() {
        return (
            <div id="Main-div">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="rose">
                                    <i className={`icon fas fa-flag fa-2x`} />
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Reported Posts</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} >
                                        <Neo.Pager
                                            pageManager={this.viewModel.postPageManager}
                                            pageControlProps={{
                                                firstText: "",
                                                prevText: "Prev",
                                                nextText: "Next",
                                                lastText: "",
                                                alignment: "center",
                                                autoHide: false,
                                                pageSizeLabel: "Show"
                                            }}>
                                            {Post => (
                                                <NeoGrid.Grid<PostLookup> className="table-hover" keyProperty="postId">
                                                    {(post: PostLookup, postMeta) => (
                                                        <NeoGrid.Row key={post.postId}>
                                                            <NeoGrid.Column>
                                                                <RichTextEditor bind={postMeta.body} readOnly />
                                                            </NeoGrid.Column>
                                                            <NeoGrid.Column display={postMeta.postedBy} />
                                                            <NeoGrid.Column display={postMeta.reportedBy} />
                                                            <NeoGrid.Column display={postMeta.createdOn} />
                                                            <NeoGrid.ButtonColumn>
                                                                <Neo.Button icon="eye" tooltip="View Conversation" onClick={() => this.viewModel.showConversation(post)} />
                                                                <Neo.Button tooltip="Clear Report on Post" onClick={() => this.viewModel.clearPostModal(post)}>Clear</Neo.Button>
                                                                <Neo.Button tooltip="Delete Reported Post" variant="danger" style={{ backgroundColor: "#f93e3e !important" }} onClick={() => this.viewModel.deletePostModal(post)}>Delete</Neo.Button>
                                                            </NeoGrid.ButtonColumn>
                                                        </NeoGrid.Row>
                                                    )}
                                                </NeoGrid.Grid>
                                            )}
                                        </Neo.Pager>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                <Neo.Modal title={"Clear Post"} size="lg"
                    show={this.viewModel.clearModal}
                    acceptButton={{
                        text: "Yes", variant: "success", icon: "check", isOutline: true,
                        onClick: () => this.viewModel.clearPost()
                    }}
                    onClose={() => { this.viewModel.clearModal = false; }}
                    closeButton={{
                        text: "No", variant: "warning", icon: "times", isOutline: true,
                    }}
                >
                    <div className="container">
                        <p>Are you sure you want to clear the report on this post </p>
                    </div>
                </Neo.Modal>

                <Neo.Modal title={"Delete Post"} size="lg"
                    show={this.viewModel.deleteModal}
                    acceptButton={{
                        text: "Yes", variant: "success", icon: "check", isOutline: true,
                        onClick: () => this.viewModel.deletePost(this.viewModel.deletedReason.meta.deletedReason.value)
                    }}
                    onClose={() => { this.viewModel.deleteModal = false; }}
                    closeButton={{
                        text: "No", variant: "warning", icon: "times", isOutline: true,
                    }}
                >
                    <div className="container">
                        <p>Are you sure you would like to remove this post? </p>
                        <p>Please provide a reason: </p>
                        <Neo.Textbox bind={this.viewModel.deletedReason.meta.deletedReason}></Neo.Textbox>
                    </div>
                </Neo.Modal>
                {this.viewModel.topicId === 0 ||
                    <ForumDrawer show={this.viewModel.showConversationDrawer} onClose={() => this.viewModel.CloseDrawer()} isMaximised={this.viewModel.isMaximised} maximiseWindow={() => this.viewModel.isMaximised = !this.viewModel.isMaximised}>
                        <ROConversationComponent topicId={this.viewModel.topicId} title="Conversation" show={this.viewModel.meta.showConversationDrawer} />
                    </ForumDrawer>
                }
            </div>
        );
    }
}