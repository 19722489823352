import { Routing as NeoRouting } from '@singularsystems/neo-core';
import { Routing } from '@singularsystems/neo-react';
import { injectable } from 'inversify';
import { AppService, Types } from './AppService';
import Home from '../Views/Security/Home';
import NotFound from '../Components/404NotFound';
import LoggedOut from '../Views/Security/LoggedOut';
import OidcLoginRedirect from '../Views/Security/OidcLoginRedirect';
import OidcSilentLoginRedirect from '../Views/Security/SilentSignInRedirect';
import { OrgAdminRoutes } from '../../Organisations/OrganisationRoutes';
import { UserAdminRoutes } from '../../Identity/IdentityRoutes';
import { MaintenanceLearningRoutes } from '../../Learning/LearningRoutes';
import SchoolView2 from '../../Organisations/Views/Maintenance/Organisations/SchoolView';
import { AuthorisationMaintenance } from '../../Authorisation/AuthorisationModule';
import LearningPathwayView from '../../Learning/Views/LearningPathways/LearningPathwayView';
import PathwayModuleConsumptionView from '../../Learning/Views/ContentConsumption/PathwayModuleConsumptionView';
import SelfAssessmentConsumptionView from '../../Learning/Views/ContentConsumption/SelfAssements/SelfAssessmentConsumptionView';
import ModuleConsumptionView from '../../Learning/Views/ContentConsumption/ModuleConsumptionView';
import ChangePasswordView from '../../Identity/Views/ChangePasswordView';
import UserProfileView from '../../Identity/Views/UserProfileView';
import { ReportingRoute, ReportingPureRoutes } from '../../Reporting/ReportingModule'
import PreviewLearningObjectView from '../../Learning/Views/ContentConsumption/PreviewLearningObjectView';
import ReviewSelfAssessmentView from '../../Learning/Views/Assessment/ReviewSelfAssessmentView';
import { DashboardRoutes } from '../../Dashboard/DashboardRoutes';
import { UserTypes } from '../Models/Enums/UserTypes.enums';
import LoadTestAnalyserView from '../Views/LoadTestAnalyserView';

export interface IAppRoute extends NeoRouting.IMenuRoute {
    userType?: UserTypes;
    children?: IAppRoute[];
}

@injectable()
export class RouteService {

    private routeProvider: Routing.RouteProvider;
    constructor(private config = AppService.get(Types.App.Config)) {

        this.routeProvider = new Routing.RouteProvider(
            this.getMenuRoutes(),
            this.getPureRoutes(),
            NotFound,
        )
    }

    /**
     * Gets the routes provider
     */
    public get routes(): Routing.RouteProvider {
        return this.routeProvider;
    }

    private getMenuRoutes(): IAppRoute[] {
        return [
            {
                name: "Home", path: '/', component: Home, icon: "home",
            },
            {
                ...DashboardRoutes
            },
            {
                name: "My Learning Pathways", path: "/Learning/Pathways", component: LearningPathwayView, icon: "road", allowAnonymous: false, userType: UserTypes.Verified
            },
            {
                name: "Administration", icon: "folder", allowAnonymous: false,
                children: [
                    //...SecurityRoute,
                    ...AuthorisationMaintenance,
                    ...UserAdminRoutes,
                    ReportingRoute,
                    // demoMenuItem
                ]
            },
            {
                name: "Org Admin", icon: "wrench", allowAnonymous: false, userType: UserTypes.Admin,
                children: [
                    ...OrgAdminRoutes
                ]
            },
            {
                name: "Tag Admin", icon: "tag", allowAnonymous: false, userType: UserTypes.Admin,
                children: [
                    MaintenanceLearningRoutes
                ]
            },
        ]
    }

    private getPureRoutes(): NeoRouting.IRoute[] {
        return [
            {
                path: "/OidcLoginRedirect", component: OidcLoginRedirect, allowAnonymous: true
            },
            {
                path: "/OidcSilentLoginRedirect", component: OidcSilentLoginRedirect, allowAnonymous: true
            },
            {
                path: "/loggedout", component: LoggedOut, allowAnonymous: true
            },
            {
                path: "/Schools2", component: SchoolView2, allowAnonymous: true
            },
            {
                path: "/Learning/ModuleContentConsumption", component: PathwayModuleConsumptionView, allowAnonymous: false,
            },

            {
                path: "/Learning/SelfAssessmentConsumption/", component: SelfAssessmentConsumptionView, allowAnonymous: false,
            },
            {
                path: "/Learning/ModuleConsumptionView/", component: ModuleConsumptionView, allowAnonymous: false,
            },
            {
                path: "/Identity/ChangePassword/", component: ChangePasswordView, allowAnonymous: false,
            },
            {
                name: "Profile", path: '/Identity/Profile', component: UserProfileView, allowAnonymous: false
            },
            {
                path: "/Learning/PreviewLearningObject/", component: PreviewLearningObjectView, allowAnonymous: false,
            },
            {
                path: "/Learning/ReviewAssessment/", component: ReviewSelfAssessmentView, allowAnonymous: false,
            },
            {
                path: "/loadtesttools", component: LoadTestAnalyserView, allowAnonymous: true
            },
            ...ReportingPureRoutes
        ]
    }
}