import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration.enum';
import { AppService, Types } from '../../../../Identity/IdentityTypes';
import { RichTextEditorVM } from '../../../Components/RichTextEditor';
import SelfAssessmentConsumptionLookup from '../../../Models/ContentCosumption/SelfAssessmentConsumptionLookup';
import LearningModule from '../../../Models/LearningModules/LearningModule';
import UserSelfAssessment from '../../../Models/UserLearning/UserSelfAssessment'
import UserSelfAssessmentQuestion from '../../../Models/UserLearning/UserSelfAssessmentQuestion';

@NeoModel
export default class SelfAssessmentConsumptionVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private contentConsumptionApiClient = AppService.get(Types.Learning.ApiClients.ContentConsumptionQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  public selfAssessmentId = 0;
  public selfAssessment = new SelfAssessmentConsumptionLookup();
  public richTextVm = new RichTextEditorVM(this.taskRunner);
  public questionCount = 0;
  public currentQuestionIndex = 0;
  public showSuggestions = false;
  public suggestedModuleList = new List(LearningModule);

  public GetSelfAssesment = async (id: number) => {
    var response = await this.taskRunner.waitFor(this.contentConsumptionApiClient.getSelfAssessment(id));
    if (response.data) {
      this.selfAssessment.set(response.data);
      this.questionCount = this.selfAssessment.selfAssessmentQuestions.length;
    }
  }

  public userSelfAssessment = new UserSelfAssessment();

  public allQuestionsCompleted = false;
  public SaveSelfAssessment = () => this.taskRunner.run(async () => {
    var assessment = this.selfAssessment;
    // Check that all questions have been answered
    var questionCount = assessment.selfAssessmentQuestions.length;
    var answerCount = 0;
    assessment.selfAssessmentQuestions.forEach(question => {
      question.selfAssessmentOptions.forEach(options => {
        if (options.answer) {
          answerCount++;
        }
      });
    });

    if (questionCount !== answerCount) {
      this.notifications.addDanger("Please answer all questions", `There are ${questionCount - answerCount} unanswered questions`, NotificationDuration.Standard);
      return
    } else {
      this.allQuestionsCompleted = true;
    }

    // Setup user selfAssessment Model for save.
    var userSelfAssessment = new UserSelfAssessment();
    this.userSelfAssessment = new UserSelfAssessment();
    userSelfAssessment.selfAssessmentId = assessment.selfAssessmentId;
    userSelfAssessment.completedDate = new Date();
    assessment.selfAssessmentQuestions.forEach((question) => {
      var questionInfo = new UserSelfAssessmentQuestion();
      questionInfo.selfAssessmentQuestionId = question.selfAssessmentQuestionId;
      questionInfo.tag3Id = question.tag3.tag3Id;
      question.selfAssessmentOptions.forEach((option) => {
        if (option.answer) {
          questionInfo.option = option.option;
          questionInfo.optionValue = option.points;
        }
      });
      userSelfAssessment.userSelfAssessmentQuestions.push(questionInfo);
    });
    const response = await this.contentConsumptionApiClient.saveUserAssesment(userSelfAssessment.toJSObject());

    if (response.data) {
      this.notifications.addSuccess("Self Assessment Completed", '', NotificationDuration.Standard);
      userSelfAssessment.set(response.data);
      this.userSelfAssessment.set(response.data)
      // suggest some modules based on assessment score;
      var suggestedModules = await this.contentConsumptionApiClient.getSuggestedModules(userSelfAssessment.toJSObject());
      if (suggestedModules.data) {
        this.suggestedModuleList.set(suggestedModules.data);
        this.showSuggestions = true;
      }
    }
    return userSelfAssessment;
  }).catch((ex: string) => { this.notifications.addDanger("Failed to save Self Assessment answers", `${ex}`, NotificationDuration.Standard); });;
}
