import { NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { NotificationDuration } from "../../../App/Models/Enums/NotificationDuration.enum";
import Card from "../../../Template/components/Card/Card";
import CardBody from "../../../Template/components/Card/CardBody";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardIcon from "../../../Template/components/Card/CardIcon";
import GridContainer from "../../../Template/components/Grid/GridContainer";
import GridItem from "../../../Template/components/Grid/GridItem";
import { AppService, Types } from "../../IdentityTypes";
import UpdateNotificationSettingsCommand from "../../Models/SystemSettings/Commands/UpdateNotificationSettingsCommand";
import NotificationSettings from "../../Models/SystemSettings/NotificationSettings";

@NeoModel
export class NotificationSettingComponentVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private systemSettingsQueryApiClient = AppService.get(Types.Identity.ApiClients.SystemsSettingsQueryApiClient),
    private systemsSettingsCommandApiClient = AppService.get(Types.Identity.ApiClients.SystemsSettingsCommandApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
    this.GetNotificationSettings();
  }

  public notificationSettings = new NotificationSettings();

  public async GetNotificationSettings() {
    const response = await this.taskRunner.waitFor(this.systemSettingsQueryApiClient.getNotificationSettings());
    if (response.data) {
      this.notificationSettings.set(response.data);
    }
  }

  public async save() {
    this.taskRunner.run(async () => {
      var command = new UpdateNotificationSettingsCommand();
      await this.systemsSettingsCommandApiClient.updateNotificationSettings(command.mapFrom(this.notificationSettings).toJSObject());
      this.notifications.addSuccess("Saved", "", NotificationDuration.Standard)
    });
  }
}

@observer
export default class NotificationSettingComponent extends React.Component {
  public viewModel = new NotificationSettingComponentVM();
  constructor(props: any) {
    super(props);
    this.viewModel = new NotificationSettingComponentVM();
  }

  public render() {
    const vm = this.viewModel;
    return (
      <GridContainer>
        <GridItem md={6}>
          <Card>
            <CardHeader>
              <CardIcon color="success">
                <i className="fa-fw fa fa-bell fa-2x" />
              </CardIcon>
              <h4 className="Card-icon-header-text">Notification Setting</h4>
              <p>Manage the systems notification settings </p>
            </CardHeader>
            <CardBody>

              <GridContainer>
                <GridItem md={6}>
                  <Neo.FormGroupFloating bind={vm.notificationSettings.meta.systemEmailAddress} />
                </GridItem>
                <GridItem md={6}>
                  <Neo.FormGroupFloating bind={vm.notificationSettings.meta.systemWhatsAppNumber} />
                </GridItem>
                <GridItem md={12}>
                  <Neo.FormGroup bind={vm.notificationSettings.meta.allowEmailVerification} />
                </GridItem>
                <GridItem md={12}>
                  <Neo.FormGroup bind={vm.notificationSettings.meta.allowSmsVerification} />
                </GridItem>
                <GridItem md={9} />
                <GridItem md={3}>
                  <Neo.Button text="Save" icon="save" variant="success" onClick={() => vm.save()} />
                </GridItem>
              </GridContainer>

            </CardBody>
          </Card>

        </GridItem>
      </GridContainer>
    )
  }

}