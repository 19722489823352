import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import SelfAssessmentConsumptionVM from './SelfAssessmentConsumptionVM';
import { observer } from 'mobx-react';
import RichTextEditor from '../../../Components/RichTextEditor';
import ModuleConsumptionView from '../ModuleConsumptionView';
import Card from '../../../../Template/components/Card/Card';
import CardHeader from '../../../../Template/components/Card/CardHeader';
import CardIcon from '../../../../Template/components/Card/CardIcon';
import CardBody from '../../../../Template/components/Card/CardBody';
import ReviewSelfAssessmentView from '../../Assessment/ReviewSelfAssessmentView';

class SelfAssesmentConstumptionViewParams {
  public selfAssementId = { required: true, isQuery: true };
}

@observer
export default class SelfAssessmentConsumptionView extends Views.ViewBase<SelfAssessmentConsumptionVM, SelfAssesmentConstumptionViewParams> {

  constructor(props: unknown) {
    super("Self Assessment Consumption", SelfAssessmentConsumptionVM, props);
  }

  // Don't forget to add this static field to your view.
  public static params = new SelfAssesmentConstumptionViewParams();

  protected async viewParamsUpdated() {
    this.viewModel.selfAssessmentId = this.viewParams.selfAssementId.value as number;
    await this.viewModel.GetSelfAssesment(this.viewModel.selfAssessmentId);
  }

  private GoToModuleView = (id: number) => {
    this.navigation.navigateToView(ModuleConsumptionView, { moduleId: id, preview: 1 });
  }

  public ReviewSelfAssessment = async () => {
    await this.viewModel.SaveSelfAssessment();
    if (this.viewModel.allQuestionsCompleted) {
      this.navigation.navigateToView(ReviewSelfAssessmentView, { userAssessmentId: this.viewModel.userSelfAssessment.userSelfAssessmentId, });
    }
  }

  public render() {
    return (
      <div>
        <div>
          <Card>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`fa-fw fa fa-clipboard-check fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">Questionnaires</h4>
            </CardHeader>
            <CardBody>
              <div className="row">
                <div className="col-md-12">
                  <h1>Welcome to: {this.viewModel.selfAssessment.title} </h1>
                </div>
                <div className="col-md-12">
                  <span className="mt-2" style={{ fontSize: "15pt" }}>Description: {this.viewModel.selfAssessment.description}</span>
                </div>
              </div>
              <div>
                <Card>
                  <CardHeader icon>
                    <CardIcon color="success">
                      <i className="fas fa-question fa-2x"></i>
                    </CardIcon>
                    <h4 className="Card-icon-header-text">Questions</h4>
                  </CardHeader>
                  <CardBody>
                    <h3>Question {this.viewModel.currentQuestionIndex + 1} of {this.viewModel.questionCount}</h3>
                    {
                      !this.viewModel.selfAssessment.selfAssessmentQuestions.length ||
                      <div>
                        <RichTextEditor labelText="" viewModel={this.viewModel.richTextVm} bind={this.viewModel.selfAssessment.selfAssessmentQuestions[this.viewModel.currentQuestionIndex].meta.question} readOnly key="questions" />
                        <NeoGrid.Grid items={this.viewModel.selfAssessment.selfAssessmentQuestions[this.viewModel.currentQuestionIndex].selfAssessmentOptions}>
                          {(option, optionMeta) => (
                            <NeoGrid.Row>
                              <NeoGrid.Column label="Options" display={optionMeta.option} />
                              <NeoGrid.Column label="Your answer" bind={optionMeta.answer} onChange={() => {
                                this.viewModel.selfAssessment.selfAssessmentQuestions[this.viewModel.currentQuestionIndex].selfAssessmentOptions.forEach(element => {
                                  element.answer = false;
                                });
                                optionMeta.answer.value = !optionMeta.answer.value
                              }} />
                            </NeoGrid.Row>
                          )}
                        </NeoGrid.Grid>

                        <div className="row">
                          <div className="col-md-12 mt-2" >
                            <div style={{ float: 'left' }} hidden={this.viewModel.selfAssessment.selfAssessmentQuestions[this.viewModel.currentQuestionIndex].contentId ? false : true}>
                              <Neo.Button text="View Content" icon="eye" variant="warning" isOutline className="mr-2" />
                            </div>
                            <div style={{ float: 'right' }}>
                              <Neo.Button text="Previous" hidden={this.viewModel.currentQuestionIndex === 0} icon="backward" variant="warning" isOutline className="mr-2" onClick={() => { this.viewModel.currentQuestionIndex -= 1 }} />
                              <Neo.Button text="Next" hidden={this.viewModel.currentQuestionIndex + 1 === this.viewModel.questionCount} icon="forward" isOutline variant="success" onClick={() => { this.viewModel.currentQuestionIndex += 1 }} />
                              <Neo.Button text="Complete" hidden={this.viewModel.currentQuestionIndex + 1 !== this.viewModel.questionCount} icon="save" isOutline variant="success" onClick={() => this.ReviewSelfAssessment()} />
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </CardBody>
                </Card>
              </div>
            </CardBody>
          </Card>
        </div>

        <Neo.Modal title="Assessment done" size="xl"
          show={this.viewModel.showSuggestions}
          acceptButton={false}
          onClose={() => this.viewModel.showSuggestions = false}>
          <div hidden={this.viewModel.suggestedModuleList.length !== 0}>
            <span>There are NO resources associated with your identified needs</span>
          </div>
          <div hidden={this.viewModel.suggestedModuleList.length === 0}>
            <span>Here are some Module suggestions based on your answers</span>
            <NeoGrid.Grid items={this.viewModel.suggestedModuleList}>
              {(module, moduleMeta) => (
                <NeoGrid.Row>
                  <NeoGrid.Column label="Module" display={module.title.meta.value} />
                  <NeoGrid.Column label="Objective" bind={module.description.meta.value} />
                  <NeoGrid.ButtonColumn>
                    <Neo.Button text="Go to Module" isOutline onClick={() => this.GoToModuleView(module.learningModuleId)} />
                  </NeoGrid.ButtonColumn>
                </NeoGrid.Row>
              )}
            </NeoGrid.Grid>
          </div>
        </Neo.Modal>
      </div>
    );
  }
}