import { ModelBase, NeoModel, Attributes, EnumHelper } from "@singularsystems/neo-core";

export enum EnumItems {
    Zero = 0,
    One = 1,
    Two = 2
}

// User the enum helper to change the enum item display and description values, or the order of each item.
EnumHelper.decorateEnum(EnumItems, e => {
    e.describe(EnumItems.Zero, "None");
});

@NeoModel
export default class DropDownModel extends ModelBase {

    @Attributes.Integer()
    public selectedItem: number | null = null;

    @Attributes.Display("Enum item")
    public selectedItemEnum: number | null = 0;

    public country: number | null = null;

    public isSell: boolean = false;
}