import { Attributes, DateUtils, List, Misc, Model, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import Tag2 from '../../../../Learning/Models/Tags/Tag2';
import Tag3 from '../../../../Learning/Models/Tags/Tag3';
import Circuit from '../../../../Organisations/Models/Organisations/Circuit';
import District from '../../../../Organisations/Models/Organisations/District';
import Province from '../../../../Organisations/Models/Organisations/Province';
import School from '../../../../Organisations/Models/Organisations/School';
import SchoolManagementTeam from '../../../../Organisations/Models/Organisations/SchoolManagementTeam';

@NeoModel
export default class ModulesSelectedCriteria extends Model.CriteriaBase {

    @Attributes.Integer()
    @Attributes.Nullable()
    @Attributes.Display("Teacher Name")
    public userId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public organisationId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public provinceId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public districtId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public circuitId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public schoolId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public schoolManagementTeamId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public categoryId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public subCategoryId: number | null = null;

    @Attributes.Integer()
    @Attributes.Nullable()
    public topicId: number | null = null;

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public startDate: Date = DateUtils.monthStart(new Date());

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public endDate: Date = new Date();  

    // Client only properties / methods

    @Attributes.NoTracking()
    public userName: string = "";
    
    @Attributes.NoTracking()
    public CategoryName: string = '';

    @Attributes.NoTracking()
    public filteredProvinceList: List<Province> = new List(Province);

    @Attributes.NoTracking()
    public filteredDistrictList: List<District> = new List(District);

    @Attributes.NoTracking()
    public filteredCircuitList: List<Circuit> = new List(Circuit);

    @Attributes.NoTracking()
    public filteredSchoolList: List<School> = new List(School);

    @Attributes.NoTracking()
    public filteredSchoolManagementTeamList: List<SchoolManagementTeam> = new List(SchoolManagementTeam);

    @Attributes.NoTracking()
    public tagTwoList: List<Tag2> = new List(Tag2);

    @Attributes.NoTracking()
    public tagThreeList: List<Tag3> =  new List(Tag3);

    @Attributes.NoTracking()
    public OrganisationDisabled: boolean = false;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Modules Selected Criteria";
    }
}