import { Data, List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { Types } from '../../Identity/IdentityTypes';
import { AppService as IDSAppService } from '../../Identity/CommonIdentityTypes';
import UserProfileLookup from '../../Identity/Models/Users/Lookups/UserProfileLookup';
import { NotificationDuration } from '../../App/Models/Enums/NotificationDuration';
import { Moderation } from '../Models/Enums/ModerationRoles.enum';
import AuthorisationTypes from '../AuthorisationTypes';
import UserRoleSearchCriteria from '../Models/Criteria/UserRoleSearchCriteria';
import UserWithGuidLookup from '../Models/Lookups/UserWithGuidLookup';
import UserModerationTag2Lookup from '../../Learning/Models/Lookups/UserModerationTag2Lookup';
import Tag2 from '../../Learning/Models/Tags/Tag2';
import Tag1 from '../../Learning/Models/Tags/Tag1';
import Tag1SearchCriteria from '../../Learning/Models/Criteria/Tag1SearchCriteria';

@NeoModel
export default class UserModerationMaintenanceVM extends Views.ViewModelBase {

    public userProfile: UserProfileLookup;
    public userTags = new List(UserModerationTag2Lookup);
    public criteria = new UserRoleSearchCriteria();
    public selectedUserGuid: string = "";
    public showEditModal: boolean = false;
    public editModalIsMaximised: boolean = true;
    public moderationTag2FilteredList = new List(Tag2);
    public tagsNoModerator = new List(Tag1);
    public tagCriteria = new Tag1SearchCriteria();

    public showUserList: boolean = false;
    public showTags: boolean = false;

    constructor(
        taskRunner = IDSAppService.get(Types.Neo.TaskRunner),
        private notifications = IDSAppService.get(Types.Neo.UI.GlobalNotifications),
        private userRoleApiClient = IDSAppService.get(AuthorisationTypes.ApiClients.UserRoleApiClient),
        private moderationQueryApiClient = IDSAppService.get(Types.Learning.ApiClients.ModerationQueryApiClient),
        private userQueryApiClient = IDSAppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        public tag1QueryApiClient = IDSAppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
        public tag2QueryApiClient = IDSAppService.get(Types.Learning.ApiClients.Tag2QueryApiClient)) {
        super(taskRunner);
        this.userProfile = new UserProfileLookup();
        this.userTags = new List(UserModerationTag2Lookup);
        this.criteria.roleName = Moderation.Moderator;
    }

    public userRoleLookupPageManager = new Data.PageManager(this.criteria, UserWithGuidLookup, this.userRoleApiClient.getPagedUsersByRoleNameAsync, {
        pageSize: 10,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sortBy: "preferredName",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner,
    })

    public async filtertag2List(userModeration: UserModerationTag2Lookup, tag1Id?: number | undefined) {
        if (tag1Id) {
            const tag2List = (await this.taskRunner.waitFor(this.tag2QueryApiClient.getTag2ListByTag1Id(tag1Id as number))).data;
            userModeration.filteredTag2List.update(tag2List);
        }
    }

    public async editUserModal(userGuid: string) {
        if (userGuid !== "") {
            this.selectedUserGuid = userGuid;
            const response = await this.taskRunner.waitFor(this.userQueryApiClient.getRegisteredUser(this.selectedUserGuid));
            let userData = response.data;
            this.userProfile.set(userData);
            const tagResponse = await this.taskRunner.waitFor(this.moderationQueryApiClient.getModerationTag2ByUserGuidAsync(this.selectedUserGuid));
            let userData2 = tagResponse.data;
            this.userTags.set(userData2);
        }
        this.showEditModal = true;
    }

    public closeTagsNotModerated(){        
        this.showTags = false;
        this.tagsNoModerator = new List(Tag1);
    }
    public async showTagsNotModerated() {
        const response = await this.taskRunner.waitFor(this.moderationQueryApiClient.tag2WhereNoModeratorsExist());
        if (response.status >= 200 && response.status < 300) {
            this.tagsNoModerator = new List(Tag1);
            var tag2s = response.data;
            tag2s.forEach(tag2NoMod => {
                var tag1s = this.tagsNoModerator.filter(tag1 => tag1.tag1Id === tag2NoMod.tag1Id && tag2NoMod != null);                
                if (tag1s.length>0) {
                    var newTag2 = new Tag2();
                    newTag2.tagName = tag2NoMod.tag2Name === undefined ? "" : tag2NoMod.tag2Name;
                    newTag2.tag1Id = tag2NoMod.tag1Id === undefined ? 0 : tag2NoMod.tag1Id;
                    newTag2.tag2Id = tag2NoMod.tag2Id === undefined ? 0 : tag2NoMod.tag2Id;
                    tag1s[0].tags2.push(newTag2);                    
                } else {
                    var newTag1 = new Tag1();
                    newTag1.tagName = tag2NoMod.tag1Name === undefined ? "" : tag2NoMod.tag1Name;
                    newTag1.tag1Id = tag2NoMod.tag1Id === undefined ? 0 : tag2NoMod.tag1Id;    

                    var newTag2Add = new Tag2();
                    newTag2Add.tagName = tag2NoMod.tag2Name === undefined ? "" : tag2NoMod.tag2Name;
                    newTag2Add.tag1Id = tag2NoMod.tag1Id === undefined ? 0 : tag2NoMod.tag1Id;
                    newTag2Add.tag2Id = tag2NoMod.tag2Id === undefined ? 0 : tag2NoMod.tag2Id;
                    newTag1.tags2.push(newTag2Add);

                    this.tagsNoModerator.push(newTag1)
                }
            })
            this.showTags = true;
        }
    }

    public async closeEditModal() {
        this.userProfile = new UserProfileLookup();
        this.selectedUserGuid = "";
        this.showEditModal = false;
    }

    public async saveUser() {
        await this.SaveTags();
    }

    private async SaveTags() {
        this.userTags.forEach(user => {
            user.userGuid = this.userProfile.userGuid;
        });
        this.taskRunner.run(async () => {
            const resp = await this.moderationQueryApiClient.updateModerationTag2Lookup(this.userTags.toJSArray());
            if (resp.data) {
                this.notifications.addSuccess(`Moderation Tag changes applied to ${this.userProfile.firstName} ${this.userProfile.surname}`, null, NotificationDuration.Standard)
            } else {
                this.notifications.addDanger(`Moderation Tag changes could not be applied to ${this.userProfile.firstName} ${this.userProfile.surname}`, null, NotificationDuration.Standard)
            }
            this.showEditModal = false;
            this.userRoleLookupPageManager.refreshData();
        });
    }
}