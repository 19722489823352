import { Routing as NeoRouting} from "@singularsystems/neo-core";
import OrganisationView2 from "./Views/Maintenance/Organisations/OrganisationView";
import OrgMaintenanceView from "./Views/Maintenance/Organisations/OrgMaintenanceView";
//import OrganisationHierarcyView from "./Views/HierarchyViews/OrganisastionHierarcyView";
import "./Styles/Hierarcy.scss"
import { UserTypes } from "../App/Models/Enums/UserTypes.enums";
import * as Roles from './Models/Security/OrganisationRoles';

export interface IAppRoute extends NeoRouting.IMenuRoute {
    userType?: UserTypes 
    children?: IAppRoute[]
   }
   
const OrganisationViewRoute: IAppRoute =
    { name: "Organisations Hierarchy", path: "/Organisation/Organisations2", component: OrganisationView2, icon: "fa-sitemap fa-s", allowAnonymous: false,  userType : UserTypes.Admin, role: Roles.Organisation.Admin}
const OrgMaintenanceViewRoute: IAppRoute =
    { name: "Org Maintenance", path: "/Organisations/OrgMaintenance", component: OrgMaintenanceView, icon: "fa-university fa-s", allowAnonymous: false, userType : UserTypes.Admin, role: Roles.Organisation.Admin}

const OrgAdminRoutes = [
    OrganisationViewRoute,
    OrgMaintenanceViewRoute
]

export {
    OrgAdminRoutes
}