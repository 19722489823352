import { NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class UserProfileLookupSearchCriteria extends ValueObject {

    public userGuid: string = "";

    public firstName: string = "";

    public surname: string = "";

    public keyword: string = "";

    public ids: string[] | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "User Profile Search Criteria";
    }
}