import React from "react";
import { observer } from "mobx-react";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { Data, NeoModel } from "@singularsystems/neo-core";
import { AppService } from "../../Dashboard/DashboardTypes";
import Types from "../../App/AppTypes";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import TDPUser from "../../Identity/Models/Security/TDPUser";
import { UserTypes } from "../../App/Models/Enums/UserTypes.enums";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
import GridContainer from "../../Template/components/Grid/GridContainer";
import GridItem from "../../Template/components/Grid/GridItem";
import ForumDrawer from "../../Forum/Components/ForumComponents/ForumDrawer";
import LearningPathwayLookup from "../../Dashboard/Models/LearningPathwayLookup";
import LearningPathwaySearchCriteria from "../../Dashboard/Models/SearchCriteria/LearningPathwaySearchCriteria";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum";
import { LearningPathwayType } from "../Models/LearningPathways/LearningPathwayType.enum";

interface IStartModuleProps {
  show: IPropertyInstance<boolean>;
  ModuleId: number;
  hidden?: boolean;
  GoToModule: (moduleId: number, preview: number) => void;
  GoToPathwayModule: (moduleId: number, pathwayId: number) => void;
  onClose: () => void;
}


@NeoModel
export class StartModuleModalVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private dashboardApiClient = AppService.get(Types.Dashboard.ApiClients.DashboardQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private learningPathwayApiClient = AppService.get(Types.Learning.ApiClients.LearningPathwayQueryApiClient)) {
    super(taskRunner);
  }

  public newPathwayName: string = "";
  public hideNewPathwayModal: boolean = true;
  public pathwayCriteria = new LearningPathwaySearchCriteria();
  public showAddToPathway: boolean = false;
  public showDuplicateModule: boolean = false;
  public startNewModule = async (id: number) => {
    await this.dashboardApiClient.startNewModule(id);
  }

  public userPathwayPagerManager = new Data.PageManager(this.pathwayCriteria, LearningPathwayLookup, this.dashboardApiClient.getUserPathways, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "learningPathwayId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });

  public CreatePathwayForModule = async (moduleId: number) => {
    var pathwayName = this.newPathwayName;
    const response = await this.dashboardApiClient.savePathwayForModule(pathwayName, moduleId);
    this.notifications.addSuccess("Pathway Saved", "Pathway", NotificationDuration.Short);
    return response.data
  }

  public AddModuleToExistingPathway = async (pathwayId: number, moduleId: number) => {
    var pathwayModules = await this.learningPathwayApiClient.getLearningPathwayModules(pathwayId);
    var pathwayModule = pathwayModules.data.find(c => c.learningModuleId === moduleId);
    if (pathwayModule !== null && pathwayModule !== undefined) {
      this.showDuplicateModule = true;
    } else {
      const response = await this.dashboardApiClient.addModuleToPathway(pathwayId, moduleId);
      if (response.data) {
        this.showAddToPathway = false;
        this.notifications.addSuccess("Pathway Updated", "Module saved to pathway", NotificationDuration.Short);
      }
    }
  }
}

@observer
export default class StartModuleModal extends React.Component<IStartModuleProps> {
  public viewModel = new StartModuleModalVM();
  private authService = AppService.get(Types.Neo.Security.AuthenticationService);
  constructor(props: IStartModuleProps) {
    super(props);
    this.viewModel = new StartModuleModalVM();
  }

  private startModule = async () => {
    const user = this.authService.user as TDPUser;
    if (user.userType !== UserTypes.Unverified) {
      await this.viewModel.startNewModule(this.props.ModuleId);
      this.props.GoToModule(this.props.ModuleId, 0);
    }
  }

  private goToPathwayAfterSave = async () => {
    const response = await this.viewModel.CreatePathwayForModule(this.props.ModuleId);
    this.props.GoToPathwayModule(this.props.ModuleId, response.learningPathwayId);
  }

  private showWAddToPathwayDrawer() {
    this.props.show.value = false;
    this.viewModel.userPathwayPagerManager.refreshData();
    this.viewModel.showAddToPathway = true;
  }

  render() {
    const props = this.props;
    const user = this.authService.user as TDPUser;;
    const viewModel = this.viewModel;
    return (
      <div hidden={props.hidden}>
        <Neo.Modal size="lg" show={props.show.value && !(user.userType === UserTypes.Unverified.toString())} title="Confirm Start Module" onClose={() => { props.onClose() }}>
          <Card>
            <CardHeader icon>
              <CardIcon color="info">
                <i className={`icon fa fa-question fa-2x`} />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <h4 className="Card-icon-header-text">Would you like to add this Module to a Pathway or start module as an activity? </h4>
              <GridContainer>
                <GridItem md={3} sm={6} xs={6}>
                  <Neo.Button hidden={!viewModel.hideNewPathwayModal} onClick={() => this.startModule()} variant="info" icon="clipboard" text="Start Module" />
                </GridItem>
                <GridItem md={3} sm={6} xs={6}>
                  <Neo.Button hidden={!viewModel.hideNewPathwayModal} onClick={() => viewModel.hideNewPathwayModal = false} variant="success" icon="road" text="New Pathway" />
                </GridItem>
                <GridItem md={3} sm={6} xs={6}>
                  <Neo.Button hidden={!viewModel.hideNewPathwayModal} onClick={() => this.showWAddToPathwayDrawer()} variant="success" icon="road" text="Add To Existing Pathway" />
                </GridItem>
                <GridItem md={12} >
                  <Neo.FormGroupFloating hidden={viewModel.hideNewPathwayModal} bind={viewModel.meta.newPathwayName} label="Pathway name" required />
                </GridItem>
                <GridItem md={4} sm={6} xs={6}>
                  <Neo.Button hidden={viewModel.hideNewPathwayModal} onClick={() => this.goToPathwayAfterSave()} variant="success" icon="save" text="Save my new Pathway" />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Neo.Modal>

        <ForumDrawer show={viewModel.showAddToPathway && !(user.userType === UserTypes.Unverified.toString())} isMaximised={true} maximiseWindow={() => { }} onClose={() => viewModel.showAddToPathway = !viewModel.showAddToPathway}>
          <Card>
            <CardHeader color="info" stats >
              <GridContainer>
                <GridItem md={6}>
                  <h4 className="card-title-white">Choose a Learning Pathways</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Neo.Pager pageManager={this.viewModel.userPathwayPagerManager}
                pageControlProps={{
                  firstText: "",
                  prevText: "Prev",
                  nextText: "Next",
                  lastText: "",
                  alignment: "center",
                  autoHide: false,
                  pageSizeLabel: "Show"
                }}>
                <NeoGrid.Grid<LearningPathwayLookup> >
                  {(learningPathway: LearningPathwayLookup, learningPathwayMeta) => (
                    <NeoGrid.Row >
                      <NeoGrid.Column label="Title" display={learningPathwayMeta.title} />
                      <NeoGrid.Column label="Mine/Shared/Invited" display={learningPathwayMeta.learningPathwayType} select={{ itemSource: Data.StaticDataSource.fromEnum(LearningPathwayType), renderAsText: true }} />
                      <NeoGrid.Column label="Completed Date" display={learningPathwayMeta.completedDate} />
                      <NeoGrid.ButtonColumn label="Actions" >
                        <Neo.Button icon="plus" isOutline hidden={user.userType === UserTypes.Unverified.toString()} variant="success" tooltip="Add" onClick={() => viewModel.AddModuleToExistingPathway(learningPathway.learningPathwayId, this.props.ModuleId)} />
                      </NeoGrid.ButtonColumn>
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
              </Neo.Pager>
            </CardBody>
          </Card>
        </ForumDrawer>

        <Neo.Modal size="sm" show={this.viewModel.showDuplicateModule} title="Pathway already contains module" onClose={() => { this.viewModel.showDuplicateModule = false }}>
          <span>This pathway already contains this module.</span>
        </Neo.Modal>

      </div>
    )
  }
}

