import { Data, Model, Utils } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import { AxiosPromise } from 'axios';
import School from '../../Models/Organisations/School';
import SchoolLookup from '../../Models/Lookups/SchoolLookup';
import SchoolSearchCriteria from '../../Models/Criteria/SchoolSearchCriteria';
import SchoolGroupLookup from '../../Models/Lookups/SchoolGroupLookup';
export interface ISchoolQueryApiClient {

    /**
     * Return a School by SchoolID with children
     * @param schoolId SchoolID
     * @returns School List with children
     */
    getSchoolList(schoolId: number): AxiosPromise<Array<Model.PlainTrackedObject<School>>>;

    /** 
     * This method will return the schools based on specific search criteria
     * @param request the paged search criteria request
     * @returns A collection of schools that match the given criteria
     */
    getSchoolListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SchoolSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SchoolLookup>>>;

    /**
    * Return a School by SchoolID with children
    * @returns School List with children
    */
     getSchoolOnlyList(): AxiosPromise<Array<Model.PlainTrackedObject<School>>>;

    // Client only properties / methods
}

@injectable()
export default class SchoolQueryApiClient extends Data.ApiClientBase implements ISchoolQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/SchoolQuery`);
    }

    public getSchoolOnlyList(): AxiosPromise<Array<Model.PlainTrackedObject<School>>> {
        return this.axios.get(`${this.apiPath}/schoolList`);
    }

    public getSchoolList(schoolId: number): AxiosPromise<Array<Model.PlainTrackedObject<School>>> {
        return this.axios.get(`${this.apiPath}/lookup?schoolId=${schoolId}`);
    }

    public getGroupedSchoolList(request: SchoolSearchCriteria): AxiosPromise<Array<Model.PlainObject<SchoolGroupLookup>>>{
        return this.axios.get(`${this.apiPath}/GroupedSchools?${Utils.getQueryString(request.toQueryObject())}`);
    }

    public getSchoolListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SchoolSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SchoolLookup>>> {
        return this.axios.get(`${this.apiPath}/SchoolsPaged?${Utils.getQueryString(request)}`);
    }

    public getSchoolByCircuit(circuitId: number): AxiosPromise<Model.PartialPlainObject<School>[]> {
        return this.axios.get(`${this.apiPath}/SchoolsByCircuit/${circuitId}`);
    }

    public getGroupedSchools(circuitId: number): AxiosPromise<Array<Model.PlainTrackedObject<SchoolGroupLookup>>> {
        return this.axios.get(`${this.apiPath}/GroupedSchoolsByCircuit/${circuitId}`);
    }
}