import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class LearningModuleSearchCriteria extends ValueObject {

  public title: string = "";

  public keyword: string = "";

  public OwnerGuid: string = "";

  public learningModuleId: number | null = null;

  public learningModuleTag2Id: number = 0;

  public learningModuleTag3Id: number = 0;

  public learningModuleTag1Id: number = 0;

  public learningModuleTag1Name: string = "";

  public ids: number[] = [];

  public completed: boolean = false;

  public recent: boolean = false;
}