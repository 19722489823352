import { Attributes, LookupBase } from '@singularsystems/neo-core';


export default class SACEEducatorImportResult extends LookupBase {

    public warnings: string[] = [];

    public fileErrors: string[] = [];

    public rowErrors: string[] = [];

    // Client only properties / methods

    @Attributes.Integer()
    public importedRowCount: number = 0;
}