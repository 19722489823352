import { NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { AppService, Types } from "../IdentityTypes";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
import RichTextEditor from "../../Learning/Components/RichTextEditor";
import TermsAndConditionLookup from "../Models/Lookups/TermsAndConditionLookup";
import { AxiosResponse } from "axios";
import Home from "../../App/Views/Security/Home";
import ForumDrawer from "../../Forum/Components/ForumComponents/ForumDrawer";
import CardFooter from "../../Template/components/Card/CardFooter";
import GridItem from "../../Template/components/Grid/GridItem";

interface ITermsAndConditionsAcceptComponentProps {
}

@NeoModel
export class TermsAndConditionsAcceptComponentVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private termsAndConditionsAcceptApiClient = AppService.get(Types.Identity.ApiClients.TermsAndConditionsAcceptApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        protected navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
        super(taskRunner);
    }

    //Properties    
    public termsAndConditions: TermsAndConditionLookup = new TermsAndConditionLookup();
    public hasAccepted: boolean = false;

    //Methods
    public async initialise() {
        await this.taskRunner.run(async () => {
            var resp = await this.termsAndConditionsAcceptApiClient.get();
            if (this.isValidResponse(resp)) {
                this.termsAndConditions = new TermsAndConditionLookup();
                this.termsAndConditions.createdOn = new Date(resp.data.createdOn);
                this.termsAndConditions.termsAndConditions = resp.data.termsAndConditions;
                if (this.termsAndConditions.termsAndConditions.length === 0) {
                    this.termsAndConditions.termsAndConditions = "{\"blocks\":[{\"key\":\"90d9i\",\"text\":\"There are no Terms And Conditions setup in the system. Please inform the administrator.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":167,\"style\":\"color-rgb(0,0,0)\"},{\"offset\":0,\"length\":159,\"style\":\"bgcolor-white\"},{\"offset\":0,\"length\":167,\"style\":\"fontfamily-Verdana\"},{\"offset\":0,\"length\":167,\"style\":\"fontsize-14\"},{\"offset\":109,\"length\":4,\"style\":\"BOLD\"},{\"offset\":139,\"length\":20,\"style\":\"ITALIC\"},{\"offset\":159,\"length\":8,\"style\":\"bgcolor-rgb(255,255,255)\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}";
                }
            }
            var resp2 = await this.termsAndConditionsAcceptApiClient.hasAccepted();
            if (this.isValidResponse(resp)) {
                if (resp2.data.valueOf()) {
                    this.hasAccepted = resp2.data;
                }
            }
        });
        this.checkAndRedirectToHome();
    }

    public async checkAndRedirectToHome() {
        if (this.hasAccepted) {
            await this.authenticationService.signinSilent();
            this.navigation.navigateToView(Home);
        }
    }

    private isValidResponse(response: AxiosResponse): boolean {
        var result = false;
        if (response.status === 200) {
            result = true;
        }
        return result;
    }

    public async accept() {
        await this.taskRunner.run(async () => {
            var resp = await this.termsAndConditionsAcceptApiClient.hasAccepted();
            if (this.isValidResponse(resp)) {
                if (!resp.data) {
                    var resp2 = await this.termsAndConditionsAcceptApiClient.accept();
                    if (this.isValidResponse(resp2)) {
                        this.notifications.addSuccess(`Terms and Conditions Accepted!`, null, NotificationDuration.Standard);
                        this.hasAccepted = true;
                    } else {
                        this.hasAccepted = false;
                        this.notifications.addDanger(`Terms and Conditions Could not be accepted due to technical error!`, null, NotificationDuration.Standard);
                    }
                }
            }
        });
        this.checkAndRedirectToHome();
    }
}

@observer
export default class TermsAndConditionsAcceptComponent extends React.Component<ITermsAndConditionsAcceptComponentProps>{

    private viewModel: TermsAndConditionsAcceptComponentVM = new TermsAndConditionsAcceptComponentVM();

    constructor(props: ITermsAndConditionsAcceptComponentProps) {
        super(props);
        this.viewModel = new TermsAndConditionsAcceptComponentVM();
    }

    private accept() {
        this.viewModel.accept();
    }

    public componentDidMount() {
        this.viewModel.initialise();
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <ForumDrawer show={true} onClose={() => { }} isMaximised={true} maximiseWindow={() => { }} >

                            <Card>
                                <CardHeader icon>
                                    <CardIcon color="success">
                                        <i className={`fa fa-address-card fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">Terms and Conditions</h4>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <label>Before you use this website, you need to accept these terms and conditions.</label>
                                    </div>
                                    <div style={{ border: "1px solid #000000" }}>
                                        {this.viewModel.termsAndConditions.termsAndConditions.length === 0 || <RichTextEditor readOnly bind={this.viewModel.termsAndConditions.meta.termsAndConditions} />}
                                    </div>
                                    <div>
                                        <label>By clicking on Accept below, you indicate that you have read and agree these terms and conditions.</label>
                                    </div>
                                </CardBody>
                                <CardFooter>

                                    <GridItem xs={6} sm={6} md={6}></GridItem>
                                    <GridItem xs={6} sm={6} md={6}>
                                        <Neo.Button text="Accept" onClick={() => this.accept()} variant="success" />
                                    </GridItem>


                                </CardFooter>
                            </Card>
                        </ForumDrawer>
                    </div>
                </section >
            </div >
        )
    }
}