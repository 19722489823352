import { Attributes, ITaskRunner, List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { AppService, Types } from '../../OrganisationTypes';
import SchoolGroupLookup from '../Lookups/SchoolGroupLookup';
import School from './School';

@NeoModel
export default class Circuit extends ModelBase {

    public circuitId: number = 0;

    @Rules.Required()
    @Rules.StringLength(50)
    @Attributes.Display("Circuit Name")
    public circuitName: string = "";

    @Rules.StringLength(250)
    @Attributes.Display("Circuit Description")
    public description: string = "";

    @Rules.Required()
    @Attributes.Display("DistrictId")
    public districtId: number | null = null;

    public schools = new List(School);

    // Client only properties / methods

    @Attributes.OnChanged<Circuit>(c => c.fetchGroupedSchools)
    public isExpanded: boolean = false;

    @Attributes.NoTracking()
    public moveToDistrictId: number = 0;

    public groupedschools = new List(SchoolGroupLookup);

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.circuitName) {
            return "New circuit";
        } else {
            return this.circuitName;
        }
    }

    public async fetchGroupedSchools() {
        this.taskRunner.run(async () => {
            if (this.groupedschools.length === 0) {
                const getGroupedSchoolListResponse = await AppService.get(Types.Organisations.ApiClients.SchoolQueryApiClient).getGroupedSchools(this.circuitId);
                if (getGroupedSchoolListResponse.data !== null) {
                    this.groupedschools.set(getGroupedSchoolListResponse.data);
                }
            }
        })
    }

    @Attributes.NoTracking()
    public taskRunner: ITaskRunner = AppService.get(Types.Neo.TaskRunner)

}
