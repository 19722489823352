import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import { List, NeoModel } from "@singularsystems/neo-core";
import { AppService } from "../../App/Services/AppService";
import Types from '../../App/AppTypes';
import SchoolGroupLookup from "../../Organisations/Models/Lookups/SchoolGroupLookup";
import OrgLevel from "../Models/Users/OrgLevel";
import Organisation from "../../Organisations/Models/Organisations/Organisation";
import Province from "../../Organisations/Models/Organisations/Province";
import District from "../../Organisations/Models/Organisations/District";
import Circuit from "../../Organisations/Models/Organisations/Circuit";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
import ForumDrawer from "../../Forum/Components/ForumComponents/ForumDrawer";


interface ISelectOrgLevelProps {
    viewModel: SelectOrgLevelComponentVM;
    optionalText: string;
    showOrgLevelModal: boolean;
    originalOrgLevel: OrgLevel;
    closeMethod: () => void;
}

@NeoModel
export class SelectOrgLevelComponentVM extends Views.ViewModelBase {

    public OrganistationList = new List(Organisation);
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private organisationApiClient = AppService.get(Types.Organisations.ApiClients.OrganisationQueryApiClient)) {
        super(taskRunner);
    }

    public currentUserCount: number = 0;
    public groupedSchoolList = new List(SchoolGroupLookup);
    public hoverOrganisationId: number = -1;
    public hoverProvinceId: number = -1;
    public hoverDistrictId: number = -1;
    public hoverCircuitId: number = -1;

    public originalOrgLevel: OrgLevel = new OrgLevel();
    public orgLevel: OrgLevel = new OrgLevel();
    public orgLevelName: string = "";
    public userFullName: string = "";
    public resultSuccess: boolean = false;

    public isMaximised = true;

    public async initialise() {
        this.clearValues();
        await this.taskRunner.waitFor(this.LoadOrganisationList());
    }

    public async GetUserCountOrgLevel(organisationId: number | null = null, provinceId: number | null = null, districtId: number | null = null, circuitId: number | null = null, schoolId: number | null = null, schoolManagementTeamId: number | null = null) {
        var orgLevel = new OrgLevel();
        orgLevel.organisationId = organisationId;
        orgLevel.provinceId = provinceId;
        orgLevel.districtId = districtId;
        orgLevel.circuitId = circuitId;
        orgLevel.schoolId = schoolId;
        orgLevel.schoolManagementTeamId = schoolManagementTeamId;
    }

    public async LoadOrganisationList() {
        const getOrganisationListResponse = await this.taskRunner.waitFor(this.organisationApiClient.getOrganisations());
        if (getOrganisationListResponse.data !== null) {
            this.OrganistationList.set(getOrganisationListResponse.data);
        }
    }

    public clearValues() {
        this.orgLevel = new OrgLevel();
    }
}

@observer
export default class SelectOrgLevelComponent extends React.Component<ISelectOrgLevelProps> {

    private ellipsis(stringin: string) {
        const maxLen = 15;
        if (stringin) {
            if (stringin.length > maxLen) {
                return stringin.substr(0, maxLen) + "..";
            } else {
                return stringin;
            }
        } else {
            return "";
        }
    }
    // Setting
    public SetOrganisation(organisation: Organisation) {
        this.props.viewModel.clearValues();
        this.props.viewModel.orgLevel.organisationId = organisation.organisationId;
        this.props.viewModel.orgLevelName = "Organisation '" + organisation.organisationName + "'";
        this.props.viewModel.resultSuccess = true;
        this.props.closeMethod();
    }

    public async SetProvince(organisation: Organisation, province: Province) {
        this.props.viewModel.clearValues();
        this.props.viewModel.orgLevel.organisationId = organisation.organisationId;
        this.props.viewModel.orgLevel.provinceId = province.provinceId;
        this.props.viewModel.orgLevelName = "Province '" + province.provinceName + "'";
        this.props.viewModel.resultSuccess = true;
        this.props.closeMethod();
    }

    public async SetDistrict(organisation: Organisation, province: Province, district: District) {
        this.props.viewModel.clearValues();
        this.props.viewModel.orgLevel.organisationId = organisation.organisationId;
        this.props.viewModel.orgLevel.provinceId = province.provinceId;
        this.props.viewModel.orgLevel.districtId = district.districtId;
        this.props.viewModel.orgLevelName = "District '" + district.districtName + "'";
        this.props.viewModel.resultSuccess = true;
        this.props.closeMethod();
    }

    public async SetCircuit(organisation: Organisation, province: Province, district: District, circuit: Circuit) {
        this.props.viewModel.clearValues();
        this.props.viewModel.orgLevel.organisationId = organisation.organisationId;
        this.props.viewModel.orgLevel.provinceId = province.provinceId;
        this.props.viewModel.orgLevel.districtId = district.districtId;
        this.props.viewModel.orgLevel.circuitId = circuit.circuitId;
        this.props.viewModel.orgLevelName = "Circuit '" + circuit.circuitName + "'";
        this.props.viewModel.resultSuccess = true;
        this.props.closeMethod();
    }


    public render() {

        this.componentDidMount = () => { this.props.viewModel.initialise() };

        const viewModel = this.props.viewModel;
        // this.props.viewModel.LoadOrganisationList();
        viewModel.resultSuccess = false;
        return (

            <ForumDrawer show={this.props.showOrgLevelModal} onClose={() => this.props.closeMethod()} isMaximised={this.props.viewModel.isMaximised} maximiseWindow={() => this.props.viewModel.meta.isMaximised.value = !this.props.viewModel.isMaximised}>
                <Card>
                    <CardHeader icon>
                        <CardIcon color="success">
                            <i className={`fa fa-sitemap fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Organisations</h4>
                    </CardHeader>
                    <CardBody>
                        <NeoGrid.Grid items={viewModel.OrganistationList} initialSortAscending={false} initialSort={"organisationId"}>
                            {(organisation, organisastionMeta) => (
                                <NeoGrid.RowGroup expandProperty={organisastionMeta.isExpanded}>
                                    <NeoGrid.Row onMouseEnter={() => viewModel.hoverOrganisationId = organisation.organisationId} onMouseLeave={() => viewModel.hoverOrganisationId = -1}                                     >
                                        <NeoGrid.Column label="Name" display={organisastionMeta.organisationName} />
                                        <NeoGrid.ButtonColumn label="Select Organisation" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                            hidden={!(viewModel.hoverOrganisationId === organisation.organisationId)}
                                            onClick={() => this.SetOrganisation(organisation)}>Select {organisation.organisationName}</Neo.Button></NeoGrid.ButtonColumn>

                                    </NeoGrid.Row>
                                    <NeoGrid.ChildRow>
                                        <NeoGrid.Grid items={organisation.provinces} initialSortAscending={true} initialSort={"provinceName"}>
                                            {(province, provinceMeta) => (
                                                <NeoGrid.RowGroup expandProperty={provinceMeta.isExpanded}>
                                                    <NeoGrid.Row onMouseEnter={() => viewModel.hoverProvinceId = province.provinceId} onMouseLeave={() => viewModel.hoverProvinceId = -1} >
                                                        <NeoGrid.Column display={provinceMeta.provinceName} />
                                                        <NeoGrid.ButtonColumn label="Select Province" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                            hidden={!(viewModel.hoverProvinceId === province.provinceId)}
                                                            onClick={() => this.SetProvince(organisation, province)}>Select {province.provinceName}</Neo.Button></NeoGrid.ButtonColumn>

                                                    </NeoGrid.Row>
                                                    <NeoGrid.ChildRow>
                                                        <NeoGrid.Grid items={province.districts} initialSortAscending={true} initialSort={"districtName"}>
                                                            {(district, districtMeta) => (
                                                                <NeoGrid.RowGroup expandProperty={districtMeta.isExpanded}>
                                                                    <NeoGrid.Row onMouseEnter={() => viewModel.hoverDistrictId = district.districtId} onMouseLeave={() => viewModel.hoverDistrictId = -1} >
                                                                        <NeoGrid.Column display={districtMeta.districtName} />

                                                                        <NeoGrid.ButtonColumn label="Select District" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                                            hidden={!(viewModel.hoverDistrictId === district.districtId)}
                                                                            onClick={() => this.SetDistrict(organisation, province, district)}>Select {this.ellipsis(district.districtName)}</Neo.Button></NeoGrid.ButtonColumn>
                                                                    </NeoGrid.Row>
                                                                    <NeoGrid.ChildRow>
                                                                        <NeoGrid.Grid items={district.circuits} initialSortAscending={true} initialSort={"circuitName"}>
                                                                            {(circuit, circuitMeta) => (

                                                                                <NeoGrid.Row onMouseEnter={() => viewModel.hoverCircuitId = circuit.circuitId} onMouseLeave={() => viewModel.hoverCircuitId = -1} >
                                                                                    <NeoGrid.Column display={circuitMeta.circuitName} />

                                                                                    <NeoGrid.ButtonColumn label="Select Circuit" width="350px"><Neo.Button variant="success" isOutline icon="import"
                                                                                        hidden={!(viewModel.hoverCircuitId === circuit.circuitId)}
                                                                                        onClick={() => this.SetCircuit(organisation, province, district, circuit)}>Select {this.ellipsis(circuit.circuitName)}</Neo.Button></NeoGrid.ButtonColumn>
                                                                                </NeoGrid.Row>
                                                                            )}
                                                                        </NeoGrid.Grid>
                                                                    </NeoGrid.ChildRow>
                                                                </NeoGrid.RowGroup>
                                                            )}
                                                        </NeoGrid.Grid>
                                                    </NeoGrid.ChildRow>
                                                </NeoGrid.RowGroup>
                                            )}
                                        </NeoGrid.Grid>
                                    </NeoGrid.ChildRow>
                                </NeoGrid.RowGroup>
                            )}
                        </NeoGrid.Grid>

                        {viewModel.OrganistationList.length === 0 &&
                            <div className="col-md-12 p-0 text-center">
                                <p>No Records</p>
                            </div>}
                    </CardBody>
                </Card>
            </ForumDrawer>
        );
    }
}

