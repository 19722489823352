import { Attributes, ModelBase, NeoModel, Validation, List } from '@singularsystems/neo-core';
import SelfAssessmentQuestionLookup from './SelfAssessmentQuestionLookup';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';
import SelfAssessmentTagLookup from './SelfAssessmentTagLookup';
import { QuestionType } from '../Questions/QuestionType.enum';
import { ModerationState } from '../LearningObjects/ModerationState.enum';
import { Sentiment } from '../Questions/Sentiment.enum';
@NeoModel
export default class SelfAssessmentLookup extends ModelBase {

  public selfAssessmentId: number = 0

  @Required("Self Assessment Title is Required.")
  public selfAssessmentTitle: string = "";

  @Required("Self Assessment Description is Required.")
  public selfAssessmentDescription: string = "";

  public questionTypeId: QuestionType = QuestionType.NotSet;

  public sentiment: Sentiment = Sentiment.NotSet;

  public selfAssessmentQuestions = new List(SelfAssessmentQuestionLookup);

  @Attributes.Observable()
  public isExpanded: boolean = false;
  public selfAssessmentTags2 = new List(SelfAssessmentTagLookup);

  public createdBy: string = "";

  public createdByUser: string = "";

  public tag2Id: number = 0;

  public moderationState: ModerationState = ModerationState.Unknown;

  public selfAssessmentModerationNotes: string = "";

  public selfAssessmentQuestionDnDModerationNotes: string = ""

  public numberOfScales: number = 4;

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.failWhen(c => c.questionTypeId === QuestionType.NotSet, "Question Type required.");
    rules.failWhen(c => c.selfAssessmentTags2.length === 0, "Assessment Tags are Required.");
    rules.failWhen(c => c.sentiment === Sentiment.NotSet && c.questionTypeId === QuestionType.LikertScale, "Sentiment is required.");
    rules.failWhen(c => c.numberOfScales === 0 && c.questionTypeId === QuestionType.LikertScale, "Number of scales can't be 0.");

  }
}