import LearningPathwaySearchCriteria from "../../Models/Criteria/LearningPathwaySearchCriteria";
import { AppService } from "../../../App/Services/AppService";
import { Types } from '../../../Identity/IdentityTypes';
import { Views } from "@singularsystems/neo-react";
import { Components, Data, List, NeoModel } from "@singularsystems/neo-core";
import LearningPathwayPagerLookup from "../../Models/Lookups/LearningPathways/LearningPathwayPagerLookup";
import { NotificationDuration } from "../../../App/Models/Enums/NotificationDuration.enum";
import { LearningPathwayEditorVM } from "./LearningPathwayEditorVM";
import LearningPathwayShareModel from "../../Models/LearningPathways/LearningPathwayShareModel";
import TDPUser from "../../../Identity/Models/Security/TDPUser";
import LearningPathway from "../../Models/LearningPathways/LearningPathway";
import { LearningPathwayType } from "../../Models/LearningPathways/LearningPathwayType.enum";
import LearningPathwayShareResult from "../../Models/LearningPathways/LearningPathwayShareResult";
import PathwayModuleDragAndDrop from "../../Models/LearningPathways/PathwayModuleDragAndDrop";
import LearningPathwayParticipantModule from "../../Models/Lookups/LearningPathways/LearningPathwayParticipantModule";
import LearningModuleSearchCriteria from "../../../Dashboard/Models/SearchCriteria/LearningModuleSearchCriteria";
import LearningModuleLookup from "../../Models/Lookups/LearningModuleLookup";
import Tag2 from "../../Models/Tags/Tag2";
import LearningPathwayModule from "../../Models/LearningPathways/LearningPathwayModule";
import { UserLearningStatus } from "../../Models/UserLearning/UsersLearningStatus.enum";
import BadgeLookup from "../../Models/Badges/BadgeLookup";
import Tag3 from "../../Models/Tags/Tag3";
import UserLearningModuleLookup from "../../Models/UserLearning/UserLearningModuleLookup";
import { Learning } from "../../Models/Security/LearningRoles";
import LearningPathwaySendEmailModel from "../../Models/LearningPathways/LearningPathwaySendEmailModel";
import LearningPathwayImportResult from "../../Models/LearningPathways/LearningImport/LearningPathwayImportResult";
import LearningPathwayImport from "../../Models/LearningPathways/LearningImport/LearningPathwayImport";

@NeoModel
export class LearningPathwayPagerVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    public learningPathwayApiClient = AppService.get(Types.Learning.ApiClients.LearningPathwayQueryApiClient),
    public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
    private tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
    private contentConsumptionApiClient = AppService.get(Types.Learning.ApiClients.ContentConsumptionQueryApiClient),
    public forumIntegrationQueryApiClient = AppService.get(Types.Forum.ApiClients.ForumIntegrationQueryApiClient),
    public forumUpdateApiClient = AppService.get(Types.Forum.ApiClients.ForumUpdateApiClient),
    public tag3ApiClient = AppService.get(Types.Learning.ApiClients.Tag3QueryApiClient),
    private dashboardApiClient = AppService.get(Types.Dashboard.ApiClients.DashboardQueryApiClient),
    private badgeApiClient = AppService.get(Types.Learning.ApiClients.BadgeQueryApiClient),
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService),
    private importApiClient = AppService.get(Types.Learning.ApiClients.LearningPathwayImportApiClient),
    private identityService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
    super(taskRunner);
    this.user = identityService.user;
    this.learningPathwayCriteria.OwnerGuid = this.user?.guid as string;
    this.learningPathwayPagerManager.refreshData();
    this.currentUserGuid = this.user?.guid as string;
    this.userCanSendEmailPathywayRole = this.authorisationService.hasRole(Learning.SendEmailPathway);
    this.userCanImportUserEmailRole = this.authorisationService.hasRole(Learning.PathwayImportEmail);
  }

  /// Propeties
  public learningPathwayEditorVM = new LearningPathwayEditorVM(this.taskRunner);
  public selectedLearningPathwayId: number | null = null;
  public selectedModuleId: number | null = null;
  private user: TDPUser | null = null;
  public selectedLearningPathway: LearningPathway = new LearningPathway();
  public selectedLearningPathwayTitle: string = "";
  public importResult?: LearningPathwayImportResult;
  public hideModules = true;
  public currentUserGuid: string | null = null;
  public userCanSendEmailPathywayRole: boolean = false;
  public userCanImportUserEmailRole: boolean = false;
  public isPathwayUser: boolean = false;
  public userPathwayType: boolean = false; 
  //Sharing
  public sharedLearningPathways = new List(LearningPathway)
  public InvitedLearningPathways = new List(LearningPathway)
  public learningPathwayShareModel = new List(LearningPathwayShareModel);
  public successfulSharedEmails = new List(LearningPathwayShareResult);
  public AcceptedPathways = new List(LearningPathway);
  public RejectedPathways = new List(LearningPathway);
  public emailsImported: List<LearningPathwayImport> = new List(LearningPathwayImport);

  /// convos
  public showForumModal = false;
  public isMaximised = false;
  public pathwayModules: PathwayModuleDragAndDrop[] = [];
  public showImportModal = false;
  public showParticipantInfo = false;
  public showAttachmentUpload = false;
  public allowedExtensions: string[] = [".png", ".pdf", ".docx", ".jpg",];
  public fileKey: string = "";
  // Bindings
  public createModal = false;
  public deleteLearningPathwayModule = false;
  public sharePathwayModal = false;
  public invitePathwayModal = false;
  public acceptSharedPathway = false;
  public acceptInvitedPathway = false;
  public deleteLearningPathway = false;
  public hasShareErrors = false;
  public forumModal = false
  public editPathwayModal = false;
  public editPathwayIsMaximised = true;
  public participantModules: List<LearningPathwayParticipantModule> = new List(LearningPathwayParticipantModule);
  public selectedPathway = new LearningPathway();
  public sendEmailModal = false;

  //Badge Stuff
  public showBadgeAward: boolean = false;
  public badgeAwarded = new BadgeLookup();
  public blobUrl: string = "";
  public thereAreSomeMoreBadges: boolean = false;
  public badgeAwardedList = new List(BadgeLookup);
  public numberOfBadgesEarned: number = 0;
  public listOfBadgeIdsToShow: number[] = [];
  public allDone: boolean = false;

  ///Methods

  private afterLearningPathwayFetched(data: LearningPathwayPagerLookup[]) {
    /// Shows user notification to accept shared pathways
    var sharedPathways = data.filter(c => c.awaitingApproval === true && c.learningPathwayType === LearningPathwayType.Shared);
    if (sharedPathways.length > 0) {
      this.SetSharedLearningPathways(sharedPathways);
    }
    /// Shows user notification to accept Invited pathways
    var invitedPathways = data.filter(c => c.awaitingApproval === true && c.learningPathwayType === LearningPathwayType.PLC)
    if (invitedPathways.length > 0) {
      this.SetInvitedLearningPathways(invitedPathways);
    }
  }

  public criteria = new LearningModuleSearchCriteria();
  public learningModuleTag2FilteredList = new List(Tag2);
  public SendEmailMeta = new LearningPathwaySendEmailModel();

  public SearchModules = () => {
    this.learningModulePageManager.refreshData();
  }

  public learningModulePageManager = new Data.PageManager(this.criteria, LearningModuleLookup, this.dashboardApiClient.getPagedLearningModuleLookup, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "learningModuleTitle",
    sortAscending: true,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner
  });

  //  Learning Pathway Pager
  public learningPathwayCriteria = new LearningPathwaySearchCriteria();
  public learningPathwayPagerManager = new Data.PageManager(this.learningPathwayCriteria, LearningPathwayPagerLookup, this.learningPathwayApiClient.getPagedLearningPathways, {
    pageSize: 15,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "learningPathwayId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
    afterFetch: this.afterLearningPathwayFetched.bind(this)
  });

  public SearchPathways() {
    this.learningPathwayPagerManager.refreshData();
  }
  public ClearFilters() {
    this.hideSearchCriteria = false;
    this.selectedLearningPathway = new LearningPathway();
    this.selectedLearningPathwayId = 0;
    this.hideModules = true;
    this.learningPathwayCriteria.keyword = "";
    this.learningPathwayPagerManager.refreshData();
  }

  public ClearModuleFilters() {
    this.criteria.title = "";
    this.criteria.keyword = "";
    this.criteria.learningModuleId = null;
    this.criteria.learningModuleTag2Id = 0;
    this.criteria.learningModuleTag3Id = 0;
    this.criteria.learningModuleTag1Id = 0;
    this.criteria.learningModuleTag1Name = "";
    this.learningModulePageManager.refreshData();
  }

  ///Creating New Pathway
  public async CloseCreateModal() {
    this.createModal = false;
    this.learningPathwayEditorVM = new LearningPathwayEditorVM(this.taskRunner);
    this.selectedLearningPathwayId = null;
    this.learningPathwayPagerManager.refreshData();
  }

  public SelectPathway(id: number) {
    this.createModal = true;
    this.selectedLearningPathwayId = id;
    this.hideModules = true;
  }

  public EditPathway = async (pathway: LearningPathwayPagerLookup) => {
    this.selectedLearningModuleList = new List(LearningModuleLookup);
    this.selectedLearningPathwayId = pathway.learningPathwayId;
    var response = await this.taskRunner.waitFor(this.learningPathwayApiClient.getLearningPathway(pathway.learningPathwayId));
    this.selectedLearningPathway.set(response.data);
    this.setModules();
    this.editPathwayModal = true;
  }

  public async ShowDeleteLearningPathwayModule(learningModuleId: number, learningPathwayId: number) {
    this.selectedModuleId = learningModuleId;
    this.selectedLearningPathwayId = learningPathwayId;
    this.deleteLearningPathwayModule = true;
  }

  public async DeleteModule() {
    await this.learningPathwayApiClient.deleteLearningPathwayModule(this.selectedModuleId as number, this.selectedLearningPathwayId as number);
    this.learningPathwayPagerManager.refreshData()
    var response = await this.taskRunner.waitFor(this.learningPathwayApiClient.getLearningPathway(this.selectedLearningPathwayId as number));
    this.selectedLearningPathway.set(response.data);
    this.deleteLearningPathwayModule = false;
    this.editPathwayModal = true;
    this.selectedModuleId = null;
    this.notifications.addSuccess("Delete Success", `Learning Pathway Module Deleted`, NotificationDuration.Standard);
  }

  public ShowShareModal(id: number) {
    this.sharePathwayModal = true;
    this.ClearValues(true);
    this.selectedLearningPathwayId = id;
    this.learningPathwayShareModel.addNew();
  }

  public CloseShareModal() {
    this.sharePathwayModal = false;
    this.ClearValues();
  }

  public ClearValues(noClear: boolean = false) {
    if (!noClear) {
      this.learningPathwayShareModel = new List(LearningPathwayShareModel);
    }

    this.successfulSharedEmails = new List(LearningPathwayShareResult);
    this.hasShareErrors = false;
  }

  public async InviteToPathway() {
    this.taskRunner.run(async () => {
      var shareResults = await this.learningPathwayApiClient.inviteToPathway(this.selectedLearningPathwayId as number, this.learningPathwayShareModel.toJSArray());
      shareResults.data.forEach((res) => {
        if (!res.emailSuccess) {
          this.hasShareErrors = true;
        } else {
          var filtered = this.learningPathwayShareModel.filter(obj => obj.userEmail === res.userEmail);
          if (filtered.length > 0) {
            filtered.forEach(removeIt => {
              var learnPathShareRes = new LearningPathwayShareResult();
              learnPathShareRes.userEmail = removeIt.userEmail;
              learnPathShareRes.emailSuccess = true;
              this.successfulSharedEmails.push(learnPathShareRes);
              this.learningPathwayShareModel.remove(removeIt);
            });
          }
        }
      });

      await this.forumUpdateApiClient.createPLCConversation(this.selectedLearningPathwayId as number);

      if (this.learningPathwayShareModel.length === 0) {
        this.hasShareErrors = false;
      }

      if (!this.hasShareErrors) {
        this.invitePathwayModal = false;
        this.ClearValues();
        this.notifications.addSuccess("Invites Sent", `Learning Pathway Invites sent successfully`, NotificationDuration.Standard);
      } else {
        this.notifications.addDanger("Some Invites Failed", `Learning Pathway invitations not sent to all recipients`, NotificationDuration.Standard);
      }
    });
    const response = await this.badgeApiClient.getAwardUserBadges()

    this.badgeAwardedList.set(response.data);
    this.numberOfBadgesEarned = response.data.length;

    if (this.numberOfBadgesEarned === 0) {
      this.allDone = true;
    }

    this.badgeAwardedList.forEach(async badge => {
      this.listOfBadgeIdsToShow.push(badge.badgeId);
    });

    await this.showNextBadge();
  }

  public showNextBadge = async () => {
    //If we click next badge and there are no more badges to show, we say we are all done. 
    if (this.listOfBadgeIdsToShow.length === 0) {
      this.allDone = true;
      return this.numberOfBadgesEarned;
    }
    var currentBadge = new BadgeLookup();

    var nextBadgeId = this.listOfBadgeIdsToShow[0];

    if (nextBadgeId !== null || nextBadgeId > 0) {
      currentBadge = this.badgeAwardedList.find(c => c.badgeId === nextBadgeId)!
      this.badgeAwarded = currentBadge;
      this.listOfBadgeIdsToShow.remove(currentBadge.badgeId);
      const bloblUrlResp = await this.taskRunner.waitFor(this.badgeApiClient.getBadgeImageByDescriptorId(this.badgeAwarded.mediaObject?.fileDescriptorId as string));
      this.badgeAwarded.blobUrl = bloblUrlResp.data;
      this.numberOfBadgesEarned = this.numberOfBadgesEarned > 0 ? this.numberOfBadgesEarned - 1 : 0;
      this.showBadgeAward = true;
    } else {
      this.numberOfBadgesEarned = 0;
    }

    return this.numberOfBadgesEarned;
  }

  public async SharePathway() {
    this.taskRunner.run(async () => {
      var shareResults = await this.learningPathwayApiClient.shareLearningPathway(this.selectedLearningPathwayId as number, this.learningPathwayShareModel.toJSArray());
      shareResults.data.forEach((res) => {
        if (!res.emailSuccess) {
          this.hasShareErrors = true;
        } else {
          var filtered = this.learningPathwayShareModel.filter(obj => obj.userEmail === res.userEmail)
          if (filtered.length > 0) {
            filtered.forEach(removeIt => {
              var learnPathShareRes = new LearningPathwayShareResult();
              learnPathShareRes.userEmail = removeIt.userEmail;
              learnPathShareRes.emailSuccess = true;
              this.successfulSharedEmails.push(learnPathShareRes);
              this.learningPathwayShareModel.remove(removeIt);
            });
          }
        }
      });

      if (this.learningPathwayShareModel.length === 0) {
        this.hasShareErrors = false;
      }

      if (!this.hasShareErrors) {
        this.sharePathwayModal = false;
        this.ClearValues();
        this.notifications.addSuccess("Learning Pathway Sharing Success", `Learning Pathway successfully Shared`, NotificationDuration.Standard);
      } else {
        this.notifications.addDanger("Learning Pathway Sharing Failed", `Learning Pathway not shared to all recipients`, NotificationDuration.Standard);
      }
    });
  }

  public ShowInviteModal(id: number, userPathway: boolean, userPathwayType: boolean ) {
    this.invitePathwayModal = true;
    this.selectedLearningPathwayId = id;
    this.hasShareErrors = false;
    this.isPathwayUser = userPathway;
    this.userPathwayType = userPathwayType;
    this.learningPathwayShareModel.addNew();
  }

  public CloseInviteModal() {
    this.invitePathwayModal = false;
    this.hasShareErrors = false;
    this.ClearValues();
  }

  public async SetSharedLearningPathways(pathways: LearningPathwayPagerLookup[]) {
    this.sharedLearningPathways = new List(LearningPathway);
    for (const pathway of pathways) {
      var response = (await this.learningPathwayApiClient.getLearningPathway(pathway.learningPathwayId));
      var newPathway = new LearningPathway();
      newPathway.set(response.data);
      this.sharedLearningPathways.push(newPathway);
    }
    this.acceptSharedPathway = true;
  }

  public async SetInvitedLearningPathways(pathways: LearningPathwayPagerLookup[]) {
    this.InvitedLearningPathways = new List(LearningPathway);
    for (const pathway of pathways) {
      var response = (await this.learningPathwayApiClient.getLearningPathway(pathway.learningPathwayId));
      var newPathway = new LearningPathway();
      newPathway.set(response.data);
      this.InvitedLearningPathways.push(newPathway);
    }
    this.acceptInvitedPathway = true;
  }

  public AddPathwayToAccptedList(pathway: LearningPathway) {
    var isAccepted = this.AcceptedPathways.filter(c => c.learningPathwayId === pathway.learningPathwayId).length > 0;
    var isRejected = this.RejectedPathways.filter(c => c.learningPathwayId === pathway.learningPathwayId).length > 0;
    if (isRejected) {
      this.RejectedPathways.remove(pathway);
    }
    if (!isAccepted) {
      this.AcceptedPathways.push(pathway);
    }
  }

  public AddPathwayToRejectedList(pathway: LearningPathway) {
    var isAccepted = this.AcceptedPathways.filter(c => c.learningPathwayId === pathway.learningPathwayId).length > 0;
    var isRejected = this.RejectedPathways.filter(c => c.learningPathwayId === pathway.learningPathwayId).length > 0;
    if (isAccepted) {
      this.AcceptedPathways.remove(pathway);
    }
    if (!isRejected) {
      this.RejectedPathways.push(pathway);
    }
  }

  public async AcceptAndRejectSharedPathways() {
    var acceptedPathways = this.AcceptedPathways;
    var rejectedPathways = this.RejectedPathways;
    for (const pathway of acceptedPathways) {
      pathway.awaitingApproval = false;
      await this.learningPathwayApiClient.acceptLearningPathway(pathway.learningPathwayId);
    }
    for (const pathway of rejectedPathways) {
      pathway.awaitingApproval = false;
      await this.learningPathwayApiClient.rejectLearningPathway(pathway.learningPathwayId);
    }
    this.acceptSharedPathway = false;
    this.learningPathwayPagerManager.refreshData();
    this.notifications.addSuccess("New Shared Learning Pathways", `You can now access your new shared pathways`, NotificationDuration.Standard);
  }

  public async AcceptAndRejectInvitedPathways() {
    var acceptedPathways = this.AcceptedPathways;
    var rejectedPathways = this.RejectedPathways;
    for (const pathway of acceptedPathways) {
      pathway.awaitingApproval = false;
      await this.learningPathwayApiClient.acceptLearningPathway(pathway.learningPathwayId);
    }
    for (const pathway of rejectedPathways) {
      pathway.awaitingApproval = false;
      await this.learningPathwayApiClient.rejectLearningPathway(pathway.learningPathwayId);
    }
    this.acceptInvitedPathway = false;
    this.learningPathwayPagerManager.refreshData();
    this.notifications.addSuccess("Invites Accepted", `You can now access your new Invited pathways`, NotificationDuration.Standard);
  }

  public GetShareGridRowStyles(id: number) {
    var isAccepted = this.AcceptedPathways.filter(c => c.learningPathwayId === id).length > 0;
    var isRejected = this.RejectedPathways.filter(c => c.learningPathwayId === id).length > 0;
    if (isAccepted) {
      return "selected-item-success";
    }
    else if (isRejected) {
      return "selected-item-danger";
    }
    else {
      return "";
    }
  }

  public ShowDeleteLearningPathwayModal(id: number) {
    this.selectedLearningPathwayId = id;
    this.deleteLearningPathway = true;
  }

  public async DeleteLearningPathway() {
    await this.learningPathwayApiClient.deleteLearningPathway(this.selectedLearningPathwayId as number)
    this.CloseDeletePathwayModal();
    this.notifications.addSuccess("Learing Pathway Deleted", `Learning Pathway Deleted`, NotificationDuration.Standard);
  }

  public CloseDeletePathwayModal() {
    this.selectedLearningPathwayId = null;
    this.deleteLearningPathway = false;
    this.learningPathwayPagerManager.refreshData();
  }

  public hideSearchCriteria = false;
  public SetSelectedLearningPathway = async (pathwayId: number | null, learningPathway: LearningPathwayPagerLookup | null) => {
    this.hideSearchCriteria = true;
    this.learningPathwayCriteria.meta.keyword.value = '';
    if (pathwayId !== null) {
      const pathway = await this.learningPathwayApiClient.getLearningPathway(pathwayId);
      this.learningPathwayCriteria.meta.keyword.value = pathway.data.title;
      await this.learningPathwayPagerManager.refreshData();
      this.selectedLearningPathwayId = pathwayId;
      this.selectedLearningPathwayTitle = pathway.data.title;
      const response = await this.learningPathwayApiClient.getPathwayDndModules(pathwayId as number);
      if (response.data) {
        this.pathwayModules = response.data;
      }
      this.hideModules = false;
    }

    if (learningPathway !== null) {
      this.learningPathwayCriteria.meta.keyword.value = learningPathway!.title;
      this.selectedLearningPathwayId = learningPathway!.learningPathwayId;
      this.selectedLearningPathwayTitle = learningPathway!.title;
      this.hideModules = false;
      const response = await this.learningPathwayApiClient.getPathwayDndModules(learningPathway!.learningPathwayId);
      if (response.data) {
        this.pathwayModules = response.data;
      }
      this.learningPathwayPagerManager.refreshData();
    }
  }

  public setDNDItems = (items: Array<PathwayModuleDragAndDrop>) => {
    this.pathwayModules = items;
    this.pathwayModules.forEach(element => {
      const item = items.find(c => c.id === element.id);
      const newindex = items.findIndex(c => c.id === element.id);
      if (item?.id === element.id) {
        element.order = newindex;
      }
    });
  }

  public CreatePathway = () => {
    this.selectedLearningPathwayId = null;
    this.hideModules = true;
    this.createModal = true;
  }

  public SetParticipantInfo = async (moduleId: number) => {
    this.showParticipantInfo = true;
    var response = await this.learningPathwayApiClient.getLearningPathwayParticipantModules(this.selectedLearningPathwayId!, moduleId)
    if (response.data) {
      this.participantModules.set(response.data)
    }
  }

  public CloseParticipantInfo() {
    this.participantModules = new List(LearningPathwayParticipantModule);
    this.showParticipantInfo = false;
  }

  public async saveLoOrderChanges() {
    for (const dndObject of this.pathwayModules) {
      await this.contentConsumptionApiClient.saveOrderChanges(this.selectedLearningPathwayId as number, dndObject.moduleId, dndObject.order);
    }
    const response = await this.learningPathwayApiClient.getPathwayDndModules(this.selectedLearningPathwayId as number);
    if (response.data) {
      this.pathwayModules = response.data;
      this.notifications.addSuccess("Learning pathway modules updated", "Order Updated", NotificationDuration.Short);
    }
  }

  public async FilterTag2ListSearch(tag1Id: number | undefined) {
    if (tag1Id) {
      const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id))).data;
      this.learningModuleTag2FilteredList.set(tag2List);
    }
    this.criteria.learningModuleTag2Id = 0;
  }

  public learningModuleTag3FilteredList = new List(Tag3);

  public async FilterModuleTag3ListSearch(tag2Id: number | undefined) {
    this.learningModuleTag3FilteredList = new List(Tag3);
    if (tag2Id) {
      const tag3List = (await this.taskRunner.waitFor(this.tag3ApiClient.getTag3ListByTag2Id(tag2Id))).data;
      this.learningModuleTag3FilteredList.set(tag3List);
    }
    this.criteria.learningModuleTag3Id = 0;
  }

  public selectedLearningModuleList = new List(LearningModuleLookup);

  public AddModuleToPathway(learningModule: LearningModuleLookup) {
    this.selectedLearningModuleList.push(learningModule);
  }

  public RemoveModule(learningModule: LearningModuleLookup) {
    this.selectedLearningModuleList.remove(learningModule);
  }

  public IsSelected(id: number) {
    return this.selectedLearningModuleList.filter(c => c.learningModuleId === id).length > 0;
  }

  public async SaveLearningPathway() {
    var learningPathway = this.selectedLearningPathway;
    this.selectedLearningModuleList.forEach(learningModule => {
      var learningPathwayModel = new LearningPathwayModule();
      learningPathwayModel.learningModuleId = learningModule.learningModuleId;
      learningPathwayModel.learningPathwayId = learningPathway.learningPathwayId;
      learningPathwayModel.status = UserLearningStatus.Awaiting;
      learningPathwayModel.lastQuizMark = 0;
      learningPathway.learningPathwayModules.push(learningPathwayModel);

    });
    const response = await this.learningPathwayApiClient.saveLearningPathway(learningPathway.toJSObject(), this.user!.guid);
    if (response.data) {
      this.editPathwayModal = false;
      this.learningPathwayPagerManager.refreshData();
      this.notifications.addSuccess("Save Success", `Learning Pathway Saved`, NotificationDuration.Standard);
      this.setModules();
      this.selectedLearningPathway = new LearningPathway();
      this.selectedLearningModuleList = new List(LearningModuleLookup);
      var pathwayResponse = await this.taskRunner.waitFor(this.learningPathwayApiClient.getLearningPathway(learningPathway.learningPathwayId));
      this.selectedLearningPathway.update(pathwayResponse.data);
      const response = await this.learningPathwayApiClient.getPathwayDndModules(pathwayResponse.data.learningPathwayId as number);
      if (response.data) {
        this.pathwayModules = response.data;
      }
    }
  }
  public setModules() {
    if (this.selectedLearningPathway.learningPathwayModules.length > 0) {
      this.selectedLearningPathway.learningPathwayModules.forEach(element => {
        this.criteria.ids.push(element.learningModuleId!);
      });
    }
  }

  public removeLearningPathwayModule = (module: LearningPathwayModule) => {
    this.selectedPathway.learningPathwayModules.remove(module);
    this.selectedPathway.update(this.selectedPathway.toJSObject())
  }

  public pathwayForumId = 0;

  public selectedConvoId = 0;

  public openForumDrawer = async (pathway: LearningPathwayPagerLookup) => {

    if (pathway.learningPathwayType === LearningPathwayType.PLC) {
      this.selectedConvoId = pathway.parentId as number

    } else {
      this.selectedConvoId = pathway.learningPathwayId;
    }
    let response = await this.forumIntegrationQueryApiClient.getConversationForLearningPathway(this.selectedConvoId);
    this.pathwayForumId = response.data.forumId;
    this.showForumModal = true;
  }

  public showConvoButton = (pathway: LearningPathwayPagerLookup) => {
    if (pathway.learningPathwayType === LearningPathwayType.PLC) {
      return false;
    }
    if (pathway.hasInvites) {
      return false;
    }
    return true;
  }

  public async closeForumModal() {
    this.showForumModal = false;
    const response = await this.badgeApiClient.getAwardUserBadges();

    this.badgeAwardedList.set(response.data);
    this.numberOfBadgesEarned = response.data.length;

    if (this.numberOfBadgesEarned === 0) {
      this.allDone = true;
    }

    this.badgeAwardedList.forEach(async badge => {
      this.listOfBadgeIdsToShow.push(badge.badgeId);
    });

    await this.showNextBadge();
  }
  public module = new UserLearningModuleLookup();
  public PreviewModule = async (moduleId: number) => {
    this.moduleIdToPreview = moduleId;
    this.showPreviewDrawer = true;
  }

  public moduleIdToPreview: number = 0;
  public showPreviewDrawer = false;

  public async SendPathwayEmailModal() {
    this.taskRunner.run(async () => {
      await this.learningPathwayApiClient.SendEmailMessage(this.selectedLearningPathwayId as number, this.fileKey, this.SendEmailMeta.toJSObject());
    });
    this.CloseSendPathwayEmailModal();
    this.clearSendEmailValues();
    this.notifications.addSuccess("Email sent", "Email have been sent successfully", NotificationDuration.Standard);
  }

  public ShowSendPathwayEmailModal() {
    this.sendEmailModal = true;
  }

  public CloseSendPathwayEmailModal() {
    this.sendEmailModal = false;
    this.clearSendEmailValues();
  }

  public clearSendEmailValues() {
    this.SendEmailMeta.EmailSubject = "";
    this.SendEmailMeta.EmailBody = "";
    this.fileKey = "";
  }

  public async showLearningPathwayImportModal() {
    this.showImportModal = true;
  }

  public async CloseLearningPathwayImportModal() {
    this.showImportModal = false;
    this.learningPathwayShareModel.set([]);
    this.emailsImported.forEach(
      c => {
        let model = new LearningPathwayShareModel();
        model.userEmail = c.emailAddress;
        this.learningPathwayShareModel.push(model);
      }
    );
  }

  public fileManager = new Components.FileManager(this.importApiClient.getUploadLearningPathEmailFileUrl(),
    {
      afterUpload: this.afterUpload.bind(this),
      onFilesSelected: this.onUploadFilesSelected.bind(this),
      allowedExtensions: [".xlsx", ".xls"]
    });

  private onUploadFilesSelected(fileList: File[] | FileList) {
    this.fileManager.uploadFiles(fileList);
  }

  private async afterUpload(fileKey: string) {
    this.taskRunner.run("Importing file", async () => {

      this.emailsImported = new List(LearningPathwayImport);
      const result = (await this.importApiClient.doImport(fileKey)).data;
      this.importResult = LearningPathwayImportResult.fromJSObject<LearningPathwayImportResult>(result);
      this.emailsImported.set([]);
      this.importResult?.importRows!.forEach((a) => {
        var newItem = new LearningPathwayImport();
        newItem.emailAddress = a.emailAddress;
        this.emailsImported.push(newItem);
      });
      this.CloseLearningPathwayImportModal();
    });
  }

  public uploadRequested() {
    this.fileManager.showFileDialog();
  }
}