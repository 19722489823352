import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../LearningTypes';
import BadgeSearchCriteria from '../../Models/Criteria/BadgeSearchCriteria';
import BadgeLookup from '../../Models/Badges/BadgeLookup';
import BadgeResult from '../../Models/Badges/BadgeResult';

export interface IBadgesQueryApiClient {

    /**
    * This method will return the paged badges
    * @param request the paged search criteria request
    * @returns A collection of badges 
    */
     getPagedBadgeLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BadgeSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<BadgeLookup>>>;
    
    /**
    * This method will Badge based on BbadgeId
    * @param badgeId 
    * @returns A badge
    */
      getBadgeById(badgeId: number): AxiosPromise<Model.PlainTrackedObject<BadgeLookup>>;

    /**
    * This method will create a new badge
    * @param request the paged search criteria request
    * @returns Created badge
    */
     createBadge(newBadge: Model.PartialPlainObject<BadgeLookup>, userId: string): AxiosPromise<Model.PlainTrackedObject<BadgeResult>>;

    /**
    * This method remove a babdge image
    * @param badgeId the badgeId
    * @returns Boolean value 
    */
     removeBadgeImage(badgeId: number): AxiosPromise<boolean>;
    
    /**
    * This method will badge image url
    * @param fileDescriptorId the fileDescriptorId
    * @returns Created badge
    */
     getBadgeImageByDescriptorId(fileDescriptorId: string): AxiosPromise<string>
    
    /**
    * This method will go and get all the badges the user has earned.
    * @returns List of badges
    */
      getAwardUserBadges(): AxiosPromise<Array<Model.PlainTrackedObject<BadgeLookup>>>

    // Client only properties / methods
}

@injectable()
export default class BadgesQueryApiClient extends Data.ApiClientBase implements IBadgesQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/BadgeQuery`);
    }

    public getPagedBadgeLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BadgeSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<BadgeLookup>>> {
        return this.axios.get(`${this.apiPath}/PagedBadgeLookup?${Utils.getQueryString(request)}`);
    }

    public getBadgeById(badgeId: number): AxiosPromise<Model.PlainTrackedObject<BadgeLookup>> {
        return this.axios.get(`${this.apiPath}/GetBadgeById/${badgeId}`);
    }

    public createBadge(newBadge: Model.PartialPlainObject<BadgeLookup>): AxiosPromise<Model.PlainTrackedObject<BadgeResult>> {
        return this.axios.put(`${this.apiPath}/CreateBadge`, newBadge);
    }

    public updateBadge(newBadge: Model.PartialPlainObject<BadgeLookup>): AxiosPromise<Model.PlainTrackedObject<BadgeResult>> {
        return this.axios.put(`${this.apiPath}/UpdateBadge`, newBadge);
    }

    public uploadBadgeImage(): string {
        return `${this.apiPath}/UploadBadgeImage`;
    }

    public removeBadgeImage(badgeId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/RemoveBadgeImage/${badgeId}`);
    }

    public getBadgeImageByDescriptorId(fileDescriptorId: string): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/DisplayBadgeImage/${encodeURIComponent(fileDescriptorId)}`);
    }
    
    public getAwardUserBadges(): AxiosPromise<Array<Model.PlainTrackedObject<BadgeLookup>>> {
        return this.axios.get(`${this.apiPath}/AwardUserBadges`);
    }

    public deleteBadge(badgeId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/RemoveBadge/${badgeId}`);
    }
    // Client only properties / methods
}