export enum Organisation {
    Admin = "Organisations.Organisation.Admin",
}

export enum ImportRoles {    
    DoImport = "Organisations.Import Roles.Do Import",
}

export enum ImportHierarchyRoles {   
    ImportUsers = "Organisations.Import Hierarchy Roles.Import Users",
    UserOrg = "Organisations.Import Hierarchy Roles.User Org"
}

