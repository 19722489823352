import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class SelfAssessmentOptions extends ModelBase {

  public option: string = "";

  public points: number = 1;

  public answer: boolean = false;

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

}