import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class LearningPathwayShareResult  extends ModelBase {

    public userEmail: string = "";

    public emailSuccess: boolean = false;

    // Client only properties / methods
}