import { AppService } from './Services/AppService';
import { appModule } from './AppModule';
import { AppServices } from '@singularsystems/neo-core';
import { NeoReactModule } from '@singularsystems/neo-react';
import { NotificationServiceModule } from '@singularsystems/neo-notifications';
import { OrganisationAppModule } from '../Organisations/OrganisationModule';
import { LearningAppModule } from '../Learning/LearningModule';
import { authorisationAppModule } from "../Authorisation/AuthorisationModule";
import { identityAppModule } from '../Identity/IdentityModule';
import { ForumAppModule } from '../Forum/ForumModule';
import { DashboardModule } from '../Dashboard/DashboardModule';
import { ReportingModule } from '@singularsystems/neo-reporting';
import { reportingAppModule } from '../Reporting/ReportingModule';

const appService = AppService as AppServices.AppService;

appService.registerModule(AppServices.NeoModule);
appService.registerModule(NeoReactModule);
appService.registerModule(NotificationServiceModule);
appService.registerModule(appModule);
appService.registerModule(OrganisationAppModule);
appService.registerModule(LearningAppModule);
appService.registerModule(authorisationAppModule);
appService.registerModule(identityAppModule);
appService.registerModule(ForumAppModule)
appService.registerModule(ReportingModule);
appService.registerModule(reportingAppModule);
appService.registerModule(ForumAppModule)
appService.registerModule(DashboardModule)