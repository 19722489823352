import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'
import Tag2 from '../../Models/Tags/Tag2';

export interface ITag2ApiClient extends Data.IUpdateableApiClient<Tag2, number> {

    // Client only properties / methods
}

@injectable()
export default class Tag2ApiClient extends Data.UpdateableApiClient<Tag2, number> implements ITag2ApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/Tag2`);
    }

    // Client only properties / methods
}