import 'core-js/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './App/AppSetup';
import App from './App/App';
import { AppService, Types } from './App/Services/AppService';
import registerServiceWorker from './registerServiceWorker';

AppService.get(Types.Neo.Config.ConfigService).loadConfig().then(async () => {

    const authService = AppService.get(Types.Neo.Security.AuthenticationService);

    /// load token don't redirect to ids
    if (window.location.pathname === "/Learning/ModuleConsumptionView") {
        await authService.loadUserFromToken();
    }

    // React init
    ReactDOM.render(

        <App />,
        document.getElementById('root') as HTMLElement
    );

});

registerServiceWorker();
