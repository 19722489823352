import { Attributes, Misc, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import LearningModule from '../LearningModules/LearningModule';
import { UserLearningStatus } from '../UserLearning/UsersLearningStatus.enum';

@NeoModel
export default class LearningPathwayModule extends ModelBase {

    constructor() {
        super()
        this.learningModule = new LearningModule();
    }

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public learningPathwayId: number | null = null;

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public learningModuleId: number | null = 0;

    public learningModule: LearningModule | null = null;

    @Attributes.Integer()
    public order: number = 0;

    public status: UserLearningStatus = UserLearningStatus.Awaiting;

    @Attributes.Date()
    public completedDate: Date | null = null;

    @Attributes.Float()
    public lastQuizMark: number = 0;

    @Attributes.Nullable()
    public lastLearningObjectId: number | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New learning pathway module";
        } else {
            return "Learning Pathway Module";
        }
    }
}