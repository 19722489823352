import { Routing  as NeoRouting} from '@singularsystems/neo-core';
import UserMaintenanceView from "./Views/Import/UserImportsView";
import { UserTypes } from '../App/Models/Enums/UserTypes.enums';
import { ImportRoles } from '../Organisations/Models/Security/OrganisationRoles';

export interface IAppRoute extends NeoRouting.IMenuRoute {
  userType?: UserTypes 
  children?: IAppRoute[]
  denyRole?: string
 }

const UserMaintenanceViewRoute: IAppRoute =
{ name: "User Imports", path: "/UserImports", component: UserMaintenanceView, icon: "fa-cogs fa-s", allowAnonymous: false, userType :  UserTypes.Admin, role: ImportRoles.DoImport }

 const UserAdminRoutes = [
     UserMaintenanceViewRoute
]

export {
    UserAdminRoutes
}