import { Attributes, ITaskRunner, List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { AppService, Types } from '../../LearningTypes';
import { ModerationState } from '../LearningObjects/ModerationState.enum';
import LearningObjectContentLookup from './LearningObjectContentLookup';
import LearningObjectTagLookup from './LearningObjectTagLookup';
import QuizQuestionLookup from './QuizQuestionLookup';
import { truncateLongString } from '../../../App/HelperClasses/GlobalHelpers';

@NeoModel
export default class LearningObjectLookup extends ModelBase {

    constructor() {
        super()
        this.moderationState = ModerationState.Unmoderated;
    }
    public learningObjectId: number = 0;

    public learningModuleId: number = 0;

    public finalQuizInd: boolean = false

    @Rules.Required("Learning Object Type is Required")
    public learningObjectTypeId: number | null = null;

    @Rules.Required()
    public learningObjectPurpose: string = "";

    @Rules.Required()
    public learningObjectTitle: string = "";

    public learningObjectText: string = "";

    public learningObjectTextHtml: string = "";

    public objectName: string = "";

    public listOfTag3s = new List(LearningObjectTagLookup);

    public learningContent = new List(LearningObjectContentLookup);

    @Attributes.ChildObject(LearningObjectContentLookup)
    public newLearningContent: LearningObjectContentLookup | null = null;

    public order: number = 0;

    public moderationState: ModerationState;

    public moderationNotes: string = '';

    // Client only properties / methods

    public quizQuestions = new List(QuizQuestionLookup);

    public numberOfQuestionsToAnswer: number = 1;

    @Attributes.Display("Linked To Resource")
    public linkedToResource: string = ""

    public randomiseOrder: boolean = true;

    // Client only properties / methods
    @Attributes.OnChanged<LearningObjectLookup>(c => c.fetchLearningContent)
    public isExpanded: boolean = false;

    public learningObjectChangedTypeId: number | null = null;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.learningObjectPurpose) {
            return "New learning object lookup";
        } else {
            return this.learningObjectPurpose;
        }
    }

    private async fetchLearningContent() {
        this.taskRunner.run(async () => {
            if (this.learningContent.length === 0) {

                const getlearningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentByObject(this.learningObjectId);
                if (getlearningContent.data !== null) {
                    this.learningContent.set(getlearningContent.data);
                }
            }
            if (this.quizQuestions.length === 0) {
                const quizQuestion = await AppService.get(Types.Learning.ApiClients.QuizLearningObjectApiClient).GetQuizQuestions(this.learningObjectId);
                if (quizQuestion.data !== null) {
                    this.quizQuestions.set(quizQuestion.data);
                    if (this.quizQuestions.length > 0) {
                        this.quizQuestions.forEach(question => {
                            question.plainText = truncateLongString(JSON.parse(question.text).blocks[0].text);
                        });
                    }
                }
            }
        })
    }

    @Attributes.NoTracking()
    public taskRunner: ITaskRunner = AppService.get(Types.Neo.TaskRunner)
}