import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';
import { ModeratableIntField, ModeratableLongStringField, ModeratableShortStringField } from '../Moderation/ModeratableFieldValues';
import LearningModuleTagLookup from './LearningModuleTagLookup';
import { ModerationState } from '../LearningObjects/ModerationState.enum';
import OrgLevel from '../../../Identity/Models/Users/OrgLevel';

@NeoModel
export default class LearningModuleWithTagsLookup extends ModelBase {

    constructor() {
        super();
        this.title = new ModeratableShortStringField();
        this.description = new ModeratableLongStringField();
        this.cptdPoints = new ModeratableIntField();
    }
    public learningModuleId: number = 0;

    @Attributes.ChildObject(ModeratableShortStringField)
    @Required("A Title is required.")
    public title: ModeratableShortStringField;

    @Attributes.ChildObject(ModeratableLongStringField)
    @Required("A Description is required.")
    public description: ModeratableLongStringField;

    @Attributes.Display("Continuous Professional Teacher Development Points")
    @Attributes.ChildObject(ModeratableIntField)
    public cptdPoints: ModeratableIntField;

    public moderationNotes: string = "";

    public moderationState: ModerationState = ModerationState.Unmoderated;

    public learningModuleTags = new List(LearningModuleTagLookup);

    public orgLevel: OrgLevel = new OrgLevel();
    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New learning module with tags lookup";
        } else {
            return "Learning Module With Tags Lookup";
        }
    }
}