import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class TopicSearchCriteria extends ValueObject {
    
    public topicId: Number = 0;

    public forumId: Number = 0;

    public topicName: string = "";

    public topicType: Number = 0;

    public  KeywordContainsPattern: string = "";

}