import React from 'react';
import IRoleItem from '../Models/IRoleItem';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { AppServices, Misc } from '@singularsystems/neo-core';
import { Neo } from '@singularsystems/neo-react';
import * as Roles from '../Models/Security/Roles';

export default class ResourceRoleTree extends React.Component<{ items: IRoleItem[], enabled: boolean }> {
    render() {
        return (
            <ul >
                {this.props.items.map(item => <ResourceRoleTreeItem key={item.itemName} item={item} enabled={this.props.enabled} />)}
            </ul>
        )
    }
}

type resourceRoleTreeItemProps = { item: IRoleItem, enabled: boolean };

@observer
class ResourceRoleTreeItem extends React.Component<resourceRoleTreeItemProps> {
    @observable.ref
    isExpanded = false;

    constructor(props: resourceRoleTreeItemProps) {
        super(props);
        this.isExpanded = props.item.level > 1;
    }

    private authorisationService = Misc.Globals.appService.get(AppServices.NeoTypes.Security.AuthorisationService);

    private canAssignRoles() {
        return this.authorisationService.hasRole(Roles.UserAccess.Update) && this.props.enabled;
    }

    render() {
        let item = this.props.item;

        return (
            <li >
                {<div className={"role-item " + (!this.canAssignRoles() ? " no-select" : "")} style={{ width: '250px !important' }}>
                    <div className="row">
                        <div className="col-md-3">
                            {item.children &&
                                <div className="role-item-text disable-selection" onClick={() => {
                                    this.isExpanded = !this.isExpanded;
                                    return false;
                                }} >
                                    <i className={`role-expander fa fa-angle-${this.isExpanded ? "down" : "right"}`} />
                                </div>}
                            <div className="role-item-text disable-selection" onClick={() => this.itemClicked(item)}>
                                {item.itemName}
                                {item.level === 1 &&
                                    <span>{" "}({item.selectedChildrenCount} / {item.children ? item.fullChildrenCount : 0})</span>}
                            </div>
                        </div>

                        <div className="col-md-3">
                            <Neo.Button isOutline style={{ border: "none", background: 'none' }} variant="dark"
                                className={item.selected === undefined ? "role-undefined " : item.selected ? "role-unlock nofloat" : "role-lock"}
                                icon={item.selected === undefined ? "minus" : item.selected ? "lock-open" : "lock"} onClick={() => this.itemClicked(item)} size="sm" ></Neo.Button>
                        </div>
                    </div>
                </div>}

                {this.isExpanded && item.children && <ResourceRoleTree items={item.children} enabled={this.props.enabled} />}
            </li>)
    }

    private itemClicked(item: IRoleItem) {
        if (this.canAssignRoles()) {
            item.selected = !item.selected;
        }
    }
}
