import { Attributes, ModelBase, Rules, Utils, Validation } from '@singularsystems/neo-core';
import { UserTypes } from '../../../../App/Models/Enums/UserTypes.enums';
import OrgLevel from '../OrgLevel';

export default abstract class UserProfileBase extends ModelBase {

    public userId: number = 0;

    @Rules.Required()
    public titleId: number = 0;

    @Rules.Required()
    public userVerified: boolean = false;

    @Rules.Required()
    public userType: UserTypes | null = null;

    @Rules.Required()
    public userGuid: string = Utils.emptyGuid();

    @Rules.Required()
    @Rules.StringLength(150)
    public firstName: string = "";

    @Rules.Required()
    @Rules.StringLength(150)
    public surname: string = "";

    @Rules.StringLength(20)
    @Attributes.Display("SACE Number")
    public saceNumber: string = "";

    @Rules.StringLength(20)
    @Attributes.Display("PERSAL Number")
    public persalNumber: string = "";

    @Rules.StringLength(20)
    @Attributes.Display("Identity Number")
    public idNumber: string = "";

    @Attributes.Display("Mobile Number")
    @Rules.StringLength(256)
    public phoneNumber: string = "";

    @Attributes.Display("Email Address")
    @Rules.StringLength(256)
    public email: string = "";

    @Attributes.Display("Is Account Active")
    @Rules.Required()
    public isActive: boolean = false;

    @Attributes.ChildObject(OrgLevel)
    public organisationlevel: OrgLevel = new OrgLevel();

    @Attributes.Integer()
    public cptdPoints: number = 0;

    public acceptedTerms: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}