import { Data, List, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService } from "../../../App/Services/AppService";
import { Types } from '../../../Identity/IdentityTypes'
import TDPUser from "../../../Identity/Models/Security/TDPUser";
import UserProfileLookup from "../../../Identity/Models/Users/Lookups/UserProfileLookup";
import PostLookup from "../../Models/Lookups/PostLookup";
import PostSearchCriteria from "../../Models/Lookups/PostSearchCriteria";
import { ReportModalVM } from "./ReportModalView";

@NeoModel
export default class ROConversationComponentVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
    private postQueryApiClient = AppService.get(Types.Forum.ApiClients.PostQueryApiClient),
    private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
    public forumIntegrationQueryApiClient = AppService.get(Types.Forum.ApiClients.ForumIntegrationQueryApiClient)) {
    super(taskRunner);
    this.user = this.authenticationService.user;
    this.currentUser = new UserProfileLookup();
  }

  public disableEdit = true;
  public postCriteria = new PostSearchCriteria();
  public user: TDPUser | null = null;
  public currentUser: UserProfileLookup = new UserProfileLookup();;
  public selectedPost: PostLookup | null = null;
  public postReplies = new List(PostLookup);
  public hideDeletePost = true;
  public hideReplyDeletePost = true;
  public topicId = 0;
  public postId: number | number = 0;
  public HideMessages = true;
  public hideReply = true;
  public reportModalVM = new ReportModalVM(this.taskRunner);
  public hideChildReply = true;
  public selectedChildPost = new PostLookup();

  public async initialise() {
    await this.getCurrentUser();
  }

  public async loadPosts(topicId: number) {
    this.topicId = topicId;
    this.postCriteria.topicId = topicId;
    await this.postPageManager.refreshData().then(() => {
      if (this.selectedPost !== null) {
        var post = this.postPageManager.data.find(c => c.postId === this.selectedPost!.postId);
        if (post) {
          this.selectedPost = post;
        }
      }
    });
  }

  public postPageManager = new Data.PageManager(this.postCriteria, PostLookup, this.postQueryApiClient.getPagedPostsByCriteriaAsync, {
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 30],
    sortBy: "createdOn",
    sortAscending: false,
    fetchInitial: false,
    initialTaskRunner: this.taskRunner,
  })

  public async getCurrentUser() {
    if (this.user !== null) {
      let response = await this.userQueryApiClient.getRegisteredUser(this.user?.guid);
      let userData = response.data;
      this.currentUser.set(userData);
    }
  }

  public setUserColor(userGuid: string) {
    return this.currentUser.userGuid === userGuid ? "#4daf50" : "#00B0F0";
  }

  public async openMessage(postId: number) {
    this.postId = postId;
  }

  public getDayDifference = (postedDate: Date) => {
    let newDate = new Date()
    let date = newDate.getDate();
    return Math.floor((Math.abs(postedDate.getDate() - date)) / (1000 * 60 * 60 * 24));
  }

  public viewReplies = async (post: PostLookup) => {
    this.selectedPost = new PostLookup();
    this.HideMessages = true;
    this.hideReply = true;
    this.postReplies = new List(PostLookup);
    if (post.hasChildren === true) {
      const response = await this.postQueryApiClient.getPostReplies(post.postId, this.topicId);
      this.postReplies.set(response.data);
      this.selectedPost = post;
      this.HideMessages = false;
    }
    else {
      this.selectedPost = post;
      this.hideReply = false;
    }
  }

  public async reportPost(post: PostLookup) {
    this.reportModalVM.show(post);
  }

  public viewChildReplies = async (post: PostLookup) => {
    this.selectedChildPost = post;
    this.hideChildReply = false;
  }

}