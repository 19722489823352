import { Data, List, NeoModel, ModalUtils, Misc } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { AppService, Types } from "../../App/Services/AppService";
import SchoolManagementTeam from "../Models/Organisations/SchoolManagementTeam";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum"
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";

interface ISchoolManagementTeamComponentProps {
    viewModal: SchoolManagementTeamComponentVM;
}

@NeoModel
export class SchoolManagementTeamComponentVM extends Views.ViewModelBase {

    public SchoolManagementTeamList = new List(SchoolManagementTeam);
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private schoolManagementTeamApiClient = AppService.get(Types.Organisations.ApiClients.SchoolManagementTeamApiClient),
        private schoolApiClient = AppService.get(Types.Organisations.ApiClients.SchoolApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    public schoolList = new Data.ApiClientDataSource(this.schoolApiClient.get)
    public enableEdit: boolean = false;

    public async initialise() {
        this.LoadSMTList();
    }

    public async LoadSMTList() {
        const getSchoolManagementTeamListResponse = await this.taskRunner.waitFor(this.schoolManagementTeamApiClient.get());
        if (getSchoolManagementTeamListResponse.data !== null) {
            this.SchoolManagementTeamList.set(getSchoolManagementTeamListResponse.data);
        }
    }

    public async saveSchoolManagementTeams() {
        this.taskRunner.run(async () => {
            const response = await this.schoolManagementTeamApiClient.saveList(this.SchoolManagementTeamList.toJSArray());
            this.SchoolManagementTeamList.update(response.data)
            this.notifications.addSuccess("School Management Teams saved", "School Management Teams saved successfully", 4);
        })
        this.enableEdit = false;
    }

    public async deleteSMT(smtId: number) {
        this.taskRunner.run(async () => {
            await this.schoolManagementTeamApiClient.delete(smtId);
            this.LoadSMTList();
            this.notifications.addSuccess("SMT Deleted", null, NotificationDuration.Short);
        })
    }
}

@observer
export default class SchoolManagementTeamView extends React.Component<ISchoolManagementTeamComponentProps>{

    private async deleteSMTPopUp(smtName: string, smtId: number) {
        if (await ModalUtils.showYesNoDismissible("Delete SMT " + smtName,
            <div>
                <p>
                    Are you sure you want to delete this School Management Team?
                </p>
            </div>) === Misc.ModalResult.Yes) {
            this.props.viewModal.deleteSMT(smtId);
        }
    }

    public render() {
        const viewModel = this.props.viewModal;
        return (
            <div>
                <div>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="success">
                                <i className={`fa fa-users fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">School Management Teams</h4>
                        </CardHeader>
                        <CardBody>
                            <p> <Neo.Button variant="success" isOutline icon="edit"
                                onClick={() => viewModel.enableEdit = !viewModel.enableEdit} >Edit School Management Teams</Neo.Button></p>
                            <NeoGrid.Grid items={viewModel.SchoolManagementTeamList} initialSort={"schoolManagementTeamID"} showAddButton>
                                {(smtItem, smtMeta) => (
                                    <NeoGrid.Row>
                                        <NeoGrid.Column label="Name" bind={smtMeta.managementTeamName} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.Column label="School" bind={smtMeta.schoolId} select={{ itemSource: viewModel.schoolList }} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.ButtonColumn showDelete={smtItem.schoolId === 0}>
                                            <Neo.Button disabled={!viewModel.enableEdit} hidden={smtItem.schoolId === 0} icon="trash" variant="danger" onClick={() => this.deleteSMTPopUp(smtItem.managementTeamName, smtItem.schoolManagementTeamID)} />
                                        </NeoGrid.ButtonColumn>
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>

                            {viewModel.SchoolManagementTeamList.length === 0 &&
                                <div className="col-md-12 p-0 text-center">
                                    <p>No Records</p>
                                </div>}
                            <Neo.Form model={viewModel.SchoolManagementTeamList} onSubmit={() => viewModel.saveSchoolManagementTeams()} showSummaryModal>
                                <div className="my-4">
                                    <Neo.Button isSubmit variant="warning" icon="check">Save</Neo.Button>
                                </div>
                            </Neo.Form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }
}