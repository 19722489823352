import { AppServices } from "@singularsystems/neo-core";
import ForumQueryApiClient from "./ApiClients/Forums/Query/ForumQueryApiClient";
import PostQueryApiClient from "./ApiClients/Forums/Query/PostQueryApiClient";
import TopicQueryApiClient from "./ApiClients/Forums/Query/TopicQueryApiClient";
import PostUpdateApiClient from "./ApiClients/Forums/Update/PostUpdateApiClient";
import TopicUpdateApiClient from "./ApiClients/Forums/Update/TopicUpdateApiClient";
import ForumIntegrationQueryApiClient from "./ApiClients/Integrations/ForumIntegrationQueryApiClient";
import ForumIntegrationUpdateApiClient from "./ApiClients/Integrations/ForumIntegrationUpdateApiClient";
import ForumUpdateApiClient from "./ApiClients/Forums/Update/ForumUpdateApiClient";
import { Types } from "./ForumTypes";


export const ForumAppModule = new AppServices.Module("Forum", container => {
    // API clients
    container.bind(Types.Forum.ApiClients.ForumIntegrationQueryApiClient).to(ForumIntegrationQueryApiClient).inSingletonScope();
    container.bind(Types.Forum.ApiClients.TopicQueryApiClient).to(TopicQueryApiClient).inSingletonScope();
    container.bind(Types.Forum.ApiClients.ForumQueryApiClient).to(ForumQueryApiClient).inSingletonScope();
    container.bind(Types.Forum.ApiClients.PostQueryApiClient).to(PostQueryApiClient).inSingletonScope();
    container.bind(Types.Forum.ApiClients.PostUpdateApiClient).to(PostUpdateApiClient).inSingletonScope();
    container.bind(Types.Forum.ApiClients.TopicUpdateApiClient).to(TopicUpdateApiClient).inSingletonScope();
    container.bind(Types.Forum.ApiClients.ForumIntegrationUpdateApiClient).to(ForumIntegrationUpdateApiClient).inSingletonScope();
    container.bind(Types.Forum.ApiClients.ForumUpdateApiClient).to(ForumUpdateApiClient).inSingletonScope();

    // Services
})