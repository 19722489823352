import { Attributes, List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import Circuit from './Circuit';
import SchoolManagementTeam from './SchoolManagementTeam';
import { SchoolStatus } from './Enums/SchoolStatus.enum';
import { SchoolSector } from './Enums/SchoolSector.enum';
import { SchoolPhase } from './Enums/SchoolPhase.enum';

@NeoModel
export default class School extends ModelBase {

    public schoolId: number = 0;

    @Rules.Required()
    public circuitId: number | null = 0;

    public circuit: Circuit | null = null;

    @Rules.Required()
    public schoolName: string = "";

    public townshipVillage: string = "";

    public suburb: string = "";

    public streetAddressLine1: string = "";

    public streetAddressLine2: string = "";

    public streetAddressLine3: string = "";

    public streetAddressPostalCode: string = "";

    public postalAddressLine1: string = "";

    public postalAddressLine2: string = "";

    public postalAddressLine3: string = "";

    public postalAddressPostalCode: string = "";

    public telephone: string = "";

    public schoolStatus: SchoolStatus | null = 0;

    public schoolSector: SchoolSector | null = 0;

    public schoolPhase: SchoolPhase | null = 0;

    @Attributes.Float()
    public schoolLatitude: number = 0;

    @Attributes.Float()
    public schoolLongitude: number = 0;

    public townCity: string = "";

    public schoolManagementTeams = new List(SchoolManagementTeam);

    public isExpanded: boolean = false
}