import {  ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class PostSearchCriteria extends ModelBase {

    public body: string = "";

    public createdBy: string = "";

    public topicId: number = 0;


}