import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'
import Circuit from '../../Models/Organisations/Circuit';

export interface ICircuitApiClient extends Data.IUpdateableApiClient<Circuit, number> {

    // Client only properties / methods
}

@injectable()
export default class CircuitApiClient extends Data.UpdateableApiClient<Circuit, number> implements ICircuitApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/Circuit`);
    }

    // Client only properties / methods
}