/// to be deleted after testing
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import RichTextEditor from "../../../Learning/Components/RichTextEditor";
import Card from "../../../Template/components/Card/Card";
import CardBody from "../../../Template/components/Card/CardBody";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardIcon from "../../../Template/components/Card/CardIcon";
import PostLookup from "../../Models/Lookups/PostLookup";
import ReportModalView from "./ReportModalView";
import ConversationComponentVM from "./ConversationComponentVM";
import GridItem from "../../../Template/components/Grid/GridItem";
import { Tooltip } from '@material-ui/core';
import GridContainer from "../../../Template/components/Grid/GridContainer";

interface IConversationComponentProps {
  topicId: number;
  title: string;
  show: IPropertyInstance;
  isMaximised?: IPropertyInstance;
  fromLearningActivity?: boolean;
  moduleName?: string;
}

@observer
export default class ConversationComponent extends React.Component<IConversationComponentProps>{
  constructor(props: IConversationComponentProps) {
    super(props);
    this.viewModel = new ConversationComponentVM();
    this.viewModel.loadPosts(this.props.topicId);
    this.viewModel.getCurrentUser();
  }

  private viewModel = new ConversationComponentVM();

  public render() {
    var conversationType: string = "";
    if (this.viewModel.integrationType === 0) {
      conversationType = "All";
    } else
      if (this.viewModel.integrationType === 1) {
        conversationType = "Learning Pathway";
      } else
        if (this.viewModel.integrationType === 2) {
          conversationType = "Global";
        } else
          if (this.viewModel.integrationType === 3) {
            conversationType = "Activity";
          }

    return (
      <div>
        <Card>
          <CardHeader>
            <CardIcon color="primary">
              <i className={`icon fas fa-comments fa-2x`}></i>
            </CardIcon>
            <h4 className="Card-icon-header-text">{this.props.title} Conversations</h4>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <Neo.Button icon="backward" isOutline text="Back to topics" variant="warning" onClick={() => { this.props.show.value = false; this.props.isMaximised!.value = false }} hidden={this.props.fromLearningActivity} />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <label>Related To Activity: {this.props.moduleName} <br /><i>Scope: {conversationType}</i></label>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            {this.viewModel.postPageManager.data.length === 0 ||
              <Neo.Pager
                pageManager={this.viewModel.postPageManager}
                pageControlProps={{
                  firstText: "",
                  prevText: "Prev",
                  nextText: "Next",
                  lastText: "",
                  alignment: "center",
                  autoHide: false,
                  pageSizeLabel: "Show"
                }}>
                {post => (
                  <NeoGrid.Grid<PostLookup> className="table-hover" keyProperty="postId">
                    {(post: PostLookup, postMeta) => (
                      <NeoGrid.Row key={post.postId}>
                        <NeoGrid.Column style={{ border: "none" }}>
                          <div className="row">
                            <div style={{ width: "40px" }}>
                              <div className="custom-avatar" style={{ background: this.viewModel.setUserColor(post.createdByGuid), width: "40px" }}>
                                <span >{post.postedBy[0]}</span>
                              </div>
                            </div>
                            <div className="col-md-11">
                              <span style={{ color: "#656c7a", fontWeight: 700 }}>{post.postedBy} </span>
                              <span style={{ fontWeight: 300, color: "grey" }}>- {post.timeAgo}</span>
                              {post.body.length === 0 ||
                                <div>
                                  <RichTextEditor key={post.postId} bind={postMeta.body} readOnly={this.viewModel.disableEdit || this.viewModel.selectedPost?.postId !== post.postId} />
                                  <Neo.Button onClick={() => this.viewModel.updatePost(post)} text="Update" icon="edit" variant="success" isOutline hidden={this.viewModel.disableEdit || this.viewModel.selectedPost?.postId !== post.postId} />
                                  <Neo.Button onClick={() => { this.viewModel.loadPosts(this.props.topicId); this.viewModel.disableEdit = true }} text="Cancel" icon="times" variant="danger" isOutline hidden={this.viewModel.disableEdit || this.viewModel.selectedPost?.postId !== post.postId} />
                                </div>}
                              <div>
                                <span onClick={() => { this.viewModel.selectForEdit(post) }} hidden={this.viewModel.currentUser.userGuid !== post.createdByGuid} className="reply-btn">{"Edit |"} </span>
                                <span onClick={() => { this.viewModel.viewReplies(post, false) }} className="reply-btn">{!post.hasChildren ? "" : "View Messages |"} </span>
                                <span hidden={post.isReported} onClick={() => this.viewModel.viewReplies(post, true)} className="reply-btn"> Reply | </span>
                                <span hidden={post.isReported} onClick={() => this.viewModel.reportPost(post)} className="report-btn"> Report </span>
                                <Tooltip hidden={post.isReported === false} id="tooltip-top" title="Post under review" placement="top"><div className="fas fa-flag report-flag" /></Tooltip>
                                <span hidden={post.hasChildren || this.viewModel.currentUser.userGuid !== post.createdByGuid} onClick={() => this.viewModel.showDeleteModal(post)} className="report-btn">| Delete</span>
                              </div>
                            </div>
                            <div key={post.postId + 2} className="col-md-12" hidden={this.viewModel.hideReply}>
                              {
                                this.viewModel.selectedPost !== post ||
                                <div className="row" style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }} >
                                  <div className="col-md-12">
                                    <RichTextEditor bind={this.viewModel.replyBody.meta.body} />
                                    <Neo.Button variant="success" icon="plus" isOutline onClick={() => this.viewModel.sendReply(this.viewModel.replyBody.meta.body.value, this.props.topicId)}>Send</Neo.Button>
                                  </div>
                                </div>
                              }
                            </div>
                            <div key={post.postId + 1} className="col-md-12" hidden={this.viewModel.hideDeletePost}>
                              {
                                this.viewModel.selectedPost !== post ||
                                <div style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }} >
                                  <p>Please give a reason for deleting this post</p>
                                  <GridItem md={12}>
                                    <Neo.FormGroupFloating label="Reason" bind={this.viewModel.selectedPost?.meta.deletedReason} input={{ rows: 3 }} />
                                  </GridItem>
                                  <GridItem md={12}>
                                    <Neo.Button variant="danger" icon="trash" isOutline onClick={() => this.viewModel.delete(this.props.topicId)}>Delete</Neo.Button>
                                    <Neo.Button variant="warning" icon="times" isOutline onClick={() => this.viewModel.cancelDelete()}>Cancel</Neo.Button>
                                  </GridItem>
                                </div>
                              }
                            </div>
                            <div key={post.postId} className="col-md-12" hidden={this.viewModel.HideMessages}>
                              {
                                this.viewModel.selectedPost !== post ||
                                this.viewModel.postReplies.map((reply, index) => (
                                  <div key={reply.postId}>
                                    <div className="row" style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }}>
                                      <div>
                                        <div className="custom-avatar" style={{ background: this.viewModel.setUserColor(reply.createdByGuid), width: "40px" }}>
                                          <span >{reply.postedBy[0]}</span>
                                        </div>
                                      </div>
                                      <div className="col-md-11">
                                        <span style={{ color: "#656c7a", fontWeight: 700 }}>{reply.postedBy} </span>
                                        <span style={{ fontWeight: 300, color: "grey" }}>- {post.timeAgo}</span>
                                        {reply.body.length === 0 ||

                                          <RichTextEditor bind={reply.meta.body} readOnly />
                                        }
                                        <div>
                                          {/* <span onClick={() => { this.viewModel.selectForEdit(reply) }} hidden={this.viewModel.currentUser.userGuid === reply.createdByGuid} className="reply-btn">{"Edit |"} </span> */}
                                          <span onClick={() => { this.viewModel.viewChildReplies(reply) }} className="reply-btn">{"Reply |"} </span>
                                          <span hidden={reply.isReported} onClick={() => this.viewModel.reportPost(reply)} className="report-btn"> Report</span>
                                          <Tooltip style={{ marginLeft: "5px" }} hidden={reply.isReported === false} id="tooltip-top" title="Post under review" placement="top"><div className="fas fa-flag report-flag" /></Tooltip>
                                          <span hidden={this.viewModel.currentUser.userGuid !== reply.createdByGuid} onClick={() => this.viewModel.showReplyDeleteModal(reply)} className="report-btn">| Delete</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div key={reply.postId + 1} className="col-md-12" hidden={this.viewModel.hideChildReply}>
                                      {
                                        this.viewModel.selectedChildPost !== reply ||
                                        <div className="row" style={{ marginLeft: "60px", marginTop: "10px", marginBottom: "10px" }} >
                                          <div className="col-md-12">
                                            <RichTextEditor bind={this.viewModel.childReplyBody.meta.body} />
                                            <Neo.Button variant="success" icon="plus" isOutline onClick={() => this.viewModel.sendChildReply(this.viewModel.childReplyBody.meta.body.value, this.props.topicId)}>Send</Neo.Button>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                    <div key={post.postId + 1} className="col-md-12" hidden={this.viewModel.hideReplyDeletePost}>
                                      {
                                        this.viewModel.selectedPost !== post ||
                                        <div style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }} >
                                          <p>Please give a reason for deleting this post</p>
                                          <GridItem md={12}>
                                            <Neo.FormGroupFloating label="Reason" bind={this.viewModel.selectedChildPost?.meta.deletedReason} input={{ rows: 3 }} />
                                          </GridItem>
                                          <GridItem md={12}>
                                            <Neo.Button variant="danger" icon="trash" isOutline onClick={() => this.viewModel.deleteReply(this.props.topicId)}>Delete</Neo.Button>
                                            <Neo.Button variant="warning" icon="times" isOutline onClick={() => this.viewModel.cancelDelete()}>Cancel</Neo.Button>
                                          </GridItem>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </NeoGrid.Column>
                      </NeoGrid.Row>
                    )}
                  </NeoGrid.Grid>
                )}
              </Neo.Pager>
            }
            <div className="row">
              <div className="col">
                <RichTextEditor bind={this.viewModel.postBody.meta.body} labelText="Start a New Topic" />
              </div>
            </div>
            <div className="row">
              <div className="col align-self-end mb-2">
                <Neo.Button variant="success" icon="plus" isOutline onClick={() => this.viewModel.sendMessage(this.viewModel.postBody.meta.body.value, this.props.topicId)}>Send</Neo.Button>
                <Neo.Button variant="warning" icon="sync-alt" isOutline onClick={() => this.viewModel.loadPosts(this.viewModel.postCriteria.topicId)}>Refresh</Neo.Button>
              </div>
            </div>
          </CardBody>
        </Card>
        <ReportModalView viewModel={this.viewModel.reportModalVM} />
      </div >
    )
  }
}