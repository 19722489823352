import { Data, List, NeoModel } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { OpenLink } from "../../App/HelperClasses/GlobalHelpers";
import { AppService, Types } from "../../Identity/IdentityTypes";
import Card from "../../Template/components/Card/Card";
import CardBody from "../../Template/components/Card/CardBody";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import GridContainer from "../../Template/components/Grid/GridContainer";
import GridItem from "../../Template/components/Grid/GridItem";
import { LearningContentMediaType } from "../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import FileLookup from "../Models/Lookups/FileLookup";
import LearningObjectContentLookup from "../Models/Lookups/LearningObjectContentLookup";
import RichTextEditor from "./RichTextEditor";

interface IProps {
  hidden: boolean;
  learningContent: LearningObjectContentLookup;
  fileDescriptorList: List<FileLookup>;
  onSave: (learningContent: LearningObjectContentLookup) => void;
  //viewModel: ModeratableContentFileComponentVM;
  moderationState: boolean;
}

@NeoModel
export class ModeratableContentFileComponentVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient)) {
    super(taskRunner);
  }

  /// Gets and set the Download path from Blob storage
  public DownloadFileGet(this: any, fileDescriptorId: string, mediaTypeId: number, link: string) {
    this.taskRunner.run(async () => {
      var response = await this.learningModuleApiClient.getFileDownloadURL(fileDescriptorId, mediaTypeId);
      if (response.data) {
        window.open(response.data.toString(), '_blank');
      }
    });
  }
  public hideContentNotes: boolean = true;
}

@observer
export default class ModeratableContentFileComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.viewModel = new ModeratableContentFileComponentVM();
    this.viewModel.hideContentNotes = true;
  }
  public viewModel = new ModeratableContentFileComponentVM();
  public showNotes: boolean = false;

  public render() {
    const learningContent = this.props.learningContent
    return (
      <div>
        <div>
          <Card hidden={this.props.hidden}>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`fas fa-file`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">Learning Content</h4>
            </CardHeader>
            <CardBody>
              <Neo.Form >
                <GridContainer>
                  <GridItem md={6} sm={12} xs={12}>
                    <Neo.FormGroupFloating bind={learningContent.learningContent.meta.title} readOnly={this.props.moderationState} />
                  </GridItem>
                  <GridItem md={6}>
                  </GridItem>
                  <GridItem md={6} sm={12} xs={12}>
                    <Neo.FormGroupFloating display={learningContent.learningContent.meta.mediaType}
                      select={{
                        itemSource: Data.StaticDataSource.fromEnum(LearningContentMediaType),
                        renderAsText: true
                      }}
                    />
                  </GridItem>
                  <GridItem md={6}>
                  </GridItem>
                  <GridItem md={6} sm={12} xs={12}>
                    <Neo.FormGroupFloating label="Alternative URL" placeholder="www.urlExample.com" bind={learningContent.learningContent.meta.externalResourceLocation}
                      hidden={learningContent.learningContent.mediaType === LearningContentMediaType.YouTube || learningContent.learningContent.mediaType === LearningContentMediaType.Conversation
                      } />
                  </GridItem>
                  <GridItem md={6}>
                  </GridItem>
                  <GridItem md={6} sm={12} xs={12}>
                    <div hidden={learningContent.learningContent.mediaType !== LearningContentMediaType.Conversation}>
                      <span>Initial Post</span>
                      <RichTextEditor labelText="Initial Post" bind={learningContent.learningContent.meta.initialPost}
                        readOnly />
                    </div>
                  </GridItem>
                  <GridItem md={6}>
                  </GridItem>
                  <GridItem md={6} sm={12} xs={12}>
                    <Neo.FormGroupFloating label="URL Key" display={learningContent.learningContent.meta.externalResourceLocationKey} hidden={learningContent.learningContent.mediaType !== LearningContentMediaType.YouTube} /><b />
                  </GridItem>
                  <GridItem md={6}>
                  </GridItem>
                  <GridItem md={12}>
                    <Neo.Button hidden={learningContent.learningContent.mediaObject === null || learningContent.learningContent.mediaType === LearningContentMediaType.Conversation || learningContent.learningContent.mediaType === LearningContentMediaType.Link}
                      text={"Download"} isOutline icon={learningContent.learningContent.mediaObject?.icon} variant="info"
                      onClick={() => this.viewModel.DownloadFileGet(learningContent.learningContent.mediaObject?.fileDescriptorId as string, learningContent.mediaTypeFetched as number, learningContent.learningContent.externalResourceLocation as string)} />
                  </GridItem>
                  <GridItem md={12}>
                    <Neo.Button hidden={learningContent.mediaTypeFetched !== LearningContentMediaType.Link} text={"Open link"} isOutline icon="link" variant="info"
                      onClick={() => OpenLink(this.props.learningContent.learningContent.externalResourceLocation as string)} />
                  </GridItem>
                  <GridItem md={12}>
                    <Neo.Button tooltip="Click to open link in new window" hidden={learningContent.learningContent.mediaType !== LearningContentMediaType.YouTube}
                      isOutline icon="link" variant="info" text="Open link" onClick={() => OpenLink(learningContent.learningContent.externalResourceLocationKey as string)} />
                  </GridItem>
                  <GridItem md={6} sm={12} xs={12}>
                    <NeoGrid.Grid className="mb-10 " items={this.props.fileDescriptorList} hidden={this.props.fileDescriptorList.length === 0}>
                      {(file, fileMeta) => (
                        <NeoGrid.Row>
                          <NeoGrid.Column label="File Name" display={fileMeta.fileName} />
                          <NeoGrid.Column label="File Type" display={fileMeta.mimeType} />
                        </NeoGrid.Row>
                      )}
                    </NeoGrid.Grid>
                  </GridItem>
                  <GridItem md={4}>
                  </GridItem>
                  <GridItem md={2} sm={12} xs={12}>
                    <Neo.FormGroup className="mt-2" label="Approve Content?" bind={learningContent.learningContent.moderation.meta.approvedByModerator} hidden={this.props.fileDescriptorList.length === 0} onChange={() => this.props.onSave(learningContent)} />
                  </GridItem>
                </GridContainer>
                {this.viewModel.hideContentNotes ||
                  <Neo.Card hidden={this.viewModel.hideContentNotes} className="moderation-notes">
                    <RichTextEditor key={"Content"} onBlur={() => this.props.onSave(learningContent)} bind={learningContent.learningContent.moderation.meta.moderationNotes} labelText="Content Moderation Notes" />
                  </Neo.Card>
                }
                <Neo.Button text="Save Moderation Progress" variant="success" isOutline className="mt-2" onClick={() => this.props.onSave(learningContent)} />
                <Neo.Button text={this.viewModel.hideContentNotes ? "Content Notes" : "Hide Content Notes"} className="mt-2 ml-2" isOutline variant="primary" icon="sticky-note" onClick={() => this.viewModel.hideContentNotes = !this.viewModel.hideContentNotes} />
              </Neo.Form>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}