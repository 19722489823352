import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { buildUpCustomHTMLForImage, buildUpCustomHTMLForSound, buildUpCustomHTMLForVideo, DownloadFile } from '../../../App/HelperClasses/GlobalHelpers';
import { AppService, Types } from '../../../Identity/IdentityTypes';
import ContentConsumptionLookup from '../../Models/ContentCosumption/ContentConsumptionLookup';
import InformationObjectConsumptionLookup from '../../Models/ContentCosumption/InformationObjectConsumptionLookup';
import { LearningContentMediaType } from '../../Models/LearningObjects/LearningContent/LearningContentMediaType.enum';

@NeoModel
export default class PreviewLearningObjectVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private contentConsumptionApiClient = AppService.get(Types.Learning.ApiClients.ContentConsumptionQueryApiClient),
        private forumIntegrationQueryApiClient = AppService.get(Types.Forum.ApiClients.ForumIntegrationQueryApiClient),
        private topicQueryApiClient = AppService.get(Types.Forum.ApiClients.TopicQueryApiClient),
        private learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);
    }

    public learningObject: InformationObjectConsumptionLookup = new InformationObjectConsumptionLookup();
    public currentContent = new ContentConsumptionLookup();
    public contentBlobUrl = '';
    public showContent: boolean = false;

    //forum stuff 
    public showForumModal: boolean = false;
    public isMaximised: boolean = false;
    public objectForumId: number = 0;
    public topicId: number = 0;

    public GetObject = async (objectId: number) => {
        const response = await this.contentConsumptionApiClient.getInfoObjectById(objectId, 0);
        if (response.data) {
            this.learningObject.update(response.data);
        }
    }

    public ViewContent = async (content: ContentConsumptionLookup) => {
        this.currentContent = content;
        this.contentBlobUrl = "";
        if (this.currentContent.mediaType === LearningContentMediaType.Conversation) {
            await this.openForumDawer(this.learningObject.learningObjectId);
            return
        }
        if (this.currentContent.mediaType !== LearningContentMediaType.Link && this.currentContent.mediaType !== LearningContentMediaType.YouTube) {
            var fileUrl = await this.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, false)
            this.contentBlobUrl = fileUrl;

            if (this.currentContent.mediaType === LearningContentMediaType.Document) {
                //in this file context (documents) we only save ".docx", ".pptx", ".ppt", ".xlsx", ".xls", ".pdf" extensions
                if (fileUrl.endsWith(".pdf")) {
                    this.contentBlobUrl = 'https://docs.google.com/gview?url=' + fileUrl + '&embedded=true';
                    this.showContent = true;
                }
                else {
                    this.contentBlobUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + fileUrl;
                    this.showContent = true;
                }
            }
            else {
                if (this.currentContent.mediaType === LearningContentMediaType.Video) {
                    this.contentBlobUrl = buildUpCustomHTMLForVideo(this.contentBlobUrl);
                }
                else if (this.currentContent.mediaType === LearningContentMediaType.Image) {
                    this.contentBlobUrl = buildUpCustomHTMLForImage(this.contentBlobUrl);
                }
                else if (this.currentContent.mediaType === LearningContentMediaType.Sound) {
                    this.contentBlobUrl = buildUpCustomHTMLForSound(this.contentBlobUrl);
                }
                this.showContent = true;
            }
        }
        else {
            this.showContent = true;
        }
    }

    public openForumDawer = async (id: number) => {
        var forumIntegrationResponse = await this.forumIntegrationQueryApiClient.getConversationForLearningActivity(id);
        var topicResponse = await this.topicQueryApiClient.getLearningObjectTopicByForumId(forumIntegrationResponse.data.forumId);
        this.topicId = topicResponse.data.topicId;
        this.showForumModal = true;
    }

    public GetFileUrlFile = async (fileDescriptorId: string, mediaTypeId: number, download: boolean) => {
        var response = await this.learningModuleApiClient.getFileDownloadURL(fileDescriptorId, mediaTypeId);

        if (download) {
            DownloadFile(response.data.toString());
        }
        return response.data.toString();
    }
}