import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class OptionLookup extends ModelBase {

  public option: string = "";

  @Attributes.Display("Correct Answer/s")
  public correctAnswerInd: boolean = false;

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

}