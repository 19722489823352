import { NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class OrgLevelUserProfileSearchCriteria extends ValueObject {

    public organisationId: number | null = null;
    public provinceId: number | null = null;
    public districtId: number | null = null;
    public circuitId: number | null = null;
    public schoolId: number | null = null;
    public schoolManagementTeamId: number | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Organisation Level User Profile Search Criteria";
    }
}