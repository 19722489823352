import { Attributes, List, Model, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import DistrictUsers from './DistrictUsers';

@NeoModel
export default class ProvinceUsers extends ModelBase {

    public provinceId: number = 0;

    @Rules.Required("Province name is required")
    @Rules.StringLength(50)
    @Attributes.Display("Province Name")
    public provinceName: string = "";

    @Rules.StringLength(250)
    @Attributes.Display("Province Description")
    public description: string = "";

    @Rules.Required()
    @Rules.StringLength(20)
    public provinceCode: string = "";

    @Rules.Required()
    @Attributes.Display("OrganisationId")
    public organisationId: number = 0; 

    @Attributes.ChildObject(Model.AuditValues)
    public audit: Model.AuditValues | null = null;

    public districts = new List(DistrictUsers);

    public isExpanded: boolean = false

    public isSelected: boolean = false;
    
    @Attributes.NoTracking()
    public usersCount: number = 0;
    
    @Attributes.NoTracking()
    public usersCountIncldChildren: number = 0;

    public switchSelection() {
        this.isSelected = !this.isSelected;
    }

    // Client only properties / methods
    public toString(): string {
        if (this.isNew) {
            return "New Province"
        }
        else {
            return this.provinceName;
        }
    }

}