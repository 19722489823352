import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class Title extends ModelBase {

    public titleId: number = 0;

    @Rules.Required()
    public titleName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.titleName) {
            return "New title";
        } else {
            return this.titleName;
        }
    }
}