import { Attributes, Validation, ValueObject } from '@singularsystems/neo-core';
import ModerationObject from './ModerationObject';

export default abstract class ModeratableBase extends ValueObject {

    @Attributes.ChildObject(ModerationObject)
    public moderation: ModerationObject | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}