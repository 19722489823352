import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import Tag2 from './Tag2';

@NeoModel
export default class Tag3 extends ModelBase {

    public tag3Id: number = 0;

    @Rules.Required("Topic required")
    @Rules.StringLength(100)
    @Attributes.Display("Topic(T3)")
    public tagName: string = "";

    @Attributes.Display("Tag2Id")
    public tag2Id: number | null = 0;

    public tag2: Tag2 | null = null;

    public tagDescription: string = "";

    public isExpanded: boolean = false;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        rules.warnWhen(c => c.tagName.length >= 100, "Topic(T3) should be less than or equal to 100.");
    }

    // Client only properties / methods

    public combinedTagName: string = this.tag2?.tagName + " " + this.tagName;

}