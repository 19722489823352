import { NeoModel } from '@singularsystems/neo-core';
import { Managers, Views } from '@singularsystems/neo-react';
import { Layout } from '@singularsystems/neo-canvas-grid';
import { AppService } from '../Services/AppService';
import  Types  from '../AppTypes';

@NeoModel
export default class LoadTestAnalyserVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);
    }

    public fileManager = new Managers.FileManager({ onFilesSelected: this.onFileSelected.bind(this) });

    public async initialise() {

    }

    public rawData: any;

    private async onFileSelected(files: FileList | File[]) {
        
        const json = await this.readFileText(files[0]);
        const lines = json.split(/[\r\n](?=.)/);

        let data = [];
        for (let line of lines) {
            let lineData = JSON.parse(line);

            if (lineData.type === "Point" && lineData.metric === "http_req_duration") {
                data.push(lineData.data);
            }
            
        }

        this.rawData = data;
    }

    private readFileText(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.readAsText(file)
            reader.addEventListener("load", e => {
                resolve(reader.result as string);
            });
        });
    }
}

export const layout = {
    "root": {
        "columns": [
            {
                "key": "value",
                "total": {
                    "type": "average"
                }
            },
            {
                "key": "time",
                "isDate": true,
                formatString: "dd MMM yy HH:mm:ss",
                "sortDirection": 0,
            }
        ],
        "calculatedColumns": [
            {
                "calcType": "bar",
                "dataKey": "value",
                "insertAfter": "value"
            }
        ],
        "parent": {
            "color": 1,
            "groupBy": [
                {
                    "key": "service_g1",
                    "title": "Service",
                    "dataKey": "service"
                },
                {
                    "key": "name_g1",
                    "title": "Name",
                    "dataKey": "name"
                }
            ],
            "summaries": [
                {
                    "key": "count_s2",
                    "width": 85,
                    "summary": {
                        "type": "count"
                    }
                },
                {
                    "key": "count_s0",
                    "title": "Failed",
                    "width": 85,
                    "summary": {
                        "type": "count",
                        "filter": {
                            "type": "notStartsWith",
                            "value": "2",
                            "columnKey": "tags.status"
                        }
                    }
                },
                {
                    "key": "value_s0",
                    "title": "Avg ms",
                    "sortDirection": 1,
                    "total": {
                        "type": "average"
                    },
                    "summary": {
                        "type": "average"
                    },
                    "childKey": "value"
                },
                {
                    "key": "value_s1",
                    "title": "P(95) ms",
                    "total": {
                        "type": "percentile",
                        "parameterValue": 0.95
                    },
                    "summary": {
                        "type": "percentile",
                        "parameterValue": 0.95
                    },
                    "childKey": "value"
                }
            ],
            "title": "Url / Group",
            "calculatedColumns": [
                {
                    "calcType": "bar",
                    "dataKey": "value_s1",
                    "insertAfter": "value_s1"
                }
            ]
        }
    }
} as Layout.IGridLayout;

// class Result {
//     group: string;
//     name: string;
//     url: string;
//     success: number;
// }