import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { NeoModel } from '@singularsystems/neo-core';
import { AppService } from "../../../App/Services/AppService";
import Types from '../../../App/AppTypes';
import PostLookup from '../../Models/Lookups/PostLookup';
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration.enum';

interface IReportModal {
    viewModel: ReportModalVM;
}

@NeoModel
export class ReportModalVM extends Views.ViewModelBase {

    public reportModal = false;
    public post = new PostLookup();

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private postUpdateApiClient = AppService.get(Types.Forum.ApiClients.PostUpdateApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    public show(post: PostLookup) {
        this.post = post;
        this.reportModal = true;
    }

    public async SubmitReport() {
        this.post.isReported = true
        await this.postUpdateApiClient.reportPostAsync(this.post.toJSObject())
        this.reportModal = false;
        this.notifications.addSuccess("Post reported", "Post has been reported and will be reviewed", NotificationDuration.Standard)
    }
}

@observer
export default class ReportModalView extends React.Component<IReportModal> {

    public render() {
        const viewModel = this.props.viewModel;
        return (
            <div>
                <Neo.Modal size="sm"
                    title={"Report Post"}
                    show={viewModel.reportModal}
                    acceptButton={{
                        text: "Yes", variant: "success", icon: "check", isOutline: true,
                        onClick: () => viewModel.SubmitReport()
                    }}
                    onClose={() => { viewModel.reportModal = false; }}
                    closeButton={{
                        text: "No", variant: "warning", icon: "times", isOutline: true,
                    }}>
                    <div className="Container" >
                        <p>Are you sure you want to report this post</p>
                    </div>
                </Neo.Modal>

            </div>
        );
    }
}