import { Data, Model } from '@singularsystems/neo-core';
import { PlainTrackedObject } from '@singularsystems/neo-core/dist/Model';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../IdentityTypes';
import ChangePasswordLookup from '../../Models/Lookups/ChangePasswordLookup';
import UpdateUserProfileCommand from '../../Models/Users/Commands/UpdateUserProfileCommand';

export interface IUserProfileApiClient {

    /**
     * Verify users profile.
     * @param userId The user Id.
     * @param enteredOTP The entered OTP.
     * @returns IActionResult.
     */
    verifyUserProfile(userId: string, enteredOTP: number): AxiosPromise<any>;

    /** 
     * UpdateFullUserProfileAsync.
     * @param fullProfile fullProfile.
     * @returns IActionResult.
     */
    updateUserProfileAsync(fullProfile: Model.PartialPlainObject<UpdateUserProfileCommand>): AxiosPromise<any>;

    resetPasswordAsync(guid: string): AxiosPromise<any>;

    changePasswordAsync(changePassword: PlainTrackedObject<ChangePasswordLookup>): AxiosPromise<any>;

    // Client only properties / methods
}

@injectable()
export default class UserProfileApiClient extends Data.ApiClientBase implements IUserProfileApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/UserProfile`);
    }

    public verifyUserProfile(userId: string, enteredOTP: number): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/verify/${userId}/${enteredOTP}`);
    }

    public updateUserProfileAsync(fullProfile: Model.PartialPlainObject<UpdateUserProfileCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/update`, fullProfile);
    }

    public resetPasswordAsync(guid: string): AxiosPromise<any> {
        var url = `${this.apiPath}/resetPassword/${guid}`;
        return this.axios.get(url);
    }

    public  changePasswordAsync(changePassword: PlainTrackedObject<ChangePasswordLookup>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/changePassword`, changePassword);
    }

    // Client only properties / methods
}