import { Data, List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration.enum';
import { AppService, Types } from '../../../Identity/IdentityTypes';
import TDPUser from '../../../Identity/Models/Security/TDPUser';
import { ModerationState } from '../../Models/LearningObjects/ModerationState.enum';
import { RichTextEditorVM } from '../../Components/RichTextEditor';
import SelfAssessmentSearchCriteria from '../../Models/Criteria/SelfAssessmentSearchCriteria';
import SelfAssessmentLookup from '../../Models/Lookups/SelfAssessmentLookup';
import SelfAssessmentQuestionLookup from '../../Models/Lookups/SelfAssessmentQuestionLookup';
import { selfAssessmentTabs } from '../Assessment/SelfAssessmentView';
import { QuestionType, SelfAssessmentQuestionComponentVM } from '../Assessment/SelfAssessmentQuestionComponent';
import SelfAssessmentQuestionDragableModel from '../../Models/Lookups/SelfAssessmentQuestionDragableModel';
import SelfAssessmentOptions from '../../Models/Questions/Assessment/SelfAssessmentOptions';
import FileLookup from '../../Models/Lookups/FileLookup';
import SelfAssessmentModerationLookup from '../../Models/Lookups/SelfAssessmentModerationLookup';
import SelfAssessmentQuestionModerationLookup from '../../Models/Lookups/SelfAssessmentQuestionModerationLookup';
import LearningObjectContentLookup from '../../Models/Lookups/LearningObjectContentLookup';
import SelfAssessmentModerationProgressLookup from '../../Models/Lookups/SelfAssessmentModerationProgressLookup';
import SelfAssessmentQuestionModerationProgressLookup from '../../Models/Lookups/SelfAssessmentQuestionModerationProgressLookup';
import Tag2 from '../../Models/Tags/Tag2';
import { truncateLongString } from '../../../App/HelperClasses/GlobalHelpers';
import SelfAssessmentModerationSummary, { AssessmentQuestion, AssessmentQuestionContent, AssessmentTagsSummary } from '../../Models/Moderation/SelfAssessmentModerationSummary';

@NeoModel
export default class SelfAssessmentModerationVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private moderationApiClient = AppService.get(Types.Learning.ApiClients.ModerationQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private selfAssessmentApiClient = AppService.get(Types.Learning.ApiClients.SelfAssessmentApiClient),
    public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
    private userGroupQueryApiClient = AppService.get(Types.Authorisation.ApiClients.AuthorisationApiClient),
    private tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
    private learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
    private identityService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
    super(taskRunner);
    this.user = identityService.user;
  }

  // Self Assessment
  private user: TDPUser | null = null;
  public selfAssessmentCriteria = new SelfAssessmentSearchCriteria();
  public SelfAssessmentQuestionComponentVM = new SelfAssessmentQuestionComponentVM(this.taskRunner);
  public selectedSelfAssessmentId: number = 0;
  public selectedQuestionId: number = 0;
  public selfAssessment = new SelfAssessmentLookup();
  public moderateableSelfAssessment = new SelfAssessmentModerationLookup();
  public selfAssessmentQuestionList = new List(SelfAssessmentQuestionLookup);
  public selfAssessmentModerateableQuestion = new SelfAssessmentQuestionModerationLookup();
  public selectedTab = selfAssessmentTabs.selfAssessments;
  public showAcceptModal = false;
  public hideSAInfo = true;
  public hideSAQInfo = true;
  public showLinkedResource = true;
  public showAssessmentModerationNotes = false;
  public showAssessmentQuestionModerationNotes = false;
  public showAssessmentQuestionDnDModerationNotes = false;
  public showAssessmentQuestionContentModerationNotes = false;
  public richTextEditorVmModule = new RichTextEditorVM(this.taskRunner);
  public richTextEditorVmSAQuestion = new RichTextEditorVM(this.taskRunner);
  public richTextEditorVmSAQuestionMN = new RichTextEditorVM(this.taskRunner);
  public richTextEditorDNDMN = new RichTextEditorVM(this.taskRunner);
  public richTextEditorVmSAContentQuestionMN = new RichTextEditorVM(this.taskRunner);
  public questionsDragAndDropList: SelfAssessmentQuestionDragableModel[] = [];
  public questionOptionsList = new List(SelfAssessmentOptions);
  public questionLearningContent = new LearningObjectContentLookup();
  public fileDescriptorList = new List(FileLookup);
  public selectedMediaType: number = 0;
  public selectedQuestionTypeId: number = 0;
  public questionModerationProgress = new SelfAssessmentQuestionModerationProgressLookup();
  public selfAssessmentReviewSummary = '';
  public moderationStarted: boolean = true;
  public hideCriteria: boolean = false;

  // Moderation Pop ups
  public showReviewSummaryDrawer = false;
  public showReviewSummaryDrawerIsMaximised = true;
  public showAssessmentCompleteModerationModal = false;

  public superAdmin: boolean = false;

  /// Methods
  public async initialise() {
    this.selfAssessmentCriteria.ModerationState = ModerationState.SubmittedForModeration;
    this.selfAssessmentCriteria.StateSelectedUnknown = true;
    this.selfAssessmentCriteria.StateSelectedUnmoderated = true;
    this.selfAssessmentCriteria.StateSelectedSubmittedForModeration = true;
    this.selfAssessmentCriteria.StateSelectedUnderModeration = true;
    this.selfAssessmentCriteria.StateSelectedReview = true;
    this.selfAssessmentCriteria.StateSelectedModerated = true;
    this.selfAssessmentCriteria.StateSelectedResubmittedForModeration = true;
    this.selfAssessmentCriteria.Moderating = true;

    //Check if user is Super Admin
    const response = await this.userGroupQueryApiClient.userGroupQuery.userIsAdminGroup(this.user!.guid);
    this.superAdmin = response.data;
    this.selfAssessmentCriteria.IsSuperAdmin = this.superAdmin;
    this.SelfAssessmentLookupPageManager.refreshData();
  }

  private afterFetch(data: SelfAssessmentLookup[]) {
    if (this.selectedSelfAssessmentId !== 0) {
      data.forEach(assessment => {
        assessment.isExpanded = assessment.selfAssessmentId === this.selectedSelfAssessmentId;
      });
    }
  }

  public SelfAssessmentLookupPageManager = new Data.PageManager(this.selfAssessmentCriteria, SelfAssessmentLookup, this.selfAssessmentApiClient.getPagedSelfAssessmentLookup, {
    pageSize: 15,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "selfAssessmentId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
    afterFetch: this.afterFetch.bind(this)
  });

  public SearchSelfAssessments = async () => {
    this.selfAssessmentCriteria.StateSelectedUnknown = true;
    this.selfAssessmentCriteria.StateSelectedUnmoderated = true;
    this.selfAssessmentCriteria.StateSelectedSubmittedForModeration = true;
    this.selfAssessmentCriteria.StateSelectedUnderModeration = true;
    this.selfAssessmentCriteria.StateSelectedReview = true;
    this.selfAssessmentCriteria.StateSelectedModerated = true;
    this.selfAssessmentCriteria.StateSelectedResubmittedForModeration = true;
    this.selfAssessmentCriteria.Moderating = true;
    this.selfAssessmentCriteria.IsSuperAdmin = this.superAdmin;
    this.resetSelfAssessmentModerationScreen();
    this.SelfAssessmentLookupPageManager.refreshData();
  }

  public showCriteria() {
    this.hideCriteria = false;
    if (this.selectedSelfAssessmentId !== 0) {
      this.selfAssessmentCriteria.SelfAssessmentId = null;
      this.SelfAssessmentLookupPageManager.pageSize = 10;
      this.SelfAssessmentLookupPageManager.refreshData();
    }
  }

  public resetSelfAssessmentModerationScreen = async () => {
    this.selectedSelfAssessmentId = 0;
    this.moderateableSelfAssessment = new SelfAssessmentModerationLookup();
    this.selfAssessmentQuestionList = new List(SelfAssessmentQuestionLookup);
    this.selfAssessmentModerateableQuestion = new SelfAssessmentQuestionModerationLookup();
    this.questionLearningContent = new LearningObjectContentLookup();
    this.hideSAInfo = true;
    this.hideSAQInfo = true;
  }

  public StartModeration = async (id: number) => {
    this.selectedSelfAssessmentId = id;
    this.showAcceptModal = true;
  }

  public SetSelectedModerateableAssessment = async (id: number) => {
    this.selectedSelfAssessmentId = id;
    this.moderateableSelfAssessment = new SelfAssessmentModerationLookup();
    var response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getModerateableSelfAssessment(id));
    this.moderateableSelfAssessment.set(response.data);
    this.moderateableSelfAssessment.selfAssessmentDescription.meta.ApprovedByModerator.value = this.moderateableSelfAssessment.selfAssessmentDescription.fieldState === ModerationState.Moderated;
    this.moderateableSelfAssessment.selfAssessmentTitle.meta.ApprovedByModerator.value = this.moderateableSelfAssessment.selfAssessmentTitle.fieldState === ModerationState.Moderated;
    this.moderateableSelfAssessment.selfAssessmentTags2.forEach(element => {
      element.meta.approvedByModerator.value = element.fieldState === ModerationState.Moderated;
    });
    var responseQuestion = await this.taskRunner.waitFor(this.selfAssessmentApiClient.GetSelfAssessmentModerationQuestionLookupList(id));
    this.moderateableSelfAssessment.selfAssessmentQuestions.set(responseQuestion.data);
    this.moderateableSelfAssessment.selfAssessmentQuestions.forEach(element => {
      element.questionText.meta.ApprovedByModerator.value = element.questionText.fieldState === ModerationState.Moderated;
      element.tag3.meta.ApprovedByModerator.value = element.tag3.fieldState === ModerationState.Moderated;
      element.selfAssessmentOptions.meta.ApprovedByModerator.value = element.selfAssessmentOptions.fieldState === ModerationState.Moderated;
    });
    this.hideSAInfo = false;
    this.showAssessmentModerationNotes = false;
    this.showAssessmentQuestionDnDModerationNotes = false;
    this.FetchQuestionsForDnd(this.selectedSelfAssessmentId);
    this.hideSAQInfo = true;
    this.showLinkedResource = true;
    if (this.moderateableSelfAssessment.moderationState === ModerationState.UnderModeration || this.moderateableSelfAssessment.moderationState === ModerationState.ResubmittedForModeration) {
      this.moderationStarted = false;
    }
    else {
      this.moderationStarted = true;
    }
    this.hideCriteria = true;
  }

  public AcceptSelfAssessmentForModeration = () => this.taskRunner.run(async () => {
    await this.moderationApiClient.acceptSelfAssessmentForModeration(this.selectedSelfAssessmentId, this.user!.guid);
    this.showAcceptModal = false;
    this.selfAssessmentCriteria.ModerationState = ModerationState.UnderModeration
    this.SelfAssessmentLookupPageManager.refreshData();
    await this.SetSelectedModerateableAssessment(this.selectedSelfAssessmentId);
    this.notifications.addSuccess("Self Assessment Accepted for Moderation", `You can now Start Moderating this Self Assessment`, NotificationDuration.Standard);
  }).catch((ex: string) => {
    this.notifications.addDanger("Self Assessment Accepting Failed", `${ex}`, NotificationDuration.Standard);
  });

  public DeclineSelfAssessmentForModeration = async () => {
    this.showAcceptModal = false;
    this.selectedSelfAssessmentId = 0;
  }

  public SaveAssessmentModerationState = (isSave: boolean) => this.taskRunner.run(async () => {
    var saveSelfAssessmentProgress = new SelfAssessmentModerationProgressLookup();
    saveSelfAssessmentProgress.selfAssessmentTitleApproved = this.moderateableSelfAssessment.selfAssessmentTitle.meta.ApprovedByModerator.value;
    saveSelfAssessmentProgress.selfAssessmentDescriptionApproved = this.moderateableSelfAssessment.selfAssessmentDescription.meta.ApprovedByModerator.value;
    this.moderateableSelfAssessment.selfAssessmentTags2.set(this.moderateableSelfAssessment.selfAssessmentTags2.toJSArray({ includeClean: true }));
    this.moderateableSelfAssessment.selfAssessmentTags2.forEach(saTag => {
      saTag.fieldState = saTag.approvedByModerator ? ModerationState.Moderated : ModerationState.Review;
      saTag.filteredTag2List = new List(Tag2);
    });
    saveSelfAssessmentProgress.selfAssessmentTags2 = this.moderateableSelfAssessment.selfAssessmentTags2;
    saveSelfAssessmentProgress.selfAssessmentModerationNotes = this.moderateableSelfAssessment.meta.moderationNotes.value;
    saveSelfAssessmentProgress.selfAssessmentId = this.moderateableSelfAssessment.selfAssessmentId;
    saveSelfAssessmentProgress.selfAssessmentQuestionDnDModerationNotes = this.moderateableSelfAssessment.selfAssessmentQuestionDnDModerationNotes;
    saveSelfAssessmentProgress.selfAssessmentTitle = this.moderateableSelfAssessment.selfAssessmentTitle.value;
    saveSelfAssessmentProgress.selfAssessmentDescription = this.moderateableSelfAssessment.selfAssessmentDescription.value;

    var response = await this.moderationApiClient.saveAssessmentModerationState(saveSelfAssessmentProgress.toJSObject({ includeClean: true }), this.user!.guid)
    if (response.data) {
      if (isSave) {
        this.notifications.addSuccess("Self Assessment Moderation progress saved.", null, NotificationDuration.Standard);
      }
      this.SelfAssessmentLookupPageManager.refreshData();
    }
  }).catch((ex: string) => {
    this.notifications.addDanger("Self Assessment Moderation progress could not be saved.", `${ex}`, NotificationDuration.Standard);
  })

  public SaveQuestionModerationState = (isSave: boolean) => this.taskRunner.run(async () => {
    this.questionModerationProgress.questionTextApprovedByModerator = this.selfAssessmentModerateableQuestion.questionText.meta.ApprovedByModerator.value;
    this.questionModerationProgress.selfAssessmentOptionsApprovedByModerator = this.selfAssessmentModerateableQuestion.selfAssessmentOptions.meta.ApprovedByModerator.value;
    this.questionModerationProgress.tag3ApprovedByModerator = this.selfAssessmentModerateableQuestion.tag3.meta.ApprovedByModerator.value;
    this.questionModerationProgress.selfAssessmentQuestionId = this.selfAssessmentModerateableQuestion.selfAssessmentQuestionId;
    this.questionModerationProgress.moderationNotes = this.selfAssessmentModerateableQuestion.meta.moderationNotes.value;
    this.questionModerationProgress.questionText = this.selfAssessmentModerateableQuestion.questionText.value;
    await this.SaveAssessmentModerationState(isSave);
    var response = await this.moderationApiClient.saveAssessmentQuestionModerationState(this.questionModerationProgress.toJSObject(), this.user!.guid);
    if (isSave) {
      if (response.data) {
        this.notifications.addSuccess("Self Assessment Question Moderation progress saved.", null, NotificationDuration.Standard);
      }
    }
  }).catch((ex: string) => {
    this.notifications.addDanger("Self Assessment Question Moderation progress could not be saved.", `${ex}`, NotificationDuration.Standard);
  })

  public SaveQuestionContentModerationState = (isSave: boolean) => this.taskRunner.run(async () => {
    this.questionModerationProgress.learningContentId = this.questionLearningContent.learningContentId;
    this.questionModerationProgress.contentModerationNotes = this.questionLearningContent.meta.selfAssessmentContentModerationNotes.value;
    this.questionModerationProgress.contentApprovedByModerator = this.questionLearningContent.meta.approvedByModerator.value;
    this.questionModerationProgress.selfAssessmentQuestionId = this.selfAssessmentModerateableQuestion.selfAssessmentQuestionId;
    this.questionModerationProgress.contentTitle = this.questionLearningContent.learningContent.meta.title.value;
    await this.SaveQuestionModerationState(isSave);

    var response = await this.moderationApiClient.saveAssessmentContentModerationState(this.questionModerationProgress.toJSObject(), this.user!.guid);
    if (isSave) {
      if (response) {
        this.notifications.addSuccess("Self Assessment Content Moderation progress saved.", null, NotificationDuration.Standard);
      }
    }
  }).catch((ex: string) => {
    this.notifications.addDanger("Self Assessment Content Moderation progress could not be saved.", `${ex}`, NotificationDuration.Standard);
  });

  public FetchQuestionsForDnd = async (selfAssessmentId: number) => {
    var responseDnd = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentQuestionDNDObjects(selfAssessmentId));

    if (responseDnd.data.length > 0) {
      responseDnd.data.forEach(element => {
        element.text = truncateLongString(JSON.parse(element.text).blocks[0].text);
      });
      return this.questionsDragAndDropList = responseDnd.data;
    }
    else {
      return this.questionsDragAndDropList;
    }
  }

  public SelectQuestionForModeration = async (questionId: number) => this.taskRunner.run(async () => {

    await this.SetSelectedModerateableAssessment(this.selectedSelfAssessmentId);
    this.questionModerationProgress = new SelfAssessmentQuestionModerationProgressLookup();
    var questionToModerate = this.moderateableSelfAssessment.selfAssessmentQuestions.find(c => c.selfAssessmentQuestionId === questionId);
    if (questionToModerate) {
      this.selfAssessmentModerateableQuestion.set(questionToModerate.toJSObject());
      this.selectedQuestionTypeId = questionToModerate.questionTypeId;
      if (this.selectedQuestionTypeId === QuestionType.LikertScale) {
        var optionsResponse = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentOptions(questionToModerate.sentiment, 4));
        this.selfAssessmentModerateableQuestion.selfAssessmentOptionList.set(optionsResponse.data);
      }
      else if (this.selectedQuestionTypeId === QuestionType.MultipleChoice) {
        var optionsResponseMulti = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentOptionsMulti(questionId));
        this.selfAssessmentModerateableQuestion.selfAssessmentOptionList.set(optionsResponseMulti.data);
      }

      var moderatableTag = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentQuestionModerationTagLookup(questionId));
      this.selfAssessmentModerateableQuestion.moderatableTag3Name = moderatableTag.data.tag3Name;
      this.selectedQuestionId = this.selfAssessmentModerateableQuestion.selfAssessmentQuestionId;
      this.showAssessmentQuestionModerationNotes = false;
      this.hideSAQInfo = false;
      if (this.selfAssessmentModerateableQuestion.contentId !== null && this.selfAssessmentModerateableQuestion.contentId !== 0) {
        const questionLearningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentById(this.selfAssessmentModerateableQuestion.contentId);
        if (questionLearningContent != null) {
          this.showLinkedResource = false;
          this.questionLearningContent.set(questionLearningContent.data);
          this.fileDescriptorList.set([]);
          var fileDescriptor = new FileLookup();
          fileDescriptor.fileDescriptorId = this.questionLearningContent.learningContent.mediaObject?.fileDescriptorId as string;
          fileDescriptor.fileName = this.questionLearningContent.learningContent.mediaObject?.fileName as string;
          fileDescriptor.mediaTypeId = this.questionLearningContent.mediaTypeFetched;
          fileDescriptor.mimeType = this.questionLearningContent.learningContent.mediaObject?.extension as string;
          this.fileDescriptorList.push(fileDescriptor);
          this.selectedMediaType = this.questionLearningContent.mediaTypeFetched;
          this.questionLearningContent.meta.learningContent.value.mediaType = this.questionLearningContent.mediaTypeFetched;
          this.showAssessmentQuestionContentModerationNotes = false;
        }
        else {
          this.showLinkedResource = true;
        }
      }
    }
  }).catch((ex: string) => {
    this.notifications.addDanger("Something went wrong.", `${ex}`, NotificationDuration.Standard);
  });

  public moderationCompleteOrReviewState = (selfAssessmentId: number): boolean => {
    this.SetSelectedModerateableAssessment(selfAssessmentId);
    var allTagsModerated = this.CheckSelfAssessmentTag2sModerated();

    if (this.moderateableSelfAssessment.selfAssessmentDescription.fieldState !== ModerationState.Moderated
      || this.moderateableSelfAssessment.selfAssessmentTitle.fieldState !== ModerationState.Moderated
      || allTagsModerated) {
      return true;
    }
    else {
      return false;
    }
  }

  public moderationSummary = new SelfAssessmentModerationSummary();
  public GetModerationSummary = async (selfAssessmentId: number) => {
    this.taskRunner.run(async () => {
      await this.SetSelectedModerateableAssessment(selfAssessmentId);
      const assessmentInfo = this.moderateableSelfAssessment;
      /// Set the assessment moderation info.
      this.moderationSummary = new SelfAssessmentModerationSummary();
      this.moderationSummary.selfAssessmentId = assessmentInfo.selfAssessmentId;
      this.moderationSummary.title = assessmentInfo.selfAssessmentTitle.value;
      this.moderationSummary.titleApproved = assessmentInfo.selfAssessmentTitle.fieldState === ModerationState.Moderated;
      this.moderationSummary.description = assessmentInfo.selfAssessmentDescription.value;
      this.moderationSummary.descriptionApproved = assessmentInfo.selfAssessmentDescription.fieldState === ModerationState.Moderated;
      this.moderationSummary.baseAssessmentApproved = this.moderationSummary.titleApproved && this.moderationSummary.descriptionApproved;
      this.moderationSummary.moduleNotes = assessmentInfo.moderationNotes;
      /// Check that the assessment tags are moderated 
      assessmentInfo.selfAssessmentTags2.forEach(tagLookUp => {
        var tagSummary = new AssessmentTagsSummary();
        tagSummary.tag1Id = tagLookUp.tag1Id;
        tagSummary.tag2Id = tagLookUp.tag2Id;
        tagSummary.tag1Name = tagLookUp.tag1Name;
        tagSummary.tag2Name = tagLookUp.tag2Name;
        tagSummary.approved = tagLookUp.fieldState === ModerationState.Moderated;
        tagSummary.errorMessage = tagSummary.approved ? "" : `Category Tag: ${tagLookUp.tag1Name} with Sub-Category Tag: ${tagLookUp.tag2Name} is not approved.`;
        this.moderationSummary.assessmentTags.push(tagSummary);
        if (!tagSummary.approved) {
          this.moderationSummary.tagsApproved = false;
        }
      });

      /// Check that the questions are moderated
      assessmentInfo.selfAssessmentQuestions.forEach(async (assessmentQuestion) => {

        var questionSummary = new AssessmentQuestion();
        questionSummary.questionId = assessmentQuestion.selfAssessmentQuestionId;
        questionSummary.question = assessmentQuestion.questionText.value;
        questionSummary.questionApproved = assessmentQuestion.questionText.fieldState === ModerationState.Moderated;
        questionSummary.tag3Approved = assessmentQuestion.tag3.fieldState === ModerationState.Moderated;
        questionSummary.optionsApproved = assessmentQuestion.selfAssessmentOptions.fieldState === ModerationState.Moderated;
        questionSummary.questionNotes = assessmentQuestion.moderationNotes;
        questionSummary.assessmentQuestionApproved = questionSummary.questionApproved && questionSummary.tag3Approved && questionSummary.optionsApproved;
        if (!questionSummary.assessmentQuestionApproved) {
          this.moderationSummary.assessmentQuestionsApproved = false;
        }
        if (assessmentQuestion.contentId !== null) {

          const questionLearningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentById(assessmentQuestion.contentId);
          this.questionLearningContent.set(questionLearningContent.data);

          var questionContent = new AssessmentQuestionContent();
          questionContent.contentId = this.questionLearningContent.learningContentId;
          questionContent.contentTitle = this.questionLearningContent.learningContent.title;
          questionContent.contentApproved = this.questionLearningContent.learningContent.moderation.moderationState === ModerationState.Moderated;

          questionSummary.questionContent = questionContent;
          if (!questionContent.contentApproved) {
            questionSummary.questionContentApproved = false;
          }
        }
        this.moderationSummary.assessmentQuestions.push(questionSummary);
      })


      if (this.moderationSummary.baseAssessmentApproved && this.moderationSummary.assessmentQuestionsApproved && this.moderationSummary.tagsApproved) {
        this.showAssessmentCompleteModerationModal = true;
      } else {
        this.showReviewSummaryDrawer = true;
      }
    })
  }

  public CheckSelfAssessmentTag2sModerated = (): boolean => {
    this.moderateableSelfAssessment.selfAssessmentTags2.toJSArray({ includeClean: true }).forEach(element => {
      if (element.fieldState !== ModerationState.Moderated) {
        return false;
      }
      else {
        return true;
      }
    });
    return true;
  }

  public SendSelfAssessmentForReview = async () => {
    var response = await this.moderationApiClient.sendSelfAssessmentForReview(this.selectedSelfAssessmentId, this.user!.guid);
    if (response.data) {
      this.SelfAssessmentLookupPageManager.refreshData();
      this.selfAssessmentReviewSummary = "";
      this.showReviewSummaryDrawer = false;
      this.selectedSelfAssessmentId = 0;
      this.notifications.addSuccess("Self Assessment sent for Review", "Self Assessment has been sent back for review", NotificationDuration.Standard);
    }
  }

  public CompleteModeration = () => this.taskRunner.run(async () => {
    await this.moderationApiClient.completeSelfAssessmentModeration(this.selectedSelfAssessmentId, this.user!.guid);
    this.showAssessmentCompleteModerationModal = false;
    this.SelfAssessmentLookupPageManager.refreshData();
    this.notifications.addSuccess("Self Assessment Moderation Completed", `Self Assessment has been successfully moderated`, NotificationDuration.Standard);
  }).catch((ex: string) => {
    this.notifications.addDanger("Self Assessment Moderation Failed", `${ex}`, NotificationDuration.Standard);
  });

  /// Gets and set the Download path from Blob storage
  public DownloadFileGet(this: any, fileDescriptorId: string, mediaTypeId: number, link: string) {
    this.taskRunner.run(async () => {
      var response = await this.learningModuleApiClient.getFileDownloadURL(fileDescriptorId, mediaTypeId);
      if (response.data) {
        window.open(response.data.toString(), '_blank');
      }
    });
  }
}