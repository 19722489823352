import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../LearningTypes';
import Tag2 from '../../Models/Tags/Tag2';

export interface ITag2QueryApiClient {

    /**
     * This method will return a sepcific tag2 based on the tag2Id with children
     * @returns Tag2 List with Children
     */
    getTag2tList(): AxiosPromise<Model.PlainTrackedObject<Tag2>>;

    /**
     * This method will return a sepcific tag2 based on the tag2Id with children
     * @param tag2Id The page request
     * @returns tag2 List with Children
     */
    getTag2(tag2Id: number): AxiosPromise<Model.PlainTrackedObject<Tag2>>;

    /** 
     * This method will return a sepcific tag2 based on the tag2Id with children
     * @param tag2Id The page request
     * @returns tag2 List with Children
     */
    getTag2ById(tag2Id: number): AxiosPromise<Model.PlainTrackedObject<Tag2>>;

    /**
     * Get Tag2 List by tag1 ID
     * @param tag1Id tag1Id
     * @returns Tag2 List
     */
    getTag2ListByTag1Id(tag1Id: number): AxiosPromise<Model.PlainTrackedObject<Tag2>[]>;

    // Client only properties / methods
}

@injectable()
export default class Tag2QueryApiClient extends Data.ApiClientBase implements ITag2QueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/Tag2Query`);
    }

    public getTag2tList(): AxiosPromise<Model.PlainTrackedObject<Tag2>> {
        return this.axios.get(`${this.apiPath}/Tags`);
    }

    public getTag2(tag2Id: number): AxiosPromise<Model.PlainTrackedObject<Tag2>> {
        return this.axios.get(`${this.apiPath}/Tag2/tag2Id=${tag2Id}`);
    }

    public getTag2ById(tag2Id: number): AxiosPromise<Model.PlainTrackedObject<Tag2>> {
        return this.axios.get(`${this.apiPath}/Tag2ById/${tag2Id}`);
    }

    // public getTag2ListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<Tag2SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<Tag2Lookup>>> {
    //     return this.axios.get(`${this.apiPath}/TagsPaged?${Utils.getQueryString(request)}`);
    // }

    public getTag2ListByTag1Id(tag1Id: number): AxiosPromise<Model.PlainTrackedObject<Tag2>[]> {
        return this.axios.get(`${this.apiPath}/Tag2ByTag1Id/${tag1Id}`);
    }

    // public getTag2ListPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<Tag2SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<Tag2Lookup>>> {
    //     return this.axios.get(`${this.apiPath}/TagsPaged?${Utils.getQueryString(request)}`);
    // }

    // Client only properties / methods
}