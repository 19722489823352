import { AppServices } from '@singularsystems/neo-core';
import { NeoReactTypes } from '@singularsystems/neo-react';
import { AppConfig } from './Models/AppConfig';
import { AppDataService } from './Services/AppDataService';
import { RouteService } from './Services/RouteService';
import AppLayout from './Services/AppLayout';

import { OrganisationExportedTypes } from '../Organisations/OrganisationExportedTypes'
import { LearningExportedTypes } from '../Learning/LearningExportedTypes';
import { AuthorisationSharedTypes } from '../Authorisation/AuthorisationSharedTypes';
import { IdentitySharedTypes } from '../Identity/IdentitySharedTypes';
import { ForumExportedTypes } from '../Forum/ForumExportedTypes';
import { DashboardExportedTypes } from '../Dashboard/DashboardExportedTypes';

const Types = {
    App: {
        Services: {
            AppDataCache: new AppServices.ServiceIdentifier<AppDataService>("Services.AppDataCache"),
            AppLayout: new AppServices.ServiceIdentifier<AppLayout>("Services.AppLayout"),
            RouteService: new AppServices.ServiceIdentifier<RouteService>("Services.RouteService"),
        },
        Config: AppServices.NeoTypes.Config.ConfigModel.asType<AppConfig>(),
    },
    Neo: NeoReactTypes,
    Organisations: OrganisationExportedTypes,
    Learning: LearningExportedTypes,
    Authorisation: AuthorisationSharedTypes,
    Identity: IdentitySharedTypes,
    Forum: ForumExportedTypes,
    Dashboard: DashboardExportedTypes
};

export default Types;