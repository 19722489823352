import { Attributes, List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import Circuit from './Circuit';

@NeoModel
export default class District extends ModelBase {

    public districtId: number = 0;

    @Rules.Required()
    public provinceId: number = 0;

    @Rules.Required()
    @Rules.StringLength(50)
    public districtName: string = "";

    @Rules.Required()
    @Rules.StringLength(500)
    public districtDetails: string = "";

    public circuits = new List(Circuit);

    public isExpanded: boolean = false

    //Client Only
    public provinceName: string = "";

    @Attributes.NoTracking()
    public moveToProvinceId: number = 0;
}