import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class SchoolLookup extends ModelBase {

    public schoolId: number = 0;

    public circuitId: number = 0;

    public schoolName: string = "";

    public townshipVillage: string = "";

    public suburb: string = "";

    public streetAddressLine1: string = "";

    public streetAddressLine2: string = "";

    public streetAddressLine3: string = "";

    public streetAddressPostalCode: string = "";

    public postalAddressLine1: string = "";

    public postalAddressLine2: string = "";

    public postalAddressLine3: string = "";

    public postalAddressPostalCode: string = "";

    public telephone: string = "";

    public schoolStatusId: number = 0;

    public schoolSectorId: number = 0;

    public schoolPhaseId: number = 0;

    @Attributes.Float()
    public schoolLatitude: number = 0;

    @Attributes.Float()
    public schoolLongitude: number = 0;

    public townCity: string = "";

    // Client only properties / methods

    public circuitName: string = "";

    public distictName: string = "";

    public districtId: number = 0;

    public provinceName: string = "";

    public provinceId: number = 0;

    public organisationId: number = 0;
}