import { Attributes, ModelBase, NeoModel, Rules, Utils, Validation } from '@singularsystems/neo-core';
import { LearningPathwayParticipantStatus } from './LearningPathwayParticipantStatus.enum';

@NeoModel
export default class LearningPathwayParticipant extends ModelBase {

    public learningPathwayParticipantId: number = 0;

    public participant: string = Utils.emptyGuid();

    public learningPathwayParticipantStatus: LearningPathwayParticipantStatus | null = null;

    @Attributes.Float()
    public latestFinalQuizResult: number = 0;

    @Rules.Required()
    @Attributes.Date()
    public latestFinalQuizDate: Date | null = null;

    public learningPathwayId: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.participant) {
            return "New learning pathway participant";
        } else {
            return this.participant;
        }
    }
}