import * as React from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import "../../Styles/VerticalDragAndDrop.scss";
import SelfAssessmentQuestionDragableModel from '../../Models/Lookups/SelfAssessmentQuestionDragableModel';

interface Props {
  items: SelfAssessmentQuestionDragableModel[];
  setDNDItems: (items: Array<SelfAssessmentQuestionDragableModel>) => void;
}

export default class QuestionOrderComponent extends React.Component<Props> {

  render() {
    const items = this.props.items;
    return (
      <div className="vertical">
      <div className="row dndOverFlow">
        <div className="col-md-8">
          <RLDD cssClasses="example-list-container" layout="vertical" items={items} itemRenderer={this.itemRenderer} onChange={this.handleRLDDChange} />
        </div>
        <div className="col-md-4 description-box">
          <p className="description">Drag And Drop items to reorder them. Remember to save</p>
        </div>
      </div>
    </div>
    );
  }

  private itemRenderer = (item: SelfAssessmentQuestionDragableModel, index: number): JSX.Element => {
    return (
      <div className="item">
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              {item.text}
            </div>
          </div>
          <div className="col-md-12" >
            <div className="purpose">
              {item.questionType}
            </div>
          </div> 
          <div className="col-md-12" >
            <div className="order">
              Order:  {index + 1}
            </div>
          </div>
        </div>
      </div>
    );
  };

  private handleRLDDChange = (reorderedItems: Array<SelfAssessmentQuestionDragableModel>) => {
    this.props.setDNDItems(reorderedItems)
  };
}
