import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../ForumTypes';
import ForumLookup from '../../../Models/Lookups/ForumLookup';
import ForumSearchCriteria from '../../../Models/Lookups/ForumSearchCriteria'

export interface IForumQueryApiClient {

    /** 
     * Get Forum by ForumId
     * @param forumId forumId
     * @returns ForumLookup
     */
    getForumById(forumId: number): AxiosPromise<Model.PlainObject<ForumLookup>>;

    /** 
     * Get Topics by Creator Guid
     * @param createdBy Created By Guid
     * @returns List of ForumLookup
     */
    getForumsByCreatorAsync(createdBy: string): AxiosPromise<Array<Model.PlainObject<ForumLookup>>>;

    /** 
     * Get Forum by TopicId
     * @param topicId topicId
     * @returns List of TopicLookup
     */
    getForumByTopicAsync(topicId: number): AxiosPromise<Model.PlainObject<ForumLookup>>;

    /** 
     * Get Forums by Posters Guid
     * @param postedBy The Guid of the person in any posts in any topics in any forums.
     * @returns List of ForumLookup where the person has posted in in any posts
     */
    getForumsByPosterAsync(postedBy: string): AxiosPromise<Array<Model.PlainObject<ForumLookup>>>;

    /** 
     * Get non-deleted Forums by the specified criteria.
     * @param request request
     * @returns List of ForumLookup
     */
    getPagedForumsByCriteriaAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ForumSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ForumLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class ForumQueryApiClient extends Data.ApiClientBase implements IForumQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.ForumApi.ApiPath}/ForumQuery`);
    }

    public getForumById(forumId: number): AxiosPromise<Model.PlainObject<ForumLookup>> {
        return this.axios.get(`${this.apiPath}/GetForumById/${forumId}`);
    }

    public getForumsByCreatorAsync(createdBy: string): AxiosPromise<Array<Model.PlainObject<ForumLookup>>> {
        return this.axios.get(`${this.apiPath}/GetForumsByCreator/${encodeURIComponent(createdBy)}`);
    }

    public getForumByTopicAsync(topicId: number): AxiosPromise<Model.PlainObject<ForumLookup>> {
        return this.axios.get(`${this.apiPath}/GetForumsByTopic?topicId=${topicId}`);
    }

    public getForumsByPosterAsync(postedBy: string): AxiosPromise<Array<Model.PlainObject<ForumLookup>>> {
        return this.axios.get(`${this.apiPath}/GetForumsByPoster/${encodeURIComponent(postedBy)}`);
    }

    public getPagedForumsByCriteriaAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ForumSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ForumLookup>>> {
        return this.axios.get(`${this.apiPath}/GetPagedForumsByCriteria?${Utils.getQueryString(request)}`);
    }

    // Client only properties / methods
}