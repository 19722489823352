import { Components, NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from '@singularsystems/neo-react';
import { AppService } from "../../App/Services/AppService";
import UserDataImportResult from "../Models/Commands/UserDataImportResult";
import OrgLevel from "../Models/Users/OrgLevel";

import Types from '../../App/AppTypes';
import React from "react";
import { observer } from "mobx-react";
import CardHeader from "../../Template/components/Card/CardHeader";
import Card from "../../Template/components/Card/Card";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
interface IUserDataImportModalProps {
    viewModal: UserDataImportModalVM;
    usersImported: () => void;
}

@NeoModel
export class UserDataImportModalVM extends Views.ViewModelBase {

    public importResult?: UserDataImportResult;

    public orgLevel: OrgLevel;

    public orgLevelName: string;

    public usersImported!: () => void;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private importApiClient = AppService.get(Types.Identity.ApiClients.UserDataImportApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
        this.orgLevelName = "";
        this.orgLevel = new OrgLevel();
    }

    public showUserImportModal = false;

    public ClearOrgLevels() {
        this.orgLevel.schoolManagementTeamId = null;
        this.orgLevel.schoolId = null;
        this.orgLevel.circuitId = null;
        this.orgLevel.districtId = null;
        this.orgLevel.provinceId = null;
        this.orgLevel.organisationId = null;
    }

    public fileManager = new Components.FileManager(this.importApiClient.getUploadUserFileUrl(),
        {
            afterUpload: this.afterUpload.bind(this),
            onFilesSelected: this.onUploadFilesSelected.bind(this),
            allowedExtensions: [".xlsx", ".xls"]
        });

    private onUploadFilesSelected(fileList: File[] | FileList) {
        this.fileManager.uploadFiles(fileList);
    }

    private afterUpload(fileKey: string) {
        const self = this;
        this.taskRunner.run("Importing file", async () => {

            const result = (await self.importApiClient.doImport(fileKey, this.orgLevel));
            self.importResult = new UserDataImportResult();
            self.importResult.set(result.data);
            //self.importResult = UserDataImportResult.fromJSObject<UserDataImportResult>(result.data);
            if ((self.importResult.fileErrors && self.importResult.fileErrors.length) || (self.importResult.rowErrors && self.importResult.rowErrors.length)) {
                // stick around
                var errors = self.importResult.fileErrors.toString() + self.importResult.rowErrors.toString();
                this.notifications.addSuccess("User Data Imported. " + errors, null, 30);
                this.usersImported();
                this.showUserImportModal = false;
            } else {
                // add a notification
                this.notifications.addSuccess("User Data Imported", null, 4);
                this.usersImported();
                this.showUserImportModal = false;
            }
        });
    }

    public uploadRequested() {
        this.fileManager.showFileDialog();
    }

    public show() {
        this.showUserImportModal = true;
    }
}

@observer
export default class UserDataImportModal extends React.Component<IUserDataImportModalProps> {

    public render() {

        const viewModel = this.props.viewModal;

        viewModel.usersImported = this.props.usersImported;

        return (
            <Neo.Modal size="xl"
                title={`User Data Import for ` + viewModel.orgLevelName}
                show={viewModel.showUserImportModal}
                onClose={() => { viewModel.showUserImportModal = false; }}
                closeButton={{
                    text: "Cancel", variant: "secondary", icon: "times"
                }} >
                <div>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="success">
                                <i className={`fa fa-folder`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">File selection</h4>
                        </CardHeader>
                        <CardBody>
                            <Neo.Loader task={viewModel.taskRunner}>
                                <Neo.FileDropArea fileManager={viewModel.fileManager}>
                                    To upload files, drop file into this block or <Neo.Button variant="primary" onClick={() => viewModel.uploadRequested()}>Click here</Neo.Button>
                                    <Neo.FileUploadProgress fileManager={viewModel.fileManager} />
                                </Neo.FileDropArea>
                            </Neo.Loader>
                        </CardBody>
                    </Card>
                </div>
            </Neo.Modal >
        )
    }
}