import React from "react";
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import dashboardStyles from '../../Template/assets/tss/material-dashboard-react/views/dashboardStyle';

interface IImageCardProps {
  image: string;
  children?: React.ReactNode;
  hidden?: boolean;
  className?: any;
  imageClassName? : any;
}

export default function ImageCard(props: IImageCardProps) {
  const classes = dashboardStyles();
  return (
    <div className={props.className} style={{ maxWidth: '180px' }}>
      <Card product className={classes.cardHover} hidden={props.hidden} >
        <CardHeader image className={classes.cardHeaderHover} >
          <img src={props.image} className={props.imageClassName} alt="" />
        </CardHeader>
        {props.children}
      </Card>
    </div>
  )
}