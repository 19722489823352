import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class LearningPathwayShareModel extends ModelBase {

    @Rules.Required()
    @Attributes.OnChanged<LearningPathwayShareModel>(c => c.validEmail)
    public userEmail: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.failWhen(c => c.validEmail(c.userEmail as string) === false, "Please Enter a valid Email");
    }

    public toString(): string {
    if (this.isNew || !this.userEmail) {
      return "New learning pathway share model";
        } else {
      return this.userEmail;
        }
    }

    private validEmail(str: string) {
    str = this.userEmail as string;
    if (str) {
      var regexp = new RegExp(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      return !!regexp.test(str);
        }
    else {
      return true;
        }
    }
}