import { Attributes, List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import Tag2Lookup from "./Tag2Lookup";

@NeoModel
export default class Tag1Lookup extends LookupBase {

    public tag1Id: number = 0;

    public tag2Id: number = 0;

    public tagName: string = "";

    public tag3Id: number = 0;

    public tagDescription: string = "";

    @Attributes.Observable()
    public isExpanded: boolean = false;

    public tags2 = new List(Tag2Lookup);

}