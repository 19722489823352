import classNames from 'classnames';
import cardIconStyles from '../../assets/tss/material-dashboard-react/components/CardStyles/cardIconStyles';
import React from 'react'

interface ICardIconProps {
  className?: string;
  color?: ("warning" | "success" | "danger" | "info" | "primary" | "rose");
  children: React.ReactNode,
}

export default function CardIcon(props: ICardIconProps) {
  const classes = cardIconStyles();
  const { className, children, color, ...rest } = props;

  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color + "CardHeader"]]: color,
    [className as any]: className !== undefined,
  });
  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
}