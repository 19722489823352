import { Attributes, Data, List, NeoModel } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { AppService } from "../../App/Services/AppService";
import Types from '../../App/AppTypes';
import Educator from "../Models/Educators/PersalEducator"
import EducatorSearchCriteria from "../Models/Criteria/PersalEducatorSearchCriteria";
import EducatorLookup from "../Models/Lookups/PersalEducatorLookup";
import { injectable } from "inversify";
import { observer } from "mobx-react";
import React from "react";
import PersalEducatorImportModal, { PersalEducatorImportModalVM } from "../Components/PersalEducatorImportModal";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";

interface IPersalImportComponentProps {
    viewModel?: PersalImportComponentVM;
}

@injectable()
@NeoModel
export class PersalImportComponentVM extends Views.ViewModelBase {

    public educatorsList = new List(Educator);
    public selectedEducatorId: number = 0;
    public educatorImportModalVM!: PersalEducatorImportModalVM;
    public criteria = new EducatorSearchCriteria();
    public showEditModal: boolean = false;
    public showConfirmDeleteModal: boolean = false;
    public selectedEducator = new Educator();

    @Attributes.Date()
    public lastImportDate: Date = new Date(1900, 1, 1);

    public educatorLookupPageManager = new Data.PageManager(this.criteria, EducatorLookup, this.educatorQueryApiClient.getEducatorsPaged, {
        pageSize: 10,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sortBy: "surname",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner,
    });

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private educatorQueryApiClient = AppService.get(Types.Identity.ApiClients.PersalEducatorQueryApiClient),
        public importApiClient = AppService.get(Types.Identity.ApiClients.PersalEducatorImportApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
        this.educatorImportModalVM = new PersalEducatorImportModalVM(this.taskRunner);
    }

    public async initialise() {
        this.autoDispose(this.criteria.onAnyPropertyChanged(() => this.educatorLookupPageManager.refreshData()));
    }

    public async ShowEducatorImportModal() {
        this.educatorImportModalVM.show()
    }

    public async CloseEducatorImportModal() {
        this.educatorImportModalVM.showEducatorImportModal = false;
    }
}

class SearchEducatorsViewParams {
    public searchString = { required: false, isQuery: true };
}

@observer
export default class PersalImportView extends React.Component<IPersalImportComponentProps, SearchEducatorsViewParams>{
    constructor(props:IPersalImportComponentProps ) {
        super(props);
        this.viewModel = new PersalImportComponentVM();
    }
    public static params = new SearchEducatorsViewParams();
    public viewModel: PersalImportComponentVM = new PersalImportComponentVM();    

    protected viewParamsUpdated() {
        /*  if (this.viewParams.searchString.value !== "") {
              if (this.viewModel.criteria !== null) {
                  this.viewModel.criteria.keyword = (this.viewParams.searchString.value as string);
              }
          }*/
    }

    public async ShowEducatorImportModal() {
        this.viewModel.educatorImportModalVM.show()
    }

    public async CloseEducatorImportModal() {
        this.viewModel.educatorLookupPageManager.refreshData(true, this.viewModel.taskRunner);
        this.viewModel.educatorImportModalVM.showEducatorImportModal = false;
    }

    private showFilter() {
        return this.viewModel.educatorLookupPageManager.data.length > 5;
    }

    private async updateImportDate() {
        var result = await this.viewModel.importApiClient.lastImportDate();
        if (result.status === 200) {
            this.viewModel.lastImportDate = new Date(result.data);
        } else {
            this.viewModel.lastImportDate = new Date(1900, 1, 1);
        }
    }
    public async CloseModelAndRefreshDate() {
        this.viewModel.taskRunner.run("Fetching last import date.", async () => {
            this.updateImportDate();
        });
    }  

    public componentDidMount() {
        this.viewModel.taskRunner.run(async () => {
            this.updateImportDate();
        });
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-book-reader fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Persal Import Data</h4>
                            </CardHeader>
                            <CardBody>
                                <Neo.FormGroup bind={this.viewModel.meta.lastImportDate}  dateProps={{formatString:`dd-MMMM-yyyy HH:mm:ss` }} readOnly />
                                <div className="row mb-2">
                                    <div className="col-md-2 float-right">
                                        <Neo.Button variant="success" isOutline icon="import"
                                            onClick={() => this.viewModel.ShowEducatorImportModal()} >Import Persal Data</Neo.Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <Neo.Input className=" cerebri-regular font-12" placeholder="Search Persal data" bind={this.viewModel.criteria.meta.keyword} />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12">
                                        <Neo.Pager
                                            pageManager={this.viewModel.educatorLookupPageManager}
                                            pageControlProps={{
                                                firstText: "",
                                                prevText: "Prev",
                                                nextText: "Next",
                                                lastText: "",
                                                alignment: "center",
                                                // show the page controls even if the amount of items is less than a single page size
                                                autoHide: false,
                                                pageSizeLabel: "Show"
                                            }}>
                                            <NeoGrid.Grid<EducatorLookup> className="table-hover" >
                                                {(educator: EducatorLookup, educatorMeta) => (
                                                    <NeoGrid.Row>
                                                        <NeoGrid.Column display={educatorMeta.surname} />
                                                        <NeoGrid.Column display={educatorMeta.identificationNumber} />
                                                        <NeoGrid.Column display={educatorMeta.persalId} />
                                                    </NeoGrid.Row>
                                                )}
                                            </NeoGrid.Grid>
                                        </Neo.Pager>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>

                {/* {Modals } */}
                <PersalEducatorImportModal viewModal={this.viewModel.educatorImportModalVM} educatorsImported={this.CloseEducatorImportModal.bind(this)} />
            </div>
        );
    }
}