import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UserGroupLookup extends ModelBase {

    public subTenantCode : string = "";

    public userGroupId : number = 0;

    @Attributes.Display("Name")
    public userGroupName : string = "";

    public isAdministratorGroup = false;

    @Attributes.Integer()
    public memberCount : number = 0;

    @Attributes.Integer()
    public roleCount : number = 0;

    // Client only properties / methods

    @Attributes.Display("Role Count")
    public get roleCountDisplay() {
        if (this.isAdministratorGroup) {
            return "All Roles";
        } else {
            return this.roleCount.toString();
        }
    }

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString() {
        if (this.isNew || !this.userGroupName) {
            return "New user group lookup"
        } else {
            return this.userGroupName;
        }
    }
}