import { AppServices } from '@singularsystems/neo-core';
import UserProfileApiClient from './ApiClients/Maintenance/UserProfileApiClient';
import UserProfileQueryApiClient from './ApiClients/Queries/UserProfileQueryApiClient'
import { Types } from '../Identity/IdentityTypes';
import SACEEducatorImportApiClient from "./ApiClients/Imports/SACEEducatorImportApiClient";
import SACEEducatorQueryApiClient from "./ApiClients/Queries/SACEEducatorQueryApiClient";
import PersalEducatorImportApiClient from "./ApiClients/Imports/PersalEducatorImportApiClient";
import PersalEducatorQueryApiClient from "./ApiClients/Queries/PersalEducatorQueryApiClient";
import TitleCommandApiClient from "./ApiClients/Maintenance/TitleCommandApiClient";
import RaceCommandApiClient from "./ApiClients/Maintenance/RaceCommandApiClient";
import RaceQueryApiClient from './ApiClients/Queries/RaceQueryApiClient';
import TitleQueryApiClient from './ApiClients/Queries/TitleQueryApiClient';
import UserDataImportApiClient from "./ApiClients/Imports/UserDataImportApiClient";

import OneTimePasswordApiClient from './ApiClients/Security/OneTimePasswordApiClient';
import TermsAndConditionsAcceptApiClient from './ApiClients/TermsAndConditions/TermsAndConditionsAcceptApiClient';
import TermsAndConditionsAdminApiClient from './ApiClients/TermsAndConditions/TermsAndConditionsAdminApiClient';
import IntroductionReadApiClient from './ApiClients/Introduction/IntroductionReadApiClient';
import IntroductionAdminApiClient from './ApiClients/Introduction/IntroductionAdminApiClient';
import SystemsSettingsCommandApiClient from './ApiClients/SystemSettings/Command/SystemSettingsCommandApiClient';
import SystemsSettingsQueryApiClient from './ApiClients/SystemSettings/Query/SystemsSettingsQueryApiClient';

// // import SecurityConfigView from './Views/SecurityConfigView';

// Modules
const identityAppModule = new AppServices.Module("Clients", container => {
    // Api Clients
    container.bind(Types.Identity.ApiClients.UserProfileQueryApiClient).to(UserProfileQueryApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.UserProfileApiClient).to(UserProfileApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.SACEEducatorImportApiClient).to(SACEEducatorImportApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.SACEEducatorQueryApiClient).to(SACEEducatorQueryApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.PersalEducatorImportApiClient).to(PersalEducatorImportApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.PersalEducatorQueryApiClient).to(PersalEducatorQueryApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.RaceQueryApiClient).to(RaceQueryApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.TitleQueryApiClient).to(TitleQueryApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.TitleCommandApiClient).to(TitleCommandApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.RaceCommandApiClient).to(RaceCommandApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.UserDataImportApiClient).to(UserDataImportApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.OneTimePasswordApiClient).to(OneTimePasswordApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.TermsAndConditionsAcceptApiClient).to(TermsAndConditionsAcceptApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.TermsAndConditionsAdminApiClient).to(TermsAndConditionsAdminApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.IntroductionReadApiClient).to(IntroductionReadApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.IntroductionAdminApiClient).to(IntroductionAdminApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.SystemsSettingsCommandApiClient).to(SystemsSettingsCommandApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.SystemsSettingsQueryApiClient).to(SystemsSettingsQueryApiClient).inSingletonScope();

    // Services

    // View Models

    // Components
});

// bind test types

// Routing

//const SecurityConfigRoute: IAppRoute = {
//   name: "Security Config", path: '/security/config', component: SecurityConfigView
//}

export {
    identityAppModule,
    // SecurityConfigRoute,
}