import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { QuestionType } from '../../Learning/Models/Questions/QuestionType.enum';
import { Sentiment } from '../../Learning/Models/Questions/Sentiment.enum';

@NeoModel
export default class UserAssessmentQuestionReviewLookup extends ModelBase {

    public selfAssessmentQuestionId: number = 0;

    public selfAssessmentId: number = 0;

    public question: string = "";

    public tag3Id: number = 0;

    public sentiment: Sentiment | null = null;

    public questionTypeId: QuestionType | null = null;

    public selectedOption: string = "";

    @Attributes.Integer()
    public optionValue: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.question) {
            return "New user assessment question review lookup";
        } else {
            return this.question;
        }
    }
}