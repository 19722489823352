import { Data } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';

export interface IUserGroupQueryApiClient {

    /** 
     * Checks if user belongs to the admin group.
     * @returns bool.
     */
    userIsAdminGroup(userGuid: string): AxiosPromise<boolean>;

    /** 
     * Gets the auth User id.
     * @returns number.
     */
    UserIdByGuid(userGuid: string): AxiosPromise<number>
    // Client only properties / methods
}

@injectable()
export default class UserGroupQueryApiClient extends Data.ApiClientBase implements IUserGroupQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.AuthorisationApi.ApiPath}/UserGroupQuery`);
    }

    public userIsAdminGroup(userGuid: string): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/UserIsAdminGroup/${encodeURIComponent(userGuid)}`);
    }

    public UserIdByGuid(userGuid: string): AxiosPromise<number> {
        return this.axios.get(`${this.apiPath}/UserIdByGuid/${encodeURIComponent(userGuid)}`);
    }

    // Client only properties / methods
}