import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import React from 'react'
interface IGridContainerProps {
  children: React.ReactNode,
  className?: string
  justify?: ("center" | "flex-end" | "flex-start" | "space-around" | "space-between" | "space-evenly")
  alignItems?: ("baseline" | "center" | "flex-end" | "flex-start" | "stretch")
  direction?: ("column" | "column-reverse" | "row" | "row-reverse")
}
const styles = {
  grid: {
    margin: "0 -15px !important",
    width: "unset",
  },
};

const useStyles = makeStyles(styles);

export default function GridContainer(props: IGridContainerProps) {
  const classes = useStyles();
  const { children, className, ...rest } = props;

  return (
    <Grid direction={props.direction} justifyContent={props.justify} alignItems={props.alignItems} container {...rest} className={classes.grid + " " + className}>
      {children}
    </Grid>
  );
}