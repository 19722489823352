import { injectable } from 'inversify';
import Axios, { AxiosPromise } from 'axios';
import { List, Misc } from '@singularsystems/neo-core';

import AuthorisationTypes from '../../AuthorisationTypes';
import AssignedRole from '../../Models/AssignedRole';

export interface IAssignedRolesApiClient {
    /**
     * Will save the list of AssignedRoles
     */
    save(assignedRoles: List<AssignedRole>): AxiosPromise<any>;
}

@injectable()
export default class AssignedRolesApiClient implements IAssignedRolesApiClient {

    private apiPath: string;

    constructor(config = Misc.Globals.appService.get(AuthorisationTypes.Config.ConfigModel)) {
        this.apiPath = `${config.authorisationServerApiPath}/AssignedRoles`;
    }

    public save(assignedRoles: List<AssignedRole>): AxiosPromise<any> {
        return Axios.post(`${this.apiPath}/SaveList/`, assignedRoles.toJSArray());
    }
}
