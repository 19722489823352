import React from "react";
import { Neo } from "@singularsystems/neo-react";
import { Report, NeoReport } from "@singularsystems/neo-reporting";
import { ReportCategory } from "../ReportCategory";
import { AppService, Types } from "../../../ReportingTypes"
import PLCCreatedPerTeacherCriteria from "./PLCCreatedPerTeacherCriteria";
import { List } from "@singularsystems/neo-core";
import Province from "../../../../Organisations/Models/Organisations/Province";
import District from "../../../../Organisations/Models/Organisations/District";
import Circuit from "../../../../Organisations/Models/Organisations/Circuit";
import School from "../../../../Organisations/Models/Organisations/School";
import SchoolManagementTeam from "../../../../Organisations/Models/Organisations/SchoolManagementTeam";
import TDPUser from "../../../../Identity/Models/Security/TDPUser";
import UserProfileLookup from "../../../../Identity/Models/Users/Lookups/UserProfileLookup";
import { Layout } from "@singularsystems/neo-canvas-grid";

@NeoReport('PLCCreatedPerTeacherReport', { category: ReportCategory.Consumption })
export default class PLCCreatedPerTeacherReport extends Report<PLCCreatedPerTeacherCriteria> {

    private user: TDPUser | null = null;
    public currentUser: UserProfileLookup = new UserProfileLookup();
    public isAdmin: boolean = false;

    constructor(
        private taskRunner = AppService.get(Types.Neo.TaskRunner),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
        private userGroupQueryApiClient = AppService.get(Types.Authorisation.ApiClients.UserGroupQueryApiClient),
        private userApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        private organisationsCache = AppService.get(Types.Organisations.Services.OrganisationDataCache)) {
        super(PLCCreatedPerTeacherCriteria);
        this.user = authenticationService.user;
        this.initialise();
    }

    public async initialise() {
        await this.taskRunner.run(async () => this.getCurrentUser());
    }

    private async getCurrentUser() {
        if (this.user !== null) {
            let response = await this.userQueryApiClient.getRegisteredUser(this.user?.guid);
            this.currentUser.set(response.data);
            this.isAdmin = (await this.userGroupQueryApiClient.userIsAdminGroup(this.user.guid)).data;

            if (this.isAdmin === false) {
                this.populateFields(this.currentUser)
            }
        }
    }

    private async populateFields(currentUser: UserProfileLookup) {
        if (currentUser.organisationlevel.organisationId != null) {
            this.criteria.organisationId = currentUser.organisationlevel.organisationId;
            this.filterProvinceList(this.criteria.organisationId);
            this.criteria.OrganisationDisabled = true;
        }
        if (currentUser.organisationlevel.provinceId != null) {
            this.criteria.provinceId = currentUser.organisationlevel.provinceId;
            this.filterDistrictList(this.criteria.provinceId);
        }
        if (currentUser.organisationlevel.districtId != null) {
            this.criteria.districtId = currentUser.organisationlevel.districtId;
            this.filterCircuitList(this.criteria.districtId);
        }
        if (currentUser.organisationlevel.circuitId != null) {
            this.criteria.circuitId = currentUser.organisationlevel.circuitId;
            this.filterSchoolList(this.criteria.circuitId)
        }
        if (currentUser.organisationlevel.schoolId != null) {
            this.criteria.schoolId = currentUser.organisationlevel.schoolId;
            this.filterSchoolManagementList(this.criteria.schoolId)
        }
    }

    public async clearAllSelectedCriteria() {
        this.criteria.provinceId = null;
        this.criteria.filteredProvinceList = new List(Province);
        this.criteria.districtId = null;
        this.criteria.filteredDistrictList = new List(District);
        this.criteria.circuitId = null;
        this.criteria.filteredCircuitList = new List(Circuit);
        this.criteria.schoolId = null;
        this.criteria.filteredSchoolList = new List(School);
        this.criteria.schoolManagementTeamId = null;
        this.criteria.filteredSchoolManagementTeamList = new List(SchoolManagementTeam);
    }

    public async filterProvinceList(organisationId: number) {
        await this.clearAllSelectedCriteria();
        var response = await this.organisationsCache.provinceList.getDataAsync();
        this.criteria.filteredProvinceList.update(response.filter(o => o.organisationId === organisationId));
    }

    public async filterDistrictList(provinceId: number) {
        var response = await this.organisationsCache.districtList.getDataAsync();
        this.criteria.filteredDistrictList.update(response.filter(o => o.provinceId === provinceId));
    }

    public async filterCircuitList(districtId: number) {
        var response = await this.organisationsCache.circuitList.getDataAsync();
        this.criteria.filteredCircuitList.set(response.filter(o => o.districtId === districtId));
    }

    public async filterSchoolList(circuitId: number) {
        var response = await this.organisationsCache.schoolList.getDataAsync();
        this.criteria.filteredSchoolList.set(response.filter(o => o.circuitId === circuitId));
    }

    public async filterSchoolManagementList(schoolId: number) {
        var response = await this.organisationsCache.schoolManagementTeamList.getDataAsync();
        this.criteria.filteredSchoolManagementTeamList.set(response.filter(o => o.schoolId === schoolId));
    }

    public renderCriteria() {
        return <Neo.GridLayout md={2} sm={1}>
            <Neo.FormGroupInline bind={this.criteria.meta.startDate} />
            <Neo.FormGroupInline bind={this.criteria.meta.endDate} />
            <Neo.FormGroupInline bindContext={this.criteria.meta.userId}>
                <Neo.AutoCompleteDropDown
                    bind={this.criteria.meta.userId}
                    bindDisplay={this.criteria.meta.userName}
                    displayMember="fullName"
                    itemSource={this.userApiClient.findUsers}
                    loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                    minCharacters={2}
                    typeToSearchPrompt="Search for users"
                />
            </Neo.FormGroupInline>
            <Neo.FormGroupInline bind={this.criteria.meta.organisationId}
                isDisabled={this.criteria.OrganisationDisabled && !this.isAdmin}
                select={{
                    itemSource: this.organisationsCache.organisationList,
                    onItemSelected: (item) => {
                        this.filterProvinceList(item?.organisationId as number)
                    }
                }} />
            <Neo.FormGroupInline bind={this.criteria.meta.provinceId}
                isDisabled={this.currentUser.organisationlevel.provinceId != null && !this.isAdmin}
                select={{
                    items: this.criteria.filteredProvinceList,
                    onItemSelected: (item) => {
                        this.filterDistrictList(item?.provinceId as number)
                    }
                }} />
            <Neo.FormGroupInline bind={this.criteria.meta.districtId}
                isDisabled={this.currentUser.organisationlevel.districtId != null && !this.isAdmin}
                select={{
                    items: this.criteria.filteredDistrictList,
                    onItemSelected: (item) => {
                        this.filterCircuitList(item?.districtId as number)
                    }
                }} />
            <Neo.FormGroupInline bind={this.criteria.meta.circuitId}
                isDisabled={this.currentUser.organisationlevel.circuitId != null && !this.isAdmin}
                select={{
                    items: this.criteria.filteredCircuitList,
                    onItemSelected: (item) => {
                        this.filterSchoolList(item?.circuitId as number)
                    }
                }} />
            <Neo.FormGroupInline bind={this.criteria.meta.schoolId}
                isDisabled={this.currentUser.organisationlevel.schoolId != null && !this.isAdmin}
                select={{
                    items: this.criteria.filteredSchoolList,
                    onItemSelected: (item) => {
                        this.filterSchoolManagementList(item?.schoolId as number)
                    }
                }} />
            <Neo.FormGroupInline bind={this.criteria.meta.schoolManagementTeamId}
                select={{ items: this.criteria.filteredSchoolManagementTeamList }} />
        </Neo.GridLayout>;
    }

    getDefaultGridLayout(criteria: PLCCreatedPerTeacherCriteria) {
        return {
            "root": {
                "columns": [
                    {
                        "key": "plc",
                        "title": "PLC"
                    }
                ]
            }
        } as Layout.IGridLayout;
    }
}