import { AppServices } from '@singularsystems/neo-core';
import { IAuthorisationConfig } from './Models/IAuthorisationConfig';
import AuthorisationDataCache from './Services/AuthorisationDataCache';
import { IUserRolesHub } from './Services/ApiClients/UserRolesHub';

import UserGroupsVM from './Views/UserGroupsVM';
import { IAssignedRolesApiClient } from './Services/ApiClients/AssignedRolesApiClient';
import { IMembershipsApiClient } from './Services/ApiClients/MembershipsApiClient';
import { IResourcesApiClient } from './Services/ApiClients/ResourcesApiClient';
import { IUserGroupsApiClient } from './Services/ApiClients/UserGroupsApiClient';
import { IUsersApiClient } from './Services/ApiClients/UsersApiClient';
import { AuthorisationSharedTypes } from './AuthorisationSharedTypes';
import { IUserRoleApiClient } from './Services/ApiClients/UserRoleApiClient';

// Merge the shared type symbols with this module's type symbols
const AuthorisationTypes = {
    ApiClients: {
        AuthorisationApiClient: AuthorisationSharedTypes.ApiClients.AuthorisationApiClient,
        AssignedRolesApiClient: new AppServices.ServiceIdentifier<IAssignedRolesApiClient>("Authorisation.ApiClients.AssignedRolesApiClient"),
        MembershipsApiClient: new AppServices.ServiceIdentifier<IMembershipsApiClient>("Authorisation.ApiClients.MembershipsApiClient"),
        ResourcesApiClient: new AppServices.ServiceIdentifier<IResourcesApiClient>("Authorisation.ApiClients.ResourcesApiClient"),
        UserGroupsApiClient: new AppServices.ServiceIdentifier<IUserGroupsApiClient>("Authorisation.ApiClients.UserGroupsApiClient"),
        UsersApiClient: new AppServices.ServiceIdentifier<IUsersApiClient>("Authorisation.ApiClients.UsersApiClient"),
        UserRolesHub: new AppServices.ServiceIdentifier<IUserRolesHub>("Authorisation.UserRolesHub"),
        UserRoleApiClient: new AppServices.ServiceIdentifier<IUserRoleApiClient>("Authorisation.ApiClients.UserRoleApiClient"),
        UserGroupQueryApiClient: AuthorisationSharedTypes.ApiClients.UserGroupQueryApiClient,
    },
    Config: {
        ConfigModel: new AppServices.ServiceIdentifier<IAuthorisationConfig>("Authorisation.ConfigModel")
    },
    Services: {
        AuthorisationDataCache: new AppServices.ServiceIdentifier<AuthorisationDataCache>("Authorisation.Data.AuthorisationDataCache"),
    },
    ViewModels: {
        UserGroupsViewModel: new AppServices.ServiceIdentifier<UserGroupsVM>("Authorisation.ViewModels.UserGroupsViewModel")
    }
}

export default AuthorisationTypes;