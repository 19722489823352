import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class PersalEducator extends ModelBase {

    public educatorId: number = 0;

    @Rules.Required()
    @Rules.StringLength(100)
    public surname: string = "";

    public persalId: string = "";

    @Rules.Required()
    public identificationNumber: string = "";

    public toString(): string {
        if (this.isNew || !this.surname) {
            return "New educator";
        } else {
            return this.surname;
        }
    }
}