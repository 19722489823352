import { AppService } from '../App/Services/AppService';

import { IdentitySharedTypes } from './IdentitySharedTypes';

// Symbols to expose outside of this module
export const CommonTypes = {
    ApiClients: {
        ...IdentitySharedTypes.ApiClients
    },
    Services: {
        ...IdentitySharedTypes.Services
    },
    ViewModels: {
        ...IdentitySharedTypes.ViewModels
    },
    Components: {
        ...IdentitySharedTypes.Components
    }
}

export { AppService, CommonTypes as Types }