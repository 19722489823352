import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { buildUpCustomHTMLForImage, buildUpCustomHTMLForSound, buildUpCustomHTMLForVideo, DownloadFile, OpenLink } from '../../../App/HelperClasses/GlobalHelpers';
import { AppService, Types } from '../../../Identity/IdentityTypes';
import BadgeLookup from '../../Models/Badges/BadgeLookup';
import ContentConsumptionLookup from '../../Models/ContentCosumption/ContentConsumptionLookup';
import InformationObjectConsumptionLookup from '../../Models/ContentCosumption/InformationObjectConsumptionLookup';
import ModuleObjectsConsumptionLookup from '../../Models/ContentCosumption/ModuleObjectsConsumptionLookup';
import QuizConsumptionLookup from '../../Models/ContentCosumption/QuizConsumptionLookup';
import { LearningContentMediaType } from '../../Models/LearningObjects/LearningContent/LearningContentMediaType.enum';
import { LearningObjectType } from '../../Models/LearningObjects/LearningObjectType.enum';
import LearningPathway from '../../Models/LearningPathways/LearningPathway';
import { LearningPathwayType } from '../../Models/LearningPathways/LearningPathwayType.enum';
import ModuleScoreLookup from '../../Models/Lookups/ModuleScoreLookup';
import OptionLookup from '../../Models/Lookups/OptionLookup';
import { QuestionType } from '../../Models/Questions/QuestionType.enum';
import fileDownload from 'js-file-download';
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration.enum';
import { RichTextEditorVM } from '../../Components/RichTextEditor';
import ModuleConsumptionLookup from '../../Models/ContentCosumption/ModuleConsumptionLookup';

@NeoModel
export default class PathwayModuleConsumptionVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private contentConsumptionApiClient = AppService.get(Types.Learning.ApiClients.ContentConsumptionQueryApiClient),
    private downloadContentApiClient = AppService.get(Types.Learning.ApiClients.DownloadContentApiClient),
    private learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
    public forumIntegrationQueryApiClient = AppService.get(Types.Forum.ApiClients.ForumIntegrationQueryApiClient),
    public topicQueryApiClient = AppService.get(Types.Forum.ApiClients.TopicQueryApiClient),
    private learningPathwayApiClient = AppService.get(Types.Learning.ApiClients.LearningPathwayQueryApiClient),
    private badgeApiClient = AppService.get(Types.Learning.ApiClients.BadgeQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  //Forum Stuff
  public forumModal: boolean = false;
  public moduleId = 0;
  public module = new ModuleConsumptionLookup();
  public pathwayId = 0;
  public infoLearningObject = new InformationObjectConsumptionLookup();
  public quizLearningObject = new QuizConsumptionLookup();
  public showContent = false;
  public alternateUrl: string = "";
  public content = new ContentConsumptionLookup();
  public contentBlobUrl = '';
  public contentQuestionBlobUrl = '';
  public hideInfoObjectInfo = true;
  public hideQuizObjectInfo = true;
  public questionListCount = 0;
  public currentIndex: number = 0;
  public completeQuizModal = false;
  public showPassModal = false;
  public showFinalQuizPassModal = false;
  public showFinalQuizFailModal = false;
  public showQuizFailModal = false;
  public moduleForumId = 0;
  public activityForumId = 0
  public showForumModal = false;
  public showActivityConversation = false;
  public isMaximised = true;
  public showTopicConversation = false;
  public quizRichTextVm = new RichTextEditorVM(this.taskRunner);

  public conversationTitle = ""
  public pathway = new LearningPathway();
  public hidePLCConvoButton = true;
  public async GetModule(moduleId: number) {
    const pathway = await this.taskRunner.waitForData(this.learningPathwayApiClient.getLearningPathway(this.pathwayId));
    this.pathway.set(pathway);
    if (this.pathway.hasInvites || this.pathway.learningPathwayType === LearningPathwayType.PLC) {
      this.hidePLCConvoButton = false;
    }
    const response = await this.taskRunner.waitForData(this.contentConsumptionApiClient.getModuleOnPathwayById(moduleId, this.pathwayId));
    if (response) {
      this.module.set(response);
    }

  }

  public SaveLastLearningObject = async (lo: ModuleObjectsConsumptionLookup) => {
    var lastLearningObject = this.module.learningObjectList.find(c => c.learningObjectId === this.module.lastLearningObjectId);

    if (lastLearningObject === undefined || (lastLearningObject.order < lo.order)) {
      const response = await this.contentConsumptionApiClient.saveLastPathwayActivity(lo.learningObjectId, this.pathwayId, this.moduleId);
      this.module.set(response.data);
    }
  }


  public GetButtonText = (content: ContentConsumptionLookup) => {
    if (content.mediaType === LearningContentMediaType.Link) {
      return content.title;
    }
    if (content.mediaType === LearningContentMediaType.Sound) {
      return "Listen";
    }
    if (content.mediaType === LearningContentMediaType.Conversation) {
      return "Conversation";
    }
    return "View";
  }

  public ViewContent = async (content: ContentConsumptionLookup, question: boolean) => {
    this.content = content;

    if (this.content.mediaType === LearningContentMediaType.Link) {
      OpenLink(this.content.externalResourceLocation);
    }

    if (this.content.mediaType === LearningContentMediaType.YouTube) {
      var url = "https://youtube.com/embed/" + this.content.externalResourceLocation+"?autoplay=1";      
      OpenLink(url);
    }

    if (this.content.mediaType === LearningContentMediaType.Document) {
      var fileUrl = await this.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, false)
      if (fileUrl.endsWith(".pdf")) {
        if (!question) {
          this.contentBlobUrl = 'https://docs.google.com/gview?url=' + fileUrl + '&embedded=true';
          OpenLink(this.contentBlobUrl);
        } else {
          this.contentQuestionBlobUrl = 'https://docs.google.com/gview?url=' + fileUrl + '&embedded=true';
          OpenLink(this.contentQuestionBlobUrl);
        }

      }
      else {
        this.contentBlobUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + fileUrl;
        OpenLink(this.contentBlobUrl);
      }
    }

    if (this.content.mediaType === LearningContentMediaType.Image) {
      var imageUrl = await this.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, false);
      let tmpURL = "";
      tmpURL = buildUpCustomHTMLForImage(imageUrl);

      if (!question) {
        this.contentBlobUrl = tmpURL;
      } else {
        this.contentQuestionBlobUrl = tmpURL;
      }

      this.showContent = true;
    }

    if (this.content.mediaType === LearningContentMediaType.Video) {
      let tmpUrl = "";
      var videoUrl = await this.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, false);
      tmpUrl = buildUpCustomHTMLForVideo(videoUrl);
      let tmpstr = this.content.externalResourceLocation.toLowerCase();
      if (tmpstr.includes("embed")) {
        this.alternateUrl = this.content.externalResourceLocation;
        if (this.alternateUrl.includes('?')) {
          this.alternateUrl += "&autoplay=1"
        } else {
          this.alternateUrl += "?autoplay=1"
        }
      } else {
        this.alternateUrl = "";
      }

      this.showContent = true;
      if (question) {
        this.contentBlobUrl = tmpUrl;
      } else {
        this.contentQuestionBlobUrl = tmpUrl;
      }
    }

    if (this.content.mediaType === LearningContentMediaType.Sound) {
      var soundUrl = await this.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, false)
      if (!question) {
        this.contentBlobUrl = buildUpCustomHTMLForSound(soundUrl);
        this.showContent = true;
      } else {
        this.contentQuestionBlobUrl = buildUpCustomHTMLForSound(soundUrl);
        this.showContent = true;
      }
    }

    if (this.content.mediaType === LearningContentMediaType.SCORM) {
      OpenLink(this.content.externalResourceLocation);
    }

    if (this.content.mediaType === LearningContentMediaType.Conversation) {
      this.openActivityForumDrawer();
    }
  }

  public backToModule = async () => {
    //await this.SaveFinalQuiz();
    this.infoLearningObject = new InformationObjectConsumptionLookup();
    this.quizLearningObject = new QuizConsumptionLookup();
    this.hideInfoObjectInfo = true;
    this.hideQuizObjectInfo = true;
    this.showFinalQuizFailModal = false;
  }

  public GetFileUrlFile = async (fileDescriptorId: string, mediaTypeId: number, download: boolean) => {
    var response = await this.learningModuleApiClient.getFileDownloadURL(fileDescriptorId, mediaTypeId);
    if (download) {
      DownloadFile(response.data.toString());
    }
    return response.data.toString();
  }

  public checkCompleted = (lo: ModuleObjectsConsumptionLookup) => {
    var lastCompletedLo = this.module.learningObjectList.find(c => c.learningObjectId === this.module.lastLearningObjectId);
    if (lastCompletedLo !== undefined) {
      if (lastCompletedLo.learningObjectId === lo.learningObjectId) {
        return true;
      }
      if (lastCompletedLo.order > lo.order) {
        return true;
      }
    } else {
      return false;
    }
    return false;
  }

  public AllowNextV2 = (lo: ModuleObjectsConsumptionLookup) => {
    var theFinalQuiz = this.module.learningObjectList.find(c => c.learningObjectType === LearningObjectType.FinalQuiz);
    if (this.module.finalQuizStarted) {
      return false;
    }

    if (theFinalQuiz!.learningObjectId === this.module.lastLearningObjectId) {
      return false;
    }

    if (this.module.finalQuizMark === 100) {
      return false;
    }

    if (this.module.finalQuizMark > 0 && this.module.finalQuizMark < 100) {
      return false;
    }

    if (this.module.finalQuizMark === 0 && this.module.lastLearningObjectId === theFinalQuiz!.learningObjectId) {
      return false;
    }

    if (lo.order === 1) {
      return false;
    }


    if (lo.order === 0) {
      return false;
    }

    var lastCompletedLo = this.module.learningObjectList.find(c => c.learningObjectId === this.module.lastLearningObjectId);
    if (lastCompletedLo !== undefined) {
      if (lastCompletedLo.order >= lo.order) {
        return false;
      }
    }

    var curActivity = this.module.learningObjectList.find(c => lo.learningObjectId);
    var curIndex = this.module.learningObjectList.indexOf(curActivity ?? new ModuleObjectsConsumptionLookup());
    var prevActivity = undefined;
    if (curIndex >= 0) {
      prevActivity = this.module.learningObjectList[curIndex - 1];
    } else {
      return false;
    }

    if (prevActivity?.learningObjectId === this.module.lastLearningObjectId) {
      return false;
    }
    return true;
  }

  public GetStatusText(lo: ModuleObjectsConsumptionLookup) {
    var theFinalQuiz = this.module.learningObjectList.find(c => c.learningObjectType === LearningObjectType.FinalQuiz);

    if (this.module.finalQuizStarted && lo.learningObjectType !== LearningObjectType.FinalQuiz) {
      return "Completed";
    }

    if (theFinalQuiz!.learningObjectId === this.module.lastLearningObjectId && lo.learningObjectType !== LearningObjectType.FinalQuiz) {
      return "Completed";
    }

    if (this.module.finalQuizMark === 100) {
      return "Completed";
    }

    if (this.module.finalQuizMark > 0 && this.module.finalQuizMark < 100 && lo.learningObjectType === LearningObjectType.FinalQuiz) {
      return "In Progress";
    }

    if (this.module.finalQuizMark > 0 && this.module.finalQuizMark < 100) {
      return "Completed";
    }

    if (this.module.finalQuizMark === 0 && this.module.lastLearningObjectId === theFinalQuiz!.learningObjectId) {
      return "In Progress";
    }

    if (this.module.lastLearningObjectId === lo.learningObjectId) {
      return "Completed";
    }

    var lastCompletedLo = this.module.learningObjectList.find(c => c.learningObjectId === this.module.lastLearningObjectId);
    if (lastCompletedLo !== undefined) {
      if (lastCompletedLo.order >= lo.order) {
        return "Completed";
      }
      if (lastCompletedLo.learningObjectId === lo.learningObjectId) {
        return "Completed";
      }
    }
    return "Not started";
  }

  public async downloadPdf(learningModuleId: number) {
    window.location.href = this.downloadContentApiClient.getDocumentFromLearningModuleAsyncUrl(learningModuleId);
    this.notifications.addSuccess(`"Learning Module", PDF file is being downloaded.`, null, NotificationDuration.Longer);
  }

  public async downloadZip(learningModuleId: number) {
    this.taskRunner.run(async () => {
      this.notifications.addSuccess(`"Learning Module", Zip file is being created and will be downloaded shortly.`, null, NotificationDuration.Longer);
      var resp = await this.downloadContentApiClient.getZipFromLearningModuleAsyncUrl(learningModuleId);
      if (resp.status === 200) {
        const tmpStr = resp.headers['content-disposition'].split('filename=')[1];
        var fileName = tmpStr.split(';')[0];
        if (fileName.length > 2) {
          fileName = fileName.substring(1, fileName.length - 1);
        } else {
          fileName = "unknown.zip"
        }
        fileDownload(resp.data, fileName);
      }
    });
  }

  public conversationMenu = false;

  public OpenConversationMenu() {
    this.privateConversation = false;
    if (this.conversationMenu === true) {
      this.conversationMenu = false;
    }
    else if (this.conversationMenu === false) {
      this.conversationMenu = true;
    }
  }

  public openGlobalForumDrawer = async () => {
    this.privateConversation = false;
    let response = await this.forumIntegrationQueryApiClient.getConversationForLearningModule(this.moduleId);
    this.moduleForumId = response.data.forumId;
    this.conversationTitle = 'General Module Conversations'
    this.showForumModal = true;
  }

  public privateConversation = false;
  public selectedConvoId = 0;
  public openLocalForumDrawer = async () => {
    if (this.pathway.learningPathwayType === LearningPathwayType.PLC) {
      this.selectedConvoId = this.pathway.parentId as number
    } else {
      this.selectedConvoId = this.pathway.learningPathwayId;
    }
    let response = await this.forumIntegrationQueryApiClient.getModuleConversationForLearningPathway(this.selectedConvoId, this.moduleId);
    if (response.data.name != null) {
      this.moduleForumId = response.data.forumId;
      this.conversationTitle = 'Private Module Conversations'
      this.showForumModal = true;
      this.privateConversation = true;
    }
    else {
      this.notifications.addInfo(`Private conversation does not exist for learning module`, null, 10)
    }
  }

  public openActivityForumDrawer = async () => {
    let response = await this.forumIntegrationQueryApiClient.getConversationForLearningActivity(this.infoLearningObject.learningObjectId)
    this.moduleForumId = response.data.forumId;
    var topicResponse = await this.topicQueryApiClient.getLearningObjectTopicByForumId(this.moduleForumId)
    this.activityForumId = topicResponse.data.topicId
    this.showActivityConversation = true;
    this.showTopicConversation = true;
  }
  public showQuizQuestionContent = false
  public showQuestionContent = false;

  public SelectObject = async (object: ModuleObjectsConsumptionLookup) => {
    this.currentIndex = 0;
    this.showFinalQuizFailModal = false;
    this.showQuizQuestionContent = false
    this.showQuestionContent = false;
    this.showQuizFailModal = false;
    if (object.learningObjectType === LearningObjectType.Information) {
      const response = await this.contentConsumptionApiClient.getInfoObjectById(object.learningObjectId, 0);
      if (response.data) {
        this.infoLearningObject.set(response.data);
        if (this.infoLearningObject.contentList.length > 0) {
          for (const content of this.infoLearningObject.contentList) {
            if (content.mediaType === LearningContentMediaType.Image ||
              content.mediaType === LearningContentMediaType.Video || content.mediaType === LearningContentMediaType.Document ||
              content.mediaType === LearningContentMediaType.Sound) {
              if (content.mediaObject !== null && content.mediaObject.fileDescriptorId !== null) {
                var blobUrl = await this.GetFileUrlFile(content.mediaObject.fileDescriptorId as string, content.mediaType as number, false);
                this.contentBlobUrl = blobUrl;
              }
            }
          }
        }
        this.hideQuizObjectInfo = true;
        this.hideInfoObjectInfo = false;
      }
    } if (object.learningObjectType === LearningObjectType.Quiz || object.learningObjectType === LearningObjectType.FinalQuiz) {
      const response = await this.contentConsumptionApiClient.getQuizInfoById(object.learningObjectId, this.moduleId);
      if (response.data) {
        this.quizLearningObject.set(response.data);
        this.hideInfoObjectInfo = true;
        this.questionListCount = this.quizLearningObject.questions.length;
        this.hideQuizObjectInfo = false;
        this.quizLearningObject.questions.forEach(async question => {
          var options: Array<string> = [];
          options = JSON.parse(question.options)
          options.forEach(element => {
            var optionLookup = new OptionLookup();
            optionLookup.option = element;
            question.optionLookupList.push(optionLookup);
          });
          if (question.contentId !== 0 && question.contentId !== null) {

            var questionContent = this.quizLearningObject.contentList.find(c => c.learningContentId === question.contentId);
            this.quizLearningObject.contentList.remove(questionContent!);
            question.contentList.push(questionContent!);
            var fileUrl = await this.GetFileUrlFile(question.contentList[0].mediaObject?.fileDescriptorId as string, this.quizLearningObject.contentList[0].mediaType as number, false)
            this.contentQuestionBlobUrl = fileUrl;
            this.showQuestionContent = true;
          } else {
            this.showQuestionContent = false;
          }
        });
        if (this.quizLearningObject.contentList.length > 0) {
          var fileUrl = await this.GetFileUrlFile(this.quizLearningObject.contentList[0].mediaObject?.fileDescriptorId as string, this.quizLearningObject.contentList[0].mediaType as number, false)
          this.contentBlobUrl = fileUrl;
          this.showQuizQuestionContent = true;
        }
      }
    }
  }

  public SaveFinalQuiz = async () => {

    const resp = await this.contentConsumptionApiClient.saveFinalQuiz(this.moduleId, this.pathwayId, this.quizLearningObject.learningObjectId, this.quizMark);
    this.badgeAwardedList.set(resp.data);
    this.numberOfBadgesEarned = resp.data.length;

    if (this.numberOfBadgesEarned === 0) {
      this.allDone = true;
      if (this.quizMark === 100) {
        this.showFinalQuizPassModal = true;
      } else {
        this.showFinalQuizFailModal = true;
      }
    }

    this.badgeAwardedList.forEach(async badge => {
      this.listOfBadgeIdsToShow.push(badge.badgeId);
    });
    await this.showNextBadge();
  }

  public CompleteInfoObjectAndGoNext = async () => {
    var lastLearningObject = this.module.learningObjectList.find(c => c.learningObjectId === this.module.lastLearningObjectId);

    if (lastLearningObject === undefined || (lastLearningObject.order < this.infoLearningObject.order)) {
      const response = await this.contentConsumptionApiClient.saveLastPathwayActivity(this.infoLearningObject.learningObjectId, this.pathwayId, this.moduleId);
      this.module.set(response.data);
    }
    this.hideInfoObjectInfo = true;
    var currentActivity = this.module.learningObjectList.find(c => c.learningObjectId === this.infoLearningObject.learningObjectId);
    let currentIndex = this.module.learningObjectList.indexOf(currentActivity ?? new ModuleObjectsConsumptionLookup());
    var nextActivity = undefined;
    if (currentIndex >= 0) {
      if (currentIndex < this.module.learningObjectList.length - 1) {
        nextActivity = this.module.learningObjectList[currentIndex + 1];
      } else {
        return;
      }
    } else {
      this.notifications.addDanger("Error navigating", "Could not find current activity before next activity.", NotificationDuration.Standard);
      return;
    }

    if (nextActivity === undefined) {
      this.notifications.addDanger("Error navigating", "Could not find next activity.", NotificationDuration.Standard);
      return;
    } else {
      this.infoLearningObject = new InformationObjectConsumptionLookup();
      await this.SelectObject(nextActivity!);
    }
  }

  public GoToPrevActivity = async (isQuestion: boolean) => {
    this.taskRunner.run(async () => {
      if (isQuestion) {
        var prevQuizObject = undefined;
        let currentObject = this.module.learningObjectList.find(c => c.learningObjectId === this.quizLearningObject.learningObjectId);
        let currentIndex = this.module.learningObjectList.indexOf(currentObject ?? new ModuleObjectsConsumptionLookup());
        if (currentIndex > 0) {
          prevQuizObject = this.module.learningObjectList[currentIndex - 1];
        } else {
          return;
        }
        if (prevQuizObject === undefined) {
          this.notifications.addDanger("Error navigating", "Could not find previous activity.", NotificationDuration.Standard)
          return;
        } else {
          this.quizLearningObject = new QuizConsumptionLookup();
          await this.SelectObject(prevQuizObject!);
        }
      } else {

        var prevObject = undefined;
        let currentObject = this.module.learningObjectList.find(c => c.learningObjectId === this.infoLearningObject.learningObjectId);
        let currentIndex = this.module.learningObjectList.indexOf(currentObject ?? new ModuleObjectsConsumptionLookup());
        if (currentIndex > 0) {
          prevObject = this.module.learningObjectList[currentIndex - 1];
        } else {
          return;
        }
        if (prevObject === undefined) {
          this.notifications.addDanger("Error navigating", "Could not find previous activity (2).", NotificationDuration.Standard)
          return;
        } else {
          this.infoLearningObject = new InformationObjectConsumptionLookup();
          await this.SelectObject(prevObject!);
        }
      }
    });
  }

  public quizMark = 0;
  public CompleteQuiz = async () => {
    await this.taskRunner.run(async () => {
      this.quizMark = 0;
      this.completeQuizModal = false;
      var percentage = await this.CalculateQuizMark();
      this.quizMark = percentage;
      if (this.quizLearningObject.learningObjectType === LearningObjectType.Quiz) {
        if (this.quizMark === 100) {
          this.showPassModal = true;
        } else {
          this.showQuizFailModal = true;
        }
      }
      if (this.quizLearningObject.learningObjectType === LearningObjectType.FinalQuiz) {
        await this.SaveFinalQuiz();
        await this.GetModule(this.moduleId)

      }
    });
  }

  public completeMidModuleQuiz = async () => {
    await this.MarkObjectAsCompleted();
    let orderIncrement = 1;
    this.showPassModal = false;
    this.showQuizFailModal = false;
    this.hideQuizObjectInfo = true;
    this.hideInfoObjectInfo = true;
    this.infoLearningObject = new InformationObjectConsumptionLookup();
    orderIncrement = 1
    var nextActivity = this.module.learningObjectList.find(c => c.order === this.quizLearningObject.order + orderIncrement)
    while (nextActivity === undefined) {
      let counter = orderIncrement + 1;
      nextActivity = this.module.learningObjectList.find(c => c.order === this.quizLearningObject.order + counter);
      counter++;
    }
    this.quizLearningObject = new QuizConsumptionLookup();
    await this.SelectObject(nextActivity!)

  }

  public moduleScoreLookup = new ModuleScoreLookup();

  public CalculateQuizMark = async () => {
    this.moduleScoreLookup = new ModuleScoreLookup();
    var response = await this.contentConsumptionApiClient.getQuizScore(this.quizLearningObject.toJSObject({ includeClean: true }));
    this.moduleScoreLookup.set(response.data);
    return this.moduleScoreLookup.score;
  }

  public MarkObjectAsCompleted = async () => {
    const response = await this.contentConsumptionApiClient.setLastLearningObjectOnModule(this.moduleId, this.pathwayId, this.quizLearningObject.learningObjectId);
    if (response.data) {
      return true;
    }
    return false;
  }

  public CalculateQuizTotalPoints = () => {
    var quizTotalPoints = 0;
    this.quizLearningObject.questions.forEach(question => {
      if (question.questionType === QuestionType.MultipleChoice || question.questionType === QuestionType.TrueFalse || question.questionType === QuestionType.YesNo) {
        quizTotalPoints += 1;
      }
      if (question.questionType === QuestionType.MultipleSelect) {
        const correctAnswerCount: Array<string> = JSON.parse(question.correctAnswers);
        quizTotalPoints += correctAnswerCount.length;
      }
    });
    return quizTotalPoints;
  }

  //Badge Stuff
  public showBadgeAward: boolean = false;
  public badgeAwarded = new BadgeLookup();
  public blobUrl: string = "";
  public thereAreSomeMoreBadges: boolean = false;
  public badgeAwardedList = new List(BadgeLookup);
  public numberOfBadgesEarned: number = 0;
  public listOfBadgeIdsToShow: number[] = [];
  public allDone: boolean = false;
  public showConfetti: boolean = false;

  public async closeForumModal() {
    this.showForumModal = false;
    const response = await this.badgeApiClient.getAwardUserBadges();

    this.badgeAwardedList.set(response.data);
    this.numberOfBadgesEarned = response.data.length;

    if (this.numberOfBadgesEarned === 0) {
      this.allDone = true;
    }

    this.badgeAwardedList.forEach(async badge => {
      this.listOfBadgeIdsToShow.push(badge.badgeId);
    });

    await this.showNextBadge();
  }

  public async closeActivityConversation() {
    this.showActivityConversation = false
  }

  public showNextBadge = async () => {
    //If we click next badge and there are no more badges to show, we say we are all done. 
    if (this.listOfBadgeIdsToShow.length === 0) {
      this.allDone = true;
      return this.numberOfBadgesEarned;
    }
    var currentBadge = new BadgeLookup();

    var nextBadgeId = this.listOfBadgeIdsToShow[0];

    if (nextBadgeId !== null || nextBadgeId > 0) {
      currentBadge = this.badgeAwardedList.find(c => c.badgeId === nextBadgeId)!
      this.badgeAwarded = currentBadge;
      this.listOfBadgeIdsToShow.remove(currentBadge.badgeId);
      const bloblUrlResp = await this.taskRunner.waitFor(this.badgeApiClient.getBadgeImageByDescriptorId(this.badgeAwarded.mediaObject?.fileDescriptorId as string));
      this.badgeAwarded.blobUrl = bloblUrlResp.data;
      this.numberOfBadgesEarned = this.numberOfBadgesEarned > 0 ? this.numberOfBadgesEarned - 1 : 0;
      this.showBadgeAward = true;
    } else {
      this.numberOfBadgesEarned = 0;
    }
    return this.numberOfBadgesEarned;
  }
}