import { injectable } from 'inversify';
import { Misc, SignalR, AppServices, Network } from '@singularsystems/neo-core';
import Types from '../../AuthorisationTypes';
import MembershipChange from '../../Models/Hubs/MembershipChange';
import AssignedRolesChange from '../../Models/Hubs/AssignedRolesChange';

export interface IUserRolesHub extends SignalR.IHubBase {

    /** Called when a user is added or removed from a group. */
    readonly membershipChanged: SignalR.IHubReceiver<MembershipChange>;

    /** Called when a role is added or removed from a group. */
    readonly assignedRoleChanged: SignalR.IHubReceiver<AssignedRolesChange>;

    /** Called when a user group (including all its memberships and roles) is removed. */
    readonly userGroupRemoved: SignalR.IHubReceiver<number>;

    /** Subscribe to a group. */
    subscribeToGroup(userGroupId: number) : Promise<void>;
}

@injectable()
export class UserRolesHub extends SignalR.HubBase implements IUserRolesHub {

    constructor(config = Misc.Globals.appService.get(Types.Config.ConfigModel),
        connectionManager = Misc.Globals.appService.get(AppServices.NeoTypes.Core.ConnectionManager)) {
        super(config.authorisationServerApiPath + "/userRolesHub");

        connectionManager.registerConnection(this, "Security Service", Network.ConnectionType.SignalR);
    }

    public readonly membershipChanged = this.registerReceiver<MembershipChange>("MembershipChanged");
    public readonly assignedRoleChanged = this.registerReceiver<AssignedRolesChange>("AssignedRoleChanged");
    public readonly userGroupRemoved = this.registerReceiver<number>("UserGroupRemoved");

    public subscribeToGroup(userGroupId: number) : Promise<void> {
        return this.invoke("SubscribeToGroup", userGroupId);
    }
}