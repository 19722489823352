import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import Card from "../../Template/components/Card/Card";
import CardBody from "../../Template/components/Card/CardBody";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import ProvinceComponentVM from "./ProvinceComponentVM"

interface IProvinceComponentProps {
    viewModal: ProvinceComponentVM;
}

@observer
export default class ProviceComponentView extends React.Component<IProvinceComponentProps>{

    public render() {
        const viewModel = this.props.viewModal;
        return (
            <div >
                <div>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="success">
                                <i className={`fa fa-map-marker fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">{viewModel.selectedProvinceId ?
                                "Selected Province: " + viewModel.provinceList.find(c => c.provinceId === viewModel.selectedProvinceId)?.provinceName : "Provinces"}</h4>
                        </CardHeader>
                        <CardBody>
                            <Neo.Button variant="success" isOutline={viewModel.enableEdit ? false : true} icon="edit"
                                onClick={() => viewModel.enableEdit = !viewModel.enableEdit} >Edit Provinces</Neo.Button>

                            <div className="col-md-12">
                                <Neo.FormGroup bind={viewModel.organisationDropDown.meta.selectedItem}
                                    select={{
                                        itemSource: viewModel.organisationList,
                                        allowNulls: true,
                                        onItemSelected: () => {
                                            viewModel.LoadProvinceList(viewModel.organisationDropDown.selectedItem ? viewModel.organisationDropDown.selectedItem : 0)
                                        }
                                    }
                                    } label="Choose an Organisation" placeholder="Filter by Organisation"
                                    append={<Neo.Button tooltip="Clear Search" size="sm" tabIndex={-1} icon="eraser" onClick={() => viewModel.clearFilters()}></Neo.Button>}></Neo.FormGroup>
                            </div>

                            <NeoGrid.Grid items={viewModel.provinceList} initialSort={"provinceId"} showAddButton={viewModel.enableEdit} selected={true} >
                                {(province, provincemeta) => (
                                    <NeoGrid.Row className={viewModel.selectedProvinceId === province.provinceId ? "selected-item-css" : "bg-none"}>
                                        <NeoGrid.ButtonColumn>
                                            <Neo.Button tooltip="Click to filter District by this Province" icon="fa-map-pin" variant={viewModel.selectedProvinceId === province.provinceId ? "info" : "success"} isOutline onClick={() => {
                                                viewModel.setSelectedProvince(province.provinceId);
                                            }} />
                                        </NeoGrid.ButtonColumn>
                                        <NeoGrid.Column label="Name" bind={provincemeta.provinceName} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.Column label="Code" bind={provincemeta.provinceCode} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.Column label="Description" bind={provincemeta.description} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.Column label="Organisation" bind={provincemeta.organisationId} select={{ itemSource: viewModel.organisationList }} isReadOnly={!viewModel.enableEdit} />
                                        <NeoGrid.ButtonColumn showDelete={province.provinceId === 0}>
                                            <Neo.Button disabled={!viewModel.enableEdit} hidden={province.provinceId === 0} icon="trash" variant="danger" onClick={() => viewModel.deleteProvincePopUp(province.provinceName, province.provinceId)} />
                                        </NeoGrid.ButtonColumn>
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>

                            {viewModel.provinceList.length === 0 &&
                                <div className="col-md-12 p-0 text-center">
                                    <p>No Records</p>
                                </div>}
                            <Neo.Form model={viewModel.provinceList} onSubmit={() => viewModel.saveProvinces()} showSummaryModal>
                                <div className="my-4 saveButton">
                                    <Neo.Button hidden={!viewModel.enableEdit} disabled={!viewModel.provinceList.isDirty} isSubmit variant="warning" icon="check">Save</Neo.Button>
                                </div>
                            </Neo.Form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }
}