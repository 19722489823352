import { Data, List, NeoModel, ModalUtils } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { AppService } from "../../App/Services/AppService";
import Types from '../../App/AppTypes';
import React from "react";
import { observer } from "mobx-react";
import LearningObjectContentLookup from "../Models/Lookups/LearningObjectContentLookup";
import LearningModuleVM from "../Views/LearningModuleVM";
import { ValidationDisplayMode } from "@singularsystems/neo-core/dist/Validation/Misc";
import QuizLearningObjectLookup from "../Models/Lookups/QuizLearningObjectLookup";
import LearningObjectLookup from "../Models/Lookups/LearningObjectLookup";
import { QuestionType } from "../Models/Questions/QuestionType.enum";
import OptionLookup from "../Models/Lookups/OptionLookup";
import { RichTextEditorVM } from "./RichTextEditor";
import MediaObject from "../Models/LearningObjects/LearningContent/MediaObject";
import FileLookup from "../Models/Lookups/FileLookup";
import { LearningContentMediaType } from "../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import FileDescriptor from "../Models/Files/FileDescriptor";
import { LearningObjectType } from "../Models/LearningObjects/LearningObjectType.enum";
import RichTextEditor from "./RichTextEditor";
import { ModerationState } from "../Models/LearningObjects/ModerationState.enum";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum";

interface IQuizLearningObjectComponent {
    hidden: boolean;
    quizLearningObject: QuizLearningObjectLookup;
    viewModel: QuizLearningObjectComponentVM;
    learningModuleVM: LearningModuleVM;
    selectedLearningObject: LearningObjectLookup;
    learningContent: LearningObjectContentLookup;
}

@NeoModel
export class QuizLearningObjectComponentVM extends Views.ViewModelBase {
    public optionLookup = new List(OptionLookup);
    public selectedQuestionTypeId = 0;
    public questionRichText = "";
    public showLinkedResource = false;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private apiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient)) {
        super(taskRunner);
        this.optionLookup = new List(OptionLookup);
    }

    // Properties
    public selectedMediaType: number = 0;
    public fileDescriptorList = new List(FileLookup);
    public richtextVm = new RichTextEditorVM(this.taskRunner);
    public richTextContentModeration = new RichTextEditorVM(this.taskRunner);
    public curQuestionHtml: string = "";

    public showModerationNotes: boolean = true;
    public showAddQuestionModal: boolean = false;
    public showAddContentForQuestionModal: boolean = false;

    public async getFileLookup(fileDescriptorId: string, mediaTypeId: number) {
        this.fileDescriptorList = new List(FileLookup);
        const fileLookUp = (await this.taskRunner.waitFor(this.apiClient.getFileByDescriptorId(fileDescriptorId, mediaTypeId))).data;
        if (fileLookUp) {
            for (let index = 0; index < fileLookUp.length; index++) {
                var element = fileLookUp[index];
                var fileDescriptor = new FileLookup();
                fileDescriptor.fileDescriptorId = element.fileDescriptorId as string;
                fileDescriptor.fileName = element.fileName as string;
                fileDescriptor.mediaTypeId = element.mediaTypeId as number;
                fileDescriptor.mimeType = element.mimeType as string;
                this.fileDescriptorList.push(fileDescriptor);
            }
        }
    }
}

@observer
export class QuizLearningObjectComponent extends React.Component<IQuizLearningObjectComponent> {

    private apiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient);
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications);
    private quizLearningObjectApiClient = AppService.get(Types.Learning.ApiClients.QuizLearningObjectApiClient);

    public currentQuestionHtmlChange(text: string) {
        this.props.quizLearningObject.quizQuestion.textHtml = this.props.viewModel.curQuestionHtml;
    }

    public AddLinkedResource() {
        this.props.viewModel.showLinkedResource = true;
    }

    public CancelLinkedResource() {
        this.props.viewModel.showLinkedResource = false;
    }

    public setSelectedQuestionType(id: number) {
        this.props.viewModel.selectedQuestionTypeId = id;
        this.getSelection(id);
    }

    private allowedExtensions: string[] = [];
    public showFiles: boolean = false;
    private afterUpload(fileDescriptor: FileDescriptor) {
        this.props.viewModel.taskRunner.run("Importing file", async () => {
            var mediaObject = new MediaObject();
            mediaObject.fileDescriptorId = fileDescriptor.fileDescriptorId;
            mediaObject.fileName = fileDescriptor.fileName;
            this.props.learningContent.learningContent.mediaObject = mediaObject;
            this.props.learningContent.learningObjectId = this.props.learningModuleVM.selectedLearningObjectId;
            this.props.viewModel.getFileLookup(fileDescriptor.fileDescriptorId, this.props.learningModuleVM.selectedMediaType);
            this.notifications.addSuccess("File has been uploaded", "You can now save your learning Content", 4);
        });
    }

    private resetLearningModuleVm() {
        if (this.props.learningModuleVM.selectedLearningContentId === 0) {
            this.props.learningModuleVM.quizLearningObject = new QuizLearningObjectLookup();
            this.props.learningModuleVM.newLearningContent = new LearningObjectContentLookup();
            this.props.viewModel.fileDescriptorList = new List(FileLookup);
            this.props.learningModuleVM.selectedContentTypeId = 0;
        }
        this.props.learningModuleVM.hideEditLearningObjectCard = true;
        this.props.learningModuleVM.hideUpdate = true;
    }

    private async deleteFile(fileName: string, fileDescriptorId: string) {
        await ModalUtils.showYesNoDismissible("Delete File " + fileName,
            "Are you sure you want to delete this File? ", () => this.deleteConfirmed(fileDescriptorId, this.props.viewModel.selectedMediaType as number));
    }

    private async deleteConfirmed(fileDescriptorId: string, mediaType: number) {
        await this.props.viewModel.taskRunner.waitFor(this.apiClient.deleteFile(fileDescriptorId, mediaType));
    }

    private setSelectedMediaType(id: number) {
        this.allowedExtensions = [""];
        this.props.viewModel.selectedMediaType = id;
        this.setAllowedExtensions(this.props.viewModel.selectedMediaType);
    }

    private setAllowedExtensions(mediaTypeId: number) {
        switch (mediaTypeId) {
            case LearningContentMediaType.Document: {
                this.allowedExtensions = [".docx", ".pptx", ".ppt", ".xlsx", ".xls", ".pdf"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.Image: {
                this.allowedExtensions = [".jpg", ".jpeg", ".png", ".JPG",];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.Sound: {
                this.allowedExtensions = [".MP3", ".MP4", ".wav", ".MPEG-4", ".mp4", ".mp3", ".ogg"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.Video: {
                this.allowedExtensions = [".avi", ".mov", ".qt", ".wmv", ".MTS", ".M2TS", ".TS", ".mp4", ".mp3"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.SCORM: {
                this.allowedExtensions = [".xml", ".zip"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
        }
    }

    private ShowFileExtensionsTypeBinding(mediaType: number) {
        if (mediaType !== LearningContentMediaType.Link
            // && mediaType !== LearningContentMediaType.Conversation 
            && mediaType !== LearningContentMediaType.YouTube
            && this.props.learningContent.learningContent.allowedFileExtensions !== "") {
            return false;
        }
        else {
            return true;
        }
    }

    public setSelectOption(option: OptionLookup) {
        var optionLookup = this.props.viewModel.optionLookup;
        for (var i = 0; i < optionLookup.length; i++) {
            if (optionLookup[i].option === option.option) {
                optionLookup[i].correctAnswerInd = true;
            }
            else {
                optionLookup[i].correctAnswerInd = false;
            }
        }
    }

    public getSelection(type: number) {
        this.props.viewModel.optionLookup = new List(OptionLookup);
        var theFirstOption = new OptionLookup();
        var theSecondOption = new OptionLookup();
        if (type === QuestionType.YesNo) {
            theFirstOption.option = "Yes";
            theFirstOption.correctAnswerInd = false;
            this.props.viewModel.optionLookup.push(theFirstOption);
            theSecondOption.option = "No";
            theSecondOption.correctAnswerInd = false;
            this.props.viewModel.optionLookup.push(theSecondOption);
            return this.props.viewModel.optionLookup;
        }
        else if (type === QuestionType.TrueFalse) {
            theFirstOption.option = "True";
            theFirstOption.correctAnswerInd = false;
            this.props.viewModel.optionLookup.push(theFirstOption);
            theSecondOption.option = "False";
            theSecondOption.correctAnswerInd = false;
            this.props.viewModel.optionLookup.push(theSecondOption);
            return this.props.viewModel.optionLookup;
        }
        return this.props.viewModel.optionLookup;
    }

    private async saveQuizLearningObject() {
        var loObject = this.props.learningModuleVM.selectedLearningObject;
        if (loObject.learningObjectId === 0 && this.props.learningModuleVM.selectedLearningObjectId === 0) {
            this.props.learningModuleVM.learningObjectList.forEach(element => {
                if (element.learningObjectTypeId === LearningObjectType.FinalQuiz && loObject.learningObjectTypeId === LearningObjectType.FinalQuiz) {
                    this.notifications.addDanger("Final Quiz Existing", "There is already a final quiz existing for this module", NotificationDuration.Standard);
                    throw new Error("There is already a final quiz existing for this module.");
                }
            });
        }
        loObject.newLearningContent = this.props.learningContent;
        loObject.learningObjectId = this.props.learningModuleVM.selectedLearningObjectId;
        loObject.learningModuleId = this.props.learningModuleVM.selectedLearningModuleId;
        var quizLearning = this.props.quizLearningObject;
        quizLearning.learningObjectId = this.props.learningModuleVM.selectedLearningObject.learningObjectId;
        quizLearning.learningObjectTitle = this.props.learningModuleVM.selectedLearningObject.learningObjectTitle;
        quizLearning.learningObjectText = this.props.learningModuleVM.selectedLearningObject.learningObjectText;
        quizLearning.LearningObjectTextHtml = this.props.learningModuleVM.selectedLearningObject.learningObjectTextHtml;
        quizLearning.learningObjectPurpose = this.props.learningModuleVM.selectedLearningObject.learningObjectPurpose;
        quizLearning.learningObjectTypeId = this.props.learningModuleVM.selectedLearningObject.learningObjectTypeId as number;
        quizLearning.quizQuestion.newLearningContent = this.props.learningContent;
        if (this.props.learningContent.meta.mediaTypeFetched.value !== 0) {
            quizLearning.quizQuestion.newLearningContent.learningContent.mediaType = this.props.learningContent.meta.mediaTypeFetched.value;
        }

        for (var i = 0; i < this.props.viewModel.optionLookup.length; i++) {
            quizLearning.quizQuestion.options.push(this.props.viewModel.optionLookup[i]);
        }

        if (this.props.quizLearningObject.quizQuestion.textHtml !== "") {
            quizLearning.quizQuestion.textHtml = this.props.quizLearningObject.quizQuestion.textHtml;
        }

        quizLearning.numberOfQuestionsToAnswer = this.props.learningModuleVM.selectedLearningObject.numberOfQuestionsToAnswer;
        if (quizLearning.quizQuestion.questionTypeId === QuestionType.MultipleChoice || quizLearning.quizQuestion.questionTypeId === QuestionType.MultipleSelect
            || quizLearning.quizQuestion.questionTypeId === QuestionType.YesNo || quizLearning.quizQuestion.questionTypeId === QuestionType.TrueFalse) {
            quizLearning.quizQuestion.options = this.props.viewModel.optionLookup;
            if (this.props.viewModel.optionLookup.length === 0) {
                this.notifications.addDanger("Error", "Options are required", NotificationDuration.Standard);
                return;
            }
        }

        this.props.viewModel.taskRunner.run(async () => {
            var response = await this.quizLearningObjectApiClient.CreateQuizLearningObjectWithQuestion(quizLearning.toJSObject({ includeClean: true }), this.props.learningModuleVM.currentUser.userGuid);
            this.props.quizLearningObject.quizQuestion.questionId = response.data;
            const LoList = (await this.props.viewModel.taskRunner.waitFor(this.apiClient.getLearningObjectLookup(this.props.learningModuleVM.selectedLearningModuleId))).data;
            this.props.learningModuleVM.learningObjectList.set(LoList);
        });

        var responseDnd = await this.props.viewModel.taskRunner.waitFor(this.apiClient.getLearningModuleDNDObjects(this.props.learningModuleVM.selectedLearningModuleId));
        this.props.learningModuleVM.loDragAndDropList = responseDnd.data;
        this.props.learningModuleVM.fetchQuestionsForDnd(quizLearning.learningObjectId);
        this.notifications.addSuccess("Quiz Question ", "Quiz Question has been saved", 4);
        if (quizLearning.quizQuestion.newLearningContent.learningContentId > 0) {
            this.props.viewModel.showAddQuestionModal = true;
        } else {
            this.noAddContent()
        }
    }

    private yesAddQuestion() {
        this.props.viewModel.showAddQuestionModal = false;
        this.props.learningModuleVM.showQuizCard();
    }

    private noAddQuestion() {
        this.props.viewModel.showLinkedResource = false;
        this.props.viewModel.showAddQuestionModal = false;
        this.props.learningModuleVM.hideQuizCard = false;
        this.props.learningModuleVM.hideQuestionDragAndDrop = true;
        this.resetLearningModuleVm();
    }

    private yesAddContent() {
        this.props.viewModel.showLinkedResource = true;
        this.props.viewModel.showAddContentForQuestionModal = false;
    }

    private noAddContent() {
        this.props.viewModel.showLinkedResource = false;
        this.props.viewModel.showAddContentForQuestionModal = false;
        this.props.learningModuleVM.hideQuizCard = false;
        this.resetLearningModuleVm();
    }

    private checkQuestionValid() {
        if (this.props.quizLearningObject.quizQuestion.questionTypeId === null) {
            return true;
        } else if (this.props.quizLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz && this.props.quizLearningObject.quizQuestion.informationLearningObjectId === null) {
            return true;
        } else if (this.props.quizLearningObject.quizQuestion.questionTypeId === QuestionType.MultipleChoice && this.props.viewModel.optionLookup.length === 0 && this.props.quizLearningObject.quizQuestion.meta.text.value === "") {
            return true;
        } else if (this.props.quizLearningObject.quizQuestion.questionTypeId === QuestionType.YesNo && this.props.quizLearningObject.quizQuestion.meta.text.value === "") {
            return true;
        } else if (this.props.quizLearningObject.quizQuestion.questionTypeId === QuestionType.TrueFalse && this.props.quizLearningObject.quizQuestion.meta.text.value === "") {
            return true;
        } else if (this.props.quizLearningObject.quizQuestion.questionTypeId === QuestionType.MultipleSelect && this.props.viewModel.optionLookup.length === 0 && this.props.quizLearningObject.quizQuestion.meta.text.value === "") {
            return true;
        } else {
            return false;
        }
    }

    private removeLearningContent(questionId: number) {
        this.props.learningModuleVM.taskRunner.run(async () => {
            await this.props.learningModuleVM.taskRunner.waitFor(this.apiClient.deleteLearningContentOnQuestion(questionId));
            this.props.learningModuleVM.hideMediaUpload = true;
            this.notifications.addSuccess("Content Deleted", null, NotificationDuration.Standard);
            this.props.learningModuleVM.refreshLOList();
            this.props.learningModuleVM.newLearningContent = new LearningObjectContentLookup();
            this.props.learningModuleVM.quizLearningObject.quizQuestion.contentId = 0;
            this.props.viewModel.showLinkedResource = false;
        });
    }

    private setLinkedLearningObject(informationLearningObjectId: number) {
        this.props.quizLearningObject.quizQuestion.informationLearningObjectId = informationLearningObjectId;
    }

    public render() {
        const quizLearningObject = this.props.quizLearningObject;
        const learningContent = this.props.learningContent;
        const fileTitle = this.props.learningModuleVM.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information ? "Media Upload" : "Question Linked Resource";

        return (
            <div>
                <Neo.Card icon="file" title={this.props.learningModuleVM.selectedLearningObjectId === 0 ? "New Quiz Question" : "Quiz Question: "} hidden={this.props.hidden}>
                    <section>
                        {
                            this.props.hidden || <RichTextEditor htmlContent={this.props.viewModel.meta.curQuestionHtml} viewModel={this.props.viewModel.richtextVm} bind={quizLearningObject.quizQuestion.meta.text} labelText={"Question*"} showCustomButtons onChange={() => { this.currentQuestionHtmlChange(quizLearningObject.quizQuestion.text) }} />
                        }
                    </section>
                    <section>
                        {/*Create Question for Selected Learning Object*/}
                        {this.props !== null &&
                            <Neo.Form validationDisplayMode={ValidationDisplayMode.Always}>
                                <Neo.GridLayout className="mb-10">
                                    <Neo.FormGroupFloating formNoValidate={false} bind={quizLearningObject.quizQuestion.meta.questionTypeId}
                                        select={{
                                            itemSource: Data.StaticDataSource.fromEnum(QuestionType),
                                            filterPredicate: (item) => (item.id !== QuestionType.LikertScale),
                                            onItemSelected: (item) => {
                                                this.setSelectedQuestionType(item?.id as number)
                                            }
                                        }} /><br />
                                </Neo.GridLayout>
                                <br />
                                {/* <Neo.GridLayout className="mb-10">
                                    <Neo.FormGroupFloating label="Question Order" disabled={true} autoFocus bind={quizLearningObject.quizQuestion.meta.questionOrder} />
                                </Neo.GridLayout>
                                <br /> */}
                                <Neo.GridLayout className="mb-10">
                                    <Neo.FormGroupFloating label="Learning Object this question relates to" select={{
                                        items: this.props.learningModuleVM.learningObjectList.filter(function (item) { return item.learningObjectTypeId === LearningObjectType.Information }),
                                        displayMember: "learningObjectTitle",
                                        valueMember: "learningObjectId",
                                        allowNulls: quizLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz ? false : true,
                                    }} formNoValidate={false} input={{ rows: 2 }} bind={quizLearningObject.quizQuestion.meta.informationLearningObjectId} /><br />
                                </Neo.GridLayout>
                                <Neo.GridLayout hidden={(quizLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz && quizLearningObject.quizQuestion.informationLearningObjectId !== null) || quizLearningObject.learningObjectTypeId !== LearningObjectType.FinalQuiz}>
                                    <div className="invalid-feedback error mb-3" style={{ display: "block" }}><span>Related Learning Object required on final quizzes.</span></div>
                                </Neo.GridLayout>
                                {
                                    (quizLearningObject.quizQuestion.questionTypeId === QuestionType.MultipleChoice) &&
                                    <Neo.GridLayout md={1} lg={2}>
                                        <NeoGrid.Grid showAddButton className="mb-20 mt-20" items={this.props.viewModel.optionLookup}>
                                            {(option, OptionMeta) => (
                                                <NeoGrid.Row>
                                                    <NeoGrid.Column label="Option" width="400px" placeholder="Option" bind={OptionMeta.option} />
                                                    <NeoGrid.Column bind={OptionMeta.correctAnswerInd} width="150px" disabled={false} onChange={() => { this.setSelectOption(option) }} />
                                                    <NeoGrid.ButtonColumn showDelete />
                                                </NeoGrid.Row>
                                            )}
                                        </NeoGrid.Grid>
                                    </Neo.GridLayout>
                                }
                                {
                                    (quizLearningObject.quizQuestion.questionTypeId === QuestionType.YesNo) &&
                                    <Neo.GridLayout md={1} lg={2}>
                                        <NeoGrid.Grid className="mb-20 mt-20" items={this.props.viewModel.optionLookup}>
                                            {(option, OptionMeta) => (
                                                <NeoGrid.Row>
                                                    <NeoGrid.Column label="Option" width="400px" disabled={true} placeholder="Option" bind={OptionMeta.option} />
                                                    <NeoGrid.Column bind={OptionMeta.correctAnswerInd} width="150px" disabled={false} onChange={() => { this.setSelectOption(option) }} />
                                                </NeoGrid.Row>
                                            )}
                                        </NeoGrid.Grid>
                                    </Neo.GridLayout>
                                }
                                {
                                    (quizLearningObject.quizQuestion.questionTypeId === QuestionType.TrueFalse) &&
                                    <Neo.GridLayout md={1} lg={2}>
                                        <NeoGrid.Grid className="mb-20 mt-20" items={this.props.viewModel.optionLookup}>
                                            {(option, OptionMeta) => (
                                                <NeoGrid.Row>
                                                    <NeoGrid.Column disabled={true} label="Option" width="400px" placeholder="Option" bind={OptionMeta.option} />
                                                    <NeoGrid.Column bind={OptionMeta.correctAnswerInd} width="150px" disabled={false} onChange={() => { this.setSelectOption(option) }} />
                                                </NeoGrid.Row>
                                            )}
                                        </NeoGrid.Grid>
                                    </Neo.GridLayout>
                                }
                                {
                                    (quizLearningObject.quizQuestion.questionTypeId === QuestionType.MultipleSelect) &&
                                    <Neo.GridLayout md={1} lg={2}>
                                        <NeoGrid.Grid showAddButton className="mb-20 mt-20" items={this.props.viewModel.optionLookup}>
                                            {(option, OptionMeta) => (
                                                <NeoGrid.Row>
                                                    <NeoGrid.Column label="Option" width="400px" placeholder="Option" bind={OptionMeta.option} />
                                                    <NeoGrid.Column bind={OptionMeta.correctAnswerInd} width="150px" disabled={false} />
                                                    <NeoGrid.ButtonColumn showDelete />
                                                </NeoGrid.Row>
                                            )}
                                        </NeoGrid.Grid>
                                    </Neo.GridLayout>
                                }
                            </Neo.Form>
                        }
                    </section>
                    <section>
                        <Neo.Button hidden={learningContent.learningContent.learningContentId > 0} onClick={() => this.AddLinkedResource()} disabled={learningContent.learningContent.learningContentId > 0} isOutline className="mt-2 mr-2" icon="fa-save" variant="info">
                            Add Question Linked Resource
                        </Neo.Button>
                        {this.props.viewModel.showLinkedResource &&
                            <Neo.Button hidden={learningContent.learningContent.learningContentId > 0} onClick={() => this.CancelLinkedResource()} isOutline className="mt-2 mr-2" icon="fa-danger" variant="success">
                                Cancel Linked Resource
                            </Neo.Button>
                        }
                        {!this.props.viewModel.showLinkedResource &&
                            <Neo.Button onClick={() => this.saveQuizLearningObject()}
                                isOutline className="mt-2 mr-2" icon="fa-save" variant="success"
                                disabled={this.checkQuestionValid()}>
                                Update Draft Question
                            </Neo.Button>
                        }
                        <Neo.Button onClick={() => this.props.learningModuleVM.showQuizCard()} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success" hidden={quizLearningObject.quizQuestion.contentId > 0}>
                            Add Question
                        </Neo.Button>
                    </section>
                    <section>
                        <Neo.Card icon="file" title={fileTitle} hidden={!this.props.viewModel.showLinkedResource} >
                            <Neo.Form validationDisplayMode={ValidationDisplayMode.AfterBlur}>
                                <Neo.GridLayout>
                                    <Neo.FormGroupFloating bind={learningContent.learningContent.meta.title} /><b />
                                    <Neo.FormGroupFloating bind={learningContent.meta.mediaTypeFetched} label="Media Type" disabled={quizLearningObject.quizQuestion.contentId > 0}
                                        select={{
                                            itemSource: Data.StaticDataSource.fromEnum(LearningContentMediaType),
                                            onItemSelected: (item) => {
                                                this.setSelectedMediaType(learningContent.meta.mediaTypeFetched.value as number)
                                            }
                                        }}
                                    /><b />
                                    <Neo.FormGroupFloating label="Alternative URL" placeholder="www.urlExample.com" bind={learningContent.learningContent.meta.externalResourceLocation}
                                        hidden={learningContent.learningContent.mediaType === LearningContentMediaType.YouTube

                                        } /><b />
                                    <Neo.FormGroupFloating label="URL Key" bind={learningContent.learningContent.meta.externalResourceLocationKey} hidden={learningContent.learningContent.mediaType !== LearningContentMediaType.YouTube} /><b />
                                </Neo.GridLayout>
                            </Neo.Form>
                            <Neo.FormGroupFloating hidden={this.ShowFileExtensionsTypeBinding(learningContent.learningContent.mediaType as number)}
                                display={learningContent.learningContent.meta.allowedFileExtensions} /><b />
                            <Neo.FileContext
                                uploadEndpoint={learningContent.learningContent.mediaType === LearningContentMediaType.Video ? this.apiClient.getUploadBigVideoFileToBlobUrl() : (learningContent.learningContent.mediaType === LearningContentMediaType.SCORM ? this.apiClient.getUploadBigSCORMFileToBlobUrl() : this.apiClient.getUploadFileToBlobUrl(this.props.viewModel.selectedMediaType))}
                                afterUpload={this.afterUpload.bind(this)}
                                allowedExtensions={this.allowedExtensions}>
                                <Neo.FileDropArea className="mb-10" hidden={learningContent.learningContent.mediaType === LearningContentMediaType.Link
                                    || learningContent.learningContent.mediaType === LearningContentMediaType.YouTube
                                    || this.props.viewModel.fileDescriptorList.length !== 0
                                }>
                                    <div hidden={learningContent.mediaTypeFetched === LearningContentMediaType.Unsupported || learningContent.learningContent.title === ""}>
                                        <Neo.FileUploadButton disabled={quizLearningObject.quizQuestion.contentId > 0} >Select a file</Neo.FileUploadButton> or drop a file here.
                                        <Neo.FileUploadProgress variant={"info"} />
                                    </div>
                                    <div hidden={learningContent.mediaTypeFetched !== LearningContentMediaType.Unsupported || learningContent.learningContent.title !== ""}>
                                        <p>Please choose a media type and title</p>
                                    </div>
                                </Neo.FileDropArea>
                            </Neo.FileContext>
                            <Neo.GridLayout md={2} lg={3} className="mt-5" hidden={learningContent.mediaTypeFetched === LearningContentMediaType.Link || learningContent.mediaTypeFetched === LearningContentMediaType.YouTube}>
                                <NeoGrid.Grid className="mb-10 mt-10" items={this.props.viewModel.fileDescriptorList} hidden={this.props.viewModel.fileDescriptorList.length === 0}>
                                    {(file, fileMeta) => (
                                        <NeoGrid.Row>
                                            <NeoGrid.Column label="File Name" display={fileMeta.fileName} />
                                            <NeoGrid.Column label="File Type" display={fileMeta.mimeType} />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.GridLayout>
                            <Neo.Button text="Delete Linked Resource"
                                hidden={this.props.learningModuleVM.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information}
                                disabled={learningContent.learningContentId === 0}
                                onClick={() => this.removeLearningContent(quizLearningObject.quizQuestion.questionId)} className="mt-2 mr-2" icon="trash" variant="danger" style={{ float: "right" }} />
                        </Neo.Card>
                        {(this.props.viewModel.showLinkedResource || this.props.viewModel.fileDescriptorList.length > 0) &&
                            <Neo.Button onClick={() => this.saveQuizLearningObject()} hidden={!this.props.viewModel.showLinkedResource}
                                isOutline className="mt-2 mr-2" icon="fa-save" variant="success">
                                Update Draft Question
                            </Neo.Button>
                        }
                        <Neo.Button text="Upload a new media file." hidden={quizLearningObject.quizQuestion.contentId === null || quizLearningObject.quizQuestion.contentId === 0} onClick={() => {
                            this.setAllowedExtensions(this.props.learningContent.mediaTypeFetched);
                            this.props.viewModel.fileDescriptorList = new List(FileLookup);
                        }} />

                        {
                            this.props.viewModel.showModerationNotes ||
                            <Neo.Card className="moderation-notes">
                                <RichTextEditor readOnly={true} bind={this.props.learningModuleVM.selectedLearningObject.meta.moderationNotes} viewModel={this.props.viewModel.richTextContentModeration} labelText="Learning Object Moderation Notes" />
                            </Neo.Card>
                        }
                        <Neo.Button hidden={this.props.learningModuleVM.selectedLearningModule.moderationState !== ModerationState.Review} text={this.props.viewModel.showModerationNotes ? "Learning Object Moderation Notes" : "Hide Learning Object Moderation Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2" onClick={() => this.props.viewModel.showModerationNotes = !this.props.viewModel.showModerationNotes} />
                    </section>
                </Neo.Card>

                <Neo.Modal title="Add Question" size="lg"
                    show={this.props.viewModel.showAddQuestionModal && this.props.learningModuleVM.selectedLearningObject.learningObjectTypeId !== LearningObjectType.Information}
                    onClose={() => this.noAddQuestion()}
                    acceptButton={{
                        text: "Yes", variant: "info", icon: "save",
                        onClick: () => { this.yesAddQuestion() }
                    }} closeButton={{
                        text: "No", variant: "secondary", icon: "times",
                    }} >
                    <Neo.Loader task={this.props.viewModel.taskRunner}>
                        <div>
                            <p>Do you wish to add another question? </p>
                        </div>
                    </Neo.Loader>
                </Neo.Modal>
                {/* <Neo.Modal title="Add Linked Resource?" size="lg"
                    show={this.props.viewModel.showAddContentForQuestionModal && this.props.learningModuleVM.selectedLearningObject.learningObjectTypeId !== LearningObjectType.Information}
                    onClose={() => this.noAddContent()}
                    acceptButton={{
                        text: "Yes", variant: "info", icon: "save",
                        onClick: () => { this.yesAddContent() }
                    }} closeButton={{
                        text: "No", variant: "secondary", icon: "times",
                    }} >
                    <Neo.Loader task={this.props.viewModel.taskRunner}>
                        <div>
                            <p>Do you wish to add a linked resource? </p>
                        </div>
                    </Neo.Loader>
                </Neo.Modal> */}
            </div>
        );
    }
}