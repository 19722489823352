import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class ForumEntity extends ModelBase {

    public forumId: number = 0;

    @Rules.Required()
    @Rules.StringLength(500)
    public name: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.name) {
            return "New forum entity";
        } else {
            return this.name;
        }
    }
}