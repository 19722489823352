import { Attributes, ITaskRunner, List, LookupBase, NeoModel, Utils } from '@singularsystems/neo-core';
import { LearningPathwayType } from '../../LearningPathways/LearningPathwayType.enum';
import LearningPathwayParticipant from '../../LearningPathways/LearningPathwayParticipant';
import LearningPathwayModule from '../../LearningPathways/LearningPathwayModule';
import { UserLearningStatus } from '../../UserLearning/UsersLearningStatus.enum';
import { AppService } from '../../../../Identity/IdentityTypes';
import Types from '../../../../App/AppTypes';
import LearningModuleLookup from '../LearningModuleLookup';

@NeoModel
export default class LearningPathwayPagerLookup extends LookupBase {

    public learningPathwayId: number = 0;

    public title: string = "";

    public ownerGuid: string = Utils.emptyGuid();

    public learningPathwayType: LearningPathwayType | null = null;

    @Attributes.Date()
    public completedDate: Date | null = null;

    public userLearningStatus: UserLearningStatus | null = null;

    public awaitingApproval: boolean = false;

    @Attributes.Nullable()
    public parentId: number | null = null;

    public participants = new List(LearningPathwayParticipant);

    public learningPathwayModules = new List(LearningPathwayModule);

    public learningModuleLookups = new List(LearningModuleLookup);

    // Client only properties / methods
    public hasInvites: boolean = false;

    @Attributes.Observable()
    public isExpanded: boolean = false;

    public isCompleted = false;

    public createdByGuid: string = ""; 

    @Attributes.NoTracking()
    public taskRunner: ITaskRunner = AppService.get(Types.Neo.TaskRunner);
}
