import { LookupBase, NeoModel } from '@singularsystems/neo-core';
@NeoModel
export default class Tag3Lookup extends LookupBase {

    public tag1Id: number = 0;

    public tag2Id: number = 0;

    public tagName: string = "";

    public tag3Id: number = 0;

    public tagDescription: string = "";
}