import { Attributes, Misc, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class ModuleSuggestionLookup extends ModelBase {

    public moduleId: number = 0;

    public moduleTitle: string = "";

    @Attributes.Integer()
    public cptdPoints: number = 0;

    public moduleDescription: string = "";

    public tag1: string = "";

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public tag1Id: number | null = null;

    public tag2: string = "";

    public tag2s: string[] = [];

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.moduleTitle) {
            return "New module suggestion lookup";
        } else {
            return this.moduleTitle;
        }
    }
}