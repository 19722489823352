import { Attributes, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class OrgLevel {

    @Attributes.Nullable()
    public organisationId: number | null = null;
    @Attributes.Nullable()
    public provinceId: number | null = null;
    @Attributes.Nullable()
    public districtId: number | null = null;
    @Attributes.Nullable()
    public circuitId: number | null = null;
    @Attributes.Nullable()
    public schoolId: number | null = null;
    @Attributes.Nullable()
    public schoolManagementTeamId: number | null = null;

}