import { List, ModelBase, NeoModel } from "@singularsystems/neo-core";
import LearningPathwayImport from "./LearningPathwayImport";

@NeoModel
export default class LearningPathwayImportResult extends ModelBase {

    public warnings: string[] = [];

    public fileErrors: string[] = [];

    public rowErrors: string[] = [];
    
    public importRows = new List(LearningPathwayImport);

    // Client only properties / methods
}