import { Attributes, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import Types from '../../../App/AppTypes';
import { RaceComponentVM } from "../../Components/RacesComponent";
import { SACEImportComponentVM } from '../../Components/SACEImportComponent';
import { PersalImportComponentVM } from '../../Components/PersalImportComponent';
import { TitleComponentVM } from '../../Components/TitlesComponent';
import { AppService } from "../../../App/Services/AppService";
import { UserMaintenanceTypes } from './UserImportsView';

@NeoModel
export default class UserImportsVM extends Views.ViewModelBase {

    @Attributes.OnChanged<UserImportsVM>(c => c.onTabChanged)
    public selectedTab = UserMaintenanceTypes.Introduction;
    public raceVM: RaceComponentVM;
    public titleVM: TitleComponentVM;
    public saceImportVM: SACEImportComponentVM;
    public persalImportVM: PersalImportComponentVM;

    private saceDataFetched: boolean = false;
    private persalDataFetched: boolean = false;
   
    constructor(taskRunner = AppService.get(Types.Neo.TaskRunner)) {
        super(taskRunner);

        this.raceVM = new RaceComponentVM(this.taskRunner);
        this.titleVM = new TitleComponentVM(this.taskRunner);
        this.saceImportVM = new SACEImportComponentVM(this.taskRunner);
        this.persalImportVM = new PersalImportComponentVM(this.taskRunner);
    }

    public async initialise() {
    }

    public async onTabChanged() {
       if (this.selectedTab === UserMaintenanceTypes.SACEImport && !this.saceDataFetched) {
            this.saceDataFetched = true;
        }
        else if (this.selectedTab === UserMaintenanceTypes.PersalImport && !this.persalDataFetched) {
            this.persalDataFetched = true;
        }
    }
}