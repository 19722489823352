import { Types } from '../../IdentityTypes';
import { AppService } from '../../CommonIdentityTypes';
import { RouteSecurityService as NeoRouteSecurityService } from '@singularsystems/neo-core/dist/Routing/RouteSecurityService';
import AppTypes from '../../../App/AppTypes';
import { injectable } from 'inversify';
import { IAppRoute } from '../../IdentityRoutes';
import { UserTypes } from '../../../App/Models/Enums/UserTypes.enums';
import * as DomainRoles from '../../../App/Models/Enums/DomainRoles.enum';

@injectable()
export class RouteSecurityService extends NeoRouteSecurityService {
    constructor(
        protected authorisationService = AppService.get(AppTypes.Neo.Security.AuthorisationService),
        protected authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService)
    ) {
        super(authorisationService);
    }

    public routeAllowed(route: IAppRoute): boolean {
        // Check for a denied role first. (But do not apply it to system admins!)
        if (route.denyRole !== undefined && route.denyRole != null) {
            if (this.authorisationService.hasRole(route.denyRole) && !(this.authorisationService.hasRole(DomainRoles.SystemPermission.SystemSettings))) {
                return false;
            }
        }
        if (this.checkUserLevel(route)) {
            return super.routeAllowed(route);
        } else {
            return false;
        }
    }

    private checkUserLevel(route: IAppRoute): boolean {
        if (route.userType) {
            if (this.authenticationService.user) {
                if (route.userType === UserTypes.Admin) {
                    return this.authenticationService.user.isUserType(UserTypes.Admin);
                } else if (route.userType === UserTypes.Verified) {
                    return this.authenticationService.user.isUserType(UserTypes.Verified) || this.authenticationService.user.isUserType(UserTypes.Admin);
                } else if (route.userType === UserTypes.Unverified) {
                    return this.authenticationService.user.isUserType(UserTypes.Unverified) || this.authenticationService.user.isUserType(UserTypes.Verified) || this.authenticationService.user.isUserType(UserTypes.Admin);
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }
}
