import { AppServices } from "@singularsystems/neo-core";
import { Types } from '../Learning/LearningTypes';
import Tag1ApiClient from './ApiClients/Maintenance/Tag1ApiClient'
import Tag3ApiClient from './ApiClients/Maintenance/Tag3ApiClient'
import Tag1QueryApiClient from './ApiClients/Queries/Tag1QueryApiClient'
import LearningModuleQueryApiClient from './ApiClients/Queries/LearningModuleQueryApiClient'
import Tag2QueryApiClient from "./ApiClients/Queries/Tag2QueryApiClient";
import Tag2ApiClient from "./ApiClients/Maintenance/Tag2ApiClient";
import Tag3QueryApiClient from "./ApiClients/Queries/Tag3QueryApiClient";
import LearningPathwayQueryApiClient from "./ApiClients/Queries/LearningPathwayQueryApiClient";
import QuizLearningObjectApiClient from "./ApiClients/Maintenance/QuizLearningObjectApiClient";
import SelfAssessmentApiClient from "./ApiClients/Maintenance/SelfAssessmentApiClient";
import ModerationQueryApiClient from "./ApiClients/Queries/ModerationQueryApiClient";
import ContentConsumptionQueryApiClient from "./ApiClients/Queries/ContentConsumptionQueryApiClient";
import DownloadContentApiClient from './ApiClients/Downloads/DownloadContentApiClient';
import BadgesQueryApiClient from "./ApiClients/Queries/BadgesQueryServiceApiClient";
import LearningPathwayImportApiClient from "./ApiClients/Import/LearningPathwayImportApiClient";

export const LearningAppModule = new AppServices.Module("Learning", container => {

    // Api clients
    container.bind(Types.Learning.ApiClients.Tag1ApiClient).to(Tag1ApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.Tag2ApiClient).to(Tag2ApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.Tag2QueryApiClient).to(Tag2QueryApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.Tag3QueryApiClient).to(Tag3QueryApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.Tag3ApiClient).to(Tag3ApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.Tag1QueryApiClient).to(Tag1QueryApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.LearningModuleQueryApiClient).to(LearningModuleQueryApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.LearningPathwayQueryApiClient).to(LearningPathwayQueryApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.QuizLearningObjectApiClient).to(QuizLearningObjectApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.SelfAssessmentApiClient).to(SelfAssessmentApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.ModerationQueryApiClient).to(ModerationQueryApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.ContentConsumptionQueryApiClient).to(ContentConsumptionQueryApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.DownloadContentApiClient).to(DownloadContentApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.BadgeQueryApiClient).to(BadgesQueryApiClient).inSingletonScope();
    container.bind(Types.Learning.ApiClients.LearningPathwayImportApiClient).to(LearningPathwayImportApiClient).inSingletonScope();
    /// Services
})