import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import UserAssessmentQuestionReviewLookup from './UserAssessmentQuestionReviewLookup';

@NeoModel
export default class UserAssessmentReviewLookup extends ModelBase {

    public selfAssessmentId: number = 0;

    public userSelfAssessmentId: number = 0;

    public selfAssessmentTitle: string = "";

    public selfAssessmentSDescription: string = "";

    @Attributes.Date()
    public completedDate: Date = new Date();

    @Attributes.Integer()
    public assessmentScore: number = 0;

    public questions = new List(UserAssessmentQuestionReviewLookup);

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.selfAssessmentTitle) {
            return "New user assessment review lookup";
        } else {
            return this.selfAssessmentTitle;
        }
    }
}