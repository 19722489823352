import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import UserDataImportResult from '../../Models/Commands/UserDataImportResult';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'
import OrgLevel from '../../Models/Users/OrgLevel';

export interface IUserDataImportApiClient {

    /** 
     * Uploads an excel file with user import data.
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @returns A file key to be passed into doImport().
     */
    getUploadUserFileUrl(): string;

    /** 
     * Processes an import for a previously uploaded file.
     * @param key Key returned from upload educator file.
     * @returns Import Result file.
     */
    doImport(key: string, orgLevel: OrgLevel): AxiosPromise<Model.PlainObject<UserDataImportResult>>;

    // Client only properties / methods
}

@injectable()
export default class UserDataImportApiClient extends Data.ApiClientBase implements IUserDataImportApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/UserImport`);
    }

    public getUploadUserFileUrl(): string {
        return `${this.apiPath}/uploadFile`;
    }

    public doImport(key: string, orgLevel: OrgLevel): AxiosPromise<Model.PlainObject<UserDataImportResult>> {
        return this.axios.post(`${this.apiPath}/doImport/${encodeURIComponent(key)}`, orgLevel);
    }

    // Client only properties / methods
}