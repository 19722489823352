import { Attributes, Misc, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { ModeratableTag3 } from '../Moderation/ModeratableFieldValues';
import LearningObjectBase from '../LearningObjects/LearningObjectBase';

@NeoModel
export default class LearningObjectTag3 extends ModelBase {

    constructor() {
        super();
        this.tag3 = new ModeratableTag3();
    }
    public learningObjectTag3Id: number = 0;

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public learningObjectId: number | null = 0;

    @Attributes.ChildObject(ModeratableTag3)
    public tag3: ModeratableTag3;

    public learningObjects: LearningObjectBase | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New learning object tag 3";
        } else {
            return "Learning Object Tag 3";
        }
    }
}