import { LearningContentMediaType } from "../../Learning/Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import ImageIcon from '../../Learning/assets/Image.png';
import DocumentIcon from '../../Learning/assets/Document.png';
import SpeakerIcon from '../../Learning/assets/Sound.png';
import VideoIcon from '../../Learning/assets/Video.png';
import ConvoIcon from '../../Learning/assets/Conversation.png';
import LinkIcon from '../../Learning/assets/Link.png';
import ContentConsumptionLookup from "../../Learning/Models/ContentCosumption/ContentConsumptionLookup";

export function validURL(str: string) {
  if (str) {
    str = str.replace('(', '%28') // replaces bracket with its URL-encode
    str = str.replace(')', '%29') // replaces bracket with its URL-encode
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol // to do add http
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator        
    return !!pattern.test(str);
  }
  else {
    return true;
  }
}

export function truncateString(value: string, trimLength: number = 50) {
  if (value) {
    // Elipsis is three chracters, i want 50 in total. (-1 for zero base)
    if (value.length > trimLength - 1) {
      return value.substring(0, trimLength - 4) + '...';
    }
    else {
      return value;
    }
  }
  return "";
}

export function truncateLongString(value: string, trimLength: number = 235) {
  return truncateString(value, trimLength);
}

export function DownloadFile(filePath: string) {
  window.open(filePath, '_blank');
}

export function OpenLink(link: string) {
  if (link) {
    window.open(link, '_blank');
  }
}

export function GetRandomCardColor() {
  var colors = ["warning", "success", "danger", "info", "primary", "rose"]
  var randomColor = colors[Math.floor(Math.random() * colors.length)] as ("warning" | "success" | "danger" | "info" | "primary" | "rose")
  return randomColor;
}

export function buildUpCustomHTMLForVideo(originalUrl: string) {
  return "<h5>Please wait patiently while the video element loads.</h5>" +
    "<video width='100%' height='500px' controls>" +
    "<source src='" + originalUrl + "' type='video/mp4'></video>"; // TODO this encoding is wrong!!
}

export function buildUpCustomHTMLForImage(originalUrl: string) {
  return "<h5>Please wait patiently while the image element loads.</h5>" +
    "<img alt='Content' src= '" + originalUrl + "' />" ;
}

export function buildUpCustomHTMLForPDF(originalUrl: string) {
  return "<h5>Please wait patiently while the document element loads.</h5>" +
    "<iframe type='application/pdf' src='" + originalUrl + "' width='100%' height='500px' data-isloaded='0'>" ;
}

export function buildUpCustomHTMLForSound(originalUrl: string) {
  return "<h5>Please wait patiently while the sound element loads.</h5>" +
    "<audio controls width='100%' height='500px'>" +
    "<source src='" + originalUrl + "' type='audio/wav'>" +
    "</audio>";
}

export function GetContentImage(mediaType: LearningContentMediaType) {
  switch (mediaType) {
    case LearningContentMediaType.Document: {
      return DocumentIcon;
    }
    case LearningContentMediaType.Image: {
      return ImageIcon;
    }
    case LearningContentMediaType.Sound: {
      return SpeakerIcon;
    }
    case LearningContentMediaType.Video: {
      return VideoIcon;
    }
    case LearningContentMediaType.SCORM: {
      return DocumentIcon;
    }
    case LearningContentMediaType.Conversation: {
      return ConvoIcon;
    }
    case LearningContentMediaType.YouTube: {
      return VideoIcon;
    }
    case LearningContentMediaType.Link: {
      return LinkIcon;
    }
  }
  return ""
}

export function GetButtonText(content: ContentConsumptionLookup) {
  if (content.mediaType === LearningContentMediaType.Link) {
    return content.title;
  }
  if (content.mediaType === LearningContentMediaType.Sound) {
    return "Listen";
  }
  if (content.mediaType === LearningContentMediaType.Conversation) {
    return "View";
  }
  return "View";
}
