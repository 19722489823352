import { EnumHelper, ModalUtils, Misc, Validation } from "@singularsystems/neo-core";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import SchoolLookup from "../Models/Lookups/SchoolLookup";
import { SchoolPhase } from "../Models/Organisations/Enums/SchoolPhase.enum";
import { SchoolSector } from "../Models/Organisations/Enums/SchoolSector.enum";
import { SchoolStatus } from "../Models/Organisations/Enums/SchoolStatus.enum";
import PagerTextFilter from "../../App/Components/PagerTextFilter"
import SchoolComponentVM from "./SchoolComponentVM"
import SchoolImportModal from "./SchoolImportModal";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";

interface ISchoolComponentProps {
    viewModal: SchoolComponentVM;
}

@observer
export default class SchoolView extends React.Component<ISchoolComponentProps>{

    private async deleteSchoolPopUp(schoolName: string, schoolId: number) {
        if (await ModalUtils.showYesNoDismissible("Delete School " + schoolName,
            <div>
                <p>
                    Are you sure you want to delete this school?
                </p>
            </div>) === Misc.ModalResult.Yes) {
            this.props.viewModal.deleteSchool(schoolId);
        }
    }

    // Imports
    public async ShowSchoolImportModal() {
        this.props.viewModal.schoolImportModalVM.show()
    }

    public async CloseSchoolImportModal() {
        this.props.viewModal.schoolLookupPageManager.refreshData(true, this.props.viewModal.taskRunner);
        this.props.viewModal.schoolImportModalVM.showSchoolImportModal = false;
    }

    public render() {
        const viewModel = this.props.viewModal;
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-school fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Schools</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="row mb-2">
                                    <div className="col-md-10">
                                        <Neo.Button variant="success" isOutline icon="import"
                                            onClick={() => viewModel.showSchoolEditModal(0)} >Add New School</Neo.Button>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="schoolImportbtn">
                                            <Neo.Button variant="primary" icon="import"
                                                onClick={() => viewModel.ShowSchoolImportModal()} >Import Schools</Neo.Button>
                                        </div>
                                    </div>
                                </div>
                                <PagerTextFilter filterProperty={viewModel.criteria.meta.keyword} pageManager={viewModel.schoolLookupPageManager} taskRunner={viewModel.taskRunner} label="Search for a School" />
                                <Neo.FormGroup bind={viewModel.criteria.meta.circuitId}
                                    select={{
                                        itemSource: viewModel.circuitList,
                                        allowNulls: true,
                                        onItemSelected: (item) => {
                                            viewModel.autoDispose(viewModel.criteria.onAnyPropertyChanged(() => viewModel.schoolLookupPageManager.refreshData(true, viewModel.taskRunner)))
                                        }
                                    }} label="Choose a Circuit" placeholder="Filter by Circuit"
                                    append={<Neo.Button tooltip="Clear Search" size="sm" tabIndex={-1} icon="eraser" onClick={() => viewModel.clearFilters()}></Neo.Button>}></Neo.FormGroup>
                                <div className="row ">
                                    <div className="col-md-12">
                                        <Neo.Pager
                                            pageManager={viewModel.schoolLookupPageManager}
                                            pageControlProps={{
                                                firstText: "",
                                                prevText: "Prev",
                                                nextText: "Next",
                                                lastText: "",
                                                alignment: "center",
                                                autoHide: false,
                                                pageSizeLabel: "Show"
                                            }}>
                                            <NeoGrid.Grid<SchoolLookup> className="table-hover" >
                                                {(school: SchoolLookup, schoolMeta) => (
                                                    <NeoGrid.Row>
                                                        <NeoGrid.Column display={schoolMeta.schoolName} />
                                                        <NeoGrid.Column display={schoolMeta.townCity} />
                                                        <NeoGrid.Column readOnly display={schoolMeta.schoolStatusId} select={{ items: EnumHelper.asList(SchoolStatus) }} />
                                                        <NeoGrid.Column display={schoolMeta.schoolSectorId} select={{ items: EnumHelper.asList(SchoolSector) }} />
                                                        <NeoGrid.Column display={schoolMeta.circuitId} select={{ itemSource: viewModel.circuitList }} />
                                                        <NeoGrid.ButtonColumn>
                                                            <Neo.Button icon="edit" isOutline variant="success" onClick={() => viewModel.showSchoolEditModal(school.schoolId)}>Edit</Neo.Button>
                                                            <Neo.Button icon="trash" variant="danger" onClick={() => this.deleteSchoolPopUp(school.schoolName, school.schoolId)} />
                                                        </NeoGrid.ButtonColumn>
                                                    </NeoGrid.Row>
                                                )}
                                            </NeoGrid.Grid>
                                        </Neo.Pager>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>

                <SchoolImportModal viewModal={viewModel.schoolImportModalVM} schoolsImported={this.CloseSchoolImportModal.bind(this)} />

                <Neo.Modal title={viewModel.selectedSchoolId !== 0 ? "Edit School" : "Create School"} size="xl"
                    show={viewModel.showEditModal}
                    formProps={{
                        model: viewModel.selectedSchool,
                        validationDisplayMode: Validation.DisplayMode.Always,
                        showSummaryModal: true
                    }}
                    onClose={() => viewModel.closeEditModal()}
                    acceptButton={{
                        text: "Save", variant: "warning", icon: "check",
                        onClick: () => viewModel.saveSchool()
                    }} closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times"
                    }} >
                    {() => (viewModel.selectedSchool &&
                        <div>
                            <Neo.GridLayout md={2} lg={3}>
                                <Neo.FormGroup bind={viewModel.selectedSchool.meta.schoolName} autoFocus />
                                <Neo.FormGroup bind={viewModel.selectedSchool.meta.schoolSector} select={{ items: EnumHelper.asList(SchoolSector) }} />
                                <Neo.FormGroup bind={viewModel.selectedSchool.meta.schoolStatus} select={{ items: EnumHelper.asList(SchoolStatus) }} />
                                <Neo.FormGroup bind={viewModel.selectedSchool.meta.schoolPhase} select={{ items: EnumHelper.asList(SchoolPhase) }} />
                                <Neo.FormGroup bind={viewModel.selectedSchool.meta.streetAddressLine1} label="Street Address" />
                                <Neo.FormGroup bind={viewModel.selectedSchool.meta.postalAddressLine1} label="Postal Address" />
                                <Neo.FormGroup bind={viewModel.selectedSchool.meta.suburb} />
                                <Neo.FormGroup bind={viewModel.selectedSchool.meta.townCity} />
                                <Neo.FormGroup bind={viewModel.selectedSchool.meta.townshipVillage} />
                                <Neo.FormGroup label="Circuit" bind={viewModel.selectedSchool.meta.circuitId} select={{ itemSource: viewModel.circuitList }} />
                            </Neo.GridLayout>
                        </div>
                    )}
                </Neo.Modal>
            </div>
        )
    }
}

