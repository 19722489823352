import React, { Component } from "react";
import classNames from "classnames";

interface OptionProps {
  onClick: () => any;
  className: string;
  activeClassName?: string;
  active?: boolean;
  disabled?: boolean;
  title: string;
}

export default class Option extends Component<
  React.PropsWithChildren<OptionProps>
> {
  onClick = () => {
    const { disabled, onClick } = this.props;
    if (!disabled) {
      onClick();
    }
  };

  render() {
    const { children, className, activeClassName, active, disabled, title } =
      this.props;
    return (
      <div
        className={classNames("rdw-option-wrapper", className, {
          [`rdw-option-active ${activeClassName ?? ""}`]: active ?? false,
          "rdw-option-disabled": disabled ?? false,
        })}
        onClick={this.onClick}
        aria-selected={active ?? false}
        title={title}
      >
        {children}
      </div>
    );
  }
}
