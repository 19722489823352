import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from 'react';

interface IGridItemProps {
  children?: React.ReactNode,
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sm?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const styles = {
  grid: {
    padding: "0 15px !important",
  },
};

const useStyles = makeStyles(styles);

export default function GridContainer(props: IGridItemProps) {
  const classes = useStyles();
  const { children } = props;
  return (
    <Grid item sm={props.sm} xs={props.xs} md={props.md} lg={props.lg} className={classes.grid}>
      {children}
    </Grid>
  );

}