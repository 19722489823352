import { Attributes, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import School from './School';

@NeoModel
export default class SchoolManagementTeam extends ModelBase {

    public schoolManagementTeamID: number = 0;

    @Rules.Required()
    @Attributes.Display("School")
    public schoolId: number | null = 0;

    public school: School | null = null;

    @Rules.Required()
    @Rules.StringLength(50)
    public managementTeamName: string = "";

}