import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class SchoolGroupLookup extends LookupBase {

    public circuitId: number = 0;

    public schoolGroupName: string = "";

    public schoolGroupStartName: string = "";

    public schoolGroupEndName: string = "";

    public schoolGroupStartId: number = 0;

    public schoolGroupEndId: number = 0;

    // Client only properties / methods
}