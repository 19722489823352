import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { CanvasGridComponent } from '@singularsystems/neo-canvas-grid'
import LoadTestAnalyserVM, { layout } from './LoadTestAnalyserVM';
import { observer } from 'mobx-react';

@observer
export default class LoadTestAnalyserView extends Views.ViewBase<LoadTestAnalyserVM> {

    constructor(props: unknown) {
        super("Load Test Analyser", LoadTestAnalyserVM, props);

        document.getElementById("")?.querySelectorAll("")
    }

    public render() {
        return (
            <div>
			    <h2>Load Test Analyser</h2>

                <Neo.FileUploadButton variant="primary" fileManager={this.viewModel.fileManager}>Select file</Neo.FileUploadButton>
				
                <div className="mt-2">
                    {this.viewModel.rawData && <CanvasGridComponent data={{ data: this.viewModel.rawData}} layout={layout} />}
                </div>
            </div>
        );
    }
}