import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../IdentityTypes';
import TermsAndConditionLookup from '../../Models/Lookups/TermsAndConditionLookup';

export interface ITermsAndConditionsAdminApiClient {

    /** 
     * Gets the latest Terms and Conditions.
     * @returns TermsAndConditionLookup.
     */
    get(): AxiosPromise<Model.PlainObject<TermsAndConditionLookup>>;

    /** 
     * Updates the latest Terms and Conditions by adding a new record.
     * @param terms TermsAndConditionLookup.
     * @returns TermsAndConditionLookup as result.
     */
    update(terms: Model.PartialPlainObject<TermsAndConditionLookup>): AxiosPromise<Model.PlainObject<TermsAndConditionLookup>>;

    // Client only properties / methods
}

@injectable()
export default class TermsAndConditionsAdminApiClient extends Data.ApiClientBase implements ITermsAndConditionsAdminApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/TermsAndConditionsAdmin`);
    }

    public get(): AxiosPromise<Model.PlainObject<TermsAndConditionLookup>> {
        return this.axios.get(`${this.apiPath}`);
    }

    public update(terms: Model.PartialPlainObject<TermsAndConditionLookup>): AxiosPromise<Model.PlainObject<TermsAndConditionLookup>> {
        return this.axios.post(`${this.apiPath}`, terms);
    }

    // Client only properties / methods
}