import { ValidationDisplayMode } from "@singularsystems/neo-core/dist/Validation/Misc";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import TagVM from "./TagVM";
import TagLookup from '../../../Models/Lookups/Tag1Lookup';
import Tag2Lookup from '../../../Models/Lookups/Tag2Lookup';
import Tag3Lookup from '../../../Models/Lookups/Tag3Lookup';
import Card from "../../../../Template/components/Card/Card";
import CardHeader from "../../../../Template/components/Card/CardHeader";
import CardIcon from "../../../../Template/components/Card/CardIcon";
import CardBody from "../../../../Template/components/Card/CardBody";

@observer
export default class TagView extends Views.ViewBase<TagVM>{
    constructor(props: unknown) {
        super("Tags", TagVM, props);
    }
    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-tags fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Tags</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="row mb-2">
                                    <div className="col-md-10">
                                        <Neo.Button variant="success" isOutline icon="import"
                                            onClick={() => this.viewModel.showEditModal(0)} >Add Category Tag</Neo.Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <Neo.Input className=" cerebri-regular font-12" placeholder="Search for a Tag" bind={this.viewModel.criteria.meta.keyword} />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="row mt-2">
                                            <div className="col-md-12">
                                                <Neo.Pager
                                                    pageManager={this.viewModel.tagLookupPageManager}
                                                    pageControlProps={{
                                                        firstText: "",
                                                        prevText: "Prev",
                                                        nextText: "Next",
                                                        lastText: "",
                                                        alignment: "center",
                                                        // show the page controls even if the amount of items is less than a single page size
                                                        autoHide: false,
                                                        pageSizeLabel: "Show"
                                                    }}>
                                                    {data => (
                                                        <NeoGrid.Grid<TagLookup> className="table-hover" keyProperty="tag1Id"  >
                                                            {(tag: TagLookup, tagMeta) => (
                                                                <NeoGrid.RowGroup expandProperty={tagMeta.isExpanded}>
                                                                    <NeoGrid.Row key={tag.tag1Id}>
                                                                        <NeoGrid.Column label="Category(T1)" bind={tagMeta.tagName} />
                                                                        <NeoGrid.Column label="Tag Description" bind={tagMeta.tagDescription} />
                                                                        <NeoGrid.ButtonColumn key="button">
                                                                            <Neo.Button icon="edit" isOutline variant="success" onClick={() => this.viewModel.showEditModal(tag.tag1Id)}>
                                                                                Edit
                                                                            </Neo.Button>
                                                                        </NeoGrid.ButtonColumn>
                                                                        <NeoGrid.ButtonColumn key="EditButoon">
                                                                            <Neo.Button icon="trash" variant="danger" onClick={() => this.viewModel.showDeleteModal(tag.tag1Id)} />
                                                                        </NeoGrid.ButtonColumn>
                                                                    </NeoGrid.Row>
                                                                    <NeoGrid.ChildRow>
                                                                        <NeoGrid.Grid items={tag.tags2} keyProperty="tag2Id">
                                                                            {(tag2: Tag2Lookup, tag2Meta) => (
                                                                                <NeoGrid.RowGroup expandProperty={tag2Meta.isExpanded} >
                                                                                    <NeoGrid.Row key={tag2.tag2Id}>
                                                                                        <NeoGrid.Column label="Sub-Category(T2)" bind={tag2Meta.tagName} />
                                                                                        <NeoGrid.Column label="Tag Description" bind={tag2Meta.tagDescription} />
                                                                                        <NeoGrid.ButtonColumn />
                                                                                    </NeoGrid.Row>
                                                                                    <NeoGrid.ChildRow>
                                                                                        <NeoGrid.Grid items={tag2.tags3} keyProperty="tag3Id">
                                                                                            {(tag3: Tag3Lookup, tag3Meta) => (
                                                                                                <NeoGrid.Row key={tag.tag3Id}>
                                                                                                    <NeoGrid.Column label="Topic(T3)" bind={tag3Meta.tagName} />
                                                                                                    <NeoGrid.Column label="Tag Description" bind={tag3Meta.tagDescription} />
                                                                                                    <NeoGrid.ButtonColumn />
                                                                                                </NeoGrid.Row>
                                                                                            )
                                                                                            }
                                                                                        </NeoGrid.Grid>
                                                                                    </NeoGrid.ChildRow>
                                                                                </NeoGrid.RowGroup>
                                                                            )}
                                                                        </NeoGrid.Grid>
                                                                    </NeoGrid.ChildRow>
                                                                </NeoGrid.RowGroup>
                                                            )}
                                                        </NeoGrid.Grid>
                                                    )}
                                                </Neo.Pager>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </section>
                <Neo.Modal title={this.viewModel.selectedTag1Id !== 0 ? "Edit Category Tag(T1)" : "Create Tag"} size="xl"
                    show={this.viewModel.showEditModalInd}
                    onClose={() => this.viewModel.showEditModalInd = false}
                    acceptButton={{
                        text: "Save", variant: "success", icon: "check",
                        onClick: () => this.viewModel.saveTag(),
                        disabled: this.viewModel.selectedTag1.isValid === false,
                    }} closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times"
                    }} >
                    <Neo.Loader task={this.viewModel.taskRunner}>
                        <Neo.Form model={this.viewModel.selectedTag1} validationDisplayMode={ValidationDisplayMode.Always}
                            showSummaryModal>
                            {(item, meta) => (
                                <div>
                                    <Neo.GridLayout md={2} lg={2}>
                                        <Neo.FormGroup bind={meta.tagName} autoFocus />
                                        <Neo.FormGroup bind={meta.tagDescription} id="tagDescription" autoFocus />
                                    </Neo.GridLayout>
                                </div>
                            )}
                        </Neo.Form>

                        <div>
                            <Card>
                                <CardHeader icon>
                                    <CardIcon color="success">
                                        <i className={`fas fa-tags fa-2x`} />
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">Sub Categories</h4>
                                </CardHeader>
                                <CardBody>
                                    <NeoGrid.Grid items={this.viewModel.selectedTag1.tags2} showAddButton addButton={{ text: "Add Sub-Category Tag", variant: "success" }}>
                                        {(tag2, tag2Meta) => (
                                            <NeoGrid.RowGroup expandProperty={tag2Meta.isExpanded}>
                                                <NeoGrid.Row>
                                                    <NeoGrid.Column label="Sub-Category(T2)" bind={tag2Meta.tagName} />
                                                    <NeoGrid.Column label="Tag Description" bind={tag2Meta.tagDescription} />
                                                    <NeoGrid.ButtonColumn showDelete />
                                                </NeoGrid.Row>
                                                <NeoGrid.ChildRow>
                                                    <NeoGrid.Grid items={tag2.tags3} showAddButton addButton={{ text: "Add Topic Tag", variant: "success" }}>
                                                        {(tag3, tag3Meta) => (
                                                            <NeoGrid.Row>
                                                                <NeoGrid.Column label="Topic(T3)" bind={tag3Meta.tagName} />
                                                                <NeoGrid.Column label="Tag Description" bind={tag3Meta.tagDescription} />
                                                                <NeoGrid.ButtonColumn showDelete />
                                                            </NeoGrid.Row>
                                                        )
                                                        }
                                                    </NeoGrid.Grid>
                                                </NeoGrid.ChildRow>
                                            </NeoGrid.RowGroup>
                                        )}
                                    </NeoGrid.Grid>
                                    {this.viewModel.tagList.length === 0 &&
                                        <div className="col-md-12 p-0 text-center">
                                            <p>No Records</p>
                                        </div>}
                                </CardBody>
                            </Card>
                        </div>
                    </Neo.Loader>
                    <div>
                    </div>
                </Neo.Modal>
                <Neo.Modal title="Delete Tag" size="sm"
                    show={this.viewModel.showConfirmDeleteModalInd}
                    onClose={() => this.viewModel.showConfirmDeleteModalInd = false}
                    acceptButton={{
                        text: "Delete", variant: "danger", icon: "trash",
                        onClick: () => this.viewModel.deleteTag()
                    }} closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times"
                    }} >
                    <Neo.Loader task={this.viewModel.taskRunner}>
                        <div>
                            <p>Please Confirm Delete</p>
                        </div>
                    </Neo.Loader>
                </Neo.Modal>
            </div>
        )
    }
}