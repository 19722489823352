import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class DistrictLookup extends LookupBase {

    public districtId: number = 0;

    public provinceId: number = 0;

    public districtName: string = "";

    public districtDetails: string = "";

    // Client only properties / methods

    public isSelected: boolean = false;
}