import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../ForumTypes';
import PostLookup from '../../../Models/Lookups/PostLookup';
import PostSearchCriteria from '../../../Models/Lookups/PostSearchCriteria';

export interface IPostQueryApiClient {

    /**
     * Get Post by postId
     * @param postId postId
     * @returns PostLookup
     */
    getPostById(postId: number): AxiosPromise<Model.PlainObject<PostLookup>>;

    /**
     * Get not deleted Posts by Topic
     * @param topicId topicId
     * @returns List of PostLookup
     */
    getPostsByTopicAsync(topicId: number): AxiosPromise<Array<Model.PlainObject<PostLookup>>>;

    /**
     * Get Posts by Posters Guid
     * @param postedBy The Guid of the person in any posts in the topic.
     * @returns List of PostLookup where the person has posted in
     */
    getPostsByPosterAsync(postedBy: string): AxiosPromise<Array<Model.PlainObject<PostLookup>>>;

    /**
     * Gets all Posts by Topic including deleted posts.
     * @param topicId topicId
     * @returns List of PostLookup
     */
    getAllPostsByTopicAsync(topicId: number): AxiosPromise<Array<Model.PartialPlainNonTrackedObject<PostLookup>>>;

    /**
     * Searches for all posts containing a search string.
     * @param searchString Search string.
     * @returns List of PostLookup where the string is in the contents of the post.
     */
    searchPostBodyAsync(searchString: string): AxiosPromise<Array<Model.PlainObject<PostLookup>>>;

    /**
     * Get non-deleted Posts by the specified criteria.
     * @param request request
     * @returns List of PostLookup
     */
    getPagedPostsByCriteriaAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<PostSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<PostLookup>>>;

    /**
     * Get Posts by Thread (by chaining up the ParentID of the supplied PostId)
     * @param postId PostId to chain up.
     * @returns List of PostLookup as part of the thread
     */
    getPostsByThread(postId: number): AxiosPromise<Array<Model.PlainObject<PostLookup>>>;

    /**
     * Get post replies
     * @param postId postId
     * @returns list of replies
     */
    getPostReplies(postId: number, topicId: number): AxiosPromise<Array<Model.PlainObject<PostLookup>>>;

    /** 
     * Get reported Posts
     * @param request request
     * @returns List of reported posts
     */
    getReportedPosts(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<PostSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<PostLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class PostQueryApiClient extends Data.ApiClientBase implements IPostQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.ForumApi.ApiPath}/PostQuery`);
    }

    public getPostById(postId: number): AxiosPromise<Model.PlainObject<PostLookup>> {
        return this.axios.get(`${this.apiPath}/GetPostById/${postId}`);
    }

    public getPostsByTopicAsync(topicId: number): AxiosPromise<Array<Model.PlainObject<PostLookup>>> {
        return this.axios.get(`${this.apiPath}/GetPostsByTopicAsync/${topicId}`);
    }

    public getPostsByPosterAsync(postedBy: string): AxiosPromise<Array<Model.PlainObject<PostLookup>>> {
        return this.axios.get(`${this.apiPath}/GetPostsByPosterAsync/${encodeURIComponent(postedBy)}`);
    }

    public getAllPostsByTopicAsync(topicId: number): AxiosPromise<Array<Model.PartialPlainNonTrackedObject<PostLookup>>> {
        return this.axios.get(`${this.apiPath}/GetAllPostsByTopicAsync/${topicId}`);
    }

    public searchPostBodyAsync(searchString: string): AxiosPromise<Array<Model.PlainObject<PostLookup>>> {
        return this.axios.get(`${this.apiPath}/SearchPostBodyAsync/${encodeURIComponent(searchString)}`);
    }

    public getPagedPostsByCriteriaAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<PostSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<PostLookup>>> {
        return this.axios.get(`${this.apiPath}/GetPagedPostsByCriteriaAsync?${Utils.getQueryString(request)}`);
    }

    public getPostsByThread(postId: number): AxiosPromise<Array<Model.PlainObject<PostLookup>>> {
        return this.axios.get(`${this.apiPath}/GetPostsByThread/${postId}`);
    }

    public getPostReplies(postId: number, topicId: number): AxiosPromise<Array<Model.PlainObject<PostLookup>>> {
        return this.axios.get(`${this.apiPath}/PostReplies/${postId}/${topicId}`);
    }

    public getReportedPosts(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<PostSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<PostLookup>>> {
        return this.axios.get(`${this.apiPath}/GetReportedPosts?${Utils.getQueryString(request)}`);
    }

    // Client only properties / methods
}