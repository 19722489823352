import { AppServices } from '@singularsystems/neo-core';
import DashboardQueryApiClient from './ApiClients/Queries/DashboardQueryApiClient';

export const DashboardExportedTypes = {
  ApiClients: {
    DashboardQueryApiClient: new AppServices.ServiceIdentifier<DashboardQueryApiClient>("Dashboard.ApiClient.DashboardQueryApiClient"),
    //Add Api Clients here
  },
  Services: {
    // Add Services here
  },
};
