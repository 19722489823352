import { Types } from '../../../Identity/IdentityTypes';
import { AppService } from '../../../Identity/CommonIdentityTypes';

export enum LearningRoles {
   CreateTags = "Learning.Tags.Create",
   EditTags = "Learning.Tags.Edit",
   ContentCreator = "Learning.Learning.Content Creator",
   ChangeModeratedModules = "Learning.Learning.Change Moderated Modules"
}

export const IsContentCreator = async () => {

   if (((await AppService.get(Types.Authorisation.ApiClients.AuthorisationApiClient).users.getCurrentUserRoles()).data[0]) == null) {
      return false
   }
   else {
      var roles = (await AppService.get(Types.Authorisation.ApiClients.AuthorisationApiClient).users.getCurrentUserRoles()).data[0].roles;
      var hasRole = !!roles.filter(c => c.roleKey === LearningRoles.ContentCreator).length ? true : false;
      return hasRole
   }
}


export const IsChangeModeratedModules = async () => {

   if (((await AppService.get(Types.Authorisation.ApiClients.AuthorisationApiClient).users.getCurrentUserRoles()).data[0]) == null) {
      return false
   }
   else {
      var roles = (await AppService.get(Types.Authorisation.ApiClients.AuthorisationApiClient).users.getCurrentUserRoles()).data[0].roles;
      var hasRole = !!roles.filter(c => c.roleKey === LearningRoles.ChangeModeratedModules).length ? true : false;
      return hasRole
   }
}