import { Attributes, List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import Tag1 from './Tag1';
import Tag3 from './Tag3';

@NeoModel
export default class Tag2 extends ModelBase {

    public tag2Id: number = 0;

    @Rules.StringLength(100)
    @Attributes.Display("Sub-Category(T2)")
    public tagName: string = "";

    @Attributes.Display("Tag1Id")
    public tag1Id: number | null = 0;

    public tag1: Tag1 | null = null;

    public tags3 = new List(Tag3);

    public tagDescription: string = "";
    public isExpanded: boolean = false;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        rules.warnWhen(c => c.tagName.length >= 100, "SubCategory(T2) should be less than or equal to 100.");
    }
}