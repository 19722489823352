import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class UserWithGuidLookup extends LookupBase {

    public userId: number = 0;

    public preferredName: string = "";

    public lastName: string = "";

    public userName: string = "";

    public userGuid: string = "00000000-0000-0000-0000-000000000000";

    // Client only properties / methods
}