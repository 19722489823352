import { List, NeoModel } from '@singularsystems/neo-core';
import QuestionLookupBase from '../Lookups/QuestionLookupBase';
import OptionLookup from '../Lookups/OptionLookup';

@NeoModel
export default class QuizQuestionLookup extends QuestionLookupBase {
  constructor() {
    super();
    this.learningObjectId = 0;
  }
  
  public questionId: number = 0

  public learningObjectId: number;

  public options = new List(OptionLookup);

  public questionText: string = "";

  public questionTextHtml: string = "";

  public informationLearningObjectId: number | null = null;

  public plainText: string = '';
}