import { Attributes, List, Misc, ModelBase, NeoModel, Rules, Utils, Validation } from '@singularsystems/neo-core';
import { LearningPathwayType } from './LearningPathwayType.enum';
import LearningPathwayParticipant from './LearningPathwayParticipant';
import LearningPathwayModule from './LearningPathwayModule';
import { UserLearningStatus } from '../UserLearning/UsersLearningStatus.enum';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';

@NeoModel
export default class LearningPathway extends ModelBase {

    public learningPathwayId: number = 0;

    @Required()
    public title: string = "";

    public ownerGuid: string = Utils.emptyGuid();

    public parentId: number | null = null;

    public conversationId: number | null = null;

    public learningPathwayType: LearningPathwayType | null = null;

    public participants = new List(LearningPathwayParticipant);

    public learningPathwayModules = new List(LearningPathwayModule);

    public userLearningStatus: UserLearningStatus = UserLearningStatus.Awaiting;

    @Attributes.Date()
    public completedDate: Date | null = null;

    public awaitingApproval: boolean = false;

    @Attributes.NoTracking(Misc.SerialiseType.FullOnly)
    public hasInvites: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.title) {
            return "New learning pathway";
        } else {
            return this.title;
        }
    }
}