import { Neo } from '@singularsystems/neo-react';
import React from "react";
import { observer } from "mobx-react";
import { VerificationModalVM } from "./VerificationModalVM";
import UserProfileVM from '../Views/UserProfileVM';
import BadgeAwardModal from '../../Learning/Components/BadgeAwardModal';


interface IVerificationModal {
    viewModel: VerificationModalVM;
    userProfileVM: UserProfileVM;
}

@observer
export default class VerificationModal extends React.Component<IVerificationModal> {

    public async getCurrentUser() {
        var verifyResponse = await this.props.viewModel.VerifyUser();
        if (verifyResponse) {
            var response = await this.props.viewModel.GetCurrentUser();
            this.props.userProfileVM.currentUser.update(response);
            if (this.props.viewModel.badgeAwardedList.length > 0) {
                this.props.userProfileVM.showConfetti = true;
            }
        }
    }

    public nextBadge = async () => {
        const response = await this.props.viewModel.showNextBadge();
        if (response === 0 && this.props.viewModel.alldone) {
            this.props.viewModel.showBadgeAward = false;
            this.props.userProfileVM.showConfetti = false;
        }
    }

    public render() {
        const viewModel = this.props.viewModel;

        return (
            <div>
                <Neo.Modal size="lg"
                    title={"User Verification"}
                    show={viewModel.verificationModal}
                    acceptButton={{
                        text: "Send OTP", variant: "success", icon: "check",
                        onClick: () => viewModel.SendOTP()
                    }}
                    onClose={() => { viewModel.verificationModal = false; }}
                    closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times"
                    }}>
                    <div className="Container" >
                        <p>Please select an option to send your one time password to:</p>
                        <div className="row">
                            <div className="col-lg-6">
                                <Neo.RadioList bind={viewModel.meta.isEmail}
                                    radioList={{ items: viewModel.radioListItems }} />
                            </div>
                        </div>
                    </div>
                </Neo.Modal>
                <Neo.Modal size="lg"
                    title={"User Verification"}
                    show={viewModel.verificationPasswordModal}
                    acceptButton={{
                        text: "Resend OTP", variant: "primary", icon: "refresh",
                        onClick: () => { viewModel.verificationPasswordModal = false; viewModel.verificationModal = true; }
                    }}
                    onClose={() => { viewModel.verificationPasswordModal = false; this.props.userProfileVM.hasActiveOTP = true }}
                    closeButton={{
                        text: "Cancel", variant: "secondary", icon: "times"
                    }}>
                    <div className="Container" onKeyPress={(event) => {
                        if (event.charCode === 13) {
                            this.getCurrentUser();
                            event.preventDefault();
                        }
                    }}>
                        <p>A One Time Password has been sent to your chosen device.</p>
                        <p>Please enter the One Time Password below:</p>
                        <div className="row">
                            <div className="col-lg-6">
                                <Neo.Textbox bind={viewModel.meta.oneTimePassword} maxLength={6} />
                            </div>

                            <Neo.Button text={"Submit OTP"} variant={"success"} isOutline icon={"check"}
                                onClick={() => this.getCurrentUser()} />
                        </div>
                    </div>

                </Neo.Modal>

                {!viewModel.showBadgeAward ||
                    <BadgeAwardModal badgeAwardMessage={viewModel.badgeAwarded.badgeAwardMessage} badgeAwardDescription={viewModel.badgeAwarded.badgeDescription}
                        badgeTitle={viewModel.badgeAwarded.badgeTitle} imageSrc={viewModel.badgeAwarded.blobUrl} onClose={() => { this.nextBadge() }} showBadgeAward={viewModel.showBadgeAward} />
                }
            </div>
        )
    }
}