import { Data, EnumHelper, NeoModel } from "@singularsystems/neo-core";
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { NotificationDuration } from "../../../App/Models/Enums/NotificationDuration.enum";
import { AppService, Types } from "../../../App/Services/AppService";
import Card from "../../../Template/components/Card/Card";
import CardBody from "../../../Template/components/Card/CardBody";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardIcon from "../../../Template/components/Card/CardIcon";
import { TopicTypes } from "../../Models/Enums/TopicTypes.enum";
import TopicLookup from "../../Models/Lookups/TopicLookup";
import TopicSearchCriteria from "../../Models/Lookups/TopicSearchCriteria";
import ConversationComponent from './ConversationComponent';
import { TopicComponentTypes } from '../../Models/Enums/TopicComponentType.enum';
import { ForumIntegrationTypes } from "../../Models/Enums/ForumLearningTypes.enum";
import UserProfileLookup from "../../../Identity/Models/Users/Lookups/UserProfileLookup";
import TDPUser from "../../../Identity/Models/Security/TDPUser";

interface ITopicComponentProps {
  forumId?: number;
  title: string;
  isMaximised?: IPropertyInstance;
  topicType: ("pathway" | "module" | 'activity' | 'all');
  topicId?: number
}

@NeoModel
export class TopicComponentVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),

    public topicUpdateApiClient = AppService.get(Types.Forum.ApiClients.TopicUpdateApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private dashboardApiClient = AppService.get(Types.Dashboard.ApiClients.DashboardQueryApiClient),
    private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
    public topicQueryApiClient = AppService.get(Types.Forum.ApiClients.TopicQueryApiClient),
    public forumQueryApiClient = AppService.get(Types.Forum.ApiClients.ForumQueryApiClient)) {
    super(taskRunner);
    this.user = this.authenticationService.user;
    this.currentUser = new UserProfileLookup();
  }
  public searchCriteria = new TopicSearchCriteria();
  public hideTopicConvo = false;
  public currentUser: UserProfileLookup = new UserProfileLookup();;
  public user: TDPUser | null = null;
  public integrationType = 0;

  public async initialise() {
    await this.getCurrentUser();
    this.moduleName = "";
  }

  public topicPageManager = new Data.PageManager(this.searchCriteria, TopicLookup, this.topicQueryApiClient.getPagedTopicsByCriteriaAsync, {
    pageSize: 5,
    pageSizeOptions: [5, 10, 12, 15],
    sortBy: "topicName",
    sortAscending: true,
    fetchInitial: true,
    initialTaskRunner: this.taskRunner,
  });

  public UserTopicPageManager = new Data.PageManager(this.searchCriteria, TopicLookup, this.dashboardApiClient.getPagedUserActiveTopics, {
    pageSize: 5,
    pageSizeOptions: [5, 10, 12, 15],
    sortBy: "lastPostDate",
    sortAscending: false,
    fetchInitial: true,
    initialTaskRunner: this.taskRunner,
  });

  public topicId = 0;
  public topicName = "";
  public moduleName: string = "";

  public async showTopicConversation(id: number, name: string) {
    this.topicId = id;
    this.topicName = name;
    this.hideTopicConvo = true;
    await this.taskRunner.run(async () => {
      var res = await this.forumQueryApiClient.getForumByTopicAsync(this.topicId);
      if (res.status === 200) {
        this.moduleName = res.data.name;
        var res2 = await this.topicQueryApiClient.getTopicById(this.topicId);
        if (res2.status === 200) {
          this.integrationType = res2.data.forumIntegrationTypes as number;
        }

      }
    });
  }

  public saveTopic = async (topicName: string) => {
    var topic = new TopicLookup();
    topic.topicName = topicName;
    topic.topicType = TopicTypes.Topic;
    topic.createdBy = this.currentUser.userGuid;
    topic.forumIntegrationTypes = this.integrationType;
    if (this.topicId !== 0) {
      await this.taskRunner.run(async () => {
        var res = await this.forumQueryApiClient.getForumByTopicAsync(this.topicId);
        if (res.status === 200) {
          topic.forumName = res.data.name;
          var res2 = await this.topicQueryApiClient.getTopicById(this.topicId);
          if (res2.status === 200) {
            this.integrationType = res2.data.forumIntegrationTypes as number;
          }
        }
      });
    }
    topic.forumId = this.searchCriteria.forumId !== 0 ? this.searchCriteria.forumId as number : 0;
    const response = await this.createTopic(topic);
    this.topicPageManager.refreshData();
    return response;
  }

  private async createTopic(topic: TopicLookup) {
    if (topic != null) {
      const response = await this.topicUpdateApiClient.createTopicAsync(topic.toJSObject());
      this.notifications.addSuccess("Topic Saved", ``, NotificationDuration.Standard);
      return response.data;
    }
  }

  public async getCurrentUser() {
    if (this.user !== null) {
      let response = await this.userQueryApiClient.getRegisteredUser(this.user?.guid);
      let userData = response.data;
      this.currentUser.set(userData);
    }
  }
}

@observer
export default class TopicComponent extends React.Component<ITopicComponentProps>{
  private viewModel = new TopicComponentVM();
  constructor(props: ITopicComponentProps) {
    super(props);
    this.viewModel = new TopicComponentVM();
    this.viewModel.searchCriteria.forumId = props.forumId as number;

    if (props.topicType === TopicComponentTypes.Activity) {
      this.viewModel.searchCriteria.topicId = props.topicId as number;
      this.viewModel.integrationType = ForumIntegrationTypes.LearningModuleActivity;
    }

    if (props.topicType === TopicComponentTypes.All) {
      this.viewModel.UserTopicPageManager.refreshData();
      this.viewModel.integrationType = ForumIntegrationTypes.Unspecified;
    }

    if (props.topicType === TopicComponentTypes.Module) {
      this.viewModel.integrationType = ForumIntegrationTypes.LearningModuleGlobal;
    }

    if (props.topicType === TopicComponentTypes.Pathway) {
      this.viewModel.integrationType = ForumIntegrationTypes.LearningPathway;
    }

    this.viewModel.topicPageManager.refreshData();
  }

  private showTopicConversation = (id: number, name: string) => {
    this.viewModel.showTopicConversation(id, name);
    this.props.isMaximised!.value = true;
  }

  public SaveTopic = async (topicName: string) => {
    const result = await this.viewModel.saveTopic(topicName);
    this.showTopicConversation(result.topicId, result.topicName);
  }

  public render() {
    return (
      <div>
        <Card hidden={this.viewModel.hideTopicConvo || this.props.topicType === TopicComponentTypes.All}>
          <CardHeader icon>
            <CardIcon color="success">
              <i className={`icon fa fa-road fa-2x`}></i>
            </CardIcon>
            <h4 className="Card-icon-header-text">{this.props.title.length !== 0 ? this.props.title : "Pathway Topics"}</h4>
          </CardHeader>
          <CardBody >
            <Neo.Pager
              pageManager={this.viewModel.topicPageManager}
              pageControlProps={{
                firstText: "",
                prevText: "Prev",
                nextText: "Next",
                lastText: "",
                alignment: "center",
                autoHide: false,
                pageSizeLabel: "Show"
              }}>
              {topic => (
                <NeoGrid.Grid<TopicLookup> keyProperty="topicId" className="table-hover" showAddButton>
                  {(topic: TopicLookup, topicMeta) => (
                    <NeoGrid.Row key={topic.topicId}>
                      <NeoGrid.Column bind={topicMeta.topicName} isReadOnly={!topic.isNew} />
                      <NeoGrid.Column label="Module Name" bind={topicMeta.moduleName} isReadOnly={true} />
                      <NeoGrid.Column label="Pathway Name" bind={topicMeta.pathwayName} isReadOnly={true} />
                      <NeoGrid.Column label="Scope" bind={topicMeta.forumIntegrationTypes} select={{ items: EnumHelper.asList(ForumIntegrationTypes), renderAsText: true }} isReadOnly={true} />
                      <NeoGrid.Column label="Last Post" display={topicMeta.timeAgo} />
                      <NeoGrid.ButtonColumn label="Actions" showDelete deleteButton={{ disabled: topic.isNew ? false : true }}>
                        <Neo.Button hidden={topic.isNew ? true : false} variant="info" icon="eye" tooltip={{ content: "View Topic", className: "tool-tip" }} onClick={() => { this.showTopicConversation(topic.topicId, topic.topicName) }} />
                        <Neo.Button icon="save" hidden={topic.isNew ? false : true} onClick={() => this.SaveTopic(topic.topicName)}>Save</Neo.Button>
                      </NeoGrid.ButtonColumn>
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
              )}
            </Neo.Pager>
          </CardBody>
        </Card>

        <Card hidden={this.props.topicType !== TopicComponentTypes.All || this.viewModel.hideTopicConvo}>
          <CardHeader icon>
            <CardIcon color="success">
              <i className={`icon fa fa-road fa-2x`}></i>
            </CardIcon>
            <h4 className="Card-icon-header-text">{this.props.title.length !== 0 ? this.props.title : "Pathway Topics"}</h4>
          </CardHeader>
          <CardBody >
            <Neo.Pager
              pageManager={this.viewModel.UserTopicPageManager}
              pageControlProps={{
                firstText: "",
                prevText: "Prev",
                nextText: "Next",
                lastText: "",
                alignment: "center",
                autoHide: false,
                pageSizeLabel: "Show"
              }}>
              {topic => (
                <NeoGrid.Grid<TopicLookup> keyProperty="topicId" className="table-hover">
                  {(topic: TopicLookup, topicMeta) => (
                    <NeoGrid.Row key={topic.topicId}>
                      <NeoGrid.Column bind={topicMeta.topicName} isReadOnly={!topic.isNew} />
                      <NeoGrid.Column label="Module" bind={topicMeta.moduleName} isReadOnly={true} />
                      <NeoGrid.Column label="Activity" display={topicMeta.activityName} />
                      <NeoGrid.Column label="Pathway" display={topicMeta.pathwayName} />
                      <NeoGrid.Column label="Scope" display={topicMeta.forumIntegrationTypes} select={{ itemSource: Data.StaticDataSource.fromEnum(ForumIntegrationTypes), renderAsText: true }} />
                      <NeoGrid.Column label="Last Post" display={topicMeta.timeAgo} />
                      <NeoGrid.ButtonColumn label="Actions" >
                        <Neo.Button hidden={topic.isNew ? true : false} variant="info" icon="eye" tooltip={{ content: "View Topic", className: "tool-tip" }} onClick={() => { this.showTopicConversation(topic.topicId, topic.topicName) }} />
                      </NeoGrid.ButtonColumn>
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
              )}
            </Neo.Pager>
          </CardBody>
        </Card>
        <div>
          {!this.viewModel.hideTopicConvo || <ConversationComponent isMaximised={this.props.isMaximised} show={this.viewModel.meta.hideTopicConvo} topicId={this.viewModel.topicId} title={this.viewModel.topicName} moduleName={this.viewModel.moduleName} />}
        </div>

      </div>
    )
  }
}