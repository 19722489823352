
import classNames from 'classnames';
import cardHeaderStyles from '../../assets/tss/material-dashboard-react/components/CardStyles/cardHeaderStyles'
import React from 'react';
interface ICardHeaderProps {
  className?: string;
  color?: ("warning" | "success" | "danger" | "info" | "primary" | "rose");
  plain?: boolean,
  stats?: boolean,
  icon?: boolean,
  children?: React.ReactNode,
  image?: boolean,
  contact?: boolean,
  signup?: boolean,
  text?: boolean,
}

export default function CardHeader(props: ICardHeaderProps) {
  const classes = cardHeaderStyles();
  const {
    className,
    children,
    color,
    plain,
    image,
    contact,
    signup,
    stats,
    icon,
    text,
    ...rest
  } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderImage]: image,
    [classes.cardHeaderContact]: contact,
    [classes.cardHeaderSignup]: signup,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [classes.cardHeaderText]: text,
    [className as any]: className !== undefined,
  });

  return (
    <div className={cardHeaderClasses} {...rest} >
      {children}
    </div>
  );
}