import { List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import LearningObjectModerationLookup from './LearningObjectModerationLookup';
import LearningObjectModerateableTagLookup from './LearningObjectModerateableTagLookup';

@NeoModel
export default class LearningObjectTagModerationLookup extends ModelBase {

  public learningObject: LearningObjectModerationLookup = new LearningObjectModerationLookup();

  public tag3s = new List(LearningObjectModerateableTagLookup);

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New learning object tag moderation lookup";
    } else {
      return "Learning Object Tag Moderation Lookup";
    }
  }
}