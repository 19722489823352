import { injectable } from 'inversify';
import Axios, { AxiosPromise } from 'axios';

import Types from '../../AuthorisationTypes';
import { Misc, Model } from '@singularsystems/neo-core';
import { UserGroupRolesLookup } from '../../Models/UserRoleLookup';
import { ApiClientBase } from '@singularsystems/neo-core/dist/Data';
import UserLookupCriteria from '../../../Authorisation/Models/UserLookupCriteria';

export interface IUsersApiClient {
    /**
     * Will get a list of users
     */
    getLookupAsync(): AxiosPromise<any>;

    /**
    * Will get a list of users filtered by username, name or surname.
    */
    getLookupFiltered(filter: string): AxiosPromise<any>;

    /**
     * Will get a list of user roles
     */
    getRoleLookupAsync(userId: string): AxiosPromise<Array<Model.PlainObject<UserGroupRolesLookup>>>;

    /**
     * Gets the user's list of memberships
     */
    getCurrentUserRoles(): AxiosPromise<Array<Model.PlainObject<UserGroupRolesLookup>>>;
}

@injectable()
export default class UsersApiClient extends ApiClientBase implements IUsersApiClient {

    constructor(config = Misc.Globals.appService.get(Types.Config.ConfigModel)) {
        super(`${config.authorisationServerApiPath}/Users`);
    }

    public getLookupAsync() {
        return Axios.get(`${this.apiPath}/Lookup/`);
    }

    public getLookupFiltered(filter: string) {
        var criteria = new UserLookupCriteria()
        criteria.SearchText = filter;

        return Axios.post(`${this.apiPath}/search/`, criteria.toJSObject());
    }

    public getRoleLookupAsync(userId: string): AxiosPromise<Array<Model.PlainObject<UserGroupRolesLookup>>> {
        return Axios.get(`${this.apiPath}/Roles/${userId}`);
    }

    public getCurrentUserRoles(): AxiosPromise<Array<Model.PlainObject<UserGroupRolesLookup>>> {
        return Axios.get(`${this.apiPath}/currentUserRoles`);
    }
}
