import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../LearningTypes';
import ModuleConsumptionLookup from '../../Models/ContentCosumption/ModuleConsumptionLookup';
import InformationObjectConsumptionLookup from '../../Models/ContentCosumption/InformationObjectConsumptionLookup';
import QuizConsumptionLookup from '../../Models/ContentCosumption/QuizConsumptionLookup';
import SelfAssessmentConsumptionLookup from '../../Models/ContentCosumption/SelfAssessmentConsumptionLookup';
import UserSelfAssessment from '../../Models/UserLearning/UserSelfAssessment';
import LearningModule from '../../Models/LearningModules/LearningModule';
import UserLearningModuleLookup from '../../Models/UserLearning/UserLearningModuleLookup';
import LearningModuleObject from '../../Models/LearningModules/LearningModuleObject';
import BadgeLookup from '../../Models/Badges/BadgeLookup';
import ModuleScoreLookup from '../../Models/Lookups/ModuleScoreLookup';

export interface IContentConsumptionQueryApiClient {

    /**
     * Get a Module by Id.
     * @param moduleId moduleId
     * @returns ModuleConsumptionLookup
     */
    getModuleById(moduleId: number, pathwayId: number): AxiosPromise<Model.PlainObject<ModuleConsumptionLookup>>;

    /**
     * Get a object by Id.
     * @param objectId objectId
     * @returns ModuleConsumptionLookup
     */
    getInfoObjectById(objectId: number, learningPathwayId: number): AxiosPromise<Model.PlainObject<InformationObjectConsumptionLookup>>;

    /**
     * Set the Last learning Object on a pathway Module
     * @param learningModuleId learningModuleId
     * @param learningPathwayId learningPathwayId
     * @param learningObjectId learningObjectId
     * @returns bool
     */
    setLastLearningObjectOnModule(learningModuleId: number, learningPathwayId: number, learningObjectId: number): AxiosPromise<boolean>;

    /**
     * Get Quiz info
     * @param learningObjectId learningObjectId
     * @returns QuizConsumptionLookup
     */
    getQuizInfoById(learningObjectId: number, moduleId: number): AxiosPromise<Model.PlainObject<QuizConsumptionLookup>>;

    /**
     * Save Final Quiz Info
     * @param learningModuleId learningModuleId
     * @param learningPathwayId learningPathwayId
     * @param learningObjectId learningObjectId
     * @param mark mark
     * @returns bool
     */
    saveFinalQuiz(learningModuleId: number, learningPathwayId: number, learningObjectId: number, mark: number): AxiosPromise<Array<Model.PlainObject<BadgeLookup>>>;

    /**
     * Gets a self assessment
     * @param selfAssessmentId selfAssessmentId
     * @returns SelfAssessmentConsumptionLookup
     */
    getSelfAssessment(selfAssessmentId: number): AxiosPromise<Model.PlainObject<SelfAssessmentConsumptionLookup>>;

    /**
     * Gets a self assessment
     * @param userSelfAssessment userSelfAssessment
     * @returns UserSelfAssessment
     */
    saveUserAssesment(userSelfAssessment: Model.PartialPlainObject<UserSelfAssessment>): AxiosPromise<Model.PlainTrackedObject<UserSelfAssessment>>;

    /**
     * Get Suggested Modules
     * @param userSelfAssessment userSelfAssessment
     * @returns LearningModules
     */
    getSuggestedModules(userSelfAssessment: Model.PartialPlainObject<UserSelfAssessment>): AxiosPromise<Array<Model.PlainTrackedObject<LearningModule>>>;

    /**
     * Get a user module for consumption
     * @param moduleId moduleId
     * @returns UserLearningModuleLookup
     */
    getUserModulebyId(moduleId: number): AxiosPromise<Model.PlainObject<UserLearningModuleLookup>>;

    /**
     * save user module
     * @param userLearningModuleLookup userLearningModuleLookup
     * @returns UserLearningModuleLookup
     */
    saveUserModule(userLearningModuleLookup: Model.PartialPlainObject<UserLearningModuleLookup>): AxiosPromise<Model.PlainObject<UserLearningModuleLookup>>;

    /**
     * Get the next module by Id
     * @param moduleId moduleId
     * @param objectId pathwayId
     * @returns ModuleConsumptionLookup
     */
    getNextObjectbyId(moduleId: number, objectId: number): AxiosPromise<LearningModuleObject>;

    /**
     * Get the previous module by Id
     * @param moduleId moduleId
     * @param objectId pathwayId
     * @returns ModuleConsumptionLookup
     */
    getPreviousObjectbyId(moduleId: number, objectId: number): AxiosPromise<LearningModuleObject>;

    /**
     * Save pathway module order
     * @param pathwayId pathwayId
     * @param moduleId moduleId
     * @param order order
     * @returns bool
     */
    saveOrderChanges(pathwayId: number, moduleId: number, order: number): AxiosPromise<boolean>;

    /**
     * Get a user module for preview
     * @param moduleId moduleId
     * @returns UserLearningModuleLookup
     */
    getUserModulebyIdForPreview(moduleId: number): AxiosPromise<Model.PlainObject<UserLearningModuleLookup>>;

    /**
     * Gets the quiz score;
     * @param moduleId quiz
     * @param quiz moduleId
     * @returns Module Score Lookup
     */
    getQuizScore(quiz: Model.PartialPlainObject<QuizConsumptionLookup>): AxiosPromise<Model.PlainObject<ModuleScoreLookup>>;

    /**
     * Saves the last activity opened
     * @param learningObjectId learningObjectId
     * @param moduleId moduleIdparam>
     *
     */
    saveLastUserActivity(learningObjectId: number, moduleId: number): AxiosPromise<Model.PlainObject<UserLearningModuleLookup>>;

    /** 
     * Saves the last Pathway activity opened
     * @param learningObjectId learningObjectId
     * @param pathwayId pathwayId
     * @param moduleId moduleId
     * @returns ModuleConsumptionLookup
     */
    saveLastPathwayActivity(learningObjectId: number, pathwayId: number, moduleId: number): AxiosPromise<Model.PlainObject<ModuleConsumptionLookup>>;

    // Client only properties / methods
}

@injectable()
export default class ContentConsumptionQueryApiClient extends Data.ApiClientBase implements IContentConsumptionQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/ContentConsumptionQuery`);
    }

    public getModuleOnPathwayById(moduleId: number, pathwayId: number): AxiosPromise<Model.PlainObject<ModuleConsumptionLookup>> {
        return this.axios.get(`${this.apiPath}/GetModuleOnPathwayById/${moduleId}/${pathwayId}`);
    }
    
    public getModuleById(moduleId: number, pathwayId: number): AxiosPromise<Model.PlainObject<ModuleConsumptionLookup>> {
        return this.axios.get(`${this.apiPath}/GetModuleById/${moduleId}`);
    }

    public getInfoObjectById(objectId: number, learningPathwayId: number): AxiosPromise<Model.PlainObject<InformationObjectConsumptionLookup>> {
        return this.axios.get(`${this.apiPath}/GetInfoObjectById/${objectId}/${learningPathwayId}`);
    }

    public setLastLearningObjectOnModule(learningModuleId: number, learningPathwayId: number, learningObjectId: number): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SetLastLearningObjectOnModule/${learningModuleId}/${learningPathwayId}/${learningObjectId}`);
    }

    public getQuizInfoById(learningObjectId: number, moduleId: number): AxiosPromise<Model.PlainObject<QuizConsumptionLookup>> {
        return this.axios.get(`${this.apiPath}/GetQuizInfoById/${learningObjectId}/${moduleId}`);
    }

    public saveFinalQuiz(learningModuleId: number, learningPathwayId: number, learningObjectId: number, mark: number): AxiosPromise<Array<Model.PlainTrackedObject<BadgeLookup>>> {
        return this.axios.get(`${this.apiPath}/SaveFinalQuiz/${learningModuleId}/${learningPathwayId}/${learningObjectId}/${mark}`);
    }

    public getSelfAssessment(selfAssessmentId: number): AxiosPromise<Model.PlainObject<SelfAssessmentConsumptionLookup>> {
        return this.axios.get(`${this.apiPath}/GetSelfAssessment/${selfAssessmentId}`);
    }

    public saveUserAssesment(userSelfAssessment: Model.PartialPlainObject<UserSelfAssessment>): AxiosPromise<Model.PlainTrackedObject<UserSelfAssessment>> {
        return this.axios.put(`${this.apiPath}/SaveUserAssesment`, userSelfAssessment);
    }

    public getSuggestedModules(userSelfAssessment: Model.PartialPlainObject<UserSelfAssessment>): AxiosPromise<Array<Model.PlainTrackedObject<LearningModule>>> {
        return this.axios.put(`${this.apiPath}/GetSuggestedModules`, userSelfAssessment);
    }

    public getUserModulebyId(moduleId: number): AxiosPromise<Model.PlainObject<UserLearningModuleLookup>> {
        return this.axios.get(`${this.apiPath}/GetUserModulebyId/${moduleId}`);
    }

    public saveUserModule(userLearningModuleLookup: Model.PartialPlainObject<UserLearningModuleLookup>): AxiosPromise<Model.PlainObject<UserLearningModuleLookup>> {
        return this.axios.put(`${this.apiPath}/SaveUserModule`, userLearningModuleLookup);
    }

    public getNextObjectbyId(moduleId: number, objectId: number): AxiosPromise<LearningModuleObject> {
        return this.axios.get(`${this.apiPath}/getNextObjectbyId/${moduleId}/${objectId}`);
    }

    public getPreviousObjectbyId(moduleId: number, objectId: number): AxiosPromise<LearningModuleObject> {
        return this.axios.get(`${this.apiPath}/getPreviousObjectbyId/${moduleId}/${objectId}`);
    }

    public saveOrderChanges(pathwayId: number, moduleId: number, order: number): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SaveOrderChanges/${pathwayId}/${moduleId}/${order}`);
    }

    public getUserModulebyIdForPreview(moduleId: number): AxiosPromise<Model.PlainObject<UserLearningModuleLookup>> {
        return this.axios.get(`${this.apiPath}/PreviousObjectbyId/${moduleId}`);
    }

    public getQuizScore(quiz: Model.PartialPlainObject<QuizConsumptionLookup>): AxiosPromise<Model.PlainObject<ModuleScoreLookup>> {
        return this.axios.put(`${this.apiPath}/QuizScore`, quiz);
    }

    public saveLastUserActivity(learningObjectId: number, moduleId: number): AxiosPromise<Model.PlainObject<UserLearningModuleLookup>> {
        return this.axios.put(`${this.apiPath}/SaveLastUserActivity/${learningObjectId}/${moduleId}`);
    }

    public saveLastPathwayActivity(learningObjectId: number, pathwayId: number, moduleId: number): AxiosPromise<Model.PlainObject<ModuleConsumptionLookup>> {
        return this.axios.put(`${this.apiPath}/SaveLastPathwayActivity/${learningObjectId}/${pathwayId}/${moduleId}`);
    }
    // Client only properties / methods
}