import { ModelBase } from '@singularsystems/neo-core';

export default class ImportResultBase extends ModelBase {

    public warnings: string[] = [];

    public fileErrors: string[] = [];

    public rowErrors: string[] = [];

}