import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import AppLayout, { ScreenSize } from '../Services/AppLayout';
import BreadCrumb from './BreadCrumb';
import UserStatus from './UserStatus';
import { observer } from 'mobx-react';

@observer
export default class HeaderPanel extends React.Component {


    constructor(props: any) {
        super(props);

        this.pinHeaderClick = this.pinHeaderClick.bind(this);
    }

    private pinHeaderClick() {
        AppLayout.current.pinHeader = !AppLayout.current.pinHeader
    }

    public render() {

        const layout = AppLayout.current;
        const globalTask = Views.ViewBase.currentView ? Views.ViewBase.currentView.taskRunner : undefined;

        let headerClassName = "app-header-panel";
        if (layout.pinHeader && layout.currentScreenSize === ScreenSize.Huge) {
            headerClassName += " pinned";
        }
        if (!layout.isScrollTop) {
            headerClassName += " scrolled";
        }

        return (
            <div className={headerClassName}>

                {globalTask && globalTask.isBusy &&
                    <Neo.ProgressBar className="page-progress" progressState={globalTask.progressState} variant={globalTask.options.variant} type="animated" />}

                <div className="app-header">

                    <div id="menu-anchor" className="app-hamburger-container" onClick={layout.showMenu.bind(layout)}>
                        <div className="app-hamburger">
                            <i className="fa fa-bars" />
                        </div>
                    </div>
                    <div className="app-breadcrumb">
                        <BreadCrumb rootItem={{ label: "TDP", link: "/" }} /> {" "}
                    </div>
                    <UserStatus />

                    {/* {layout.currentScreenSize === ScreenSize.Huge &&
                        <div className="app-header-thumb text-primary" onClick={this.pinHeaderClick} title="Pin the header to the top">
                            <i className={"fa fa-thumbtack" + (layout.pinHeader ? " fa-rotate-270" : "")} />
                        </div>} */}

                </div>
            </div>
        )
    }
}