import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class LearningPathwaySearchCriteria extends ValueObject {
  public Title: string = "";

  public keyword: string = "";

  public InProgress: boolean = true;

  public OwnerGuid: string = "";

  public ids: number[] = [];
}