import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import Tag2 from '../Tags/Tag2';

@NeoModel
export default class UserModerationTag2Lookup extends ModelBase {

    public userModerationTag2Id: number = 0;

    public userGuid: string = "00000000-0000-0000-0000-000000000000";

    public tag2Id: number = 0;

    public tag1Name: string = "";

    public tag2Name: string = "";

    public filteredTag2List = new List(Tag2);

    // Client only properties / methods    

    public tag1Id: number = 0;
}