import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../LearningTypes';
import LearningModuleSearchCriteria from '../../Models/Criteria/LearningModuleSearchCriteria';
import LearningModuleLookup from '../../Models/Lookups/LearningModuleLookup';
import LearningObjectModerateableTagLookup from '../../Models/Lookups/LearningObjectModerateableTagLookup';
import LearningObjectModerationLookup from '../../Models/Lookups/LearningObjectModerationLookup';
import LearningModuleWithTagsLookup from '../../Models/Lookups/LearningModuleWithTagsLookup';
import LearningObjectWithTagModerationLookup from '../../Models/Lookups/LearningObjectWithTagModerationLookup';
import LearningObjectContentLookup from '../../Models/Lookups/LearningObjectContentLookup';
import ModeratableQuizQuestionLookup from '../../Models/Lookups/ModeratableQuizQuestionLookup';
import SelfAssessmentModerationProgressLookup from '../../Models/Lookups/SelfAssessmentModerationProgressLookup';
import SelfAssessmentQuestionModerationProgressLookup from '../../Models/Lookups/SelfAssessmentQuestionModerationProgressLookup';
import UserModerationTag2Lookup from '../../Models/Lookups/UserModerationTag2Lookup';
import Tag1SearchCriteria from '../../Models/Criteria/Tag1SearchCriteria';

export interface IModerationQueryApiClient {

    /**
     * This method will return the paged learning modules
     * @param request the paged search criteria request
     * @returns A collection of learning modules
     */
    getPagedLearningModuleLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>>;

    /**
     * Accept a module For moderation
     * @param learningModuleId learningModuleId
     * @param userGuid userGuid
     * @returns Boolean
     */
    acceptModuleForModeration(learningModuleId: number, userGuid: string): AxiosPromise<boolean>;

    /**
     * Save the moderation changes on a Module
     * @param module module
     * @param userGuid userGuid
     * @returns save Result
     */
    saveModuleModeration(module: Model.PartialPlainObject<LearningModuleWithTagsLookup>, userGuid: string): AxiosPromise<boolean>;

    /**
     * Save Learning Object Moderation
     * @param learningObject learningObject
     * @param tag3s tag3s
     * @param userGuid User Guid
     * @returns bool
     */
    saveLearningObjectModeration(learningObject: Model.PartialPlainObject<LearningObjectWithTagModerationLookup>, userGuid: string): AxiosPromise<boolean>;

    /**
     * Get Quiz Question Info
     * @param questionId questionId
     * @returns bool
     */
    getQuizQuestion(questionId: number, learningObjectId: number): AxiosPromise<Model.PlainTrackedObject<ModeratableQuizQuestionLookup>>;

    /**
     * Get all LO for specific LM
     * @param learningModuleId The page request
     * @returns LOs for specific LM
     */
    getLearningObjectLookup(learningModuleId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectModerationLookup>>>;

    /**
     * Get all LO for specific LM
     * @param learningObjectId The page request
     * @returns LOs for specific LM
     */
    getLearningObjectTag3s(learningObjectId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectModerateableTagLookup>>>;

    /** 
     * Save Learning Content Moderation
     * @param learningContent learningContent
     * @param userGuid User Guid
     * @returns bool
     */
    saveLearningContentModeration(learningContent: Model.PartialPlainObject<LearningObjectContentLookup>, userGuid: string): AxiosPromise<boolean>;

    /**
     * Save Question Moderation
     * @param question question
     * @param userGuid userGuid
     * @returns bool
     */
    saveQuestionModeration(question: Model.PartialPlainObject<ModeratableQuizQuestionLookup>, userGuid: string): AxiosPromise<boolean>;

    /**
     * Send a module back for review
     * @param moduleId moduleId
     * @returns bool
     */
    sendModuleForReview(moduleId: number, userGuid: string): AxiosPromise<boolean>;

    /**
     * Send a module back for review
     * @param moduleId moduleId
     * @param userGuid userGuid
     * @returns bool
     */
    completeModuleModeration(moduleId: number, userGuid: string): AxiosPromise<boolean>;

    /**
     * Save a Self Assessment Moderation progress
     * @param saveSelfAssessmentProgress SelfAssessmentModerationProgressLookup
     * @param userGuid userGuid
     * @returns Boolean
     */
    saveAssessmentModerationState(saveSelfAssessmentProgress: Model.PartialPlainObject<SelfAssessmentModerationProgressLookup>, userGuid: string): AxiosPromise<boolean>;

    /**
     * Save a Self Assessment Question Moderation progress
     * @param selfAssessmentQuestion learningModuleId
     * @param userGuid userGuid
     * @returns Boolean
     */
    saveAssessmentQuestionModerationState(selfAssessmentQuestion: Model.PartialPlainObject<SelfAssessmentQuestionModerationProgressLookup>, userGuid: string): AxiosPromise<boolean>;

    /**
     * Save a Self Assessment Content Moderation progress
     * @param selfAssessmentContent learningModuleId
     * @param userGuid userGuid
     * @returns Boolean
     */
    saveAssessmentContentModerationState(selfAssessmentContent: Model.PartialPlainObject<SelfAssessmentQuestionModerationProgressLookup>, userGuid: string): AxiosPromise<boolean>;

    /**
     * accept Self Assessment for moderation
     * @param selfAssessmentId moduleId
     * @param userGuid userGuid
     * @returns bool
     */
    acceptSelfAssessmentForModeration(selfAssessmentId: number, userGuid: string): AxiosPromise<boolean>;

    /**
     * Send a Self Assessment back for review
     * @param selfAssessmentId selfAssessmentId
     * @param userGuid userGuid
     * @returns bool
     */
    sendSelfAssessmentForReview(selfAssessmentId: number, userGuid: string): AxiosPromise<boolean>;

    /**
     * Complete Modedration on self assessment and mark as moderated
     * @param selfAssessmentId moduleId
     * @param userGuid userGuid
     * @returns bool
     */
    completeSelfAssessmentModeration(selfAssessmentId: number, userGuid: string): AxiosPromise<boolean>;

    /** 
     * Get the tags2 where no moderator is assigned.
     * @returns List of UserModerationTag2Lookup
     */
    tag2WhereNoModeratorsExist(): AxiosPromise<Array<Model.PlainObject<UserModerationTag2Lookup>>>;
    
    getModerationTag2ByUserGuidAsync(userGuid: string): AxiosPromise<Array<Model.PartialPlainObject<UserModerationTag2Lookup>>>;

    tag2WhereNoModeratorsExistPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<Tag1SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserModerationTag2Lookup>>>;

    /** 
     * DeactivateModule
     * @param learningModuleId learningModuleId
     * @returns bool
     */
    deactivateModule(learningModuleId: number): AxiosPromise<boolean>;

    /**
     * UpdateModerationTag2Lookup
     * @param userModerationTags userModerationTags
     * @returns List of UserModerationTag2Lookup
     */
    updateModerationTag2Lookup(userModerationTags: Array<Model.PartialPlainObject<UserModerationTag2Lookup>>): AxiosPromise<Array<Model.PlainObject<UserModerationTag2Lookup>>>;

    // Client only properties / methods
}

@injectable()
export default class ModerationQueryApiClient extends Data.ApiClientBase implements IModerationQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/ModerationQuery`);
    }

    public getPagedLearningModuleLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>> {
        return this.axios.get(`${this.apiPath}/LearningModulesPaged?${Utils.getQueryString(request)}`);
    }

    public acceptModuleForModeration(learningModuleId: number, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/AcceptModuleForModeration/${learningModuleId}/${encodeURIComponent(userGuid)}`);
    }

    public saveModuleModeration(module: Model.PartialPlainObject<LearningModuleWithTagsLookup>, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SaveModuleModeration/${module}/${encodeURIComponent(userGuid)}`, module);
    }

    public saveLearningObjectModeration(learningObject: Model.PartialPlainObject<LearningObjectWithTagModerationLookup>, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SaveLearnigObjectModeration/${userGuid}`, learningObject);
    }

    public getQuizQuestion(questionId: number, learningObjectId: number): AxiosPromise<Model.PlainTrackedObject<ModeratableQuizQuestionLookup>> {
        return this.axios.put(`${this.apiPath}/GetQuizQuestion/${questionId}/${learningObjectId}`);
    }

    public getLearningObjectLookup(learningModuleId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectModerationLookup>>> {
        return this.axios.get(`${this.apiPath}/GetLearningObjectLookup/${learningModuleId}`);
    }

    public getLearningObjectTag3s(learningObjectId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectModerateableTagLookup>>> {
        return this.axios.get(`${this.apiPath}/GetLearningObjectModerateableTagLookup/${learningObjectId}`);
    }

    public saveLearningContentModeration(learningContent: Model.PartialPlainObject<LearningObjectContentLookup>, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SaveLearningContentModeration/${encodeURIComponent(userGuid)}`, learningContent);
    }

    public saveQuestionModeration(question: Model.PartialPlainObject<ModeratableQuizQuestionLookup>, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SaveQuestionModeration/${encodeURIComponent(userGuid)}`, question);
    }

    public sendModuleForReview(moduleId: number, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SendModuleForReview/${moduleId}/${encodeURIComponent(userGuid)}`);
    }

    public completeModuleModeration(moduleId: number, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/CompleteModuleModeration/${moduleId}/${encodeURIComponent(userGuid)}`);
    }

    public saveAssessmentModerationState(saveSelfAssessmentProgress: Model.PlainObject<SelfAssessmentModerationProgressLookup>, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SaveAssessmentModerationState/${encodeURIComponent(userGuid)}`, saveSelfAssessmentProgress);
    }

    public saveAssessmentQuestionModerationState(selfAssessmentQuestion: Model.PartialPlainObject<SelfAssessmentQuestionModerationProgressLookup>, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SaveQuestionModerationState/${encodeURIComponent(userGuid)}`, selfAssessmentQuestion);
    }

    public saveAssessmentContentModerationState(selfAssessmentContent: Model.PartialPlainObject<SelfAssessmentQuestionModerationProgressLookup>, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SaveContentModerationState/${encodeURIComponent(userGuid)}`, selfAssessmentContent);
    }

    public acceptSelfAssessmentForModeration(selfAssessmentId: number, userGuid: string): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/AcceptSelfAssessmentForModeration/${selfAssessmentId}/${encodeURIComponent(userGuid)}`);
    }

    public sendSelfAssessmentForReview(selfAssessmentId: number, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/SendSelfAssessmentForReview/${selfAssessmentId}/${encodeURIComponent(userGuid)}`);
    }

    public completeSelfAssessmentModeration(selfAssessmentId: number, userGuid: string): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/CompleteSelfAssessmentModeration/${selfAssessmentId}/${encodeURIComponent(userGuid)}`);
    }

    public tag2WhereNoModeratorsExist(): AxiosPromise<Array<Model.PlainObject<UserModerationTag2Lookup>>> {
        return this.axios.get(`${this.apiPath}/tag2nomoderator`);
    }

    public getModerationTag2ByUserGuidAsync(userGuid: string): AxiosPromise<Array<Model.PartialPlainObject<UserModerationTag2Lookup>>>
        {
            return this.axios.get(`${this.apiPath}/GetModerationTag2ByUserGuid/${encodeURIComponent(userGuid)}`);
        }

    public updateModerationTag2Lookup(userModerationTags: Array<Model.PartialPlainObject<UserModerationTag2Lookup>>): AxiosPromise<Array<Model.PlainObject<UserModerationTag2Lookup>>> {
        return this.axios.put(`${this.apiPath}/GetModerationTag2ByUserGuid`, userModerationTags);
    }

    public tag2WhereNoModeratorsExistPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<Tag1SearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserModerationTag2Lookup>>> {
        return this.axios.get(`${this.apiPath}/tag2nomoderator/paged?${Utils.getQueryString(request)}`);
    }

    public deactivateModule(learningModuleId: number): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/DeactivateModule/${learningModuleId}`);
    }
    // Client only properties / methods
}