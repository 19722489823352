import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { buildUpCustomHTMLForImage, buildUpCustomHTMLForSound, buildUpCustomHTMLForVideo, DownloadFile, OpenLink } from '../../../App/HelperClasses/GlobalHelpers';
import { AppService, Types } from '../../../Identity/IdentityTypes';
import { RichTextEditorVM } from '../../Components/RichTextEditor';
import BadgeLookup from '../../Models/Badges/BadgeLookup';
import ContentConsumptionLookup from '../../Models/ContentCosumption/ContentConsumptionLookup';
import InformationObjectConsumptionLookup from '../../Models/ContentCosumption/InformationObjectConsumptionLookup';
import ModuleObjectsConsumptionLookup from '../../Models/ContentCosumption/ModuleObjectsConsumptionLookup';
import QuizConsumptionLookup from '../../Models/ContentCosumption/QuizConsumptionLookup';
import { LearningContentMediaType } from '../../Models/LearningObjects/LearningContent/LearningContentMediaType.enum';
import { LearningObjectType } from '../../Models/LearningObjects/LearningObjectType.enum';
import OptionLookup from '../../Models/Lookups/OptionLookup';
import UserLearningModuleLookup from '../../Models/UserLearning/UserLearningModuleLookup';
import { UserLearningStatus } from '../../Models/UserLearning/UsersLearningStatus.enum';
import ModuleScoreLookup from '../../Models/Lookups/ModuleScoreLookup';
import fileDownload from 'js-file-download';
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration.enum';

@NeoModel
export default class ModuleConsumptionVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private contentConsumptionApiClient = AppService.get(Types.Learning.ApiClients.ContentConsumptionQueryApiClient),
    private downloadContentApiClient = AppService.get(Types.Learning.ApiClients.DownloadContentApiClient),
    public forumIntegrationQueryApiClient = AppService.get(Types.Forum.ApiClients.ForumIntegrationQueryApiClient),
    public topicQueryApiClient = AppService.get(Types.Forum.ApiClients.TopicQueryApiClient),
    private learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
    private badgeApiClient = AppService.get(Types.Learning.ApiClients.BadgeQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  public moduleId: number = 0;
  public isPreview: boolean = false;
  public module = new UserLearningModuleLookup();
  public infoLearningObject = new InformationObjectConsumptionLookup();
  public quizLearningObject = new QuizConsumptionLookup();
  public objectRichTextVm = new RichTextEditorVM(this.taskRunner);
  public hideInfoObjectInfo = true;
  public hideQuizObjectInfo = true;
  public quizRichTextVm = new RichTextEditorVM(this.taskRunner);
  public currentIndex: number = 0;
  public showContent = false;
  public alternateUrl: string = "";
  public content = new ContentConsumptionLookup();
  public contentBlobUrl = '';
  public contentBaseUrl = '';
  public completeQuizModal = false;
  public GoToNextFormQuiz = false;
  public questionListCount = 0;
  public multiSelectRichTextVm = new RichTextEditorVM(this.taskRunner);
  public multiChoiceRichTextVm = new RichTextEditorVM(this.taskRunner);
  public yesNoRichTextVm = new RichTextEditorVM(this.taskRunner);
  public trueFalseRichTextVm = new RichTextEditorVM(this.taskRunner);
  public quizMark = 0;
  public showQuizContent = false;
  public pathwayId = 0;
  public objectId = 0;
  public showPassModal = false;
  public showFinalQuizPassModal = false;
  public showFailModal = false;
  public conversationTitle = '';

  // Rich text HTML bindings
  public curQuestionHtml: string = "";

  //Forum properties
  public moduleForumId = 0;
  public activityForumId = 0
  public showTopicConversation = false;
  public showActivityConversation = false;
  public isMaximised = true;
  public moduleName: string = "";
  public showForumModal = false;

  //Badge properties
  public showBadgeAward: boolean = false;
  public badgeAwarded = new BadgeLookup();
  public blobUrl: string = "";
  public thereAreSomeMoreBadges: boolean = false;
  public badgeAwardedList = new List(BadgeLookup);
  public numberOfBadgesEarned: number = 0;
  public listOfBadgeIdsToShow: number[] = [];
  public allDone: boolean = false;

  public async downloadPdf(learningModuleId: number) {
    window.location.href = this.downloadContentApiClient.getDocumentFromLearningModuleAsyncUrl(learningModuleId);
    this.notifications.addSuccess(`"Learning Module", PDF file is being created and will be downloaded shortly.`, null, NotificationDuration.Longer);
  }

  public async downloadZip(learningModuleId: number) {
    this.taskRunner.run(async () => {
      this.notifications.addSuccess(`"Learning Module", Zip file is being created and will be downloaded shortly.`, null, NotificationDuration.Longer);
      var resp = await this.downloadContentApiClient.getZipFromLearningModuleAsyncUrl(learningModuleId);
      if (resp.status === 200) {
        const tmpStr = resp.headers['content-disposition'].split('filename=')[1];
        var fileName = tmpStr.split(';')[0];
        if (fileName.length > 2) {
          fileName = fileName.substring(1, fileName.length - 1);
        } else {
          fileName = "unknown.zip"
        }
        fileDownload(resp.data, fileName);
      }
    });
  }

  public GetModule = async (moduleId: number, moduleOnly: string) => {
    if (moduleOnly === '1') {
      const response = await this.contentConsumptionApiClient.getModuleById(moduleId, 0);
      this.moduleId = moduleId;
      if (response.data) {
        this.module.set(response.data);
      }
    } else {
      const response = await this.contentConsumptionApiClient.getUserModulebyId(moduleId);
      this.moduleId = moduleId;
      if (response.data) {
        this.module.set(response.data);
      }
    }
  }

  public showQuizQuestionContent = false;
  public showQuestionContent = false;
  public SelectObject = async (object: ModuleObjectsConsumptionLookup) => {
    this.currentIndex = 0;
    this.showFailModal = false;
    this.showQuizQuestionContent = false;
    if (object.learningObjectType === LearningObjectType.Information) {
      const response = await this.contentConsumptionApiClient.getInfoObjectById(object.learningObjectId, 0);

      if (response.data) {
        this.infoLearningObject.set(response.data);
        for (const content of this.infoLearningObject.contentList) {
          if (content.mediaType === LearningContentMediaType.Image ||
            content.mediaType === LearningContentMediaType.Video || content.mediaType === LearningContentMediaType.Document ||
            content.mediaType === LearningContentMediaType.Sound) {
            var blobUrl = await this.GetFileUrlFile(content.mediaObject!.fileDescriptorId as string, content.mediaType as number, false);
            this.contentBlobUrl = blobUrl;
          }
        }
        this.hideQuizObjectInfo = true;
        this.hideInfoObjectInfo = false;
      }
    } if (object.learningObjectType === LearningObjectType.Quiz || object.learningObjectType === LearningObjectType.FinalQuiz) {
      const response = await this.contentConsumptionApiClient.getQuizInfoById(object.learningObjectId, this.moduleId);
      if (response.data) {
        this.quizLearningObject.set(response.data);
        this.hideInfoObjectInfo = true;
        this.questionListCount = this.quizLearningObject.questions.length;
        this.hideQuizObjectInfo = false;
        this.quizLearningObject.questions.forEach(question => {
          var options: Array<string> = [];
          options = JSON.parse(question.options)
          options.forEach(element => {
            var optionLookup = new OptionLookup();
            optionLookup.option = element;
            question.optionLookupList.push(optionLookup);
          });
          if (question.contentId !== 0 && question.contentId !== null) {
            var questionContent = this.quizLearningObject.contentList.find(c => c.learningContentId === question.contentId);
            this.quizLearningObject.contentList.remove(questionContent!);
            question.contentList.push(questionContent!);
            this.showQuestionContent = true;
          } else {
            this.showQuestionContent = false;
          }
        });
        if (this.quizLearningObject.contentList.length > 0) {
          var fileUrl = await this.GetFileUrlFile(this.quizLearningObject.contentList[0].mediaObject?.fileDescriptorId as string, this.quizLearningObject.contentList[0].mediaType as number, false)
          this.contentBlobUrl = fileUrl;
          this.showQuizQuestionContent = true;
        }
      }
    }
  }

  public ViewContent = async (content: ContentConsumptionLookup) => {
    this.content = content;

    if (this.content.mediaType === LearningContentMediaType.Link) {
      OpenLink(this.content.externalResourceLocation);
    }

    if (this.content.mediaType === LearningContentMediaType.YouTube) {
      var url = "https://youtube.com/embed/" + this.content.externalResourceLocation + "?autoplay=1";
      OpenLink(url);
    }

    if (this.content.mediaType === LearningContentMediaType.Document) {
      var fileUrl = await this.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, false)
      if (fileUrl.endsWith(".pdf")) {
        this.contentBlobUrl = 'https://docs.google.com/gview?url=' + fileUrl + '&embedded=true';
        OpenLink(this.contentBlobUrl);
      }
      else {
        this.contentBlobUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + fileUrl;
        OpenLink(this.contentBlobUrl);
      }
    }

    if (this.content.mediaType === LearningContentMediaType.Image) {
      var imageUrl = await this.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, false);
      this.contentBlobUrl = buildUpCustomHTMLForImage(imageUrl);
      this.showContent = true;
    }

    if (this.content.mediaType === LearningContentMediaType.Video) {
      var videoUrl = await this.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, false)
      this.contentBlobUrl = buildUpCustomHTMLForVideo(videoUrl);
      let tmpstr = this.content.externalResourceLocation.toLowerCase();
      if (tmpstr.includes("embed")) {
        this.alternateUrl = this.content.externalResourceLocation;
        if (this.alternateUrl.includes('?')) {
          this.alternateUrl += "&autoplay=1"
        } else {
          this.alternateUrl += "?autoplay=1"
        }
      } else {
        this.alternateUrl = "";
      }
      this.showContent = true;
    } else {
      this.alternateUrl = "";
    }

    if (this.content.mediaType === LearningContentMediaType.Sound) {
      var soundUrl = await this.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, false)
      this.contentBlobUrl = buildUpCustomHTMLForSound(soundUrl);
      this.showContent = true;
    }

    if (this.content.mediaType === LearningContentMediaType.SCORM) {
      OpenLink(this.content.externalResourceLocation);
    }

    if (this.content.mediaType === LearningContentMediaType.Conversation) {
      this.openActivityForumDrawer();
    }
  }

  public GetFileUrlFile = async (fileDescriptorId: string, mediaTypeId: number, download: boolean) => {
    var response = await this.learningModuleApiClient.getFileDownloadURL(fileDescriptorId, mediaTypeId);
    if (download) {
      DownloadFile(response.data.toString());
    }
    return response.data.toString();
  }

  public CompleteQuiz = async () => {
    await this.taskRunner.run(async () => {
      this.completeQuizModal = false;
      var percentage = await this.CalculateQuizMark();
      this.quizMark = percentage;
      if (this.quizLearningObject.learningObjectType === LearningObjectType.Quiz) {
        this.showPassModal = true;
      }
      if (this.quizLearningObject.learningObjectType === LearningObjectType.FinalQuiz) {
        await this.saveUserModule(true);
        if (percentage === 100) {
          await this.checkBadges();
        } else {
          this.showFailModal = true;
        }
      }
    });
  }

  public moduleScoreLookup = new ModuleScoreLookup();

  public CalculateQuizMark = async () => {
    this.moduleScoreLookup = new ModuleScoreLookup();
    var response = await this.contentConsumptionApiClient.getQuizScore(this.quizLearningObject.toJSObject({ includeClean: true }));
    this.moduleScoreLookup.set(response.data);
    return this.moduleScoreLookup.score;
  }


  public SaveLastLearningObject = async (lo: ModuleObjectsConsumptionLookup) => {

    var lastLearningObject = this.module.learningObjectList.find(c => c.learningObjectId === this.module.lastLearningObjectId);

    if (lastLearningObject === undefined || (lastLearningObject !== undefined && lastLearningObject.order < lo.order)) {
      this.module.lastLearningObjectId = lo.learningObjectId;
      const response = await this.contentConsumptionApiClient.saveLastUserActivity(lo.learningObjectId, this.moduleId);
      this.module.set(response.data);
    }
  }

  public saveUserModule = async (isQuiz: boolean = false) => {
    if (!isQuiz) {
      this.module.lastLearningObjectId = this.infoLearningObject.learningObjectId;
      this.module.status = UserLearningStatus.InProgress;
      const response = await this.contentConsumptionApiClient.saveUserModule(this.module.toJSObject());
      if (response.data) {
        this.module.userLearningModuleId = response.data.userLearningModuleId;
        let nextActivity = undefined;
        let currentObject = this.module.learningObjectList.find(c => c.learningObjectId === this.infoLearningObject.learningObjectId);
        let currentIndex = this.module.learningObjectList.indexOf(currentObject ?? new ModuleObjectsConsumptionLookup());
        if (currentIndex >= 0) {
          if (currentIndex < this.module.learningObjectList.length - 1) {
            nextActivity = this.module.learningObjectList[currentIndex + 1];
          } else {
            this.notifications.addDanger("Error navigating", "Cannot advance past the end.", NotificationDuration.Standard)
            return;
          }
        } else {
          this.notifications.addDanger("Error navigating", "Could not find current activity before next activity.", NotificationDuration.Standard);
          return;
        }

        if (nextActivity === undefined) {
          this.notifications.addDanger("Error navigating", "Could not find next activity.", NotificationDuration.Standard);
          return;
        } else {
          this.infoLearningObject = new InformationObjectConsumptionLookup();
          await this.SelectObject(nextActivity!)
          this.module.userLearningModuleId = response.data.userLearningModuleId;
        }
      }
    } else {
      this.module.lastLearningObjectId = this.quizLearningObject.learningObjectId;
      this.module.status = UserLearningStatus.InProgress;
      this.showFinalQuizPassModal = false;
      this.showPassModal = false;

      if (this.quizLearningObject.learningObjectType === LearningObjectType.FinalQuiz) {
        this.module.finalQuizMark = this.quizMark;
      }
      const response = await this.contentConsumptionApiClient.saveUserModule(this.module.toJSObject());
      if (response.data) {
        let quizNextActivity = undefined;
        this.module.userLearningModuleId = response.data.userLearningModuleId;
        if (this.quizLearningObject.learningObjectType === LearningObjectType.Quiz) {

          this.hideQuizObjectInfo = true;
          let currentObject = this.module.learningObjectList.find(c => c.learningObjectId === this.quizLearningObject.learningObjectId);
          let currentIndex = this.module.learningObjectList.indexOf(currentObject ?? new ModuleObjectsConsumptionLookup());
          if (currentIndex >= 0) {
            if (currentIndex < this.module.learningObjectList.length - 1) {
              quizNextActivity = this.module.learningObjectList[currentIndex + 1];
            } else {
              this.notifications.addDanger("Error navigating", "Cannot advance past the end. (2)", NotificationDuration.Standard)
              return;
            }
          } else {
            this.notifications.addDanger("Error navigating", "Could not find current activity before next activity. (2)", NotificationDuration.Standard);
            return;
          }
          if (quizNextActivity === undefined) {
            this.notifications.addDanger("Error navigating", "Could not find next activity (2).", NotificationDuration.Standard);
            return;
          } else {
            this.quizLearningObject = new QuizConsumptionLookup();
            await this.SelectObject(quizNextActivity!)
          }
        }
      }
    }
  }

  public async checkBadges() {
    const badgeResponse = await this.badgeApiClient.getAwardUserBadges()

    this.badgeAwardedList.set(badgeResponse.data);
    this.numberOfBadgesEarned = badgeResponse.data.length;

    if (this.numberOfBadgesEarned === 0) {
      this.allDone = true;
      this.showFinalQuizPassModal = true;
    }

    this.badgeAwardedList.forEach(async badge => {
      this.listOfBadgeIdsToShow.push(badge.badgeId);
    });

    await this.showNextBadge();
  }

  public GetButtonText = (content: ContentConsumptionLookup) => {
    if (content.mediaType === LearningContentMediaType.Link) {
      return content.title;
    }
    if (content.mediaType === LearningContentMediaType.Sound) {
      return "Listen";
    }
    if (content.mediaType === LearningContentMediaType.Conversation) {
      return "Conversation";
    }
    return "View"
  }

  public CloseForumDrawer() {
    this.showActivityConversation = false
  }

  public backToModule = () => {
    this.infoLearningObject = new InformationObjectConsumptionLookup();
    this.quizLearningObject = new QuizConsumptionLookup();
    this.hideInfoObjectInfo = true;
    this.hideQuizObjectInfo = true;
    this.showFailModal = false;
    this.showQuestionContent = false;
  }

  public GetStatusText(lo: ModuleObjectsConsumptionLookup) {
    var theFinalQuiz = this.module.learningObjectList.find(c => c.learningObjectType === LearningObjectType.FinalQuiz);

    if (this.module.finalQuizStarted && lo.learningObjectType !== LearningObjectType.FinalQuiz) {
      return "Completed";
    }

    if (theFinalQuiz!.learningObjectId === this.module.lastLearningObjectId && lo.learningObjectType !== LearningObjectType.FinalQuiz) {
      return "Completed";
    }

    if (this.module.finalQuizMark === 100) {
      return "Completed";
    }

    if (this.module.finalQuizMark > 0 && this.module.finalQuizMark < 100 && lo.learningObjectType === LearningObjectType.FinalQuiz) {
      return "In Progress";
    }

    if (this.module.finalQuizMark > 0 && this.module.finalQuizMark < 100) {
      return "Completed";
    }

    if (this.module.finalQuizMark === 0 && this.module.lastLearningObjectId === theFinalQuiz!.learningObjectId) {
      return "In Progress";
    }

    if (this.module.lastLearningObjectId === lo.learningObjectId) {
      return "Completed"
    }

    var lastCompletedLo = this.module.learningObjectList.find(c => c.learningObjectId === this.module.lastLearningObjectId);
    if (lastCompletedLo !== undefined) {
      if (lastCompletedLo.order > lo.order) {
        return "Completed";
      }
      if (lastCompletedLo.learningObjectId === lo.learningObjectId) {
        return "Completed";
      }
    }
    return "Not started"
  }


  public AllowNextV2 = (lo: ModuleObjectsConsumptionLookup) => {
    var theFinalQuiz = this.module.learningObjectList.find(c => c.learningObjectType === LearningObjectType.FinalQuiz);
    if (this.module.finalQuizStarted) {
      return false;
    }

    if (theFinalQuiz!.learningObjectId === this.module.lastLearningObjectId) {
      return false;
    }

    if (this.module.finalQuizMark === 100) {
      return false;
    }

    if (this.module.finalQuizMark > 0 && this.module.finalQuizMark < 100) {
      return false;
    }

    if (this.module.finalQuizMark === 0 && this.module.lastLearningObjectId === theFinalQuiz!.learningObjectId) {
      return false;
    }

    if (lo.order === 1) {
      return false;
    }


    if (lo.order === 0) {
      return false;
    }

    var lastCompletedLo = this.module.learningObjectList.find(c => c.learningObjectId === this.module.lastLearningObjectId);
    if (lastCompletedLo !== undefined) {
      if (lastCompletedLo.order >= lo.order) {
        return false;
      }
    }

    var curActivity = this.module.learningObjectList.find(c => lo.learningObjectId);
    var curIndex = this.module.learningObjectList.indexOf(curActivity ?? new ModuleObjectsConsumptionLookup());
    var prevActivity = undefined;
    if (curIndex >= 0) {
      prevActivity = this.module.learningObjectList[curIndex - 1];
    } else {
      return false;
    }

    if (prevActivity?.learningObjectId === this.module.lastLearningObjectId) {
      return false;
    }
    return true;
  }

  public GoToPrevActivity = async (isQuestion: boolean) => {
    if (isQuestion) {
      let currentObject = this.module.learningObjectList.find(c => c.learningObjectId === this.quizLearningObject.learningObjectId);
      let currentIndex = this.module.learningObjectList.indexOf(currentObject ?? new ModuleObjectsConsumptionLookup());
      var prevQuizObject = undefined;

      if (currentIndex >= 1) {
        prevQuizObject = this.module.learningObjectList[currentIndex - 1];
      } else {
        return;
      }

      if (prevQuizObject === undefined) {
        this.notifications.addDanger("Error navigating", "Could not find previous activity. (2)", NotificationDuration.Standard)
        return;
      } else {
        this.quizLearningObject = new QuizConsumptionLookup();
        await this.SelectObject(prevQuizObject!);
      }
    } else {
      let currentObject = this.module.learningObjectList.find(c => c.learningObjectId === this.infoLearningObject.learningObjectId);
      let currentIndex = this.module.learningObjectList.indexOf(currentObject ?? new ModuleObjectsConsumptionLookup());
      var prevObject = undefined;
      if (currentIndex >= 1) {
        prevObject = this.module.learningObjectList[currentIndex - 1];
      } else {
        return;
      }

      if (prevObject === undefined) {
        this.notifications.addDanger("Error navigating", "Could not find previous activity.", NotificationDuration.Standard)
        return;
      } else {
        this.infoLearningObject = new InformationObjectConsumptionLookup();
        await this.SelectObject(prevObject!);
      }
    }
  }

  public openActivityForumDrawer = async () => {
    let response = await this.forumIntegrationQueryApiClient.getConversationForLearningActivity(this.infoLearningObject.learningObjectId);
    this.moduleForumId = response.data.forumId;
    var topicResponse = await this.topicQueryApiClient.getLearningObjectTopicByForumId(this.moduleForumId);
    this.activityForumId = topicResponse.data.topicId;
    this.moduleName = topicResponse.data.forumName;
    this.showActivityConversation = true;
    this.showTopicConversation = true;
  }

  public showNextBadge = async () => {
    //If we click next badge and there are no more badges to show, we say we are all done. 
    if (this.listOfBadgeIdsToShow.length === 0) {
      this.allDone = true;
      return this.numberOfBadgesEarned;
    }
    var currentBadge = new BadgeLookup();

    var nextBadgeId = this.listOfBadgeIdsToShow[0];

    if (nextBadgeId !== null || nextBadgeId > 0) {
      currentBadge = this.badgeAwardedList.find(c => c.badgeId === nextBadgeId)!
      this.badgeAwarded = currentBadge;
      this.listOfBadgeIdsToShow.remove(currentBadge.badgeId);
      const bloblUrlResp = await this.taskRunner.waitFor(this.badgeApiClient.getBadgeImageByDescriptorId(this.badgeAwarded.mediaObject?.fileDescriptorId as string));
      this.badgeAwarded.blobUrl = bloblUrlResp.data;
      this.numberOfBadgesEarned = this.numberOfBadgesEarned > 0 ? this.numberOfBadgesEarned - 1 : 0;
      this.showBadgeAward = true;
    } else {
      this.numberOfBadgesEarned = 0;
      this.showFinalQuizPassModal = true;
    }

    return this.numberOfBadgesEarned;
  }

  public openForumDrawer = async () => {
    let response = await this.forumIntegrationQueryApiClient.getConversationForLearningModule(this.moduleId);
    this.moduleForumId = response.data.forumId;
    this.conversationTitle = 'General Module Conversations'
    this.showForumModal = true;
  }

  public async closeForumModal() {
    this.showForumModal = false
  }
}