import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'
import Tag3 from '../../Models/Tags/Tag3';

export interface ITag3ApiClient extends Data.IUpdateableApiClient<Tag3, number> {

    // Client only properties / methods
}

@injectable()
export default class Tag3ApiClient extends Data.UpdateableApiClient<Tag3, number> implements ITag3ApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/Tag3`);
    }

    // Client only properties / methods
}