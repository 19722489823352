import { Attributes, List, Model, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { BadgeType } from './BadgeType.enum';
import MediaObject from '../LearningObjects/LearningContent/MediaObject';
import BadgeLearningPathway from './BadgeLearningPathway';
import BadgeModule from './BadgeModule';
import { BadgeAction } from './BadgeAction.enum.';
import { BadgeSubAction } from './BadgeSubAction.enum';

@NeoModel
export default class BadgeBase extends ModelBase {

    public badgeId: number = 0;

    public badgeType: BadgeType | null = null;

    @Rules.StringLength(255)
    public badgeTitle: string = "";

    public badgeDescription: string = "";

    public badgeAwardMessage: string = "";

    @Attributes.ChildObject(Model.UserEvent)
    public created: Model.UserEvent | null = null;

    @Attributes.ChildObject(Model.UserEvent)
    public modified: Model.UserEvent | null = null;

    public emailNotification: boolean = false;

    public badgeApproved: boolean = false;

    public activeBadge: boolean = false;

    @Attributes.ChildObject(MediaObject)
    public mediaObject: MediaObject | null = null;

    public badgeAction: BadgeAction | null = null;

    public badgeSubAction: BadgeSubAction | null = null;

    public badgeLearningPathways = new List(BadgeLearningPathway);

    public badgeModules = new List(BadgeModule);

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.badgeTitle) {
            return "New badge base";
        } else {
            return this.badgeTitle;
        }
    }
}