import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class PersalEducatorLookup extends LookupBase {

    public educatorId: number = 0;

    public surname: string = "";

    public persalId: string = "";

    public identificationNumber: string = "";

    // Client only properties / methods
}