import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'
import District from '../../Models/Organisations/District';

export interface IDistrictApiClient extends Data.IUpdateableApiClient<District, number> {

    // Client only properties / methods
}

@injectable()
export default class DistrictApiClient extends Data.UpdateableApiClient<District, number> implements IDistrictApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/District`);
    }

    // Client only properties / methods
}