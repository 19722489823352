import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import InformationObjectConsumptionLookup from '../ContentCosumption/InformationObjectConsumptionLookup';

@NeoModel
export default class ModuleScoreLookup extends ModelBase {

  public objectsToReview = new List(InformationObjectConsumptionLookup);

  @Attributes.Float()
  public score: number = 0;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New module score lookup";
    } else {
      return "Module Score Lookup";
    }
  }
}