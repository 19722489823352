import { Routing as NeoRouting } from "@singularsystems/neo-core";
import TagView from "./Views/Maintenance/Tags/TagView";
import LearningModuleView from "./Views/LearningModuleView";
import { UserTypes } from "../App/Models/Enums/UserTypes.enums";
import * as Roles from './Models/Security/LearningRoles';
import * as ModerationRoles from '../Authorisation/Models/Enums/ModerationRoles.enum';
import ModuleModerationView from "./Views/Moderation/ModuleModerationView";
import AssessmentView from "./Views/Assessment/AssessmentView";
import SelfAssessmentModerationView from "./Views/Moderation/SelfAssessmentModerationView";

export interface IAppRoute extends NeoRouting.IMenuRoute {
    userType?: UserTypes 
    children?: IAppRoute[]
    denyRole?: string
   }

export const MaintenanceLearningRoutes: IAppRoute = {
    /// add Routes here
    name: "Learning", icon: "chalkboard-teacher", allowAnonymous: false, userType: UserTypes.Verified,
    children: [
        {
            name: "Tag Admin", path: "/Learning/Tags", component: TagView, icon: "tags", allowAnonymous: false, userType: UserTypes.Admin, role: Roles.Tags.Access
        },
        {
            name: "Create Modules", path: "/Learning/Modules", component: LearningModuleView, icon: "chalkboard", allowAnonymous: false, userType: UserTypes.Admin, role: Roles.Learning.ContentCreator
        },
        {
            name: "Create Self-Assessments", path: '/Learning/Assessment', allowAnonymous: false, component: AssessmentView, icon: "clipboard-check", userType: UserTypes.Admin, role: Roles.Learning.ContentCreator
        },
        {
            name: "Moderation", path: "/Learning/Moderation", component: ModuleModerationView, icon: "band-aid", allowAnonymous: false, userType: UserTypes.Admin, role: ModerationRoles.Moderation.Moderator
        },
        {
            name: "Self-Assessment Moderation", path: "/Learning/AssessmentModeration", component: SelfAssessmentModerationView, icon: "band-aid", allowAnonymous: false, userType: UserTypes.Admin, role: ModerationRoles.Moderation.Moderator
        }
    ]
}

const PureRoutes: NeoRouting.IRoute[] = [];

export {
    PureRoutes
}