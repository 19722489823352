import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import School from '../../Models/Organisations/School';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import SchoolManagementTeam from '../../Models/Organisations/SchoolManagementTeam';


export interface ISchoolManagementTeamQueryApiClient {

    /**
     * Return a School management team by SchoolID with children
     * @param schoolManagementTeamId SchoolID
     * @returns School management team list with children
     */
    getSchoolManagementListById(schoolManagementTeamId: number): AxiosPromise<Array<Model.PlainTrackedObject<School>>>;

    /** 
     * Gets a list of school management teams
     * @returns A list of School Management Teams
     */
    getSchoolManagementTeamList(): AxiosPromise<Array<Model.PlainTrackedObject<SchoolManagementTeam>>>;

    // Client only properties / methods
}

@injectable()
export default class SchoolManagementTeamQueryApiClient extends Data.ApiClientBase implements ISchoolManagementTeamQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/SchoolManagementTeamQuery`);
    }
    
    public getSchoolManagementListById(schoolManagementTeamId: number): AxiosPromise<Array<Model.PlainTrackedObject<School>>> {
        return this.axios.get(`${this.apiPath}/SchoolManagementTeamList/${schoolManagementTeamId}`);
    }
    
    public getSchoolManagementTeamList(): AxiosPromise<Array<Model.PlainTrackedObject<SchoolManagementTeam>>> {
        return this.axios.get(`${this.apiPath}/SchoolManagementTeams`);
    }
    
    // Client only properties / methods
}