import { Data, Misc, ModalUtils, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { Types } from '../IdentityTypes';
import { AppService } from '../CommonIdentityTypes';
import UserProfileLookup from '../Models/Users/Lookups/UserProfileLookup';
import UserProfileLookupSearchCriteria from '../Models/Users/Lookups/UserProfileLookupSearchCriteria';
import { NotificationDuration } from '../../App/Models/Enums/NotificationDuration';
import { UserTypes } from '../../App/Models/Enums/UserTypes.enums';
import OrgLevel from '../Models/Users/OrgLevel';
import { SelectOrgLevelComponentVM } from '../Components/SelectOrgLevelComponent';

@NeoModel
export default class UserMaintennceVM extends Views.ViewModelBase {

    public userProfile: UserProfileLookup;
    public criteria = new UserProfileLookupSearchCriteria();
    public selectedUserGuid: string = "";
    public showEditModal: boolean = false;
    public EditIsMaximised: boolean = true;
    private OldUserType: UserTypes | null = null;
    private OldIsActive: boolean = false;

    public orgLevel: OrgLevel = new OrgLevel();
    public orgLevelName: string = "";
    public showUserList: boolean = false;
    public showOrgLevelModal: boolean = false;
    public orginalOrgLevel: OrgLevel = new OrgLevel();
    public selectOrgLevelComponentVM: SelectOrgLevelComponentVM = new SelectOrgLevelComponentVM();


    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private userApiClient = AppService.get(Types.Identity.ApiClients.UserProfileApiClient),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient)) {
        super(taskRunner);
        this.userProfile = new UserProfileLookup()
    }

    public userLookupPageManager = new Data.PageManager(this.criteria, UserProfileLookup, this.userQueryApiClient.getPagedRegisteredUserLookupAtCurrentUserOrgLevelAndBelowAsync, {
        pageSize: 10,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sortBy: "firstName",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner,
    })

    public async editUserModal(userGuid: string, userType: UserTypes | null = null, isActive: boolean) {
        if (userGuid !== "") {
            this.selectedUserGuid = userGuid;
            this.OldUserType = userType;
            this.OldIsActive = isActive;
            const response = await this.taskRunner.waitFor(this.userQueryApiClient.getRegisteredUser(this.selectedUserGuid));
            let userData = response.data;
            this.userProfile.set(userData);
        }
        this.showEditModal = true;
    }

    public async closeEditModal() {
        this.userProfile = new UserProfileLookup();
        this.selectedUserGuid = "";
        this.showEditModal = false;
    }

    public async saveUser() {
        if (this.userProfile.isDirty) {
            if (this.userProfile.isActive !== this.OldIsActive && this.userProfile.isActive === false) {
                if (await ModalUtils.showYesNo("Please Note", `${this.userProfile.firstName} ${this.userProfile.surname} is about to be deactivated. They will be unable to login to the website. Would you like to proceed with this change?`) === Misc.ModalResult.Yes) {
                    await this.SaveUserData();
                }
            }
            else if (this.userProfile.userType !== this.OldUserType && this.userProfile.userType === UserTypes.Admin) {
                if (await ModalUtils.showYesNo("Please Note", `${this.userProfile.firstName} ${this.userProfile.surname} is about to be made an administrator. They will be required to provide a password at the next login. Would you like to proceed with this change?`) === Misc.ModalResult.Yes) {
                    await this.SaveUserData();
                }
            }
            else if (this.OldUserType === UserTypes.Admin && this.userProfile.userType !== UserTypes.Admin) {
                if (await ModalUtils.showYesNo("Please Note", `${this.userProfile.firstName} ${this.userProfile.surname} was an admin and is about to lose their admin status. This will clear any password the user has and remove them from all groups they belong to. Would you like to proceed with this change?`) === Misc.ModalResult.Yes) {
                    await this.SaveUserData();
                }
            }
            else {
                await this.SaveUserData();
            }
        }
        else {
            this.showEditModal = false;
            this.notifications.addInfo(`No changes made to ${this.userProfile.firstName} ${this.userProfile.surname}'s details`, null, NotificationDuration.Standard)
        }
    }

    private async SaveUserData() {
        this.taskRunner.run(async () => {
            this.showEditModal = false;
            await this.userApiClient.updateUserProfileAsync(this.userProfile.toJSObject());
            this.notifications.addSuccess(`${this.userProfile.firstName} ${this.userProfile.surname}'s,  details saved`, null, NotificationDuration.Standard);
            this.selectedUserGuid = "";
            this.userProfile = new UserProfileLookup();
            this.userLookupPageManager.refreshData();
        });
    }

    private clearValues() {
        this.orgLevel = new OrgLevel();
    }
    // Select Organisation Level Modal

    public showOrgSelector() {
        this.orginalOrgLevel = this.userProfile.organisationlevel;
        this.showEditModal = false;
        this.showOrgLevelModal = true;
    }

    public async doResetPassword(profile: UserProfileLookup) {
        this.taskRunner.run(async () => {
            this.userApiClient.resetPasswordAsync(profile.userGuid);
            this.notifications.addSuccess(`${this.userProfile.firstName} ${this.userProfile.surname}'s,  password reset. They should get emailed shortly.`, null, NotificationDuration.Standard);
        });
    }

    public async resetPassword(profile: UserProfileLookup) {
        if (profile.userType !== UserTypes.Admin) {
            await ModalUtils.showMessage("Error", "You can only reset the password of an admin user.");
            return;
        } else {
            if (await ModalUtils.showYesNo("Please Note", `You are about to reset the password for ${this.userProfile.firstName} ${this.userProfile.surname}. This person will receive an email with the new password. Would you like to proceed with this change?`) === Misc.ModalResult.Yes) {
                await this.doResetPassword(profile);
            }
        }
    }

    public closeSelectOrgLevelModal() {
        this.showOrgLevelModal = false;
        this.showEditModal = true;
        if (this.selectOrgLevelComponentVM.resultSuccess) {
            this.userProfile.organisationLevelName = this.selectOrgLevelComponentVM.orgLevelName;
            this.userProfile.organisationlevel = this.selectOrgLevelComponentVM.orgLevel;
        }
    }
    public async downloadUserData(){
        this.taskRunner.run( async() =>{
            const tokenResponse = await this.userQueryApiClient.getUserExcelExportToken();
            const fileUserData = await this.userQueryApiClient.getUserExcelExport(tokenResponse.data);
            window.location.href = fileUserData;
        })
    }
}