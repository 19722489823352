import React from "react";
import { NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService } from "../../../App/Services/AppService";

import Types from "../../../App/AppTypes";
import Option from "./Option";
import CloudStorageImportModal, {
  CloudStorageImportModalVM,
} from "./CloudStorageImportModal";

import { AtomicBlockUtils, EditorProps } from "draft-js";

@NeoModel
export class UploadToCloudComponentVM extends Views.ViewModelBase {
  constructor(taskRunner = AppService.get(Types.Neo.TaskRunner)) {
    super(taskRunner);
  }
}

interface UploadToCloudComponentProps
  extends Pick<EditorProps, "editorState" | "onChange"> {
  viewModel: UploadToCloudComponentVM;
  focusEditor: Function;
}

interface UploadToCloudComponentState {
  showImportModal: boolean;
}

export class UploadToCloudComponent extends React.Component<
  UploadToCloudComponentProps,
  UploadToCloudComponentState
> {
  private imageIconSrc =
    "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNzQxIDBILjI2Qy4xMTYgMCAwIC4xMzYgMCAuMzA0djEzLjM5MmMwIC4xNjguMTE2LjMwNC4yNTkuMzA0SDE0Ljc0Yy4xNDMgMCAuMjU5LS4xMzYuMjU5LS4zMDRWLjMwNEMxNSAuMTM2IDE0Ljg4NCAwIDE0Ljc0MSAwem0tLjI1OCAxMy4zOTFILjUxN1YuNjFoMTMuOTY2VjEzLjM5eiIvPjxwYXRoIGQ9Ik00LjEzOCA2LjczOGMuNzk0IDAgMS40NC0uNzYgMS40NC0xLjY5NXMtLjY0Ni0xLjY5NS0xLjQ0LTEuNjk1Yy0uNzk0IDAtMS40NC43Ni0xLjQ0IDEuNjk1IDAgLjkzNC42NDYgMS42OTUgMS40NCAxLjY5NXptMC0yLjc4MWMuNTA5IDAgLjkyMy40ODcuOTIzIDEuMDg2IDAgLjU5OC0uNDE0IDEuMDg2LS45MjMgMS4wODYtLjUwOSAwLS45MjMtLjQ4Ny0uOTIzLTEuMDg2IDAtLjU5OS40MTQtMS4wODYuOTIzLTEuMDg2ek0xLjgxIDEyLjE3NGMuMDYgMCAuMTIyLS4wMjUuMTcxLS4wNzZMNi4yIDcuNzI4bDIuNjY0IDMuMTM0YS4yMzIuMjMyIDAgMCAwIC4zNjYgMCAuMzQzLjM0MyAwIDAgMCAwLS40M0w3Ljk4NyA4Ljk2OWwyLjM3NC0zLjA2IDIuOTEyIDMuMTQyYy4xMDYuMTEzLjI3LjEwNS4zNjYtLjAyYS4zNDMuMzQzIDAgMCAwLS4wMTYtLjQzbC0zLjEwNC0zLjM0N2EuMjQ0LjI0NCAwIDAgMC0uMTg2LS4wOC4yNDUuMjQ1IDAgMCAwLS4xOC4xTDcuNjIyIDguNTM3IDYuMzk0IDcuMDk0YS4yMzIuMjMyIDAgMCAwLS4zNTQtLjAxM2wtNC40IDQuNTZhLjM0My4zNDMgMCAwIDAtLjAyNC40My4yNDMuMjQzIDAgMCAwIC4xOTQuMTAzeiIvPjwvZz48L3N2Zz4=";

  constructor(props: UploadToCloudComponentProps) {
    super(props);

    this.state = {
      showImportModal: false,
    };
  }

  toggleShowImportModal = () => {
    this.setState({ showImportModal: !this.state.showImportModal });
    if (this.state.showImportModal) {
      this.props.focusEditor();
    }
  };

  addImage = (src: string, height: string, width: string) => {
    const { editorState, onChange } = this.props;
    const entityData = { src, height, width };

    const entityKey = editorState
      .getCurrentContent()
      .createEntity("IMAGE", "MUTABLE", entityData)
      .getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );
    onChange(newEditorState);
  };

  selectImageControlAndEnterCloudStorageUrl(cloudStorageImageUrl: string) {
    this.addImage(cloudStorageImageUrl, "auto", "auto");
  }

  setImageControlUrlAfterCloudStorageUpload = (
    cloudStorageImageUrl: string
  ) => {
    setTimeout(() => {
      this.toggleShowImportModal();
      this.selectImageControlAndEnterCloudStorageUrl(cloudStorageImageUrl);
    }, 500);
  };

  render() {
    const { viewModel } = this.props;
    return (
      <div
        className="rdw-cloud-upload-wrapper"
        aria-haspopup="true"
        aria-expanded={this.state.showImportModal}
        aria-label="rdw-image-control"
      >
        <Option
          title="Image"
          onClick={this.toggleShowImportModal}
          className={""}
        >
          <img src={this.imageIconSrc} alt="" />
        </Option>

        <CloudStorageImportModal
          viewModel={new CloudStorageImportModalVM(viewModel.taskRunner)}
          shouldShow={this.state.showImportModal}
          onClose={this.toggleShowImportModal}
          onReturnedUrl={this.setImageControlUrlAfterCloudStorageUpload}
        />
      </div>
    );
  }
}
