import { AppServices, ModalUtils, List, NeoModel, NotifyUtils, Misc } from "@singularsystems/neo-core";
import { Views } from '@singularsystems/neo-react';

import { injectable } from 'inversify';

import AuthorisationTypes from '../AuthorisationTypes';
import UserGroupLookup from "../Models/UserGroupLookup";
import UserGroup from "../Models/UserGroup";
import GroupMembershipLookup from "../Models/GroupMembershipLookup";
import AssignedRole from "../Models/AssignedRole";
import MemberFindCriteria from "../Models/MemberFindCriteria";
import NewMembership from "../Models/NewMembership";
import Resource from "../Models/Resource";
import UserLookup from '../Models/UserLookup';
import { NotificationDuration } from '../../App/Models/Enums/NotificationDuration';
import { AppService, Types } from "../../Identity/IdentityTypes";

export enum UserGroupsViewState {
  ManageList = 2,
  ManageItem = 3
}

@injectable()
@NeoModel
export default class UserGroupsVM extends Views.ViewModelBase {

  private userGroupIdToLoad = 0;

  public selectedTab: string = "Members";

  public userGroupsLoaded: boolean = false;

  public userGroups = new List(UserGroupLookup);

  public resourceRoles = new List(Resource);

  public userList = new List(UserLookup);

  public userGroup = new UserGroup();

  public currentUserGroupLookup = new UserGroupLookup();

  public groupMemberships = new List(GroupMembershipLookup);

  public assignedRoles = new List(AssignedRole);

  public viewState: UserGroupsViewState = UserGroupsViewState.ManageList;

  public memberFindCriteria = new MemberFindCriteria();

  public isSaving: boolean = false;

  constructor(
    taskRunner = Misc.Globals.appService.get(AppServices.NeoTypes.TaskRunner),
    public apiClient = Misc.Globals.appService.get(AuthorisationTypes.ApiClients.AuthorisationApiClient),
    public userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
    public roleApiClient = Misc.Globals.appService.get(AuthorisationTypes.ApiClients.UserRoleApiClient),
    public rolesTask = Misc.Globals.appService.get(AppServices.NeoTypes.TaskRunner)) {

    super(taskRunner);
  }

  public async initialise() {
    this.refresh()
  }

  public async refresh() {
    // load the roles and users. Don't await these
    this.loadResourceRolesAsync();
    this.loadUsersAsync();

    // load the user groups, await these
    const response = await this.taskRunner.waitFor(this.apiClient.userGroups.getLookupAsync());
    this.userGroups.set(response.data);
    this.userGroupsLoaded = true;

    if (this.userGroupIdToLoad !== 0) {
      this.currentUserGroupLookup = this.userGroups.find(ug => ug.userGroupId === this.userGroupIdToLoad)!;
    }
  }

  public async loadUserGroup(userGroupId: number) {
    if (!this.userGroupsLoaded) {
      this.userGroupIdToLoad = userGroupId;

      // still load the full user group while we are waiting for the lookups
      await this.manageUserGroupId(userGroupId);
    } else {
      var userGroup = this.userGroups.find(ug => ug.userGroupId === userGroupId);
      if (userGroup) {
        await this.manageUserGroup(userGroup);
      }
    }
  }

  public async loadResourceRolesAsync() {
    const response = await this.rolesTask.waitFor(this.apiClient.resources.getRolesAsync());
    this.resourceRoles.set(response.data);

    if (this.viewState === UserGroupsViewState.ManageItem) {
      this.computeSelectedRoles();
    }
  }

  public async loadUsersAsync() {
    const response = await this.rolesTask.waitFor(this.apiClient.users.getLookupAsync());
    this.userList.set(response.data);
  }

  public addUserGroup() {
    this.currentUserGroupLookup = new UserGroupLookup();
    this.resetUserGroup();
  }

  private resetUserGroup() {
    this.userGroup = new UserGroup();
    this.groupMemberships.set([]);
    this.assignedRoles.set([]);
  }

  public async manageUserGroup(item: UserGroupLookup) {
    await this.manageUserGroupId(item.userGroupId);

    this.currentUserGroupLookup = item;
  }

  public resetAssignedRoles() {
    this.computeSelectedRoles();
  }

  private async manageUserGroupId(userGroupId: number) {
    try {
      const response = await this.apiClient.userGroups.get(userGroupId);

      if (response.data.userGroup) {
        this.userGroup.set(response.data.userGroup);
        this.groupMemberships.set(response.data.groupMemberships);
        this.assignedRoles.set(response.data.assignedRoles);

        this.computeSelectedRoles();
        this.viewState = UserGroupsViewState.ManageItem;
      } else {
        this.resetUserGroup();
      }

    } catch (e) {
      alert(e);
    }
  }

  private allRoleCount = 0;

  public get assignedRolesLength() {
    if (this.userGroup.isAdministratorGroup) {
      return this.allRoleCount;
    } else {
      return this.assignedRoles.length;
    }
  }

  public computeSelectedRoles() {
    // make a set with the assigned role ids to make the lookup faster
    if (this.userGroup.isAdministratorGroup) {
      this.allRoleCount = 0;
      this.resourceRoles.forEach(resource => {
        resource.categories.forEach(category => {
          category.roles.forEach(role => {
            role.selected = true;
            this.allRoleCount++;
          });
        });
        resource.markOld();
      });
    }
    else {
      const roleMap: { [id: number]: AssignedRole } = {};
      this.assignedRoles.forEach((item, index) => {
        roleMap[item.roleId] = this.assignedRoles[index];
      });
      // now loop through all resource roles and mark as selected/unselected
      this.resourceRoles.forEach(resource => {
        resource.categories.forEach(category => {
          category.roles.forEach(role => {
            if (roleMap[role.roleId]) {
              role.selected = true;
              role.assignedRoleId = roleMap[role.roleId].assignedRoleId;
            } else {
              role.selected = false;
              role.assignedRoleId = null;
            }
          });
        });
        resource.markOld();
      });
    }

  }

  public async addMember() {
    if (await this.canAddMember()) {
      this.taskRunner.run(async () => {
        try {
          if (this.userGroup.isDirty) {
            // Save user groups so that we know we have a user group id
            await this.saveUserGroup();
          }

          var lookupGuid = this.memberFindCriteria.addUserGuid;
          if (lookupGuid == null) { return; }
          var result = await this.roleApiClient.getUserByGuid(lookupGuid);
          if (result.status !== 200) {
            return;
          }

          const membership = new NewMembership();
          membership.userGroupId = this.userGroup.userGroupId;
          membership.userId = result.data.userId as number;

          this.isSaving = true;
          const response = await this.apiClient.memberships.add(membership);
          // update the list to refresh the UI
          this.currentUserGroupLookup.memberCount++;
          this.groupMemberships.update([response.data]);
          // clear the selected user
          this.memberFindCriteria.addUserGuid = null;

          NotifyUtils.addSuccess("Member Added", `New member added to ${this.userGroup.userGroupName}`, NotificationDuration.Standard);
        }
        finally {
          this.isSaving = false;
        }
      });
    }
  }

  public async canAddMember() {
    if (this.memberFindCriteria.addUserGuid) {

      var lookupGuid = this.memberFindCriteria.addUserGuid;
      if (lookupGuid == null) { return; }
      var result = await this.roleApiClient.getUserByGuid(lookupGuid);
      if (result.status !== 200) { return (false); }

      const existingMember = this.groupMemberships.find(
        m => m.userId === Number(result.data.userId)
      );
      if (existingMember) {
        NotifyUtils.addWarning("Already a member", `${existingMember.memberName} is already a member of the group`, NotificationDuration.Standard);
        this.memberFindCriteria.addUserGuid = null;
        return false;
      }
      return true;
    } else {
      NotifyUtils.addWarning("Select user", "Select the user first", NotificationDuration.Standard);
    }
    return false;
  }

  public async removeMember(member: GroupMembershipLookup) {
    if (await ModalUtils.showYesNo(
      `Remove '${member.memberName}'?`,
      `Are you sure you want to remove member '${member.memberName}'?`)
      === Misc.ModalResult.Yes) {
      this.taskRunner.run(async () => {
        this.isSaving = true;
        await this.apiClient.memberships.remove(member.membershipId);
        this.currentUserGroupLookup.memberCount--;
        this.groupMemberships.remove(member);
        this.isSaving = false;
        NotifyUtils.addSuccess("Member Removed", `Member removed from ${this.userGroup.userGroupName}`, NotificationDuration.Standard);
      });
    }
  }

  public canSave(): boolean {
    return (
      this.isSaving || (!this.userGroup.isDirty && !this.resourceRoles.isDirty)
    );
  }

  public async save() {
    this.taskRunner.run(async () => {
      try {
        if (this.userGroup.isDirty) {
          await this.saveUserGroup();
        }

        if (this.resourceRoles.isDirty) {
          await this.saveAssignedRoles();
        }

        NotifyUtils.addSuccess("Saved Successfully", `User group ${this.userGroup.userGroupName} saved successfully`, NotificationDuration.Standard);
      }
      finally {
        this.isSaving = false;
      }
    });
  }

  public async saveAssignedRoles() {
    this.updateAssignedRoles();

    this.isSaving = true;
    const response = await this.apiClient.assignedRoles.save(this.assignedRoles);

    this.assignedRoles.update(response.data);
    this.computeSelectedRoles();
    this.isSaving = false;
  }

  private updateAssignedRoles() {
    this.resourceRoles.forEach(resource => {
      if (resource.isDirty) {
        resource.categories.forEach(category => {
          if (category.isDirty) {
            category.roles.forEach(role => {
              if (role.isDirty) {
                if (role.selected && role.assignedRoleId == null) {
                  // this is a new role
                  const newRole = this.assignedRoles.addNew();
                  newRole.userGroupId = this.userGroup.userGroupId;
                  newRole.roleId = role.roleId;
                }
                else if (role.selected === false && role.assignedRoleId != null) {
                  // this is a deleted role
                  const roleToDelete = this.assignedRoles.find(r => r.assignedRoleId === role.assignedRoleId);
                  this.assignedRoles.remove(roleToDelete as AssignedRole);
                }
              }
            });
          }
        });
      }
    });
  }

  public async saveUserGroup() {
    this.isSaving = true;
    try {
      const response = await this.apiClient.userGroups.save(this.userGroup);

      this.userGroup.set(response.data);
      // update the base list (to add if new, and change name if existing)
      if (!this.currentUserGroupLookup.isNew) {
        // set the entity identifier so that it does not add a new one to the list
        response.data.entityIdentifier = this.currentUserGroupLookup.entityIdentifier;
      }
      this.userGroups.update([response.data]);

      if (this.currentUserGroupLookup.isNew) {
        // find the one that was just added
        this.currentUserGroupLookup = this.userGroups.find(
          ug => ug.userGroupId === this.userGroup.userGroupId
        ) as UserGroupLookup;
      }
    }
    finally {
      this.isSaving = false;
    }
  }

  public deleteUserGroup(item: UserGroupLookup) {
    ModalUtils.showYesNo(
      `Delete '${item.userGroupName}'?`,
      `Are you sure you want to delete user group '${item.userGroupName}'?`,
      () => this.taskRunner.run(async () => {
        this.isSaving = true;
        await this.apiClient.userGroups.delete(item.userGroupId);
        this.userGroups.remove(item);
        this.isSaving = false;

        NotifyUtils.addSuccess("User Group Deleted", `User group ${this.userGroup.userGroupName} has been deleted`, NotificationDuration.Standard);
      })
    );
  }
}
