/* tslint:disable:max-classes-per-file */
import React from 'react';
import { Routing, Utils } from '@singularsystems/neo-core';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Scrollbar from 'react-custom-scrollbars';
import { AppService, Types } from '../Services/AppService';
import AppLayout, { MenuState, ScreenSize } from '../Services/AppLayout';
import DBELogo from '../assets/img/DBE-logo1.jpg'
@observer
class Sidebar extends React.Component {

    private siteMode: string = "";
    private routeService = AppService.get(Types.App.Services.RouteService);

    private menuContainer: React.RefObject<HTMLDivElement>;
    private config = AppService.get(Types.App.Config);

    constructor(props: any) {
        super(props);

        this.menuContainer = React.createRef<HTMLDivElement>();
        this.hideMainMenu = this.hideMainMenu.bind(this);
    }

    public componentDidUpdate() {
        // Position the menu relative to the menu anchor
        if (this.menuContainer.current) {
            if (AppLayout.current.currentScreenSize === ScreenSize.Huge) {
                this.menuContainer.current.style.left = "0px";
            } else {
                this.menuContainer.current.style.left = document.getElementById("menu-anchor")!.getBoundingClientRect().left + "px";
            }
        }
    }

    private hideMainMenu() {
        AppLayout.current.menuVisible = true;
    }

    public render() {
        const layout = AppLayout.current;

        var env = this.config.SiteMode;

        if (env !== "") {
            if (env.toUpperCase().trim() !== 'DEV' && env.toUpperCase().trim() !== 'PRD') {
                if (env.toUpperCase().trim() === 'UAT') {
                    this.siteMode = "UAT";
                } else if (env.toUpperCase().trim() === 'QA') {
                    this.siteMode = "QA";
                }
            }
        }

        return (
            <div ref={this.menuContainer}
                className={"app-left-panel " + (layout.menuVisible ? "show" : "")} >
                {/* onMouseLeave={this.hideMainMenu} */}

                <div className="sidebar">
                    <div className="sidebar-header">
                        {/* style={{backgroundImage: "url(" ../ ")"}} */}
                        <div hidden={layout.menuState === MenuState.Collapsed} className="Logo" >
                            <img src={DBELogo} alt="logo" />
                        </div>
                    </div>
                    {
                    this.siteMode !== "" &&
                        <h2 className="site-mode">{this.siteMode} Mode</h2>
                    }
                    <div className="sidebar-content">
                        {/* Full screen menu */}
                        {layout.currentScreenSize === ScreenSize.Huge &&
                            <Scrollbar height="100%">
                                <Menu items={this.routeService.routes.menuRoutes} level={1} />
                            </Scrollbar>}

                        {/* Small screen menu */}
                        {layout.currentScreenSize !== ScreenSize.Huge &&
                            <Menu items=
                                {this.routeService.routes.menuRoutes} level={1} />}
                    </div>
                </div>
            </div>
        );
    }
}

interface IMenuItemProps {
    item: Routing.IMenuRoute,
    level: number
};

@observer
class Menu extends React.Component<{ items: Routing.IMenuRoute[], level: number }> {

    public render() {

        const menuItemProvider = AppService.get(Types.Neo.Routing.MenuItemProvider);
        const menuItems = menuItemProvider.processRoutes(this.props.items, { collapseSingle: true, hideWhenEmpty: true });

        return (
            <ul className={"menu-level-" + this.props.level}>
                {menuItems.map(item => <MenuItem key={item.path || item.name} item={item} level={this.props.level} />)}
            </ul>
        )
    }
}

@observer
class MenuItem extends React.Component<IMenuItemProps> {

    @observable.ref
    public isExpanded = false;

    constructor(props: IMenuItemProps) {
        super(props);

        if (props.item.children && props.item.expanded) {
            this.isExpanded = true;
        }

        this.onClick = this.onClick.bind(this);
        this.onClickExpand = this.onClickExpand.bind(this);
    }

    private onClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        if (this.props.item.children) {
            e.stopPropagation();

            this.isExpanded = !this.isExpanded;
        }
    }
    private onClickExpand() {
        if (this.props.item.children) {
            this.isExpanded = !this.isExpanded;
        }
    }

    public render() {
        const item = this.props.item;
        const hasChildren = !!item.children;
        const isMiniNav = AppLayout.current.menuState === MenuState.Collapsed;


        return (
            <li>
                <div onClick={this.onClick} className={"menu-item" + (hasChildren ? " has-children" : "")}>

                    {item.path ?
                        <NavLink className="" exact to={item.path}>{item.icon && <i data-tip={item.name} className={"fa-fw " + Utils.getFontAwesomeClass(item.icon)} />} <div className="responsive-hidden">{item.name}</div></NavLink> :
                        <span>{item.icon && <i data-tip={item.name} className={"fa-fw " + Utils.getFontAwesomeClass(item.icon)} />} <div className="responsive-hidden">{item.name}</div></span>
                    }

                    {hasChildren &&
                        <div className={(isMiniNav ? "mini-menu-expander " : "menu-expander ") + (this.isExpanded ? "expanded" : "collapsed")}>
                            <button onClick={this.onClickExpand}><i className={"fa fa-" + (this.isExpanded ? "caret-down" : "caret-right")} /></button>
                        </div>
                    }
                </div>

                {this.isExpanded && item.children && <Menu items={item.children} level={this.props.level + 1} />}
            </li>)
    }
}

export default Sidebar;