export enum Learning {
    Access = "Learning.Learning.Access",
    Create = "Learning.Learning.Create",
    Edit = "Learning.Learning.Edit",
    Remove = "Learning.Learning.Remove",
    View = "Learning.Learning.View",
    ContentCreator = "Learning.Learning.Content Creator",
    ChangeModeratedModules = "Learning.Learning.Change Moderated Modules",
    ChangeModeratedAssessment = "Learning.Learning.Change Moderated Assessment",
    ViewAllContent = "Learning.Learning.View All Content",
    SendEmailPathway = "Learning.Learning.Send Email Pathway",
    PathwayImportEmail = "Learning.Learning.Pathway Import Email"
}

export enum Tags {
    Access = "Learning.Tags.Access",
    Create = "Learning.Tags.Create",
    Edit = "Learning.Tags.Edit",
    Remove = "Learning.Tags.Remove",
    View = "Learning.Tags.View",
}