import { Attributes, NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class UserRoleSearchCriteria extends ValueObject {

    public roleName: string = "";

    @Attributes.Integer()
    public pageIndex: number = 0;

    public keyword: string = "";

    @Attributes.Integer()

    public pageSize: number = 10;
    // Client only properties / methods

    public toString(): string {
        return "User Role Search Criteria";
    }
}