import { ValidationDisplayMode } from "@singularsystems/neo-core/dist/Validation/Misc";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import LearningModuleLookup from "../Models/Lookups/LearningModuleLookup";
import LearningModuleVM from "./LearningModuleVM";
import "../../Organisations/Styles/Hierarcy.scss";
import { Data } from "@singularsystems/neo-core";
import LearningContentFileComponent from "../Components/LearningContentFileComponent";
import RichTextEditorComponent from "../Components/RichTextEditor";
import { LearningContentMediaType } from "../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import "../../App/Styles/Modules.scss";
import { QuizLearningObjectComponent } from "../Components/QuizLearningObjectComponent";
import VerticalDragAndDropComponent from "../Components/DragAndDrop/VerticalDragAndDropComponent";
import { ModerationState } from "../Models/LearningObjects/ModerationState.enum";
import RichTextEditor from "../Components/RichTextEditor";
import { truncateString, OpenLink } from '../../App/HelperClasses/GlobalHelpers';
import { LearningObjectType } from "../Models/LearningObjects/LearningObjectType.enum";
import PreviewLearningObjectView from "./ContentConsumption/PreviewLearningObjectView";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
import GridContainer from "../../Template/components/Grid/GridContainer";
import GridItem from "../../Template/components/Grid/GridItem";
import LearningObjectLookup from "../Models/Lookups/LearningObjectLookup";
import ForumDrawer from "../../Forum/Components/ForumComponents/ForumDrawer";
import FabButton from '../../Template/components/CustomButtons/FabButton';
class LearningModuleViewParams {
    public searchString = { required: false, isQuery: true };
}

export enum learningObjectTabs {
    dnd = "Learning Object Ordering",
    loDetails = "Learning Object Details"
}

export enum ModuleModerationState {
    Unknown = 1,
    Unmoderated = 2,
    Review = 5,
    Moderated = 6,
    ResubmittedForModeration = 7,
    Deactivated = 8
}

export enum LearningObjectQuizType {
    Quiz = 2,
    FinalQuiz = 4,
}

@observer
export default class LearningModuleView extends Views.ViewBase<LearningModuleVM, LearningModuleViewParams>{
    constructor(props: unknown) {
        super("LearningModules", LearningModuleVM, props);
        this.editorRef = React.createRef<RichTextEditorComponent>();
    }

    private editorRef: React.RefObject<RichTextEditorComponent>;
    public static params = new LearningModuleViewParams();

    protected viewParamsUpdated() {
        if (this.viewParams.searchString.value !== "") {
            if (this.viewModel.criteria !== null) {
                this.viewModel.criteria.keyword = (this.viewParams.searchString.value as string);
            }
        }
    }

    private GoToLearningObjectPreview = (id: number) => {
        var path = this.navigation.getPathForView(PreviewLearningObjectView, { objectId: id });
        OpenLink(path);
    }

    private yesAddContent() {
        this.viewModel.onAddMediaClick();
        this.viewModel.showAddContentModalForNewLearningObject = false;
    }

    private noAddContent() {
        this.viewModel.hideMediaUpload = true;
        this.viewModel.showAddContentModalForNewLearningObject = false;
        this.viewModel.hideLearningObjectCard = true;
        this.viewModel.selectedLearningObjectId = 0;
        this.viewModel.selectedLearningContentId = 0;
    }

    public render() {
        return (
            <div >
                {/* Module Search and pager */}
                <Card>
                    <CardHeader icon>
                        <CardIcon color="success">
                            <i className={`icon fa fa-chalkboard fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Modules</h4>
                        <GridContainer>
                            <GridItem md={4}>
                                <Neo.Button variant="success" isOutline icon="plus" onClick={() => this.viewModel.showEditModuleCard(0)} text="Add New Module" />
                                <Neo.Button icon="check" text={this.viewModel.showModerationStateFilter ? "Moderation States" : "Hide Moderation States"} variant="info" isOutline onClick={() => this.viewModel.showModerationStateFilter = !this.viewModel.showModerationStateFilter} />
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <div className="row mb-2" hidden={!this.viewModel.hideCriteria}>
                                <div className="col-md-10">
                                    <Neo.Button variant="info" isOutline icon="eye" onClick={() => { this.viewModel.showCriteria() }} >Show Search Criteria</Neo.Button>
                                </div>
                            </div>
                            <div hidden={this.viewModel.hideCriteria} onKeyPress={(event) => {
                                if (event.charCode === 13) {
                                    this.viewModel.searchModules();
                                    event.preventDefault();
                                }
                            }}>
                                <GridContainer>
                                    <GridItem md={12} sm={12} xs={12}>
                                        <div hidden={this.viewModel.showModerationStateFilter}>
                                            <Neo.GridLayout className="moderationStatesContainer ml-1">
                                                <Neo.Input label="Unknown" type="radio" bind={this.viewModel.criteria.meta.stateSelectedUnknown} />
                                                <Neo.Input label="Unmoderated" type="radio" bind={this.viewModel.criteria.meta.stateSelectedUnmoderated} />
                                                <Neo.Input label="Submitted For Moderation" type="radio" bind={this.viewModel.criteria.meta.stateSelectedSubmittedForModeration} />
                                                <Neo.Input label="Under Moderation" type="radio" bind={this.viewModel.criteria.meta.stateSelectedUnderModeration} />
                                                <Neo.Input label="Under Review" type="radio" bind={this.viewModel.criteria.meta.stateSelectedReview} />
                                                <Neo.Input label="Moderated" type="radio" bind={this.viewModel.criteria.meta.stateSelectedModerated} />
                                                <Neo.Input label="Resubmitted For Moderation" type="radio" bind={this.viewModel.criteria.meta.stateSelectedResubmittedForModeration} />
                                            </Neo.GridLayout>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Neo.Input label="My Modules" type="radio" disabled={!(this.viewModel.canViewAllContent || this.viewModel.isAdminGroup)}
                                            onClick={() => { this.viewModel.updateCriteriaCreatedBy() }} placeholder="Search for a Module" bind={this.viewModel.criteria.meta.filterByCreated} />
                                        <Neo.FormGroupFloating label="Title" placeholder="Search for a Module by Title" bind={this.viewModel.criteria.meta.keyword} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <label>Category Tag</label>
                                        <Neo.AutoCompleteDropDown
                                            bind={this.viewModel.criteria.meta.learningModuleTag1Id}
                                            bindDisplay={this.viewModel.criteria.meta.learningModuleTag1Name}
                                            displayMember="tagName"
                                            itemSource={this.viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                                            onItemSelected={(item) => { this.viewModel.filtertag2ListSearch(item?.tag1Id) }}
                                            loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                                            minCharacters={2}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Neo.FormGroup label="Sub-Category Tag (based on Category Tag)" bind={this.viewModel.criteria.meta.learningModuleTag2Id} select={{
                                            items: this.viewModel.learningModuleTag2FilteredList,
                                            allowNulls: true
                                        }} />
                                    </GridItem>
                                    <GridItem md={4}>
                                        <Neo.Button autoFocus className="mt-1" variant="info" isOutline text="Search Modules" icon="fa fa-search" onClick={() => { this.viewModel.searchModules() }} />
                                        <Neo.Button className="mt-1 ml-2" variant="warning" isOutline text="Clear Search" icon="fa fa-eraser" onClick={() => { this.viewModel.clearModuleSearchFilters() }} />
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <GridContainer>
                                <GridItem md={12}>
                                    <Neo.Pager
                                        pageManager={this.viewModel.LearningModuleLookupPageManager}
                                        pageControlProps={{
                                            firstText: "",
                                            prevText: "Prev",
                                            nextText: "Next",
                                            lastText: "",
                                            alignment: "center",
                                            autoHide: false,
                                            pageSizeLabel: "Show"
                                        }}>
                                        <NeoGrid.Grid<LearningModuleLookup> hidden={this.viewModel.hideLearningModuleGrid}>
                                            {(learningModule: LearningModuleLookup, learningModuleMeta) => (
                                                <NeoGrid.Row className={this.viewModel.selectedLearningModuleId === learningModule.learningModuleId ? "selected-item-css" : "bg-none"}>
                                                    <NeoGrid.Column label="Title" display={learningModuleMeta.learningModuleTitle} autoFocus={true} />
                                                    <NeoGrid.Column label="Learning Objective" display={learningModuleMeta.description} />
                                                    <NeoGrid.Column label="Created By" display={learningModuleMeta.createdByUserInformation} />
                                                    <NeoGrid.Column label="Created Date" display={learningModuleMeta.createdDate} />
                                                    <NeoGrid.Column display={learningModuleMeta.moderationState} select={{
                                                        itemSource: Data.StaticDataSource.fromEnum(ModerationState),
                                                        renderAsText: true
                                                    }} />
                                                    <NeoGrid.Column label="Moderated By" display={learningModuleMeta.moderatedByFullName} />
                                                    <NeoGrid.ButtonColumn>
                                                        <Neo.Button icon="check" text={"Select"} hidden={(this.viewModel.isAdminGroup) && (learningModule.moderationState === ModerationState.SubmittedForModeration || learningModule.moderationState === ModerationState.UnderModeration || learningModule.moderationState === ModerationState.Moderated)} isOutline variant="success" disabled={(learningModule.moderationState === ModerationState.SubmittedForModeration || learningModule.moderationState === ModerationState.UnderModeration || learningModule.moderationState === ModerationState.Moderated)}
                                                            onClick={() => this.viewModel.showEditModuleCard(learningModule.learningModuleId)} />
                                                        <div hidden={learningModule.moderationState !== ModerationState.Moderated}>
                                                            <Neo.Button icon="check" text={"Admin Edit"} isOutline variant="success" hidden={!this.viewModel.canChangeModeratedModules}
                                                                onClick={() => this.viewModel.showEditModuleCard(learningModule.learningModuleId)} />
                                                        </div>

                                                    </NeoGrid.ButtonColumn>
                                                    <NeoGrid.ButtonColumn>
                                                        <Neo.Button hidden={learningModule.moderationState !== ModerationState.Review} icon="eye" tooltip="Review Moderation Summary" isOutline variant="info" onClick={() => this.viewModel.CheckModerationState(learningModule.learningModuleId)} />
                                                        <Neo.Button hidden={learningModule.moderationState !== ModerationState.Unmoderated && learningModule.moderationState !== ModerationState.Unknown} text="Submit for Moderation"
                                                            isOutline variant="info" onClick={() => this.viewModel.submitForModeration(learningModule.learningModuleId)} />
                                                        <Neo.Button icon="info" hidden={learningModule.moderationState !== ModerationState.SubmittedForModeration} text="Submitted for Moderation"
                                                            isOutline variant="info" disabled={true} />
                                                        <Neo.Button icon="info" hidden={learningModule.moderationState !== ModerationState.UnderModeration && learningModule.moderationState !== ModerationState.ResubmittedForModeration} text="Under Moderation"
                                                            isOutline variant="info" disabled={true} />
                                                        <Neo.Button icon="info" hidden={learningModule.moderationState !== ModerationState.Moderated} text="Moderated"
                                                            isOutline variant="info" disabled={true} />
                                                        <Neo.Button icon="info" hidden={learningModule.moderationState !== ModerationState.Review} text="Resubmit for Moderation"
                                                            tooltip="submit module for review after addressing the notes" isOutline variant="info" onClick={() => this.viewModel.resubmitModuleForModeration(learningModule.learningModuleId)} />
                                                    </NeoGrid.ButtonColumn>
                                                    <NeoGrid.ButtonColumn>
                                                        <Neo.Button icon="trash" key="delete" variant="danger" onClick={() => this.viewModel.showDeleteLearningModule(learningModule, learningModule.learningModuleId)} />
                                                    </NeoGrid.ButtonColumn>
                                                </NeoGrid.Row>
                                            )}
                                        </NeoGrid.Grid>
                                    </Neo.Pager>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </CardBody>
                </Card>

                {/* Edit and And new Module card */}
                <Card hidden={this.viewModel.hideEditModuleCard}>
                    <CardHeader icon>
                        <CardIcon color="info">
                            <i className={`icon fa fa-chalkboard fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">{this.viewModel.selectedLearningModuleId !== 0 ? "Edit Module - " + this.viewModel.selectedLearningModule.title : "Add New Module"}</h4>    
                    </CardHeader>
                    <CardBody>
                        <Neo.Form model={this.viewModel.selectedLearningModule} validationDisplayMode={ValidationDisplayMode.Always}
                            showSummaryModal>
                            {(item, meta) => (
                                <div>
                                    <Neo.GridLayout>
                                        <Neo.FormGroupFloating label="Title*" bind={meta.title.value?.meta.value} autoFocus /><b />
                                        <Neo.FormGroupFloating label="CPTD Points*" bind={item.cptdPoints?.meta.value} autoFocus /><b />
                                        <Neo.FormGroupFloating input={{ rows: 2 }} label="Learning Objective*" bind={item.description?.meta.value} autoFocus />
                                        <Neo.Button hidden={this.viewModel.isAdminGroup === false} variant="danger" style={{float: "right"}} onClick={()=>this.viewModel.DeactivateModule(item.learningModuleId)} >
                                            Deactivate Module
                                        </Neo.Button>
                                    </Neo.GridLayout>

                                </div>
                            )}
                            
                        </Neo.Form>
                        <Neo.Button hidden={!this.viewModel.hideEditTagsCard} disabled={!this.viewModel.selectedLearningModule.isValid} variant="success" isOutline onClick={() => this.viewModel.showTagCards()}>
                            Add Module Tags
                        </Neo.Button>
                        {
                            this.viewModel.hideModuleNotes ||
                            <Neo.Card className="moderation-notes">
                                <RichTextEditor readOnly={true} bind={this.viewModel.selectedLearningModule.meta.moderationNotes} viewModel={this.viewModel.richTextEditorVmModule} labelText="Module Moderation Notes" />
                            </Neo.Card>
                        }
                        <Neo.Button hidden={this.viewModel.selectedLearningModule.moderationState !== ModerationState.Review} text={this.viewModel.hideModuleNotes ? "Module Moderation Notes" : "Hide Module Moderation Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.viewModel.hideModuleNotes = !this.viewModel.hideModuleNotes} />
                    </CardBody>
                </Card>

                {/* Module Tags */}
                <Card hidden={this.viewModel.hideEditTagsCard}>
                    <CardHeader icon>
                        <CardIcon color="rose">
                            <i className={`icon fa fa-tags fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">{this.viewModel.selectedLearningModuleId === 0 ? "Add Tags To Module" : "Module: " + this.viewModel.selectedLearningModule.title + " Tags"}</h4>
                    </CardHeader>
                    <CardBody>
                        <Neo.GridLayout hidden={this.viewModel.selectedLearningModule.learningModuleTags.length !== 0}>
                            <div className="invalid-feedback error mb-3" style={{ display: "block" }}><span>Tag 1 and Tag 2 Required*</span></div>
                        </Neo.GridLayout>
                        <NeoGrid.Grid className="mb-10" items={this.viewModel.selectedLearningModule.learningModuleTags} initialSort={"tag2Id"} showAddButton>
                            {(moduleTag, moduleTagMeta) => (
                                <NeoGrid.Row>
                                    <NeoGrid.Column formNoValidate={false} autoFocus width={800} size={10} disabled={!moduleTag.isNew && this.viewModel.selectedLearningModuleId !== 0} label="Category Tag">
                                        <Neo.AutoCompleteDropDown
                                            bind={moduleTagMeta.tag1Id}
                                            bindDisplay={moduleTagMeta.tag1Name}
                                            displayMember="tagName"
                                            itemSource={this.viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                                            onItemSelected={(item, values) => { this.viewModel.filtertag2List(moduleTag, item?.tag1Id) }}
                                            //   onItemSelected={(item) => { this.viewModel.filtertag2ListSearch(item?.tag1Id as number) }}
                                            loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                                            disabled={!moduleTag.isNew && this.viewModel.selectedLearningModuleId !== 0}
                                            minCharacters={2}
                                        />
                                    </NeoGrid.Column>
                                    <NeoGrid.Column width={800} size={10} disabled={!moduleTag.isNew && this.viewModel.selectedLearningModuleId !== 0} hidden={moduleTag.tag1Id === 0}
                                        label="Sub-Category Tag" bind={moduleTagMeta.tag2Id} autoFocus={false} select={{ items: moduleTag.filteredTag2List, onItemSelected: (item) => { this.viewModel.checkForDuplicateTag2s(item?.tag2Id as number) } }} />
                                    <NeoGrid.Column hidden={moduleTag.tag1Id !== 0}>
                                        <span>Select a Tag 1</span>
                                    </NeoGrid.Column>
                                    <NeoGrid.ButtonColumn>
                                        <Neo.Button icon="trash" variant="danger" onClick={() => this.viewModel.deleteLearningModuleTag2(moduleTag)} />
                                    </NeoGrid.ButtonColumn>
                                </NeoGrid.Row>
                            )}
                        </NeoGrid.Grid>
                        <Neo.Button onClick={() => this.viewModel.saveModule()} disabled={this.viewModel.canSaveModule()} className="mt-2 ml-2" icon="fa-plus" variant="success" isOutline hidden={this.viewModel.selectedLearningModuleId !== 0}>
                            Save Draft Module
                        </Neo.Button>
                        <Neo.Button onClick={() => this.viewModel.saveModule()} disabled={!this.viewModel.selectedLearningModule.isDirty} className="mt-2 mr-2" icon="fa-plus" variant="success" isOutline hidden={this.viewModel.selectedLearningModuleId === 0}>
                            Update Draft Module
                        </Neo.Button>
                    </CardBody>
                </Card>

                <div hidden={this.viewModel.selectedLearningModuleId === 0} >

                    <Neo.TabContainer selectedTab={this.viewModel.meta.selectedTab}>
                        <Neo.Tab header="Learning Object Details" icon="info">
                            <Card>
                                <CardBody>
                                    <div hidden={this.viewModel.learningObjectList.length !== 0}>
                                        <p>
                                            No learning objects linked to this Module.
                                        </p>
                                    </div>
                                    <NeoGrid.Grid className="mb-10" items={this.viewModel.learningObjectList} initialSort="order" hidden={this.viewModel.learningObjectList.length === 0}>
                                        {(learningObject, learningObjectsMeta) => (
                                            <NeoGrid.RowGroup expandProperty={learningObjectsMeta.isExpanded}>
                                                <NeoGrid.Row className={this.viewModel.selectedLearningObjectId === learningObject.learningObjectId ? "selected-item-css" : "bg-none"}>
                                                    <NeoGrid.Column label="Title" display={learningObjectsMeta.learningObjectTitle} />
                                                    <NeoGrid.Column label="Purpose" display={learningObjectsMeta.learningObjectPurpose} />
                                                    {/* <NeoGrid.Column label="order no" display={learningObjectsMeta.order} /> */}
                                                    <NeoGrid.Column label="Object Type" display={learningObjectsMeta.learningObjectTypeId} select={{
                                                        itemSource: Data.StaticDataSource.fromEnum(LearningObjectType),
                                                        renderAsText: true
                                                    }} />
                                                    <NeoGrid.Column label="Link To Resource" display={learningObjectsMeta.linkedToResource} />
                                                    <NeoGrid.ButtonColumn>
                                                        <Neo.Button icon="select" isOutline variant="success" onClick={() => this.viewModel.setSelectedObject(learningObject.learningObjectId)}>
                                                            Select
                                                        </Neo.Button>
                                                        <Neo.Button icon="trash" variant="danger" onClick={() => this.viewModel.deleteModuleLearningObjectPopUp(learningObjectsMeta.objectName.value, learningObject.learningObjectId, this.viewModel.selectedLearningModule.title?.value as string)} />
                                                    </NeoGrid.ButtonColumn>
                                                </NeoGrid.Row>
                                                <NeoGrid.ChildRow  >
                                                    {learningObject.learningObjectTypeId === LearningObjectType.Information &&
                                                        <NeoGrid.Grid items={learningObject.learningContent}>
                                                            {(content, contentMeta) => (
                                                                <NeoGrid.Row key={content.learningContent.title} className={this.viewModel.selectedLearningContentId === content.learningContentId ? "selected-item-css" : "bg-none"}>
                                                                    <NeoGrid.Column label="Title" display={content.learningContent.meta.title} />
                                                                    <NeoGrid.Column display={content.learningContent.mediaObject?.meta.fileName} />
                                                                    <NeoGrid.Column label="Media Type" display={content.meta.mediaTypeFetched}
                                                                        select={{
                                                                            itemSource: Data.StaticDataSource.fromEnum(LearningContentMediaType),
                                                                            renderAsText: true
                                                                        }}
                                                                    />
                                                                    <NeoGrid.Column label="File Type" display={content.learningContent.mediaObject?.meta.extension} />
                                                                    <NeoGrid.ButtonColumn label="Alternative Url" style={{ maxWidth: "10%" }}>
                                                                        <Neo.Button tooltip="Click to open link in new window" hidden={content.mediaTypeFetched === LearningContentMediaType.YouTube} text={truncateString(content.learningContent.externalResourceLocation as string)} variant="link" onClick={() => OpenLink(content.learningContent.externalResourceLocation as string)} />
                                                                        <Neo.Button tooltip="Click to open link in new window" hidden={content.mediaTypeFetched !== LearningContentMediaType.YouTube} text={truncateString(content.learningContent.externalResourceLocationKey as string)} variant="link" onClick={() => OpenLink(content.learningContent.externalResourceLocationKey as string)} />
                                                                    </NeoGrid.ButtonColumn>
                                                                    <NeoGrid.ButtonColumn>
                                                                        <Neo.Button isOutline disabled={this.viewModel.selectedLearningObjectId !== content.learningObjectId} variant="success" onClick={() => { this.viewModel.setSelectedContent(content) }} >
                                                                            Select
                                                                        </Neo.Button>
                                                                    </NeoGrid.ButtonColumn>
                                                                    <NeoGrid.ButtonColumn>
                                                                        <Neo.Button hidden={content.learningContent.mediaObject === null || content.learningContent.mediaObject.fileDescriptorId === null} text={"Download"} isOutline icon={content.learningContent.mediaObject?.icon} variant="info"
                                                                            onClick={() => this.viewModel.downloadFileGet(content.learningContent.mediaObject?.fileDescriptorId as string, content.mediaTypeFetched as number, content.learningContent.externalResourceLocation as string)} />
                                                                        <Neo.Button text="Open link" hidden={content.learningContent.mediaObject?.fileDescriptorId !== null} onClick={() => OpenLink(content.learningContent.externalResourceLocation)} />
                                                                        <Neo.Button hidden={content.mediaTypeFetched !== LearningContentMediaType.Link} text={"Open link"} isOutline icon="link" variant="info"
                                                                            onClick={() => OpenLink(content.learningContent.externalResourceLocation as string)} />
                                                                        <Neo.Button hidden={content.mediaTypeFetched !== LearningContentMediaType.YouTube} text={"Open Youtube"} isOutline icon="link" variant="info"
                                                                            onClick={() => OpenLink(content.learningContent.externalResourceLocationKey as string)} />
                                                                    </NeoGrid.ButtonColumn>
                                                                    <NeoGrid.ButtonColumn>
                                                                        <Neo.Button icon="trash" variant="danger" onClick={() => this.viewModel.deleteLCFromLO(content)} />
                                                                    </NeoGrid.ButtonColumn>
                                                                </NeoGrid.Row>
                                                            )}
                                                        </NeoGrid.Grid>
                                                    }
                                                </NeoGrid.ChildRow>
                                                {(learningObject.learningObjectTypeId === LearningObjectType.Quiz || learningObject.learningObjectTypeId === LearningObjectType.FinalQuiz) &&
                                                    <NeoGrid.ChildRow>
                                                        <NeoGrid.Grid items={learningObject.quizQuestions} initialSortAscending={true} initialSort={"questionOrder"}>
                                                            {(question, questionMeta) => (
                                                                <NeoGrid.Row className={this.viewModel.selectedQuestionId === question.questionId ? "selected-item-css" : "bg-none"}>
                                                                    <NeoGrid.Column label="Question Id" hidden={true} display={questionMeta.questionId} />
                                                                    <NeoGrid.Column label="Question" display={questionMeta.plainText} />
                                                                    <NeoGrid.Column label="Question Type" display={questionMeta.questionType} />
                                                                    <NeoGrid.Column label="Question Order" width="150px" display={questionMeta.questionOrder} />
                                                                    <NeoGrid.Column label="Link To Resource" width="150px" display={questionMeta.linkedToResource} />
                                                                    <NeoGrid.ButtonColumn>
                                                                        <Neo.Button isOutline disabled={this.viewModel.selectedLearningObjectId !== question.learningObjectId} variant="success" onClick={() => { this.viewModel.setSelectedQuestion(question) }} >
                                                                            Select
                                                                        </Neo.Button>
                                                                    </NeoGrid.ButtonColumn>
                                                                    <NeoGrid.ButtonColumn>
                                                                        <Neo.Button icon="trash" variant="danger" onClick={() => this.viewModel.deleteQuizQuestion(question, learningObject.learningObjectId)} />
                                                                    </NeoGrid.ButtonColumn>
                                                                </NeoGrid.Row>
                                                            )}
                                                        </NeoGrid.Grid>
                                                    </NeoGrid.ChildRow>
                                                }
                                            </NeoGrid.RowGroup>
                                        )}
                                    </NeoGrid.Grid>

                                    <Neo.Button onClick={() => this.viewModel.showLearningObjectCard()} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success">
                                        Create Learning Object
                                    </Neo.Button>
                                    <Neo.Button hidden={true} onClick={() => this.viewModel.showLearningObjectToAdd()} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success">
                                        Add Existing Learning Object
                                    </Neo.Button>
                                </CardBody>
                            </Card>
                        </Neo.Tab>
                        <Neo.Tab header="Learning Object Ordering" icon="ellipsis-h">
                            <Card>
                                <CardBody>
                                    <VerticalDragAndDropComponent items={this.viewModel.loDragAndDropList} setDNDItems={(items) => { this.viewModel.setDNDItems(items) }} />
                                    <Neo.Button onClick={() => this.viewModel.saveLoOrderChanges()} isOutline className="mt-2 mr-2" icon="fa-save" variant="info">
                                        Save Order Changes
                                    </Neo.Button>
                                    <Neo.Button onClick={() => this.viewModel.showLearningObjectCard()} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success">
                                        Create Learning Object
                                    </Neo.Button>
                                    <Neo.Button hidden={true} onClick={() => this.viewModel.showLearningObjectToAdd()} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success">
                                        Add Existing Learning Object
                                    </Neo.Button>
                                </CardBody>
                            </Card>
                        </Neo.Tab>
                    </Neo.TabContainer>

                </div>

                {/*Create Learning Object for Selected Module*/}
                <Card hidden={this.viewModel.hideLearningObjectCard}>
                    <CardHeader icon>
                        <CardIcon color="rose">
                            <i className={`icon fa fa-plus fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">{this.viewModel.selectedLearningObjectId === 0 ? "New Learning Object" : "Learning Object: " + this.viewModel.selectedLearningObject.objectName}</h4>
                    </CardHeader>
                    <CardBody>
                        <Neo.Form validationDisplayMode={ValidationDisplayMode.Always} allDisabled={!(this.viewModel.isAdminGroup) && this.viewModel.selectedLearningObject.moderationState === ModerationState.Moderated}>
                            <Neo.GridLayout className="mb-10" hidden={this.viewModel.selectedLearningObject.learningObjectId > 0 && this.viewModel.selectedLearningObject.learningObjectTypeId !== LearningObjectType.Information}>
                                <Neo.FormGroupFloating autoFocus={true} formNoValidate={false} bind={this.viewModel.selectedLearningObject.meta.learningObjectTypeId} readOnly={this.viewModel.selectedLearningObject.learningObjectId > 0 && this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information}
                                    select={{
                                        itemSource: Data.StaticDataSource.fromEnum(LearningObjectType),
                                        onItemSelected: (item) => {
                                            this.viewModel.setSelectedContentType(item?.id as number)
                                        }
                                    }}
                                /><br />
                            </Neo.GridLayout>
                            <Neo.GridLayout className="mb-10"
                                hidden={this.viewModel.selectedLearningObject.learningObjectId === 0 || (this.viewModel.selectedLearningObject.learningObjectId > 0 && this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information)}>
                                <Neo.FormGroupFloating autoFocus={true} formNoValidate={false} bind={this.viewModel.selectedLearningObject.meta.learningObjectTypeId}
                                    select={{
                                        itemSource: Data.StaticDataSource.fromEnum(LearningObjectQuizType),
                                        onItemSelected: (item) => {
                                            this.viewModel.setSelectedContentType(item?.id as number)
                                        }
                                    }}
                                    onBlur={() => this.viewModel.checkCreateUpdateLO()}
                                /><br />
                            </Neo.GridLayout>
                            <Neo.GridLayout className="mb-10">
                                <Neo.FormGroupFloating label="Title*" formNoValidate={false} input={{ rows: 2 }} bind={this.viewModel.selectedLearningObject.meta.learningObjectTitle} onBlur={() => this.viewModel.checkCreateUpdateLO()} /><br />
                                <Neo.FormGroupFloating label="Purpose*" formNoValidate={false} input={{ rows: 3 }} bind={this.viewModel.selectedLearningObject.meta.learningObjectPurpose} onBlur={() => this.viewModel.checkCreateUpdateLO()} />
                            </Neo.GridLayout>
                            {(this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Quiz || this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz) &&
                                <Neo.GridLayout className="mb-10">
                                    <Neo.FormGroupFloating label="Number Of Questions To Answer*" autoFocus bind={this.viewModel.selectedLearningObject.meta.numberOfQuestionsToAnswer} onBlur={() => this.viewModel.checkCreateUpdateLO()} /><br />
                                </Neo.GridLayout>
                            }

                            <Neo.FormGroup hidden={this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information || this.viewModel.selectedLearningObject.learningObjectTypeId === null} bind={this.viewModel.selectedLearningObject.meta.randomiseOrder} editorTooltip="Allow Questions to be randomised on consumption" />

                            {this.viewModel.hideLearningObjectCard ||
                                <RichTextEditorComponent htmlContent={this.viewModel.meta.htmlContent} bind={this.viewModel.selectedLearningObject.meta.learningObjectText} labelText={"Rich text field area*"} showCustomButtons onChange={() => {
                                    this.viewModel.selectedLearningObject.learningObjectTextHtml = this.viewModel.htmlContent;
                                }} readOnly={this.viewModel.selectedLearningObject.moderationState === ModerationState.Moderated && !this.viewModel.isAdminGroup}
                                    onBlur={() => this.viewModel.checkCreateUpdateLO()} />
                            }
                            {
                                (this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information) &&
                                <Neo.GridLayout hidden={this.viewModel.selectedLearningObject.listOfTag3s.length !== 0}>
                                    <div className="invalid-feedback error" style={{ display: "block" }}><span>Tag 3 Required*</span></div>
                                </Neo.GridLayout>
                            }
                            {
                                this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information &&
                                <NeoGrid.Grid required className="mb-10" items={this.viewModel.selectedLearningObject.listOfTag3s} initialSort={"tag3Id"} showAddButton >
                                    {(contentTag, contentTagMeta) => (
                                        <NeoGrid.Row onChange={async () => {
                                            await this.viewModel.checkCreateUpdateLO();
                                            await this.viewModel.setSelectedObject(this.viewModel.selectedLearningObject.learningObjectId);
                                        }}>
                                            <NeoGrid.Column label="Learning Content Tag 3*" bind={contentTagMeta.tag3Id}
                                                readOnly={!contentTag.isNew}
                                                select={{
                                                    items: this.viewModel.learningObjectFilteredTag3List,
                                                    displayMember: "tag3Name",
                                                    valueMember: "tag3Id",
                                                    onItemSelected: (item) => {
                                                        this.viewModel.checkForDuplicateTag3s(item?.tag3Id as number);
                                                    }
                                                }} />
                                            <NeoGrid.ButtonColumn showDelete onClick={() => this.viewModel.deleteT3FromLo(this.viewModel.selectedLearningObject.listOfTag3s, contentTag)} />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            }
                            {
                                this.viewModel.hideObjectNotes ||
                                <Neo.Card className="moderation-notes">
                                    <RichTextEditor readOnly={true} bind={this.viewModel.selectedLearningObject.meta.moderationNotes} viewModel={this.viewModel.richTextEditorVmLearningObject} labelText="Learning Object Moderation Notes" />
                                </Neo.Card>
                            }
                            {/* Update Learning Object */}
                            <Neo.Button onClick={() => this.viewModel.updateLearningObject(false)} hidden={this.viewModel.selectedLearningObjectId === 0 || this.viewModel.selectedLearningObject.moderationState === ModerationState.Moderated} isOutline className="mt-2 mr-2" icon="fa-save" variant="success">
                                {`Update Draft ${this.viewModel.selectedLearningObject.objectName} `}
                            </Neo.Button>
                            {/* Create Learning Object */}
                            <Neo.Button onClick={() => this.viewModel.createNewLO(false)} hidden={this.viewModel.selectedLearningObjectId !== 0}
                                disabled={!this.viewModel.canSaveLO()}
                                isOutline className="mt-2 mr-2" icon="fa-save" variant="success">
                                Update Draft LO
                            </Neo.Button>
                            <div style={{ float: this.viewModel.selectedLearningObjectId === 0 && this.viewModel.hideMediaUpload ? "left" : "none", display: "inline" }}>
                                <Neo.Button onClick={() => this.viewModel.onAddMediaClick()}
                                    disabled={(this.viewModel.selectedLearningObjectId === 0 && this.viewModel.hideMediaUpload)
                                        || (this.viewModel.selectedLearningObject.moderationState === ModerationState.Moderated && !this.viewModel.canChangeModeratedModules)}
                                    hidden={this.viewModel.selectedLearningObject.learningObjectTypeId !== LearningObjectType.Information
                                        && this.viewModel.selectedLearningContentId > 0}
                                    isOutline
                                    className="mt-2 mr-2"
                                    icon="fa-save" variant="info">
                                    Add Media
                                </Neo.Button>
                            </div>
                            <Neo.Button onClick={() => this.viewModel.showQuizCard()} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success"
                                hidden={this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information
                                    || (this.viewModel.selectedLearningObject.learningObjectTypeId !== LearningObjectType.Information && this.viewModel.selectedLearningObjectId === 0)}>
                                Add Question
                            </Neo.Button>
                            {/* Add new Learning Object */}
                            <Neo.Button onClick={() => this.viewModel.showLearningObjectCard()} hidden={this.viewModel.canCreateNewLearningObject} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success">
                                New Learning Object
                            </Neo.Button>
                            <Neo.Button hidden={true} onClick={() => this.viewModel.showLearningObjectToAdd()} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success">
                                Add Existing Learning Object
                            </Neo.Button>
                            <Neo.Button hidden={this.viewModel.selectedLearningModule.moderationState !== ModerationState.Review}
                                text={this.viewModel.hideObjectNotes ? "Learning Object Moderation Notes" : "Hide Learning Object Moderation Notes"}
                                isOutline
                                variant="primary"
                                icon="sticky-note"
                                className="mt-2 ml-2"
                                onClick={() => this.viewModel.hideObjectNotes = !this.viewModel.hideObjectNotes} />
                        </Neo.Form>
                    </CardBody>
                </Card>

                {<section>
                    {/* Media Upload for object Component */}
                    {
                        (this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information || this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Quiz || this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz) &&
                        <LearningContentFileComponent allowAdminEdit={this.viewModel.canChangeModeratedModules} learningModuleVM={this.viewModel} learningContent={this.viewModel.newLearningContent} viewModel={this.viewModel.LearningContentFileComponentVM} hidden={this.viewModel.hideMediaUpload} />
                    }
                </section>}

                <section>
                    {/* Quiz Learning Object component */}
                    {(this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Quiz || this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.FinalQuiz) &&
                        <QuizLearningObjectComponent learningModuleVM={this.viewModel}
                            quizLearningObject={this.viewModel.quizLearningObject}
                            viewModel={this.viewModel.QuizLearningObjectComponentVM}
                            hidden={!this.viewModel.hideQuizCard}
                            selectedLearningObject={this.viewModel.selectedLearningObject}
                            learningContent={this.viewModel.newQuestionLearningContent} />
                    }
                </section>
                <section>
                    <Neo.Card title={"Learning Object: " + this.viewModel.selectedLearningObject.objectName + " Question Drag and Drop"} icon="fa-ellipsis-h" hidden={this.viewModel.hideQuestionDragAndDrop}>
                        <VerticalDragAndDropComponent items={this.viewModel.questionDragAndDropList} setDNDItems={(items) => { this.viewModel.setQuestionDNDItems(items) }} />
                        <Neo.Button onClick={() => this.viewModel.saveQuestionOrderChanges()} isOutline className="mt-2 mr-2" icon="fa-save" variant="info">
                            Save Order Changes
                        </Neo.Button>
                    </Neo.Card>
                </section>

                <ForumDrawer show={this.viewModel.showModuleReviewSummaryModal} onClose={() => this.viewModel.showModuleReviewSummaryModal = false} isMaximised={this.viewModel.reviewIsMaximised}
                    maximiseWindow={() => this.viewModel.reviewIsMaximised = !this.viewModel.reviewIsMaximised}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="warning">
                                <i className={`fa fa-info fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Review Summary for: {this.viewModel.summaryLookup.moduleTitle}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem md={12}>
                                    {/* The review summary for module */}
                                    <Card hidden={this.viewModel.summaryLookup.moduleIsComplete}>
                                        <CardHeader icon>
                                            <CardIcon color="danger">
                                                <i className={`fa fa-clipboard fa-2x`}></i>
                                            </CardIcon>
                                            <h4 className="Card-icon-header-text"> {this.viewModel.summaryLookup.moduleTitle}</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <h4>The Following properties are not approved on your module:</h4>
                                            <ul>
                                                {this.viewModel.summaryLookup.moduleTitleApproved || <li style={{ fontSize: "20px" }}>Title</li>}
                                                {this.viewModel.summaryLookup.moduleCPTDPointsApproved || <li style={{ fontSize: "20px" }}>CPTD Points</li>}
                                                {this.viewModel.summaryLookup.moduleLearningObjectiveApproved || <li style={{ fontSize: "20px" }}>Learning objective</li>}
                                            </ul>
                                            <div hidden={this.viewModel.summaryLookup.moduleNotes.length === 0}>
                                                <RichTextEditor key="Module notes" bind={this.viewModel.summaryLookup.meta.moduleNotes} readOnly={true} labelText="Module notes" />
                                            </div>
                                            {/* Module Tags */}
                                            <GridItem md={12}>
                                                <Card hidden={this.viewModel.summaryLookup.allTagsApproved}>
                                                    <CardHeader icon>
                                                        <CardIcon color="danger">
                                                            <i className={`fa fa-tags fa-2x`}></i>
                                                        </CardIcon>
                                                        <h4 className="Card-icon-header-text">Review Module Tags</h4>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <h4>The Following tags are not approved on your module:</h4>
                                                        <ul>
                                                            {
                                                                this.viewModel.summaryLookup.moduleTags.map((tag) => (
                                                                    <li key={tag.tag1Id} style={{ fontSize: "20px" }} hidden={tag.approved}>{tag.errorMessage} </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                {/* Module Objects */}
                                <GridItem md={12}>
                                    {
                                        <Card hidden={this.viewModel.summaryLookup.allObjectsApproved}>
                                            <CardHeader icon>
                                                <CardIcon color="primary">
                                                    <i className={`fa fa-info fa-2x`}></i>
                                                </CardIcon>
                                                <h4 className="Card-icon-header-text">Review Learning Objects for Module</h4>
                                            </CardHeader>
                                            <CardBody>
                                                {
                                                    this.viewModel.summaryLookup.objectList.map((object) => (
                                                        <Card hidden={object.objectIsCompleted} key={object.objectId}>
                                                            <CardHeader icon>
                                                                <CardIcon color="warning">
                                                                    <i className={`fa fa-eye fa-2x`} />
                                                                </CardIcon>
                                                                <h4 className="Card-icon-header-text">{object.title}</h4>
                                                            </CardHeader>
                                                            <CardBody> {(object.titleApproved && object.purposeApproved && object.richTextApproved) || <h4>The Following properties are not approved on this Object:</h4>}
                                                                <ul>
                                                                    {object.titleApproved || <li style={{ fontSize: "20px" }}>Title</li>}
                                                                    {object.purposeApproved || <li style={{ fontSize: "20px" }}>Purpose</li>}
                                                                    {object.richTextApproved || <li style={{ fontSize: "20px" }}>Object Rich Text</li>}
                                                                </ul>
                                                                <div hidden={object.objectNotes.length === 0}>
                                                                    <RichTextEditor bind={object.meta.objectNotes} readOnly={true} labelText="Object Notes" />
                                                                </div>

                                                                <GridItem md={12}>
                                                                    <Card hidden={object.allObjectTagsApproved}>
                                                                        <CardHeader icon>
                                                                            <CardIcon color="danger">
                                                                                <i className={`fa fa-tags fa-2x`}></i>
                                                                            </CardIcon>
                                                                            <h4 className="Card-icon-header-text">Review Object Tags</h4>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <h4>The following tags are not approved on your learning object:</h4>
                                                                            <ul>
                                                                                {
                                                                                    object.objectTags.map((tag3) => (
                                                                                        <li key={tag3.tag3Id} style={{ fontSize: "20px" }} hidden={tag3.approved}>{tag3.errorMessage} </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        </CardBody>
                                                                    </Card>
                                                                </GridItem>

                                                                <GridItem md={12}>
                                                                    <div hidden={object.allContentApproved}>
                                                                        <Card hidden={object.objectTypeId !== LearningObjectType.Information}>
                                                                            <CardHeader icon>
                                                                                <CardIcon color="danger">
                                                                                    <i className={`fa fa-tags fa-2x`}></i>
                                                                                </CardIcon>
                                                                                <h4 className="Card-icon-header-text">Review Learning Object Content</h4>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <h4>The following content is not approved on your learning object:</h4>
                                                                                <ul>
                                                                                    {
                                                                                        object.contentList.map((content) => (
                                                                                            <div key={content.contentId}>
                                                                                                <div hidden={content.contentNotes.length === 0}>
                                                                                                    <RichTextEditor bind={content.meta.contentNotes} readOnly={true} labelText="Content Notes" />
                                                                                                </div>
                                                                                                <div>
                                                                                                    <li key={content.contentId} style={{ fontSize: "20px" }} hidden={content.approved}>{content.contentName} </li>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </div>
                                                                </GridItem>
                                                                <GridItem md={12}>
                                                                    <Card hidden={object.objectTypeId === LearningObjectType.Information}>
                                                                        <CardHeader icon>
                                                                            <CardIcon color="danger">
                                                                                <i className={`fa fa-tags fa-2x`}></i>
                                                                            </CardIcon>
                                                                            <h4 className="Card-icon-header-text">Review Learning Object Quiz Content</h4>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <h4>The following content is not approved on your learning object: </h4>
                                                                            <ul>
                                                                                {
                                                                                    object.quizContent === null ||
                                                                                    <div key={object.quizContent.contentId}>
                                                                                        <div hidden={object.quizContent.contentNotes.length === 0}>
                                                                                            <RichTextEditor bind={object.quizContent.meta.contentNotes} readOnly={true} labelText="Content Notes" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <li key={object.quizContent.contentId} style={{ fontSize: "20px" }} hidden={object.quizContent.approved}>{object.quizContent.contentName} </li>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </ul>
                                                                        </CardBody>
                                                                    </Card>
                                                                </GridItem>
                                                                <GridItem md={12}>
                                                                    <Card hidden={object.objectTypeId === LearningObjectType.Information} >
                                                                        <CardHeader icon>
                                                                            <CardIcon color="danger">
                                                                                <i className={`fa fa-tags fa-2x`}></i>
                                                                            </CardIcon>
                                                                            <h4 className="Card-icon-header-text">Review Questions</h4>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <h4>The Following Questions are not approved on your quiz:</h4>
                                                                            <ul>
                                                                                {
                                                                                    object.questionList.map((questions) => (
                                                                                        <div key={questions.questionId}>
                                                                                            <div hidden={questions.questionNotes.length === 0}>
                                                                                                <RichTextEditor bind={questions.meta.questionNotes} readOnly={true} labelText="Question Notes" />
                                                                                            </div>
                                                                                            <li key={questions.questionId} style={{ fontSize: "20px" }} hidden={questions.approved && !questions.questionContentApproved}>
                                                                                                <RichTextEditor bind={questions.meta.question} readOnly={true} />
                                                                                            </li>
                                                                                            <li hidden={!questions.hasContent}>
                                                                                                <h4>The Content on the this question is not approved </h4>
                                                                                                <p>Content Title:  {questions.questionContent.contentName}</p>
                                                                                            </li>
                                                                                            <div hidden={questions.questionContent.meta.contentNotes.value === ""}>
                                                                                                <RichTextEditor bind={questions.questionContent.meta.contentNotes} readOnly={true} labelText="Question content Notes" />
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </ul>

                                                                        </CardBody>
                                                                    </Card>
                                                                </GridItem>
                                                            </CardBody>
                                                        </Card>
                                                    ))
                                                }
                                            </CardBody>
                                        </Card>
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </ForumDrawer>

                <Neo.Modal title="Add Content" size="lg"
                    show={this.viewModel.showAddContentModalForNewLearningObject && this.viewModel.selectedLearningObject.learningObjectTypeId === LearningObjectType.Information}
                    onClose={() => this.noAddContent()}
                    acceptButton={{
                        text: "Yes", variant: "info", icon: "save",
                        onClick: () => { this.yesAddContent() }
                    }} closeButton={{
                        text: "No", variant: "secondary", icon: "times",
                    }} >
                    <Neo.Loader task={this.viewModel.taskRunner}>
                        <div>
                            <p>Do you wish to add a media item? </p>
                        </div>
                    </Neo.Loader>
                </Neo.Modal>

                <ForumDrawer show={this.viewModel.showAddObjectToModule} isMaximised={this.viewModel.isMaximised} onClose={() => this.viewModel.showAddObjectToModule = false} maximiseWindow={() => this.viewModel.isMaximised = !this.viewModel.isMaximised}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="info">
                                <i className={`icon fa fa-search fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Add Objects</h4>
                        </CardHeader>
                    </Card>
                    <CardBody>
                        <GridContainer>
                            <div onKeyPress={(event) => {
                                if (event.charCode === 13) {
                                    this.viewModel.searchObjects();
                                    event.preventDefault();
                                }
                            }} style={{ width: "100%" }}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Neo.FormGroupFloating label="Search by title" bind={this.viewModel.learningObjectSearchCriteria.meta.Title} placeholder="Search by title" />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Neo.FormGroupFloating label="Learning Content Tag 3*" bind={this.viewModel.learningObjectSearchCriteria.meta.tag3Id} select={{
                                        items: this.viewModel.learningObjectFilteredTag3List,
                                        displayMember: "tag3Name",
                                        valueMember: "tag3Id"
                                    }} />
                                </GridItem>
                                <GridItem xs={2} sm={2} md={4}>
                                    <Neo.Button isOutline variant="info" text="Search" icon="search" onClick={() => this.viewModel.searchObjects()} />
                                    <Neo.Button text="Clear Filters" variant="warning" isOutline icon="eraser" onClick={() => this.viewModel.clearObjectsFilters()} />
                                </GridItem>
                            </div>
                            <GridItem xs={12} sm={12} md={12}>
                                <Neo.Pager
                                    pageManager={this.viewModel.LearningObjectLookupPageManager}
                                    pageControlProps={{
                                        firstText: "",
                                        prevText: "Prev",
                                        nextText: "Next",
                                        lastText: "",
                                        alignment: "center",
                                        autoHide: false,
                                        pageSizeLabel: "Show"
                                    }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <NeoGrid.Grid<LearningObjectLookup>>
                                                {(learningObject, learningObjectsMeta) => (
                                                    <NeoGrid.RowGroup>
                                                        <NeoGrid.Row>
                                                            <NeoGrid.Column label="Title" display={learningObjectsMeta.learningObjectTitle} />
                                                            <NeoGrid.Column label="Purpose" display={learningObjectsMeta.learningObjectPurpose} />
                                                            <NeoGrid.Column label="Object Type" display={learningObjectsMeta.learningObjectTypeId} select={{
                                                                itemSource: Data.StaticDataSource.fromEnum(LearningObjectType),
                                                                renderAsText: true
                                                            }} />
                                                            <NeoGrid.Column label="Link To Resource" display={learningObjectsMeta.linkedToResource} />
                                                            <NeoGrid.ButtonColumn label="Actions" >
                                                                <Neo.Button variant="info" isOutline icon="eye" tooltip="Preview" onClick={() => this.GoToLearningObjectPreview(learningObject.learningObjectId)} />
                                                                <Neo.Button variant="success" isOutline icon="plus" tooltip="Add"
                                                                    onClick={() => { this.viewModel.addObjectToModule(this.viewModel.selectedLearningModuleId, learningObject) }} />
                                                            </NeoGrid.ButtonColumn>
                                                        </NeoGrid.Row>
                                                    </NeoGrid.RowGroup>
                                                )}
                                            </NeoGrid.Grid>
                                        </div>
                                    </div>
                                </Neo.Pager>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    {/* </Neo.Modal> */}
                </ForumDrawer>

                <FabButton icon="eye" tooltip="Click to view moderation summary" hidden={this.viewModel.selectedLearningModuleId === 0 || this.viewModel.selectedLearningModule.moderationState !== ModerationState.Review} onClick={() => this.viewModel.CheckModerationState(this.viewModel.selectedLearningModuleId)} />
            </div >
        )
    }
}