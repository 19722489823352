/// This is a work in progress the current question components is using vms from all over 

import React from "react";
import { observer } from 'mobx-react';
import { Data, } from "@singularsystems/neo-core";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
import RichTextEditor from "./RichTextEditor";
import SelfAssessmentQuestionLookup from "../Models/Lookups/SelfAssessmentQuestionLookup";
import { QuestionType } from "../Views/Assessment/SelfAssessmentQuestionComponent";
import GridContainer from "../../Template/components/Grid/GridContainer";
import GridItem from "../../Template/components/Grid/GridItem";
import CardFooter from "../../Template/components/Card/CardFooter";
import { ValidationDisplayMode } from "@singularsystems/neo-core/dist/Validation/Misc";
import AssessmentQuestionVM from './AssessmentQuestionVM';
import { Sentiment } from "../Models/Questions/Sentiment.enum";
import { LearningContentMediaType } from "../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import MediaObject from "../Models/LearningObjects/LearningContent/MediaObject";
import FileDescriptor from "../Models/Files/FileDescriptor";

interface IAssessmentQuestion {
  assessmentId: number;
  questionId: number;
  hidden: boolean;
  saveQuestion: (question: SelfAssessmentQuestionLookup) => void;
  disabled: boolean;
}

@observer
export default class AssessmentQuestion extends React.Component<IAssessmentQuestion> {
  private viewModel = new AssessmentQuestionVM();
  constructor(props: IAssessmentQuestion) {
    super(props)
    this.viewModel = new AssessmentQuestionVM();
    this.viewModel.GetAssessment(props.assessmentId, props.questionId);
  }

  async componentDidUpdate(prevProps: IAssessmentQuestion) {
    if (prevProps.questionId !== this.props.questionId) {
      this.viewModel.GetAssessment(this.props.assessmentId, this.props.questionId);
    }
  }

  private afterUpload(fileDescriptor: FileDescriptor) {
    this.viewModel.taskRunner.run("Importing Files", async () => {
      var mediaObject = new MediaObject();
      mediaObject.fileDescriptorId = fileDescriptor.fileDescriptorId;
      mediaObject.fileName = fileDescriptor.fileName;
      this.viewModel.selfAssessmentQuestion.newLearningContent.learningContent.mediaObject = mediaObject;
      this.viewModel.selfAssessmentQuestion.newLearningContent.learningContent.learningObjectId = 0
      this.viewModel.selfAssessmentQuestion.newLearningContent.learningContent.mediaType = this.viewModel.selfAssessmentQuestion.newLearningContent.mediaTypeFetched;
      this.viewModel.getFileLookup(fileDescriptor.fileDescriptorId, this.viewModel.selfAssessmentQuestion.newLearningContent.learningContent.mediaType);
      this.viewModel.notifications.addSuccess("File has been uploaded", "You can now save your linked resource", 4);
    })
  }

  render() {
    const viewModel = this.viewModel;
    const props = this.props;
    return (
      <>
        <div hidden={props.hidden}>
          <Card>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`fas fa-question fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text"> {props.questionId === 0 ? 'New Question' : "Update Question"}  </h4>
            </CardHeader>
            <CardBody>
              <GridContainer >
                <Neo.Form validationDisplayMode={ValidationDisplayMode.Always}>
                  <GridItem md={12} sm={12} xs={12}>
                    {viewModel.hideRichText || <RichTextEditor bind={viewModel.selfAssessmentQuestion.meta.text}
                      labelText={"Question"} readOnly={props.disabled} />
                    }
                  </GridItem>

                  <GridItem md={5} sm={12} xs={12}>
                    <Neo.FormGroupFloating bind={viewModel.selfAssessmentQuestion.meta.questionTypeId}
                      select={{
                        itemSource: Data.StaticDataSource.fromEnum(QuestionType),
                      }} disabled={true} />
                  </GridItem>

                  <GridItem md={5} sm={12} xs={12}>
                    <Neo.FormGroupFloating hidden={viewModel.selfAssessmentQuestion.questionTypeId === QuestionType.MultipleChoice}
                      bind={viewModel.selfAssessmentQuestion.meta.sentiment}
                      select={{
                        itemSource: Data.StaticDataSource.fromEnum(Sentiment),
                      }} disabled={true} />
                  </GridItem>

                  <GridItem md={5} sm={12} xs={12}>
                    <Neo.FormGroupFloating
                      hidden={viewModel.selfAssessmentQuestion.questionTypeId === QuestionType.MultipleChoice}
                      bind={viewModel.selfAssessmentQuestion.meta.numberOfScales}
                      disabled={props.disabled} />
                  </GridItem>

                  <GridItem md={5} sm={12} xs={12}>
                    <Neo.FormGroupFloating label="Topic (Tag 3)*" bind={viewModel.selfAssessmentQuestion.meta.tag3Id}
                      select={{
                        items: viewModel.topicList,
                        displayMember: "tag3Name",
                        valueMember: "tag3Id",
                        allowNulls: false
                      }} disabled={props.disabled} />
                  </GridItem>

                  <GridItem md={8}>
                    {
                      (viewModel.selfAssessment.questionTypeId === (QuestionType.LikertScale as number)) &&

                      <NeoGrid.Grid className="mb-20 mt-20" items={viewModel.selfAssessmentQuestion.selfAssessmentOptionList}>
                        {(option, OptionMeta) => (
                          <NeoGrid.Row>

                            <NeoGrid.Column disabled={true} label="Option" width="400px" placeholder="Option" bind={OptionMeta.option} />
                            <NeoGrid.Column bind={OptionMeta.points} label='Weighting' width="150px" disabled={true} />
                            <NeoGrid.ButtonColumn disabled={true} showDelete hidden={true} />
                          </NeoGrid.Row>
                        )}
                      </NeoGrid.Grid>
                    }
                  </GridItem>

                  <GridItem md={8}>
                    {
                      (viewModel.selfAssessment.questionTypeId === (QuestionType.MultipleChoice as number)) &&

                      <NeoGrid.Grid showAddButton className="mb-20 mt-20" items={viewModel.selfAssessmentQuestion.selfAssessmentOptionList}>
                        {(option, OptionMeta) => (
                          <NeoGrid.Row>
                            <NeoGrid.Column label="Option" width="400px" placeholder="Option" bind={OptionMeta.option} disabled={option.answer || props.disabled} />
                            <NeoGrid.Column bind={OptionMeta.points} label='Weighting' width="150px" disabled={option.answer || props.disabled} />
                            <NeoGrid.Column bind={OptionMeta.answer} disabled={props.disabled} label='Answer' width="150px" onChange={() => {
                              viewModel.selfAssessmentQuestion.selfAssessmentOptionList.forEach((element) => {
                                element.answer = false;
                                element.points = 1
                              });
                              option.answer = !option.answer;
                              option.points = 0;
                            }} />
                            <NeoGrid.ButtonColumn showDelete disabled={props.disabled} />
                          </NeoGrid.Row>
                        )}
                      </NeoGrid.Grid>
                    }
                  </GridItem>
                  <GridItem md={12}>
                    {
                      viewModel.hideQuestionNotes ||
                      <Neo.Card className="moderation-notes" hidden={viewModel.selfAssessmentQuestion.selfAssessmentQuestionModerationNotes.length === 0}>
                        <RichTextEditor bind={viewModel.selfAssessmentQuestion.meta.selfAssessmentQuestionModerationNotes} labelText="Moderation Notes" readOnly={true} />
                      </Neo.Card>
                    }
                  </GridItem>
                </Neo.Form>

              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem md={12}>
                  <Neo.Button disabled={viewModel.selfAssessmentQuestion.tag3Id === 0
                    || viewModel.selfAssessmentQuestion.selfAssessmentOptionList.length === 0
                    || viewModel.selfAssessmentQuestion.selfAssessmentOptionList.filter(c => c.option.length === 0).length > 0
                    || (viewModel.selfAssessmentQuestion.selfAssessmentOptionList.sum(c => c.points) === 0
                      && this.viewModel.selfAssessmentQuestion.questionTypeId === QuestionType.MultipleChoice)
                    || viewModel.selfAssessmentQuestion.text.length === 0
                    || (viewModel.selfAssessmentQuestion.selfAssessmentOptionList.filter(c => c.answer === true).length === 0
                      && this.viewModel.selfAssessmentQuestion.questionTypeId === QuestionType.MultipleChoice)
                    || props.disabled}
                    onClick={() => props.saveQuestion(viewModel.selfAssessmentQuestion)}
                    isOutline
                    className="mt-2 mr-2"
                    icon="fa-save"
                    variant="success"
                    text={props.questionId === 0 ? "Save Question" : "Update Question"} />

                  <Neo.Button
                    text="Add Linked Resource"
                    disabled={props.disabled}
                    icon="file" variant="info"
                    hidden={viewModel.selfAssessmentQuestion.selfAssessmentQuestionId === 0 || !viewModel.hideContent}
                    onClick={() => viewModel.AddContent()} />
                </GridItem>
              </GridContainer>
            </CardFooter>
            {viewModel.hideContent || <Card >
              <CardHeader icon>
                <CardIcon color="success">
                  <i className={`fas fa-file fa-2x`} />
                </CardIcon>
                <h4 className="Card-icon-header-text">Question Linked Resource</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem md={6}>
                    <Neo.FormGroupFloating disabled={props.disabled} bind={viewModel.selfAssessmentQuestion.newLearningContent.learningContent.meta.title} />
                  </GridItem>
                  <GridItem md={6}>
                    <Neo.FormGroupFloating
                      bind={viewModel.selfAssessmentQuestion.newLearningContent.meta.mediaTypeFetched}
                      disabled={viewModel.selfAssessmentQuestion.contentId > 0 || props.disabled}
                      label="Media Type"
                      select={{
                        itemSource: Data.StaticDataSource.fromEnum(LearningContentMediaType),
                        onItemSelected: (item) => {
                          viewModel.setAllowedExtensions(viewModel.selfAssessmentQuestion.newLearningContent.mediaTypeFetched)
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem md={6}>
                    <Neo.FormGroupFloating label="Alternative URL" placeholder="www.urlExample.com"
                      bind={viewModel.selfAssessmentQuestion.newLearningContent.learningContent.meta.externalResourceLocation}
                      disabled={props.disabled}
                      hidden={viewModel.selfAssessmentQuestion.newLearningContent.learningContent.mediaType === LearningContentMediaType.YouTube} />
                  </GridItem>

                  {viewModel.selfAssessmentQuestion.newLearningContent.mediaTypeFetched !== LearningContentMediaType.YouTube || <GridItem md={6} >
                    <Neo.FormGroupFloating label="URL Key" disabled={props.disabled}
                      bind={viewModel.selfAssessmentQuestion.newLearningContent.learningContent.meta.externalResourceLocationKey}
                    />
                  </GridItem>}

                  <GridItem md={6}>
                    <Neo.FormGroupFloating disabled={props.disabled} hidden={viewModel.allowedExtensions.length === 0} display={viewModel.meta.allowedExtensions} />
                  </GridItem>

                  <GridItem md={12}>
                    <Neo.FileContext
                      uploadEndpoint={viewModel.selfAssessmentQuestion.newLearningContent.mediaTypeFetched === LearningContentMediaType.Video ? viewModel.learningModuleApiClient.getUploadBigVideoFileToBlobUrl()
                        : (viewModel.selfAssessmentQuestion.newLearningContent.mediaTypeFetched === LearningContentMediaType.SCORM ? viewModel.learningModuleApiClient.getUploadBigSCORMFileToBlobUrl()
                          : viewModel.learningModuleApiClient.getUploadFileToBlobUrl(viewModel.selfAssessmentQuestion.newLearningContent.mediaTypeFetched as number))}
                      afterUpload={this.afterUpload.bind(this)}
                      allowedExtensions={viewModel.allowedExtensions}
                    >
                      <Neo.FileDropArea className="mb-10" hidden={viewModel.selfAssessmentQuestion.newLearningContent.learningContent.mediaType === LearningContentMediaType.Link
                        || viewModel.selfAssessmentQuestion.newLearningContent.learningContent.mediaType === LearningContentMediaType.YouTube
                        || viewModel.fileDescriptorList.length !== 0
                        || viewModel.selfAssessmentQuestion.contentId > 0}>
                        <div hidden={viewModel.allowedExtensions.length === 0}>
                          <Neo.FileUploadButton> Select a file</Neo.FileUploadButton> or drop a file here.
                        </div>
                        <div hidden={viewModel.allowedExtensions.length !== 0}>
                          <p>Please Choose a media type to upload a file</p>
                        </div>
                        <Neo.FileUploadProgress variant={"info"} />
                      </Neo.FileDropArea>
                    </Neo.FileContext>
                  </GridItem>

                  <GridItem md={12}>
                    <NeoGrid.Grid hidden={viewModel.fileDescriptorList.length === 0} className="mb-10 mt-10" items={viewModel.fileDescriptorList}>
                      {(file, fileMeta) => (
                        <NeoGrid.Row>
                          <NeoGrid.Column label="File Name" display={fileMeta.fileName} />
                          <NeoGrid.Column label="File Type" display={fileMeta.mimeType} />
                        </NeoGrid.Row>
                      )}
                    </NeoGrid.Grid>
                  </GridItem>

                  <GridItem md={12}>
                    {
                      viewModel.hideContentNotes ||
                      <Neo.Card className="moderation-notes" hidden={viewModel.selfAssessmentQuestion.newLearningContent.selfAssessmentContentModerationNotes.length === 0}>
                        <RichTextEditor bind={viewModel.selfAssessmentQuestion.newLearningContent.meta.selfAssessmentContentModerationNotes} labelText="Moderation Notes" readOnly={true} />
                      </Neo.Card>
                    }
                  </GridItem>
                  <GridItem md={4}>
                    <Neo.Button
                      hidden={props.disabled}
                      onClick={() => props.saveQuestion(viewModel.selfAssessmentQuestion)}
                      isOutline className="mt-2 mr-2" icon="fa-save" variant="success">
                      Update Question Content
                    </Neo.Button>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>}

          </Card>
        </div>
      </>
    )
  }
}