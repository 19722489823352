import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import Organisation from '../../Models/Organisations/Organisation';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'

export interface IOrganisationApiClient extends Data.IUpdateableApiClient<Organisation, number> {

    // Client only properties / methods
}

@injectable()
export default class OrganisationApiClient extends Data.UpdateableApiClient<Organisation, number> implements IOrganisationApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/Organisation`);
    }

    // Client only properties / methods
}