import React from "react"
import cardAvatarStyle from "../../assets/tss/material-dashboard-react/components/CardStyles/CardAvatarStyle";
import classNames from 'classnames';

interface ICardBodyProps {
  className?: string,
  plain?: boolean,
  profile?: boolean,
  children: React.ReactNode,
  testimonial?: boolean
  testimonialFooter?: boolean
}

export default function CardAvatar(props: ICardBodyProps) {
  const classes = cardAvatarStyle();
  const {
    children,
    className,
    plain,
    profile,
    testimonial,
    testimonialFooter,
    ...rest
  } = props;
  const cardAvatarClasses = classNames({
    [classes.cardAvatar]: true,
    [classes.cardAvatarProfile]: profile,
    [classes.cardAvatarPlain]: plain,
    [classes.cardAvatarTestimonial]: testimonial,
    [classes.cardAvatarTestimonialFooter]: testimonialFooter,
    [className as any]: className !== undefined,
  });
  return (
    <div className={cardAvatarClasses} {...rest}>
      {children}
    </div>
  );
}