import { Attributes, NeoModel, Rules, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class MediaObject extends ValueObject {

    @Rules.StringLength(100)
    public name: string = "";

    @Rules.StringLength(100)
    public fileName: string | null = null;

    public fileDescriptorId: string | null = null;

    @Rules.StringLength(50)
    public mimeType: string | null = null;

    // Client only properties / methods

    @Attributes.NoTracking()
    public extension = "";

    @Attributes.NoTracking()
    public icon = "";

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public onDeserialiseCompleted() {
        if (this.fileName !== null) {
            this.extension = this.fileName.substring(this.fileName.lastIndexOf("."));
            this.icon = this.getIconClassName();
        }
    }

    public getIconClassName(): string {


        let iconName = "";
        switch (this.extension.toLowerCase()) {
            case ".docx":
            case ".doc":
                iconName = "file-word";
                break;
            case ".pptx":
            case ".ppt":
                iconName = "file-powerpoint";
                break;
            case ".xlsx":
            case ".xls":
                iconName = "file-excel";
                break;
            case ".pdf":
                iconName = "file-pdf";
                break;
            case ".jpg":
            case ".jpeg":
            case ".png":
                iconName = "file-image";
                break;
            case ".mp3":
                iconName = "file-audio";
                break;
            case ".mp4":
                iconName = "file-video";
                break;
            default:
                iconName = "file-alt";
        }
        return `fa fa-${iconName}`
    }

    public toString(): string {
        return "Media Object";
    }
}