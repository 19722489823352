import {NeoModel, Rules, Validation, ModelBase} from '@singularsystems/neo-core';

@NeoModel
export default class LearningPathwaySendEmailModel extends ModelBase {
    @Rules.Required()
    public EmailSubject: string = "";
    
    @Rules.Required()
    public EmailBody: string = "";

    public fileKey: string = "";

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.add(this.emailValidation).onProperties(c => [c.EmailBody, c.EmailSubject]);
    }

    private async emailValidation(context: Validation.IRuleContext){
        if (this.EmailSubject === ""){
            context.addError("Subject is required!");
        }
        if (this.EmailBody === ""){
            context.addError("Body is required!");
        }
    }
}