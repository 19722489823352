import { AppServices, Routing as NeoRouting } from '@singularsystems/neo-core';
import { ReportingTypes } from '@singularsystems/neo-reporting';
// import { ReportingView, ReportView } from '@singularsystems/neo-reporting';
import { ReportingView } from '@singularsystems/neo-reporting';
import { UserTypes } from '../App/Models/Enums/UserTypes.enums';
import * as ReportingRoles from '../Reporting/Models/Security/ReportingRoles';
import ReportingService from './Services/ReportingService'

// Modules
const reportingAppModule = new AppServices.Module("Reporting", container => {

    // Services
    container.bind(ReportingTypes.Services.ReportingService).to(ReportingService).inSingletonScope();

});

export interface IAppRoute extends NeoRouting.IMenuRoute {
    userType?: UserTypes 
    children?: IAppRoute[]
    denyRole?: string
   }

// Routes
const ReportingRoute: IAppRoute =

    { name: "Reporting", path: "/reporting", icon: "file-video", component: ReportingView, userType: UserTypes.Admin, denyRole: ReportingRoles.NoReportAccess.NoReports };

const ReportRoute: IAppRoute =

    { name: "Report", path: "/report", icon: "file-alt", component: ReportingView, userType: UserTypes.Admin, denyRole: ReportingRoles.NoReportAccess.NoReports };

const ReportingPureRoutes = [ReportRoute];

export {
    reportingAppModule,
    ReportingRoute,
    ReportingPureRoutes
}