import React from 'react';
import { observer } from 'mobx-react';
import GridContainer from "../../../Template/components/Grid/GridContainer";
import GridItem from "../../../Template/components/Grid/GridItem";
import Card from "../../../Template/components/Card/Card";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardBody from "../../../Template/components/Card/CardBody";
import CardIcon from "../../../Template/components/Card/CardIcon";
import ModuleObjectsConsumptionLookup from '../../Models/ContentCosumption/ModuleObjectsConsumptionLookup';
import { truncateString } from '../../../App/HelperClasses/GlobalHelpers';
import { LearningObjectType } from '../../Models/LearningObjects/LearningObjectType.enum';
import ModulePreviewVM from './ModulePreviewVM';
import ForumDrawer from "../../../Forum/Components/ForumComponents/ForumDrawer";

interface IModulePreviewProps {
    showPreviewDrawer: boolean;
    moduleId: number;
    onClose: (show: boolean) => void;

}

@observer
export default class ModulePreview extends React.Component<IModulePreviewProps> {
    private viewModel = new ModulePreviewVM();
    constructor(props: IModulePreviewProps) {
        super(props);
        this.viewModel = new ModulePreviewVM();
        if (props.moduleId) {
            this.viewModel.PreviewModule(props.moduleId as number);
        }
    }

    public render() {
        const props = this.props;
        const viewModel = this.viewModel;
        return (
            <div>
                <ForumDrawer show={props.showPreviewDrawer}
                    isMaximised={viewModel.previewDrawerMaximised}
                    onClose={() => { props.onClose(props.showPreviewDrawer) }}
                    maximiseWindow={() => viewModel.previewDrawerMaximised = !viewModel.previewDrawerMaximised}>
                    {/* Main Module Info */}
                    <div >
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                <br />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader icon>
                                        <CardIcon color="success">
                                            <i className={`icon fa fa-chalkboard fa-2x`}></i>
                                        </CardIcon>
                                        <h4 className="Card-icon-header-text">{this.viewModel.module.moduleTitle}</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className="module-tiles">Learning Objective</h4>
                                        <p className="module-description">{this.viewModel.module.learningObjective}</p>
                                        <Card>
                                            <CardHeader icon>
                                                <CardIcon color="info">
                                                    <i className={`icon fa fa-tasks fa-2x`}></i>
                                                </CardIcon>
                                                <h4 className="Card-icon-header-text">Activities</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <GridContainer>
                                                    {this.viewModel.module === undefined ||
                                                        this.viewModel.module.learningObjectList.map((lo: ModuleObjectsConsumptionLookup, index) => (
                                                            <GridItem md={3} key={lo.learningObjectId}>
                                                                <div>
                                                                    <Card>
                                                                        <CardHeader color={lo.learningObjectType === LearningObjectType.Information ? "success" : lo.learningObjectType === LearningObjectType.Quiz ? "warning" : "danger"} plain >
                                                                            <h4 className="card-title-white-small">({lo.order}) {lo.title}</h4>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <p className="module-description">{truncateString(lo.purpose, 150)}</p>
                                                                        </CardBody>
                                                                    </Card>
                                                                </div>
                                                            </GridItem>
                                                        ))
                                                    }
                                                </GridContainer>
                                            </CardBody>
                                        </Card>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </ForumDrawer>
            </div>
        );
    }
}