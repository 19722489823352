import { Attributes, Misc, ModelBase, NeoModel } from '@singularsystems/neo-core';
import BadgeBase from './BadgeBase';
import LearningPathway from '../LearningPathways/LearningPathway';

@NeoModel
export default class BadgeLearningPathway extends ModelBase {

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public badgeId: number | null = null;

    // @Attributes.ChildObject(BadgeBase)
    public badgeBase: BadgeBase | null = null;

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public learningPathwayId: number | null = null;

    @Attributes.ChildObject(LearningPathway)
    public learningPathway: LearningPathway | null = null;

    // Client only properties / methods
}