import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import ModuleObjectsConsumptionLookup from './ModuleObjectsConsumptionLookup';

@NeoModel
export default class ModuleConsumptionLookup extends ModelBase {

  public moduleId: number = 0;

  public moduleTitle: string = "";

  public learningObjective: string = "";

  @Attributes.Integer()
  public cptdPoints: number = 0;

  public learningObjectList = new List(ModuleObjectsConsumptionLookup);

  public learningPathwayId: number = 0;

  @Attributes.Nullable()
  public lastLearningObjectId: number | null = 0;

  @Attributes.Float()
  public finalQuizMark: number = 0;

  public finalQuizStarted: boolean = false;

  public isDeactivated: boolean = false;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.moduleTitle) {
      return "New module consumption lookup";
    } else {
      return this.moduleTitle;
    }
  }
}