import { ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class SchoolImportResult extends ModelBase {

    public warnings: string[] = [];

    public fileErrors: string[] = [];

    public rowErrors: string[] = [];

    // Client only properties / methods
}