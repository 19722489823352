import { Data } from "@singularsystems/neo-core";
import { ValidationDisplayMode } from "@singularsystems/neo-core/dist/Validation/Misc";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import { BadgeAction } from "../../Models/Badges/BadgeAction.enum.";
import BadgeLookup from "../../Models/Badges/BadgeLookup";
import { BadgeSubAction } from "../../Models/Badges/BadgeSubAction.enum";
import BadgeVM from "./BadgeVM";
import { Types } from '../../../Identity/IdentityTypes';
import { AppService } from '../../../Identity/CommonIdentityTypes';
import FileDescriptor from "../../Models/Files/FileDescriptor";
import MediaObject from "../../Models/LearningObjects/LearningContent/MediaObject";
import Card from "../../../Template/components/Card/Card";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardBody from "../../../Template/components/Card/CardBody";
import CardIcon from "../../../Template/components/Card/CardIcon";
import GridContainer from "../../../Template/components/Grid/GridContainer";
import GridItem from "../../../Template/components/Grid/GridItem";
import LearningModuleLookup from "../../Models/Lookups/LearningModuleLookup";
import PathwayModuleConsumptionView from "../ContentConsumption/PathwayModuleConsumptionView";
import ModuleConsumptionView from "../ContentConsumption/ModuleConsumptionView";
import { OpenLink } from "../../../App/HelperClasses/GlobalHelpers";
import LearningPathwayPagerLookup from "../../Models/Lookups/LearningPathways/LearningPathwayPagerLookup";
import { NotificationDuration } from "../../../App/Models/Enums/NotificationDuration.enum";

export enum SubActionParam {
    SubActionParameter = 1,
}

@observer
export default class BadgeView extends Views.ViewBase<BadgeVM>{
    constructor(props: unknown) {
        super("Badges", BadgeVM, props);
    }

    private apiClient = AppService.get(Types.Learning.ApiClients.BadgeQueryApiClient);

    private afterUpload(fileDescriptor: FileDescriptor) {
        this.viewModel.taskRunner.run("Uploading Image", async () => {
            var mediaObject = new MediaObject();
            mediaObject.fileDescriptorId = fileDescriptor.fileDescriptorId;
            mediaObject.fileName = fileDescriptor.fileName;
            mediaObject.mimeType = fileDescriptor.mimeType;
            mediaObject.name = fileDescriptor.fileName;
            this.viewModel.selectedBadge.mediaObject = mediaObject;
            this.viewModel.selectedBadge.badgeImageName = fileDescriptor.fileName;
            this.viewModel.notifications.addSuccess("Upload Complete", null, NotificationDuration.Short);
            this.viewModel.showBadgeImage(fileDescriptor.fileDescriptorId);
        });
    }

    private GoToModuleView = (id: number, preview: boolean = false) => {
        if (!preview) {
            this.navigation.navigateToView(PathwayModuleConsumptionView, { moduleId: id, pathwayId: this.viewModel.learningPagerVm.selectedLearningPathwayId });
        } else {
            var path = this.navigation.getPathForView(ModuleConsumptionView, { moduleId: id, preview: 1, moduleOnly: 1 });
            OpenLink(path);
        }
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <Neo.Card title="Badges" icon="certificate">
                        <div className="row mb-2">
                            <div className="col-md-10">
                                <Neo.Button variant="success" isOutline icon="import"
                                    onClick={() => this.viewModel.addBadge()} >Add New Badge</Neo.Button>
                            </div>
                        </div>
                        <div className="row mb-2" hidden={this.viewModel.showSearchCriteria}>
                            <div className="col-md-10">
                                <Neo.Button variant="info" isOutline icon="import" onClick={() => { this.viewModel.showCriteria() }} >Show Search Criteria</Neo.Button>
                            </div>
                        </div>
                        <div hidden={!this.viewModel.showSearchCriteria} onKeyPress={(event) => {
                            if (event.charCode === 13) {
                                this.viewModel.searchBadges();
                                event.preventDefault();
                            }
                        }}>
                            <Neo.GridLayout md={1}>
                                <Neo.Input className="cerebri-regular font-12" placeholder="Search for a Badge by Title" bind={this.viewModel.badgeCriteria.meta.keyword} />
                                <div className="mt-3 ml-3 mb-3">
                                    <Neo.GridLayout >
                                        <Neo.Input label="Approved Badges?" type="radio" bind={this.viewModel.badgeCriteria.meta.badgeApproved} /><br />
                                        <Neo.Input label="Active Badges?" type="radio" bind={this.viewModel.badgeCriteria.meta.activeBadge} />
                                    </Neo.GridLayout>
                                </div>
                            </Neo.GridLayout>
                            <Neo.Button autoFocus className="mt-3" variant="info" isOutline text="Search Badges" icon="fa fa-search"
                                onClick={() => { this.viewModel.searchBadges() }}
                            />
                            <Neo.Button className="mt-1 ml-2" variant="warning" isOutline text="Clear Search" icon="fa fa-eraser" onClick={() => { this.viewModel.clearBadgeSearchFilters() }} />
                        </div>

                        <div className="row ">
                            <div className="col-md-12">
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <Neo.Pager
                                            pageManager={this.viewModel.badgeLookupPageManager}
                                            pageControlProps={{
                                                firstText: "",
                                                prevText: "Prev",
                                                nextText: "Next",
                                                lastText: "",
                                                alignment: "center",
                                                autoHide: false,
                                                pageSizeLabel: "Show"
                                            }}>
                                            <NeoGrid.Grid<BadgeLookup> hidden={this.viewModel.hideBadgeModuleGrid}>
                                                {(badge: BadgeLookup, badgeMeta) => (
                                                    <NeoGrid.Row className={this.viewModel.selectedBadgeId === badge.badgeId ? "selected-item-css" : "bg-none"}>
                                                        <NeoGrid.Column label="Badge Title" display={badgeMeta.badgeTitle} autoFocus={true} />
                                                        <NeoGrid.Column label="Created Date" display={badgeMeta.createdDate} />
                                                        <NeoGrid.Column label="Badge Approved" display={badgeMeta.badgeApproved} />
                                                        <NeoGrid.Column label="Badge Active" display={badgeMeta.activeBadge} />
                                                        <NeoGrid.ButtonColumn>
                                                            <Neo.Button text="Select" isOutline variant="success"
                                                                onClick={() => this.viewModel.setSelectedBadge(badge.badgeId)} />
                                                        </NeoGrid.ButtonColumn>
                                                        <NeoGrid.ButtonColumn>
                                                            <Neo.Button icon="trash" key="delete" variant="danger" onClick={() => this.viewModel.showDeleteBadge(badge)} />
                                                        </NeoGrid.ButtonColumn>
                                                    </NeoGrid.Row>
                                                )}
                                            </NeoGrid.Grid>
                                        </Neo.Pager>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Neo.Card>
                </section>

                <section>
                    <Neo.Card title={this.viewModel.selectedBadgeId !== 0 ? "Edit Badge - " + this.viewModel.selectedBadge.badgeTitle : "Add New Badge"} icon="certificate" hidden={this.viewModel.editAddBadgeCard}>
                        <Neo.Form model={this.viewModel.selectedBadge} validationDisplayMode={ValidationDisplayMode.Always}
                            showSummaryModal>
                            {(badge, badgeMeta) => (
                                <div>
                                    <GridContainer>
                                        <GridItem md={6}>
                                            <GridContainer>
                                                <GridItem md={12}>
                                                    <Neo.FormGroupFloating label="Badge Title*" bind={badgeMeta.badgeTitle} autoFocus />
                                                </GridItem>
                                                <GridItem md={12}>
                                                    <Neo.FormGroupFloating label="Badge Description*" input={{ rows: 2 }} bind={badgeMeta.badgeDescription} />
                                                </GridItem>
                                                <GridItem md={12}>
                                                    <Neo.FormGroupFloating label="Badge Award Message" input={{ rows: 2 }} bind={badgeMeta.badgeAwardMessage} /><br />
                                                </GridItem>
                                                <GridItem md={12}>
                                                    <Neo.FormGroupFloating bind={badgeMeta.badgeAction}
                                                        disabled={badgeMeta.badgeId.value > 0}
                                                        select={{
                                                            itemSource: Data.StaticDataSource.fromEnum(BadgeAction),
                                                        }} /><br />
                                                </GridItem>
                                                <GridItem md={12}>
                                                    <Neo.FormGroupFloating bind={badgeMeta.badgeSubAction}
                                                        hidden={badgeMeta.badgeAction.value === BadgeAction.VerifyUserAccount}
                                                        disabled={badgeMeta.badgeId.value > 0}
                                                        select={{
                                                            itemSource: (badgeMeta.badgeAction.value === BadgeAction.InviteToLearningPathway
                                                                || badgeMeta.badgeAction.value === BadgeAction.ConversationContribution) ? Data.StaticDataSource.fromEnum(SubActionParam) : Data.StaticDataSource.fromEnum(BadgeSubAction),
                                                            allowNulls: true
                                                        }} /><br />
                                                </GridItem>
                                                <GridItem md={12}>
                                                    <Neo.FormGroup hidden={badgeMeta.badgeSubAction.value == null || badgeMeta.badgeSubAction.value === BadgeSubAction.SubActionList} label="Parameter " bind={badgeMeta.parameter} /><br />
                                                </GridItem>
                                                <GridItem md={12}>
                                                    <Card hidden={badgeMeta.badgeSubAction.value === null || badgeMeta.badgeSubAction.value === BadgeSubAction.SubActionParameter
                                                        || ((badgeMeta.badgeSubAction.value === BadgeSubAction.SubActionList) && !(badgeMeta.badgeAction.value === BadgeAction.ModuleComplete))
                                                        || ((badgeMeta.badgeSubAction.value === BadgeSubAction.SubActionParameterList) && !(badgeMeta.badgeAction.value === BadgeAction.ModuleComplete))}>
                                                        <CardHeader icon>
                                                            <CardIcon color="info">
                                                                <i className={`icon fa fa-search fa-2x`}></i>
                                                            </CardIcon>
                                                            <h4 className="Card-icon-header-text">Add Modules</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <Neo.FormGroupFloating label="Search by Title" bind={this.viewModel.learningModuleCriteria.meta.keyword} placeholder="Search by Title" />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <label>Category Tag</label>
                                                                    <Neo.AutoCompleteDropDown
                                                                        bind={this.viewModel.learningModuleCriteria.meta.learningModuleTag1Id}
                                                                        bindDisplay={this.viewModel.learningModuleCriteria.meta.learningModuleTag1Name}
                                                                        displayMember="tagName"
                                                                        itemSource={this.viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                                                                        onItemSelected={(item) => { this.viewModel.filtertag2ListSearch(item?.tag1Id) }}
                                                                        loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                                                                        minCharacters={2}
                                                                        typeToSearchPrompt="Start typing for search options"
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Neo.FormGroup onSubmit={() => { this.viewModel.searchModules() }} label="Sub-Category Tag (based on Category Tag)" bind={this.viewModel.learningModuleCriteria.meta.learningModuleTag2Id} select={{
                                                                        items: this.viewModel.learningModuleTag2FilteredList,
                                                                        allowNulls: true
                                                                    }} />
                                                                </GridItem>
                                                                <GridItem xs={2} sm={2} md={4}>
                                                                    <Neo.Button isOutline variant="info" text="Search" icon="search" type="submit" onSubmit={() => this.viewModel.searchModules()} onClick={() => this.viewModel.searchModules()} />
                                                                    <Neo.Button text="Clear Search" variant="warning" isOutline icon="eraser" onClick={() => this.viewModel.clearFilters()} />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <Neo.Pager
                                                                        pageManager={this.viewModel.learningModulePageManager}
                                                                        pageControlProps={{
                                                                            firstText: "",
                                                                            prevText: "Prev",
                                                                            nextText: "Next",
                                                                            lastText: "",
                                                                            alignment: "center",
                                                                            autoHide: false,
                                                                            pageSizeLabel: "Show"
                                                                        }}>
                                                                        <NeoGrid.Grid<LearningModuleLookup>>
                                                                            {(learningModule: LearningModuleLookup, learningModuleMeta) => (
                                                                                <NeoGrid.Row className={this.viewModel.isSelected(learningModule.learningModuleId) ? "selected-item-css" : "bg-none"}>
                                                                                    <NeoGrid.Column label="Title" display={learningModuleMeta.learningModuleTitle} />
                                                                                    <NeoGrid.Column label="Learning Objective" display={learningModuleMeta.description} />
                                                                                    <NeoGrid.ButtonColumn label="Actions" >
                                                                                        <Neo.Button variant="info" isOutline icon="eye" tooltip="Preview" onClick={() => this.GoToModuleView(learningModule.learningModuleId, true)} />
                                                                                        <Neo.Button variant="success" isOutline hidden={this.viewModel.isSelected(learningModule.learningModuleId)} icon="plus" tooltip="Add" onClick={() => { this.viewModel.addModuleToBadge(learningModule.learningModuleId) }} />
                                                                                        <Neo.Button variant="danger" isOutline hidden={!this.viewModel.isSelected(learningModule.learningModuleId)} icon="times" tooltip="Remove" onClick={() => { this.viewModel.removeModule(learningModule.learningModuleId) }} />
                                                                                    </NeoGrid.ButtonColumn>
                                                                                </NeoGrid.Row>
                                                                            )}
                                                                        </NeoGrid.Grid>
                                                                    </Neo.Pager>
                                                                </GridItem>
                                                            </GridContainer>
                                                        </CardBody>
                                                    </Card><br />
                                                </GridItem>
                                                <GridItem md={12}>
                                                    <Card hidden={badgeMeta.badgeSubAction.value === null || badgeMeta.badgeSubAction.value === BadgeSubAction.SubActionParameter
                                                        || ((badgeMeta.badgeSubAction.value === BadgeSubAction.SubActionList) && !(badgeMeta.badgeAction.value === BadgeAction.LearningPathwayComplete))
                                                        || ((badgeMeta.badgeSubAction.value === BadgeSubAction.SubActionParameterList) && !(badgeMeta.badgeAction.value === BadgeAction.LearningPathwayComplete))}>
                                                        <CardHeader icon>
                                                            <CardIcon color="info">
                                                                <i className={`icon fa fa-search fa-2x`}></i>
                                                            </CardIcon>
                                                            <h4 className="Card-icon-header-text">Add Learning Pathways</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <Neo.FormGroupFloating label="Search" bind={this.viewModel.learningPathwayCriteria.meta.keyword} placeholder="Title" />
                                                                </GridItem>
                                                                <GridItem xs={2} sm={2} md={4}>
                                                                    <Neo.Button isOutline variant="info" text="Search" icon="search" type="submit" onSubmit={() => this.viewModel.searchPathways()} onClick={() => this.viewModel.searchPathways()} />
                                                                    <Neo.Button text="Clear Search" variant="warning" isOutline icon="eraser" onClick={() => this.viewModel.clearPathwayFilters()} />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer >
                                                                <GridItem xs={12} sm={12} md={12} >
                                                                    <Neo.Pager pageManager={this.viewModel.learningPathwayPagerManager}
                                                                        pageControlProps={{
                                                                            firstText: "",
                                                                            prevText: "Prev",
                                                                            nextText: "Next",
                                                                            lastText: "",
                                                                            alignment: "center",
                                                                            autoHide: false,
                                                                            pageSizeLabel: "Show"
                                                                        }}>
                                                                        <NeoGrid.Grid<LearningPathwayPagerLookup> >
                                                                            {(learningPathway: LearningPathwayPagerLookup, learningPathwayMeta) => (
                                                                                <NeoGrid.Row className={this.viewModel.isPathwaySelected(learningPathway.learningPathwayId) ? "selected-item-css" : "bg-none"}>
                                                                                    <NeoGrid.Column label="Title" display={learningPathwayMeta.title} />
                                                                                    <NeoGrid.ButtonColumn label="Actions" >
                                                                                        <Neo.Button variant="success" isOutline hidden={this.viewModel.isPathwaySelected(learningPathway.learningPathwayId)} icon="plus" tooltip="Add" onClick={() => { this.viewModel.addPathwayToBadge(learningPathway.learningPathwayId) }} />
                                                                                        <Neo.Button variant="danger" isOutline hidden={!this.viewModel.isPathwaySelected(learningPathway.learningPathwayId)} icon="times" tooltip="Remove" onClick={() => { this.viewModel.removePathway(learningPathway.learningPathwayId) }} />
                                                                                    </NeoGrid.ButtonColumn>
                                                                                </NeoGrid.Row>
                                                                            )}
                                                                        </NeoGrid.Grid>
                                                                    </Neo.Pager>
                                                                </GridItem>
                                                            </GridContainer>
                                                        </CardBody>
                                                    </Card><br />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem md={6} >
                                            <Neo.FormGroupFloating hidden={badgeMeta.badgeImageName.value.length > 0}
                                                display={this.viewModel.meta.allowedImageExtensions} /><b />
                                            {
                                                badgeMeta.badgeImageName.value.length === 0 &&
                                                <Neo.FileContext
                                                    uploadEndpoint={this.apiClient.uploadBadgeImage()}
                                                    afterUpload={this.afterUpload.bind(this)}
                                                    allowedExtensions={this.viewModel.allowedExtensions}
                                                >
                                                    <Neo.FileDropArea className="mb-10" hidden={badgeMeta.badgeImageName.value.length > 0}>
                                                        <Neo.FileUploadButton>Select an image</Neo.FileUploadButton> or drop an image here.
                                                        <Neo.FileUploadProgress variant={"info"} />
                                                    </Neo.FileDropArea>
                                                </Neo.FileContext>
                                            }
                                            <GridContainer>
                                                <GridItem md={12}>
                                                    <div className="badge-square-image-view mb-2" hidden={badgeMeta.badgeImageName.value.length === 0}>
                                                        <img src={this.viewModel.blobUrl} className={"badge-img"} alt="Badge" />
                                                        <div className="delete-badge-btn">
                                                            <Neo.Button icon="trash" variant="danger" className="mb-2" isOutline onClick={() => this.viewModel.deleteBadgeImage()} />
                                                        </div>
                                                    </div>
                                                </GridItem >
                                                <GridItem md={12}>
                                                    <Neo.FormGroupFloating label="Image Name*" display={badgeMeta.badgeImageName} hidden={badgeMeta.badgeImageName.value.length === 0} />
                                                </GridItem>
                                            </GridContainer>
                                            <GridItem md={12}>
                                                <Neo.FormGroup label="Badge Approved" bind={badgeMeta.badgeApproved} /><br />
                                            </GridItem>
                                            <GridItem md={12}>
                                                <Neo.FormGroup label="Badge Active" bind={badgeMeta.activeBadge} /><br />
                                            </GridItem>
                                            <GridItem md={12}>
                                                <Neo.FormGroup label="Send Email Notification?" bind={badgeMeta.emailNotification} /><br />
                                            </GridItem>
                                            <Neo.Button onClick={() => this.viewModel.saveBadge()} disabled={!this.viewModel.canSaveBadge()} className="mt-2 ml-2" icon="fa-plus" variant="success" isOutline hidden={this.viewModel.selectedBadgeId !== 0}>
                                                Save Draft Badge
                                            </Neo.Button>
                                            <Neo.Button onClick={() => this.viewModel.saveBadge()} disabled={!this.viewModel.canSaveBadge()} className="mt-2 mr-2" icon="fa-plus" variant="success" isOutline hidden={this.viewModel.selectedBadgeId === 0}>
                                                Update Draft Badge
                                            </Neo.Button>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            )}
                        </Neo.Form>
                    </Neo.Card>
                </section>
            </div >
        )
    }
}