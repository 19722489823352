import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import UserProfileVM from './UserProfileVM';
import { observer } from 'mobx-react';
import VerificationModal from '../Components/VerificationModal';
import { UserTypes } from '../../App/Models/Enums/UserTypes.enums';
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import CardIcon from '../../Template/components/Card/CardIcon';
import CardBody from '../../Template/components/Card/CardBody';
import Confetti from 'react-confetti';
import Home from '../../App/Views/Security/Home';
import GridContainer from '../../Template/components/Grid/GridContainer';
import GridItem from '../../Template/components/Grid/GridItem';
import SchoolSearchComponent from '../../Organisations/Components/SchoolsSearchComponent';

@observer
export default class UserProfileView extends Views.ViewBase<UserProfileVM> {

    constructor(props: unknown) {
        super("User Profile", UserProfileVM, props);
    }

    public saveUserAndHomePage() {
        this.viewModel.saveUser();
        this.navigation.navigateToView(Home);
    }

    public render() {
        return (
            <div>
                <Confetti hidden={!this.viewModel.showConfetti} />
                <Card>
                    <CardHeader icon>
                        <CardIcon color="success">
                            <i className={`fa fa-user fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Profile</h4>
                    </CardHeader>
                    <CardBody>
                        <div className="row client-component-header-mb-5 ">
                            <div className="col-md-5">
                                {/* <h2 className="h2-bold">Profile</h2> */}
                            </div>
                            {!this.viewModel.currentUser.userVerified && !this.viewModel.hasActiveOTP && this.viewModel.currentUser.userType === UserTypes.Unverified &&
                                <div className="col-md-5">
                                    <Neo.Button className="primary-color-pulse" variant="success" icon="check" style={{ float: "right" }}
                                        onClick={() => this.viewModel.openVerificationModal()}>Verify your profile</Neo.Button>
                                </div>
                            }
                            {!this.viewModel.currentUser.userVerified && this.viewModel.hasActiveOTP && this.viewModel.currentUser.userType === UserTypes.Unverified &&
                                <div className="col-md-5">
                                    <Neo.Button className="primary-color-pulse" variant="success" icon="check" style={{ float: "right" }}
                                        onClick={() => this.viewModel.openOTPModal()}>Enter Verification</Neo.Button>
                                </div>
                            }
                            {(this.viewModel.currentUser.userType === UserTypes.Verified) &&
                                <div className="col-md-5">
                                    <Neo.Button disabled={true} className="primary-color-pulse" variant="success" icon="fa-user-check" style={{ float: "right" }}>You are a Verified User</Neo.Button>
                                </div>
                            }
                            {(this.viewModel.currentUser.userType === UserTypes.Admin) &&
                                <div className="col-md-5">
                                    <Neo.Button disabled={true} className="primary-color-pulse" variant="success" icon="fa-user-shield" style={{ float: "right" }}>You are an Admin User</Neo.Button>

                                </div>
                            }
                            <div className="col-md-5">
                            </div>
                        </div>
                        <div>
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6}>
                                    <Card>
                                        <CardHeader icon>
                                            <CardIcon color="success">
                                                <i className={`fa fa-user`}></i>
                                            </CardIcon>
                                            <h4 className="Card-icon-header-text">Manage Your Details</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row mb-5">
                                                <div className="col-md-3 col-xs-6">
                                                    <i className={`${"fa fa-user"} ${"float-right"}`} />
                                                </div>
                                                <div className="col-md-4 col-xs-6">
                                                    <Neo.FormGroup bind={this.viewModel.currentUser.meta.firstName} className="icon-name" />
                                                    <Neo.FormGroup bind={this.viewModel.currentUser.meta.surname} className="icon-name" />
                                                    <Neo.FormGroup bind={this.viewModel.currentUser.meta.email} className="icon-envelope" />
                                                    <Neo.FormGroup bind={this.viewModel.currentUser.meta.phoneNumber} className="icon-mobile" />
                                                    <Neo.FormGroup bind={this.viewModel.currentUser.meta.saceNumber} className="icon-name" disabled />
                                                    <Neo.FormGroup bind={this.viewModel.currentUser.meta.persalNumber} className="icon-name" disabled />
                                                    <Neo.FormGroup bind={this.viewModel.currentUser.meta.idNumber} className="icon-name" disabled />
                                                    <Neo.Button text={"Save & Close"} variant={"success"} icon={"check"} style={{ float: 'right' }}
                                                        onClick={() => this.saveUserAndHomePage()} />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </GridItem>

                                <GridItem xs={6} sm={6} md={6}>
                                    <Card hidden={(this.viewModel.currentUser.userType === UserTypes.Admin)}>
                                        <CardHeader icon>
                                            <CardIcon color="success">
                                                <i className={`fa fa-school`}></i>
                                            </CardIcon>
                                            <h4 className="Card-icon-header-text">School Details</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row mb-5">
                                                <div className="col-md-1 col-xs-6">
                                                    <i className={`${"fa fa-school"} ${"float-right"}`} />
                                                </div>
                                                <div className="col-md-10 col-xs-6">
                                                    <label>Our records show you are linked to the following school. Please make corrections if applicable.</label>
                                                    <Neo.FormGroup label="" bind={this.viewModel.currentUser.meta.organisationLevelName} readOnly={true} className="icon-name" />
                                                    <Neo.Button text={"Change School"} variant={"success"} icon={"fa-school"} style={{ float: 'right' }}
                                                        onClick={() => this.viewModel.showChangeSchool = true} />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card hidden={!(this.viewModel.currentUser.userType === UserTypes.Admin)}>
                                        <CardHeader icon>
                                            <CardIcon color="success">
                                                <i className={`fa fa-user-shield`}></i>
                                            </CardIcon>
                                            <h4 className="Card-icon-header-text">Organisation Details</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row mb-5">
                                                <div className="col-md-1 col-xs-6">
                                                    <i className={`${"fa fa-user-shield"} ${"float-right"}`} />
                                                </div>
                                                <div className="col-md-10 col-xs-6">
                                                    <label>You are an administrative user and have been linked at a specific level in the Organisational Hierarchy. This can only be edited by the system administrator.</label>
                                                    <Neo.FormGroup label=" " bind={this.viewModel.currentUser.meta.organisationLevelName} readOnly={true} className="icon-name" />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <VerificationModal viewModel={this.viewModel.userVerificationVM} userProfileVM={this.viewModel} />
                    </CardBody>
                </Card>
                <section>
                    <Neo.Modal size="xl" show={this.viewModel.showChangeSchool} title="Change School" onClose={() => this.viewModel.showChangeSchool = false}>
                        <SchoolSearchComponent onSelected={(school) => this.viewModel.onSchoolSelected(school)} />
                    </Neo.Modal>
                </section>
            </div>
        );
    }
}