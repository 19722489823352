import { Attributes, List } from '@singularsystems/neo-core';
import LearningModuleObject from '../LearningModules/LearningModuleObject';
import { LearningObjectType } from './LearningObjectType.enum';
import ModeratableBase from '../Moderation/ModeratableBase';
import LearningObjectTag3 from '../Tags/LearningObjectTag3';
import { ModeratableShortStringField } from '../Moderation/ModeratableFieldValues';

export default class LearningObjectBase extends ModeratableBase {

    public learningObjectId: number = 0;

    public learningObjectType: LearningObjectType | null = null;

    @Attributes.ChildObject(ModeratableShortStringField)
    public purpose: ModeratableShortStringField | null = null;

    public learningModuleObjects = new List(LearningModuleObject);

    public learningObjectTag3s = new List(LearningObjectTag3);

    // Client only properties / methods
}