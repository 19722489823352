import { UserManager, User } from 'oidc-client';
import { Security, NeoModel } from '@singularsystems/neo-core';
// import { NotificationServiceTypes } from '@singularsystems/neo-notifications';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import AppTypes from '../../../App/AppTypes';
import TDPUser from '../../Models/Security/TDPUser';

export interface IAuthenticationService extends Security.OidcAuthService {
    readonly user: TDPUser | null;
    redirectToRegistration(emailAddress: string): void;
    hasSeenIntroduction: boolean;
}

@injectable()
@NeoModel
export class OidcAuthenticationService extends Security.OidcAuthService<TDPUser> implements IAuthenticationService {
    
    public hasSeenIntroduction: boolean = false;

    constructor(
        axios = AppService.get(AppTypes.Neo.Axios),
        private config = AppService.get(AppTypes.App.Config)
    ) {
        super(
            new UserManager(config.userManagerSettings),
            axios);
    }

    protected createUser(user: User): TDPUser {
        return new TDPUser(user);
    }

    protected afterUserLoaded() {
        // AppService.get(NotificationServiceTypes.Services.NotificationService).initialise();
    }

    public async redirectToRegistration(emailAddress: string) {
        // Create the sign in request to send to IDS.
       // const request = await this.userManager.createSigninRequest({ state: this.routeService.registerRoute.path + "/complete" });

        // We only care about the query string from the above request.
       // const returnUrl = "/connect/authorize/callback" + decodeURIComponent(request.url.substr(request.url.indexOf("?")));

        // Redirect to the register page on the identity portal, with its redirect url set to IDS' authorise callback.
        // On authorization, IDS will redirect back to our app (to the register page).
        // const registerUrl = `${this.config.IdentityServerApi.BasePath}/identity/account/register?email=${encodeURIComponent(emailAddress)}&ReturnUrl=${encodeURIComponent(returnUrl)}`;

       // (window.location as any) = registerUrl;
    }

}