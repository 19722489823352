import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { RichTextEditorVM } from '../../../Learning/Components/RichTextEditor';
import { ForumIntegrationTypes } from "../../Models/Enums/ForumLearningTypes.enum";

@NeoModel
export default class PostLookup extends ModelBase {

    public postId: number = 0;

    public parentId: number = 0;

    public topicId: number = 0;

    public body: string = "";

    public postedBy: string = "";

    public isActive: boolean = true;

    public isDeleted: boolean = false;

    public isReported: boolean = false;

    public reportedBy: string = "";

    public hasChildren: boolean = false;

    public createdBy: number = 0;

    public deletedReason: string = "";

    public createdByGuid: string = '';

    public forumName: string = "";

    public forumLearningType: ForumIntegrationTypes = ForumIntegrationTypes.Unspecified;

    @Attributes.Date()
    public createdOn: Date = new Date();

    public timeAgo: string = "";

    // Client only properties / methods

    @Attributes.NoTracking()
    public viewModel = new RichTextEditorVM();
}