import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';
import QuizQuestionLookup from './QuizQuestionLookup';

@NeoModel
export default class QuizLearningObjectLookup extends ModelBase {

    constructor() {
        super();
        this.quizQuestion = new QuizQuestionLookup();
    }

    public learningModuleId: number = 0;

    public learningObjectId: number = 0;

    public FinalQuizInd: boolean = false;

    public learningObjectTypeId: number | null = null;

    public learningObjectPurpose: string = "";

    public learningObjectTitle: string = "";

    public learningObjectText: string = "";

    public LearningObjectTextHtml: string = "";

    public objectName: string = "";

    public contentId: number = 0;

    @Attributes.Display("Number Of Questions To Answer")
    @Required("Number Of Questions To Answer required.")
    public numberOfQuestionsToAnswer: number = 0;

    public quizQuestions = new List(QuizQuestionLookup);

    @Attributes.ChildObject(QuizQuestionLookup)
    public quizQuestion: QuizQuestionLookup;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.learningObjectPurpose) {
            return "New learning object lookup";
        } else {
            return this.learningObjectPurpose;
        }
    }
}