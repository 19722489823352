import { Attributes, ModelBase, NeoModel, Utils, Validation } from '@singularsystems/neo-core';
import LearningPathwayModule from '../../LearningPathways/LearningPathwayModule';

@NeoModel
export default class LearningPathwayParticipantModule extends ModelBase {

  public userGuid: string = Utils.emptyGuid();

  public userFullName: string = "";

  @Attributes.ChildObject(LearningPathwayModule)
  public participantModule: LearningPathwayModule = new LearningPathwayModule();

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New learning pathway participant module";
    } else {
      return "Learning Pathway Participant Module";
    }
  }
}