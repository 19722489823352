import { Neo } from "@singularsystems/neo-react";
import React from "react";
import { observer } from "mobx-react";
import ContentConsumptionLookup from "../Models/ContentCosumption/ContentConsumptionLookup";
import { LearningContentMediaType } from "../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import { ITaskRunner } from "@singularsystems/neo-core";
import { OpenLink } from "../../App/HelperClasses/GlobalHelpers";

interface IContentModalProps {
  title: string;
  show: boolean;
  content: ContentConsumptionLookup;
  onClose: (show: boolean) => void;
  blobUrl: string;
  alternateUrl: string;
  taskRunner: ITaskRunner;
}

@observer
export default class ContentModal extends React.Component<IContentModalProps>{

  public setDoneLoading() {
    document.getElementById('loadingIframe')!.style.display = 'none';
  }

  public render() {
    const props = this.props;
    return (
      <Neo.Modal title={props.content.title} size="xl"
        show={props.show}
        onClose={() => { props.onClose(props.show) }}
        closeButton={{ text: "Cancel", variant: "secondary", icon: "times" }} >
        <div >
          {/* Images */}
          {props.content.mediaType === LearningContentMediaType.Image &&
            <div style={{ textAlign: 'center' }} >
              <Neo.Loader task={props.taskRunner}>
                <div dangerouslySetInnerHTML={{ __html: props.blobUrl }} />
              </Neo.Loader>
            </div>}

          {/* Video */}
          {props.content.mediaType === LearningContentMediaType.Video &&
            <div style={{ textAlign: 'center' }} >
              {!props.alternateUrl.includes("embed") &&
                <Neo.Loader task={props.taskRunner} showSpinner>
                  <div dangerouslySetInnerHTML={{ __html: props.blobUrl }} />
                </Neo.Loader>
              }
              {props.alternateUrl.includes("embed") &&
                <div>
                  <div id="loadingIframe">
                    <p>Loading... please wait</p>
                  </div>
                  <iframe title={props.content.title} style={{ width: "100%", height: "500px" }} src={props.alternateUrl} onLoad={this.setDoneLoading} allow='autoplay; encrypted-media' />
                </div>
              }
            </div>}

          {/* Youtube */}
          {props.content.mediaType === LearningContentMediaType.YouTube &&
            <div style={{ textAlign: 'center' }} >
              <iframe title={props.content.title} style={{ width: "100%", height: "500px" }} src={props.content.externalResourceLocation} onLoad={this.setDoneLoading} allow='autoplay; encrypted-media' />
            </div>}

          {/* Document */}
          {props.content.mediaType === LearningContentMediaType.Document &&
            <div style={{ textAlign: 'center' }}>
              <div id="loadingIframe">
                <p>Loading... please wait</p>
              </div>
              {/* <div dangerouslySetInnerHTML={{ __html: props.blobUrl }} /> */}
              <Neo.Button text="Document not loading? click here to view." onClick={() => OpenLink(props.blobUrl)} variant="info" icon="link" />
              <iframe title={props.content.title} src={props.blobUrl} style={{ width: "100%", height: "500px" }} onLoad={this.setDoneLoading} />
            </div>}

          {/* Link */}
          {props.content.mediaType === LearningContentMediaType.Link &&
            <div style={{ textAlign: 'center' }} >
              <Neo.Button text="Website not loading? click here to view." onClick={() => OpenLink(props.content.externalResourceLocation)} variant="info" icon="link" />
              <iframe title={props.content.title} style={{ width: "100%", height: "500px" }} src={props.content.externalResourceLocation} onLoad={this.setDoneLoading} />
            </div>}

          {/* Sound */}
          {props.content.mediaType === LearningContentMediaType.Sound &&
            <div style={{ textAlign: 'center' }} >
              <Neo.Loader task={props.taskRunner} showSpinner>
                <div dangerouslySetInnerHTML={{ __html: props.blobUrl }} />
              </Neo.Loader>
            </div>}
        </div>
      </Neo.Modal>
    )
  }
}