import { List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import RoleCategory from './RoleCategory';
import IRoleItem from './IRoleItem';

@NeoModel
export default class Resource extends ModelBase implements IRoleItem {

    public resourceName : string = "";

    public categories = new List(RoleCategory);

    // Client only properties / methods

    public get itemName() {
        return this.resourceName;
    }

    public get children() {
        // This suddenly would not compile without this "as unknown as List<IRoleItem>" :(
        return this.categories as unknown as List<IRoleItem>;
    }

    public get level() { return 1; };

    public get fullChildrenCount() {
        return this.children.sum(c => c.children ? c.children.length : 0);
    }

    public get selectedChildrenCount() {
        return this.children.sum(c => c.selectedChildrenCount);
    }

    public get selected() {
        let allSelected = true;
        let allUnselected = true;
        for (const category of this.categories)
        {
            if (category.selected === true)
            {
                allUnselected = false;
            }
            else if (category.selected === false)
            {
                allSelected = false;
            }
            else if (category.selected === undefined)
            {
                allSelected = false;
                allUnselected = false;
            }
            if (!allSelected && !allUnselected) { break; }
        }
        if (allSelected && allUnselected) { return undefined; }
        else if (allSelected) { return true; }
        else if (allUnselected) { return false; }
        else { return undefined; }
    }

    public set selected(value: boolean | undefined) {
        this.categories.forEach(resource => resource.selected = value as boolean );
    }

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString() {
        if (this.isNew) {
            return "New resource"
        } else {
            return "Resource";
        }
    }
}