import { injectable } from 'inversify';

import Types from '../../AuthorisationTypes';
import { Misc } from '@singularsystems/neo-core';
import { IUserGroupsApiClient } from './UserGroupsApiClient';
import { IUsersApiClient } from './UsersApiClient';
import { IResourcesApiClient } from './ResourcesApiClient';
import { IMembershipsApiClient } from './MembershipsApiClient';
import { IAssignedRolesApiClient } from './AssignedRolesApiClient';
import { IUserRoleApiClient } from './UserRoleApiClient';
import { IUserGroupQueryApiClient } from './UserGroupQueryApiClient';

export interface IAuthorisationApiClient {
    /**
     * The user groups api client
     */
    userGroups: IUserGroupsApiClient,

    /**
     * The users api client
     */
    users: IUsersApiClient,

    /**
     * The resources api client
     */
    resources: IResourcesApiClient,

    /**
     * The memberships api client
     */
    memberships: IMembershipsApiClient,

    /**
     * The memberships api client
     */
    assignedRoles: IAssignedRolesApiClient,

    userRoles: IUserRoleApiClient,

    userGroupQuery: IUserGroupQueryApiClient
}

@injectable()
export default class AuthorisationApiClient implements IAuthorisationApiClient {

    constructor(
        public assignedRoles = Misc.Globals.appService.get(Types.ApiClients.AssignedRolesApiClient),
        public memberships = Misc.Globals.appService.get(Types.ApiClients.MembershipsApiClient),
        public resources = Misc.Globals.appService.get(Types.ApiClients.ResourcesApiClient),
        public userGroups = Misc.Globals.appService.get(Types.ApiClients.UserGroupsApiClient),
        public users = Misc.Globals.appService.get(Types.ApiClients.UsersApiClient),
        public userGroupQuery = Misc.Globals.appService.get(Types.ApiClients.UserGroupQueryApiClient),
        public userRoles = Misc.Globals.appService.get(Types.ApiClients.UserRoleApiClient)) {
    }
}