import { Data, List, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { AppService } from "../../App/Services/AppService";
import Types from '../../App/AppTypes';
import React from "react";
import { observer } from "mobx-react";
import { LearningContentMediaType } from "../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import FileDescriptor from "../Models/Files/FileDescriptor";
import LearningModuleVM from "../Views/LearningModuleVM";
import MediaObject from "../Models/LearningObjects/LearningContent/MediaObject";
import FileLookup from "../Models/Lookups/FileLookup";
import LearningObjectContentLookup from "../Models/Lookups/LearningObjectContentLookup";
import { ValidationDisplayMode } from "@singularsystems/neo-core/dist/Validation/Misc";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum";
import { LearningObjectType } from "../Models/LearningObjects/LearningObjectType.enum";
import RichTextEditor, { RichTextEditorVM } from "./RichTextEditor";
import { ModerationState } from "../Models/LearningObjects/ModerationState.enum";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";

interface ILearningContentFileComponent {
    hidden: boolean;
    learningContent: LearningObjectContentLookup;
    viewModel: LearningContentFileComponentVM;
    learningModuleVM: LearningModuleVM;
    allowAdminEdit: boolean;
}

@NeoModel
export class LearningContentFileComponentVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private apiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient)) {
        super(taskRunner);
        this.fileDescriptorList = new List(FileLookup);
    }

    // Properties
    public selectedMediaType: number = 0;
    public fileDescriptorList = new List(FileLookup);
    public richTextEditorVmLearningContent = new RichTextEditorVM(this.taskRunner);
    public showAddContentModal = false;
    public showAddQuestionModal = false;

    public hideContentNotes = true;
    public async getFileLookup(fileDescriptorId: string, mediaTypeId: number) {
        this.fileDescriptorList = new List(FileLookup);
        const fileLookUp = (await this.taskRunner.waitFor(this.apiClient.getFileByDescriptorId(fileDescriptorId, mediaTypeId))).data;
        if (fileLookUp) {
            for (let index = 0; index < fileLookUp.length; index++) {
                var element = fileLookUp[index];
                var fileDescriptor = new FileLookup();
                fileDescriptor.fileDescriptorId = element.fileDescriptorId as string;
                fileDescriptor.fileName = element.fileName as string;
                fileDescriptor.mediaTypeId = element.mediaTypeId as number;
                fileDescriptor.mimeType = element.mimeType as string;
                this.fileDescriptorList.push(fileDescriptor);
            }
        }
    }
}

@observer
export default class LearningContentFileComponent extends React.Component<ILearningContentFileComponent> {

    private apiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient);
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications);
    private allowedExtensions: string[] = [];
    public showFiles: boolean = false;
    private afterUpload(fileDescriptor: FileDescriptor) {
        this.props.viewModel.taskRunner.run("Importing file", async () => {
            var mediaObject = new MediaObject();
            mediaObject.fileDescriptorId = fileDescriptor.fileDescriptorId;
            mediaObject.fileName = fileDescriptor.fileName;
            this.props.learningContent.learningContent.mediaObject = mediaObject;
            this.props.learningContent.learningObjectId = this.props.learningModuleVM.selectedLearningObjectId;
            this.props.viewModel.getFileLookup(fileDescriptor.fileDescriptorId, this.props.learningModuleVM.selectedMediaType);
            this.notifications.addSuccess("File has been uploaded", "You can now save your learning Content", 4);
        });
    }

    public async saveLearningContent() {
        try {
            var loObject = this.props.learningModuleVM.selectedLearningObject!;
            if (loObject.learningObjectId === 0) {
                this.props.learningModuleVM.learningObjectList.forEach(element => {
                    if (element.learningObjectTypeId === LearningObjectType.FinalQuiz && loObject.learningObjectTypeId === LearningObjectType.FinalQuiz) {
                        this.notifications.addDanger("Final Quiz Existing", "There is already a final quiz existing for this module", NotificationDuration.Standard);
                        throw new Error("There is already a final quiz existing for this module.");
                    }
                });
            }
            loObject.newLearningContent = this.props.learningContent;
            loObject.learningObjectId = this.props.learningModuleVM.selectedLearningObjectId;
            loObject.learningModuleId = this.props.learningModuleVM.selectedLearningModuleId;

            if (loObject.learningObjectTypeId === LearningObjectType.Quiz || loObject.learningObjectTypeId === LearningObjectType.FinalQuiz) {
                var response = await this.apiClient.createQuizLearningObjectWithContent(loObject.toJSObject());
                this.props.learningModuleVM.refreshLOList();
                if (this.props.learningModuleVM.selectedLearningObjectId === 0) {
                    this.props.learningModuleVM.selectedLearningObjectId = response.data.learningObjectId;
                }
                this.props.viewModel.showAddQuestionModal = true;
            }

            if (loObject.learningObjectTypeId === LearningObjectType.Information) {
                var loResponse = await this.apiClient.createLearningObjectWithContent(loObject.toJSObject());
                this.props.learningModuleVM.refreshLOList();
                if (this.props.learningModuleVM.selectedLearningObjectId === 0) {
                    this.props.learningModuleVM.selectedLearningObjectId = loResponse.data.learningObjectId;
                }
                this.props.viewModel.showAddContentModal = true;
            }

            const getlearningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentByObject(this.props.learningModuleVM.selectedLearningObjectId);
            if (getlearningContent.data !== null && getlearningContent.data.length !== 0) {
                this.props.learningModuleVM.newLearningContent.set(getlearningContent.data[0]);
                this.props.learningModuleVM.selectedLearningContentId = getlearningContent.data[0].learningContentId;
            }

            this.props.viewModel.fileDescriptorList.set([]);
            var fileDescriptor = new FileLookup();
            fileDescriptor.fileDescriptorId = this.props.learningModuleVM.newLearningContent.learningContent.mediaObject?.fileDescriptorId as string;
            fileDescriptor.fileName = this.props.learningModuleVM.newLearningContent.learningContent.mediaObject?.fileName as string;
            fileDescriptor.mediaTypeId = this.props.learningModuleVM.newLearningContent.mediaTypeFetched;
            fileDescriptor.mimeType = this.props.learningModuleVM.newLearningContent.learningContent.mediaObject?.extension as string;
            this.props.viewModel.fileDescriptorList.push(fileDescriptor);
            this.props.viewModel.selectedMediaType = this.props.learningModuleVM.newLearningContent.mediaTypeFetched;
            this.notifications.addSuccess("Learning Content Saved", "Learning Content has been saved", NotificationDuration.Standard);
        }
        catch (error) {
            this.notifications.addDanger("Fail", "Your Learning Content did not save", NotificationDuration.Long);
        }
    }

    private async deleteFile(fileName: string, fileDescriptorId: string) {
        await ModalUtils.showYesNoDismissible("Delete File " + fileName,
            "Are you sure you want to delete this File? ", () => this.deleteConfirmed(fileDescriptorId, this.props.viewModel.selectedMediaType));
    }

    private async deleteConfirmed(fileDescriptorId: string, mediaType: number) {
        await this.props.viewModel.taskRunner.waitFor(this.apiClient.deleteFile(fileDescriptorId, mediaType));
    }

    private setSelectedMediaType(id: number) {
        this.allowedExtensions = [""];
        this.props.viewModel.selectedMediaType = id;
        this.setAllowedExtensions(this.props.viewModel.selectedMediaType);
    }

    private setAllowedExtensions(mediaTypeId: number) {
        switch (mediaTypeId) {
            case LearningContentMediaType.Document: {
                this.allowedExtensions = [".docx", ".pptx", ".ppt", ".xlsx", ".xls", ".pdf"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.Image: {
                this.allowedExtensions = [".jpg", ".jpeg", ".png", ".JPG",];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.Sound: {
                this.allowedExtensions = [".MP3", ".MP4", ".wav", ".MPEG-4", ".mp4", ".mp3", ".ogg"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.Video: {
                this.allowedExtensions = [".avi", ".mov", ".qt", ".wmv", ".MTS", ".M2TS", ".TS", ".mp4", ".mp3", ".m4v"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
            case LearningContentMediaType.SCORM: {
                this.allowedExtensions = [".xml", ".zip"];
                this.props.learningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
                break;
            }
        }
    }

    private ShowFileExtensionsTypeBinding(mediaType: number) {
        if (mediaType !== LearningContentMediaType.Link
            && mediaType !== LearningContentMediaType.YouTube
            && this.props.learningContent.learningContent.allowedFileExtensions !== "") {
            return false;
        }
        else {
            return true;
        }
    }

    private yesAddContent() {
        this.props.learningModuleVM.onAddMediaClick();
        this.props.viewModel.showAddContentModal = false;
    }

    private noAddContent() {
        this.props.learningModuleVM.hideMediaUpload = true;
        this.props.viewModel.showAddContentModal = false;
        this.props.learningModuleVM.hideLearningObjectCard = true;
        this.props.learningModuleVM.selectedLearningObjectId = 0;
        this.props.learningModuleVM.selectedLearningContentId = 0;
    }

    private yesAddQuestion() {
        this.props.learningModuleVM.hideMediaUpload = true;
        this.props.viewModel.showAddQuestionModal = false;
        this.props.learningModuleVM.hideLearningObjectCard = false;
        this.props.learningModuleVM.hideQuizCard = true;
    }

    private noAddQuestion() {
        this.props.learningModuleVM.hideMediaUpload = true;
        this.props.viewModel.showAddQuestionModal = false;
        this.props.learningModuleVM.hideLearningObjectCard = true;
        this.props.learningModuleVM.selectedLearningObjectId = 0;
        this.props.learningModuleVM.selectedLearningContentId = 0;
    }

    private removeLearningContent() {
        this.props.learningModuleVM.taskRunner.run(async () => {
            await this.props.learningModuleVM.taskRunner.waitFor(this.apiClient.deleteLearningContent(this.props.learningModuleVM.selectedLearningContentId));
            this.props.learningModuleVM.hideMediaUpload = true;
            this.notifications.addSuccess("Content Deleted", null, NotificationDuration.Standard);
            this.props.learningModuleVM.selectedLearningContentId = 0;
            this.props.learningModuleVM.refreshLOList();
        });
    }

    public render() {
        const learningContent = this.props.learningContent;
        var fileTitle = this.props.learningModuleVM.selectedLearningObject!.learningObjectTypeId === LearningObjectType.Information ? "Media Upload" : "Linked Resource";
        return (
            <div>
                <div>
                    <Card hidden={this.props.hidden}>
                        <CardHeader icon>
                            <CardIcon color="success">
                                <i className={`fas fa-file`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">{fileTitle}</h4>
                        </CardHeader>
                        <CardBody>
                            <Neo.Form validationDisplayMode={ValidationDisplayMode.Always} allDisabled={this.props.learningModuleVM.selectedLearningObject!.moderationState === ModerationState.Moderated && !this.props.allowAdminEdit}>
                                <Neo.GridLayout>
                                    <Neo.FormGroupFloating bind={learningContent.learningContent.meta.title} /><b />
                                    <Neo.FormGroupFloating bind={learningContent.learningContent.meta.mediaType} disabled={this.props.learningModuleVM.selectedLearningContentId !== 0}
                                        select={{
                                            itemSource: Data.StaticDataSource.fromEnum(LearningContentMediaType),
                                            onItemSelected: (item) => {
                                                this.setSelectedMediaType(learningContent.learningContent.mediaType as number)
                                            }
                                        }}
                                    /><b />
                                    <Neo.FormGroupFloating label="Alternative URL" placeholder="www.urlExample.com" bind={learningContent.learningContent.meta.externalResourceLocation}
                                        hidden={learningContent.learningContent.mediaType === LearningContentMediaType.YouTube || learningContent.learningContent.mediaType === LearningContentMediaType.Conversation
                                        } /><b />
                                    <Neo.FormGroupFloating label="URL Key" bind={learningContent.learningContent.meta.externalResourceLocationKey} hidden={learningContent.learningContent.mediaType !== LearningContentMediaType.YouTube} /><b />
                                    {
                                        learningContent.learningContent.mediaType !== LearningContentMediaType.Conversation || <RichTextEditor labelText="Initial Post" bind={learningContent.learningContent.meta.initialPost} />
                                    }
                                </Neo.GridLayout>
                            </Neo.Form>
                            <Neo.FormGroupFloating hidden={this.ShowFileExtensionsTypeBinding(learningContent.learningContent.mediaType as number)}
                                display={learningContent.learningContent.meta.allowedFileExtensions} /><b />

                            <Neo.FileContext
                                uploadEndpoint={learningContent.learningContent.mediaType === LearningContentMediaType.Video ? this.apiClient.getUploadBigVideoFileToBlobUrl() : (learningContent.learningContent.mediaType === LearningContentMediaType.SCORM ? this.apiClient.getUploadBigSCORMFileToBlobUrl() : this.apiClient.getUploadFileToBlobUrl(learningContent.learningContent.mediaType as number))}
                                afterUpload={this.afterUpload.bind(this)}
                                taskRunner={this.props.viewModel.taskRunner}
                                allowedExtensions={this.allowedExtensions}>
                                <Neo.FileDropArea className="mb-10" hidden={learningContent.learningContent.mediaType === LearningContentMediaType.Link
                                    || learningContent.learningContent.mediaType === LearningContentMediaType.YouTube
                                    || learningContent.learningContent.mediaType === LearningContentMediaType.Conversation
                                    || this.props.learningContent.learningContent.mediaType === null
                                    || this.props.viewModel.fileDescriptorList.length !== 0
                                }>
                                    <Neo.FileUploadButton disabled={this.props.learningModuleVM.selectedLearningContentId !== 0} >Select a file</Neo.FileUploadButton> or drop a file here.
                                    <Neo.FileUploadProgress variant={"info"} />
                                </Neo.FileDropArea>
                            </Neo.FileContext>
                            <br /><br />
                            <Neo.GridLayout md={2} lg={3} className="mt-3" hidden={learningContent.learningContent.mediaType === LearningContentMediaType.YouTube || learningContent.learningContent.mediaType === LearningContentMediaType.Conversation || learningContent.learningContent.mediaType === LearningContentMediaType.Link}>
                                <NeoGrid.Grid className="mb-10 mt-10" items={this.props.viewModel.fileDescriptorList}>
                                    {(file, fileMeta) => (
                                        <NeoGrid.Row>
                                            <NeoGrid.Column label="File Name" display={fileMeta.fileName} />
                                            <NeoGrid.Column label="File Type" display={fileMeta.mimeType} />
                                            {/* <NeoGrid.ButtonColumn showDelete onClick={() => this.deleteFile(fileMeta.fileName.value, fileMeta.fileDescriptorId.value)} /> */}
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.GridLayout>
                            <Neo.Button text={this.props.learningModuleVM.selectedLearningObjectId !== 0 ? `Update Learning Content` : "Save Learning Content"}
                                disabled={(learningContent.learningContent.mediaType === LearningContentMediaType.SCORM
                                    && (learningContent.learningContent.externalResourceLocation === null || learningContent.learningContent.externalResourceLocation.length === 0))
                                    || learningContent.learningContent.title === "" || learningContent.learningContent.mediaType === null}
                                onClick={() => this.saveLearningContent()} isOutline className="mt-2 mr-2" icon="fa-plus" variant="success" />
                            <Neo.Button text="Upload a new media file." onClick={() => { this.setAllowedExtensions(this.props.learningContent.mediaTypeFetched); this.props.viewModel.fileDescriptorList = new List(FileLookup); this.props.learningModuleVM.selectedLearningContentId = 0 }} />
                            <Neo.Button text="Delete Linked Resource"
                                hidden={this.props.learningModuleVM.selectedLearningObject!.learningObjectTypeId === LearningObjectType.Information}
                                disabled={(this.props.learningModuleVM.selectedLearningContentId === 0 || this.props.learningModuleVM.selectedLearningObject!.moderationState === ModerationState.Moderated) && !this.props.allowAdminEdit}
                                onClick={() => { this.removeLearningContent(); this.props.learningModuleVM.LearningContentFileComponentVM = new LearningContentFileComponentVM(); }} className="mt-2 mr-2" icon="trash" variant="danger" style={{ float: "right" }} />
                            {
                                this.props.viewModel.hideContentNotes ||
                                <Neo.Card className="moderation-notes">
                                    <RichTextEditor readOnly={true} bind={learningContent.learningContent.moderation.meta.moderationNotes} viewModel={this.props.viewModel.richTextEditorVmLearningContent} labelText="Module Moderation Notes" />
                                </Neo.Card>
                            }
                            <Neo.Button hidden={this.props.learningModuleVM.selectedLearningModule.moderationState !== ModerationState.Review} text={this.props.viewModel.hideContentNotes ? "Content Moderation Notes" : "Hide Content Moderation Notes"} isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.props.viewModel.hideContentNotes = !this.props.viewModel.hideContentNotes} />
                        </CardBody>
                    </Card>
                </div>

                <Neo.Modal title="Add Content" size="lg"
                    show={this.props.viewModel.showAddContentModal && !(this.props.learningModuleVM.selectedLearningObject!.learningObjectTypeId === LearningObjectType.Quiz || this.props.learningModuleVM.selectedLearningObject!.learningObjectTypeId === LearningObjectType.FinalQuiz)}
                    onClose={() => this.noAddContent()}
                    acceptButton={{
                        text: "Yes", variant: "info", icon: "save",
                        onClick: () => { this.yesAddContent() }
                    }} closeButton={{
                        text: "No", variant: "secondary", icon: "times",
                    }} >
                    <Neo.Loader task={this.props.viewModel.taskRunner}>
                        <div>{
                            <p>Do you wish to add another media item? </p>
                        }
                        </div>
                    </Neo.Loader>
                </Neo.Modal>

                <Neo.Modal title="Add Content" size="lg"
                    show={this.props.viewModel.showAddQuestionModal && !(this.props.learningModuleVM.selectedLearningObject!.learningObjectTypeId === LearningObjectType.Information)}
                    onClose={() => this.noAddQuestion()}
                    acceptButton={{
                        text: "Yes", variant: "info", icon: "save",
                        onClick: () => { this.yesAddQuestion() }
                    }} closeButton={{
                        text: "No", variant: "secondary", icon: "times",
                    }} >
                    <Neo.Loader task={this.props.viewModel.taskRunner}>
                        <div>{
                            <p>Do you wish to add a question? </p>
                        }
                        </div>
                    </Neo.Loader>
                </Neo.Modal>
            </div>
        )
    }
}