import { Attributes, Model, NeoModel, ValueObject } from '@singularsystems/neo-core';
import { ModerationState } from '../LearningObjects/ModerationState.enum'

@NeoModel
export default class ModerationObject extends ValueObject {

    constructor() {
        super()
        this.moderationState = ModerationState.Unmoderated
    }
    @Attributes.ChildObject(Model.UserEvent)
    public created: Model.UserEvent | null = null;

    public moderationState: ModerationState | null = null;

    @Attributes.ChildObject(Model.UserEvent)
    public started: Model.UserEvent | null = null;

    @Attributes.ChildObject(Model.UserEvent)
    public moderated: Model.UserEvent | null = null;

    @Attributes.Nullable()
    @Attributes.Integer()
    public reviewAssignedTo: number | null = null;

    @Attributes.ChildObject(Model.UserEvent)
    public reviewed: Model.UserEvent | null = null;

    public moderationNotes: string = "";

    // Client only properties / methods

    public approvedByModerator: boolean = false;

}