import { AppServices, Security } from '@singularsystems/neo-core';
import Types from './AppTypes';
import Axios from 'axios';
import AuthorisationService from '../Authorisation/Services/AuthorisationService';
import AuthorisationTypes from '../Authorisation/AuthorisationTypes';
import { AppConfig } from './Models/AppConfig';
import { NotificationServiceTypes } from '@singularsystems/neo-notifications';
import { AppDataService } from './Services/AppDataService';
import { RouteService } from './Services/RouteService';
import AppLayout from './Services/AppLayout';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import AsyncSelect from 'react-select/async';
import ReactSelect from 'react-select';
import { SketchPicker } from 'react-color';
import { OidcAuthenticationService } from '../Identity/Services/Security/OidcAuthenticationService';
import { RouteSecurityService } from '../Identity/Services/Security/RouteSecurityService';
import { ReportingTypes } from '@singularsystems/neo-reporting';

const appModule = new AppServices.Module("App", container => {

    // Config
    container.bind(Types.App.Config).to(AppConfig).inSingletonScope();
    container.bind(AuthorisationTypes.Config.ConfigModel).toService(Types.App.Config.symbol);

    container.bindConfig(ReportingTypes.ConfigModel, (c: AppConfig) => c.reportingConfig);
    container.bindConfig(NotificationServiceTypes.ConfigModel, (c: AppConfig) => c.notificationServerConfig);

    // Security
    container.bind(Types.Neo.Security.AuthenticationService).to(OidcAuthenticationService).inSingletonScope();
    container.bind(Types.Neo.Security.AuthorisationService).to(AuthorisationService).inSingletonScope();
    container.bind(Types.Neo.Security.SecurityService).to(Security.SecurityService).inSingletonScope();

    // Api clients
    container.bind(Types.Neo.Axios).toConstantValue(Axios);

    // Services
    container.bind(Types.App.Services.AppDataCache).to(AppDataService).inSingletonScope();
    container.bind(Types.App.Services.AppLayout).to(AppLayout).inSingletonScope();
    container.bind(Types.App.Services.RouteService).to(RouteService).inSingletonScope();

    // Components
    container.bind(Types.Neo.Components.Slider).toConstantValue(Slider);
    container.bind(Types.Neo.Components.Range).toConstantValue(Range);
    container.bind(Types.Neo.Components.SketchPicker).toConstantValue(SketchPicker);
    // We add these for the auto complete dropdowns
    container.bind(Types.Neo.Components.AsyncSelect).toConstantValue(AsyncSelect);
    container.bind(Types.Neo.Components.ReactSelect).toConstantValue(ReactSelect);
    container.bind(Types.Neo.Routing.RouteSecurityService).to(RouteSecurityService).inSingletonScope();
});

const appTestModule = new AppServices.Module("App", container => {

});

export { appModule, appTestModule };