import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { Types } from '../IdentityTypes';
import { AppService } from '../CommonIdentityTypes';
import TDPUser from '../Models/Security/TDPUser';
import UserProfileLookup from '../Models/Users/Lookups/UserProfileLookup';
import { NotificationDuration } from '../../App/Models/Enums/NotificationDuration.enum';
import { VerificationModalVM } from '../Components/VerificationModalVM';
import UserVerificationLookup from '../Models/Users/Lookups/UserVerificationLookup';
import SchoolLookup from '../../Organisations/Models/Lookups/SchoolLookup';
import OrgLevel from '../Models/Users/OrgLevel';
import { UserTypes } from '../../App/Models/Enums/UserTypes.enums';

@NeoModel
export default class UserProfileVM extends Views.ViewModelBase {

    public currentUser: UserProfileLookup;
    private user: TDPUser | null = null;
    public currentUserVerificationState: UserVerificationLookup = new UserVerificationLookup();
    public hasActiveOTP: boolean = false;
    public showConfetti: boolean = false;

    public showChangeSchool: boolean = false;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        private userApiClient = AppService.get(Types.Identity.ApiClients.UserProfileApiClient),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
        public userVerificationVM = new VerificationModalVM(taskRunner)) {
        super(taskRunner);
        this.user = authenticationService.user;
        this.currentUser = new UserProfileLookup();
    }

    public async initialise() {
        await this.taskRunner.run(() => this.getCurrentUser());
    }

    private async getCurrentUser() {
        if (this.user !== null) {
            let response = await this.userQueryApiClient.getRegisteredUser(this.user?.guid);
            let userData = response.data;
            this.currentUser.set(userData);
            this.ActiveOTP();
        }
    }

    public async onSchoolSelected(school: SchoolLookup) {
        if (this.currentUser.userType !== UserTypes.Admin) {
            var orgLevel = new OrgLevel();
            orgLevel.schoolId = school.schoolId;
            orgLevel.circuitId = school.circuitId;
            orgLevel.districtId = school.districtId;
            orgLevel.provinceId = school.provinceId;
            orgLevel.organisationId = school.organisationId;

            this.currentUser.organisationlevel = orgLevel;
            this.saveUser(false);
            this.notifications.addSuccess(`Your school has been updated.`, null, NotificationDuration.Standard);
        } else {
            this.notifications.addSuccess(`You cannot change your school.`, null, NotificationDuration.Standard);
        }
        this.showChangeSchool = false;
    }

    public saveUser(showNotification: boolean = true) {
        this.taskRunner.run(async () => {
            if (this.currentUser.isDirty) {
                await this.userApiClient.updateUserProfileAsync(this.currentUser.toJSObject());
                await this.getCurrentUser();
                if (showNotification) {
                    this.notifications.addSuccess(`Your details have been saved`, null, NotificationDuration.Standard);
                }
            }
            else {
                if (showNotification) {
                    this.notifications.addInfo(`No changes were made to your profile`, null, NotificationDuration.Standard);
                }
            }
        });
    }

    openVerificationModal() {
        this.userVerificationVM.show(this.currentUser.userGuid)
    }

    openOTPModal() {
        this.userVerificationVM.showPasswordModal(this.currentUser.userGuid)
    }

    public async ActiveOTP() {
        let response = await this.userQueryApiClient.getUserVerificationState(this.currentUser.userGuid);
        this.hasActiveOTP = response.data;
    }
}