import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class LearningObjectSearchCriteria extends ValueObject {

    public Title: string = "";

    public keyword: string = "";

    public tag3Ids: number[] = [];

    public tag3Id: number | null = 0;

    public learningModuleId: number | null = 0;
}