import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import Card from "../../../../Template/components/Card/Card";
import CardBody from "../../../../Template/components/Card/CardBody";
import CardHeader from "../../../../Template/components/Card/CardHeader";
import CardIcon from "../../../../Template/components/Card/CardIcon";
import OrganisationVM2 from "./OrganisationVM";
import SchoolView2 from "./SchoolView";

@observer
export default class ListOrganisationView extends Views.ViewBase<OrganisationVM2>{
    constructor(props: unknown) {
        super("Organisation", OrganisationVM2, props);
    }

    private navigateToSchoolGroup(schoolGroupStartName?: string, schoolGroupEndName?: string, schoolGroupStartId?: number, schoolGroupEndId?: number, circuitId?: number) {
        this.navigation.navigateToView(SchoolView2, { schoolGroupStartName: schoolGroupStartName, schoolGroupEndName: schoolGroupEndName, schoolGroupStartId: schoolGroupStartId, schoolGroupEndId: schoolGroupEndId, circuitId: circuitId });
    }

    public render() {
        return (
            <div>
                <div>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="success">
                                <i className={`fa fa-sitemap fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Organisations</h4>
                        </CardHeader>
                        <CardBody>
                            <NeoGrid.Grid items={this.viewModel.OrganistationList} initialSortAscending={false} initialSort={"organisationId"}>
                                {(organisation, organisastionMeta) => (
                                    <NeoGrid.RowGroup expandProperty={organisastionMeta.isExpanded}>
                                        <NeoGrid.Row>
                                            <NeoGrid.Column label="Name" display={organisastionMeta.organisationName} />
                                            <NeoGrid.Column label="Reg Number" display={organisastionMeta.registrationNumber} />
                                            <NeoGrid.Column label="Email" display={organisastionMeta.contactEmail} />
                                        </NeoGrid.Row>
                                        <NeoGrid.ChildRow>
                                            <NeoGrid.Grid items={organisation.provinces} initialSortAscending={true} initialSort={"provinceName"}>
                                                {(province, provinceMeta) => (
                                                    <NeoGrid.RowGroup expandProperty={provinceMeta.isExpanded}>
                                                        <NeoGrid.Row>
                                                            <NeoGrid.Column display={provinceMeta.provinceName} />
                                                            <NeoGrid.Column display={provinceMeta.provinceCode} />
                                                        </NeoGrid.Row>
                                                        <NeoGrid.ChildRow>
                                                            <NeoGrid.Grid items={province.districts} initialSortAscending={true} initialSort={"districtName"}>
                                                                {(district, districtMeta) => (
                                                                    <NeoGrid.RowGroup expandProperty={districtMeta.isExpanded}>
                                                                        <NeoGrid.Row>
                                                                            <NeoGrid.Column display={districtMeta.districtName} />
                                                                            <NeoGrid.ButtonColumn><Neo.Button variant="success" isOutline icon="import"
                                                                                onClick={() => this.viewModel.showMoveProvince = !this.viewModel.showMoveProvince} >Change Province</Neo.Button></NeoGrid.ButtonColumn>
                                                                            <NeoGrid.Column hidden={!this.viewModel.showMoveProvince} label="Select province to move to" bind={districtMeta.moveToProvinceId} select={{
                                                                                items: organisation.provinces,
                                                                                onItemSelected: () => this.viewModel.moveDistrictToProvince(district, province, organisation.provinces)
                                                                            }} />

                                                                        </NeoGrid.Row>
                                                                        <NeoGrid.ChildRow>
                                                                            <NeoGrid.Grid items={district.circuits} initialSortAscending={true} initialSort={"circuitName"}>
                                                                                {(circuit, circuitMeta) => (
                                                                                    <NeoGrid.RowGroup expandProperty={circuitMeta.isExpanded}>
                                                                                        <NeoGrid.Row>
                                                                                            <NeoGrid.Column display={circuitMeta.circuitName} />
                                                                                            <NeoGrid.ButtonColumn><Neo.Button variant="success" isOutline icon="import"
                                                                                                onClick={() => this.viewModel.showMoveDistrict = !this.viewModel.showMoveDistrict} >Change District</Neo.Button></NeoGrid.ButtonColumn>
                                                                                            <NeoGrid.Column hidden={!this.viewModel.showMoveDistrict} label="Select district to move to" bind={circuitMeta.moveToDistrictId} select={{
                                                                                                items: province.districts,
                                                                                                onItemSelected: () => this.viewModel.moveCircuitToDistrict(circuit, district, province.districts)
                                                                                            }} />
                                                                                        </NeoGrid.Row>
                                                                                        <NeoGrid.ChildRow>
                                                                                            <NeoGrid.Grid items={circuit.groupedschools} initialSortAscending={true}>
                                                                                                {(school, schoolMeta) => (
                                                                                                    <NeoGrid.Row>
                                                                                                        <NeoGrid.Column display={schoolMeta.schoolGroupName} />
                                                                                                        <NeoGrid.ButtonColumn>
                                                                                                            <Neo.Button variant="success" isOutline icon="import"
                                                                                                                onClick={() => this.navigateToSchoolGroup(schoolMeta.schoolGroupStartName.value, schoolMeta.schoolGroupEndName.value, schoolMeta.schoolGroupStartId.value, schoolMeta.schoolGroupEndId.value, schoolMeta.circuitId.value)} >See Schools</Neo.Button>
                                                                                                        </NeoGrid.ButtonColumn>
                                                                                                    </NeoGrid.Row>
                                                                                                )}
                                                                                            </NeoGrid.Grid>
                                                                                        </NeoGrid.ChildRow>

                                                                                    </NeoGrid.RowGroup>
                                                                                )}
                                                                            </NeoGrid.Grid>

                                                                        </NeoGrid.ChildRow>
                                                                    </NeoGrid.RowGroup>
                                                                )}
                                                            </NeoGrid.Grid>
                                                        </NeoGrid.ChildRow>
                                                    </NeoGrid.RowGroup>
                                                )}
                                            </NeoGrid.Grid>
                                        </NeoGrid.ChildRow>
                                    </NeoGrid.RowGroup>
                                )}
                            </NeoGrid.Grid>

                            {this.viewModel.OrganistationList.length === 0 &&
                                <div className="col-md-12 p-0 text-center">
                                    <p>No Records</p>
                                </div>}
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}
