import React from 'react';
import cardBodyStyles from '../../assets/tss/material-dashboard-react/components/CardStyles/CardBodyStyle';
import classNames from 'classnames';

interface ICardBodyProps {
  className?: string,
  plain?: boolean,
  profile?: boolean,
  children: React.ReactNode,
  background?: boolean,
  formHorizontal?: boolean,
  pricing?: boolean,
  signup?: boolean,
  color?: boolean,
  calendar?: boolean,
}

export default function CardBody(props: ICardBodyProps) {
  const classes = cardBodyStyles();
  const {
    className,
    children,
    background,
    plain,
    formHorizontal,
    pricing,
    signup,
    color,
    profile,
    calendar,
    ...rest
  } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyBackground]: background,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyFormHorizontal]: formHorizontal,
    [classes.cardPricing]: pricing,
    [classes.cardSignup]: signup,
    [classes.cardBodyColor]: color,
    [classes.cardBodyProfile]: profile,
    [classes.cardBodyCalendar]: calendar,
    [className as any]: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}