import { Data } from "@singularsystems/neo-core";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import LearningPathwayPagerLookup from "../../Models/Lookups/LearningPathways/LearningPathwayPagerLookup";
import { LearningPathwayType } from "../../Models/LearningPathways/LearningPathwayType.enum";
import LearningPathwayEditor from './LearningPathwayEditor'
import { UserLearningStatus } from "../../Models/UserLearning/UsersLearningStatus.enum"
import { LearningPathwayPagerVM } from "./LearningPathwayPagerVM";
import DeleteModal, { DeleteModalVm } from '../../../App/Components/DeleteModal';
import ModuleDragAndDropComponent from "../DragAndDrop/ModuleDragAndDrop";
import LearningModuleLookup from "../../Models/Lookups/LearningModuleLookup";
import GridContainer from "../../../Template/components/Grid/GridContainer";
import GridItem from "../../../Template/components/Grid/GridItem";
import Card from "../../../Template/components/Card/Card";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardBody from "../../../Template/components/Card/CardBody";
import CardIcon from "../../../Template/components/Card/CardIcon";
import CardFooter from "../../../Template/components/Card/CardFooter";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import ForumDrawer from "../../../Forum/Components/ForumComponents/ForumDrawer";
import Confetti from 'react-confetti';
import BadgeAwardModal from '../../Components/BadgeAwardModal';
import PLCConversation from '../../../Forum/Components/ForumComponents/PLC/PLCConversation';
import ModulePreview from "./ModulePreview";

interface ILearningPathwayProps {
  viewModel: LearningPathwayPagerVM;
  GoToModule: (moduleId: number, preview: boolean) => void;
}

@observer
export default class LearningPathwayPager extends React.Component<ILearningPathwayProps> {

  public nextBadge = async () => {
    const response = await this.props.viewModel.showNextBadge();
    if (response === 0 && this.props.viewModel.allDone) {
      this.props.viewModel.showBadgeAward = false;
    }
  }

  private afterUpload(fileKey: string) {
    this.props.viewModel.fileKey = fileKey;
    this.props.viewModel.SendEmailMeta.fileKey = fileKey;
  }

  public render() {
    const viewModel = this.props.viewModel;
    return (
      <div id="Main-div">
        <Confetti hidden={!viewModel.showBadgeAward} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="success">
                  <i className={`icon fa fa-clipboard fa-2x`}></i>
                </CardIcon>
                <h4 className="Card-icon-header-text">Learning Pathways</h4>
                <Neo.Button hidden={viewModel.hideSearchCriteria} icon="road" isOutline text="Create Learning Pathway" variant="success" onClick={() => { viewModel.CreatePathway() }} />
                <Neo.Button hidden={!viewModel.hideSearchCriteria} icon="eye" isOutline text="Show All My Pathways" variant="success" onClick={() => { viewModel.ClearFilters() }} />
              </CardHeader>
              <CardBody>
                <div hidden={viewModel.hideSearchCriteria}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <Neo.FormGroupFloating label="Search" bind={viewModel.learningPathwayCriteria.meta.keyword} placeholder="Title" />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={viewModel.learningPathwayCriteria.meta.InProgress.value}
                            onClick={() => { viewModel.learningPathwayCriteria.meta.InProgress.value = !viewModel.learningPathwayCriteria.meta.InProgress.value }}
                            checkedIcon={<Check className={"checked-Icon"} />}
                            icon={<Check className={"unchecked-Icon"} />}
                            classes={{
                              checked: 'checked',
                              root: "check-root",
                            }}
                          />
                        }
                        classes={{
                          label: "checkbox-label",
                        }}
                        label="In Progress"
                      />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={1}>
                      <Neo.Button text="Search" icon="search" variant="info" style={{ top: 5 }} isOutline onClick={() => viewModel.SearchPathways()} />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={1}>
                      <Neo.Button text="Clear Search" icon="clear" variant="warning" style={{ top: 5 }} isOutline onClick={() => viewModel.ClearFilters()} />
                    </GridItem>
                  </GridContainer>
                </div>

                <GridContainer >
                  <GridItem xs={12} sm={12} md={12} >
                    <Neo.Pager pageManager={viewModel.learningPathwayPagerManager}
                      pageControlProps={{
                        firstText: "",
                        prevText: "Prev",
                        nextText: "Next",
                        lastText: "",
                        alignment: "center",
                        autoHide: false,
                        pageSizeLabel: "Show"
                      }}>

                      <NeoGrid.Grid<LearningPathwayPagerLookup> >
                        {(learningPathway: LearningPathwayPagerLookup, learningPathwayMeta) => (
                          <NeoGrid.Row className={viewModel.selectedLearningPathwayId === learningPathway.learningPathwayId ? "selected-item-css" : "bg-none"} onClick={() => { viewModel.SetSelectedLearningPathway(null, learningPathway) }}>
                            <NeoGrid.Column label="Title" display={learningPathwayMeta.title} />
                            <NeoGrid.Column label="Mine/Shared/PLC" display={learningPathwayMeta.learningPathwayType} select={{ itemSource: Data.StaticDataSource.fromEnum(LearningPathwayType), renderAsText: true }} />
                            <NeoGrid.Column label="Completed?" display={learningPathwayMeta.isCompleted} />
                            <NeoGrid.Column label="Completed Date" display={learningPathwayMeta.completedDate} />
                            <NeoGrid.ButtonColumn label="Actions" >
                              <Neo.Button hidden={!viewModel.userCanSendEmailPathywayRole || learningPathway.createdByGuid !== viewModel.currentUserGuid || learningPathway.learningPathwayType !== LearningPathwayType.Mine || learningPathway.participants.length === 0} icon="envelope" isOutline variant="info" tooltip="Send Email" onClick={() => { viewModel.ShowSendPathwayEmailModal() }} />

                              <Neo.Button icon="eye" isOutline variant="info" tooltip="View" onClick={() => { viewModel.SetSelectedLearningPathway(null, learningPathway) }} />
                              <Neo.Button icon="fas fa-comments"
                                isOutline
                                hidden={viewModel.showConvoButton(learningPathway)}
                                variant="primary" tooltip="Conversations"
                                onClick={() => { viewModel.openForumDrawer(learningPathway) }} />
                              <Neo.Button icon="share-alt" isOutline variant="info" tooltip="Share your pathway with other users" onClick={() => { viewModel.ShowShareModal(learningPathway.learningPathwayId) }} />
                              <Neo.Button isOutline variant="info"
                                disabled={learningPathway.learningPathwayType === LearningPathwayType.PLC} icon="user-plus"
                                tooltip={viewModel.showConvoButton(learningPathway) ? "Invite users to your pathway" : "Invite other users to your PLC"}
                                onClick={() => { viewModel.ShowInviteModal(learningPathway.learningPathwayId, learningPathway.createdByGuid === viewModel.currentUserGuid, learningPathway.learningPathwayType === LearningPathwayType.Mine) }} />
                              <Neo.Button tooltip="Edit" isOutline variant="info" icon="edit" disabled={learningPathway.learningPathwayType === LearningPathwayType.PLC} onClick={() => { viewModel.EditPathway(learningPathway) }} />
                              <Neo.Button
                                isOutline
                                tooltip="Delete"
                                variant="danger"
                                icon="trash"
                                disabled={learningPathway.learningPathwayType === LearningPathwayType.PLC}
                                onClick={() => { viewModel.ShowDeleteLearningPathwayModal(learningPathway.learningPathwayId) }} />
                            </NeoGrid.ButtonColumn>
                          </NeoGrid.Row>
                        )}
                      </NeoGrid.Grid>
                    </Neo.Pager>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {viewModel.hideModules ||
          <Card>
            <CardHeader color="info" stats >
              <h4 className="card-title-white"> {viewModel.selectedLearningPathwayTitle === "" ? "Learning Pathway Modules" : "Learning Pathway Modules for " + viewModel.selectedLearningPathwayTitle}</h4>
              <p className="card-category-white">
                Drag and Drop modules to reorder
              </p>
            </CardHeader>
            <CardBody>
              <ModuleDragAndDropComponent items={viewModel.pathwayModules} setDNDItems={(items) => { viewModel.setDNDItems(items) }} showParticipantInfo={(value) => { viewModel.SetParticipantInfo(value) }} GoToModule={(ModuleId) => { this.props.GoToModule(ModuleId, false) }} />
            </CardBody>
            <CardFooter>
              <Neo.Button style={{ float: "left" }} isOutline text="Save Order" variant="success" onClick={() => viewModel.saveLoOrderChanges()} />
            </CardFooter>
          </Card>
        }

        {!viewModel.createModal || <LearningPathwayEditor GoToModule={(id, preview) => { viewModel.PreviewModule(id) }} show={viewModel.createModal} onClose={() => { viewModel.CloseCreateModal() }} learningPathwayId={viewModel.selectedLearningPathwayId} />}
        {!viewModel.showPreviewDrawer || <ModulePreview showPreviewDrawer={viewModel.showPreviewDrawer} moduleId={viewModel.moduleIdToPreview} onClose={() => { viewModel.showPreviewDrawer = false }} />}

        {/* To do Change the below Modals to components */}
        <Neo.Modal title="Share Learning Pathway" size="xl"
          show={viewModel.sharePathwayModal}
          onClose={() => { viewModel.CloseShareModal() }}
          acceptButton={{
            text: "Share", variant: "info", isOutline: true, icon: "share", disabled: !viewModel.learningPathwayShareModel.isValid,
            onClick: () => viewModel.SharePathway()
          }} closeButton={{
            text: "Cancel", variant: "danger", icon: "times", isOutline: true
          }} >
          <Neo.Loader task={viewModel.taskRunner}>
            <div>                
              <p>Add Users Using Email.</p>
              <div hidden={!viewModel.hasShareErrors}><p>There was an error processing some of the shares.</p>
                <p>These email addresses were <strong>successful:</strong></p>
                <NeoGrid.Grid readOnly={true} items={viewModel.successfulSharedEmails} >
                  {(successEmail, successEmailMeta) => (
                    <NeoGrid.Row>
                      <NeoGrid.Column label="Successful Email Addresses" readOnly={true} bind={successEmailMeta.userEmail} />
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
                <p>These email addresses were <b>not successful</b>, please edit the email addresses to correct them or remove them from this list:</p>
              </div>
              <div>
                <NeoGrid.Grid items={viewModel.learningPathwayShareModel} showAddButton>
                  {(shareModel, shareModelMeta) => (
                    <NeoGrid.Row>
                      <NeoGrid.Column label={!viewModel.hasShareErrors ? `Users Email Addresses` : `Failed Email Addresses`} className={!viewModel.hasShareErrors ? "" : "invalid-column"} bind={shareModelMeta.userEmail} />
                      <NeoGrid.ButtonColumn showDelete deleteButton={{ isOutline: true, variant: "danger" }} />
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
              </div>
            </div>
          </Neo.Loader>
        </Neo.Modal>

        <Neo.Modal title={"Invite Users to a Professional Learning Community (PLC)"} size="xl"
          show={viewModel.invitePathwayModal}
          onClose={() => { viewModel.CloseInviteModal() }}
          acceptButton={{
            text: "Invite", variant: "info", icon: "share",
            onClick: () => viewModel.InviteToPathway()
          }} closeButton={{
            text: "Cancel", variant: "danger", icon: "times", isOutline: true
          }} >
          <Neo.Loader task={viewModel.taskRunner}>
            <div>
              <p>Add Users Using Email.</p>
              <div hidden={!viewModel.hasShareErrors}><p>There was an error processing some of the invites.</p>
                <p>These email addresses were <strong>successful:</strong></p>
                <NeoGrid.Grid readOnly={true} items={viewModel.successfulSharedEmails} >
                  {(successEmail, successEmailMeta) => (
                    <NeoGrid.Row>
                      <NeoGrid.Column label="Successful Email Addresses" readOnly={true} bind={successEmailMeta.userEmail} />
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
                <p>These email addresses were <b>not successful</b>, please edit the email addresses to correct them or remove them from this list:</p>
              </div>
              <div>
               <Neo.Button hidden={!viewModel.userCanImportUserEmailRole|| !viewModel.isPathwayUser || !viewModel.userPathwayType } variant="success" isOutline icon="import"
                     onClick={() => viewModel.showLearningPathwayImportModal()} >Import Emails</Neo.Button>
                <NeoGrid.Grid items={viewModel.learningPathwayShareModel} showAddButton>
                  {(shareModel, shareModelMeta) => (
                    <NeoGrid.Row>
                      <NeoGrid.Column label={!viewModel.hasShareErrors ? `Users Email Addresses` : `Failed Email Addresses`} className={!viewModel.hasShareErrors ? "" : "invalid-column"} bind={shareModelMeta.userEmail} />
                      <NeoGrid.ButtonColumn showDelete deleteButton={{ isOutline: true, variant: "danger" }} />
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
              </div>
            </div>
          </Neo.Loader>
        </Neo.Modal>

        {!viewModel.showBadgeAward ||
          <BadgeAwardModal badgeAwardMessage={viewModel.badgeAwarded.badgeAwardMessage} badgeAwardDescription={viewModel.badgeAwarded.badgeDescription}
            badgeTitle={viewModel.badgeAwarded.badgeTitle} imageSrc={viewModel.badgeAwarded.blobUrl} onClose={() => { this.nextBadge() }} showBadgeAward={viewModel.showBadgeAward} />
        }

        <Neo.Modal title="You have new Shared Pathways" size="xl"
          show={viewModel.acceptSharedPathway}
          onClose={() => viewModel.acceptSharedPathway = false}
          acceptButton={{
            text: "Save your choices", variant: "info", icon: "share",
            onClick: () => viewModel.AcceptAndRejectSharedPathways()
          }}
          closeButton={{
            text: "Close", variant: "secondary", icon: "times"
          }} >
          <Neo.Loader task={viewModel.taskRunner}>
            <div>
              <p>New Shared Pathways.</p>
              <NeoGrid.Grid items={viewModel.sharedLearningPathways}>
                {(sharedLearingPathways, sharedLearingPathwayMeta) => (
                  <NeoGrid.Row className={viewModel.GetShareGridRowStyles(sharedLearingPathways.learningPathwayId)}>
                    <NeoGrid.Column label="Pathway Name" display={sharedLearingPathwayMeta.title} />
                    <NeoGrid.ButtonColumn >
                      <Neo.Button text="Accept" variant="info" isOutline onClick={() => { viewModel.AddPathwayToAccptedList(sharedLearingPathways) }} />
                    </NeoGrid.ButtonColumn>
                    <NeoGrid.ButtonColumn >
                      <Neo.Button text="Reject" variant="danger" isOutline onClick={() => { viewModel.AddPathwayToRejectedList(sharedLearingPathways) }} />
                    </NeoGrid.ButtonColumn>
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </div>
          </Neo.Loader>
        </Neo.Modal>

        <Neo.Modal title="You have new Learning Pathway Invites" size="xl"
          show={viewModel.acceptInvitedPathway}
          onClose={() => viewModel.acceptInvitedPathway = false}
          acceptButton={{
            text: "Save your choices", variant: "info", icon: "share",
            onClick: () => viewModel.AcceptAndRejectInvitedPathways()
          }}
          closeButton={{
            text: "Close", variant: "secondary", icon: "times"
          }} >
          <Neo.Loader task={viewModel.taskRunner}>
            <div>
              <p>New Shared Pathways.</p>
              <NeoGrid.Grid items={viewModel.InvitedLearningPathways}>
                {(invitedLearingPathways, invitedLearingPathwayMeta) => (
                  <NeoGrid.Row className={viewModel.GetShareGridRowStyles(invitedLearingPathways.learningPathwayId)}>
                    <NeoGrid.Column label="Pathway Name" display={invitedLearingPathwayMeta.title} />
                    <NeoGrid.ButtonColumn >
                      <Neo.Button text="Accept" variant="info" isOutline onClick={() => { viewModel.AddPathwayToAccptedList(invitedLearingPathways) }} />
                    </NeoGrid.ButtonColumn>
                    <NeoGrid.ButtonColumn >
                      <Neo.Button text="Reject" variant="danger" isOutline onClick={() => { viewModel.AddPathwayToRejectedList(invitedLearingPathways) }} />
                    </NeoGrid.ButtonColumn>
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </div>
          </Neo.Loader>
        </Neo.Modal>

        <Neo.Modal title="Module Participant Info" size="xl"
          show={viewModel.showParticipantInfo}
          onClose={() => { viewModel.CloseParticipantInfo() }}
          closeButton={{
            text: "Close", variant: "secondary", icon: "times"
          }} >
          <Neo.Loader task={viewModel.taskRunner}>
            <div>
              <NeoGrid.Grid items={viewModel.participantModules}>
                {(ParticipantModule, ParticipantModuleMeta) => (
                  <NeoGrid.Row>
                    <NeoGrid.Column display={ParticipantModuleMeta.userFullName} />
                    <NeoGrid.Column label="Status" display={ParticipantModule.participantModule?.meta.status} select={{ itemSource: Data.StaticDataSource.fromEnum(UserLearningStatus), renderAsText: true }} />
                    <NeoGrid.Column label="Last Mark" display={ParticipantModule.participantModule?.meta.lastQuizMark} />
                    <NeoGrid.Column label="Completed Date" display={ParticipantModule.participantModule?.meta.completedDate} />
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </div>
          </Neo.Loader>
        </Neo.Modal>

        <Neo.Modal title={"Send Email to all Members of Learning Pathway"} size="xl"
          show={viewModel.sendEmailModal}
          onClose={() => { viewModel.CloseSendPathwayEmailModal() }}
          acceptButton={{
            text: "Send", variant: "info", icon: "share",
            disabled: !viewModel.SendEmailMeta.isValid,
            onClick: () => viewModel.SendPathwayEmailModal()
          }} closeButton={{
            text: "Cancel", variant: "danger", icon: "times", isOutline: true
          }} >
          <Neo.Loader task={viewModel.taskRunner}>
            <div>
              <p>Please fill in the required Subject and Body below. Add an attachment if you wish. </p>
              <Neo.Input className="font-12 mb-2" placeholder="Subject" bind={viewModel.SendEmailMeta.meta.EmailSubject} />
              <Neo.Input input={{ rows: 5 }} className="font-12 mb-2" placeholder="Body" bind={viewModel.SendEmailMeta.meta.EmailBody} />
              <Neo.FileContext
                uploadEndpoint={viewModel.learningPathwayApiClient.UploadFile()}
                afterUpload={this.afterUpload.bind(this)}
                allowedExtensions={viewModel.allowedExtensions}
              >
                <Neo.FileDropArea className="mb-10" hidden={viewModel.fileKey.length > 0}>
                  <Neo.FileUploadButton>Select a file</Neo.FileUploadButton> or drop a file here.
                  <Neo.FileUploadProgress variant={"info"} />
                </Neo.FileDropArea>
                <GridItem md={6}>
                  <Neo.FormGroupFloating label="Attachment*" display={viewModel.SendEmailMeta.meta.fileKey} hidden={viewModel.fileKey.length === 0} />
                </GridItem>
              </Neo.FileContext>
            </div>
          </Neo.Loader>
        </Neo.Modal>

        <Neo.Modal size="xl"
                title={`Email Import`}
                 show={viewModel.showImportModal}
                 onClose={() => viewModel.CloseLearningPathwayImportModal() }                
                closeButton={{
                    text: "Cancel", variant: "secondary", icon: "times"
                }} >
                <div>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <h4 className="Card-icon-header-text">File selection</h4>
                            </CardHeader>
                            <CardBody>
                                <Neo.Loader task={viewModel.taskRunner}>
                                    <Neo.FileDropArea fileManager={viewModel.fileManager}>
                                        To upload files, drop file into this block or <Neo.Button variant="primary" onClick={() => viewModel.uploadRequested()}>Click here</Neo.Button>
                                        <Neo.FileUploadProgress fileManager={viewModel.fileManager} />
                                    </Neo.FileDropArea>
                                </Neo.Loader>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Neo.Modal>

        <ForumDrawer show={viewModel.editPathwayModal} onClose={() => viewModel.editPathwayModal = false} isMaximised={viewModel.editPathwayIsMaximised} maximiseWindow={() => viewModel.editPathwayIsMaximised = !viewModel.editPathwayIsMaximised}>
          <Card>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`icon fa fa-road fa-2x`} />
              </CardIcon>
              <h4 className="Card-icon-header-text">Edit Learning Pathway</h4>
              <Neo.FormGroupFloating label="Learning Pathway Name" bind={viewModel.selectedLearningPathway.meta.title} placeholder="Title" />
            </CardHeader>
            <CardBody>
              <NeoGrid.Grid items={viewModel.selectedLearningPathway.learningPathwayModules} allowSort >
                {(learningPathwayModule, learningPathwayModuleMeta) => (
                  <NeoGrid.Row >
                    <NeoGrid.Column display={learningPathwayModule.learningModule!.meta.title} />
                    <NeoGrid.Column label="Status" display={learningPathwayModuleMeta.status} select={{ itemSource: Data.StaticDataSource.fromEnum(UserLearningStatus), renderAsText: true }} />
                    <NeoGrid.Column label="Description" display={learningPathwayModule.learningModule?.meta.description} />
                    <NeoGrid.ButtonColumn label="Actions">
                      <Neo.Button icon="trash" isOutline variant="danger" onClick={() => {
                        viewModel.deleteLearningPathwayModule = true;
                        viewModel.editPathwayModal = false;
                        viewModel.selectedModuleId = learningPathwayModule.learningModuleId;
                      }} />
                    </NeoGrid.ButtonColumn>
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
              <Card>
                <CardHeader icon>
                  <CardIcon color="info">
                    <i className={`icon fa fa-search fa-2x`}></i>
                  </CardIcon>
                  <h4 className="Card-icon-header-text">Add Modules</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <label>Search</label>
                      <Neo.FormGroupFloating label="Search module by title" bind={viewModel.criteria.meta.title} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <label>Category Tag</label>
                      <Neo.AutoCompleteDropDown
                        bind={viewModel.criteria.meta.learningModuleTag1Id}
                        bindDisplay={viewModel.criteria.meta.learningModuleTag1Name}
                        displayMember="tagName"
                        itemSource={viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                        onItemSelected={(item) => { viewModel.FilterTag2ListSearch(item?.tag1Id) }}
                        loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                        minCharacters={2}
                        typeToSearchPrompt="Start typing for search options"
                        menuPosition="fixed"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Neo.FormGroup label="Sub-Category Tag (based on Category Tag)" bind={viewModel.criteria.meta.learningModuleTag2Id} select={{
                        items: viewModel.learningModuleTag2FilteredList,
                        allowNulls: true,
                        displayMember: "tagName",
                        onItemSelected: (item) => viewModel.FilterModuleTag3ListSearch(item?.tag2Id)
                      }} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Neo.FormGroup label="Topic (Based on Sub-category Tag)" bind={viewModel.criteria.meta.learningModuleTag3Id} select={{
                        items: viewModel.learningModuleTag3FilteredList,
                        allowNulls: true
                      }} />
                    </GridItem>
                    <GridItem xs={2} sm={2} md={4}>
                      <Neo.Button isOutline variant="info" text="Search" icon="search" type="submit" onSubmit={() => viewModel.learningModulePageManager.refreshData()} onClick={() => viewModel.learningModulePageManager.refreshData()} />
                      <Neo.Button isOutline text="Clear Search" variant="warning" icon="eraser" onClick={() => viewModel.ClearModuleFilters()} />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <Neo.Pager
                        pageManager={viewModel.learningModulePageManager}
                        pageControlProps={{
                          firstText: "",
                          prevText: "Prev",
                          nextText: "Next",
                          lastText: "",
                          alignment: "center",
                          autoHide: false,
                          pageSizeLabel: "Show"
                        }}>
                        <NeoGrid.Grid<LearningModuleLookup>>
                          {(learningModule: LearningModuleLookup, learningModuleMeta) => (
                            <NeoGrid.Row className={viewModel.IsSelected(learningModule.learningModuleId) ? "selected-item-css" : "bg-none"}>
                              <NeoGrid.Column label="Title" display={learningModuleMeta.learningModuleTitle} />
                              <NeoGrid.Column label="Learning Objective" display={learningModuleMeta.description} />
                              <NeoGrid.ButtonColumn label="Actions" >
                                <Neo.Button variant="info" isOutline icon="eye" tooltip="Preview" onClick={() => viewModel.PreviewModule(learningModule.learningModuleId)} />
                                <Neo.Button variant="success" isOutline hidden={viewModel.IsSelected(learningModule.learningModuleId)} icon="plus" tooltip="Add" onClick={() => { viewModel.AddModuleToPathway(learningModule) }} />
                                <Neo.Button variant="danger" isOutline hidden={!viewModel.IsSelected(learningModule.learningModuleId)} icon="times" tooltip="Remove" onClick={() => { viewModel.RemoveModule(learningModule) }} />
                              </NeoGrid.ButtonColumn>
                            </NeoGrid.Row>
                          )}
                        </NeoGrid.Grid>
                      </Neo.Pager>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </CardBody>
            <CardFooter>
              <Neo.Button icon="save" variant="success" text="Save" onClick={() => viewModel.SaveLearningPathway()} />
            </CardFooter>
          </Card>
        </ForumDrawer>
        {viewModel.showForumModal && <ForumDrawer show={viewModel.showForumModal} onClose={() => viewModel.closeForumModal()} isMaximised={viewModel.isMaximised} maximiseWindow={() => viewModel.isMaximised = !viewModel.isMaximised}>
          <PLCConversation learningPathwayId={viewModel.selectedConvoId as number} forumId={viewModel.pathwayForumId} />
        </ForumDrawer>}


        <DeleteModal viewModel={new DeleteModalVm()} Title="Delete Pathway Module" show={viewModel.deleteLearningPathwayModule} OnDelete={() => { viewModel.DeleteModule() }} onClose={() => { viewModel.deleteLearningPathwayModule = false }} />
        <DeleteModal viewModel={new DeleteModalVm()} Title="Delete Learning Pathway" show={viewModel.deleteLearningPathway} OnDelete={() => { viewModel.DeleteLearningPathway() }} onClose={() => { viewModel.deleteLearningPathway = false }} />
      </div >
    )
  }
}