import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class CircuitLookup extends LookupBase {

    public circuitId: number = 0;

    public circuitName: string = "";

    public description: string = "";

    public districtId: number = 0;

    // Client only properties / methods
}