import { NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { AppService, Types } from "../IdentityTypes";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration";
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";
import TermsAndConditionLookup from "../Models/Lookups/TermsAndConditionLookup";
import { AxiosResponse } from "axios";
import ForumDrawer from "../../Forum/Components/ForumComponents/ForumDrawer";
import CardFooter from "../../Template/components/Card/CardFooter";
import GridItem from "../../Template/components/Grid/GridItem";
import { FormGroup } from "@singularsystems/neo-react/dist/ReactComponents/_Exports";

interface IIntroductionReadComponentProps {
}

@NeoModel
export class IntroductionReadComponentVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private introductionReadApiClient = AppService.get(Types.Identity.ApiClients.IntroductionReadApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
        super(taskRunner);
    }

    //Properties    
    public termsAndConditions: TermsAndConditionLookup = new TermsAndConditionLookup();
    public hasHidden: boolean = false;
    public dontShowAgain: boolean = false;
    public showIntroduction: boolean = false;
    public introductionUrl: string = "";

    //Methods
    public async initialise() {
        if (!this.authenticationService.hasSeenIntroduction) {
            var result = await this.introductionReadApiClient.hasHidden();
            if (result.status === 200) {
                if (result.data) {
                    this.authenticationService.hasSeenIntroduction = true;
                } else {
                    var resp2 = await this.introductionReadApiClient.get();
                    if (resp2.status === 200) {
                        this.introductionUrl = resp2.data.introductionUrl
                        if (this.introductionUrl !== "") {
                            this.showIntroduction = true;
                        }
                    }
                }
            }
        }
    }

    private isValidResponse(response: AxiosResponse): boolean {
        var result = false;
        if (response.status === 200) {
            result = true;
        }
        return result;
    }

    public async accept() {
        await this.taskRunner.run(async () => {
            var showMess = true;
            if (!this.authenticationService.hasSeenIntroduction) {
                this.authenticationService.hasSeenIntroduction = true;
                this.showIntroduction = false;
                if (this.dontShowAgain) {
                    var result2 = await this.introductionReadApiClient.hide();
                    if (result2.status !== 200) {
                        showMess = false;
                        this.notifications.addDanger(`Introduction could not be hidden due to technical error!`, null, NotificationDuration.Standard);
                    }
                }
            }
            if (showMess) {
                this.notifications.addSuccess(`Introduction finished.`, null, NotificationDuration.Standard);
            }
        });
    }
}

@observer
export default class IntroductionReadComponent extends React.Component<IIntroductionReadComponentProps>{

    private viewModel: IntroductionReadComponentVM = new IntroductionReadComponentVM();

    constructor(props: IIntroductionReadComponentProps) {
        super(props);
        this.viewModel = new IntroductionReadComponentVM();
    }

    private accept() {
        this.viewModel.accept();
    }

    public componentDidMount() {
        this.viewModel.initialise();
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <ForumDrawer show={this.viewModel.showIntroduction} onClose={() => { this.accept() }} isMaximised={true} maximiseWindow={() => { }} >

                            <Card>
                                <CardHeader icon>
                                    <CardIcon color="success">
                                        <i className={`fa fa-address-card fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">Introduction</h4>
                                </CardHeader>
                                <CardBody>
                                    <div style={{ border: "1px solid #000000" }}>
                                        {<iframe title="Introduction" src={this.viewModel.introductionUrl} width={'100%'} height={600} />}
                                    </div>
                                    <div>
                                        <FormGroup style={{textAlign:'center',fontSize:23}} bind={this.viewModel.meta.dontShowAgain} label="Do not show me again (unless the introduction is updated)." />
                                    </div>
                                </CardBody>
                                <CardFooter>

                                    <GridItem xs={6} sm={6} md={6}></GridItem>
                                    <GridItem xs={6} sm={6} md={6}>
                                        <Neo.Button text="Close" onClick={() => this.accept()} variant="success" />
                                    </GridItem>
                                </CardFooter>
                            </Card>
                        </ForumDrawer>
                    </div>
                </section >
            </div >
        )
    }
}