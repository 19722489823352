import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import Race from '../../Models/Educators/Race';
export interface IRaceQueryApiClient {

    /**
     * This method will return a sepcific Races
     * @returns Race list
     */
    GetRaceList(): AxiosPromise<Array<Model.PlainTrackedObject<Race>>>;

    /**
     * This method will check if a specific race is linked  SACE Educators, if so, we dont allow delete
     * @param raceId The page request
     * @returns boolean
     */
    checkRaceLinks(raceId: number): AxiosPromise<boolean>;

    // Client only properties / methods
}

@injectable()
export default class RaceQueryApiClient extends Data.ApiClientBase implements IRaceQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/RaceQuery`);
    }

    public GetRaceList(): AxiosPromise<Array<Model.PlainTrackedObject<Race>>> {
        return this.axios.get(`${this.apiPath}/Races`);
    }

    public  checkRaceLinks(raceId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/CheckRaceLinks/${raceId}`);
    }

}