import { Attributes, List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import Tag2 from './Tag2';

@NeoModel
export default class Tag1 extends ModelBase {

    public tag1Id: number = 0;

    @Rules.StringLength(100)
    @Attributes.Display("Category(T1)")
    public tagName: string = "";

    public tagDescription: string = "";

    public tags2 = new List(Tag2);

    public isExpanded: boolean = false;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        rules.warnWhen(c => c.tagName.length >= 100, "Category(T1) should be less than or equal to 100.");
    }
}