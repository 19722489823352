import GridContainer from '../../Template/components/Grid/GridContainer';
import GridItem from '../../Template/components/Grid/GridItem';
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import CardIcon from '../../Template/components/Card/CardIcon';
import React from 'react';
import DashboardVM from './DashboardVM';
import { observer } from 'mobx-react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import CardFooter from '../../Template/components/Card/CardFooter';
import ForumDrawer from '../../Forum/Components/ForumComponents/ForumDrawer';
import CardBody from '../../Template/components/Card/CardBody';
import SelfAssessmentLookup from '../../Learning/Models/Lookups/SelfAssessmentLookup';
import TopicComponent from '../../Forum/Components/ForumComponents/TopicComponent';
import DashboardBadgeLookup from '../Models/DashboardBadgeLookup';
import BadgeAward from '../../Learning/Components/BadgeAward';
import NavPills from '../../Template/components/Panels/NavPills';
import UserSelfAssessmentLookup from "../Models/UserAssessmentLookup";
import { Tooltip } from '@material-ui/core';
import { truncateString } from '../../App/HelperClasses/GlobalHelpers';
import { SpinnerRoundOutlined } from 'spinners-react';
import LearningModuleLookup from '../../Learning/Models/Lookups/LearningModuleLookup';
import UserModulePager from './UserModulePager';
import UserPathwayPager from './UserPathwayPager';
import { LearningObjectType } from '../../Learning/Models/LearningObjects/LearningObjectType.enum';
import ModuleObjectsConsumptionLookup from '../../Learning/Models/ContentCosumption/ModuleObjectsConsumptionLookup';
import TDPUser from '../../Identity/Models/Security/TDPUser';
import { AppService } from '../../App/Services/AppService';
import { Types } from '../../Identity/IdentityTypes';
import { UserTypes } from '../../App/Models/Enums/UserTypes.enums';
import StartModuleModal from "../../Learning/Components/StartModule";
import { ReminderFrequency } from '../../Learning/Models/Reminders/ReminderFrquency.enum';
import "../Styles/Dashboard.scss";
import IntroductionReadComponent from '../../Identity/Components/IntroductionReadComponent';

interface IDashboardProps {
  GoToPathway: (id: number) => void;
  GotoSelfAssessment: (assessmentId: number) => void;
  ReviewSelfAssessment: (userAssessmentId: number) => void;
  GoToModule: (moduleId: number, preview: number) => void;
  GoToProfile: () => void;
  GoToPathwayModule: (moduleId: number, pathwayId: number) => void;
}

@observer
export default class Dashboard extends React.Component<IDashboardProps> {
  private viewModel = new DashboardVM();
  private authService = AppService.get(Types.Neo.Security.AuthenticationService);

  constructor(props: IDashboardProps) {
    super(props)
    this.viewModel = new DashboardVM();
  }

  private goToProfile() {
    this.props.GoToProfile();
  }

  private moduleTitleSearchLink() {
    window.open('http://nicpd.online/help/search-title/play.mp4', '_blank');
  }

  private moduleCategorySearchLink() {
    window.open('http://nicpd.online/help/search-cat/play.mp4', '_blank');
  }

  private manageNotifications() {
    this.viewModel.showReminderFrequencyModal = true;
  }

  public render() {
    const viewModel = this.viewModel;
    const user = this.authService.user as TDPUser;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <i className="fa-fw fa fa-clipboard-check fa-2x" />
                </CardIcon>
                <h4 className="Card-icon-header-text">Recommendations</h4>
              </CardHeader>
              <CardBody>
                <p className="card-category">Questionnaires Done</p>
                <SpinnerRoundOutlined className="info-card-Title" enabled={viewModel.userAssessmentCount == null} />
                <h4 hidden={viewModel.userAssessmentCount == null} className="info-card-Title ">{viewModel.userAssessmentCount}</h4>
                <br />
                <p className="card-category">Questionnaires Available</p>
                <SpinnerRoundOutlined className="info-card-Title" enabled={viewModel.userAssessmentCount == null} />
                <h4 hidden={viewModel.userAssessmentCount == null} className="info-card-Title mb-2">{viewModel.assessmentCount}</h4>
              </CardBody>
              <CardFooter stats>
                <Neo.Button icon="clipboard-check" text="View Questionnaires" variant="warning" isOutline onClick={() => viewModel.GetAvailableAssessments()} />
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={9}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <i className=" fa fa-clipboard fa-2x" />
                </CardIcon>
                <h4 className="Card-icon-header-text">Search Modules</h4>
                {user.userType === UserTypes.Unverified.toString() &&
                  <GridContainer alignItems="flex-end">
                    <GridItem md={12}>
                      <div style={{ float: "right" }}><label style={{ color: "Black" }}><b>Please Note</b> You are not verified and will not be able to enjoy the full experience.&nbsp;&nbsp;</label>
                        <Neo.Button text={" Please verify."} variant="danger" onClick={() => this.goToProfile()} />
                      </div>
                    </GridItem>
                  </GridContainer>
                }
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>Search module by title<Neo.Button icon='question-circle' variant="link" tooltip={"How to search by title?"} onClick={() => this.moduleTitleSearchLink()} /></label>
                    <Neo.FormGroupFloating label={" "} bind={this.viewModel.moduleCriteria.meta.title} onSubmit={() => { }} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>Search by Category (e.g Subject)<Neo.Button icon='question-circle' variant="link" tooltip={"How to search by category?"} onClick={() => this.moduleCategorySearchLink()} /></label>
                    <Neo.AutoCompleteDropDown
                      bind={this.viewModel.moduleCriteria.meta.learningModuleTag1Id}
                      bindDisplay={viewModel.moduleCriteria.meta.learningModuleTag1Name}
                      displayMember="tagName"
                      itemSource={viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                      onItemSelected={(item) => { viewModel.FilterModuleTag2ListSearch(item?.tag1Id) }}
                      loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                      minCharacters={2}
                      typeToSearchPrompt="Start typing for search options"
                      menuPosition="fixed"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Neo.FormGroup label="Search by Sub-Category (based on Category)" bind={viewModel.moduleCriteria.meta.learningModuleTag2Id} select={{
                      items: viewModel.learningModuleTag2FilteredList,
                      allowNulls: true,
                      displayMember: "tagName",
                      onItemSelected: (item) => viewModel.FilterModuleTag3ListSearch(item?.tag2Id)
                    }} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Neo.FormGroup label="Topic (Based on Sub-category)" bind={viewModel.moduleCriteria.meta.learningModuleTag3Id} select={{
                      items: viewModel.learningModuleTag3FilteredList,
                      allowNulls: true
                    }} />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter stats>
                <GridItem md={8} sm={8} xs={8}>
                  <Neo.Button icon="search" text="Search" variant="info" isOutline onClick={() => viewModel.ViewModules()} />
                  <Neo.Button icon="save" text="Save my search" variant="success" isOutline onClick={() => viewModel.saveModuleSearchCriteria()} hidden={user.userType === UserTypes.Unverified.toString()} />
                  <Neo.Button icon="eraser" text="Clear my search" variant="warning" isOutline onClick={() => viewModel.clearModuleSearchCriteria()} />
                </GridItem>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <div style={{ marginTop: -20 }}>
          <GridContainer >
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <i className=" fa fa-comments fa-2x" />
                  </CardIcon>
                  <h5 className="Card-icon-header-text">Conversations</h5>
                </CardHeader>
                <CardBody>
                  <p className="card-category">Participated Topics</p>
                  <SpinnerRoundOutlined className="info-card-Title" enabled={viewModel.newMessageCount == null} />
                  <h3 hidden={viewModel.newMessageCount == null} className="info-card-Title"> {viewModel.topicCount}</h3>

                  <p className="card-category">Recent Messages</p>
                  <SpinnerRoundOutlined className="info-card-Title" enabled={viewModel.newMessageCount == null} />
                  <h3 hidden={viewModel.newMessageCount == null} className="info-card-Title"> {viewModel.newMessageCount}</h3>
                </CardBody>
                <CardFooter stats>
                  <Neo.Button icon="comments" text="View Conversations" variant="primary" isOutline hidden={user.userType === UserTypes.Unverified.toString()} onClick={() => viewModel.GetConversations()} />
                </CardFooter>
              </Card>
              <Card>
                <CardHeader color="success" icon>
                  <CardIcon color="success">
                    <i className=" fa fa-award fa-2x" />
                  </CardIcon>
                  <h5 className="Card-icon-header-text">Rewards</h5>
                </CardHeader>
                <CardBody>

                  <GridContainer>
                    <GridItem md={12}>
                      <p className="card-category-cptd" >CPTD Points</p>
                      <SpinnerRoundOutlined className="info-card-Title" enabled={viewModel.badgeCount == null} />
                      <h3 hidden={viewModel.badgeCount == null} className="info-card-Title">{viewModel.cptdPointsEarned === -1 ? 0 : viewModel.cptdPointsEarned}</h3>
                    </GridItem>
                    <GridItem md={6} sm={12} xs={12}>
                      <p className="card-category">My Badges</p>
                      <SpinnerRoundOutlined className="info-card-Title" enabled={viewModel.badgeCount == null} />
                      <h3 hidden={viewModel.badgeCount == null} className="info-card-Title">{viewModel.userBadgeCount}</h3>
                    </GridItem>
                    <GridItem md={6} sm={12} xs={12}>

                      <p className="card-category">Remaining Badges</p>
                      <SpinnerRoundOutlined className="info-card-Title" enabled={viewModel.badgeCount == null} />
                      <h3 hidden={viewModel.badgeCount == null} className="info-card-Title">{viewModel.badgeCount}</h3>
                    </GridItem>
                  </GridContainer>

                </CardBody>
                <CardFooter stats>
                  <Neo.Button icon="award" text="View My badges" hidden={user.userType === UserTypes.Unverified.toString()} variant="success" isOutline onClick={() => viewModel.ViewBadges()} />
                </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <Card>
                <CardBody>
                  <Neo.Button variant="primary" className='notification-btn' isOutline icon={this.viewModel.userReminder.reminderId > 0 ? "bell-slash" : "bell"} text="" tooltip={this.viewModel.userReminder.reminderId > 0 ? "Remove notifications" : "Notify me"} onClick={() => { this.viewModel.userReminder.reminderId > 0 ? this.viewModel.removeReminder() : this.manageNotifications() }} />
                  <NavPills
                    alignCenter={false}
                    color="rose"
                    tabs={[
                      {
                        tabIcon: "clipboard fa-2x",
                        tabButton: "My Modules",
                        tabContent: (
                          <GridItem md={12} sm={12} xs={12}>
                            <UserModulePager GoToModule={(moduleId, preview) => { if (user.userType !== UserTypes.Unverified.toString()) { this.props.GoToModule(moduleId, preview); } }} />
                          </GridItem>
                        ),
                      }, {
                        tabIcon: "road fa-2x",
                        tabButton: "My Learning Pathways",
                        tabContent: (
                          <GridItem md={12} sm={12} xs={12}>
                            <UserPathwayPager openLink={(id) => { if (user.userType !== UserTypes.Unverified.toString()) { this.props.GoToPathway(id); } }} GoToPathwayModule={(moduleId, pathwayId) => { if (user.userType !== UserTypes.Unverified.toString()) { this.props.GoToPathwayModule(moduleId, pathwayId) } }} GoToModule={(moduleId, preview) => { if (user.userType !== UserTypes.Unverified.toString()) { this.props.GoToModule(moduleId, preview) } }} />
                          </GridItem>
                        ),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>

        {/* Self assessments Drawer */}
        <ForumDrawer show={viewModel.showAssessmentDrawer} isMaximised={viewModel.assessmentDrawerMaximised}
          maximiseWindow={() => viewModel.assessmentDrawerMaximised = !viewModel.assessmentDrawerMaximised}
          onClose={() => viewModel.showAssessmentDrawer = false}>
          <Card>
            <CardBody>
              <NavPills
                alignCenter={false}
                color="rose"
                tabs={[
                  {
                    tabIcon: "clipboard fa-2x",
                    tabButton: "Available Questionnaires",
                    tabContent: (
                      <GridItem md={12} sm={12} xs={12}>
                        <Card>
                          <CardHeader icon>
                            <CardIcon color="success">
                              <i className={`icon fa fa-clipboard fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Available Questionnaires</h4>
                          </CardHeader>
                          <CardBody>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <Neo.FormGroupFloating label="Search for Questionnaires by title" bind={this.viewModel.assessmentCriteria.meta.keyword} />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} >
                                <label>Category Tag</label>
                                <Neo.AutoCompleteDropDown
                                  bind={this.viewModel.assessmentCriteria.meta.SelfAssessmentTag1Id}
                                  bindDisplay={this.viewModel.assessmentCriteria.meta.SelfAssessmentTag1Name}
                                  displayMember="tagName"
                                  itemSource={this.viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                                  onItemSelected={(item) => { this.viewModel.FilterAssessmentTag2ListSearch(item?.tag1Id) }}
                                  loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                                  minCharacters={2}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <Neo.FormGroup onSubmit={() => { viewModel.assessmentPagerManager.refreshData() }} label="Sub-Category Tag (based on Category Tag)" bind={viewModel.assessmentCriteria.meta.SelfAssessmentTag2Id} select={{
                                  items: viewModel.assessmentTag2FilteredList,
                                  allowNulls: true
                                }} />
                              </GridItem>
                              <GridItem xs={2} sm={2} md={4}>
                                <Neo.Button isOutline variant="info" text="Search" icon="search" type="submit" onClick={() => viewModel.assessmentPagerManager.refreshData()} />
                                <Neo.Button isOutline text="Clear Search" variant="warning" icon="eraser" onClick={() => viewModel.ClearAssessmentFilters()} />
                              </GridItem>
                            </GridContainer>

                            <Neo.Pager pageManager={this.viewModel.assessmentPagerManager}
                              pageControlProps={{
                                firstText: "",
                                prevText: "Prev",
                                nextText: "Next",
                                lastText: "",
                                alignment: "center",
                                autoHide: false,
                                pageSizeLabel: "Show"
                              }}>
                              <NeoGrid.Grid<SelfAssessmentLookup> >
                                {(assessment: SelfAssessmentLookup, assessmentMeta) => (
                                  <NeoGrid.Row >
                                    <NeoGrid.Column label="Title" display={assessmentMeta.selfAssessmentTitle} />
                                    <NeoGrid.Column label="Description">
                                      <Tooltip
                                        id="tooltip-top"
                                        title={assessment.selfAssessmentDescription}
                                        placement="top"
                                      >
                                        <p>{assessment.selfAssessmentDescription} </p>
                                      </Tooltip>
                                    </NeoGrid.Column>
                                    <NeoGrid.ButtonColumn label="Actions" >
                                      <Neo.Button icon="eye" isOutline variant="success" text="Start Questionnaire" tooltip="Start" onClick={() => this.props.GotoSelfAssessment(assessment.selfAssessmentId)} />
                                    </NeoGrid.ButtonColumn>
                                  </NeoGrid.Row>
                                )}
                              </NeoGrid.Grid>
                            </Neo.Pager>
                          </CardBody>
                        </Card>
                      </GridItem>
                    ),
                  }, {
                    tabIcon: "user fa-2x",
                    tabButton: "Past Recommendations",
                    tabContent: (
                      <GridItem md={12} sm={12} xs={12}>
                        <Card>
                          <CardHeader icon>
                            <CardIcon color="success">
                              <i className={`icon fa fa-user fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Past Recommendations</h4>
                          </CardHeader>
                          <CardBody>
                            <Neo.Pager pageManager={this.viewModel.userAssessmentPagerManager}
                              pageControlProps={{
                                firstText: "",
                                prevText: "Prev",
                                nextText: "Next",
                                lastText: "",
                                alignment: "center",
                                autoHide: false,
                                pageSizeLabel: "Show"
                              }}>
                              <NeoGrid.Grid<UserSelfAssessmentLookup> >
                                {(assessment: UserSelfAssessmentLookup, assessmentMeta) => (
                                  <NeoGrid.Row >
                                    <NeoGrid.Column label="Title" display={assessmentMeta.selfAssessmentTitle} />
                                    <NeoGrid.Column label="Description">
                                      <Tooltip
                                        id="tooltip-top"
                                        title={assessment.selfAssessmentDescription}
                                        placement="top"
                                      >
                                        <p>{assessment.selfAssessmentDescription} </p>
                                      </Tooltip>
                                    </NeoGrid.Column>
                                    <NeoGrid.Column label="Completed on" display={assessmentMeta.completedDate} dateProps={{ formatString: "dd MMM yyyy HH:mm" }} />
                                    <NeoGrid.ButtonColumn label="Actions" >
                                      <Neo.Button icon="eye" isOutline variant="success" text="View Recommendations" tooltip="Start" onClick={() => this.props.ReviewSelfAssessment(assessment.userSelfAssessmentId)} />
                                    </NeoGrid.ButtonColumn>
                                  </NeoGrid.Row>
                                )}
                              </NeoGrid.Grid>
                            </Neo.Pager>
                          </CardBody>
                        </Card>
                      </GridItem>
                    ),
                  },
                ]}
              />
            </CardBody>
          </Card>

        </ForumDrawer>

        {/* Conversation drawer */}
        <ForumDrawer show={viewModel.conversationDrawer} isMaximised={viewModel.conversationDrawerMaximised}
          maximiseWindow={() => viewModel.conversationDrawerMaximised = !viewModel.conversationDrawerMaximised}
          onClose={() => viewModel.conversationDrawer = false}>
          <TopicComponent title="Recent Topic Activity" topicType="all" isMaximised={viewModel.meta.conversationDrawerMaximised} />
        </ForumDrawer>

        {/* Badge Drawer */}
        <ForumDrawer show={viewModel.badgeDrawer} isMaximised={viewModel.badgeDrawerMaximised} maximiseWindow={() => viewModel.badgeDrawerMaximised = !viewModel.badgeDrawerMaximised} onClose={() => viewModel.badgeDrawer = false}>
          <Card>
            <CardBody>
              {<NavPills
                alignCenter={false}
                color="rose"
                tabs={[
                  {
                    tabIcon: "user fa-2x",
                    tabButton: "My Badges",
                    tabContent: (
                      <GridItem md={12} sm={12} xs={12}>
                        <Card>
                          <CardHeader icon>
                            <CardIcon color="success">
                              <i className={`icon fa fa-user fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Your Badges</h4>
                          </CardHeader>
                          <CardBody>
                            <GridContainer>
                              {this.viewModel.UserBadges == null || this.viewModel.UserBadges.map((badge) => (
                                <GridItem md={4} sm={12} xs={12}>
                                  <BadgeAward badgeAwardMessage={badge.badgeAwardMessage} badgeTitle={badge.badgeTitle}
                                    earnedDate={badge.earnedDated.toDateString()} imageSrc={badge.blobUrl} badgeId={badge.badgeId} />
                                </GridItem>
                              ))}
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                    ),
                  }, {
                    tabIcon: "award fa-2x",
                    tabButton: "Available Badges",
                    tabContent: (
                      <GridItem md={12} sm={12} xs={12}>
                        <Card>
                          <CardHeader icon>
                            <CardIcon color="success">
                              <i className={`icon fa fa-award fa-2x`} />
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Available Badges</h4>
                          </CardHeader>
                          <CardBody>
                            <Neo.Pager pageManager={this.viewModel.availableBadgesPagerMananger}
                              pageControlProps={{
                                firstText: "",
                                prevText: "Prev",
                                nextText: "Next",
                                lastText: "",
                                alignment: "center",
                                autoHide: false,
                                pageSizeLabel: "Show"
                              }}>
                              <NeoGrid.Grid<DashboardBadgeLookup> >
                                {(badge: DashboardBadgeLookup, badgeMeta) => (
                                  <NeoGrid.Row >
                                    <NeoGrid.Column display={badgeMeta.badgeTitle} />
                                    <NeoGrid.Column display={badgeMeta.badgeDescription} />
                                    <NeoGrid.ButtonColumn label="Actions" >
                                      <Neo.Button icon="eye" isOutline variant="success" text="View Badge" tooltip="Start" onClick={() => { viewModel.ViewAvailableBadgeInfo(badge) }} />
                                    </NeoGrid.ButtonColumn>
                                  </NeoGrid.Row >
                                )}
                              </NeoGrid.Grid>
                            </Neo.Pager>
                            {
                              !viewModel.showAvailableBadgeInfo ||
                              <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={7}>
                                  <BadgeAward badgeAwardMessage={viewModel.selectedAvailableBadge.badgeAwardMessage} badgeTitle={viewModel.selectedAvailableBadge.badgeTitle}
                                    earnedDate="Not Earned yet" imageSrc={viewModel.selectedAvailableBadge.blobUrl} badgeId={viewModel.selectedAvailableBadge.badgeId} />
                                </GridItem>
                              </GridContainer>
                            }
                          </CardBody>
                        </Card>
                      </GridItem>
                    ),
                  },
                ]}
              />}
            </CardBody>
          </Card>
        </ForumDrawer>

        {/* Modules Search */}
        <ForumDrawer show={viewModel.showModuleSearch} isMaximised={viewModel.modulesIsMaximised} onClose={() => viewModel.showModuleSearch = false}
          maximiseWindow={() => viewModel.modulesIsMaximised = !viewModel.modulesIsMaximised} >
          <Card>
            <CardHeader icon>
              <CardIcon color="info">
                <i className={`icon fa fa-search fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">Modules</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/* Left here for if they change their mind */}
                {/* <GridItem xs={12} sm={12} md={6}>
                  <label>Search</label>
                  <Neo.FormGroupFloating label="Search module by title" bind={this.viewModel.moduleCriteria.meta.title} onSubmit={() => { }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label>Category Tag</label>
                  <Neo.AutoCompleteDropDown
                    bind={this.viewModel.moduleCriteria.meta.learningModuleTag1Id}
                    bindDisplay={viewModel.moduleCriteria.meta.learningModuleTag1Name}
                    displayMember="tagName"
                    itemSource={viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                    onItemSelected={(item) => { viewModel.FilterModuleTag2ListSearch(item?.tag1Id) }}
                    loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                    minCharacters={2}
                    typeToSearchPrompt="Type To search by Tag 1"
                    menuPosition="fixed"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Neo.FormGroup label="Sub-Category Tag (based on Category Tag)" bind={viewModel.moduleCriteria.meta.learningModuleTag2Id} select={{
                    items: viewModel.learningModuleTag2FilteredList,
                    allowNulls: true,
                    displayMember: "tagName",
                    onItemSelected: (item) => viewModel.FilterModuleTag3ListSearch(item?.tag2Id)
                  }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Neo.FormGroup label="Topic (Based on Sub-category Tag)" bind={viewModel.moduleCriteria.meta.learningModuleTag3Id} select={{
                    items: viewModel.learningModuleTag3FilteredList,
                    allowNulls: true
                  }} />
                </GridItem>
                <GridItem xs={2} sm={2} md={4}>
                  <Neo.Button isOutline variant="info" text="Search" icon="search" type="submit" onSubmit={() => viewModel.learningModulePageManager.refreshData()} onClick={() => viewModel.learningModulePageManager.refreshData()} />
                  <Neo.Button isOutline text="Clear Search" variant="warning" icon="eraser" onClick={() => viewModel.ClearModuleFilters()} />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={12}>
                  <Neo.Pager
                    pageManager={viewModel.learningModulePageManager}
                    pageControlProps={{
                      firstText: "",
                      prevText: "Prev",
                      nextText: "Next",
                      lastText: "",
                      alignment: "center",
                      autoHide: false,
                      pageSizeLabel: "Show"
                    }}>
                    <NeoGrid.Grid<LearningModuleLookup>>
                      {(learningModule: LearningModuleLookup, learningModuleMeta) => (
                        <NeoGrid.Row >
                          <NeoGrid.Column label="Title" display={learningModuleMeta.learningModuleTitle} />
                          <NeoGrid.Column label="Learning Objective" display={learningModuleMeta.description} />
                          <NeoGrid.Column label="Completed Module" display={learningModuleMeta.userHasDoneModule} />
                          <NeoGrid.ButtonColumn label="Actions">
                            <Neo.Button variant="info" isOutline icon="eye" text="Preview" tooltip="Preview" onClick={() => this.viewModel.PreviewModule(learningModule.learningModuleId)} />
                            <Neo.Button variant="success" isOutline icon="pen" text="Start Module" tooltip="pen" hidden={user.userType === UserTypes.Unverified.toString()} onClick={() => this.viewModel.selectModuleForStart(learningModule)} />
                          </NeoGrid.ButtonColumn>
                        </NeoGrid.Row>
                      )}
                    </NeoGrid.Grid>
                  </Neo.Pager>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </ForumDrawer>
<IntroductionReadComponent />
        <StartModuleModal onClose={() => this.viewModel.confirmStartNewModule = false}
          show={this.viewModel.meta.confirmStartNewModule}
          ModuleId={this.viewModel.selectedModelId}
          GoToModule={(moduleId, preview) => this.props.GoToModule(moduleId, preview)}
          GoToPathwayModule={(pathwayId, moduleId) => this.props.GoToPathwayModule(pathwayId, moduleId)} />

        <ForumDrawer show={viewModel.showPreviewDrawer} isMaximised={viewModel.previewDrawerMaximised}
          onClose={() => viewModel.showPreviewDrawer = false}
          maximiseWindow={() => viewModel.previewDrawerMaximised = !viewModel.previewDrawerMaximised}>
          {/* Main Module Info */}
          <div >
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader icon>
                    <CardIcon color="success">
                      <i className={`icon fa fa-chalkboard fa-2x`}></i>
                    </CardIcon>
                    <h4 className="Card-icon-header-text">{this.viewModel.module.moduleTitle}</h4>
                  </CardHeader>
                  <CardBody>
                    <h4 className="module-tiles">Learning Objective</h4>
                    <p className="module-description">{this.viewModel.module.learningObjective}</p>
                    <Card>
                      <CardHeader icon>
                        <CardIcon color="info">
                          <i className={`icon fa fa-tasks fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Activities</h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          {this.viewModel.module === undefined ||
                            this.viewModel.module.learningObjectList.map((lo: ModuleObjectsConsumptionLookup, index) => (
                              <GridItem md={3} key={lo.learningObjectId}>
                                <div>
                                  <Card>
                                    <CardHeader color={lo.learningObjectType === LearningObjectType.Information ? "success" : lo.learningObjectType === LearningObjectType.Quiz ? "warning" : "danger"} plain >
                                      <h4 className="card-title-white-small">({lo.order}) {lo.title}</h4>
                                    </CardHeader>
                                    <CardBody>
                                      <p className="module-description">{truncateString(lo.purpose, 150)}</p>
                                    </CardBody>
                                  </Card>
                                </div>
                              </GridItem>
                            ))
                          }

                        </GridContainer>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </ForumDrawer>
        <Neo.Modal title="Reminders" size="lg"
          show={this.viewModel.showReminderFrequencyModal}
          onClose={() => this.viewModel.showReminderFrequencyModal = false}
          acceptButton={{
            text: "Yes", variant: "info", icon: "bell",
            disabled: this.viewModel.selectedReminderFrequency === null || this.viewModel.selectedReminderFrequency === 0,
            onClick: () => { this.viewModel.setReminder() }
          }} closeButton={{
            text: "Nevermind", variant: "secondary", icon: "times",
          }} >
          <div>
            Please indicate how frequently you would like to receive reminders:
          </div><br />
          <Neo.RadioList bind={this.viewModel.meta.selectedReminderFrequency} radioList={{ enumType: ReminderFrequency }} />
        </Neo.Modal>
      </div>
    )
  }
}