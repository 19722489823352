import React from "react";
import cardStyles from '../../assets/tss/material-dashboard-react/components/CardStyles/cardStyle';
import classNames from 'classnames';
import './Styles/cardStyles.scss';

interface ICardProps {
  className?: string;
  plain?: boolean;
  profile?: boolean;
  chart?: boolean;
  children?: React.ReactNode;
  hidden?: boolean;
  blog?: boolean,
  raised?: boolean,
  background?: boolean,
  pricing?: boolean,
  testimonial?: boolean,
  color?: ("warning" | "success" | "danger" | "info" | "primary" | "rose");
  product?: boolean,
  login?: boolean
}

export default function Card(props: ICardProps) {
  const classes = cardStyles();
  const {
    className,
    children,
    plain,
    profile,
    blog,
    raised,
    background,
    pricing,
    color,
    product,
    testimonial,
    chart,
    login,
    hidden,
    ...rest
  } = props;

  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile || testimonial,
    [classes.cardBlog]: blog,
    [classes.cardRaised]: raised,
    [classes.cardBackground]: background,
    [classes.cardPricingColor]:
      (pricing && color !== undefined) || (pricing && background !== undefined),
    [classes[color as any]]: color,
    [classes.cardPricing]: pricing,
    [classes.cardProduct]: product,
    [classes.cardChart]: chart,
    [classes.cardLogin]: login,
    [className as any]: className !== undefined,
  });

  return (
    <div className={cardClasses} {...rest} hidden={hidden}>
      {children}
    </div>
  );
}