import { ModelBase, NeoModel, Utils, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class FileLookup extends ModelBase {

    public fileDescriptorId: string = Utils.emptyGuid();

    public fileName: string = "";

    public mimeType: string = "";

    public mediaTypeId: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.fileDescriptorId) {
            return "New file lookup";
        } else {
            return this.fileDescriptorId;
        }
    }
}