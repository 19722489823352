import React from "react";
import SwipeableViews from "react-swipeable-views";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { AppService, Types } from "../../../App/Services/AppService";
import { observer } from "mobx-react";
import { NeoModel } from "@singularsystems/neo-core";
import navPillsStyle from '../../assets/tss/material-dashboard-react/components/Panels/NavPillsStyle';
import { Views } from "@singularsystems/neo-react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import classNames from "classnames";

@NeoModel
export class NavPills1VM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  public active: number = 0;

  public setActive = (index: number) => {
    this.active = index
  }

  public handleChangeIndex = (index: number) => {
    this.setActive(index);
  };
}

interface INavPillsProps {
  color: ("warning" | "success" | "danger" | "info" | "primary" | "rose");
  active?: number;
  direction?: ("rtl" | "x-reverse" | "x");
  alignCenter?: boolean;
  tabs: Array<{ tabButton: string, tabIcon?: string, tabContent: React.ReactNode }>;
  horizontal?: {
    tabsGrid: {
      xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      sm?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      md?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    },
    contentGrid: {
      xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      sm?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      md?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    }
  };
  handleChangeIndex?: (index: number) => void;
}

@observer
export default class NavPills1 extends React.Component<INavPillsProps> {
  constructor(props: any) {
    super(props)
    this.viewModel = new NavPills1VM();
  }

  private viewModel = new NavPills1VM();

  render() {
    return this.props.horizontal !== undefined ? (
      <GridContainer>
        <GridItem md={this.props.horizontal.tabsGrid.md} sm={this.props.horizontal.tabsGrid.sm} xs={this.props.horizontal.tabsGrid.xs} {...this.props.horizontal.tabsGrid}><Tabbuttons horizontal={this.props.horizontal} active={this.viewModel.active} tabs={this.props.tabs} color={this.props.color} handleChangeIndex={(index) => this.viewModel.handleChangeIndex(index)} /></GridItem>
        <GridItem md={this.props.horizontal.contentGrid.md} sm={this.props.horizontal.contentGrid.sm} xs={this.props.horizontal.contentGrid.xs}><TabContent active={this.viewModel.active} tabs={this.props.tabs} direction="rtl" handleChangeIndex={(index) => this.viewModel.handleChangeIndex(index)} /></GridItem>
      </GridContainer>
    ) : (
      <div>
        <Tabbuttons active={this.viewModel.active} tabs={this.props.tabs} color={this.props.color} handleChangeIndex={(index) => this.viewModel.handleChangeIndex(index)} />
        <TabContent active={this.viewModel.active} tabs={this.props.tabs} direction={this.props.direction} handleChangeIndex={(index) => this.viewModel.handleChangeIndex(index)} />
      </div>
    );
  }
}

interface INavButtonProps {
  color: ("warning" | "success" | "danger" | "info" | "primary" | "rose");
  active?: number;
  alignCenter?: boolean;
  tabs: Array<{ tabButton: string, tabIcon?: string, tabContent: React.ReactNode }>;
  horizontal?: {
    tabsGrid: {
      xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      sm?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      md?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    },
    contentGrid: {
      xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      sm?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      md?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    }
  }; handleChangeIndex: (index: number) => void;
}

export function Tabbuttons(props: INavButtonProps) {
  const classes = navPillsStyle();
  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: props.horizontal !== undefined,
  });

  return (
    <div>
      <Tabs classes={{
        root: classes.root, fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.displayNone,
      }} value={props.active}
        onChange={(event, index) => props.handleChangeIndex(index)}
        centered={props.alignCenter}
      >
        {props.tabs.map((prop, key) => {
          var icon = {};
          if (prop.tabIcon !== undefined) {
            icon["icon"] = <i className={`fa fa-${prop.tabIcon}`} />;
          }
          const pillsClasses = classNames({
            [classes.pills]: true,
            [classes.horizontalPills]: props.horizontal !== undefined,
            [classes.pillsWithIcons]: prop.tabIcon !== undefined,
          });
          return (
            <Tab label={prop.tabButton} key={key}  {...icon} classes={{ root: pillsClasses, selected: classes[props.color] }} />
          );
        })}
      </Tabs>
    </div>
  )
}


interface ITabContentProps {
  active?: number;
  direction?: ("rtl" | "x-reverse" | "x");
  tabs: Array<{ tabButton: string, tabIcon?: string, tabContent: React.ReactNode }>;
  handleChangeIndex: (index: number) => void;
}

export function TabContent(props: ITabContentProps) {
  const classes = navPillsStyle();
  return (
    <div>
      <div className={classes.contentWrapper}>
        <SwipeableViews
          axis={props.direction === "rtl" ? "x-reverse" : "x"}
          index={props.active}
          onChangeIndex={props.handleChangeIndex}
          style={{ overflowY: "hidden" }}
        >
          {props.tabs.map((prop, key) => {
            return (
              <div key={key}>
                {prop.tabContent}
              </div>
            );
          })}
        </SwipeableViews>
      </div>
    </div>
  )
}