import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import Tag3 from '../Tags/Tag3';
import { Sentiment } from '../Questions/Sentiment.enum';
import SelfAssessmentOptions from '../Questions/Assessment/SelfAssessmentOptions';

@NeoModel
export default class SelfAssessmentQuestionsLookup extends ModelBase {

  public selfAssessmentQuestionId: number = 0;

  public selfAssessmentId: number = 0;

  public question: string = "";

  public tag3: Tag3 = new Tag3();

  public selfAssessmentOptions = new List(SelfAssessmentOptions);

  @Attributes.Nullable()
  public contentId: number | null = null;

  public sentiment: Sentiment = Sentiment.NotSet;

  @Attributes.Integer()
  public order: number = 0;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.selfAssessmentOptions) {
      return "New self assessment questions lookup";
    } else {
      return "";
    }
  }
}