import React from "react";
import { Neo } from "@singularsystems/neo-react";
import { Report, NeoReport } from "@singularsystems/neo-reporting";
import { ReportCategory } from "../ReportCategory";
import { AppService, Types } from "../../../ReportingTypes"
import BadUserProfilesCriteria from "./BadUserProfilesCriteria";
import TDPUser from "../../../../Identity/Models/Security/TDPUser";
import UserProfileLookup from "../../../../Identity/Models/Users/Lookups/UserProfileLookup";
import { Layout } from "@singularsystems/neo-canvas-grid";

@NeoReport('BadUserProfiles', { category: ReportCategory.Security })
export default class BadUserProfilesReport extends Report<BadUserProfilesCriteria> {

    private user: TDPUser | null = null;
    public currentUser: UserProfileLookup = new UserProfileLookup();
    public isAdmin: boolean = false;

    constructor(
        private taskRunner = AppService.get(Types.Neo.TaskRunner),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
        private userGroupQueryApiClient = AppService.get(Types.Authorisation.ApiClients.UserGroupQueryApiClient),
        private userApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient)) {
        super(BadUserProfilesCriteria)
        this.user = authenticationService.user;
        this.initialise();
    }

    public async initialise() {
        await this.taskRunner.run(async () => await this.getCurrentUser());
    }

    private async getCurrentUser() {
        if (this.user !== null) {
            let response = await this.userQueryApiClient.getRegisteredUser(this.user?.guid);
            this.currentUser.set(response.data);
            this.isAdmin = (await this.userGroupQueryApiClient.userIsAdminGroup(this.user.guid)).data;
            // if (this.isAdmin === false) {            
            // }
        }
    }

    private async populateFields(currentUser: UserProfileLookup) {
      
    }

    public async clearAllSelectedCriteria() {
        this.criteria.isRegistrationMissing = false;
        this.criteria.isUserProfileMissing = false;
    }

    public renderCriteria() {
        return <Neo.GridLayout md={1} sm={1}>
            <Neo.FormGroupInline bind={this.criteria.meta.isRegistrationMissing} />
            <Neo.FormGroupInline bind={this.criteria.meta.isUserProfileMissing} />
        </Neo.GridLayout>;
    }

    getDefaultGridLayout(criteria: BadUserProfilesCriteria) {
        return {
            "root": {
                "columns": [
                    {
                        "key": "id",
                        "title": "User Guid",
                        "sortDirection": 1
                    }
                ],
                "columnOrder": [
                    "id",
                    "userName",
                    "firstName",
                    "lastName",
                    "email",
                    "problemType"
                ]
            }
        } as Layout.IGridLayout;
    }
}
