import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class SelfAssessmentModerationSummary extends ModelBase {

  public selfAssessmentId: number = 0;

  public title: string = "";

  public titleApproved: boolean = false;

  public description: string = "";

  public descriptionApproved: boolean = false;

  public baseAssessmentApproved: boolean = false;

  public assessmentTags: List<AssessmentTagsSummary> = new List(AssessmentTagsSummary)

  public tagsApproved: boolean = true;

  public assessmentQuestions: List<AssessmentQuestion> = new List(AssessmentQuestion);

  public assessmentQuestionsApproved: boolean = true;

  public moduleNotes: string = "";
}

@NeoModel
export class AssessmentQuestion extends ModelBase {

  public questionId: number = 0;

  public question: string = "";

  public questionApproved: boolean = false;

  public tag3Id: number = 0;

  public tag3Approved: boolean = false;

  public optionsApproved: boolean = false;

  public assessmentQuestionApproved = false;

  public questionContent: AssessmentQuestionContent = new AssessmentQuestionContent();

  public questionContentApproved: boolean = true;

  public questionNotes: string = "";
}

@NeoModel
export class AssessmentTagsSummary extends ModelBase {

  public tag1Id: number = 0;

  public tag2Id: number = 0;

  public tag1Name: string = "";

  public tag2Name: string = "";

  public approved: boolean = false;

  public errorMessage: string = "";
}

@NeoModel
export class AssessmentQuestionContent extends ModelBase {
  public contentId: number = 0;

  public contentTitle: string = "";

  public contentApproved: boolean = false;

}