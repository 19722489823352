import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UserLookup extends ModelBase {

    public userId: number = 0;

    public preferredName: string = "";

    public lastName: string = "";

    public userName: string = "";    

    public combinedName: string = "";

    public isActive: boolean = true;

    public userType: string = "Unverified";

    public memberships: string[] = [];

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }    

    public toString() {
        if (this.isNew || !this.preferredName) {
            return "New user lookup"
        } else {
            return this.preferredName;
        }
    }
}