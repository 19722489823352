import { Attributes, NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class PersalEducatorSearchCriteria extends ValueObject {

    public educatorId: number | null = null;

    public surname: string = "";

    public ids: number[] | null = null;

    @Attributes.Integer()
    public pageIndex: number = 0;

    public keyword: string = "";

    @Attributes.Integer()

    public pageSize: number = 10;
    // Client only properties / methods

    public toString(): string {
        return "Educator Search Criteria";
    }
}