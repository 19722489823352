import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import LearningPathway from '../../Models/LearningPathways/LearningPathway';
import LearningPathwayPagerLookup from '../../Models/Lookups/LearningPathways/LearningPathwayPagerLookup';
import LearningPathwaySearchCriteria from '../../Models/Criteria/LearningPathwaySearchCriteria';
import LearningModuleLookup from '../../Models/Lookups/LearningModuleLookup';
import LearningPathwayShareModel from '../../Models/LearningPathways/LearningPathwayShareModel';
import LearningPathwayParticipantModule from '../../Models/Lookups/LearningPathways/LearningPathwayParticipantModule';
import LearningPathwayShareResult from '../../Models/LearningPathways/LearningPathwayShareResult';
import { AppService, Types } from '../../../Identity/IdentityTypes';
import PathwayModuleDragAndDrop from '../../Models/LearningPathways/PathwayModuleDragAndDrop';
import LearningModuleSearchCriteria from '../../Models/Criteria/LearningModuleSearchCriteria';
import LearningPathwaySendEmailResult from '../../Models/LearningPathways/LearningPathwaySendEmailResult';
import LearningPathwaySendEmailModel from '../../Models/LearningPathways/LearningPathwaySendEmailModel';

export interface ILearningPathwayQueryApiClient {

    /**
     * Creates a new Learning Pathway
     * @param learningPathway learningPathway
     * @param userGuid userGuid
     * @param learningModules learningModules
     * @returns newlearningPathway
     */
    saveLearningPathway(learningPathway: Model.PartialPlainObject<LearningPathway>, userGuid: string): AxiosPromise<Model.PlainTrackedObject<LearningPathway>>;

    /**
     * Accepts a Learning Pathway
     * @param learningPathwayId learningPathwayId
     * @returns newlearningPathway
     */
    acceptLearningPathway(learningPathwayId: number): AxiosPromise<Model.PlainTrackedObject<LearningPathway>>;

    /**
     * Rejects a Learning Pathway
     * @param learningPathwayId learningPathwayId
     * @returns newlearningPathway
     */
    rejectLearningPathway(learningPathwayId: number): AxiosPromise<Model.PlainTrackedObject<LearningPathway>>;

    /**
     * Delete a learning pathway module
     * @param learningModuleId learningModuleId
     * @param learningPathwayId learningPathwayId
     * @returns bool
     */
    deleteLearningPathwayModule(learningModuleId: number, learningPathwayId: number): AxiosPromise<boolean>;

    /**
     * Shares a pathway with users
     * @param learningPathwayId learningPathwayId
     * @param learningPathwayShareModels learningPathwayShareModels
     * @returns Array<Model.PartialPlainObject<LearningPathwayShareResult>>
     */
    shareLearningPathway(learningPathwayId: number, learningPathwayShareModels: Array<Model.PartialPlainObject<LearningPathwayShareModel>>): AxiosPromise<Array<Model.PartialPlainObject<LearningPathwayShareResult>>>

    /**
     * Invite a user to pathway
     * @param learningPathwayId learningPathwayId
     * @param learningPathwayShareModels learningPathwayShareModels
     * @returns Array<Model.PartialPlainObject<LearningPathwayShareResult>>
     */
    inviteToPathway(learningPathwayId: number, learningPathwayShareModels: Array<Model.PartialPlainObject<LearningPathwayShareModel>>): AxiosPromise<Array<Model.PartialPlainObject<LearningPathwayShareResult>>>

    /**
     * Delete a Learning Pathway
     * @param learningPathwayId learningPathwayId
     * @returns bool
     */
    deleteLearningPathway(learningPathwayId: number): AxiosPromise<boolean>;

    /**
     * Delete a Learning Pathway
     * @param learningPathwayId learningPathwayId
     * @param learningModuleId learningModuleId
     * @returns bool
     */
    getLearningPathwayParticipantModules(learningPathwayId: number, learningModuleId: number): AxiosPromise<Array<Model.PlainTrackedObject<LearningPathwayParticipantModule>>>;

    /**
     * Gets a list of Modules for Drag and drop
     * @param pathwayId pathwayId
     * @returns List of PathwayModuleDragAndDrop
     */
    getPathwayDndModules(pathwayId: number): AxiosPromise<Array<Model.PlainObject<PathwayModuleDragAndDrop>>>;

    /**
     * This method will return the paged learning modules
     * @param request the paged search criteria request
     * @returns A collection of learning modules
     */
    getPagedLearningModuleLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>>;

    /** 
     * Updates a learning pathway.
     * @param learningPathway learningPathway
     * @returns LearningPathway
     */
    updateLearningPathway(learningPathway: Model.PartialPlainObject<LearningPathway>): AxiosPromise<Model.PlainTrackedObject<LearningPathway>>;

    /**
     * Get Paged Learning Pathways
     * @param request request
     * @returns Paged result
     */
    getPagedLearningPathways(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningPathwaySearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningPathwayPagerLookup>>>;

    /**
     * Get Paged Learning Pathways
     * @param request request
     * @returns Paged result
     */
    getBasePagedLearningPathways(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningPathwaySearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningPathwayPagerLookup>>>;

    /**
     * Get Paged Learning Pathways
     * @param learningPathwayId learningPathwayId
     * @returns Paged result
     */
    getLearningPathwayModules(learningPathwayId: number): AxiosPromise<Array<Model.PlainObject<LearningModuleLookup>>>;

    /**
     * Get a Learrning Pathway by Id
     * @param learningpathwayId learningpathwayId
     * @returns LearningPathway
     */
    getLearningPathway(learningpathwayId: number): AxiosPromise<Model.PlainTrackedObject<LearningPathway>>;

        /**
     * Invite a user to pathway
     * @param learningPathwayId learningPathwayId
     * @param learningPathwaySendEmailModels learningPathwayShareModels
     * @returns Array<Model.PartialPlainObject<LearningPathwayShareResult>>
     */
    SendEmailMessage(learningPathwayId: number,key: string, learningPathwaySendEmailModels: Model.PartialPlainObject<LearningPathwayShareModel>): AxiosPromise<Model.PartialPlainObject<LearningPathwayShareResult>>

    // Client only properties / methods
}

@injectable()
export default class LearningPathwayQueryApiClient extends Data.ApiClientBase implements ILearningPathwayQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/LearningPathwayQuery`);
    }

    public saveLearningPathway(learningPathway: Model.PartialPlainObject<LearningPathway>, userGuid: string): AxiosPromise<Model.PlainTrackedObject<LearningPathway>> {
        return this.axios.put(`${this.apiPath}/SaveLearningPathway/${encodeURIComponent(userGuid)}`, learningPathway);
    }

    public acceptLearningPathway(learningPathwayId: number): AxiosPromise<Model.PlainTrackedObject<LearningPathway>> {
        return this.axios.put(`${this.apiPath}/AcceptLearningPathway/${learningPathwayId}`);
    }

    public rejectLearningPathway(learningPathwayId: number): AxiosPromise<Model.PlainTrackedObject<LearningPathway>> {
        return this.axios.put(`${this.apiPath}/RejectLearningPathway/${learningPathwayId}`);
    }

    public deleteLearningPathwayModule(learningModuleId: number, learningPathwayId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/DeleteLearningPathwayModule/${learningModuleId}/${learningPathwayId}`);
    }

    public shareLearningPathway(learningPathwayId: number, learningPathwayShareModels: Array<Model.PartialPlainObject<LearningPathwayShareModel>>): AxiosPromise<Array<Model.PartialPlainObject<LearningPathwayShareResult>>> {
        return this.axios.put(`${this.apiPath}/ShareLearningPathway/${learningPathwayId}`, learningPathwayShareModels);
    }

    public inviteToPathway(learningPathwayId: number, learningPathwayShareModels: Array<Model.PartialPlainObject<LearningPathwayShareModel>>): AxiosPromise<Array<Model.PartialPlainObject<LearningPathwayShareResult>>> {
        return this.axios.put(`${this.apiPath}/InviteToPathway/${learningPathwayId}`, learningPathwayShareModels);
    }

    public deleteLearningPathway(learningPathwayId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/DeleteLearningPathway/${learningPathwayId}`);
    }

    public getLearningPathwayParticipantModules(learningPathwayId: number, learningModuleId: number): AxiosPromise<Array<Model.PlainTrackedObject<LearningPathwayParticipantModule>>> {
        return this.axios.get(`${this.apiPath}/GetLearningPathwayParticipantModules/${learningPathwayId}/${learningModuleId}`);
    }

    public getPathwayDndModules(pathwayId: number): AxiosPromise<Array<Model.PlainObject<PathwayModuleDragAndDrop>>> {
        return this.axios.get(`${this.apiPath}/GetPathwayDndModules/${pathwayId}`);
    }

    public getPagedLearningModuleLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>> {
        return this.axios.get(`${this.apiPath}/LearningModulesPaged?${Utils.getQueryString(request)}`);
    }

    public updateLearningPathway(learningPathway: Model.PartialPlainObject<LearningPathway>): AxiosPromise<Model.PlainTrackedObject<LearningPathway>> {
        return this.axios.put(`${this.apiPath}/UpdateLearningPathway`, learningPathway);
    }

    public getPagedLearningPathways(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningPathwaySearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningPathwayPagerLookup>>> {
        return this.axios.get(`${this.apiPath}/LearningPathwayPaged?${Utils.getQueryString(request)}`);
    }

    public getBasePagedLearningPathways(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningPathwaySearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningPathwayPagerLookup>>> {
        return this.axios.get(`${this.apiPath}/BaseLearningPathwayPaged?${Utils.getQueryString(request)}`);
    }

    public getLearningPathwayModules(learningPathwayId: number): AxiosPromise<Array<Model.PlainObject<LearningModuleLookup>>> {
        return this.axios.get(`${this.apiPath}/LearningPathwayModules/${learningPathwayId}`);
    }

    public getLearningPathway(learningPathwayId: number): AxiosPromise<Model.PlainTrackedObject<LearningPathway>> {
        return this.axios.get(`${this.apiPath}/LearningPathway/${learningPathwayId}`);
    }

    public SendEmailMessage(learningPathwayId: number, key: string, learningPathwaySendEmailModels: Model.PartialPlainObject<LearningPathwaySendEmailModel>): AxiosPromise<Model.PartialPlainObject<LearningPathwaySendEmailResult>> {
        return this.axios.post(`${this.apiPath}/SendEmailMessage/${learningPathwayId}?key=${encodeURIComponent(key)}`, learningPathwaySendEmailModels);
    }
    public UploadFile(): string {
        return `${this.apiPath}/UploadFile`;
    }
    // Client only properties / methods
}