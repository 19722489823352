import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import SACEImportComponent from '../../Components/SACEImportComponent';
import PersalImportComponent from '../../Components/PersalImportComponent';
import UserImportsVM from "./UserImportsVM";
import Card from '../../../Template/components/Card/Card';
import CardHeader from '../../../Template/components/Card/CardHeader';
import CardIcon from '../../../Template/components/Card/CardIcon';
import CardBody from '../../../Template/components/Card/CardBody';

export enum UserMaintenanceTypes {
    Introduction = "Start",
    SACEImport = "SACE Data Import",
    PersalImport = "Persal Data Import",
    UserDataImport = "User Data Import",
  
}

@observer
export default class UserImportsView extends Views.ViewBase<UserImportsVM> {

    constructor(props: unknown) {
        super("UserImports", UserImportsVM, props);
    }

    public render() {
        return (
            <div className="OrgMaintenance">
                <Neo.TabContainer selectedTab={this.viewModel.meta.selectedTab}>
                    <Neo.Tab header={UserMaintenanceTypes.Introduction} icon="book-reader">
                        <div className="pv-5">
                            <div>                                
                            <Card>
                                <CardHeader icon>
                                    <CardIcon color="success">
                                        <i className={`fas fa-book-reader fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">User Import</h4>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <p>Please choose a tab to navigate to.</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        </div>
                    </Neo.Tab>
                    <Neo.Tab header={UserMaintenanceTypes.SACEImport} icon="book-reader">
                        <SACEImportComponent viewModel={this.viewModel.saceImportVM} />
                    </Neo.Tab>
                    <Neo.Tab header={UserMaintenanceTypes.PersalImport} icon="book-reader">
                        <PersalImportComponent  />
                    </Neo.Tab>
                </Neo.TabContainer>
            </div>
        );
    }
}