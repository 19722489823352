import { ModelBase, NeoModel } from "@singularsystems/neo-core";


@NeoModel
export default class TopicLookUpCriteria extends ModelBase {

  public learningModuleId: number = 0;

  public learningPathwayId: number = 0;

}