import { Data, NeoModel, Misc, ModalUtils, } from '@singularsystems/neo-core';
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import { UserTypes } from "../../App/Models/Enums/UserTypes.enums";
import UserProfileLookup from "../Models/Users/Lookups/UserProfileLookup";
import { Types } from '../IdentityTypes';
import { AppService } from '../CommonIdentityTypes';
import { NotificationDuration } from '../../App/Models/Enums/NotificationDuration';
import OrgLevelUserProfileSearchCriteria from '../Models/Users/Lookups/OrgLevelUserProfileSearchCriteria';
import OrgLevel from '../Models/Users/OrgLevel';
import ProvinceUsers from '../../Organisations/Models/OrganisationUsers/ProvinceUsers';
import OrganisationUsers from '../../Organisations/Models/OrganisationUsers/OrganisationUsers';
import DistrictUsers from '../../Organisations/Models/OrganisationUsers/DistrictUsers';
import CircuitUsers from '../../Organisations/Models/OrganisationUsers/CircuitUsers';
import SelectOrgLevelComponent, { SelectOrgLevelComponentVM } from "./SelectOrgLevelComponent"
import Card from '../../Template/components/Card/Card';
import CardHeader from '../../Template/components/Card/CardHeader';
import CardIcon from '../../Template/components/Card/CardIcon';
import CardBody from '../../Template/components/Card/CardBody';

interface IUserDataImportModalProps {
    viewModel: UserOrgLevelMaintenanceComponentVM;
    orgLevel: OrgLevel;
    orgLevelName: string;
}

@NeoModel
export class UserOrgLevelMaintenanceComponentVM extends Views.ViewModelBase {

    public userProfile: UserProfileLookup;
    public criteria = new OrgLevelUserProfileSearchCriteria();
    public selectedUserGuid: string = "";
    public showEditModal: boolean = false;
    private OldUserType: UserTypes | null = null;
    private OldIsActive: boolean = false;
    public orgLevel: OrgLevel = new OrgLevel();
    public orgLevelName: string = "";
    public showUserList: boolean = false;
    public showOrgLevelModal: boolean = false;
    public orginalOrgLevel: OrgLevel = new OrgLevel();

    public selectOrgLevelComponentVM: SelectOrgLevelComponentVM = new SelectOrgLevelComponentVM();

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private userApiClient = AppService.get(Types.Identity.ApiClients.UserProfileApiClient),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient)) {
        super(taskRunner);
        this.userProfile = new UserProfileLookup()
    }

    public userLookupPageManager = new Data.PageManager(this.criteria, UserProfileLookup, this.userQueryApiClient.getPagedRegisteredUserLookupAtOrgLevel, {
        pageSize: 10,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sortBy: "firstName",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner,
    })

    public show() {
        this.showUserList = true;
    }

    public async editUserModal(userGuid: string, userType: UserTypes | null = null, isActive: boolean) {
        if (userGuid !== "") {
            this.selectedUserGuid = userGuid;
            this.OldUserType = userType;
            this.OldIsActive = isActive;
            const response = await this.taskRunner.waitFor(this.userQueryApiClient.getRegisteredUser(this.selectedUserGuid));
            let userData = response.data;
            this.userProfile.set(userData);
        }
        this.showEditModal = true;
    }

    public async closeEditModal() {
        this.userProfile = new UserProfileLookup();
        this.selectedUserGuid = "";
        this.showEditModal = false;
    }

    public async saveUser() {

        if (this.userProfile.isDirty) {
            if (this.userProfile.isActive !== this.OldIsActive && this.userProfile.isActive === false) {
                if (await ModalUtils.showYesNo("Please Note", `${this.userProfile.firstName} ${this.userProfile.surname} is about to be deactivated. They will be unable to login to the website. Would you like to proceed with this change?`) === Misc.ModalResult.Yes) {
                    await this.SaveUserData();
                }
            }
            else if (this.userProfile.userType !== this.OldUserType && this.userProfile.userType === UserTypes.Admin) {
                if (await ModalUtils.showYesNo("Please Note", `${this.userProfile.firstName} ${this.userProfile.surname} is about to be made an administrator. They will be required to provide a password at the next login. Would you like to proceed with this change?`) === Misc.ModalResult.Yes) {
                    await this.SaveUserData();
                }
            }
            else if (this.OldUserType === UserTypes.Admin && this.userProfile.userType !== UserTypes.Admin) {
                if (await ModalUtils.showYesNo("Please Note", `${this.userProfile.firstName} ${this.userProfile.surname} was an admin and is about to lose their admin status. This will clear any password the user has and remove them from all groups they belong to. Would you like to proceed with this change?`) === Misc.ModalResult.Yes) {
                    await this.SaveUserData();
                }
            }
            else {
                await this.SaveUserData();
            }
        }
        else {
            this.showEditModal = false;
            this.notifications.addInfo(`No changes made to ${this.userProfile.firstName} ${this.userProfile.surname}'s details`, null, NotificationDuration.Standard)
        }
    }

    private async SaveUserData() {
        this.taskRunner.run(async () => {
            this.showEditModal = false;
            await this.userApiClient.updateUserProfileAsync(this.userProfile.toJSObject());

            this.notifications.addSuccess(`${this.userProfile.firstName} ${this.userProfile.surname}'s,  details saved`, null, NotificationDuration.Standard);
            this.selectedUserGuid = "";
            this.userProfile = new UserProfileLookup();
            this.userLookupPageManager.refreshData();
        })
    }

    private clearValues() {
        this.criteria.organisationId = null;
        this.criteria.provinceId = null;
        this.criteria.districtId = null;
        this.criteria.circuitId = null;
        this.criteria.schoolId = null;
        this.criteria.schoolManagementTeamId = null;
        this.orgLevel = new OrgLevel();
    }

    public CloseUserListModal() {
        this.showUserList = false;
        this.clearValues();
    }

    public ShowUserListModal() {
        this.userLookupPageManager.refreshData();
        this.showUserList = true;
    }

    // -- EDIT --    
    public async editOrganisation(organisation: OrganisationUsers) {
        this.clearValues();
        this.orgLevel.organisationId = organisation.organisationId;
        this.orgLevelName = "Organisation '" + organisation.organisationName + "'";
        this.criteria.organisationId = organisation.organisationId;
        this.ShowUserListModal()
    }

    public async editProvince(organisation: OrganisationUsers, province: ProvinceUsers) {
        this.clearValues();
        this.orgLevel.organisationId = organisation.organisationId;
        this.orgLevel.provinceId = province.provinceId;
        this.orgLevelName = "Province '" + province.provinceName + "'";
        this.criteria.organisationId = organisation.organisationId;
        this.criteria.provinceId = province.provinceId;
        this.ShowUserListModal();
    }

    public async editDistrict(organisation: OrganisationUsers, province: ProvinceUsers, district: DistrictUsers) {
        this.clearValues();
        this.orgLevel.organisationId = organisation.organisationId;
        this.orgLevel.provinceId = province.provinceId;
        this.orgLevel.districtId = district.districtId;
        this.orgLevelName = "District '" + district.districtName + "'";
        this.criteria.organisationId = organisation.organisationId;
        this.criteria.provinceId = province.provinceId;
        this.criteria.districtId = district.districtId;
        this.ShowUserListModal();
    }

    public async editCircuit(organisation: OrganisationUsers, province: ProvinceUsers, district: DistrictUsers, circuit: CircuitUsers) {
        this.clearValues();
        this.orgLevel.organisationId = organisation.organisationId;
        this.orgLevel.provinceId = province.provinceId;
        this.orgLevel.districtId = district.districtId;
        this.orgLevel.circuitId = circuit.circuitId;
        this.orgLevelName = "Circuit '" + circuit.circuitName + "'";
        this.criteria.organisationId = organisation.organisationId;
        this.criteria.provinceId = province.provinceId;
        this.criteria.districtId = district.districtId;
        this.criteria.circuitId = circuit.circuitId;
        this.ShowUserListModal();
    }

    // Select Organisation Level Modal

    public showOrgSelector() {
        this.orginalOrgLevel = this.userProfile.organisationlevel;
        this.showOrgLevelModal = true;
    }

    public closeSelectOrgLevelModal() {
        this.showOrgLevelModal = false;
        if (this.selectOrgLevelComponentVM.resultSuccess) {
            this.userProfile.organisationLevelName = this.selectOrgLevelComponentVM.orgLevelName;
            this.userProfile.organisationlevel = this.selectOrgLevelComponentVM.orgLevel;
        }
    }
}

@observer
export default class UserOrgLevelMaintenanceComponent extends React.Component<IUserDataImportModalProps> {
    constructor(props: IUserDataImportModalProps) {
        super(props, UserOrgLevelMaintenanceComponentVM);
    }

    public render() {

        const viewModel = this.props.viewModel;

        viewModel.orgLevel = this.props.orgLevel;
        // viewModel.selectOrgLevelComponentVM = this.props.viewModel.selectOrgLevelComponentVM;

        return (
            <Neo.Modal size="xl"
                title={`Edit User Data for ` + viewModel.orgLevelName}
                show={viewModel.showUserList}
                onClose={() => { viewModel.CloseUserListModal(); }}
                closeButton={{
                    text: "Cancel", variant: "secondary", icon: "times"
                }} >
                <div className="pv-5">
                    <section>
                        <div>
                            <Card>
                                <CardHeader icon>
                                    <CardIcon color="success">
                                        <i className={`icon fa fa-user-shield fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">{`User Maintenance for ${viewModel.orgLevelName}`}</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="row">
                                        <div className="col md-12">
                                            <div className="row mt-2">
                                                <div className="col-md-12">
                                                    <Neo.Pager
                                                        pageManager={viewModel.userLookupPageManager}
                                                        pageControlProps={{
                                                            firstText: "",
                                                            prevText: "Prev",
                                                            nextText: "Next",
                                                            lastText: "",
                                                            alignment: "center",
                                                            autoHide: false,
                                                            pageSizeLabel: "Show"
                                                        }}>
                                                        {User => (
                                                            <NeoGrid.Grid<UserProfileLookup> className="table-hover" keyProperty="userId">
                                                                {(user: UserProfileLookup, userMeta) => (
                                                                    <NeoGrid.Row key={user.userGuid}>
                                                                        <NeoGrid.Column display={userMeta.firstName} disabled />
                                                                        <NeoGrid.Column display={userMeta.surname} disabled />
                                                                        <NeoGrid.Column display={userMeta.idNumber} disabled />
                                                                        <NeoGrid.Column display={userMeta.email} disabled />
                                                                        <NeoGrid.Column display={userMeta.persalNumber} disabled />
                                                                        <NeoGrid.Column display={userMeta.saceNumber} disabled />
                                                                        <NeoGrid.Column display={userMeta.organisationLevelName} disabled />
                                                                        <NeoGrid.Column display={userMeta.userType} select={{ itemSource: Data.StaticDataSource.fromEnum(UserTypes) }} disabled />
                                                                        <NeoGrid.Column display={userMeta.isActive} disabled />
                                                                        <NeoGrid.ButtonColumn>
                                                                            <Neo.Button icon="edit" isOutline variant="success" onClick={() => viewModel.editUserModal(user.userGuid, user.userType, user.isActive)}>Edit</Neo.Button>
                                                                        </NeoGrid.ButtonColumn>
                                                                    </NeoGrid.Row>
                                                                )}
                                                            </NeoGrid.Grid>
                                                        )}
                                                    </Neo.Pager>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </section>

                    <Neo.Modal title={viewModel.selectedUserGuid !== "" ? "Edit User" : ""} size="xl"
                        show={viewModel.showEditModal}
                        formProps={{
                            showSummaryModal: true
                        }}
                        onClose={() => viewModel.closeEditModal()}
                        acceptButton={{
                            text: "Save", variant: "success", icon: "check",
                            onClick: () => viewModel.saveUser()
                        }} closeButton={{
                            text: "Cancel", variant: "secondary", icon: "times"
                        }} >
                        {() => (viewModel.userProfile &&
                            <div>
                                <Neo.GridLayout md={2} lg={3}>
                                    <Neo.FormGroup bind={viewModel.userProfile.meta.firstName} autoFocus />
                                    <Neo.FormGroup bind={viewModel.userProfile.meta.surname} autoFocus />
                                    <Neo.FormGroup bind={viewModel.userProfile.meta.idNumber} disabled autoFocus />
                                    <Neo.FormGroup bind={viewModel.userProfile.meta.email} autoFocus />
                                    <Neo.FormGroup bind={viewModel.userProfile.meta.phoneNumber} autoFocus />
                                    <Neo.FormGroup bind={viewModel.userProfile.meta.saceNumber} disabled autoFocus />
                                    <Neo.FormGroup bind={viewModel.userProfile.meta.persalNumber} disabled autoFocus />
                                    <Neo.FormGroup display={viewModel.userProfile.meta.organisationLevelName} disabled ><Neo.Button isOutline={true} variant="info" style={{ float: "right" }} onClick={() => viewModel.showOrgSelector()}>Change</Neo.Button> </Neo.FormGroup>
                                    <Neo.FormGroup bind={viewModel.userProfile.meta.userType} select={{ itemSource: Data.StaticDataSource.fromEnum(UserTypes) }} />
                                    <Neo.FormGroup bind={viewModel.userProfile.meta.isActive} autoFocus />
                                </Neo.GridLayout>
                            </div>
                        )}
                    </Neo.Modal>
                </div>
                {/* Modals */}
                <SelectOrgLevelComponent showOrgLevelModal={viewModel.showOrgLevelModal} closeMethod={() => { viewModel.closeSelectOrgLevelModal() }} originalOrgLevel={viewModel.orginalOrgLevel} viewModel={viewModel.selectOrgLevelComponentVM} optionalText="test"></SelectOrgLevelComponent>
            </Neo.Modal>

        )
    }

}

