import { Attributes, List, ModelBase, NeoModel, Utils, Validation } from '@singularsystems/neo-core';
import ModuleObjectsConsumptionLookup from '../ContentCosumption/ModuleObjectsConsumptionLookup';
import { UserLearningStatus } from './UsersLearningStatus.enum';

@NeoModel
export default class UserLearningModuleLookup extends ModelBase {

    public userLearningModuleId: number = 0;

    public learningModuleId: number = 0;

    public moduleTitle: string = "";

    public learningObjective: string = "";

    @Attributes.Integer()
    public cptdPoints: number = 0;

    public learningObjectList = new List(ModuleObjectsConsumptionLookup);

    public userGuid: string = Utils.emptyGuid();

    public status: UserLearningStatus | null = null;

    @Attributes.Nullable()
    public lastLearningObjectId: number | null = null;

    @Attributes.Float()
    public finalQuizMark: number = 0;

    @Attributes.Date()
    public completedDate: Date | null = null;

    public finalQuizStarted: boolean = false;

    public isDeactivated: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.moduleTitle) {
            return "New user learning module lookup";
        } else {
            return this.moduleTitle;
        }
    }
}