import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class LearningPathwayLookup extends LookupBase {

    public learningPathwayId: number = 0;

    public title: string = "";

    public ownerGuid: string = '';

    @Attributes.Date()
    public completedDate: Date | null = null;

    @Attributes.Integer()
    public learningPathwayType: number = 0;

    @Attributes.Integer()
    public userLearningStatus: number = 0;

    // Client only properties / methods
}