import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../IdentityTypes';
import NotificationSettings from '../../../Models/SystemSettings/NotificationSettings';

export interface ISystemsSettingsQueryApiClient {

    /** 
     * Gets the Notification Settings.
     * @returns NotificationSettings.
     */
    getNotificationSettings(): AxiosPromise<Model.PlainTrackedObject<NotificationSettings>>;

    // Client only properties / methods
}

@injectable()
export default class SystemsSettingsQueryApiClient extends Data.ApiClientBase implements ISystemsSettingsQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/SystemsSettingsQuery`);
    }

    public getNotificationSettings(): AxiosPromise<Model.PlainTrackedObject<NotificationSettings>> {
        return this.axios.get(`${this.apiPath}/NotificationSettings`);
    }

    // Client only properties / methods
}