import { Neo } from '@singularsystems/neo-react';
import { Model, ITaskRunner, Data } from '@singularsystems/neo-core';
import React from 'react';

type IPagerTextFilterProps = {
    filterProperty: Model.IPropertyInstance;
    pageManager: Data.IPageManager;
    taskRunner: ITaskRunner;
    label?: any;
}

export default class PagerTextFilter extends React.Component<IPagerTextFilterProps> {

    public render() {
        return (
            <Neo.FormGroupInline md={12} bind={this.props.filterProperty} placeholder={this.props.label} suppressLabel={true}
                editorProps={{
                    onKeyPress: event => {
                        if (event.charCode === 13) {
                            this.props.pageManager.refreshData(true, this.props.taskRunner);
                            event.preventDefault();
                        }
                    }
                }}
                append={() =>
                    <Neo.Button size="sm" tabIndex={-1} icon="search"
                        onClick={() => this.props.pageManager.refreshData(true, this.props.taskRunner)} />
                } />
        );
    }
}