import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class BadgeSearchCriteria extends ValueObject {

    public badgeId: number = 0;

    public badgeTitle: string = "";

    public keyword: string = "";

    public badgeApproved: boolean = true;

    public activeBadge: boolean = true;
}