import classNames from 'classnames';
import cardFooterStyles from '../../assets/tss/material-dashboard-react/components/CardStyles/cardFooterStyles';
import React from 'react'

interface ICardFooterProps {
  className?: string;
  plain?: boolean;
  profile?: boolean;
  stats?: boolean;
  chart?: boolean;
  testimonial?: boolean;
  children: React.ReactNode;
}

export default function CardHeader(props: ICardFooterProps) {
  const classes = cardFooterStyles();
  const { className, children, plain, profile, stats, chart, testimonial, ...rest } = props;

  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: plain,
    [classes.cardFooterProfile]: profile || testimonial,
    [classes.cardFooterTestimonial]: testimonial,
    [classes.cardFooterStats]: stats,
    [classes.cardFooterChart]: chart,
    [className as any]: className !== undefined,
  });
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
}