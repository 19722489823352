import { Data, List, ModalUtils, Model, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { Types } from '../../../Identity/IdentityTypes';
import { AppService } from '../../../Identity/CommonIdentityTypes';
import { LearningContentFileComponentVM } from "../../Components/LearningContentFileComponent";
import LearningObjectContentLookup from "../../Models/Lookups/LearningObjectContentLookup";
import FileLookup from "../../Models/Lookups/FileLookup";
import UserProfileLookup from "../../../Identity/Models/Users/Lookups/UserProfileLookup";
import TDPUser from "../../../Identity/Models/Security/TDPUser";
import Tag2 from "../../Models/Tags/Tag2";
import { NotificationDuration } from "../../../App/Models/Enums/NotificationDuration.enum";
import { IsContentCreator } from "../../../Authorisation/Models/Enums/LearningRoles.enum";
import SelfAssessmentLookup from '../../Models/Lookups/SelfAssessmentLookup';
import SelfAssessmentQuestionLookup from '../../Models/Lookups/SelfAssessmentQuestionLookup';
import { SelfAssessmentQuestionComponentVM } from "../Assessment/SelfAssessmentQuestionComponent";
import SelfAssessmentTagLookup from "../../Models/Lookups/SelfAssessmentTagLookup";
import SelfAssessmentSearchCriteria from "../../Models/Criteria/SelfAssessmentSearchCriteria";
import SelfAssessmentQuestionTagLookup from "../../Models/Lookups/SelfAssessmentQuestionTagLookup";
import ScalesLookup from "../../Models/Lookups/ScalesLookup";
import SelfAssessmentOptions from "../../Models/Questions/Assessment/SelfAssessmentOptions";
import SelfAssessmentQuestionDragableModel from "../../Models/Lookups/SelfAssessmentQuestionDragableModel";
import LearningContentCombinedObject from "../../Models/LearningObjects/LearningContentCombinedObject";
import { selfAssessmentTabs } from "./SelfAssessmentView";
import { RichTextEditorVM } from "../../Components/RichTextEditor";
import { ModerationState } from "../../Models/LearningObjects/ModerationState.enum";
import SelfAssessmentModerationLookup from "../../Models/Lookups/SelfAssessmentModerationLookup";
import { Sentiment } from "../../Models/Questions/Sentiment.enum";
import { QuestionType } from "./SelfAssessmentQuestionComponent";
import { truncateLongString } from '../../../App/HelperClasses/GlobalHelpers';
import SelfAssessmentModerationSummary, { AssessmentQuestion, AssessmentTagsSummary } from "../../Models/Moderation/SelfAssessmentModerationSummary";

@NeoModel
export default class SelfAssessmentVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
        private selfAssessmentApiClient = AppService.get(Types.Learning.ApiClients.SelfAssessmentApiClient),
        private tag2ApiQueryClient = AppService.get(Types.Learning.ApiClients.Tag2QueryApiClient),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
        public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
        private identityService = AppService.get(Types.Identity.Services.TDPAuthenticationService)) {
        super(taskRunner);
        this.user = identityService.user;
        this.currentUser = new UserProfileLookup();
    }

    // Self Assessment
    public selfAssessment = new SelfAssessmentLookup();
    public selfAssessmentQuestion = new SelfAssessmentQuestionLookup();
    public selfAssessmentQuestionList = new List(SelfAssessmentQuestionLookup);
    public selectedTab = selfAssessmentTabs.selfAssessments;

    // User Info
    public currentUser: UserProfileLookup;
    private user: TDPUser | null = null;

    public questionsDragAndDropList: SelfAssessmentQuestionDragableModel[] = [];
    public hideCriteria: boolean = false;
    public isContentCreator: boolean = false;

    // Self Assessment Properties
    public selectedSelfAssessmentId: number = 0;
    public selfAssessmentQuestionFilteredTag3List = new List(SelfAssessmentQuestionTagLookup);
    public scalesList = new List(ScalesLookup);
    public learningModuleTag2FilteredList = new List(Tag2);
    public searchCriteriaClear: boolean = false;
    public selfAssessmentCriteria = new SelfAssessmentSearchCriteria();
    public selectedSelfAssessmentQuestionId: number = 0;
    public SelfAssessmentQuestionComponentVM = new SelfAssessmentQuestionComponentVM(this.taskRunner);
    public hideMediaUpload = true;
    public selectedQuestionTypeId: number = 0;
    public selfAssessmentOptions = new List(SelfAssessmentOptions);
    public numberOfScales: number = 4;
    public showModerationStateFilter: boolean = true;

    // Props for Moderation stuff 
    public moderateableSelfAssessment = new SelfAssessmentModerationLookup();
    public showAssessmentReviewSummaryDrawer: boolean = false;
    public showAssessmentReviewSummaryIsMax: boolean = true;
    public showAssessmentQuestionContentModerationNotes: boolean = false;
    public richTextEditorVmSAContentQuestionMN = new RichTextEditorVM(this.taskRunner);
    public test = new RichTextEditorVM(this.taskRunner);
    public showAssessmentQuestionModerationNotes: boolean = false;
    public richTextEditorVmSAQuestionMN = new RichTextEditorVM(this.taskRunner);
    public showAssessmentModerationNotes: boolean = false;
    public richTextEditorVmSAMN = new RichTextEditorVM(this.taskRunner);
    public richTextEditorDNDMN = new RichTextEditorVM(this.taskRunner);
    public showAssessmentQuestionDnDModerationNotes = false;

    //Learning Content Properties

    public selectedMediaType: number = 0;
    public LearningContentFileComponentVM = new LearningContentFileComponentVM(this.taskRunner);
    public newLearningContent = new LearningObjectContentLookup();
    public richTextEditorVmSAQuestion = new RichTextEditorVM(this.taskRunner);
    public newQuestionLearningContent = new LearningObjectContentLookup();

    // bindings
    public hideEditModuleCard = true;
    public hideEditTagsCard = true;
    public hideContentType = true;
    public hideUpdate = true;
    public hideQuizCard = false;

    public showAssessmentCard = true;

    //Called on ComponentDidMount
    public async initialise() {
        await this.getCurrentUser();
        this.selfAssessmentCriteria.meta.Createdby.value = this.currentUser.userGuid;
        this.isContentCreator = await IsContentCreator();
        this.selfAssessmentLookupPageManager.refreshData();
    }

    private async getCurrentUser() {
        if (this.user != null) {
            let response = await this.userQueryApiClient.getRegisteredUser(this.user?.guid);
            let userData = response.data;
            this.currentUser.set(userData);
        }
    }

    private afterSelfAssessmentFetched(data: SelfAssessmentLookup[]) {
    }

    private beforeSelfAssessmentFetched(criteria: Model.PlainObject<Data.PageRequest<SelfAssessmentSearchCriteria>>) {

    }

    public selfAssessmentLookupPageManager = new Data.PageManager(this.selfAssessmentCriteria, SelfAssessmentLookup, this.selfAssessmentApiClient.getPagedSelfAssessmentLookup, {
        pageSize: 15,
        pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
        sortBy: "selfAssessmentId",
        sortAscending: false,
        initialTaskRunner: this.taskRunner,
        allowSort: true,
        taskRunner: this.taskRunner,
        beforeFetch: this.beforeSelfAssessmentFetched.bind(this),
        afterFetch: this.afterSelfAssessmentFetched.bind(this)
    });

    public async showEditAssessmentCard(selfAssessmentId: number) {
        if (selfAssessmentId === 0) {
            this.selectedSelfAssessmentId = 0;
            this.selfAssessmentCriteria.SelfAssessmentId = null;
            this.hideCriteria = true;
            this.selfAssessment = new SelfAssessmentLookup();
            this.showAssessmentCard = false;
            this.hideEditTagsCard = true;
            this.hideQuizCard = false;
            this.showAssessmentQuestionModerationNotes = false;
            this.showAssessmentModerationNotes = false;
            this.showAssessmentQuestionContentModerationNotes = false;
        }
        else {
            this.questionsDragAndDropList = [];
            this.FetchQuestionsForDnd(selfAssessmentId);
            this.selfAssessment = new SelfAssessmentLookup();
            var response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessment(selfAssessmentId));
            this.selfAssessment.set(response.data);
            this.showAssessmentCard = false;
            this.hideEditTagsCard = false;
            this.selectedSelfAssessmentId = selfAssessmentId;
            this.selectedQuestionTypeId = this.selfAssessment.questionTypeId;
            this.selectedSelfAssessmentQuestionId = 0;
            this.selfAssessmentQuestion = new SelfAssessmentQuestionLookup();
            this.selfAssessmentQuestionList = new List(SelfAssessmentQuestionLookup);
            var responseQuestion = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentQuestionLookupList(selfAssessmentId));
            this.selfAssessmentQuestionList.set(responseQuestion.data);
            this.showAssessmentQuestionModerationNotes = this.selfAssessment.moderationState === ModerationState.Review;
            this.showAssessmentModerationNotes = this.selfAssessment.moderationState === ModerationState.Review;
            this.showAssessmentQuestionDnDModerationNotes = this.selfAssessment.moderationState === ModerationState.Review;
            this.hideQuizCard = false;
            this.hideCriteria = true;
        }
    }

    public async FetchQuestionsForDnd(selfAssessmentId: number) {
        var responseDnd = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentQuestionDNDObjects(selfAssessmentId));

        if (responseDnd.data.length > 0) {
            responseDnd.data.forEach(element => {
                element.text = truncateLongString(JSON.parse(element.text).blocks[0].text);
            });
            return this.questionsDragAndDropList = responseDnd.data;
        }
        else {
            return this.questionsDragAndDropList;
        }
    }

    public async deleteSelfAssessment(selfAssessmentId: number) {
        this.taskRunner.run(async () => {
            const response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.deleteSelfAssessment(selfAssessmentId));
            if (response.data === false) {
                this.notifications.addDanger("Delete unsuccessful", "SelfAssessment cannot be deleted as it has already been moderated", NotificationDuration.Standard);
            }
            else {
                this.selfAssessmentLookupPageManager.refreshData();
                this.notifications.addSuccess("Self Assessment Deleted", null, NotificationDuration.Standard);
            }
        })
    }

    public async showDeleteSelfAssessment(selfAssessment: SelfAssessmentLookup, selfAssessmentId: number) {
        await ModalUtils.showYesNoDismissible("Delete Self Assessment " + selfAssessment.selfAssessmentTitle,
            "Are you sure you want to delete this Self Assessment? ", () => this.deleteSelfAssessment(selfAssessmentId));
    }

    public async deleteSelfAssessmentTag2(selfAssessmentTag: SelfAssessmentTagLookup) {
        var canDelete = await this.CanDeleteCTag2(selfAssessmentTag.tag2Id);
        if (canDelete) {
            if (selfAssessmentTag.isNew) {
                this.selfAssessment.selfAssessmentTags2.remove(selfAssessmentTag);
            }
            else {
                await ModalUtils.showYesNoDismissible("Delete Topic(T1), Sub-Category(T2)",
                    "Are you sure you want to delete this Topic(T1) -> " + selfAssessmentTag.tag1Name + ", Sub-Category(T2) -> " + selfAssessmentTag.tag2Name + ", from this Self Assessment?", () => this.deleteTag2(selfAssessmentTag));
            }
        }
        else {
            ModalUtils.showMessage("Can't delete Tags", "The selected tags have Self Assessment Question containing Linked tag 3's. Please remove before deleting.", 4);
        }
    }

    public async deleteTag2(selfAssessmentTag: SelfAssessmentTagLookup) {
        const response = await this.selfAssessmentApiClient.deleteSelfAssessmentTag2(selfAssessmentTag.selfAssessmentId, selfAssessmentTag.tag2Id);
        if (response.data) {
            this.selfAssessment.selfAssessmentTags2.remove(selfAssessmentTag);
            this.notifications.addSuccess("Self Assessment Tag Deleted", "Self Assessment Tags Delete ", NotificationDuration.Standard);
        } else {
            this.notifications.addDanger("Delete unsuccessful", "Tag cannot be deleted as it has already been moderated", NotificationDuration.Standard);
        }
    }

    public async CanDeleteCTag2(Tag2Id: number) {
        var canDelete = true;
        const response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentTagList(this.selectedSelfAssessmentId));
        var tag = response.data.filter(c => c.tag2Id === Tag2Id).length;
        if (tag !== 0) {
            canDelete = false;
        }
        return canDelete;
    }

    public async deleteAssessmentTag2(selfAssessmentTag: SelfAssessmentTagLookup) {
        const response = await this.selfAssessmentApiClient.deleteSelfAssessmentTag2(this.selfAssessment.selfAssessmentId, selfAssessmentTag.tag2Id)
        if (response.data) {
            this.selfAssessment.selfAssessmentTags2.remove(selfAssessmentTag);
            this.notifications.addSuccess("Self Assessment Tag Deleted", "Self Assessment Tags Delete ", NotificationDuration.Standard);
        } else {
            this.notifications.addDanger("Delete unsuccessful", "Tag cannot be deleted as it has already been moderated", NotificationDuration.Standard);
        }
    }

    public async filtertag2List(selfAssessmentTag: SelfAssessmentTagLookup, tag1Id?: number | undefined) {
        if (tag1Id) {
            const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id as number))).data;
            selfAssessmentTag.filteredTag2List.update(tag2List);
        }
    }

    public async filtertag2ListSearch(tag1Id: number | undefined) {
        if (tag1Id) {
            const tag2List = (await this.taskRunner.waitFor(this.tag2ApiQueryClient.getTag2ListByTag1Id(tag1Id))).data;
            this.learningModuleTag2FilteredList.set(tag2List);
        }
        this.selfAssessmentCriteria.SelfAssessmentTag2Id = 0;
    }

    public async saveSelfAssessment() {
        var SelfAssessment = this.selfAssessment;
        SelfAssessment.selfAssessmentTags2.set(this.selfAssessment.selfAssessmentTags2.toJSArray());
        if (this.selfAssessment.selfAssessmentId !== 0) {
            this.taskRunner.run(async () => {
                var response = await this.selfAssessmentApiClient.updateSelfAssessment(SelfAssessment.toJSObject({ includeClean: true }));
                if (response.data) {
                    this.selfAssessmentLookupPageManager.refreshData();
                    await this.refreshSelfAssessment(this.selfAssessment.selfAssessmentId);
                    this.notifications.addSuccess("Self Assessment Updated", "Self Assessment Updated", 4);
                }
                else {
                    this.notifications.addDanger("Self Assessment Update Failed", "Self Assessment Update Failed", 4);
                }
            });
        } else {
            this.taskRunner.run(async () => {
                var response = await this.selfAssessmentApiClient.SaveSelfAssessment(SelfAssessment.toJSObject({ includeClean: true }));
                if (response.data) {
                    this.selfAssessment.set(response.data);
                    this.selfAssessmentLookupPageManager.pageSize = 1;
                    this.selfAssessmentLookupPageManager.refreshData();
                    await this.refreshSelfAssessment(this.selfAssessment.selfAssessmentId);
                    this.notifications.addSuccess("Self Assessment Saved", "Self Assessment Saved", NotificationDuration.Standard);
                    this.hideUpdate = false;
                }
                else {
                    this.notifications.addDanger("Self Assessment Save Failed", "Self Assessment Save Failed", NotificationDuration.Standard);
                }
            });
        }
        this.hideUpdate = true;
    }

    public async refreshSelfAssessment(selfAssessmentId: number) {
        const responseList = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentQuestionTagSelectList(selfAssessmentId));
        this.selfAssessmentQuestionFilteredTag3List.set(responseList.data);
        const selfAssessmentQuestionScales = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getScales());
        this.scalesList.set(selfAssessmentQuestionScales.data);
        this.selectedSelfAssessmentId = selfAssessmentId;
        var response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessment(selfAssessmentId));
        this.selfAssessment.set(response.data);
        this.selectedQuestionTypeId = this.selfAssessment.questionTypeId;
        this.selfAssessmentCriteria.SelfAssessmentId = this.selfAssessment.selfAssessmentId;
        this.selfAssessmentLookupPageManager.refreshData();
    }

    /// visiblity bindings
    public showTagCards() {
        if (this.selfAssessment.selfAssessmentId === 0) {
            this.hideEditTagsCard = false;
            this.hideUpdate = false;
        }
        this.hideEditTagsCard = false;
    }

    public selfAssessmentValid() {
        var SelfAssessment = this.selfAssessment;
        if (SelfAssessment.selfAssessmentTags2.length > 0
            && ((SelfAssessment.questionTypeId === QuestionType.LikertScale as number && SelfAssessment.sentiment > 0)
                || (SelfAssessment.questionTypeId === QuestionType.MultipleChoice as number))) {
            return false;
        }
        else {
            return true;
        }
    }

    public selfAssessmentQuestionValid(question: SelfAssessmentQuestionLookup) {
        if (question.questionTypeId as number > 0
            && question.tag3Id as number > 0
            && this.selfAssessmentOptions.length > 0
        ) {
            return false;
        }
        else {
            return true;
        }
    }

    public async showSelfAssessmentCard() {
        this.refreshSelfAssessment(this.selfAssessment.selfAssessmentId);
        this.selfAssessmentQuestion = new SelfAssessmentQuestionLookup();
        this.selfAssessmentQuestion.selfAssessmentId = this.selfAssessment.selfAssessmentId;
        this.selfAssessmentQuestion.numberOfScales = 4;
        this.selfAssessmentQuestion.questionTypeId = this.selfAssessment.questionTypeId;
        this.selectedQuestionTypeId = this.selfAssessment.questionTypeId;
        this.selfAssessmentQuestion.sentiment = this.selfAssessment.sentiment;
        this.selfAssessmentOptions = new List(SelfAssessmentOptions);
        if (this.selfAssessmentQuestion.sentiment !== Sentiment.NotSet) {
            var response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentOptions(this.selfAssessmentQuestion.sentiment, 4));
            this.selfAssessmentOptions.set(response.data);
        }
        this.newQuestionLearningContent = new LearningObjectContentLookup();
        this.SelfAssessmentQuestionComponentVM.fileDescriptorList = new List(FileLookup);
        this.hideQuizCard = true;
        this.SelfAssessmentQuestionComponentVM.showLinkedResource = false;
        this.SelfAssessmentQuestionComponentVM.selectedQuestionTypeId = this.selfAssessment.questionTypeId;
    }

    public async setSelectedSelfAssesmentQuestion(question: SelfAssessmentQuestionLookup) {
        this.selectedSelfAssessmentId = question.selfAssessmentId;
        this.selfAssessmentQuestion = question;
        this.selfAssessmentQuestion.sentiment = this.selfAssessment.sentiment;
        if (this.selfAssessmentQuestion.sentiment !== Sentiment.NotSet) {
            var response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentOptions(this.selfAssessmentQuestion.sentiment, 4));
            this.selfAssessmentOptions.set(response.data);
        }
        this.selfAssessmentQuestion.selfAssessmentQuestionModerationNotes = question.selfAssessmentQuestionModerationNotes;
        this.selectedSelfAssessmentQuestionId = question.selfAssessmentQuestionId;
        if (this.selfAssessment.selfAssessmentId !== 0) {
            this.refreshSelfAssessment(this.selfAssessment.selfAssessmentId);
            this.selfAssessmentOptions = question.selfAssessmentOptionList;
            this.SelfAssessmentQuestionComponentVM.selectedQuestionTypeId = (question.questionTypeId as number);
            this.questionsDragAndDropList = [];
            this.FetchQuestionsForDnd(question.selfAssessmentId);
            if (this.selfAssessmentQuestion.contentId > 0) {
                const questionLearningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentById(this.selfAssessmentQuestion.contentId);
                if (questionLearningContent != null) {
                    this.SelfAssessmentQuestionComponentVM.showLinkedResource = true;
                    this.newQuestionLearningContent.set(questionLearningContent.data);
                    this.newQuestionLearningContent.selfAssessmentContentModerationNotes = questionLearningContent.data.selfAssessmentContentModerationNotes;
                    this.showAssessmentQuestionContentModerationNotes = this.selfAssessment.moderationState === ModerationState.Review;
                }
                this.SelfAssessmentQuestionComponentVM.fileDescriptorList.set([]);
                var fileDescriptor = new FileLookup();
                fileDescriptor.fileDescriptorId = this.newQuestionLearningContent.learningContent.mediaObject?.fileDescriptorId as string;
                fileDescriptor.fileName = this.newQuestionLearningContent.learningContent.mediaObject?.fileName as string;
                fileDescriptor.mediaTypeId = this.newQuestionLearningContent.mediaTypeFetched;
                fileDescriptor.mimeType = this.newQuestionLearningContent.learningContent.mediaObject?.extension as string;
                this.SelfAssessmentQuestionComponentVM.fileDescriptorList.push(fileDescriptor);
                this.SelfAssessmentQuestionComponentVM.selectedMediaType = this.newQuestionLearningContent.mediaTypeFetched;
            }
            else {
                this.newQuestionLearningContent = new LearningObjectContentLookup();
                this.newQuestionLearningContent.learningContent = new LearningContentCombinedObject();
                this.SelfAssessmentQuestionComponentVM.fileDescriptorList = new List(FileLookup);
                this.SelfAssessmentQuestionComponentVM.showLinkedResource = false;
                this.showAssessmentQuestionContentModerationNotes = false;
            }
            this.hideQuizCard = true;
        }
    }

    public checkForDuplicateTag2s(id: number) {
        var tag2s = this.selfAssessment.selfAssessmentTags2.filter(c => c.tag2Id === id);
        if (tag2s.length > 1) {
            ModalUtils.showMessage("This is a Duplicate Tag2", "Please Remove Duplicate Tag", 4);
        }
    }

    public setSelectedMediaType(id: number) {
        this.selectedMediaType = id;
    }

    public async deleteQuestionConfirmed(question: SelfAssessmentQuestionLookup) {
        await this.taskRunner.waitFor(this.selfAssessmentApiClient.DeleteQuestion(question.selfAssessmentQuestionId));
        this.selfAssessmentLookupPageManager.refreshData();
    }

    public async deleteSelfAssessmentQuestion(questionToDelete: SelfAssessmentQuestionLookup) {
        await ModalUtils.showYesNoDismissible("Delete " + questionToDelete.selfAssessmentQuestionId,
            "Are you sure you want to delete this Question? ", () => this.deleteQuestionConfirmed(questionToDelete));
    }

    public async searchSelfAssessments() {
        this.selfAssessmentLookupPageManager.refreshData();
        this.selectedSelfAssessmentId = 0;
        this.selectedSelfAssessmentQuestionId = 0;
        this.selfAssessment = new SelfAssessmentLookup();
        this.selfAssessmentQuestion = new SelfAssessmentQuestionLookup();
        this.hideEditTagsCard = true;
        this.hideEditModuleCard = true;
        this.hideUpdate = true;
        this.hideQuizCard = false;
        this.showAssessmentCard = true;
    }

    public myContent: boolean = false;
    public UpdateCriteriaCreatedBy() {
        if (this.selfAssessmentCriteria.meta.Createdby.value === '') {
            this.selfAssessmentCriteria.meta.Createdby.value = this.currentUser.userGuid;
            this.myContent = true;
        } else {
            this.selfAssessmentCriteria.meta.Createdby.value = '';
            this.myContent = false;
        }
    }

    public clearSelfAssessmentSearchFilters() {
        this.selfAssessmentCriteria.SelfAssessmentTitle = "";
        this.selfAssessmentCriteria.meta.Createdby.value = this.currentUser.userGuid;
        this.selfAssessmentCriteria.FilterByCreated = true;
        this.selfAssessmentCriteria.keyword = "";
        this.selfAssessmentCriteria.SelfAssessmentId = null;
        this.selfAssessmentCriteria.SelfAssessmentTag2Id = 0;
        this.selfAssessmentCriteria.SelfAssessmentTag1Id = 0;
        this.selfAssessmentCriteria.SelfAssessmentTag1Name = "";
        this.selfAssessmentCriteria.StateSelectedUnknown = false;
        this.selfAssessmentCriteria.StateSelectedUnmoderated = true;
        this.selfAssessmentCriteria.StateSelectedSubmittedForModeration = false;
        this.selfAssessmentCriteria.StateSelectedUnderModeration = false;
        this.selfAssessmentCriteria.StateSelectedReview = false;
        this.selfAssessmentCriteria.StateSelectedModerated = false;
        this.selfAssessmentCriteria.StateSelectedResubmittedForModeration = false;
        this.myContent = true;
        this.selfAssessmentLookupPageManager.refreshData();
    }

    public checkForCriteriaSet() {
        return this.searchCriteriaClear;
    }

    public showCriteria() {
        this.hideCriteria = false;
        if (this.selectedSelfAssessmentId !== 0) {
            this.selfAssessmentCriteria.SelfAssessmentId = null;
            this.selfAssessmentLookupPageManager.pageSize = 10;
            this.selfAssessmentLookupPageManager.refreshData();
        }
    }

    //used to track Dnd changes
    public setDNDItems(items: Array<SelfAssessmentQuestionDragableModel>) {
        this.questionsDragAndDropList = items;
        this.questionsDragAndDropList.forEach(element => {
            const item = items.find(c => c.id === element.id);
            const newindex = items.findIndex(c => c.id === element.id);
            if (item?.id === element.id) {
                element.order = newindex;
            }
        });
    }

    public async saveQuestionOrderChanges() {
        for (const selfsDnd of this.questionsDragAndDropList) {
            await this.selfAssessmentApiClient.UpdateSelfAssessmentQuestionOrder(selfsDnd.id, selfsDnd.order);
        }
        this.questionsDragAndDropList = [];
        this.FetchQuestionsForDnd(this.selfAssessment.selfAssessmentId);
        this.selfAssessmentLookupPageManager.refreshData();
        this.notifications.addSuccess("Self Assessment Questions updated", "Self Assessment Questions updated", NotificationDuration.Standard);
    }

    public SubmitForModeration = async (assessmentId: number) => {
        await this.selfAssessmentApiClient.submitAssessmentForModeration(assessmentId);
        this.selfAssessmentLookupPageManager.refreshData();
        this.clearSelfAssessmentSearchFilters();
        this.searchSelfAssessments();
        this.hideCriteria = false;
        this.notifications.addSuccess("Assessment Submitted", "Submitted for Moderation. You will be notified via email once assessment has been moderated", NotificationDuration.Standard);
    }

    public ResubmitSelfAssessmentForModeration = async (assessmentId: number) => {
        await this.selfAssessmentApiClient.resubmitAssessmentForModeration(assessmentId);
        this.notifications.addSuccess("Assessment Resubmitted", "Resubmitted for Moderation. You will be notified via email once assessment has been moderated", NotificationDuration.Standard);
        this.selfAssessmentLookupPageManager.refreshData();
    }

    public SetSelectedModerateableAssessment = async (id: number) => {
        this.selectedSelfAssessmentId = id;
        this.moderateableSelfAssessment = new SelfAssessmentModerationLookup();
        var response = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getModerateableSelfAssessment(id));
        this.moderateableSelfAssessment.set(response.data);
        this.moderateableSelfAssessment.selfAssessmentDescription.meta.ApprovedByModerator.value = this.moderateableSelfAssessment.selfAssessmentDescription.fieldState === ModerationState.Moderated;
        this.moderateableSelfAssessment.selfAssessmentTitle.meta.ApprovedByModerator.value = this.moderateableSelfAssessment.selfAssessmentTitle.fieldState === ModerationState.Moderated;
        this.moderateableSelfAssessment.selfAssessmentTags2.forEach(element => {
            element.meta.approvedByModerator.value = element.fieldState === ModerationState.Moderated;
        });
        var responseQuestion = await this.taskRunner.waitFor(this.selfAssessmentApiClient.GetSelfAssessmentModerationQuestionLookupList(id));
        this.moderateableSelfAssessment.selfAssessmentQuestions.set(responseQuestion.data);
        this.moderateableSelfAssessment.selfAssessmentQuestions.forEach(element => {
            element.questionText.meta.ApprovedByModerator.value = element.questionText.fieldState === ModerationState.Moderated;
            element.tag3.meta.ApprovedByModerator.value = element.tag3.fieldState === ModerationState.Moderated;
            element.selfAssessmentOptions.meta.ApprovedByModerator.value = element.selfAssessmentOptions.fieldState === ModerationState.Moderated;
        });
        this.showAssessmentModerationNotes = false;
        this.showAssessmentQuestionDnDModerationNotes = false;
        this.FetchQuestionsForDnd(this.selectedSelfAssessmentId);
    }

    public moderationSummary = new SelfAssessmentModerationSummary();
    public GetModerationSummary = async (selfAssessmentId: number) => {
        this.taskRunner.run(async () => {
            await this.SetSelectedModerateableAssessment(selfAssessmentId);
            const assessmentInfo = this.moderateableSelfAssessment;
            /// Set the assessment moderation info.
            this.moderationSummary = new SelfAssessmentModerationSummary();
            this.moderationSummary.selfAssessmentId = assessmentInfo.selfAssessmentId;
            this.moderationSummary.title = assessmentInfo.selfAssessmentTitle.value;
            this.moderationSummary.titleApproved = assessmentInfo.selfAssessmentTitle.fieldState === ModerationState.Moderated;
            this.moderationSummary.description = assessmentInfo.selfAssessmentDescription.value;
            this.moderationSummary.descriptionApproved = assessmentInfo.selfAssessmentDescription.fieldState === ModerationState.Moderated;
            this.moderationSummary.baseAssessmentApproved = this.moderationSummary.titleApproved && this.moderationSummary.descriptionApproved;
            /// Check that the assessment tags are moderated 
            assessmentInfo.selfAssessmentTags2.forEach(tagLookUp => {
                var tagSummary = new AssessmentTagsSummary();
                tagSummary.tag1Id = tagLookUp.tag1Id;
                tagSummary.tag2Id = tagLookUp.tag2Id;
                tagSummary.tag1Name = tagLookUp.tag1Name;
                tagSummary.tag2Name = tagLookUp.tag2Name;
                tagSummary.approved = tagLookUp.fieldState === ModerationState.Moderated;
                tagSummary.errorMessage = tagSummary.approved ? "" : `Category Tag: ${tagLookUp.tag1Name} with Sub-Category Tag: ${tagLookUp.tag2Name} is not approved.`;
                this.moderationSummary.assessmentTags.push(tagSummary);
                if (!tagSummary.approved) {
                    this.moderationSummary.tagsApproved = false;
                }
            });

            /// Check that the questions are moderated
            assessmentInfo.selfAssessmentQuestions.forEach(assessmentQuestion => {
                var questionSummary = new AssessmentQuestion();
                questionSummary.questionId = assessmentQuestion.selfAssessmentQuestionId;
                questionSummary.question = assessmentQuestion.questionText.value;
                questionSummary.questionApproved = assessmentQuestion.questionText.fieldState === ModerationState.Moderated;
                questionSummary.tag3Approved = assessmentQuestion.tag3.fieldState === ModerationState.Moderated;
                questionSummary.optionsApproved = assessmentQuestion.selfAssessmentOptions.fieldState === ModerationState.Moderated;
                questionSummary.assessmentQuestionApproved = questionSummary.questionApproved && questionSummary.tag3Approved && questionSummary.optionsApproved;
                if (!questionSummary.assessmentQuestionApproved) {
                    this.moderationSummary.assessmentQuestionsApproved = false;
                }
                this.moderationSummary.assessmentQuestions.push(questionSummary);
            })
            this.showAssessmentReviewSummaryDrawer = true;
        })
    }

    public CheckSelfAssessmentTag2sModerated = (tag2s: List<SelfAssessmentTagLookup>): boolean => {
        tag2s.forEach(element => {
            if (element.fieldState !== ModerationState.Moderated) {
                return false;
            }
            else {
                return true;
            }
        });
        return true;
    }

    public SetSelectedQuestionType = (id: number) => {
        this.selectedQuestionTypeId = id;
    }


    /// Gets and set the Download path from Blob storage
    public DownloadFileGet(this: any, fileDescriptorId: string, mediaTypeId: number, link: string) {
        this.taskRunner.run(async () => {
            var response = await this.learningModuleApiClient.getFileDownloadURL(fileDescriptorId, mediaTypeId);
            if (response.data) {
                window.open(response.data.toString(), '_blank');
            }
        });
    }
}