import { Attributes, ModelBase, NeoModel, Utils, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UserVerificationLookup extends ModelBase {

    public userGuid: string = Utils.emptyGuid();

    @Attributes.Date()
    public expiryDate : Date | null = null;

    public verificationAttempts: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}