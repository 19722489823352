import React from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../Styles/RichTextEditor.scss";
import { NeoModel } from "@singularsystems/neo-core";
import { AppService } from "../../App/Services/AppService";
import Types from "../../App/AppTypes";
import { Views } from "@singularsystems/neo-react";
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import toolbarConfig from "./RichTextEditorToolbarConfig";
import { stateToHTML } from "draft-js-export-html";
import {
  UploadToCloudComponent,
  UploadToCloudComponentVM,
} from "./RichTextEditorCutstomComponents/UploadToCloudComponent";
import { observer } from "mobx-react";

//See https://blog.logrocket.com/building-rich-text-editors-in-react-using-draft-js-and-react-draft-wysiwyg/
// https://github.com/jpuri/react-draft-wysiwyg/tree/master/stories for examples

interface Props {
  viewModel?: RichTextEditorVM;
  labelText?: string;
  bind: IPropertyInstance;
  readOnly?: boolean;
  showCustomButtons?: boolean;
  onChange?: (value: string) => void;
  htmlContent?: IPropertyInstance;
  onBlur?: () => void;
}

@NeoModel
export class RichTextEditorVM extends Views.ViewModelBase {
  constructor(
    public taskRunner = AppService.get(Types.Neo.TaskRunner)) {
    super(taskRunner);
  }

  public prevContentHtml: string = "";
  public editorState: EditorState = EditorState.createWithContent(ContentState.createFromText(""));
  public editorRef: React.RefObject<Editor> = React.createRef<Editor>();
  public SetEditorState = (bind: IPropertyInstance) => {
    if (bind.value !== null) {
      if (bind.value.length > 0) {
        this.editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(bind.value)));
      } else {
        this.editorState = EditorState.createWithContent(ContentState.createFromText(bind.value));
      }
    }

  };
}

@observer
export default class RichTextEditor extends React.Component<Props> {
  private viewModel = new RichTextEditorVM();
  constructor(props: any) {
    super(props);
    this.viewModel = new RichTextEditorVM();
    this.viewModel.editorRef = React.createRef<Editor>();
    this.viewModel.editorState = EditorState.createWithContent(ContentState.createFromText(""));
    this.viewModel.SetEditorState(this.props.bind);
  }

  focus() {
    let editor = this.viewModel.editorRef.current;
    if (editor) {
      editor.focusEditor();
    }
  };

  setEditorState(state: EditorState) {
    this.viewModel.editorState = state;
    const convertedText = convertToRaw(state.getCurrentContent());
    this.props.bind.value = JSON.stringify(convertedText);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.bind.value !== this.props.bind.value) {
      this.viewModel.SetEditorState(this.props.bind);
    }
  };

  onChangeProcess(state: RawDraftContentState) {
    var htmlContent = stateToHTML(convertFromRaw(state));
    if (htmlContent !== this.viewModel.prevContentHtml) {
      if (this.props.htmlContent != null) {
        this.props.htmlContent!.value = htmlContent;
      }
      this.viewModel.prevContentHtml = htmlContent;
            
      if (!(this.props.onChange === null || this.props.onChange === undefined)) {
        this.props.onChange!(this.props.bind.value)
      }
    }
  }

  render() {
    return (
      <div className="editor" >
        <h5 >{this.props.labelText}</h5>
        <Editor
          editorStyle={{ fontSize: "14pt", fontFamily: "Verdana", }}
          editorClassName={this.props.readOnly ? "custom-editor-class" : ""}
          onChange={(state) => { this.onChangeProcess(state) }}
          onBlur={() => this.props.onBlur === undefined ? {} : this.props.onBlur()}
          editorState={this.viewModel.editorState}
          onEditorStateChange={(state: EditorState) => { this.setEditorState(state) }}
          ref={this.viewModel.editorRef}
          readOnly={this.props.readOnly}
          toolbarHidden={this.props.readOnly}
          toolbar={toolbarConfig}
          toolbarCustomButtons={this.props.showCustomButtons
            ? [
              <UploadToCloudComponent
                editorState={this.viewModel.editorState}
                onChange={(state: EditorState) => { this.setEditorState(state); }}
                viewModel={new UploadToCloudComponentVM(this.viewModel.taskRunner)}
                focusEditor={this.focus} />,
            ] : []
          }
        />
      </div>
    );
  }
}
