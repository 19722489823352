import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../ForumTypes';
import ForumLookup from '../../../Models/Lookups/ForumLookup';

export interface IForumUpdateApiClient {

    /**
     * Creates a forum
     * @param forum Forum to create
     * @returns Forum
     */
    createForumAsync(forum: Model.PartialPlainObject<ForumLookup>): AxiosPromise<any>;

    /**
     * Creates a forum
     * @param forum Forum to create
     * @returns Forum
     */
    updateForumAsync(forum: Model.PartialPlainObject<ForumLookup>): AxiosPromise<any>;

    /**
     * Creates a new conversation for a global module.
     * @param learningModuleId learningModuleId.
     * @returns The new Forum.
     */
    createGlobalModuleConversationAsync(learningModuleId: number): AxiosPromise<Model.PlainObject<ForumLookup>>;

    /**
     * Creates a new forum for a PLC Conversation.
     * @param learningModuleId learningModuleId.
     * @param learningPathwayId learningPathwayId.
     * @returns The new forum.
     */
    createPLCConversation(learningPathwayId: number): AxiosPromise<Model.PlainObject<ForumLookup>>;

    // Client only properties / methods
}

@injectable()
export default class ForumUpdateApiClient extends Data.ApiClientBase implements IForumUpdateApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.ForumApi.ApiPath}/ForumUpdate`);
    }

    public createForumAsync(forum: Model.PartialPlainObject<ForumLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/CreateForum`, forum);
    }

    public updateForumAsync(forum: Model.PartialPlainObject<ForumLookup>): AxiosPromise<any> {
        return this.axios.put(`${this.apiPath}/UpdateForum`, forum);
    }

    public createGlobalModuleConversationAsync(learningModuleId: number): AxiosPromise<Model.PlainObject<ForumLookup>> {
        return this.axios.put(`${this.apiPath}/CreateGlobalModuleConversationAsync/${learningModuleId}`);
    }

    public createPLCConversation(learningPathwayId: number): AxiosPromise<Model.PlainObject<ForumLookup>> {
        return this.axios.put(`${this.apiPath}/CreatePLCConversation/${learningPathwayId}`);
    }

    // Client only properties / methods
}