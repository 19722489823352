import { Data, List, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../App/Services/AppService";
import CircuitSearchCriteria from "../Models/Criteria/CircuitSearchCriteria";
import CircuitLookup from "../Models/Lookups/CircuitLookup";
import Circuit from "../Models/Organisations/Circuit";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum"

@NeoModel
export default class CircuitComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private circuitApiClient = AppService.get(Types.Organisations.ApiClients.CircuitApiClient),
        private circuitQueryApiClient = AppService.get(Types.Organisations.ApiClients.CircuitQueryApiClient),
        private districtApiClient = AppService.get(Types.Organisations.ApiClients.DistrictApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
        this.autoDispose(this.criteria.onAnyPropertyChanged(() => this.circuitLookupPageManager.refreshData(true, this.taskRunner)))
    }

    //Properties 
    public CircuitList = new List(Circuit);
    public selectedCircuitId: number = 0;
    public selectedCircuit = new Circuit();

    public filterCircuitId: number = 0

    //Drop Downs
    public districtList = new Data.ApiClientDataSource(this.districtApiClient.get)

    //Circuit Paging Manager
    public criteria = new CircuitSearchCriteria();
    public showEditModal: boolean = false;

    public circuitLookupPageManager = new Data.PageManager(this.criteria, CircuitLookup, this.circuitQueryApiClient.getCircuitListPaged, {
        pageSize: 10,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sortBy: "circuitName",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner
    });

    public async SetCriteria(districtId: number) {
        this.criteria.districtId = districtId;
        this.circuitLookupPageManager.refreshData(true, this.taskRunner);
    }

    public async saveCircuit() {
        this.taskRunner.run(async () => {
            this.showEditModal = false
            const circuit = this.selectedCircuit;
            await this.circuitApiClient.save(circuit.toJSObject());
            this.selectedCircuitId = 0;
            this.selectedCircuit = new Circuit();
            this.circuitLookupPageManager.refreshData()
            this.notifications.addSuccess("Circuit saved", null, NotificationDuration.Short);
        })
    }

    public async deleteCircuit(circuitId: number) {
        this.taskRunner.run(async () => {
            await this.circuitApiClient.delete(circuitId);
            this.circuitLookupPageManager.refreshData();
            this.notifications.addSuccess("Circuit Deleted", null, NotificationDuration.Short);
        })
    }

    public async showCircuitEditModal(circuitId: number) {
        if (circuitId !== 0) {
            this.selectedCircuitId = circuitId
            const response = await this.taskRunner.waitFor(this.circuitApiClient.getById(circuitId));
            this.selectedCircuit.set(response.data)
        }
        this.showEditModal = true;
    }

    public async closeEditModal() {
        this.selectedCircuit = new Circuit();
        this.selectedCircuitId = 0;
        this.showEditModal = false;
    }

    public async deleteCircuitPopUp(circuitName: string, circuitId: number) {
        const response = await this.taskRunner.waitFor(this.circuitQueryApiClient.checkCircuitLinks(circuitId));
        if (response.data) {
            ModalUtils.showMessage("Cannot Delete " + circuitName, "This circuit has schools linked to it and cannot be deleted", 3)
        }
        else {
            await ModalUtils.showYesNoDismissible("Delete Circuit " + circuitName,
                "Are you sure you want to delete this Circuit? ", () => this.deleteCircuit(circuitId))
        }
    }

    public async setSelectedCircuit(circuitId: number) {
        // Unselect Circuit
        if (this.filterCircuitId === circuitId) {
            this.filterCircuitId = 0;
        }
        // Set Selected Circuit
        else if (circuitId) {
            this.filterCircuitId = circuitId;
        } else {
            this.filterCircuitId = 0
        }
    }

    public clearFilters() {
        this.criteria.meta.districtId.value = 0;
        this.criteria.meta.circuitName.value = "";
    }
}