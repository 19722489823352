import { AppService, Types } from "../../App/Services/AppService";
import { List, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import SelfAssessmentLookup from "../Models/Lookups/SelfAssessmentLookup";
import SelfAssessmentQuestionLookup from "../Models/Lookups/SelfAssessmentQuestionLookup";
import SelfAssessmentQuestionTagLookup from "../Models/Lookups/SelfAssessmentQuestionTagLookup";
import { QuestionType } from "../Views/Assessment/SelfAssessmentQuestionComponent";
import LearningContentCombinedObject from "../Models/LearningObjects/LearningContentCombinedObject";
import FileLookup from "../Models/Lookups/FileLookup";
import { LearningContentMediaType } from "../Models/LearningObjects/LearningContent/LearningContentMediaType.enum";
import { RichTextEditorVM } from "./RichTextEditor";
import LearningObjectContentLookup from "../Models/Lookups/LearningObjectContentLookup";
import { ModerationState } from "../Models/LearningObjects/ModerationState.enum";
import MediaObject from "../Models/LearningObjects/LearningContent/MediaObject";

@NeoModel
export default class AssessmentQuestionVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    public learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
    private selfAssessmentApiClient = AppService.get(Types.Learning.ApiClients.SelfAssessmentApiClient)) {
    super(taskRunner);
  }

  public selfAssessment = new SelfAssessmentLookup();
  public assessmentId = 0;
  public selfAssessmentQuestion = new SelfAssessmentQuestionLookup();
  public topicList = new List(SelfAssessmentQuestionTagLookup);
  public hideRichText = true;
  public hideContent = true;
  public richTextVM = new RichTextEditorVM();
  public fileDescriptorList = new List(FileLookup);
  public allowedExtensions: string[] = [];
  public hideQuestionNotes = true;
  public hideContentNotes = true;

  public GetAssessment(assessmentId: number, questionId: number) {
    this.taskRunner.run(async () => {
      this.hideRichText = true;
      this.selfAssessment = new SelfAssessmentLookup();
      this.selfAssessmentQuestion = new SelfAssessmentQuestionLookup();
      this.assessmentId = assessmentId;

      var assessmentResponse = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessment(assessmentId));
      this.selfAssessment.set(assessmentResponse.data);
      this.selfAssessment.moderationState = assessmentResponse.data.moderationState as number
      this.selfAssessmentQuestion.questionTypeId = this.selfAssessment.questionTypeId;
      this.selfAssessmentQuestion.sentiment = this.selfAssessment.sentiment;
      this.selfAssessmentQuestion.selfAssessmentId = this.selfAssessment.selfAssessmentId;
      this.selfAssessmentQuestion.newLearningContent = new LearningObjectContentLookup();
      this.selfAssessmentQuestion.newLearningContent.learningContent = new LearningContentCombinedObject();
      if (this.selfAssessmentQuestion.questionTypeId === QuestionType.LikertScale) {
        var optionResponse = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentOptions(this.selfAssessment.sentiment, 4));
        this.selfAssessmentQuestion.selfAssessmentOptionList.update(optionResponse.data);
      }

      await this.GetTag3List(assessmentId);
      if (questionId !== 0) {
        await this.GetAssessmentQuestion(questionId);
      } else {
        this.hideRichText = false;
      }
    });
  }

  public async GetAssessmentQuestion(questionId: number) {
    this.hideContent = true;
    const response = await this.selfAssessmentApiClient.getSelfAssessmentQuestion(questionId);
    this.selfAssessmentQuestion.selfAssessmentQuestionId = response.data.selfAssessmentQuestionId;
    this.selfAssessmentQuestion.meta.text.value = response.data.text;
    this.selfAssessmentQuestion.tag3Id = response.data.tag3Id;
    this.selfAssessmentQuestion.contentId = response.data.contentId;
    this.selfAssessmentQuestion.selfAssessmentQuestionModerationNotes = response.data.selfAssessmentQuestionModerationNotes;
    if (this.selfAssessment.moderationState === ModerationState.Review) {
      this.hideQuestionNotes = false;
    }
    this.hideRichText = false;
    // this.selfAssessmentQuestion.newLearningContent = response.data.newLearningContent;
    if (this.selfAssessmentQuestion.questionTypeId === QuestionType.MultipleChoice) {
      var multiOptionResponse = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentOptionsMulti(questionId));
      this.selfAssessmentQuestion.selfAssessmentOptionList.set(multiOptionResponse.data);
      this.selfAssessmentQuestion.selfAssessmentOptionList.forEach(element => {
        if (element.points === 0) {
          element.answer = true;
        }
      });
    } else {
      var optionResponse = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentOptions(this.selfAssessmentQuestion.sentiment, 4));
      this.selfAssessmentQuestion.selfAssessmentOptionList.set(optionResponse.data);
    }
    if (this.selfAssessmentQuestion.contentId !== null && this.selfAssessmentQuestion.contentId !== 0) {

      const questionLearningContent = await AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient).getLearningContentById(this.selfAssessmentQuestion.contentId);
      this.selfAssessmentQuestion.newLearningContent.mediaTypeFetched = questionLearningContent.data.mediaTypeFetched;
      this.selfAssessmentQuestion.newLearningContent.learningContent.title = questionLearningContent.data.learningContent.title;
      this.selfAssessmentQuestion.newLearningContent.selfAssessmentContentModerationNotes = questionLearningContent.data.selfAssessmentContentModerationNotes;
      this.selfAssessmentQuestion.newLearningContent.learningContent.mediaObject = new MediaObject();
      this.selfAssessmentQuestion.newLearningContent.learningContent.mediaObject.mapFrom(questionLearningContent.data.learningContent.mediaObject);
      this.selfAssessmentQuestion.newLearningContent.learningContent.mediaType = questionLearningContent.data.mediaTypeFetched;

      if (this.selfAssessment.moderationState === ModerationState.Review) {
        this.hideContentNotes = false;
      }
      var fileDescriptor = new FileLookup();
      fileDescriptor.fileDescriptorId = this.selfAssessmentQuestion.newLearningContent.learningContent.mediaObject?.fileDescriptorId as string;
      fileDescriptor.fileName = this.selfAssessmentQuestion.newLearningContent.learningContent.mediaObject?.fileName as string;
      fileDescriptor.mediaTypeId = this.selfAssessmentQuestion.newLearningContent.mediaTypeFetched;
      fileDescriptor.mimeType = this.selfAssessmentQuestion.newLearningContent.learningContent.mediaObject?.extension as string;
      this.fileDescriptorList.push(fileDescriptor);
      this.hideContent = false;
    } else {
      this.selfAssessmentQuestion.newLearningContent = new LearningObjectContentLookup();
      this.selfAssessmentQuestion.newLearningContent.mediaTypeFetched = 1;
    }
  }

  public async AddContent() {
    this.hideContent = false;
  }

  public async GetTag3List(selfAssessmentId: number) {
    const responseList = await this.taskRunner.waitFor(this.selfAssessmentApiClient.getSelfAssessmentQuestionTagSelectList(selfAssessmentId));
    this.topicList.set(responseList.data);
  }

  /// File upload

  public async getFileLookup(fileDescriptorId: string, mediaTypeId: number) {
    this.fileDescriptorList = new List(FileLookup);
    const fileLookUp = (await this.taskRunner.waitFor(this.learningModuleApiClient.getFileByDescriptorId(fileDescriptorId, mediaTypeId))).data;
    if (fileLookUp) {
      for (let index = 0; index < fileLookUp.length; index++) {
        var element = fileLookUp[index];
        var fileDescriptor = new FileLookup();
        fileDescriptor.fileDescriptorId = element.fileDescriptorId as string;
        fileDescriptor.fileName = element.fileName as string;
        fileDescriptor.mediaTypeId = element.mediaTypeId as number;
        fileDescriptor.mimeType = element.mimeType as string;
        this.fileDescriptorList.push(fileDescriptor);
      }
    }
  }

  public setAllowedExtensions(mediaTypeId: number) {
    this.allowedExtensions = [];
    switch (mediaTypeId) {
      case LearningContentMediaType.Document: {
        this.allowedExtensions = [".docx", ".pptx", ".ppt", ".xlsx", ".xls", ".pdf"];
        this.selfAssessmentQuestion.newLearningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
        break;
      }
      case LearningContentMediaType.Image: {
        this.allowedExtensions = [".jpg", ".jpeg", ".png", ".JPG",];
        this.selfAssessmentQuestion.newLearningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
        break;
      }
      case LearningContentMediaType.Sound: {
        this.allowedExtensions = [".MP3", ".MP4", ".wav", ".MPEG-4", ".mp4", ".mp3", ".ogg"];
        this.selfAssessmentQuestion.newLearningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
        break;
      }
      case LearningContentMediaType.Video: {
        this.allowedExtensions = [".avi", ".mov", ".qt", ".wmv", ".MTS", ".M2TS", ".TS", ".mp4", ".mp3", "m4v"];
        this.selfAssessmentQuestion.newLearningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
        break;
      }
      case LearningContentMediaType.SCORM: {
        this.allowedExtensions = [".xml", ".zip"];
        this.selfAssessmentQuestion.newLearningContent.learningContent.allowedFileExtensions = this.allowedExtensions.toString().replace(new RegExp(",", "g"), ' | ');
        break;
      }
      case LearningContentMediaType.Unsupported: {
        this.allowedExtensions = [];
        this.selfAssessmentQuestion.newLearningContent.learningContent.allowedFileExtensions = '';
        break;
      }
    }
  }
}