import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class SACEEducatorLookup extends LookupBase {

    public educatorId: number = 0;

    public firstName: string = "";

    public surname: string = "";

    public mobileNumber: string = "";

    @Attributes.Date()
    public educatorCreatedDate: Date = new Date();

    @Attributes.Integer()
    public age: number = 0;

    public raceId: number = 0;

    public titleId: number = 0;

    @Attributes.Nullable()
    public schoolId: number | null = null;

    public emailAddress: string = "";

    public persalId: string = "";

    @Attributes.Display("SACE")
    public saceId: string = "";

    public identificationNumber: string = "";

    // Client only properties / methods
}