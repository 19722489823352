import { observable, action } from 'mobx';
import { Utils } from '@singularsystems/neo-core';
import { injectable } from 'inversify';

export enum ScreenSize {
    ExtraSmall = 1,
    Small = 2,
    Medium = 3,
    Large = 4,
    ExtraLarge = 5,
    Huge = 6
}

export enum MenuState {
    Hidden,
    Collapsed,
    Full,
    FullOverlay,
}

/**
 * Contains logic about the layout of the app. E.g. screensize, theme etc.
 * Use AppLayout.current for the singleton instance.
 */
@injectable()
export default class AppLayout {

    @observable.ref
    public pinHeader = true;

    @observable
    public menuVisible = true;

    @observable.ref
    public currentScreenSize = ScreenSize.Medium;

    @observable.ref
    public isScrollTop = true;

    public get menuState() {
        if (this.currentScreenSize < ScreenSize.Medium) {
            if (this.menuVisible) {
                return MenuState.FullOverlay;
            } else {
                return MenuState.Hidden;
            }
        } else {
            if (this.menuVisible) {
                return MenuState.Full;
            } else {
                return MenuState.Collapsed;
            }
        }
    }

    constructor() {
        window.addEventListener("resize", this.onWindowResize.bind(this));
        window.addEventListener("click", (e) => {
        });

        document.addEventListener("scroll", () => {
            // This event is fired a lot, which is why we are using peek().
            if ((window.scrollY === 0) !== Utils.peek(this, "isScrollTop")) {
                this.isScrollTop = window.scrollY === 0;
            }
        });

        this.onWindowResize();
    }

    @action
    private onWindowResize() {

        if (window.innerWidth <= 576) {
            this.currentScreenSize = ScreenSize.ExtraSmall;
            this.menuVisible = false;
        } else if (window.innerWidth <= 768) {
            this.currentScreenSize = ScreenSize.Small;
            this.menuVisible = false;
        } else if (window.innerWidth <= 992) {
            this.currentScreenSize = ScreenSize.Medium;
            this.menuVisible = true;
        } else if (window.innerWidth <= 1200) {
            this.currentScreenSize = ScreenSize.Large;
            this.menuVisible = true;
        } else if (window.innerWidth <= 1360) {
            this.currentScreenSize = ScreenSize.ExtraLarge;
            this.menuVisible = true;
        } else {
            this.currentScreenSize = ScreenSize.Huge;
            this.menuVisible = true;
        }
    }

    private static _current?: AppLayout;
    /** Returns the app layout for the app. */
    public static get current(): AppLayout {
        if (!AppLayout._current) {
            AppLayout._current = new AppLayout();
        }
        return AppLayout._current;
    }

    public showMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.menuVisible = !this.menuVisible;
    }

    private isChildOf(element: Element, parent: Element) {
        while (true) {
            if (element === parent) {
                return true;
            }
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                return false;
            }
        }
    }
}