import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { TopicTypes } from '../Enums/TopicTypes.enum';

@NeoModel
export default class TopicLookup extends ModelBase {

    public topicId: number = 0;

    public forumId: number = 0;

    public topicName: string = "";

    public forumName: string = "";

    public topicType: TopicTypes = TopicTypes.Unspecified;

    public isActive: boolean = true;

    public isDeleted: boolean = false;

    public isReported: boolean = false;

    public createdBy: string = "";

    @Attributes.Date()
    public createdOn: Date = new Date();

    @Attributes.Date()
    public lastPostDate: Date = new Date();

    public timeAgo: string = "";

    public forumIntegrationTypes: number = 0;

    public activityName: string = "";

    public moduleName: string = "";

    public pathwayName: string = "";
    // Client only properties / methods
}