import { Data, NeoModel } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { UserTypes } from "../../App/Models/Enums/UserTypes.enums";
import { AppService, Types } from "../../App/Services/AppService";
import TDPUser from "../../Identity/Models/Security/TDPUser";
import LearningPathwayEditor from "../../Learning/Components/LearningPathwayComponents/LearningPathwayEditor";
import ModulePreview from "../../Learning/Components/LearningPathwayComponents/ModulePreview";
import { LearningPathwayType } from "../../Learning/Models/LearningPathways/LearningPathwayType.enum";
import PathwayModuleDragAndDrop from "../../Learning/Models/LearningPathways/PathwayModuleDragAndDrop";
import UserLearningModuleLookup from "../../Learning/Models/UserLearning/UserLearningModuleLookup";
import Card from "../../Template/components/Card/Card";
import CardBody from "../../Template/components/Card/CardBody";
import CardFooter from "../../Template/components/Card/CardFooter";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import GridContainer from "../../Template/components/Grid/GridContainer";
import GridItem from "../../Template/components/Grid/GridItem";
import LearningPathwayLookup from "../Models/LearningPathwayLookup";
import LearningPathwaySearchCriteria from "../Models/SearchCriteria/LearningPathwaySearchCriteria";

interface ITeacherDashboardprops {
  openLink: (id: number) => void;
  GoToPathwayModule: (moduleId: number, pathwayId: number) => void;
  GoToModule: (moduleId: number, preview: number) => void;
}

@NeoModel
export class UserPathwayPagerVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private dashboardApiClient = AppService.get(Types.Dashboard.ApiClients.DashboardQueryApiClient),
    private learningPathwayApiClient = AppService.get(Types.Learning.ApiClients.LearningPathwayQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
    this.userPathwayPagerManager.refreshData();
  }

  public pathwayCriteria = new LearningPathwaySearchCriteria();
  public showPathwayModules = false;
  public selectedPathway = new LearningPathwayLookup();
  public createModal = false;

  public userPathwayPagerManager = new Data.PageManager(this.pathwayCriteria, LearningPathwayLookup, this.dashboardApiClient.getUserPathways, {
    pageSize: 5,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "learningPathwayId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });

  public pathwayModules: PathwayModuleDragAndDrop[] = [];
  public viewPathwayModules = async (pathway: LearningPathwayLookup) => {
    this.selectedPathway = pathway;
    const response = await this.learningPathwayApiClient.getPathwayDndModules(pathway.learningPathwayId as number);
    this.pathwayModules = response.data;
    this.showPathwayModules = true;
  }

  public CloseCreateModal() {
    this.createModal = false;
    this.selectedPathway = new LearningPathwayLookup();
    this.userPathwayPagerManager.refreshData();
  }

  public module = new UserLearningModuleLookup();
  public PreviewModule = async (moduleId: number) => {
    this.moduleIdToPreview = moduleId;
    this.showPreviewDrawer = true;
  }

  public moduleIdToPreview: number = 0;
  public showPreviewDrawer = false;
}

@observer
export default class UserPathwayPager extends React.Component<ITeacherDashboardprops> {
  private authService = AppService.get(Types.Neo.Security.AuthenticationService);
  constructor(props: ITeacherDashboardprops) {
    super(props)
    this.viewModel = new UserPathwayPagerVM();
  }
  public viewModel: UserPathwayPagerVM;

  public render() {
    const user = this.authService.user as TDPUser;
    return (
      <div>
        <Card>
          <CardHeader color="info" stats >
            <GridContainer>
              <GridItem md={6}>
                <h4 className="card-title-white">My Learning Pathways</h4>
                <p className="card-category-white">
                  Your recent pathways
                </p>
              </GridItem>
              <GridItem md={6}>
                <Neo.Button style={{ float: 'right' }} hidden={user.userType === UserTypes.Unverified.toString()} isOutline text="Start new pathway" variant="success" onClick={() => this.viewModel.createModal = true} />

              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Neo.Pager pageManager={this.viewModel.userPathwayPagerManager}
              pageControlProps={{
                firstText: "",
                prevText: "Prev",
                nextText: "Next",
                lastText: "",
                alignment: "center",
                autoHide: false,
                pageSizeLabel: "Show"
              }}>
              <NeoGrid.Grid<LearningPathwayLookup> >
                {(learningPathway: LearningPathwayLookup, learningPathwayMeta) => (
                  <NeoGrid.Row >
                    <NeoGrid.Column label="Title" display={learningPathwayMeta.title} />
                    <NeoGrid.Column label="Mine/Shared/PLC" display={learningPathwayMeta.learningPathwayType} select={{ itemSource: Data.StaticDataSource.fromEnum(LearningPathwayType), renderAsText: true }} />
                    <NeoGrid.Column label="Completed Date" display={learningPathwayMeta.completedDate} />
                    <NeoGrid.ButtonColumn label="Actions" >
                      <Neo.Button icon="eye" isOutline variant="success" tooltip="Continue" hidden={user.userType === UserTypes.Unverified.toString()} onClick={() => { this.props.openLink(learningPathway.learningPathwayId) }} />
                      <Neo.Button icon="clipboard" isOutline variant="info" tooltip="View Modules" onClick={() => this.viewModel.viewPathwayModules(learningPathway)} />
                    </NeoGrid.ButtonColumn>
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </Neo.Pager>
          </CardBody>
        </Card>

        <Neo.Modal title={this.viewModel.selectedPathway.title + " Modules"} size="xl"
          show={this.viewModel.showPathwayModules}
          onClose={() => { this.viewModel.showPathwayModules = false }}
          closeButton={{ text: "Cancel", variant: "secondary", icon: "times" }} >
          <Neo.Loader task={this.viewModel.taskRunner}>
            {
              this.viewModel.pathwayModules.map((module, index) => (
                <div>
                  <Card>
                    <CardHeader icon>
                      <CardIcon color="info">
                        <i className={`icon fa fa-clipboard fa-2x`}></i>
                      </CardIcon>
                      <h4 className="Card-icon-header-text">{module.moduleTitle}</h4>
                    </CardHeader>
                    <CardBody>
                      {module.learningObjective}
                    </CardBody>
                    <CardFooter>
                      <Neo.Button text="Go to Module" variant="success" icon="eye" hidden={user.userType === UserTypes.Unverified.toString()} onClick={() => this.props.GoToPathwayModule(module.moduleId, this.viewModel.selectedPathway.learningPathwayId)} />
                    </CardFooter>
                  </Card>
                </div>
              ))
            }
          </Neo.Loader>
        </Neo.Modal>
        {!this.viewModel.createModal || <LearningPathwayEditor show={this.viewModel.createModal}
          GoToModule={(id, preview) => { if (user.userType !== UserTypes.Unverified.toString()) { this.viewModel.PreviewModule(id) } }}
          onClose={() => { this.viewModel.CloseCreateModal() }} learningPathwayId={this.viewModel.selectedPathway.learningPathwayId} />}
        {!this.viewModel.showPreviewDrawer || <ModulePreview showPreviewDrawer={this.viewModel.showPreviewDrawer}
          moduleId={this.viewModel.moduleIdToPreview} onClose={() => { this.viewModel.showPreviewDrawer = false }} />}
      </div>
    )
  }
}