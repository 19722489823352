import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import ReviewSelfAssessmentVM from './ReviewSelfAssessmentVM';
import { observer } from 'mobx-react';
import Card from '../../../Template/components/Card/Card';
import CardHeader from '../../../Template/components/Card/CardHeader';
import CardIcon from '../../../Template/components/Card/CardIcon';
import CardBody from '../../../Template/components/Card/CardBody';
import GridContainer from '../../../Template/components/Grid/GridContainer';
import GridItem from '../../../Template/components/Grid/GridItem';
import RichTextEditor from '../../Components/RichTextEditor';
import CardFooter from '../../../Template/components/Card/CardFooter';
import { GetRandomCardColor, truncateString } from '../../../App/HelperClasses/GlobalHelpers';
import ModuleConsumptionView from '../ContentConsumption/ModuleConsumptionView';
import { AppService } from '../../../App/Services/AppService';
import { Types } from '../../../Identity/IdentityTypes';
import { UserTypes } from '../../../App/Models/Enums/UserTypes.enums';
import TDPUser from '../../../Identity/Models/Security/TDPUser';
import StartModuleModal from '../../Components/StartModule';
import PathwayModuleConsumptionView from '../ContentConsumption/PathwayModuleConsumptionView';
import ModuleObjectsConsumptionLookup from '../../Models/ContentCosumption/ModuleObjectsConsumptionLookup';
import { LearningObjectType } from '../../Models/LearningObjects/LearningObjectType.enum';
import ForumDrawer from '../../../Forum/Components/ForumComponents/ForumDrawer';

class ReviewSelfAssessmentParams {
  // TODO: Add parameters here in the form: public paramName = { isQuery?: boolean, required?: boolean };
  public userAssessmentId = { isQuery: true, required: true }
}

@observer
export default class ReviewSelfAssessmentView extends Views.ViewBase<ReviewSelfAssessmentVM, ReviewSelfAssessmentParams> {
  private authService = AppService.get(Types.Neo.Security.AuthenticationService);
  public static params = new ReviewSelfAssessmentParams();

  constructor(props: unknown) {
    super("Review Self Assessment", ReviewSelfAssessmentVM, props);
  }

  private GoToPathwayModule = (moduleId: number, pathwayId: number) => {
    this.navigation.navigateToView(PathwayModuleConsumptionView, { moduleId: moduleId, pathwayId: pathwayId });
  }

  protected viewParamsUpdated() {
    this.viewModel.userAssessmentId = (this.viewParams.userAssessmentId.value as number);
    this.viewModel.GetAssessmentInfo(this.viewModel.userAssessmentId);
  }

  private startModule = async (id: number) => {
    this.viewModel.selectedModelId = id;
    this.viewModel.confirmStartNewModule = true;
  }

  private async GoToModule(moduleId: number) {
    await this.viewModel.startNewModule(moduleId);
    this.navigation.navigateToView(ModuleConsumptionView, { moduleId: moduleId });
  }

  public render() {
    const assessmentInfo = this.viewModel.userAssessment;
    const suggestedModules = this.viewModel.moduleSuggestions;
    const user = this.authService.user as TDPUser;
    return (
      <div>
        <Card>
          <CardHeader icon>
            <CardIcon color="success">
              <i className={`icon fa fa-clipboard fa-2x`}></i>
            </CardIcon>
            <h4 className="Card-icon-header-text">{assessmentInfo.selfAssessmentTitle}</h4>
          </CardHeader>
          <CardBody>
            <h4 className="module-tiles">Questionnaire Description:</h4>
            <p className="module-description">{assessmentInfo.selfAssessmentSDescription}</p>
            <Card >
              <CardHeader >
                <h4 className="Card-icon-header-text" hidden={suggestedModules.length === 0}>We Suggest the following modules based on your answers below</h4>
                <h4 className="Card-icon-header-text" hidden={suggestedModules.length !== 0}>There are no resources associated with your identified needs</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {suggestedModules.map((module) => (
                    <GridItem md={4} sm={12} xs={12} key={module.moduleId}>
                      <Card>
                        <CardHeader color={GetRandomCardColor()}  >
                          <h4 className="Card-icon-header-text" style={{ textAlign: 'center', color: "#fff" }}>{module.moduleTitle}</h4>
                        </CardHeader>
                        <CardBody>
                          <p className="module-tiles mb-3">Learning Objective: {module.moduleDescription}</p>
                          <p className="module-tiles mb-3">Category: {module.tag1}</p>
                          <p className="module-tiles mb-3">CPTD Points: {module.cptdPoints}</p>
                        </CardBody>
                        <CardFooter>
                          <div style={{ float: 'left' }}>
                            <Neo.Button icon="eye " isOutline variant="success" onClick={() => { this.viewModel.PreviewModule(module.moduleId) }} >
                              <span>Preview Module </span>
                            </Neo.Button>
                          </div>
                          <div style={{ float: 'right' }}>
                            <Neo.Button icon="plus " isOutline variant="warning" hidden={user.userType === UserTypes.Unverified.toString()} onClick={() => { this.startModule(module.moduleId) }} >
                              <span>Start Module</span>
                            </Neo.Button>
                            <Neo.Button icon="plus " isOutline variant="warning" disabled={true} hidden={user.userType !== UserTypes.Unverified.toString()} >
                              <span>You are unverified</span>
                            </Neo.Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  ))}
                </GridContainer>
              </CardBody>
            </Card>

          </CardBody>
        </Card >
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <i className={`icon fa fa-question fa-2x`}></i>
            </CardIcon>
            <h4 className="Card-icon-header-text">Questions Review</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {assessmentInfo.questions.map((question, index) => (
                <GridItem md={3} sm={12} xs={12} key={question.selfAssessmentQuestionId}>
                  <Card>
                    <CardHeader color={GetRandomCardColor()} >
                      <h4 className="Card-icon-header-text" style={{ textAlign: 'center', color: "#fff" }}>Question {index + 1}</h4>
                    </CardHeader>
                    <CardBody>
                      <RichTextEditor bind={question.meta.question} readOnly={true} labelText="" />
                      <p className="module-tiles mb-3">Your Answer : {question.selectedOption}</p>
                    </CardBody>
                  </Card>
                </GridItem>
              ))}
            </GridContainer>
          </CardBody>
        </Card>

        <StartModuleModal onClose={() => this.viewModel.confirmStartNewModule = false}
          show={this.viewModel.meta.confirmStartNewModule}
          ModuleId={this.viewModel.selectedModelId}
          GoToModule={(moduleId, preview) => this.GoToModule(moduleId)}
          GoToPathwayModule={(pathwayId, moduleId) => this.GoToPathwayModule(pathwayId, moduleId)} />

        <ForumDrawer show={this.viewModel.showPreviewDrawer} isMaximised={this.viewModel.previewDrawerMaximised}
          onClose={() => this.viewModel.showPreviewDrawer = false}
          maximiseWindow={() => this.viewModel.previewDrawerMaximised = !this.viewModel.previewDrawerMaximised}>
          {/* Main Module Info */}
          <div >
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader icon>
                    <CardIcon color="success">
                      <i className={`icon fa fa-chalkboard fa-2x`}></i>
                    </CardIcon>
                    <h4 className="Card-icon-header-text">{this.viewModel.module.moduleTitle}</h4>
                  </CardHeader>
                  <CardBody>
                    <h4 className="module-tiles">Learning Objective</h4>
                    <p className="module-description">{this.viewModel.module.learningObjective}</p>
                    <Card>
                      <CardHeader icon>
                        <CardIcon color="info">
                          <i className={`icon fa fa-tasks fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Activities</h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          {this.viewModel.module === undefined ||
                            this.viewModel.module.learningObjectList.map((lo: ModuleObjectsConsumptionLookup, index) => (
                              <GridItem md={3} key={lo.learningObjectId}>
                                <div>
                                  <Card>
                                    <CardHeader color={lo.learningObjectType === LearningObjectType.Information ? "success" : lo.learningObjectType === LearningObjectType.Quiz ? "warning" : "danger"} plain >
                                      <h4 className="card-title-white-small">({lo.order}) {lo.title}</h4>
                                    </CardHeader>
                                    <CardBody>
                                      <p className="module-description">{truncateString(lo.purpose, 150)}</p>
                                    </CardBody>
                                  </Card>
                                </div>
                              </GridItem>
                            ))
                          }
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </ForumDrawer>
      </div >
    );
  }
}