export enum Sentiment
{

    /// <summary>
    /// NotSet
    /// </summary>
    NotSet = 5,

    /// <summary>
    /// Agree
    /// </summary>
    Agree = 1,

    /// <summary>
    /// Disagree
    /// </summary>
    Disagree = 2,

    /// <summary>
    /// Never
    /// </summary>
    Never = 3,

    /// <summary>
    /// Always
    /// </summary>
    Always = 4 

  }