import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import AssessmentVM from './AssessmentVM';
import { observer } from 'mobx-react';
import Card from '../../../Template/components/Card/Card';
import CardHeader from '../../../Template/components/Card/CardHeader';
import CardIcon from '../../../Template/components/Card/CardIcon';
import CardBody from '../../../Template/components/Card/CardBody';
import GridContainer from '../../../Template/components/Grid/GridContainer';
import GridItem from '../../../Template/components/Grid/GridItem';
import SelfAssessmentLookup from '../../Models/Lookups/SelfAssessmentLookup';
import { ModerationState } from '../../Models/LearningObjects/ModerationState.enum';
import { Data } from '@singularsystems/neo-core';
import SelfAssessmentQuestionLookup from '../../Models/Lookups/SelfAssessmentQuestionLookup';
import QuestionOrderComponent from '../../Components/DragAndDrop/QuestionOrderComponent';
import RichTextEditor from '../../Components/RichTextEditor';
import { Sentiment } from '../../Models/Questions/Sentiment.enum';
import { ValidationDisplayMode } from '@singularsystems/neo-core/dist/Validation/Misc';
import AssessmentQuestion from '../../Components/AssessmentQuestion';
import { QuestionType } from './SelfAssessmentQuestionComponent';
import ForumDrawer from '../../../Forum/Components/ForumComponents/ForumDrawer';

@observer
export default class AssessmentView extends Views.ViewBase<AssessmentVM> {

  constructor(props: unknown) {
    super("Assessment", AssessmentVM, props);
  }

  public render() {
    const viewModel = this.viewModel;
    return (
      <div >
        <Card>
          <CardHeader icon>
            <CardIcon color="success">
              <i className={`fas fa-chalkboard fa-2x`}></i>
            </CardIcon>
            <h4 className="Card-icon-header-text">Questionnaires</h4>
          </CardHeader>
          <CardBody>
            <div hidden={viewModel.hideSearchArea}>
              {/* Header Buttons */}
              <GridContainer >
                <GridItem md={4}>
                  <Neo.Button variant="success" icon="plus" onClick={() => viewModel.CreateNewAssessment()} >Add New Self Assessment</Neo.Button>
                  <Neo.Button variant="info" text={viewModel.hideModerationFilters ? "Moderation States" : "Hide Moderation State filter"} icon="eye"
                    onClick={() => { viewModel.hideModerationFilters = !viewModel.hideModerationFilters }} />
                </GridItem>
              </GridContainer>

              {/* Moderation State filters */}
              <Neo.GridLayout style={{ marginTop: "15px" }} className="moderationStatesContainer" hidden={viewModel.hideModerationFilters}>
                <Neo.Input label="Unknown" type="radio" bind={viewModel.assessmentCriteria.meta.StateSelectedUnknown} />
                <Neo.Input label="Un-moderated" type="radio" bind={viewModel.assessmentCriteria.meta.StateSelectedUnmoderated} />
                <Neo.Input label="Submitted For Moderation" type="radio" bind={viewModel.assessmentCriteria.meta.StateSelectedSubmittedForModeration} />
                <Neo.Input label="Under Moderation" type="radio" bind={viewModel.assessmentCriteria.meta.StateSelectedUnderModeration} />
                <Neo.Input label="Under Review" type="radio" bind={viewModel.assessmentCriteria.meta.StateSelectedReview} />
                <Neo.Input label="Moderated" type="radio" bind={viewModel.assessmentCriteria.meta.StateSelectedModerated} />
                <Neo.Input label="Resubmitted For Moderation" type="radio" bind={viewModel.assessmentCriteria.meta.StateSelectedResubmittedForModeration} />
              </Neo.GridLayout>

              {/* Tag Filters */}
              <GridContainer>
                <GridItem md={8}>
                  <Neo.FormGroupFloating className="cerebri-regular font-12" style={{ marginTop: "15px" }} placeholder="Search for a Self Assessment by Title" bind={viewModel.assessmentCriteria.meta.keyword} />
                </GridItem>
                <GridItem md={12}>
                  <Neo.Input label="My Questionnaires" type="radio" style={{ marginTop: "15px" }} onClick={() => { this.viewModel.UpdateCriteriaCreatedBy() }} placeholder="Search for a Self Assessment"
                    bind={this.viewModel.assessmentCriteria.meta.FilterByCreated} />
                </GridItem>
                <GridItem md={6}>
                  <label style={{ marginTop: "15px" }}>Category Tag</label>
                  <Neo.AutoCompleteDropDown
                    bind={viewModel.assessmentCriteria.meta.SelfAssessmentTag1Id}
                    bindDisplay={viewModel.assessmentCriteria.meta.SelfAssessmentTag1Name}
                    displayMember="tagName"
                    itemSource={viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                    onItemSelected={(item) => { viewModel.filterTag2ListSearch(item?.tag1Id) }}
                    loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                    minCharacters={2}
                  />
                </GridItem>

                <GridItem md={6}>
                  <Neo.FormGroup label="Sub-Category Tag (based on Category Tag)" style={{ marginTop: "15px" }}
                    bind={viewModel.assessmentCriteria.meta.SelfAssessmentTag1Id} select={{
                      items: viewModel.assessmentTag2FilteredList,
                      allowNulls: true
                    }} />
                </GridItem>

                <GridItem md={4}>
                  <Neo.Button text='Search' icon='search' variant='primary' onClick={() => viewModel.selfAssessmentPageManager.refreshData()} />
                  <Neo.Button text='Show all my assessments' icon='eraser' variant='warning' onClick={() => viewModel.clearSearch()} />
                </GridItem>

              </GridContainer>
            </div>

            <Neo.Button style={{ marginBottom: "15px" }} hidden={!viewModel.hideSearchArea} text='Show search area' icon='eye' onClick={() => viewModel.hideSearchArea = false} />

            <Neo.TabContainer selectedTab={viewModel.meta.selectedTab} style={{ marginTop: "15px" }}>
              <Neo.Tab header={"Self Assessments"} icon='fa-info'>
                <Neo.Pager
                  pageManager={viewModel.selfAssessmentPageManager}
                  pageControlProps={{
                    firstText: "",
                    prevText: "Prev",
                    nextText: "Next",
                    lastText: "",
                    alignment: "center",
                    autoHide: false,
                    pageSizeLabel: "Show"
                  }}>
                  <NeoGrid.Grid<SelfAssessmentLookup>>
                    {(assessment: SelfAssessmentLookup, assessmentMeta) => (
                      <NeoGrid.RowGroup expandProperty={assessmentMeta.isExpanded}>
                        <NeoGrid.Row className={viewModel.selectedAssessmentId === assessment.selfAssessmentId ? "selected-item-css" : "bg-none"}>
                          <NeoGrid.Column label="Title" display={assessmentMeta.selfAssessmentTitle} />
                          <NeoGrid.Column label="Description" display={assessmentMeta.selfAssessmentDescription} />
                          <NeoGrid.Column label="Created By" display={assessmentMeta.createdByUser} />
                          <NeoGrid.Column label="State"
                            display={assessmentMeta.moderationState}
                            select={{
                              itemSource: Data.StaticDataSource.fromEnum(ModerationState),
                              renderAsText: true
                            }} />
                          <NeoGrid.ButtonColumn label="Actions">

                            <Neo.Button icon="eye"
                              isOutline
                              variant="success"
                              tooltip='Select'
                              disabled={assessment.moderationState === ModerationState.SubmittedForModeration
                                || assessment.moderationState === ModerationState.UnderModeration}
                              onClick={() => viewModel.selectAssessment(assessment.selfAssessmentId)} />

                            <Neo.Button icon='forward'
                              hidden={assessment.moderationState !== ModerationState.Unmoderated}
                              text="Submit for Moderation"
                              tooltip="Submit Self Assessment for review"
                              isOutline variant="info"
                              onClick={() => viewModel.submitForModeration(assessment.selfAssessmentId)} />

                            <Neo.Button hidden={assessment.moderationState !== ModerationState.Review}
                              icon="eye"
                              tooltip="Review Moderation Summary"
                              isOutline
                              variant="info"
                              onClick={() => viewModel.getModerationSummary(assessment.selfAssessmentId)} />

                            <Neo.Button hidden={assessment.moderationState !== ModerationState.Review}
                              text="Resubmit for Moderation"
                              tooltip="Submit Self Assessment for review after addressing the notes"
                              isOutline
                              variant="info"
                              onClick={() => viewModel.resubmitSelfAssessmentForModeration(assessment.selfAssessmentId)} />

                            <Neo.Button icon="lock"
                              variant="info"
                              tooltip="Admin Edit"
                              disabled={!viewModel.canChangeModeratedAssessment}
                              hidden={assessment.moderationState !== ModerationState.Moderated}
                              onClick={() => viewModel.selectAssessment(assessment.selfAssessmentId)} />

                            <Neo.Button icon="trash"
                              key="delete"
                              variant="danger"
                              tooltip="Delete Assessment"
                              disabled={assessment.moderationState === ModerationState.Moderated}
                              onClick={() => viewModel.showDeleteSelfAssessment(assessment, assessment.selfAssessmentId)} />

                          </NeoGrid.ButtonColumn>
                        </NeoGrid.Row>
                        <NeoGrid.ChildRow>
                          <NeoGrid.Grid items={assessment.selfAssessmentQuestions} initialSort={"questionOrder"}>
                            {(question: SelfAssessmentQuestionLookup, questions) => (
                              <NeoGrid.RowGroup>
                                <NeoGrid.Row key={question.selfAssessmentQuestionId} className={viewModel.selectedQuestionId === question.selfAssessmentQuestionId ? "selected-item-css" : "bg-none"}>
                                  <NeoGrid.Column label="Question" display={questions.plainText} />
                                  <NeoGrid.Column label="Question Type" display={questions.questionType} />
                                  <NeoGrid.Column label="Question Order" display={questions.questionOrder} />
                                  <NeoGrid.Column label="Linked Resource" display={questions.linkedToResource} />
                                  <NeoGrid.ButtonColumn label='Actions'>
                                    <Neo.Button
                                      icon='eye'
                                      isOutline
                                      variant="success"
                                      disabled={viewModel.selectedAssessmentId !== question.selfAssessmentId
                                        || assessment.moderationState === ModerationState.SubmittedForModeration}
                                      onClick={() => { viewModel.setSelectedQuestion(question.selfAssessmentQuestionId) }}
                                      text='Select' />

                                    <Neo.Button icon="trash" key="delete" variant="danger" onClick={() => viewModel.deleteSelfAssessmentQuestion(question)} />
                                  </NeoGrid.ButtonColumn>

                                  <NeoGrid.ButtonColumn>
                                  </NeoGrid.ButtonColumn>
                                </NeoGrid.Row>
                              </NeoGrid.RowGroup>
                            )}
                          </NeoGrid.Grid>
                        </NeoGrid.ChildRow>
                      </NeoGrid.RowGroup>
                    )}
                  </NeoGrid.Grid>
                </Neo.Pager>

              </Neo.Tab>

              <Neo.Tab header={"Question Ordering"} icon='fa-info'>
                {viewModel.selectedAssessment.selfAssessmentId > 0 &&
                  <div>
                    <Card>
                      <CardHeader icon>
                        <CardIcon color="success">
                          <i className={`fa fa-book fa-2x`}></i>
                        </CardIcon>
                        <h4 className="Card-icon-header-text">Self Assessment Question Ordering</h4>
                      </CardHeader>
                      <CardBody>
                        <QuestionOrderComponent items={viewModel.questionsDragAndDropList} setDNDItems={(items) => { viewModel.setDNDItems(items) }} />

                        <Neo.Button onClick={() => viewModel.saveQuestionOrderChanges()} isOutline className="mt-2 mr-2" icon="fa-save" variant="info">
                          Save Order Changes
                        </Neo.Button>
                        <Neo.Button onClick={() => viewModel.addNewQuestion()} disabled={viewModel.selectedAssessmentId === 0} className="mt-2 mr-2"
                          icon="fa-plus" variant="success" isOutline hidden={viewModel.selectedAssessment.selfAssessmentId === 0 && viewModel.selectedAssessment.moderationState !== ModerationState.Review}>
                          Add Question
                        </Neo.Button>

                      </CardBody>
                    </Card>
                  </div>
                }

              </Neo.Tab>
            </Neo.TabContainer>

          </CardBody>
        </Card>

        {/* Edit Assessment Card */}
        <Card hidden={viewModel.hideAssessmentCard}>
          <CardHeader icon>
            <CardIcon color="success">
              <i className={`fas fa-plus fa-2x`}></i>
            </CardIcon>
            <h4 className="Card-icon-header-text">{"Self Assessment: " + viewModel.selectedAssessment.selfAssessmentTitle}</h4>
          </CardHeader>
          <CardBody>
            <Neo.Form validationDisplayMode={ValidationDisplayMode.Always}>
              <GridContainer>
                <GridItem md={7}>
                  <Neo.FormGroupFloating disabled={viewModel.disableEdit} label="Self Assessment Title*" formNoValidate input={{ rows: 1 }} bind={viewModel.selectedAssessment.meta.selfAssessmentTitle} /><br />
                </GridItem>
                <GridItem md={7}>
                  <Neo.FormGroupFloating disabled={viewModel.disableEdit} label="Self Assessment Description*" formNoValidate input={{ rows: 2 }} bind={viewModel.selectedAssessment.meta.selfAssessmentDescription} />
                </GridItem>
                <GridItem md={6}>
                  <Neo.FormGroupFloating disabled={viewModel.disableEdit} label="Question Type*" bind={this.viewModel.selectedAssessment.meta.questionTypeId}
                    select={{
                      itemSource: Data.StaticDataSource.fromEnum(QuestionType),
                    }} />
                </GridItem>
                <GridItem md={6}  >
                  <Neo.FormGroupFloating disabled={viewModel.disableEdit} hidden={viewModel.selectedAssessment.questionTypeId !== QuestionType.LikertScale as number} label="Sentiment*" bind={viewModel.selectedAssessment.meta.sentiment}
                    select={{
                      itemSource: Data.StaticDataSource.fromEnum(Sentiment),
                    }} />
                </GridItem>
                <GridItem md={6} >
                  <Neo.FormGroupFloating disabled={viewModel.disableEdit} hidden={viewModel.selectedAssessment.questionTypeId !== QuestionType.LikertScale as number} readOnly={true} label="Number Of Scales" formNoValidate bind={viewModel.selectedAssessment.meta.numberOfScales} />
                </GridItem>
              </GridContainer>
            </Neo.Form>

            <Neo.Button
              hidden={!this.viewModel.hideEditTagsCard}
              disabled={(viewModel.selectedAssessment.questionTypeId === QuestionType.LikertScale
                && viewModel.selectedAssessment.sentiment === Sentiment.NotSet) || viewModel.disableEdit}
              variant="success"
              isOutline
              icon='plus'
              onClick={() => this.viewModel.showTagCards()}
              text='Add Self Assessment Tags' />
          </CardBody>
        </Card>

        {/* Self Assessment Tags Create and Info */}
        <div>
          <Card hidden={this.viewModel.hideEditTagsCard}>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`fas fa-tags fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">Self Assessment Tags</h4>
            </CardHeader>
            <CardBody>
              <Neo.Form validationDisplayMode={ValidationDisplayMode.Always}>
                <NeoGrid.Grid className="mb-10" items={this.viewModel.selectedAssessment.selfAssessmentTags2} initialSort={"tag2Id"}
                  showAddButton addButton={{ disabled: this.viewModel.selectedAssessment.moderationState === ModerationState.Moderated }}>
                  {(assessment, assessmentMeta) => (
                    <NeoGrid.Row>
                      <NeoGrid.Column
                        autoFocus
                        size={10}
                        disabled={!assessment.isNew && this.viewModel.selectedAssessment.selfAssessmentId !== 0}
                        label="Category Tag">
                        <Neo.AutoCompleteDropDown
                          bind={assessmentMeta.tag1Id}
                          bindDisplay={assessmentMeta.tag1Name}
                          displayMember="tagName"
                          itemSource={this.viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                          onItemSelected={(item, values) => { this.viewModel.filterTag2List(assessment, item?.tag1Id) }}
                          loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                          disabled={(!assessment.isNew && viewModel.selectedAssessment.selfAssessmentId !== 0) || viewModel.disableEdit}
                          minCharacters={2}
                        />
                      </NeoGrid.Column>
                      <NeoGrid.Column
                        width={800} size={10}
                        disabled={(!assessment.isNew && this.viewModel.selectedAssessment.selfAssessmentId !== 0) || viewModel.disableEdit}
                        hidden={assessment.tag1Id === 0}
                        label="Sub-Category Tag" bind={assessmentMeta.tag2Id} autoFocus={false}
                        select={{ items: assessment.filteredTag2List, onItemSelected: (item) => { viewModel.checkForDuplicateTag2s(item?.tag2Id as number) } }} />
                      <NeoGrid.Column hidden={assessment.tag1Id !== 0}>
                        <span>Select a Tag 1</span>
                      </NeoGrid.Column>
                      <NeoGrid.ButtonColumn>
                        <Neo.Button icon="trash" disabled={viewModel.disableEdit} variant="danger" onClick={() => viewModel.deleteSelfAssessmentTag2(assessment)} />
                      </NeoGrid.ButtonColumn>
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
                <div hidden={this.viewModel.selectedAssessment.moderationState === ModerationState.Moderated && !this.viewModel.canChangeModeratedAssessment}>
                  <Neo.Button onClick={() => this.viewModel.saveSelfAssessment()}
                    hidden={viewModel.selectedAssessment.selfAssessmentId !== 0 || viewModel.disableEdit}
                    disabled={!viewModel.selectedAssessment.isValid} isOutline className="mt-2 mr-2" icon="fa-save" variant="success">
                    Save Self Assessment
                  </Neo.Button>
                  <Neo.Button onClick={() => this.viewModel.saveSelfAssessment()} disabled={!viewModel.selectedAssessment.isDirty}
                    className="mt-2 mr-2" icon="fa-plus"
                    variant="success" isOutline
                    hidden={viewModel.selectedAssessment.selfAssessmentId === 0 && viewModel.selectedAssessment.moderationState !== ModerationState.Moderated}>
                    Update Self Assessment
                  </Neo.Button>
                  <Neo.Button onClick={() => this.viewModel.addNewQuestion()} disabled={this.viewModel.selectedAssessmentId === 0}
                    className="mt-2 mr-2" icon="fa-plus" variant="success" isOutline hidden={viewModel.selectedAssessment.selfAssessmentId === 0}>
                    Add Question
                  </Neo.Button>
                  {
                    viewModel.selectedAssessment.moderationState !== ModerationState.Review ||
                    <Neo.Card className="moderation-notes" hidden={viewModel.selectedAssessment.selfAssessmentModerationNotes.length === 0}>
                      <RichTextEditor bind={viewModel.selectedAssessment.meta.selfAssessmentModerationNotes}
                        labelText="Moderation Notes" readOnly={true} />
                    </Neo.Card>
                  }
                </div>
              </Neo.Form>
            </CardBody>
          </Card>
        </div>

        {viewModel.hideQuestionComponent ||
          <AssessmentQuestion hidden={viewModel.hideQuestionComponent}
            assessmentId={viewModel.selectedAssessmentId}
            questionId={viewModel.selectedQuestionId}
            saveQuestion={(question) => viewModel.saveQuestion(question)}
            disabled={viewModel.disableEdit} />
        }

        <ForumDrawer
          show={viewModel.showReviewSummary}
          onClose={() => viewModel.showReviewSummary = false}
          isMaximised={viewModel.reviewDrawerIsMax}
          maximiseWindow={() => viewModel.reviewDrawerIsMax = !viewModel.reviewDrawerIsMax}>

          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <i className={`fa fa-info fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">Review Summary for: {this.viewModel.assessmentModerationSummary.title}</h4>
            </CardHeader>
            <CardBody>
              <Card hidden={this.viewModel.assessmentModerationSummary.baseAssessmentApproved}>
                <CardHeader icon>
                  <CardIcon color="danger">
                    <i className={`fa fa-clipboard fa-2x`}></i>
                  </CardIcon>
                  <h4 className="Card-icon-header-text"> {this.viewModel.assessmentModerationSummary.title}</h4>
                </CardHeader>
                <CardBody>
                  <p className="card-category-white">
                    The Following properties are not approved on this assessment:
                  </p>
                  <ul>
                    {this.viewModel.assessmentModerationSummary.titleApproved || <li style={{ fontSize: "15px" }}>Title</li>}
                    {this.viewModel.assessmentModerationSummary.descriptionApproved || <li style={{ fontSize: "15px" }}>Description</li>}
                  </ul>
                  <div hidden={this.viewModel.assessmentModerationSummary.moduleNotes.length === 0}>
                    <RichTextEditor bind={this.viewModel.assessmentModerationSummary.meta.moduleNotes} readOnly={true} labelText="Assessment notes" />
                  </div>
                </CardBody>
              </Card>

              <Card hidden={this.viewModel.assessmentModerationSummary.tagsApproved}>
                <CardHeader icon>
                  <CardIcon color="danger">
                    <i className={`fa fa-tags fa-2x`}></i>
                  </CardIcon>
                  <h4 className="Card-icon-header-text">Review Assessment Tags</h4>
                </CardHeader>
                <CardBody>
                  <p className="card-category-white">
                    The Following tags are not approved on your assessment:
                  </p>
                  <ul>
                    {
                      this.viewModel.assessmentModerationSummary.assessmentTags.map((tag) => (
                        <li key={tag.tag1Id} style={{ fontSize: "15px" }} hidden={tag.approved}>{tag.errorMessage} </li>
                      ))
                    }
                  </ul>
                </CardBody>
              </Card>

              <Card hidden={this.viewModel.assessmentModerationSummary.assessmentQuestionsApproved}>
                <CardHeader icon>
                  <CardIcon color="danger">
                    <i className={`fa fa-question fa-2x`}></i>
                  </CardIcon>
                  <h4 className="Card-icon-header-text">Review Assessment Questions</h4>
                </CardHeader>
                <CardBody>
                  <p className="card-category-white">
                    The following questions are not approved:
                  </p>

                  {this.viewModel.assessmentModerationSummary.assessmentQuestions.map((question) => (
                    <Card hidden={question.assessmentQuestionApproved} key={question.question}>
                      <CardHeader >
                        <CardIcon color="info">
                          <i className={`fa fa-eye fa-2x`} />
                        </CardIcon>
                        <RichTextEditor bind={question.meta.question} readOnly labelText="Question" />
                      </CardHeader>
                      <CardBody>
                        <p className="card-category-white">
                          The Following properties are not approved on this question:
                        </p>
                        <ul>
                          {question.questionApproved || <li style={{ fontSize: "15px" }}>Question Text</li>}
                          {question.tag3Approved || <li style={{ fontSize: "15px" }}>Tag 3</li>}
                          {question.optionsApproved || <li style={{ fontSize: "15px" }}>Question Options</li>}
                        </ul>
                        <div hidden={this.viewModel.assessmentModerationSummary.moduleNotes.length === 0}>
                          <RichTextEditor bind={this.viewModel.assessmentModerationSummary.meta.moduleNotes} readOnly={true} labelText="Question notes" />
                        </div>
                      </CardBody>

                      <Card hidden={question.questionContentApproved}>
                        <CardHeader >
                          <CardIcon color="info">
                            <i className={`fa fa-video fa-2x`} />
                          </CardIcon>
                          <CardBody>
                            <p className="card-category-white">
                              The Content on this question has not been approved:
                            </p>
                            <p>Content Title : {question.questionContent.contentTitle}</p>
                          </CardBody>
                        </CardHeader>
                      </Card>
                    </Card>
                  ))}

                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </ForumDrawer>
      </div >
    );
  }
}