import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import OptionLookup from '../Lookups/OptionLookup';
import { QuestionType } from '../Questions/QuestionType.enum';
import ContentConsumptionLookup from './ContentConsumptionLookup';

@NeoModel
export default class QuizQuestionConsumptionLookup extends ModelBase {

    public learningObjectId: number = 0;

    public questionType: QuestionType = QuestionType.NotSet;

    public questionText: string = "";

    public questionTextHtml: string = "";

    public options: string = "";

    @Attributes.Integer()
    public correctAnswer: number = -1;

    public correctAnswers: string = "";

    @Attributes.Nullable()
    public contentId: number | null = null;

    @Attributes.NoTracking()
    public contentList = new List(ContentConsumptionLookup);

    @Attributes.Nullable()
    public linkedInformationObjectId: number | null = null;
    // Client only properties / methods

    public optionLookupList: List<OptionLookup> = new List(OptionLookup);

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.options) {
            return "New quiz question consumption lookup";
        } else {
            return this.options;
        }
    }
}