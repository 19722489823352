import { List, ModalUtils, NeoModel } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { injectable } from "inversify";
import { observer } from "mobx-react";
import React from "react";
import { AppService, Types } from "../../App/Services/AppService";
import Race from "../Models/Educators/Race";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration"
import DeleteRaceCommand from '../Models/Commands/DeleteRaceCommand';
import UpdateRaceCommand from '../Models/Commands/UpdateRaceCommand';
import Card from "../../Template/components/Card/Card";
import CardHeader from "../../Template/components/Card/CardHeader";
import CardIcon from "../../Template/components/Card/CardIcon";
import CardBody from "../../Template/components/Card/CardBody";

interface IRaceComponentProps {
    viewModel: RaceComponentVM;
}

@injectable()
@NeoModel
export class RaceComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private raceApiClient = AppService.get(Types.Identity.ApiClients.RaceCommandApiClient),
        private raceQueryApiClient = AppService.get(Types.Identity.ApiClients.RaceQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    //Properties
    public raceList = new List(Race);
    public RaceCommands = new List(UpdateRaceCommand);
    public enableEdit: boolean = false;

    //Methods
    public async initialise() {
        this.LoadRaceList();
    }

    public async LoadRaceList() {
        const getRaceListResponse = await this.taskRunner.waitFor(this.raceQueryApiClient.GetRaceList());
        if (getRaceListResponse.data !== null) {
            this.raceList.set(getRaceListResponse.data);
        }
    }

    private markSelectedItems(list: Array<Race>) {
    }

    public async saveRaces() {
        this.taskRunner.run(async () => {
            this.RaceCommands.set([]);
            this.raceList.filter(function (f) { return f.isNew || f.isDirty }).forEach(item => {
                var raceCommand = new UpdateRaceCommand();
                raceCommand.raceId = item.raceId;
                raceCommand.raceName = item.raceName;
                this.RaceCommands.push(raceCommand);
            });

            await this.raceApiClient.saveList(this.RaceCommands.toJSArray());
            this.LoadRaceList();
            this.notifications.addSuccess("Races saved", null, NotificationDuration.Short);
        })
        this.enableEdit = false;
    }

    public async deleteRacePopUp(raceName: string, raceId: number) {
        const response = await this.taskRunner.waitFor(this.raceQueryApiClient.checkRaceLinks(raceId));
        if (response.data) {
            ModalUtils.showMessage("Cannot Delete " + raceName, "This race has SACE Eductor(s) linked to it and cannot be deleted", 3)
        }
        else {
            await ModalUtils.showYesNoDismissible("Delete Race " + raceName,
                "Are you sure you want to delete this Race? ", () => this.delete(raceId))
        }
    }

    public async delete(raceId: number) {
        this.taskRunner.run(async () => {
            var raceCommand = new DeleteRaceCommand();
            raceCommand.raceId = raceId;
            await this.raceApiClient.delete(raceCommand.toJSObject());
            this.LoadRaceList();
            this.notifications.addSuccess("Race Deleted", null, NotificationDuration.Short);
        })
    }

}

@observer
export default class RaceView extends React.Component<IRaceComponentProps>{

    public render() {
        const viewModel = this.props.viewModel;
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fa fa-user-circle fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Races</h4>
                            </CardHeader>
                            <CardBody>
                                <NeoGrid.Grid items={viewModel.raceList} initialSortAscending={false} initialSort={"raceId"} showAddButton={!viewModel.enableEdit}>
                                    {(race, raceMeta) => (
                                        <NeoGrid.Row>
                                            <NeoGrid.Column label="Name" bind={raceMeta.raceName} />
                                            <NeoGrid.ButtonColumn>
                                                <Neo.Button icon="trash" variant="danger" onClick={() => viewModel.deleteRacePopUp(race.raceName, race.raceId)} />
                                                {/* <Neo.Button disabled={!viewModel.enableEdit} hidden={province.provinceId === 0} icon="trash" variant="danger" onClick={() => viewModel.deleteProvincePopUp(province.provinceName, province.provinceId)} /> */}
                                            </NeoGrid.ButtonColumn>
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>

                                {viewModel.raceList.length === 0 &&
                                    <div className="col-md-12 p-0 text-center">
                                        <p>No Records</p>
                                    </div>}

                                <Neo.Form model={viewModel.raceList} onSubmit={() => viewModel.saveRaces()} showSummaryModal>
                                    <div className="my-4 raceSave">
                                        <Neo.Button disabled={!viewModel.raceList.isDirty} isSubmit variant="warning" icon="check">Save</Neo.Button>
                                    </div>
                                </Neo.Form>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
        )
    }
}