import React from "react";
import { observer } from 'mobx-react';
import PLCModuleConversationVM from "./PLCModuleConversationVM";
import PostManagerVM from "../PostManagerVm";
import Card from "../../../../Template/components/Card/Card";
import CardBody from "../../../../Template/components/Card/CardBody";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import TopicLookup from "../../../Models/Lookups/TopicLookup";
import GridContainer from "../../../../Template/components/Grid/GridContainer";
import GridItem from "../../../../Template/components/Grid/GridItem";
import CardHeader from "../../../../Template/components/Card/CardHeader";
import CardIcon from "../../../../Template/components/Card/CardIcon";
import { EnumHelper } from "@singularsystems/neo-core";
import { ForumIntegrationTypes } from "../../../Models/Enums/ForumLearningTypes.enum";
import { Tooltip } from "@material-ui/core";
import PostLookup from "../../../Models/Lookups/PostLookup";
import RichTextEditor from "../../../../Learning/Components/RichTextEditor";
import ReportModalView from "../ReportModalView";

interface IPLCModuleConversationProps {
  learningPathwayId: number;
  learningModuleId: number;
  forumId: number;
  hidden?: boolean
}

@observer
export default class PLCModuleConversation extends React.Component<IPLCModuleConversationProps> {
  private viewModel = new PLCModuleConversationVM();
  private postManagerVM = new PostManagerVM();

  constructor(props: IPLCModuleConversationProps) {
    super(props);
    this.postManagerVM = new PostManagerVM();
    this.viewModel = new PLCModuleConversationVM();
    this.viewModel.GetPathwayById(props.learningPathwayId);
    this.viewModel.GetModuleById(props.learningModuleId);
    this.viewModel.forumId = props.forumId;
    this.viewModel.topicCriteria.learningPathwayId = props.learningPathwayId;
    this.viewModel.topicCriteria.learningModuleId = props.learningModuleId;
    this.viewModel.PLCModuleTopicPager.refreshData();
  }

  public ViewConversation(topic: TopicLookup) {
    this.viewModel.selectedTopic.set(topic.toJSObject());
    this.viewModel.showConversation = true;
    this.postManagerVM.postCriteria.topicId = topic.topicId;
    this.postManagerVM.postPageManager.refreshData();
  }

  public render() {
    const viewModel = this.viewModel;
    const postManagerVM = this.postManagerVM;
    return (
      <div hidden={this.props.hidden}>
        <Card hidden={viewModel.showConversation}>
          <CardHeader>
            <CardIcon color="primary">
              <i className={`icon fas fa-comments fa-2x`}></i>
            </CardIcon>
            <h4 className="Card-icon-header-text"> PLC Module Topics</h4>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <p>Related To Learning Pathway: {viewModel.pathwayTitle}</p>
                <p>Related To Module: {viewModel.moduleTitle}</p>
                <p>Scope: Private Module</p>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Neo.Pager
              pageManager={this.viewModel.PLCModuleTopicPager}
              pageControlProps={{
                firstText: "",
                prevText: "Prev",
                nextText: "Next",
                lastText: "",
                alignment: "center",
                autoHide: false,
                pageSizeLabel: "Show"
              }}>
              {topic => (
                <NeoGrid.Grid<TopicLookup> className="table-hover" keyProperty="postId" showAddButton>
                  {(topic: TopicLookup, topicMeta) => (
                    <NeoGrid.Row key={topic.topicId} >
                      <NeoGrid.Column bind={topicMeta.topicName} readOnly={!topic.isNew} />
                      <NeoGrid.Column display={topicMeta.pathwayName} readOnly={!topic.isNew} />
                      <NeoGrid.Column display={topicMeta.moduleName} readOnly={!topic.isNew} />
                      <NeoGrid.Column label="Scope" bind={topicMeta.forumIntegrationTypes} select={{ items: EnumHelper.asList(ForumIntegrationTypes), renderAsText: true }} isReadOnly={true} />
                      <NeoGrid.Column label="Last Post" display={topicMeta.timeAgo} />
                      <NeoGrid.ButtonColumn >
                        <Neo.Button hidden={topic.isNew} variant="info" icon="eye" tooltip={{ content: "View Topic", className: "tool-tip" }} onClick={() => { this.ViewConversation(topic) }} />
                        <Neo.Button icon="save" hidden={!topic.isNew} onClick={() => this.viewModel.CreateTopic(topic)}>Save</Neo.Button>
                      </NeoGrid.ButtonColumn>
                      <Tooltip title="Topics with posts can't be deleted">
                        <NeoGrid.ButtonColumn showDelete hidden={topic.isNew} deleteButton={{ disabled: topic.createdOn !== null, onClick: () => viewModel.DeleteTopic(topic) }} />
                      </Tooltip>
                      <NeoGrid.ButtonColumn showDelete hidden={!topic.isNew} />
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
              )}
            </Neo.Pager>
          </CardBody>
        </Card >
        {viewModel.showConversation &&
          <Card>
            <CardHeader>
              <CardIcon color="primary">
                <i className={`icon fas fa-comments fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">{viewModel.selectedTopic.topicName} Conversations</h4>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <Neo.Button icon="backward" isOutline text="Back to topics" variant="warning" onClick={() => { viewModel.backToTopics() }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <p>Related To Learning Pathway: {viewModel.pathwayTitle}</p>
                  <p>Related To Module: {viewModel.moduleTitle}</p>
                  <p>Scope: Private Module</p>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {postManagerVM.postPageManager.data.length === 0 ||
                <Neo.Pager
                  pageManager={postManagerVM.postPageManager}
                  pageControlProps={{
                    firstText: "",
                    prevText: "Prev",
                    nextText: "Next",
                    lastText: "",
                    alignment: "center",
                    autoHide: false,
                    pageSizeLabel: "Show"
                  }}>
                  {post => (
                    <NeoGrid.Grid<PostLookup> className="table-hover" keyProperty="postId">
                      {(post: PostLookup, postMeta) => (
                        <NeoGrid.Row key={post.postId}>
                          <NeoGrid.Column style={{ border: "none" }}>
                            <div className="row">
                              <div style={{ width: "40px" }}>
                                <div className="custom-avatar" style={{ background: postManagerVM.setUserColor(post.createdByGuid), width: "40px" }}>
                                  <span >{post.postedBy[0]}</span>
                                </div>
                              </div>
                              <div className="col-md-11">
                                <span style={{ color: "#656c7a", fontWeight: 700 }}>{post.postedBy} </span>
                                <span style={{ fontWeight: 300, color: "grey" }}>- {post.timeAgo}</span>
                                {post.body.length === 0 ||
                                  <div>
                                    <RichTextEditor key={post.postId} bind={postMeta.body} readOnly={postManagerVM.disableEdit || postManagerVM.selectedPost?.postId !== post.postId} />
                                    <Neo.Button onClick={() => postManagerVM.updatePost(post)} text="Update" icon="edit" variant="success" isOutline hidden={postManagerVM.disableEdit || postManagerVM.selectedPost?.postId !== post.postId} />
                                    <Neo.Button onClick={() => { postManagerVM.loadPosts(viewModel.selectedTopic.topicId); postManagerVM.disableEdit = true }} text="Cancel" icon="times" variant="danger" isOutline hidden={postManagerVM.disableEdit || postManagerVM.selectedPost?.postId !== post.postId} />
                                  </div>}
                                <div>
                                  <span onClick={() => { postManagerVM.selectForEdit(post) }} hidden={postManagerVM.currentUser.userGuid !== post.createdByGuid} className="reply-btn">{"Edit |"} </span>
                                  <span onClick={() => { postManagerVM.viewReplies(post, false) }} className="reply-btn">{!post.hasChildren ? "" : "View Messages |"} </span>
                                  <span hidden={post.isReported} onClick={() => postManagerVM.viewReplies(post, true)} className="reply-btn"> Reply | </span>
                                  <span hidden={post.isReported} onClick={() => postManagerVM.reportPost(post)} className="report-btn"> Report </span>
                                  <Tooltip hidden={post.isReported === false} id="tooltip-top" title="Post under review" placement="top"><div className="fas fa-flag report-flag" /></Tooltip>
                                  <span hidden={post.hasChildren || postManagerVM.currentUser.userGuid !== post.createdByGuid} onClick={() => postManagerVM.showDeleteModal(post)} className="report-btn">| Delete</span>
                                </div>
                              </div>
                              <div key={post.postId + 2} className="col-md-12" hidden={postManagerVM.hideReply}>
                                {
                                  postManagerVM.selectedPost !== post ||
                                  <div className="row" style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }} >
                                    <div className="col-md-12">
                                      <RichTextEditor bind={postManagerVM.replyBody.meta.body} />
                                      <Neo.Button variant="success" icon="plus" isOutline onClick={() => postManagerVM.sendReply(postManagerVM.replyBody.meta.body.value, viewModel.selectedTopic.topicId)}>Send</Neo.Button>
                                    </div>
                                  </div>
                                }
                              </div>
                              <div key={post.postId + 1} className="col-md-12" hidden={postManagerVM.hideDeletePost}>
                                {
                                  postManagerVM.selectedPost !== post ||
                                  <div style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }} >
                                    <p>Please give a reason for deleting this post</p>
                                    <GridItem md={12}>
                                      <Neo.FormGroupFloating label="Reason" bind={postManagerVM.selectedPost?.meta.deletedReason} input={{ rows: 3 }} />
                                    </GridItem>
                                    <GridItem md={12}>
                                      <Neo.Button variant="danger" icon="trash" isOutline onClick={() => postManagerVM.delete(viewModel.selectedTopic.topicId)}>Delete</Neo.Button>
                                      <Neo.Button variant="warning" icon="times" isOutline onClick={() => postManagerVM.cancelDelete()}>Cancel</Neo.Button>
                                    </GridItem>
                                  </div>
                                }
                              </div>
                              <div key={post.postId} className="col-md-12" hidden={postManagerVM.HideMessages}>
                                {
                                  postManagerVM.selectedPost !== post ||
                                  postManagerVM.postReplies.map((reply, index) => (
                                    <div key={reply.postId}>
                                      <div className="row" style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }}>
                                        <div>
                                          <div className="custom-avatar" style={{ background: postManagerVM.setUserColor(reply.createdByGuid), width: "40px" }}>
                                            <span >{reply.postedBy[0]}</span>
                                          </div>
                                        </div>
                                        <div className="col-md-11">
                                          <span style={{ color: "#656c7a", fontWeight: 700 }}>{reply.postedBy} </span>
                                          <span style={{ fontWeight: 300, color: "grey" }}>- {post.timeAgo}</span>
                                          {reply.body.length === 0 ||

                                            <RichTextEditor bind={reply.meta.body} readOnly />
                                          }
                                          <div>
                                            {/* <span onClick={() => { this.viewModel.selectForEdit(reply) }} hidden={this.viewModel.currentUser.userGuid === reply.createdByGuid} className="reply-btn">{"Edit |"} </span> */}
                                            <span onClick={() => { postManagerVM.viewChildReplies(reply) }} className="reply-btn">{"Reply |"} </span>
                                            <span hidden={reply.isReported} onClick={() => postManagerVM.reportPost(reply)} className="report-btn"> Report</span>
                                            <Tooltip style={{ marginLeft: "5px" }} hidden={reply.isReported === false} id="tooltip-top" title="Post under review" placement="top"><div className="fas fa-flag report-flag" /></Tooltip>
                                            <span hidden={postManagerVM.currentUser.userGuid !== reply.createdByGuid} onClick={() => postManagerVM.showReplyDeleteModal(reply)} className="report-btn">| Delete</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div key={reply.postId + 1} className="col-md-12" hidden={postManagerVM.hideChildReply}>
                                        {
                                          postManagerVM.selectedChildPost !== reply ||
                                          <div className="row" style={{ marginLeft: "60px", marginTop: "10px", marginBottom: "10px" }} >
                                            <div className="col-md-12">
                                              <RichTextEditor bind={postManagerVM.childReplyBody.meta.body} />
                                              <Neo.Button variant="success" icon="plus" isOutline onClick={() => postManagerVM.sendChildReply(postManagerVM.childReplyBody.meta.body.value, viewModel.selectedTopic.topicId)}>Send</Neo.Button>
                                            </div>
                                          </div>
                                        }
                                      </div>
                                      <div key={post.postId + 1} className="col-md-12" hidden={postManagerVM.hideReplyDeletePost}>
                                        {
                                          postManagerVM.selectedPost !== post ||
                                          <div style={{ marginLeft: "50px", marginTop: "10px", marginBottom: "10px" }} >
                                            <p>Please give a reason for deleting this post</p>
                                            <GridItem md={12}>
                                              <Neo.FormGroupFloating label="Reason" bind={postManagerVM.selectedChildPost?.meta.deletedReason} input={{ rows: 3 }} />
                                            </GridItem>
                                            <GridItem md={12}>
                                              <Neo.Button variant="danger" icon="trash" isOutline onClick={() => postManagerVM.deleteReply(viewModel.selectedTopic.topicId)}>Delete</Neo.Button>
                                              <Neo.Button variant="warning" icon="times" isOutline onClick={() => postManagerVM.cancelDelete()}>Cancel</Neo.Button>
                                            </GridItem>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </NeoGrid.Column>
                        </NeoGrid.Row>
                      )}
                    </NeoGrid.Grid>
                  )}
                </Neo.Pager>
              }
              <div className="row">
                <div className="col">
                  <RichTextEditor bind={postManagerVM.postBody.meta.body} labelText="Start a New Topic" />
                </div>
              </div>
              <div className="row">
                <div className="col align-self-end mb-2">
                  <Neo.Button variant="success" icon="plus" isOutline onClick={() => postManagerVM.sendMessage(postManagerVM.postBody.meta.body.value, viewModel.selectedTopic.topicId)}>Send</Neo.Button>
                  <Neo.Button variant="warning" icon="sync-alt" isOutline onClick={() => postManagerVM.loadPosts(postManagerVM.postCriteria.topicId)}>Refresh</Neo.Button>
                </div>
              </div>
            </CardBody>
          </Card>
        }
        <ReportModalView viewModel={postManagerVM.reportModalVM} />
        <Neo.Modal show={viewModel.showConfirmDelete} title="Confirm Delete"
          onClose={() => viewModel.showConfirmDelete = false}
          acceptButton={{ icon: "trash", variant: "danger", onClick: () => viewModel.ConfirmDeleteTopic() }}>
          <p>Please Confirm Delete.</p>
        </Neo.Modal>
      </div>
    )
  }

}