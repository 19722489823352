import React from 'react';
import { Views } from '@singularsystems/neo-react';
import LearningPathwayVM from './LearningPathwayVM';
import { observer } from 'mobx-react';
import LearningPathwayPager from "../../Components/LearningPathwayComponents/LearningPathwayPager"
import PathwayModuleConsumptionView from '../ContentConsumption/PathwayModuleConsumptionView';
import ModuleConsumptionView from '../ContentConsumption/ModuleConsumptionView';
import { OpenLink } from '../../../App/HelperClasses/GlobalHelpers';

class LearningPathwayViewParams {
  public pathwayId = { required: false, isQuery: true };
}

@observer
export default class LearningPathwayView extends Views.ViewBase<LearningPathwayVM, LearningPathwayViewParams> {

  constructor(props: unknown) {
    super("Learning Pathway", LearningPathwayVM, props);
  }

  public static params = new LearningPathwayViewParams();

  protected async viewParamsUpdated() {
    this.viewModel.pathwayId = this.viewParams.pathwayId.value as number;
    if (this.viewParams.pathwayId.value !== null || this.viewParams.pathwayId.value !== undefined) {
      await this.viewModel.learningPagerVm.SetSelectedLearningPathway(this.viewParams.pathwayId.value as number, null);
    }
  }

  private GoToModuleView = (id: number, preview: boolean = false) => {
    if (!preview) {
      this.navigation.navigateToView(PathwayModuleConsumptionView, { moduleId: id, pathwayId: this.viewModel.learningPagerVm.selectedLearningPathwayId });
    } else {
      var path = this.navigation.getPathForView(ModuleConsumptionView, { moduleId: id, preview: 1 });
      OpenLink(path);
    }
  }

  public render() {
    return (
      <div>
        <LearningPathwayPager viewModel={this.viewModel.learningPagerVm} GoToModule={(id, preview) => { this.GoToModuleView(id, preview) }} />
      </div>
    );
  }
}