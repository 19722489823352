import AppTypes from '../App/AppTypes';
import { AppService } from '../App/Services/AppService';

import { LearningExportedTypes } from './LearningExportedTypes';

const Types = {
    ...AppTypes,
    Learning: {
        ApiClients: {
            ...LearningExportedTypes.ApiClients
        },
        Services: {
            ...LearningExportedTypes.Services,
        }
    }
}

export { AppService, Types }