import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UserModuleCriteria extends ModelBase {

    public userModuleCriteriaID: number = 0;

    public keyWord: string = "";

    public tag1Id: number = 0;

    public tag2Id: number = 0;

    public tag3Id: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "User Module Criteria";
    }
}