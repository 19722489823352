import { NeoModel, NotifyUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService } from '../CommonIdentityTypes';
import { Types } from '../IdentityTypes';
import ChangePasswordModel from '../Models/Password/ChangePasswordModel';
import ChangePasswordLookup from '../Models/Lookups/ChangePasswordLookup';
import { NotificationDuration } from '../../App/Models/Enums/NotificationDuration.enum';

@NeoModel
export default class ChangePasswordVM extends Views.ViewModelBase {

    public passwordModel: ChangePasswordModel = new ChangePasswordModel();
    private changePasswordLookup: ChangePasswordLookup = new ChangePasswordLookup();

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private userApiClient = AppService.get(Types.Identity.ApiClients.UserProfileApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);
    }

    public async initialise() {

    }

    public async changePassword() {
        this.taskRunner.run(async () => {
            this.changePasswordLookup = new ChangePasswordLookup();
            this.changePasswordLookup.newPassword = this.passwordModel.newPassword;
            this.changePasswordLookup.currentPassword = this.passwordModel.oldPassword;
            await this.userApiClient.changePasswordAsync(this.changePasswordLookup.toJSObject())
            this.passwordModel = new ChangePasswordModel();
            NotifyUtils.addSuccess("Password Changed", `Password Changed Successfully!`, NotificationDuration.Standard);
        });
    }
}