import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class UserLookupCriteria extends ModelBase {

    public SearchText: string = "";    

    public toString() {
        if (this.isNew || !this.SearchText) {
            return "New user lookup criteria"
        } else {
            return this.SearchText;
        }
    }
}