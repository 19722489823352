import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import TopicLookup from '../../../Forum/Models/Lookups/TopicLookup';
import TopicSearchCriteria from '../../../Forum/Models/Lookups/TopicSearchCriteria';
import LearningModuleSearchCriteria from '../../Models/SearchCriteria/LearningModuleSearchCriteria';
import LearningModuleLookup from '../../../Learning/Models/Lookups/LearningModuleLookup';
import SelfAssessmentLookup from '../../../Learning/Models/Lookups/SelfAssessmentLookup';
import { AppService, Types } from '../../DashboardTypes';
import LearningPathwayLookup from '../../Models/LearningPathwayLookup';
import LearningPathwaySearchCriteria from '../../Models/SearchCriteria/LearningPathwaySearchCriteria';
import SelfAssessmentSearchCriteria from '../../Models/SearchCriteria/SelfAssessmentSearchCriteria';
import UserDashboardSetting from '../../Models/UserDashboardSetting';
import DashboardBadgeLookup from '../../Models/DashboardBadgeLookup';
import BadgeSearchCriteria from '../../../Learning/Models/Criteria/BadgeSearchCriteria';
import UserAssessmentLookup from '../../Models/UserAssessmentLookup';
import UserAssessmentReviewLookup from '../../Models/UserAssessmentReviewLookup';
import ModuleSuggestionLookup from '../../Models/ModuleSuggestionLookup';
import LearningPathway from '../../../Learning/Models/LearningPathways/LearningPathway';
import UserReminder from '../../../Learning/Models/Reminders/UserReminder';

export interface IDashboardQueryApiClient {
    /**
     * Get Paged Learning Pathways.
     * @param request request.
     * @returns Paged result.
     */
    getUserPathways(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningPathwaySearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningPathwayLookup>>>;

    /**
     * Get User Activity.
     * @param request request.
     * @returns LearningModuleLookup.
     */
    getUserActivity(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>>;

    /**
     * Get The Total User Self assessements.
     * @returns Assessement Count.
     */
    getTotalUserSelfAssessments(): AxiosPromise<number>;

    /**
     * Gets Paged assessments.
     * @param request request.
     * @returns Paged SelfAssessmentLookup.
     */
    getAvailableAssessmentsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SelfAssessmentSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SelfAssessmentLookup>>>;

    /**
     * Updatyye user Dashboard Active setting.
     * @returns Paged SelfAssessmentLookup.
     */
    updateUserLastActiveDate(): AxiosPromise<Model.PlainTrackedObject<UserDashboardSetting>>;

    /**
     * Gets User Dashboard Settings.
     * @returns Paged SelfAssessmentLookup.
     */
    getUserDashboardSetting(): AxiosPromise<Model.PlainTrackedObject<UserDashboardSetting>>;

    /**
     * Gets User Dashboard Settings.
     * @returns Paged SelfAssessmentLookup.
     */
    getNewMessageCount(): AxiosPromise<number>;

    /**
     * Get Paged user Topics.
     * @param request request.
     * @returns TopicLookup.
     */
    getPagedUserActiveTopics(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>>;

    /**
     * Gets the users badge count.
     * @returns badge count.
     */
    getUserBadgeCount(): AxiosPromise<number>;

    /**
     * Get Paged UserBadges.
     * @param request request.
     * @returns DashboardBadgeLookup.
     */
    getUserBadgesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BadgeSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<DashboardBadgeLookup>>>;

    /**
     * Get Paged Available Badges.
     * @param request request.
     * @returns DashboardBadgeLookup.
     */
    getAvailableBadgesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BadgeSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<DashboardBadgeLookup>>>;

    /**
     * Get Paged assessments.
     * @param request request.
     * @returns Paged SelfAssessmentLookup.
     */
    getUserAssessmentsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SelfAssessmentSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserAssessmentLookup>>>;

    /**
     * Start a new Module.
     * @param learningModuleId learningModuleId.
     * @returns boolean.
     */
    startNewModule(learningModuleId: number): AxiosPromise<boolean>;

    /**
     * Get a assessment for review.
     * @param userSelfAssessmentId userSelfAssessmentId.
     * @returns UserAssessmentReviewLookup.
     */
    getUserAssessmentReviewInfo(userSelfAssessmentId: number): AxiosPromise<Model.PlainObject<UserAssessmentReviewLookup>>;

    /** 
     * Get the suggested Modules for a user assessment.
     * @param userSelfAssessmentId userSelfAssessmentId.
     * @returns ModuleSuggestionLookup.
     */
    getSuggestedModulesForUserAssessment(userSelfAssessmentId: number): AxiosPromise<Array<Model.PlainObject<ModuleSuggestionLookup>>>;

    /**
     * Get available badge count.
     * @returns count.
     */
    getAvailableBadgeCount(): AxiosPromise<number>;

    /**
     * Get available assessment count.
     * @returns count.
     */
    getAvailableAssessmentCount(): AxiosPromise<number>;

    /**
     * Get available assessment count.
     * @returns count.
     */
    getParticipatedTopicCount(): AxiosPromise<number>;

    /**
     * Get User badges.
     * @returns DashboardBadgeLookup.
     */
    getUserBadges(): AxiosPromise<Array<Model.PlainObject<DashboardBadgeLookup>>>;

    /**
     * Update Dashboard Settings.
     * @param userDashboardSetting userDashboardSetting.
     * @returns UserDashboardSetting.
     */
    saveUserModuleDashboardCriteria(userDashboardSetting: Model.PartialPlainObject<UserDashboardSetting>): AxiosPromise<Model.PlainTrackedObject<UserDashboardSetting>>;

    /**
     * Save a pathway with a module.
     * @param pathwayName pathwayName
     * @param moduleId moduleId
     * @returns LearningPathway.
     */
    savePathwayForModule(pathwayName: string, moduleId: number): AxiosPromise<Model.PlainTrackedObject<LearningPathway>>;

    /**
     * GetPagedLearningModuleLookup.
     * @param request request.
     * @returns LearningModuleLookup.
     */
    getPagedLearningModuleLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>>;

    /**
     * Manage user reminders for learning pathways and learning modules.
     * @param userReminder request.
     * @returns Reminder set boolean value.
     */
    createUserReminder(userReminder: Model.PartialPlainObject<UserReminder>): AxiosPromise;

    /**
     * Manage user reminders for learning pathways and learning modules.
     * @returns Reminder set boolean value.
     */
    deleteUserReminder(userReminderId: number): AxiosPromise;

    /**
     * Add a module to a pathway.
     * @param pathwayId pathwayId.
     * @param moduleId moduleId.
     * @returns bool.
     */
    addModuleToPathway(pathwayId: number, moduleId: number): AxiosPromise<boolean>;

    /**
     * Gets the users total cptd points.
     * @returns cptd points.
     */
    getUserCptdPoints(): AxiosPromise<number>;

    /**
     * Get user reminder settings.
     * @returns Reminder set boolean value.
     */
    getUserReminderSettings(): AxiosPromise<Model.PlainTrackedObject<UserReminder>>;

    // Client only properties / methods
}

@injectable()
export default class DashboardQueryApiClient extends Data.ApiClientBase implements IDashboardQueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.DashboardApi.ApiPath}/DashboardQuery`);
    }

    public getUserPathways(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningPathwaySearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningPathwayLookup>>> {
        return this.axios.get(`${this.apiPath}/GetUserPathways?${Utils.getQueryString(request)}`);
    }

    public getUserActivity(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>> {
        return this.axios.get(`${this.apiPath}/GetUserActivity?${Utils.getQueryString(request)}`);
    }

    public getTotalUserSelfAssessments(): AxiosPromise<number> {
        return this.axios.get(`${this.apiPath}/GetTotalUserSelfAssessments`);
    }

    public getAvailableAssessmentsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SelfAssessmentSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SelfAssessmentLookup>>> {
        return this.axios.get(`${this.apiPath}/GetAvailableAssessmentsPaged?${Utils.getQueryString(request)}`);
    }

    public updateUserLastActiveDate(): AxiosPromise<Model.PlainTrackedObject<UserDashboardSetting>> {
        return this.axios.get(`${this.apiPath}/UpdateUserLastActiveDate`);
    }

    public getUserDashboardSetting(): AxiosPromise<Model.PlainTrackedObject<UserDashboardSetting>> {
        return this.axios.get(`${this.apiPath}/GetUserDashboardSetting`);
    }

    public getNewMessageCount(): AxiosPromise<number> {
        return this.axios.get(`${this.apiPath}/GetNewMessageCount`);
    }

    public getPagedUserActiveTopics(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TopicSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<TopicLookup>>> {
        return this.axios.get(`${this.apiPath}/GetPagedUserActiveTopics?${Utils.getQueryString(request)}`);
    }

    public getUserBadgeCount(): AxiosPromise<number> {
        return this.axios.get(`${this.apiPath}/GetUserBadgeCount`);
    }

    public getUserBadgesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BadgeSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<DashboardBadgeLookup>>> {
        return this.axios.get(`${this.apiPath}/GetUserBadgesPaged?${Utils.getQueryString(request)}`);
    }

    public getAvailableBadgesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BadgeSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<DashboardBadgeLookup>>> {
        return this.axios.get(`${this.apiPath}/GetAvailableBadgesPaged?${Utils.getQueryString(request)}`);
    }

    public getUserAssessmentsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SelfAssessmentSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<UserAssessmentLookup>>> {
        return this.axios.get(`${this.apiPath}/GetUserAssessmentsPaged?${Utils.getQueryString(request)}`);
    }

    public startNewModule(learningModuleId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/StartNewModule/${learningModuleId}`);
    }

    public getUserAssessmentReviewInfo(userSelfAssessmentId: number): AxiosPromise<Model.PlainObject<UserAssessmentReviewLookup>> {
        return this.axios.get(`${this.apiPath}/GetUserAssessmentReviewInfo/${userSelfAssessmentId}`);
    }

    public getSuggestedModulesForUserAssessment(userSelfAssessmentId: number): AxiosPromise<Array<Model.PlainObject<ModuleSuggestionLookup>>> {
        return this.axios.get(`${this.apiPath}/GetSuggestedModulesForUserAssessment/${userSelfAssessmentId}`);
    }

    public getAvailableBadgeCount(): AxiosPromise<number> {
        return this.axios.get(`${this.apiPath}/GetAvailableBadgeCount`);
    }

    public getAvailableAssessmentCount(): AxiosPromise<number> {
        return this.axios.get(`${this.apiPath}/GetAvailableAssessmentCount`);
    }

    public getParticipatedTopicCount(): AxiosPromise<number> {
        return this.axios.get(`${this.apiPath}/GetParticipatedTopicCount`);
    }

    public getUserBadges(): AxiosPromise<Array<Model.PlainObject<DashboardBadgeLookup>>> {
        return this.axios.get(`${this.apiPath}/GetUserBadges`);
    }

    public saveUserModuleDashboardCriteria(userDashboardSetting: Model.PartialPlainObject<UserDashboardSetting>): AxiosPromise<Model.PlainTrackedObject<UserDashboardSetting>> {
        return this.axios.put(`${this.apiPath}/SaveUserModuleDashboardCriteria`, userDashboardSetting);
    }

    public savePathwayForModule(pathwayName: string, moduleId: number): AxiosPromise<Model.PlainTrackedObject<LearningPathway>> {
        return this.axios.put(`${this.apiPath}/SavePathwayForModule/${encodeURIComponent(pathwayName)}/${moduleId}`);
    }

    public getPagedLearningModuleLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>> {
        return this.axios.get(`${this.apiPath}/PagedLearningModuleLookup?${Utils.getQueryString(request)}`);
    }

    public createUserReminder(userReminder: Model.PartialPlainObject<UserReminder>): AxiosPromise<boolean> {
        return this.axios.post(`${this.apiPath}/reminder-create`, userReminder);
    }

    public deleteUserReminder(userReminderId: number): AxiosPromise {
        return this.axios.delete(`${this.apiPath}/reminder-delete/${userReminderId}`);
    }
    public addModuleToPathway(pathwayId: number, moduleId: number): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/AddModuleToPathway/${pathwayId}/${moduleId}`);
    }

    public getUserCptdPoints(): AxiosPromise<number> {
        return this.axios.get(`${this.apiPath}/UserCptdPoints`);
    }

    public getUserReminderSettings(): AxiosPromise<Model.PlainTrackedObject<UserReminder>> {
        return this.axios.get(`${this.apiPath}/reminder-settings`);
    }

    // Client only properties / methods
}