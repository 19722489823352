import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import DeleteRaceCommand from "../../Models/Commands/DeleteRaceCommand"
import UpdateRaceCommand from "../../Models/Commands/UpdateRaceCommand"
import { PlainObject, PartialPlainObject } from '@singularsystems/neo-core/dist/Model';

export interface IRaceCommandApiClient {

    /**
     * Save race(s)
     */
    saveList(model: PartialPlainObject<UpdateRaceCommand>[]): AxiosPromise<PlainObject<UpdateRaceCommand>[]>;
    /**
     * Delete race
     */
    delete(command: Model.PartialPlainObject<DeleteRaceCommand>): AxiosPromise<number>;
}

@injectable()
export default class RaceCommandApiClient extends Data.ApiClientBase implements IRaceCommandApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/RaceCommand`);
    }

    public saveList(races: Array<Model.PlainTrackedObject<UpdateRaceCommand>>): AxiosPromise<Array<Model.PlainTrackedObject<UpdateRaceCommand>>> {
        return this.axios.post(`${this.apiPath}/saveList`, races);
    }

    public delete(command: Model.PartialPlainObject<DeleteRaceCommand>): AxiosPromise<number> {
        return this.axios.post(`${this.apiPath}/delete/`, command);
    }

}