import { AppServices } from "@singularsystems/neo-core";
import { Types } from '../Organisations/OrganisationTypes';

import OrganisationApiClient from './ApiClients/Maintenance/OrganisationApiClient'
import CircuitApiClient from "./ApiClients/Maintenance/CircuitApiClient";
import DistrictApiClient from "./ApiClients/Maintenance/DistrictApiClient";
import ProvinceApiClient from "./ApiClients/Maintenance/ProvinceApiClient";
import SchoolApiClient from "./ApiClients/Maintenance/SchoolApiClient";
import SchoolManagementTeamApiClient from "./ApiClients/Maintenance/SchoolManagementTeamApiClient";
import SchoolManagementTeamQueryApiClient from "./ApiClients/Queries/SchoolManagementTeamQueryApiClient";
import SchoolImportApiClient from "./ApiClients/Imports/SchoolsImportApiClient";
import SchoolQueryApiClient from "./ApiClients/Queries/SchoolQueryApiClient";
import DistrictQueryApiClient from './ApiClients/Queries/DistrictQueryApiClient';
import CircuitQueryApiClient from './ApiClients/Queries/CircuitQueryApiClient';
import OrganisationQueryApiClient from "./ApiClients/Queries/OrganisationQueryApiClient";
import ProvinceQueryApiClient from "./ApiClients/Queries/ProvinceQueryApiClient";
import OrganisationsDataCache from "./Services/OrganisationsDataCache";

export const OrganisationAppModule = new AppServices.Module("Organisation", container => {

    // Api clients
    container.bind(Types.Organisations.ApiClients.OrganisationApiClient).to(OrganisationApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.OrganisationQueryApiClient).to(OrganisationQueryApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.SchoolApiClient).to(SchoolApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.ProvinceApiClient).to(ProvinceApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.ProvinceQueryApiClient).to(ProvinceQueryApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.DistrictApiClient).to(DistrictApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.DistrictQueryApiClient).to(DistrictQueryApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.CircuitApiClient).to(CircuitApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.CircuitQueryApiClient).to(CircuitQueryApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.SchoolManagementTeamApiClient).to(SchoolManagementTeamApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.SchoolManagementTeamQueryApiClient).to(SchoolManagementTeamQueryApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.SchoolImportApiClient).to(SchoolImportApiClient).inSingletonScope();
    container.bind(Types.Organisations.ApiClients.SchoolQueryApiClient).to(SchoolQueryApiClient).inSingletonScope();

    /// Services
    container.bind(Types.Organisations.Services.OrganisationDataCache).to(OrganisationsDataCache).inSingletonScope();
})