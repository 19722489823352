import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import PathwayModuleConsumptionVM from './PathwayModuleConsumptionVM';
import { observer } from 'mobx-react';
import LearningPathwayView from '../LearningPathways/LearningPathwayView';
import ModuleObjectsConsumptionLookup from '../../Models/ContentCosumption/ModuleObjectsConsumptionLookup';
import { LearningObjectType } from '../../Models/LearningObjects/LearningObjectType.enum';
import { GetContentImage, truncateString } from '../../../App/HelperClasses/GlobalHelpers';
import GridContainer from "../../../Template/components/Grid/GridContainer";
import GridItem from "../../../Template/components/Grid/GridItem";
import Card from '../../../Template/components/Card/Card';
import CardHeader from '../../../Template/components/Card/CardHeader';
import CardBody from '../../../Template/components/Card/CardBody';
import CardIcon from '../../../Template/components/Card/CardIcon';
import CardFooter from '../../../Template/components/Card/CardFooter';
import ForumDrawer from '../../../Forum/Components/ForumComponents/ForumDrawer';
import Confetti from 'react-confetti';
import BadgeAwardModal from '../../Components/BadgeAwardModal';
import FabButton from '../../../Template/components/CustomButtons/FabButton';
import ImageCard from '../../../App/Components/ImageCard';
import { LearningContentMediaType } from '../../Models/LearningObjects/LearningContent/LearningContentMediaType.enum';
import RichTextEditor from '../../Components/RichTextEditor';
import { QuestionType } from '../../Models/Questions/QuestionType.enum';
import OptionLookup from '../../Models/Lookups/OptionLookup';
import ContentModal from '../../Components/ContentModal';
import ConversationComponent from '../../../Forum/Components/ForumComponents/ConversationComponent';
import { FloatingMenu, MainButton, ChildButton, Directions } from 'react-floating-button-menu'
import { Tooltip } from '@material-ui/core';
import GlobalConversation from '../../../Forum/Components/ForumComponents/Global/GlobalConversation';
import PLCModuleConversation from '../../../Forum/Components/ForumComponents/PLC/PLCModuleConversation';

class PathwayModuleConstumptionViewParams {
  public moduleId = { required: true, isQuery: true };
  public pathwayId = { required: true, isQuery: true }
}

@observer
export default class PathwayModuleConsumptionView extends Views.ViewBase<PathwayModuleConsumptionVM, PathwayModuleConstumptionViewParams> {
  constructor(props: unknown) {
    super("Content Consumption", PathwayModuleConsumptionVM, props);
  }

  // Don't forget to add this static field to your view.
  public static params = new PathwayModuleConstumptionViewParams();
  protected async viewParamsUpdated() {
    this.viewModel.moduleId = this.viewParams.moduleId.value as number;
    this.viewModel.pathwayId = this.viewParams.pathwayId.value as number;
    await this.viewModel.GetModule(this.viewModel.moduleId);
  }

  public GoToPathwayScreen = () => {
    this.navigation.navigateToView(LearningPathwayView, { pathwayId: this.viewParams.pathwayId.value as number });
  }

  public nextBadge = async () => {
    const response = await this.viewModel.showNextBadge();
    if (response === 0 && this.viewModel.allDone) {
      this.viewModel.showBadgeAward = false;
      this.viewModel.showConfetti = false;
      if (this.viewModel.quizMark === 100) {
        this.viewModel.showFinalQuizPassModal = true;
      } else {
        this.viewModel.showFinalQuizFailModal = true;
      }
    }
  }

  public backToPathwayScreen() {
    this.navigation.navigateToView(LearningPathwayView);
  }

  public async selectObject(lo: ModuleObjectsConsumptionLookup) {
    await this.viewModel.SaveLastLearningObject(lo);
    await this.viewModel.SelectObject(lo);
    window.scrollTo(0, 0);
  }

  public async nextActivity() {
    await this.viewModel.CompleteInfoObjectAndGoNext();
    window.scrollTo(0, 0);
  }

  public render() {
    return (
      <div >
        <Confetti hidden={!this.viewModel.showConfetti} />
        {/* Module overview */}
        <div hidden={!this.viewModel.hideInfoObjectInfo || !this.viewModel.hideQuizObjectInfo}>
          <Card>
            <CardHeader icon>
              <CardIcon color="success">
                <i className={`icon fa fa-chalkboard fa-2x`}></i>
              </CardIcon>
              <h4 className="Card-icon-header-text">{this.viewModel.module.moduleTitle}</h4>
              <GridContainer>
                <GridItem md={8}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Neo.Button text={this.viewModel.pathway.title} size="sm" icon="backward" isOutline variant="info" onClick={() => this.GoToPathwayScreen()} />
                </GridItem>
                <GridItem md={4}>
                  <Neo.Button onClick={() => this.viewModel.downloadPdf(this.viewModel.module.moduleId)} icon="download" text="Download Text Only" tooltip="Download a PDF version of this module." variant="primary" isOutline />
                  <Neo.Button onClick={() => this.viewModel.downloadZip(this.viewModel.module.moduleId)} icon="downloadZip" text="Download All Files" tooltip="Download a ZIP version of this module with all content inside." variant="primary" isOutline />
                  <Neo.Button disabled style={{ float: "right" }} variant="danger" hidden={!this.viewModel.module.isDeactivated ? true : false} text="This Module is Deactivated"
                      tooltip="This module is deactivated and there may be a newer version of this module available." isOutline />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <h4 className="module-tiles">Learning Objective</h4>
              <p className="module-description">{this.viewModel.module.learningObjective}</p>
              <Card>
                <CardHeader icon>
                  <CardIcon color="info">
                    <i className={`icon fa fa-tasks fa-2x`}></i>
                  </CardIcon>
                  <h4 className="Card-icon-header-text">Activities</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    {this.viewModel.module === undefined ||
                      this.viewModel.module.learningObjectList.map((lo: ModuleObjectsConsumptionLookup) => (
                        <GridItem key={lo.learningObjectId} md={3}>
                          <div>
                            <Card>
                              <CardHeader color={lo.learningObjectType === LearningObjectType.Information ? "success" : lo.learningObjectType === LearningObjectType.Quiz ? "warning" : "danger"} plain >
                                <h4 className="card-title-white-small">({lo.order})  {lo.title}</h4>
                              </CardHeader>
                              <CardBody>
                                <p className="module-description">{truncateString(lo.purpose, 150)}</p>
                              </CardBody>
                              <CardFooter>
                                <div style={{ float: 'left' }}>
                                  <p>{this.viewModel.GetStatusText(lo)}</p>
                                </div>
                                <div style={{ float: 'right' }}>
                                  <Neo.Button disabled={this.viewModel.AllowNextV2(lo)}
                                    variant={lo.learningObjectType === LearningObjectType.Information ? "success"
                                      : lo.learningObjectType === LearningObjectType.Quiz ? "warning" : "danger"}
                                    text={lo.learningObjectType === LearningObjectType.Information ? "View Activity"
                                      : lo.learningObjectType === LearningObjectType.Quiz ? "Start Quiz" : "Final Quiz"}
                                    onClick={() => this.selectObject(lo)} isOutline />
                                </div>
                              </CardFooter>
                            </Card>
                          </div>
                        </GridItem>
                      ))
                    }
                  </GridContainer>
                </CardBody>
              </Card>
            </CardBody>
            {!this.viewModel.pathway.hasInvites &&
              <FabButton color="primary" tooltip="Conversations" icon="comments" onClick={() => this.viewModel.openGlobalForumDrawer()} />}
            {!this.viewModel.hidePLCConvoButton &&
              <div className='floating-menu-conversations'>
                <FloatingMenu
                  slideSpeed={500}
                  spacing={10}
                  direction={Directions.Up}
                  isOpen={this.viewModel.conversationMenu}
                >
                  <MainButton iconResting={<Tooltip id="tooltip-top" title="Conversations" placement="top"><i className='fa fa-comments fa-2x' /></Tooltip>}
                    iconActive={<i className='fas fa-times fa-2x' style={{ color: 'white' }} />}
                    background="#3f51b5"
                    onClick={() => this.viewModel.OpenConversationMenu()}
                    size={56}
                  />
                  <ChildButton
                    icon={<Tooltip id="tooltip-left" title="Module" placement="left" ><i className='fas fa-globe-africa fa-2x' /></Tooltip>}
                    background="white"
                    size={40}
                    onClick={() => this.viewModel.openGlobalForumDrawer()}
                  />
                  <ChildButton
                    icon={<Tooltip id="tooltip-left" title="PLC" placement="left" ><i className='far fa-comment fa-2x' /></Tooltip>}
                    background="white"
                    size={40}
                    onClick={() => this.viewModel.openLocalForumDrawer()}
                  />
                </FloatingMenu>
              </div>}
          </Card>
        </div>

        {/* Info Object */}
        <div hidden={this.viewModel.hideInfoObjectInfo}>
          <GridContainer>
            <GridItem md={12}>
              <Card>
                <CardHeader icon >
                  <CardIcon color="success">
                    <i className={`icon fa fa-book fa-2x`}></i>
                  </CardIcon>
                  <h4 className="Card-icon-header-text">{this.viewModel.infoLearningObject.title}</h4>
                  <GridContainer>
                    <GridItem md={9} sm={8} xs={8}>
                      <Neo.Button text={this.viewModel.module.moduleTitle} icon="backward" onClick={() => this.viewModel.backToModule()} />
                    </GridItem>
                    <GridItem xs={4} md={3} sm={4}>
                      <Neo.Button text="Prev Activity" variant="warning" isOutline icon="backward" onClick={() => this.viewModel.GoToPrevActivity(false)} />
                      <Neo.Button text="Next Activity" variant="success" isOutline icon="forward" onClick={() => this.viewModel.CompleteInfoObjectAndGoNext()} />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <h4 className="module-tiles">Activity Purpose</h4>
                  <p className="module-description">{this.viewModel.infoLearningObject.purpose}</p>
                  <br />
                  <h4 className="module-tiles">Activity Text</h4>
                  {
                    !this.viewModel.infoLearningObject.objectText.length || <RichTextEditor viewModel={this.viewModel.quizRichTextVm} bind={this.viewModel.infoLearningObject.meta.objectText} labelText="" readOnly />
                  }
                  <Card hidden={this.viewModel.infoLearningObject.contentList.length === 0}>
                    <CardHeader icon>
                      <CardIcon color="info">
                        <i className="fa fa-images fa-2x" />
                      </CardIcon>
                      <h4 className="Card-icon-header-text">Media</h4>
                    </CardHeader>
                    <CardBody >
                      <GridContainer >
                        {
                          this.viewModel.infoLearningObject.contentList.map((content, index) => (
                            <GridItem xs={5} sm={5} md={2} key={index}>
                              <ImageCard image={GetContentImage(content.mediaType as number)} className="image-card-learning-object" imageClassName="media-image" >
                                <CardBody>
                                  <div className="card-hover-under">
                                  </div>
                                  <p className="module-description">{content.title}</p>
                                </CardBody>
                                <CardFooter>

                                  <Neo.Button tooltip={this.viewModel.GetButtonText(content)} text={this.viewModel.GetButtonText(content)} isOutline icon="eye" variant="success" onClick={() => this.viewModel.ViewContent(content, false)} />

                                  <Neo.Button tooltip="Download" hidden={content.mediaType === LearningContentMediaType.Conversation || content.mediaType === LearningContentMediaType.Link || content.mediaType === LearningContentMediaType.YouTube}
                                    variant="warning"
                                    icon="download"
                                    isOutline
                                    onClick={() => this.viewModel.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, true)} />

                                </CardFooter>
                              </ImageCard>
                            </GridItem >
                          ))
                        }
                      </GridContainer>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <FabButton color="primary" tooltip="Complete Activity" icon="check" onClick={() => this.viewModel.CompleteInfoObjectAndGoNext()} />
        </div >

        {/* Quiz Object */}
        < div hidden={this.viewModel.hideQuizObjectInfo} >
          <Confetti hidden={!this.viewModel.showBadgeAward} />
          <Card>
            <CardHeader icon>
              <CardIcon color={this.viewModel.quizLearningObject.learningObjectType === LearningObjectType.Quiz ? "warning" : "danger"}>
                <i className="fa fa-question fa-2x" />
              </CardIcon>
              <h4 className="Card-icon-header-text">{this.viewModel.quizLearningObject.title}</h4>
              <GridContainer>
                <GridItem md={9} sm={8} xs={8}>
                  <Neo.Button text="Back to module" icon="backward" onClick={() => this.viewModel.backToModule()} />
                </GridItem>
                <GridItem xs={4} md={3} sm={4}>
                  <Neo.Button text="Prev Activity" variant="warning" isOutline icon="backward" onClick={() => this.viewModel.GoToPrevActivity(true)} />
                  <Neo.Button hidden={this.viewModel.quizLearningObject.learningObjectType === LearningObjectType.FinalQuiz} text="Next Activity" variant="success" isOutline icon="forward" onClick={() => this.viewModel.completeQuizModal = true} />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <div>


                <h4 className="module-tiles">Activity Purpose</h4>
                <p className="module-description">{this.viewModel.quizLearningObject.purpose}</p>
                <br />
                <h4 className="module-tiles">Activity Text</h4>
                {
                  !this.viewModel.quizLearningObject.text.length || <RichTextEditor viewModel={this.viewModel.quizRichTextVm} bind={this.viewModel.quizLearningObject.meta.text} labelText="" readOnly />
                }
                {!this.viewModel.quizLearningObject.questions.length ||
                  <Card>
                    <CardHeader plain color="info">
                      <h4 className="card-title-white"> Question</h4>
                    </CardHeader>
                    <CardBody>
                      <div style={{ float: 'right' }}>
                        <GridContainer>
                          <div hidden={this.viewModel.currentIndex === 0}>
                            <GridItem md={2}>
                              <Neo.Button text="Prev Question" icon="backward" variant="warning" isOutline onClick={() => { this.viewModel.currentIndex -= 1 }} />
                            </GridItem>
                          </div>
                          <div hidden={this.viewModel.currentIndex + 1 === this.viewModel.questionListCount}>
                            <GridItem md={2}>
                              <Neo.Button text="Next Question" icon="forward" isOutline variant="success" onClick={() => { this.viewModel.currentIndex += 1 }} />
                            </GridItem>
                          </div>
                          <div hidden={this.viewModel.currentIndex + 1 !== this.viewModel.questionListCount}>
                            <GridItem md={2}>
                              <Neo.Button text="Complete Quiz" icon="save" isOutline variant="success" onClick={() => { this.viewModel.completeQuizModal = true }} />
                            </GridItem>
                          </div>
                        </GridContainer>
                      </div>
                      <h4 className="module-tiles">Question {this.viewModel.currentIndex + 1} of {this.viewModel.questionListCount}</h4>
                      <div hidden={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.MultipleSelect}>
                        {
                          this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.MultipleSelect || <RichTextEditor labelText="Question:"
                            bind={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].meta.questionText} readOnly key="questions" />
                        }
                        <h4 className="module-tiles">Multiple Select</h4>
                        <NeoGrid.Grid items={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList}>
                          {(option, optionMeta) => (
                            <NeoGrid.Row>
                              <NeoGrid.Column display={optionMeta.option} />
                              <NeoGrid.Column label="Select Options" bind={optionMeta.correctAnswerInd} />
                            </NeoGrid.Row>
                          )}
                        </NeoGrid.Grid>
                      </div>
                      <div hidden={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.MultipleChoice}>
                        {
                          this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.MultipleChoice || <RichTextEditor labelText="Question:"
                            bind={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].meta.questionText} readOnly key="questions" />
                        }
                        <h4 className="module-tiles">Multiple Choice</h4>
                        <NeoGrid.Grid items={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList}>
                          {(option, optionMeta) => (
                            <NeoGrid.Row>
                              <NeoGrid.Column display={optionMeta.option} />
                              <NeoGrid.Column label="Choose and answer" bind={optionMeta.correctAnswerInd} onChange={() => {
                                this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                  element.correctAnswerInd = false;
                                });
                                optionMeta.correctAnswerInd.value = !optionMeta.correctAnswerInd.value
                              }} />
                            </NeoGrid.Row>
                          )}
                        </NeoGrid.Grid>
                      </div>
                      <div hidden={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.YesNo}>
                        {
                          this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.YesNo || <RichTextEditor labelText="Question:"
                            bind={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].meta.questionText} readOnly key="questions" />
                        }
                        <h4 className="module-tiles">Yes or No Questions</h4>
                        <br />
                        <div className="row">
                          {
                            this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.map((option: OptionLookup) => (
                              <div className="col-md-1 " key={option.option}>

                                <Neo.Button isOutline hidden={option.option !== "Yes"} size="lg" text="Yes" variant={option.correctAnswerInd ? "success" : "warning"} onClick={() => {
                                  this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                    element.correctAnswerInd = false;
                                  });
                                  option.correctAnswerInd = !option.correctAnswerInd;
                                }} />

                                <Neo.Button hidden={option.option !== "No"} isOutline size="lg" text="No" variant={option.correctAnswerInd ? "success" : "warning"} onClick={() => {
                                  this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                    element.correctAnswerInd = false;
                                  });
                                  option.correctAnswerInd = !option.correctAnswerInd;
                                }} />

                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div hidden={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.TrueFalse}>
                        {
                          this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].questionType !== QuestionType.TrueFalse ||
                          <RichTextEditor labelText="Question:" bind={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].meta.questionText} readOnly key="questions" />
                        }
                        <h4 className="module-tiles">True or false Questions</h4>
                        <div className="row">
                          {
                            this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.map((option: OptionLookup) => (
                              <div className="col-md-1" key={option.option}>

                                <Neo.Button hidden={option.option !== "True"} isOutline size="lg" text="True" variant={option.correctAnswerInd ? "success" : "warning"} onClick={() => {
                                  this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                    element.correctAnswerInd = false;
                                  });
                                  option.correctAnswerInd = !option.correctAnswerInd;
                                }} />
                                <Neo.Button hidden={option.option !== "False"} isOutline size="lg" text="False" variant={option.correctAnswerInd ? "success" : "warning"} onClick={() => {
                                  this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].optionLookupList.forEach(element => {
                                    element.correctAnswerInd = false;
                                  });
                                  option.correctAnswerInd = !option.correctAnswerInd;
                                }} />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                }
                {!this.viewModel.showQuestionContent ||
                  <Card hidden={this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].contentList.toJSArray({ includeClean: true }).length === 0}>
                    <CardHeader icon>

                      <CardIcon color="info">
                        <i className="fa fa-images fa-2x" />
                      </CardIcon>
                      <h4 className="Card-icon-header-text">Question Content</h4>
                    </CardHeader>
                    <CardBody >
                      <GridContainer>
                        {
                          this.viewModel.quizLearningObject.questions[this.viewModel.currentIndex].contentList.map((content, index) => (
                            <GridItem xs={12} sm={12} md={2} lg={2} key={content.learningContentId}>
                              <ImageCard image={GetContentImage(content.mediaType as number)} className="image-card-learning-object" imageClassName="media-image">
                                <CardBody>
                                  <div className="card-hover-under">
                                  </div>
                                  <p className="module-description">{content.title}</p>
                                </CardBody>
                                <CardFooter>
                                  <Neo.Button icon="eye" variant="success" isOutline tooltip={this.viewModel.GetButtonText(content)} text={this.viewModel.GetButtonText(content)} onClick={() => this.viewModel.ViewContent(content, true)} />
                                  <Neo.Button hidden={content.mediaType === LearningContentMediaType.Conversation
                                    || content.mediaType === LearningContentMediaType.Link
                                    || content.mediaType === LearningContentMediaType.YouTube}
                                    isOutline variant="warning"
                                    icon="download"
                                    tooltip="Download"
                                    onClick={() => this.viewModel.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, true)} />
                                </CardFooter>
                              </ImageCard>
                            </GridItem >
                          ))
                        }
                      </GridContainer>
                    </CardBody>
                  </Card>
                }
                <Card hidden={!this.viewModel.showQuizQuestionContent}>
                  <CardHeader icon>
                    <CardIcon color="info">
                      <i className="fa fa-images fa-2x" />
                    </CardIcon>
                    <h4 className="Card-icon-header-text">Quiz Content</h4>
                  </CardHeader>
                  <CardBody >
                    <GridContainer>
                      {
                        this.viewModel.quizLearningObject.contentList.map((content, index) => (
                          <GridItem xs={12} sm={12} md={2} lg={2} key={content.learningContentId}>
                            <ImageCard image={GetContentImage(content.mediaType as number)} className="image-card-learning-object" imageClassName="media-image">
                              <CardBody>
                                <div className="card-hover-under">
                                </div>
                                <p className="module-description">{content.title}</p>
                              </CardBody>
                              <CardFooter>
                                <Neo.Button icon="eye" variant="success" isOutline text={this.viewModel.GetButtonText(content)} tooltip={this.viewModel.GetButtonText(content)} onClick={() => this.viewModel.ViewContent(content, false)} />
                                <Neo.Button hidden={content.mediaType === LearningContentMediaType.Conversation || content.mediaType === LearningContentMediaType.Link
                                  || content.mediaType === LearningContentMediaType.YouTube}
                                  isOutline
                                  tooltip="Download"
                                  variant="warning"
                                  icon="download"
                                  onClick={() => this.viewModel.GetFileUrlFile(content.mediaObject?.fileDescriptorId as string, content.mediaType as number, true)} />
                              </CardFooter>
                            </ImageCard>
                          </GridItem >
                        ))
                      }
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            </CardBody>
            <CardFooter>
              <Neo.Button text="Back to Module" variant="warning" icon="backward" isOutline className="mb-2" onClick={() => this.viewModel.backToModule()} />
            </CardFooter>
          </Card>

          <ContentModal title="Content" show={this.viewModel.showContent} blobUrl={this.viewModel.contentBlobUrl} onClose={(show) => { this.viewModel.showContent = false }} content={this.viewModel.content} taskRunner={this.viewModel.taskRunner} alternateUrl={this.viewModel.alternateUrl}/>

          <Neo.Modal title="Finish Quiz"
            show={this.viewModel.completeQuizModal}
            onClose={() => this.viewModel.completeQuizModal = false}
            acceptButton={{
              text: "Submit Quiz", variant: "info", icon: "save", isOutline: true,
              onClick: () => this.viewModel.CompleteQuiz()
            }}>
            <div>
              <p>Please confirm you are ready to complete this Quiz?</p>
              <span>Confirmation will evaluate your latest set of answers and provide marked feedback.</span>
            </div>
          </Neo.Modal>
          <Neo.Modal title="Well Done Quiz Passed!"
            show={this.viewModel.showPassModal}
            onClose={() => this.viewModel.showPassModal = false}
            acceptButton={{
              text: "Next Activity", variant: "info",
              onClick: () => this.viewModel.completeMidModuleQuiz()
            }}>
            <div>
              <h4>Congratulations You passed!</h4>
              <span>You scored {this.viewModel.quizMark}%</span>
            </div>
          </Neo.Modal>

          <Neo.Modal title="Well Done Final Quiz Passed!"
            show={this.viewModel.showFinalQuizPassModal}
            onClose={() => this.backToPathwayScreen()}
            acceptButton={{
              text: "Back to PathwayScreen", variant: "info", icon: "backward",
              onClick: () => this.backToPathwayScreen()
            }}>
            <div>
              <p>Congratulations you have passed the Final Quiz! CPTD points have been allocated to your profile accordingly.</p>
              <span>You scored {Math.round(this.viewModel.quizMark)}%</span><br />
            </div>
          </Neo.Modal>

          <Neo.Modal title="Final Quiz Failed." size="xl"
            show={this.viewModel.showFinalQuizFailModal}
            onClose={() => this.viewModel.showFinalQuizFailModal = false}
            acceptButton={{
              text: "Back to Module", variant: "info", icon: "save",
              onClick: () => this.viewModel.backToModule()
            }}>
            <div>
              <span>You scored {Math.round(this.viewModel.quizMark)}%, required pass mark: 100% </span>
              <span>Please review the following Questions and Activities:</span><br />
              <div>
                <NeoGrid.Grid items={this.viewModel.moduleScoreLookup.objectsToReview}>
                  {(object, objectMeta) => (
                    <NeoGrid.Row>
                      <NeoGrid.Column label="Wrong Question" display={objectMeta.title} />
                      <NeoGrid.Column label="Activity to review" display={objectMeta.objectText} />
                      <NeoGrid.ButtonColumn>
                        <Neo.Button text="Go to Activity" isOutline icon="eye" onClick={() => this.viewModel.SelectObject(this.viewModel.module.learningObjectList.find(c => c.learningObjectId === object.learningObjectId)!)} />
                      </NeoGrid.ButtonColumn>
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
              </div>
            </div>
          </Neo.Modal>

          <Neo.Modal title="Quiz Completed." size="xl"
            show={this.viewModel.showQuizFailModal}
            onClose={() => this.viewModel.showQuizFailModal = false}
            acceptButton={{
              text: "Next Activity", variant: "info", icon: "save",
              onClick: () => this.viewModel.completeMidModuleQuiz()
            }}>
            <div>
              <span>You scored {Math.round(this.viewModel.quizMark)}% </span><br />
              <span>Please review the following Questions and Activities:</span><br />
              <div>
                <NeoGrid.Grid items={this.viewModel.moduleScoreLookup.objectsToReview} hidden={this.viewModel.moduleScoreLookup.objectsToReview.length === 0}>
                  {(object, objectMeta) => (
                    <NeoGrid.Row>
                      <NeoGrid.Column label="Wrong Question" display={objectMeta.title} />
                      <NeoGrid.Column label="Activity to review" display={objectMeta.objectText} />
                      <NeoGrid.ButtonColumn>
                        <Neo.Button text="Go to Activity" disabled={object.learningObjectId === 0 || object.learningObjectId === null} isOutline icon="eye" onClick={() => this.viewModel.SelectObject(this.viewModel.module.learningObjectList.find(c => c.learningObjectId === object.learningObjectId)!)} />
                      </NeoGrid.ButtonColumn>
                    </NeoGrid.Row>
                  )}
                </NeoGrid.Grid>
              </div>
            </div>
          </Neo.Modal >

          <FabButton hidden={this.viewModel.quizLearningObject.learningObjectType === LearningObjectType.FinalQuiz} color="primary" tooltip="Complete Quiz" icon="check" onClick={() => this.viewModel.completeQuizModal = true} />
        </div >

        <ForumDrawer show={this.viewModel.showForumModal} onClose={() => this.viewModel.closeForumModal()} isMaximised={this.viewModel.isMaximised} maximiseWindow={() => this.viewModel.isMaximised = !this.viewModel.isMaximised}>
          {this.viewModel.privateConversation ||
            <GlobalConversation learningModuleId={this.viewModel.moduleId} forumId={this.viewModel.moduleForumId} hidden={this.viewModel.privateConversation} />
          }
          {!this.viewModel.privateConversation ||
            <PLCModuleConversation hidden={!this.viewModel.privateConversation} learningModuleId={this.viewModel.moduleId} learningPathwayId={this.viewModel.selectedConvoId} forumId={this.viewModel.moduleForumId} />
          }
        </ForumDrawer>

        <ForumDrawer show={this.viewModel.showActivityConversation} onClose={() => this.viewModel.closeActivityConversation()} isMaximised={this.viewModel.isMaximised} maximiseWindow={() => this.viewModel.isMaximised = !this.viewModel.isMaximised}>
          <ConversationComponent topicId={this.viewModel.activityForumId} title={"Learning Activity"} show={this.viewModel.meta.showTopicConversation} fromLearningActivity={true} />
        </ForumDrawer>
        {
          !this.viewModel.showBadgeAward ||
          <BadgeAwardModal badgeAwardMessage={this.viewModel.badgeAwarded.badgeAwardMessage} badgeAwardDescription={this.viewModel.badgeAwarded.badgeDescription}
            badgeTitle={this.viewModel.badgeAwarded.badgeTitle} imageSrc={this.viewModel.badgeAwarded.blobUrl} onClose={() => { this.nextBadge() }} showBadgeAward={this.viewModel.showBadgeAward} />
        }
      </div >
    );
  }
}