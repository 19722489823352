import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class SelfAssessmentQuestionTagLookup extends ModelBase {

    public learningModuleId: number = 0;

    public tag3Id: number = 0;

    public tag3Name: string = "";

    public tag2Id: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

}