import { Data, List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration.enum';
import { AppService, Types } from '../../../Identity/IdentityTypes';
import TDPUser from '../../../Identity/Models/Security/TDPUser';
import LearningModuleSearchCriteria from '../../Models/Criteria/LearningModuleSearchCriteria';
import { ModerationState } from '../../Models/LearningObjects/ModerationState.enum';
import LearningModuleLookup from '../../Models/Lookups/LearningModuleLookup';
import LearningModuleWithTagsLookup from '../../Models/Lookups/LearningModuleWithTagsLookup';
import LearningObjectModerationLookup from '../../Models/Lookups/LearningObjectModerationLookup';
import LearningObjectModerateableTagLookup from '../../Models/Lookups/LearningObjectModerateableTagLookup';
import { RichTextEditorVM } from '../../Components/RichTextEditor';
import LearningObjectWithTagModerationLookup from '../../Models/Lookups/LearningObjectTagModerationLookup';
import LearningObjectContentLookup from '../../Models/Lookups/LearningObjectContentLookup';
import FileLookup from '../../Models/Lookups/FileLookup';
import ModeratableQuizQuestionLookup from '../../Models/Lookups/ModeratableQuizQuestionLookup';
import { LearningObjectType } from '../../Models/LearningObjects/LearningObjectType.enum';
import { Moderation } from '../../../Authorisation/Models/Enums/ModerationRoles.enum'
import ModerationSummaryLookup, { ModerationModuleTagsSummary } from "../../../Learning/Models/Moderation/ModerationSummaryLookup";
import ModerationObjectLookup, { ModerationObjectTagsSummary, ObjectContentSummary, ObjectQuestionSummary } from '../../Models/Moderation/ModerationObjectLookup';

@NeoModel
export default class ModuleModerationVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private moderationApiClient = AppService.get(Types.Learning.ApiClients.ModerationQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    public tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
    private learningModuleApiClient = AppService.get(Types.Learning.ApiClients.LearningModuleQueryApiClient),
    private QuizLearningObjectApiClient = AppService.get(Types.Learning.ApiClients.QuizLearningObjectApiClient),
    private identityService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
    private userGroupQueryApiClient = AppService.get(Types.Authorisation.ApiClients.AuthorisationApiClient),
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService)) {
    super(taskRunner);
    this.user = identityService.user;
  }

  /// Properties
  public selectedLearningModuleId: number = 0;
  public selectedLearningModule = new LearningModuleWithTagsLookup();
  public criteria = new LearningModuleSearchCriteria();
  public selectedlearningObjectId: number = 0;
  public selectedLearningObject = new LearningObjectModerationLookup();
  public loModerateableTag3List = new List(LearningObjectModerateableTagLookup);
  public learningObjectList = new List(LearningObjectModerationLookup);
  public selectedLearningContentId: number = 0;
  public selectedLearningContent = new LearningObjectContentLookup();
  public selectedQuestion = new ModeratableQuizQuestionLookup();
  public selectedQuestionId: number = 0;
  public showQuestionContent = false;
  public moduleReviewSummary = '';
  public hideQuestionInfo = true;

  // Bindings
  public hideLOCard: boolean = true;
  public showModuleNotes: boolean = true;
  public showObjectNotes: boolean = true;
  public showQuestionNotes: boolean = true;
  public showQuestionContentNotes: boolean = true;
  public showAcceptModal = false;
  public hideModuleInfo = true;
  public hideMediaUpload = true;
  public showModuleReviewSummaryModal = false;
  public showCompleteModeration = false;
  public reviewIsMaximised = true;
  public moderationStarted: boolean = true;
  public hideCriteria: boolean = false;
  public hasCPTDModerationRole: boolean = false;
  public moderationStartedAndHasRole: boolean = false;

  public curLearningObjectTextHtml: string = "";
  public curLearningQuestionTextHtml: string = "";
  public superAdmin: boolean = false;


  public richTextEditorVmQuestionContentNotes = new RichTextEditorVM(this.taskRunner);
  public fileDescriptorList = new List(FileLookup);
  private user: TDPUser | null = null;

  /// Methods
  public async initialise() {
    this.criteria.moderationState = ModerationState.SubmittedForModeration;
    this.criteria.stateSelectedUnknown = true;
    this.criteria.stateSelectedUnmoderated = true;
    this.criteria.stateSelectedSubmittedForModeration = true;
    this.criteria.stateSelectedUnderModeration = true;
    this.criteria.stateSelectedReview = true;
    this.criteria.stateSelectedModerated = true;
    this.criteria.stateSelectedResubmittedForModeration = true;
    this.criteria.stateSelectedDeactivated = true;

    //Check if user is Super Admin
    const response = await this.userGroupQueryApiClient.userGroupQuery.userIsAdminGroup(this.user!.guid);
    this.superAdmin = response.data;

    this.criteria.isSuperAdmin = this.superAdmin;

    this.LearningModulePageManager.refreshData();
    this.hasCPTDModerationRole = this.authorisationService.hasRole(Moderation.ModerateCPTDPoints);

  }

  /// Learning Module page manager
  public LearningModulePageManager = new Data.PageManager(this.criteria, LearningModuleLookup, this.moderationApiClient.getPagedLearningModuleLookup, {
    pageSize: 15,
    pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
    sortBy: "learningModuleId",
    sortAscending: false,
    initialTaskRunner: this.taskRunner,
    allowSort: true,
    taskRunner: this.taskRunner,
  });

  /// Get and set the selected learning Module info with a list of linked learning Objects
  public SetModuleAndObjectInfo = async (id: number) => {
    this.resetModerationScreen();
    this.selectedlearningObjectId = 0;
    this.selectedLearningObject = new LearningObjectModerationLookup();
    this.hideLOCard = true;
    this.showModuleNotes = false;
    // Get and Set Selected Module Info
    const SetModuleInfo = async () => {
      this.selectedLearningModuleId = id;
      const learningModule = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningModuleById(id));
      this.selectedLearningModule.set(learningModule.data);
      if (this.selectedLearningModule.moderationState === ModerationState.UnderModeration || this.selectedLearningModule.moderationState === ModerationState.ResubmittedForModeration) {
        this.moderationStarted = false;
      }
      else {
        this.moderationStarted = true;
      }

      if (!this.moderationStarted && this.hasCPTDModerationRole) {
        this.moderationStartedAndHasRole = false;
      } else {
        this.moderationStartedAndHasRole = true;
      }

      this.SetApprovedModuleInfo(this.selectedLearningModule);
    }

    // Get and Set Selected Module Learning Object List
    const SetLearningObjectInfo = async () => {
      const learningObjectList = (await this.taskRunner.waitFor(this.moderationApiClient.getLearningObjectLookup(id)));
      this.learningObjectList.set(learningObjectList.data);
      this.hideModuleInfo = false;
    }

    SetModuleInfo().then(() => SetLearningObjectInfo());
    this.showModuleNotes = true;
    this.hideCriteria = true;
  }


  public resetModerationScreen = async () => {
    this.hideLOCard = true;
    this.showModuleNotes = false;
    this.selectedLearningModuleId = 0;
    this.hideModuleInfo = true;
    this.showModuleNotes = false;
    this.selectedLearningModule = new LearningModuleWithTagsLookup();
    this.selectedlearningObjectId = 0;
    this.selectedLearningObject = new LearningObjectModerationLookup();
    this.selectedLearningContentId = 0;
    this.selectedLearningContent = new LearningObjectContentLookup();
    this.selectedQuestion = new ModeratableQuizQuestionLookup();
    this.hideQuestionInfo = true;
  }

  /// On search button click event
  public SearchModules = async () => {
    this.criteria.stateSelectedUnknown = true;
    this.criteria.stateSelectedUnmoderated = true;
    this.criteria.stateSelectedSubmittedForModeration = true;
    this.criteria.stateSelectedUnderModeration = true;
    this.criteria.stateSelectedReview = true;
    this.criteria.stateSelectedModerated = true;
    this.criteria.stateSelectedResubmittedForModeration = true;
    this.criteria.isSuperAdmin = this.superAdmin;
    this.criteria.stateSelectedDeactivated = true;

    this.resetModerationScreen();
    this.LearningModulePageManager.refreshData();
  }

  public showCriteria() {
    this.hideCriteria = false;
    if (this.selectedLearningModuleId !== 0) {
      this.criteria.learningModuleId = null;
      this.LearningModulePageManager.pageSize = 10;
      this.LearningModulePageManager.refreshData();
    }
  }

  /// Asks the user if they would like to take responsibility for a selected module
  public StartModeration = async (id: number) => {
    this.selectedLearningModuleId = id;
    this.showAcceptModal = true;
  }

  /// Accept a module to allow user to start moderation
  public AcceptModuleForModeration = () => this.taskRunner.run(async () => {
    await this.moderationApiClient.acceptModuleForModeration(this.selectedLearningModuleId, this.user!.guid);
    this.showAcceptModal = false;
    this.criteria.moderationState = ModerationState.UnderModeration;
    this.LearningModulePageManager.refreshData();
    await this.SetSelectedLearningModule(this.selectedLearningModuleId);
    this.notifications.addSuccess("Module Accepted for Moderation", `You can now Start Moderating this Module`, NotificationDuration.Standard);
    this.hideModuleInfo = false;
  }).catch((ex: string) => {
    this.notifications.addDanger("Module Accepting Failed", `${ex}`, NotificationDuration.Standard);
  });

  public CompleteModeration = () => this.taskRunner.run(async () => {
    await this.moderationApiClient.completeModuleModeration(this.selectedLearningModuleId, this.user!.guid);
    this.showCompleteModeration = false;
    this.LearningModulePageManager.refreshData();
    this.notifications.addSuccess("Module Moderation Completed", `Module and content successfully moderated`, NotificationDuration.Standard);
    this.resetModerationScreen();
  }).catch((ex: string) => {
    this.notifications.addDanger("Module Complete Failed", `${ex}`, NotificationDuration.Standard);
  });

  /// Close Accept Modal
  public DeclineModuleForModeration = async () => {
    this.showAcceptModal = false;
    this.selectedLearningModuleId = 0;
  }

  /// Save the current Module Moderation state and notes
  public SaveModuleModerationState = async (isSave: boolean) => {
    var module = this.selectedLearningModule;
    module.title.fieldState = module.title.ApprovedByModerator ? ModerationState.Moderated : ModerationState.Review;
    module.description.fieldState = module.description.ApprovedByModerator ? ModerationState.Moderated : ModerationState.Review;
    module.cptdPoints.fieldState = module.cptdPoints.ApprovedByModerator ? ModerationState.Moderated : ModerationState.Review;
    module.learningModuleTags.forEach(moduleTag => {
      moduleTag.fieldState = moduleTag.approved ? ModerationState.Moderated : ModerationState.Review;
    });
    this.taskRunner.run(async () => {
      var response = await this.moderationApiClient.saveModuleModeration(module.toJSObject(), this.user!.guid)
      if (response.data) {
        if (isSave) {
          this.notifications.addSuccess("Progress Saved", `Module Moderation Progress Saved`, NotificationDuration.Standard);
        }
        this.LearningModulePageManager.refreshData();
      }
    })
  }

  /// Get and set the selected learning Module info 
  public SetSelectedLearningModule = async (id: number) => {
    this.SetModuleAndObjectInfo(id);
  }

  /// Sets the approved indicator on module.
  /// ToDo Tyrone: Do this on the model itself
  public SetApprovedModuleInfo = (learningModule: LearningModuleWithTagsLookup) => {
    this.selectedLearningModule.title.ApprovedByModerator = learningModule.title.fieldState === ModerationState.Moderated;
    this.selectedLearningModule.description.ApprovedByModerator = learningModule.description.fieldState === ModerationState.Moderated;
    this.selectedLearningModule.cptdPoints.ApprovedByModerator = learningModule.cptdPoints.fieldState === ModerationState.Moderated;
    learningModule.learningModuleTags.forEach(moduleTag => {
      moduleTag.approved = moduleTag.fieldState === ModerationState.Moderated;
    });
  }

  /// Saves the learning Object moderation state. 
  /// Also calls the SaveModuleModerationState() method
  public SaveLearningObjectProgress = async (isSave: boolean) => {
    // Update Module if there are any changes
    await this.SaveModuleModerationState(isSave);

    this.selectedLearningObject.learningObjectTitle!.fieldState = this.selectedLearningObject.learningObjectTitle!.ApprovedByModerator ? ModerationState.Moderated : ModerationState.Review;
    this.selectedLearningObject.learningObjectPurpose!.fieldState = this.selectedLearningObject.learningObjectPurpose!.ApprovedByModerator ? ModerationState.Moderated : ModerationState.Review;
    this.selectedLearningObject.learningObjectText!.fieldState = this.selectedLearningObject.learningObjectText!.ApprovedByModerator ? ModerationState.Moderated : ModerationState.Review;
    this.loModerateableTag3List.forEach(tag3 => {
      tag3.fieldState = tag3.ApprovedByModerator ? ModerationState.Moderated : ModerationState.Review;
    });

    var lo = new LearningObjectWithTagModerationLookup();
    lo.learningObject = this.selectedLearningObject;
    lo.tag3s = this.loModerateableTag3List;
    if (this.selectedLearningObject !== null && this.selectedLearningObject.learningObjectId !== 0) {
      var response = await this.moderationApiClient.saveLearningObjectModeration(lo.toJSObject(), this.user!.guid)
      if (isSave) {
        if (response.data) {
        } else {
          this.notifications.addDanger("Progress Save failed", `Learning Object Moderation Progress did not save`, NotificationDuration.Standard);
        }
      }
    }
  }

  /// Manualy set the approve indicator.
  /// TO DO Tyrone: figure out how to do this on the model 
  public SetApprovedObjectInfo = (learningObject: LearningObjectModerationLookup) => {
    this.selectedLearningObject.learningObjectTitle!.ApprovedByModerator = learningObject.learningObjectTitle!.fieldState === ModerationState.Moderated;
    this.selectedLearningObject.learningObjectText!.ApprovedByModerator = learningObject.learningObjectText!.fieldState === ModerationState.Moderated;
    this.selectedLearningObject.learningObjectPurpose!.ApprovedByModerator = learningObject.learningObjectPurpose!.fieldState === ModerationState.Moderated;
    this.loModerateableTag3List.forEach(tag3 => {
      tag3.ApprovedByModerator = tag3.fieldState === ModerationState.Moderated;
    });
  }

  /// Gets and sets the selected learning Object info 
  public setSelectedLearningObject = async (learningObject: LearningObjectModerationLookup) => {
    this.selectedLearningContentId = 0;
    this.selectedLearningContent = new LearningObjectContentLookup();
    this.selectedQuestion = new ModeratableQuizQuestionLookup();
    this.hideQuestionInfo = true;
    this.showObjectNotes = false;
    this.showQuestionContent = false;
    this.selectedlearningObjectId = learningObject.learningObjectId;
    this.selectedLearningObject = learningObject;
    this.selectedQuestionId = 0;
    this.selectedQuestion = new ModeratableQuizQuestionLookup();

    if (learningObject.learningObjectTypeId !== LearningObjectType.Information) {
      var quizContent = await this.learningModuleApiClient.getLearningContentByObject(learningObject.learningObjectId);
      if (quizContent.data.length > 0) {
        this.selectedLearningObject.learningContent.set(quizContent.data);
        this.SetSelectedContent(this.selectedLearningObject.learningContent[0])
      }
    }
    const loTag3s = await this.taskRunner.waitFor(this.moderationApiClient.getLearningObjectTag3s(learningObject.learningObjectId));
    this.loModerateableTag3List.set(loTag3s.data);
    this.hideLOCard = false;
    this.hideMediaUpload = true;
    this.SetApprovedObjectInfo(learningObject);
  }

  /// Saves the learning Content moderation state
  public SaveLearningContentModeration = async (isSave: boolean) => {
    await this.saveQuestionModeration(isSave);
    this.selectedLearningContent.learningContent.moderation.moderationState = this.selectedLearningContent.learningContent.moderation.approvedByModerator ? ModerationState.Moderated : ModerationState.Unmoderated;
    if (this.selectedLearningContent !== null && this.selectedLearningContent.learningContentId !== 0) {

      var response = await this.moderationApiClient.saveLearningContentModeration(this.selectedLearningContent.toJSObject(), this.user!.guid);
      if (isSave) {
        if (response.data) {
          const learningObjectList = (await this.taskRunner.waitFor(this.moderationApiClient.getLearningObjectLookup(this.selectedLearningModuleId)));
          this.learningObjectList.set(learningObjectList.data);
          this.learningObjectList.forEach(object => {
            object.isExpanded = object.learningObjectId === this.selectedlearningObjectId;
          });
        }
        else {
          this.notifications.addDanger("Progress Save failed", `Learning Content Moderation Progress did not save`, NotificationDuration.Standard);
        }
      }
    }
  }
  ///Get and set the selected learning content info and media 
  public SetSelectedContent = async (content: LearningObjectContentLookup) => {
    this.selectedQuestionId = 0;
    this.selectedQuestion = new ModeratableQuizQuestionLookup();
    this.hideMediaUpload = false;
    this.selectedLearningContentId = 0;
    this.selectedLearningContent = new LearningObjectContentLookup();
    this.fileDescriptorList = new List(FileLookup);
    this.selectedLearningContentId = content.learningContentId;
    this.selectedLearningContent.set(content.toJSObject());
    this.selectedLearningContent.meta.learningContent.value.mediaType = this.selectedLearningContent.mediaTypeFetched;
    var fileDescriptor = new FileLookup();
    fileDescriptor.fileDescriptorId = this.selectedLearningContent.learningContent.mediaObject?.fileDescriptorId as string;
    fileDescriptor.fileName = this.selectedLearningContent.learningContent.mediaObject?.fileName as string;
    fileDescriptor.mediaTypeId = this.selectedLearningContent.mediaTypeFetched;
    fileDescriptor.mimeType = this.selectedLearningContent.learningContent.mediaObject?.extension as string;
    this.fileDescriptorList.push(fileDescriptor);
    this.selectedLearningContent.learningContent.moderation.meta.approvedByModerator.value = this.selectedLearningContent.learningContent.moderation.moderationState === ModerationState.Moderated;
  }

  // Get and Set the Question Lookup model for moderation
  public setSelectedQuestion = async (id: number) => {
    this.selectedQuestionId = id;
    this.selectedQuestion = new ModeratableQuizQuestionLookup();
    this.hideQuestionInfo = true
    this.showQuestionContent = false;
    var response = await this.moderationApiClient.getQuizQuestion(id, this.selectedlearningObjectId);
    this.selectedQuestion.set(response.data);
    this.selectedQuestion.question.ApprovedByModerator = this.selectedQuestion.question.fieldState === ModerationState.Moderated;
    /// Get Linked Content if there is any
    if (response.data.linkedContentId !== null) {
      this.fileDescriptorList = new List(FileLookup);
      var content = await this.learningModuleApiClient.getLearningContentById(response.data.linkedContentId);
      this.selectedQuestion.questionContent.set(content.data);
      this.selectedQuestion.questionContent.learningContent.moderation!.approvedByModerator = content.data.learningContent.moderation.moderationState === ModerationState.Moderated;
      var fileDescriptor = new FileLookup();
      fileDescriptor.fileDescriptorId = this.selectedQuestion.questionContent.learningContent.mediaObject?.fileDescriptorId as string;
      fileDescriptor.fileName = this.selectedQuestion.questionContent.learningContent.mediaObject?.fileName as string;
      fileDescriptor.mediaTypeId = this.selectedQuestion.questionContent.mediaTypeFetched;
      fileDescriptor.mimeType = this.selectedQuestion.questionContent.learningContent.mediaObject?.extension as string;
      this.fileDescriptorList.push(fileDescriptor);
      this.showQuestionContent = true;
    } else {
      this.showQuestionContent = false;
    }
    this.hideQuestionInfo = false;
  }

  public saveQuestionModeration = async (isSave: boolean) => {
    await this.SaveLearningObjectProgress(isSave);
    this.selectedQuestion.question.fieldState = this.selectedQuestion.question.ApprovedByModerator ? ModerationState.Moderated : ModerationState.Review;
    this.selectedQuestion.questionId = this.selectedQuestionId;
    if (this.selectedQuestion !== null && this.selectedQuestion.questionId !== 0) {
      var response = await this.moderationApiClient.saveQuestionModeration(this.selectedQuestion.toJSObject(), this.user!.guid)
      if (isSave) {
        if (response.data) {
          this.notifications.addSuccess("Question Saved", `Question Moderation Progress Saved`, NotificationDuration.Standard);
        }
      }
      if (this.selectedQuestion.linkedContentId != null) {
        this.selectedQuestion.questionContent.learningContent.moderation.moderationState = this.selectedQuestion.questionContent.learningContent.moderation.approvedByModerator ? ModerationState.Moderated : ModerationState.Review;
        await this.moderationApiClient.saveLearningContentModeration(this.selectedQuestion.questionContent.toJSObject(), this.user!.guid)
      }
    }
  }

  public summaryLookup = new ModerationSummaryLookup();

  public CheckModerationState = async (moduleId: number) => {
    this.taskRunner.run(async () => {
      this.summaryLookup = new ModerationSummaryLookup();
      this.selectedLearningModuleId = moduleId;

      //get the module Moderation Info
      const learningModule = await this.taskRunner.waitFor(this.learningModuleApiClient.getLearningModuleById(moduleId));

      ///set the module summary info 
      this.summaryLookup.moduleId = learningModule.data.learningModuleId;
      this.summaryLookup.moduleTitle = learningModule.data.title.value;
      this.summaryLookup.moduleTitleApproved = learningModule.data.title.fieldState === ModerationState.Moderated ? true : false;
      this.summaryLookup.moduleCPTDPoints = learningModule.data.cptdPoints.value;
      this.summaryLookup.moduleCPTDPointsApproved = learningModule.data.cptdPoints.fieldState === ModerationState.Moderated ? true : false;
      this.summaryLookup.moduleLearningObjective = learningModule.data.description.value;
      this.summaryLookup.moduleLearningObjectiveApproved = learningModule.data.description.fieldState === ModerationState.Moderated ? true : false;
      this.summaryLookup.moduleNotes = learningModule.data.moderationNotes;
      /// Check that the tags are moderated 
      learningModule.data.learningModuleTags.forEach(tagLookUp => {
        var tagSummary = new ModerationModuleTagsSummary();
        tagSummary.tag1Id = tagLookUp.tag1Id;
        tagSummary.tag2Id = tagLookUp.tag2Id;
        tagSummary.tag1Name = tagLookUp.tag1Name;
        tagSummary.tag2Name = tagLookUp.tag2Name;
        tagSummary.approved = tagLookUp.fieldState === ModerationState.Moderated ? true : false;
        tagSummary.errorMessage = tagSummary.approved ? "" : `Category Tag: ${tagLookUp.tag1Name} with Sub-Category Tag: ${tagLookUp.tag2Name} is not approved.`
        this.summaryLookup.allTagsApproved = !tagSummary.approved ? false : true;
        this.summaryLookup.moduleTags.push(tagSummary);
      });
      this.summaryLookup.moduleIsComplete = this.summaryLookup.allTagsApproved && learningModule.data.title.fieldState === ModerationState.Moderated && learningModule.data.cptdPoints.fieldState === ModerationState.Moderated && learningModule.data.description.fieldState === ModerationState.Moderated ? true : false;

      ///Get the learning Objects for this module.
      await this.moderationApiClient.getLearningObjectLookup(moduleId).then(async (learningObjectList) => {

        for (let index = 0; index < learningObjectList.data.length; index++) {
          const learningObject = learningObjectList.data[index];

          /// Create a Moderation summary for an object.
          const object = new ModerationObjectLookup();
          object.objectId = learningObject.learningObjectId;
          object.objectTypeId = learningObject.learningObjectTypeId;
          object.title = learningObject.learningObjectTitle!.value;
          object.titleApproved = learningObject.learningObjectTitle!.fieldState === ModerationState.Moderated;
          object.objectNotes = learningObject.moderationNotes;
          object.purpose = learningObject.learningObjectPurpose!.value;
          object.purposeApproved = learningObject.learningObjectPurpose!.fieldState === ModerationState.Moderated;

          object.richText = learningObject.learningObjectText!.value;
          object.richTextApproved = learningObject.learningObjectText!.fieldState === ModerationState.Moderated;

          object.objectIsCompleted = object.richTextApproved && object.purposeApproved && object.titleApproved;

          if (object.objectTypeId === LearningObjectType.Information) {

            /// Check the tags moderation state for the current object.
            const loTag3s = await this.moderationApiClient.getLearningObjectTag3s(learningObject.learningObjectId);
            loTag3s.data.forEach(tag3 => {
              var objectTag = new ModerationObjectTagsSummary();
              objectTag.tag3Id = tag3.tag3Id;
              objectTag.tag3Name = tag3.tag3Name;
              objectTag.approved = tag3.fieldState === ModerationState.Moderated;
              objectTag.errorMessage = `${tag3.tag3Name} `

              if (!objectTag.approved) {
                object.allObjectTagsApproved = false;
                object.objectIsCompleted = false;
              }
              object.objectTags.push(objectTag);
            });

            /// Now check the content 
            const learningContentList = await this.learningModuleApiClient.getLearningContentByObject(learningObject.learningObjectId);
            learningContentList.data.forEach(content => {
              var ObjectContent = new ObjectContentSummary();
              ObjectContent.contentId = content.learningContentId;
              ObjectContent.contentName = content.learningContent.title;
              ObjectContent.approved = content.learningContent.moderation.moderationState === ModerationState.Moderated ? true : false;
              ObjectContent.contentNotes = content.learningContent.moderation.moderationNotes;
              if (!ObjectContent.approved) {
                object.allContentApproved = false;
                object.objectIsCompleted = false;
              }
              object.contentList.push(ObjectContent);
            });
          } else {
            const quizQuestionList = await this.QuizLearningObjectApiClient.GetQuizQuestions(learningObject.learningObjectId);

            const quizlearningContentList = await this.learningModuleApiClient.getLearningContentByObject(learningObject.learningObjectId);

            if (quizlearningContentList.data.length > 0) {
              object.quizContent.contentName = quizlearningContentList.data[0].learningContent.title;
              object.quizContent.contentId = quizlearningContentList.data[0].learningContent.learningContentId;
              object.quizContent.contentNotes = quizlearningContentList.data[0].learningContent.moderation.moderationNotes;
              object.approvedQuizContent = quizlearningContentList.data[0].learningContent.moderation.moderationState === ModerationState.Moderated ? true : false;;
            }

            for (let index = 0; index < quizQuestionList.data.length; index++) {
              const question = quizQuestionList.data[index];
              await this.moderationApiClient.getQuizQuestion(question.questionId, learningObject.learningObjectId).then(async (questionInfo) => {
                var questionSummary = new ObjectQuestionSummary();
                questionSummary.question = questionInfo.data.question.value;
                questionSummary.questionId = questionInfo.data.questionId;
                questionSummary.approved = questionInfo.data.question.fieldState === ModerationState.Moderated ? true : false;
                if (questionInfo.data.moderation !== null) {
                  if (questionInfo.data.moderation!.moderationNotes !== "") {
                    questionSummary.questionNotes = questionInfo.data.moderation!.moderationNotes;
                  } else {
                    questionSummary.questionNotes = "";
                  }
                }

                if (!questionSummary.approved) {
                  object.allQuestionsApproved = false;
                  object.objectIsCompleted = false;
                }

                if (questionInfo.data.linkedContentId !== 0 && questionInfo.data.linkedContentId !== null) {
                  const learningContent = await this.learningModuleApiClient.getLearningContentById(questionInfo.data.linkedContentId);
                  questionSummary.hasContent = true;
                  var questionContent = new ObjectContentSummary();
                  questionContent.contentId = learningContent.data.learningContentId;
                  questionContent.contentName = learningContent.data.learningContent.title;
                  questionContent.approved = learningContent.data.learningContent.moderation.moderationState === ModerationState.Moderated ? true : false;
                  questionContent.contentNotes = learningContent.data.learningContent.moderation.moderationNotes;
                  if (!questionContent.approved) {
                    questionSummary.questionContentApproved = false;
                    object.objectIsCompleted = false;
                  }
                  questionSummary.questionContent = questionContent;
                }

                object.questionList.push(questionSummary);
              });
            }
          }

          this.summaryLookup.objectList.push(object);
          if (object.objectIsCompleted === false) {
            this.summaryLookup.allObjectsApproved = false;
          }
        }
      }).finally(() => {
        this.summaryLookup.passedModeration = this.summaryLookup.allObjectsApproved && this.summaryLookup.allTagsApproved && this.summaryLookup.moduleIsComplete ? true : false;
        if (!this.summaryLookup.passedModeration) {
          this.showModuleReviewSummaryModal = true;
        } else {
          this.showCompleteModeration = true;
        }
      });
    });
  }

  /// Submit Module for review by orginal creator
  public SendModuleForReview = async () => {
    this.taskRunner.run(async () => {
      this.showModuleReviewSummaryModal = false;
      var moduleId = this.selectedLearningModuleId;
      var response = await this.moderationApiClient.sendModuleForReview(moduleId, this.user!.guid);
      if (response.data) {
        this.LearningModulePageManager.refreshData();
        this.moduleReviewSummary = '';
        this.showModuleReviewSummaryModal = false;
        await this.resetModerationScreen()
        this.notifications.addSuccess("Module Sent For review", `Module has been sent back for review.`, NotificationDuration.Standard);
      }
    })
  }

  public async searchModules() {
    this.LearningModulePageManager.refreshData();
    this.resetModerationScreen();
  }

  /// Gets and set the Download path from Blob storage.
  public DownloadFileGet(this: any, fileDescriptorId: string, mediaTypeId: number, link: string) {
    this.taskRunner.run(async () => {
      var response = await this.learningModuleApiClient.getFileDownloadURL(fileDescriptorId, mediaTypeId);
      if (response.data) {
        window.open(response.data.toString(), '_blank');
      }
    });
  }
  //Sets Module's ModerationState back to Moderate
  public async ReactivateModule(learningModuleId: number) {
    this.taskRunner.run(async () => {
        const response = await this.moderationApiClient.completeModuleModeration(learningModuleId,this.user!.guid);
        if (response.status === 200) {
            if (!response.data) {
                this.notifications.addDanger("Reactivate unsuccessful", "Module cannot be Reactivated", NotificationDuration.Standard);
            }
            else {
                this.notifications.addSuccess("Module Reactivated", null, NotificationDuration.Standard);
            }
        } else {
            this.notifications.addDanger("Reactivate unsuccessful", "Module cannot be Reactivated. Unknown response received.", NotificationDuration.Standard);
        }
    });
}
}