import { Data, List, NeoModel, Model } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService } from "../../../../App/Services/AppService";
import Types from '../../../../App/AppTypes';
import Tag1 from "../../../Models/Tags/Tag1";
import TagLookup from '../../../Models/Lookups/Tag1Lookup';
import Tag1SearchCriteria from "../../../Models/Criteria/Tag1SearchCriteria";

@NeoModel
export default class TagVM extends Views.ViewModelBase {
    //Circuit Paging Manager
    public criteria = new Tag1SearchCriteria();
    public showEditModalInd: boolean = false;
    public selectedTag1 = new Tag1();
    public selectedTag1Id: number = 0;
    public showConfirmDeleteModalInd: boolean = false;

    public tagList = new List(Tag1);

    private beforeCircuitFetched(criteria: Model.PlainObject<Data.PageRequest<Tag1SearchCriteria>>) {
    }

    private afterCircuitFetched(data: TagLookup[]) {
    }

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private tag1ApiClient = AppService.get(Types.Learning.ApiClients.Tag1ApiClient),
        private tag1QueryApiClient = AppService.get(Types.Learning.ApiClients.Tag1QueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
    }

    public tagLookupPageManager = new Data.PageManager(this.criteria, TagLookup, this.tag1QueryApiClient.getTag1ListPaged, {
        pageSize: 10,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sortBy: "tagName",
        sortAscending: true,
        fetchInitial: true,
        initialTaskRunner: this.taskRunner,
        beforeFetch: this.beforeCircuitFetched.bind(this),
        afterFetch: this.afterCircuitFetched.bind(this)
    });

    public async initialise() {
        this.LoadTagList();
        this.autoDispose(this.criteria.onAnyPropertyChanged(() => this.tagLookupPageManager.refreshData()));
    }

    public async LoadTagList() {
        const response = await this.taskRunner.waitFor(this.tag1QueryApiClient.getTag1List());
        if (response != null) {
            this.tagList.set(response.data);
        }
    }

    public async showEditModal(tag1Id: number) {
        this.selectedTag1 = new Tag1();
        if (tag1Id !== 0) {
            this.selectedTag1Id = tag1Id;
            const response = await this.taskRunner.waitFor(this.tag1QueryApiClient.getTag1(tag1Id));
            this.selectedTag1.set(response.data)
        }
        this.showEditModalInd = true;
    }

    public async closeEditModal() {
        this.selectedTag1 = new Tag1();
        this.showEditModalInd = false;
    }

    public async saveTags() {
        this.taskRunner.run(async () => {
            const response = await this.tag1ApiClient.saveList(this.tagList.toJSArray());
            this.tagList.update(response.data)
            this.notifications.addSuccess("Tags saved", "Tags saved successfully", 4);
        })
    }

    public async saveTag() {
        this.taskRunner.run(async () => {
            this.showEditModalInd = false
            const tag = this.selectedTag1
            await this.tag1ApiClient.save(tag.toJSObject());
            this.showEditModalInd = false
            this.selectedTag1Id = 0;
            this.selectedTag1 = new Tag1();
            this.tagLookupPageManager.refreshData()
            this.notifications.addSuccess("Tag saved", "Tag saved successfully", 4);
        })
    }

    public async showDeleteModal(tag1Id: number) {
        this.selectedTag1Id = tag1Id;
        this.showConfirmDeleteModalInd = true
    }

    public async deleteTag() {
        this.taskRunner.run(async () => {
            this.showConfirmDeleteModalInd = false;
            await this.tag1ApiClient.delete(this.selectedTag1Id);
            this.tagLookupPageManager.refreshData();
            this.notifications.addSuccess("Tag Deleted", "Tag Deleted", 4);
        })
    }
}