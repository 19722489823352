import { NeoModel } from "@singularsystems/neo-core";
import { Neo, Views } from "@singularsystems/neo-react";
import { AppService } from "../../../App/Services/AppService";
import { Types } from "../../../Identity/IdentityTypes";
import CloudStorage from "../../Models/LearningModules/CloudStorage";
import FileDescriptor from "../../Models/Files/FileDescriptor";
import React from "react";
import { observer } from "mobx-react";
import Card from "../../../Template/components/Card/Card";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardIcon from "../../../Template/components/Card/CardIcon";
import CardBody from "../../../Template/components/Card/CardBody";

interface CloudStorageImportModalProps {
  viewModel: CloudStorageImportModalVM;
  shouldShow: boolean;
  onClose: () => void;
  onReturnedUrl: (url: string) => void;
}

@NeoModel
export class CloudStorageImportModalVM extends Views.ViewModelBase {
  public cloudStorageModel: CloudStorage;
  public knownImageExtensions: string[] = [
    ".jpg",
    ".jpeg",
    ".png",
    ".JPG",
    ".gif",
    ".svg",
  ];

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public apiClient = AppService.get(
      Types.Learning.ApiClients.LearningModuleQueryApiClient
    )
  ) {
    super(taskRunner);
    this.cloudStorageModel = new CloudStorage();
    this.cloudStorageModel.allowedExtensions = this.knownImageExtensions
      .toString()
      .replace(new RegExp(",", "g"), " | ");
  }
}

@observer
export default class CloudStorageImportModal extends React.Component<CloudStorageImportModalProps> {

  private returnImageDownloadUrl(fileDescriptor: FileDescriptor) {
    const { viewModel } = this.props;
    viewModel.taskRunner.run(async () => {
      var response = await viewModel.apiClient.getFileDownloadURL(
        fileDescriptor.fileDescriptorId,
        6 // Image
      );
      if (response.data) {
        this.props.onReturnedUrl(`${response.data}`);
      }
    });
  }

  public render() {
    const { shouldShow, onClose, viewModel } = this.props;
    return (
      <Neo.Modal
        size="lg"
        title={`Image Upload`}
        show={shouldShow}
        onClose={() => {
          onClose();
        }}
        closeButton={{
          text: "Cancel",
          variant: "danger",
          icon: "times",
        }}
      >
        <>
          <div>
            <Card>
              <CardHeader icon>
                <CardIcon color="success">
                  <i className={`fas fa-image fa-2x`}></i>
                </CardIcon>
                <h4 className="Card-icon-header-text">File selection</h4>
              </CardHeader>
              <CardBody>
                <Neo.FileContext
                  uploadEndpoint={viewModel.apiClient.getUploadFileToBlobUrl(6)}
                  afterUpload={this.returnImageDownloadUrl.bind(this)}
                  allowedExtensions={viewModel.knownImageExtensions}
                >
                  <Neo.FormGroupFloating
                    display={viewModel.cloudStorageModel.meta.allowedExtensions}
                  />
                  <Neo.FileDropArea className="mb-10" hidden={false}>
                    <Neo.FileUploadButton
                      variant="primary"
                      disabled={viewModel.taskRunner.isBusy}
                    >
                      Select a file
                    </Neo.FileUploadButton>{" "}
                    or drop a file here.
                    <Neo.FileUploadProgress variant={"info"} />
                  </Neo.FileDropArea>
                </Neo.FileContext>
              </CardBody>
            </Card>
          </div>
        </>
      </Neo.Modal>
    );
  }
}
