import { ValidationDisplayMode } from "@singularsystems/neo-core/dist/Validation/Misc";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from 'react';
import SelfAssessmentVM from "./SelfAssessmentVM";
import "../../../App/Styles/Modules.scss";
import { QuestionType, SelfAssessmentQuestionComponent } from "./SelfAssessmentQuestionComponent";
import SelfAssessmentLookup from "../../Models/Lookups/SelfAssessmentLookup";
import SelfAssesmentQuestionLookup from "../../Models/Lookups/SelfAssessmentQuestionLookup";
import QuestionOrderComponent from "../../Components/DragAndDrop/QuestionOrderComponent";
import { Data } from "@singularsystems/neo-core";
import { ModerationState } from "../../Models/LearningObjects/ModerationState.enum";
import RichTextEditor from "../../Components/RichTextEditor";
import { Sentiment } from "../../Models/Questions/Sentiment.enum";
import Card from "../../../Template/components/Card/Card";
import CardHeader from "../../../Template/components/Card/CardHeader";
import CardIcon from "../../../Template/components/Card/CardIcon";
import CardBody from "../../../Template/components/Card/CardBody";
import ForumDrawer from "../../../Forum/Components/ForumComponents/ForumDrawer";
import FabButton from "../../../Template/components/CustomButtons/FabButton";
import { GetRandomCardColor } from "../../../App/HelperClasses/GlobalHelpers";
//import AssessmentQuestion from "../../Components/AssessmentQuestion";

class SelfAssessmentViewParams {
    public searchString = { required: false, isQuery: true };
}

export enum selfAssessmentTabs {
    selfAssessments = "Self Assessments",
    questionOrdering = "Question Ordering"
}

@observer
export default class SelfAssessmentView extends Views.ViewBase<SelfAssessmentVM, SelfAssessmentViewParams>{
    constructor(props: unknown) {
        super("SelfAssessments", SelfAssessmentVM, props);
    }

    public static params = new SelfAssessmentViewParams();

    protected viewParamsUpdated() {
        if (this.viewParams.searchString.value !== "") {
            if (this.viewModel.selfAssessmentCriteria !== null) {
                this.viewModel.selfAssessmentCriteria.keyword = (this.viewParams.searchString.value as string);
            }
        }
    }

    public render() {
        return (
            <div className="pv-5">
                <section>
                    <div>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-chalkboard fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Self Assessment</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="row mb-2">
                                    <div className="col-md-10">
                                        <Neo.Button variant="success" isOutline icon="import"
                                            onClick={() => this.viewModel.showEditAssessmentCard(0)} >Add New Self Assessment</Neo.Button>
                                    </div>
                                </div>

                                <div className="row mb-2" hidden={!this.viewModel.hideCriteria}>
                                    <div className="col-md-10">
                                        <Neo.Button variant="info" isOutline icon="import" onClick={() => { this.viewModel.showCriteria() }} >Show Search Criteria</Neo.Button>
                                    </div>
                                </div>
                                <div hidden={this.viewModel.hideCriteria} onKeyPress={(event) => {
                                    if (event.charCode === 13) {
                                        this.viewModel.searchSelfAssessments();
                                        event.preventDefault();
                                    }
                                }}>
                                    <Neo.GridLayout md={1} >
                                        <Neo.Input className="cerebri-regular font-12" placeholder="Search for a Self Assessment by Title" bind={this.viewModel.selfAssessmentCriteria.meta.keyword} />
                                        <div className="mt-3 ml-2">
                                            <Neo.Input label="My Questionnaires" type="radio" onClick={() => { this.viewModel.UpdateCriteriaCreatedBy() }} placeholder="Search for a Self Assessment"
                                                bind={this.viewModel.selfAssessmentCriteria.meta.FilterByCreated} />
                                        </div>
                                        <div className="mt-3 ml-3 mr-3" hidden={this.viewModel.showModerationStateFilter}>
                                            <Neo.GridLayout className="moderationStatesContainer">
                                                <Neo.Input label="Unknown" type="radio" bind={this.viewModel.selfAssessmentCriteria.meta.StateSelectedUnknown} />
                                                <Neo.Input label="Unmoderated" type="radio" bind={this.viewModel.selfAssessmentCriteria.meta.StateSelectedUnmoderated} />
                                                <Neo.Input label="Submitted For Moderation" type="radio" bind={this.viewModel.selfAssessmentCriteria.meta.StateSelectedSubmittedForModeration} />
                                                <Neo.Input label="Under Moderation" type="radio" bind={this.viewModel.selfAssessmentCriteria.meta.StateSelectedUnderModeration} />
                                                <Neo.Input label="Under Review" type="radio" bind={this.viewModel.selfAssessmentCriteria.meta.StateSelectedReview} />
                                                <Neo.Input label="Moderated" type="radio" bind={this.viewModel.selfAssessmentCriteria.meta.StateSelectedModerated} />
                                                <Neo.Input label="Resubmitted For Moderation" type="radio" bind={this.viewModel.selfAssessmentCriteria.meta.StateSelectedResubmittedForModeration} />
                                            </Neo.GridLayout>
                                        </div>
                                        <Neo.Button text={this.viewModel.showModerationStateFilter ? "Moderation States" : "Hide Moderation States"} className="mt-3" variant="info" isOutline
                                            onClick={() => this.viewModel.showModerationStateFilter = !this.viewModel.showModerationStateFilter} />
                                    </Neo.GridLayout>
                                    <label style={{ marginTop: "15px" }}>Category Tag</label>
                                    <Neo.GridLayout md={1} >
                                        <Neo.AutoCompleteDropDown
                                            bind={this.viewModel.selfAssessmentCriteria.meta.SelfAssessmentTag1Id}
                                            bindDisplay={this.viewModel.selfAssessmentCriteria.meta.SelfAssessmentTag1Name}
                                            displayMember="tagName"
                                            itemSource={this.viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                                            onItemSelected={(item) => { this.viewModel.filtertag2ListSearch(item?.tag1Id) }}
                                            loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                                            minCharacters={2}
                                        />
                                    </Neo.GridLayout>
                                    <Neo.GridLayout md={1} style={{ marginTop: "15px" }} >
                                        <Neo.FormGroup label="Sub-Category Tag (based on Category Tag)" bind={this.viewModel.selfAssessmentCriteria.meta.SelfAssessmentTag1Id} select={{
                                            items: this.viewModel.learningModuleTag2FilteredList,
                                            allowNulls: true
                                        }} />
                                    </Neo.GridLayout>
                                    <Neo.Button autoFocus className="mt-1" variant="info" isOutline text="Search Self Assessments" icon="fa fa-search"
                                        onClick={() => { this.viewModel.searchSelfAssessments() }}
                                    />
                                    <Neo.Button className="mt-1 ml-2" variant="warning" isOutline text="Clear Search" icon="fa fa-eraser" onClick={() => { this.viewModel.clearSelfAssessmentSearchFilters() }} />
                                </div>
                                <section>
                                    {/* Self Assessments */}
                                    <div className="OrgMaintenance">
                                        <Neo.TabContainer selectedTab={this.viewModel.meta.selectedTab}>
                                            <Neo.Tab header={"Self Assessments"} icon="fa-info" >
                                                <section>
                                                    <div className="row ">
                                                        <div className="col-md-12">
                                                            <div className="row mt-2">
                                                                <div className="col-md-12">
                                                                    <Neo.Pager
                                                                        pageManager={this.viewModel.selfAssessmentLookupPageManager}
                                                                        pageControlProps={{
                                                                            firstText: "",
                                                                            prevText: "Prev",
                                                                            nextText: "Next",
                                                                            lastText: "",
                                                                            alignment: "center",
                                                                            // show the page controls even if the amount of items is less than a single page size
                                                                            autoHide: false,
                                                                            pageSizeLabel: "Show"
                                                                        }}>
                                                                        <NeoGrid.Grid<SelfAssessmentLookup> >
                                                                            {(assessment: SelfAssessmentLookup, assessmentMeta) => (
                                                                                <NeoGrid.RowGroup expandProperty={assessmentMeta.isExpanded}>
                                                                                    <NeoGrid.Row className={this.viewModel.selectedSelfAssessmentId === assessment.selfAssessmentId ? "selected-item-css" : "bg-none"}>
                                                                                        <NeoGrid.Column label="Self Assessment Title" display={assessmentMeta.selfAssessmentTitle} />
                                                                                        <NeoGrid.Column label="Self Assessment Description" display={assessmentMeta.selfAssessmentDescription} />
                                                                                        <NeoGrid.Column label="Created By" display={assessmentMeta.createdByUser} />
                                                                                        <NeoGrid.Column label="Moderation State" display={assessmentMeta.moderationState} select={{
                                                                                            itemSource: Data.StaticDataSource.fromEnum(ModerationState),
                                                                                            renderAsText: true
                                                                                        }} />
                                                                                        <NeoGrid.ButtonColumn>
                                                                                            <Neo.Button isOutline variant="success" disabled={assessment.moderationState === ModerationState.SubmittedForModeration ||
                                                                                                assessment.moderationState === ModerationState.UnderModeration} onClick={() => this.viewModel.showEditAssessmentCard(assessment.selfAssessmentId)}>
                                                                                                Select
                                                                                            </Neo.Button>
                                                                                        </NeoGrid.ButtonColumn>
                                                                                        <NeoGrid.ButtonColumn>
                                                                                            <Neo.Button hidden={assessment.moderationState !== ModerationState.Moderated} text="Moderated" isOutline variant="info" disabled={true} />

                                                                                            <Neo.Button hidden={assessment.moderationState !== ModerationState.Unmoderated} text="Submit for Moderation"
                                                                                                isOutline variant="info" onClick={() => this.viewModel.SubmitForModeration(assessment.selfAssessmentId)} />

                                                                                            <Neo.Button hidden={assessment.moderationState !== ModerationState.SubmittedForModeration} text="Submitted for Moderation"
                                                                                                isOutline variant="info" disabled={true} />

                                                                                            <Neo.Button hidden={assessment.moderationState !== ModerationState.UnderModeration &&
                                                                                                assessment.moderationState !== ModerationState.ResubmittedForModeration} text="Under Moderation" isOutline variant="info" disabled={true} />

                                                                                            <Neo.Button hidden={assessment.moderationState !== ModerationState.Review} icon="eye" tooltip="Review Moderation Summary"
                                                                                                isOutline variant="info" onClick={() => this.viewModel.GetModerationSummary(assessment.selfAssessmentId)} />
                                                                                            <Neo.Button hidden={assessment.moderationState !== ModerationState.Review} text="Resubmit for Moderation"
                                                                                                tooltip="Submit Self Assessment for review after addressing the notes" isOutline variant="info"
                                                                                                onClick={() => this.viewModel.ResubmitSelfAssessmentForModeration(assessment.selfAssessmentId)} />
                                                                                        </NeoGrid.ButtonColumn>
                                                                                        <NeoGrid.ButtonColumn>
                                                                                            <Neo.Button icon="trash" key="delete" variant="danger" onClick={() => this.viewModel.showDeleteSelfAssessment(assessment, assessment.selfAssessmentId)} />
                                                                                        </NeoGrid.ButtonColumn>
                                                                                    </NeoGrid.Row>
                                                                                    <NeoGrid.ChildRow>
                                                                                        <NeoGrid.Grid items={assessment.selfAssessmentQuestions} initialSort={"questionOrder"}>
                                                                                            {(question: SelfAssesmentQuestionLookup, questions) => (
                                                                                                <NeoGrid.RowGroup>
                                                                                                    <NeoGrid.Row key={question.selfAssessmentQuestionId} className={this.viewModel.selectedSelfAssessmentQuestionId === question.selfAssessmentQuestionId ? "selected-item-css" : "bg-none"}>
                                                                                                        <NeoGrid.Column label="Question" display={questions.plainText} />
                                                                                                        <NeoGrid.Column label="Question Type" display={questions.questionType} />
                                                                                                        <NeoGrid.Column label="Question Order" width="150px" display={questions.questionOrder} />
                                                                                                        <NeoGrid.Column label="Linked Resource" width="150px" display={questions.linkedToResource} />
                                                                                                        <NeoGrid.ButtonColumn>
                                                                                                            <Neo.Button isOutline variant="success" disabled={this.viewModel.selectedSelfAssessmentId !== question.selfAssessmentId || assessment.moderationState === ModerationState.SubmittedForModeration}
                                                                                                                onClick={() => { this.viewModel.setSelectedSelfAssesmentQuestion(question) }}>
                                                                                                                Select
                                                                                                            </Neo.Button>
                                                                                                        </NeoGrid.ButtonColumn>
                                                                                                        <NeoGrid.ButtonColumn>
                                                                                                            <Neo.Button icon="trash" key="delete" variant="danger" onClick={() => this.viewModel.deleteSelfAssessmentQuestion(question)} />
                                                                                                        </NeoGrid.ButtonColumn>
                                                                                                    </NeoGrid.Row>
                                                                                                </NeoGrid.RowGroup>
                                                                                            )}
                                                                                        </NeoGrid.Grid>
                                                                                    </NeoGrid.ChildRow>
                                                                                </NeoGrid.RowGroup>
                                                                            )}
                                                                        </NeoGrid.Grid>
                                                                    </Neo.Pager>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </Neo.Tab>

                                            <Neo.Tab header={"Question Ordering"} icon="fa-ellipsis-h" >
                                                {this.viewModel.selfAssessment.selfAssessmentId > 0 &&
                                                    <div>
                                                        <Card>
                                                            <CardHeader icon>
                                                                <CardIcon color="success">
                                                                    <i className={`fa fa-book fa-2x`}></i>
                                                                </CardIcon>
                                                                <h4 className="Card-icon-header-text">Self Assessment Question Ordering</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <QuestionOrderComponent items={this.viewModel.questionsDragAndDropList} setDNDItems={(items) => { this.viewModel.setDNDItems(items) }} />
                                                                {
                                                                    !this.viewModel.showAssessmentQuestionDnDModerationNotes ||
                                                                    <Neo.Card className="moderation-notes">
                                                                        <RichTextEditor bind={this.viewModel.selfAssessment.meta.selfAssessmentQuestionDnDModerationNotes} viewModel={this.viewModel.richTextEditorDNDMN} labelText="Moderation Notes" />
                                                                    </Neo.Card>
                                                                }
                                                                <Neo.Button onClick={() => this.viewModel.saveQuestionOrderChanges()} isOutline className="mt-2 mr-2" icon="fa-save" variant="info">
                                                                    Save Order Changes
                                                                </Neo.Button>
                                                                <Neo.Button onClick={() => this.viewModel.showSelfAssessmentCard()} disabled={this.viewModel.selectedSelfAssessmentId === 0} className="mt-2 mr-2"
                                                                    icon="fa-plus" variant="success" isOutline hidden={this.viewModel.selfAssessment.selfAssessmentId === 0 && this.viewModel.selfAssessment.moderationState !== ModerationState.Review}>
                                                                    Add Question
                                                                </Neo.Button>
                                                                <Neo.Button hidden={this.viewModel.selfAssessment.moderationState !== ModerationState.Review}
                                                                    text={!this.viewModel.showAssessmentQuestionDnDModerationNotes ? "Question Notes" : "Hide Question Notes"}
                                                                    isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2"
                                                                    onClick={() => this.viewModel.showAssessmentQuestionDnDModerationNotes = !this.viewModel.showAssessmentQuestionDnDModerationNotes} />
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                }
                                            </Neo.Tab>

                                        </Neo.TabContainer>
                                    </div>
                                </section>
                            </CardBody>
                        </Card>
                    </div>
                </section>

                <section>
                    {/*Create self assessment*/}
                    <div>
                        <Card hidden={this.viewModel.showAssessmentCard}>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-plus fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">{"Self Assessment: "}</h4>
                            </CardHeader>
                            <CardBody>
                                <Neo.Form validationDisplayMode={ValidationDisplayMode.AfterBlur}>
                                    <Neo.GridLayout className="mb-10">
                                        <Neo.FormGroupFloating label="Self Assessment Title*" formNoValidate input={{ rows: 2 }} bind={this.viewModel.selfAssessment.meta.selfAssessmentTitle} /><br />
                                        <Neo.FormGroupFloating label="Self Assessment Description*" formNoValidate input={{ rows: 3 }} bind={this.viewModel.selfAssessment.meta.selfAssessmentDescription} />
                                    </Neo.GridLayout>
                                    <Neo.GridLayout className="mb-10">
                                        <Neo.FormGroupFloating label="Question Type*" bind={this.viewModel.selfAssessment.meta.questionTypeId}
                                            select={{
                                                itemSource: Data.StaticDataSource.fromEnum(QuestionType),
                                                onItemSelected: (item) => {
                                                    this.viewModel.SetSelectedQuestionType(item?.id as number)
                                                }
                                            }} /><br />
                                    </Neo.GridLayout>
                                    {
                                        (this.viewModel.selectedQuestionTypeId === (QuestionType.LikertScale as number)) &&
                                        <Neo.GridLayout>
                                            <Neo.FormGroupFloating label="Sentiment*" bind={this.viewModel.selfAssessment.meta.sentiment}
                                                select={{
                                                    itemSource: Data.StaticDataSource.fromEnum(Sentiment),
                                                }} /><br />
                                            <Neo.FormGroupFloating readOnly={true} label="Number Of Scales" formNoValidate bind={this.viewModel.meta.numberOfScales} />
                                        </Neo.GridLayout>
                                    }
                                </Neo.Form>
                                <Neo.Button hidden={!this.viewModel.hideEditTagsCard} disabled={(this.viewModel.selfAssessment.questionTypeId === QuestionType.LikertScale
                                    && this.viewModel.selfAssessment.sentiment === Sentiment.NotSet) || !this.viewModel.selfAssessment.isValid} variant="success" isOutline onClick={() => this.viewModel.showTagCards()}>
                                    Add Self Assessment Tags
                                </Neo.Button>
                            </CardBody>
                        </Card>
                    </div>

                    {/* Self Assessment Tags Create and Info */}
                    <div>
                        <Card hidden={this.viewModel.hideEditTagsCard}>
                            <CardHeader icon>
                                <CardIcon color="success">
                                    <i className={`fas fa-tags fa-2x`}></i>
                                </CardIcon>
                                <h4 className="Card-icon-header-text">Self Assessment Tags</h4>
                            </CardHeader>
                            <CardBody>
                                <Neo.Form validationDisplayMode={ValidationDisplayMode.Always}>
                                    <NeoGrid.Grid className="mb-10" items={this.viewModel.selfAssessment.selfAssessmentTags2} initialSort={"tag2Id"}
                                        showAddButton addButton={{ disabled: this.viewModel.selfAssessment.moderationState === ModerationState.Moderated }}>
                                        {(assessment, assessmentMeta) => (
                                            <NeoGrid.Row>
                                                <NeoGrid.Column autoFocus width={800} size={10} disabled={!assessment.isNew && this.viewModel.selfAssessment.selfAssessmentId !== 0} label="Category Tag">
                                                    <Neo.AutoCompleteDropDown
                                                        bind={assessmentMeta.tag1Id}
                                                        bindDisplay={assessmentMeta.tag1Name}
                                                        displayMember="tagName"
                                                        itemSource={this.viewModel.tag1QueryApiClient.getTag1DropDownFilter}
                                                        onItemSelected={(item, values) => { this.viewModel.filtertag2List(assessment, item?.tag1Id) }}
                                                        loadingMessage={(item) => `Looking for: ${item.inputValue}`}
                                                        disabled={!assessment.isNew && this.viewModel.selfAssessment.selfAssessmentId !== 0}
                                                        minCharacters={2}
                                                    />
                                                </NeoGrid.Column>
                                                <NeoGrid.Column width={800} size={10} disabled={!assessment.isNew && this.viewModel.selfAssessment.selfAssessmentId !== 0} hidden={assessment.tag1Id === 0}
                                                    label="Sub-Category Tag" bind={assessmentMeta.tag2Id} autoFocus={false}
                                                    select={{ items: assessment.filteredTag2List, onItemSelected: (item) => { this.viewModel.checkForDuplicateTag2s(item?.tag2Id as number) } }} />
                                                <NeoGrid.Column hidden={assessment.tag1Id !== 0}>
                                                    <span>Select a Tag 1</span>
                                                </NeoGrid.Column>
                                                <NeoGrid.ButtonColumn>
                                                    <Neo.Button icon="trash" variant="danger" onClick={() => this.viewModel.deleteSelfAssessmentTag2(assessment)} />
                                                </NeoGrid.ButtonColumn>
                                            </NeoGrid.Row>
                                        )}
                                    </NeoGrid.Grid>
                                    <div hidden={this.viewModel.selfAssessment.moderationState === ModerationState.Moderated}>
                                        <Neo.Button onClick={() => this.viewModel.saveSelfAssessment()}
                                            hidden={this.viewModel.selfAssessment.selfAssessmentId !== 0}
                                            disabled={this.viewModel.selfAssessmentValid()} isOutline className="mt-2 mr-2" icon="fa-save" variant="success">
                                            Save Self Assessment
                                        </Neo.Button>
                                        <Neo.Button onClick={() => this.viewModel.saveSelfAssessment()} disabled={!this.viewModel.selfAssessment.isDirty}
                                            className="mt-2 mr-2" icon="fa-plus"
                                            variant="success" isOutline
                                            hidden={this.viewModel.selfAssessment.selfAssessmentId === 0 && this.viewModel.selfAssessment.moderationState !== ModerationState.Moderated}>
                                            Update Self Assessment
                                        </Neo.Button>
                                        <Neo.Button onClick={() => this.viewModel.showSelfAssessmentCard()} disabled={this.viewModel.selectedSelfAssessmentId === 0}
                                            className="mt-2 mr-2" icon="fa-plus" variant="success" isOutline hidden={this.viewModel.selfAssessment.selfAssessmentId === 0}>
                                            Add Question
                                        </Neo.Button>
                                        {
                                            this.viewModel.showAssessmentModerationNotes ||
                                            <Neo.Card className="moderation-notes" hidden={this.viewModel.selfAssessment.moderationState !== ModerationState.Moderated}>
                                                <RichTextEditor bind={this.viewModel.selfAssessment.meta.selfAssessmentModerationNotes} viewModel={this.viewModel.richTextEditorVmSAMN}
                                                    labelText="Moderation Notes" readOnly={true} />
                                            </Neo.Card>
                                        }
                                        <Neo.Button hidden={this.viewModel.selfAssessment.moderationState !== ModerationState.Review}
                                            text={this.viewModel.showAssessmentModerationNotes ? "Moderation Notes" : "Hide Moderation Notes"}
                                            isOutline variant="primary" icon="sticky-note" className="mt-2 ml-2" onClick={() => this.viewModel.showAssessmentModerationNotes = !this.viewModel.showAssessmentModerationNotes} />
                                    </div>
                                </Neo.Form>
                            </CardBody>
                        </Card>
                    </div>
                </section >
                <section>
                    {/* {this.viewModel.hideAssessmentQuestion ||
                        <AssessmentQuestion hidden={this.viewModel.hideAssessmentQuestion}
                            assessmentId={this.viewModel.selectedSelfAssessmentId}
                            questionId={this.viewModel.selectedQuestionId}
                            saveQuestion={(question) => this.viewModel.saveSelfAssessmentQuestion(question)} />
                    } */}

                    {/* Self Assessment Question Component */}
                    <SelfAssessmentQuestionComponent selfAssessmentVM={this.viewModel} viewModel={this.viewModel.SelfAssessmentQuestionComponentVM}
                        hidden={!this.viewModel.hideQuizCard} selectedLearningObject={this.viewModel.selfAssessment}
                        learningContent={this.viewModel.newQuestionLearningContent}
                        selfAssessmentQuestion={this.viewModel.selfAssessmentQuestion} selfAssessmentOptions={this.viewModel.selfAssessmentOptions} />
                </section>

                <ForumDrawer
                    show={this.viewModel.showAssessmentReviewSummaryDrawer}
                    onClose={() => this.viewModel.showAssessmentReviewSummaryDrawer = false}
                    isMaximised={this.viewModel.showAssessmentReviewSummaryIsMax}
                    maximiseWindow={() => this.viewModel.showAssessmentReviewSummaryIsMax = !this.viewModel.showAssessmentReviewSummaryIsMax}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="warning">
                                <i className={`fa fa-info fa-2x`}></i>
                            </CardIcon>
                            <h4 className="Card-icon-header-text">Review Summary for: {this.viewModel.moderationSummary.title}</h4>
                        </CardHeader>
                        <CardBody>
                            <Card hidden={this.viewModel.moderationSummary.baseAssessmentApproved}>
                                <CardHeader icon>
                                    <CardIcon color="danger">
                                        <i className={`fa fa-clipboard fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text"> {this.viewModel.moderationSummary.title}</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-category-white">
                                        The Following properties are not approved on this assessment:
                                    </p>
                                    <ul>
                                        {this.viewModel.moderationSummary.titleApproved || <li style={{ fontSize: "15px" }}>Title</li>}
                                        {this.viewModel.moderationSummary.descriptionApproved || <li style={{ fontSize: "15px" }}>Description</li>}
                                    </ul>
                                </CardBody>
                            </Card>

                            <Card hidden={this.viewModel.moderationSummary.tagsApproved}>
                                <CardHeader icon>
                                    <CardIcon color="danger">
                                        <i className={`fa fa-tags fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">Review Assessment Tags</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-category-white">
                                        The Following tags are not approved on your assessment:
                                    </p>
                                    <ul>
                                        {
                                            this.viewModel.moderationSummary.assessmentTags.map((tag) => (
                                                <li key={tag.tag2Id} style={{ fontSize: "15px" }} hidden={tag.approved}>{tag.errorMessage} </li>
                                            ))
                                        }
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card hidden={this.viewModel.moderationSummary.assessmentQuestionsApproved}>
                                <CardHeader icon>
                                    <CardIcon color="danger">
                                        <i className={`fa fa-question fa-2x`}></i>
                                    </CardIcon>
                                    <h4 className="Card-icon-header-text">Review Assessment Questions</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-category-white">
                                        The following questions are not approved:
                                    </p>
                                    {this.viewModel.moderationSummary.assessmentQuestions.map((question, index) => (

                                        <Card hidden={question.assessmentQuestionApproved} key={index}>
                                            <CardHeader >
                                                <CardIcon color={GetRandomCardColor()}>
                                                    <i className={`fa fa-eye fa-2x`} />
                                                </CardIcon>
                                                <RichTextEditor bind={question.meta.question} readOnly labelText="Question" />
                                            </CardHeader>
                                            <CardBody>
                                                <p className="card-category-white">
                                                    The Following properties are not approved on this question:
                                                </p>
                                                <ul>
                                                    {question.questionApproved || <li style={{ fontSize: "15px" }}>Question Text</li>}
                                                    {question.tag3Approved || <li style={{ fontSize: "15px" }}>Tag 3</li>}
                                                    {question.optionsApproved || <li style={{ fontSize: "15px" }}>Question Options</li>}
                                                </ul>
                                            </CardBody>
                                        </Card>

                                    ))}

                                </CardBody>
                            </Card>
                        </CardBody>
                    </Card>
                </ForumDrawer>
                <FabButton icon="eye" tooltip="Click to view moderation summary" hidden={this.viewModel.selectedSelfAssessmentId === 0 && this.viewModel.moderateableSelfAssessment.moderationState !== ModerationState.Review} onClick={() => this.viewModel.GetModerationSummary(this.viewModel.selectedSelfAssessmentId)} />

            </div >
        )
    }
}