import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../CommonIdentityTypes';
import Types from '../../../App/AppTypes';
import IntroductionContentLookup from '../../Models/Lookups/IntroductionContentLookup';

export interface IIntroductionAdminApiClient {

    /** 
     * Gets the latest Introduction.
     * @returns IntroductionContentLookup.
     */
    get(): AxiosPromise<Model.PlainObject<IntroductionContentLookup>>;

    /** 
     * Updates the latest Introduction.
     * @param intro IntroductionContentLookup.
     * @returns IntroductionContentLookup as result.
     */
    update(intro: Model.PartialPlainObject<IntroductionContentLookup>): AxiosPromise<Model.PlainObject<IntroductionContentLookup>>;

    // Client only properties / methods
}

@injectable()
export default class IntroductionAdminApiClient extends Data.ApiClientBase implements IIntroductionAdminApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/IntroductionAdmin`);
    }

    public get(): AxiosPromise<Model.PlainObject<IntroductionContentLookup>> {
        return this.axios.get(`${this.apiPath}`);
    }

    public update(intro: Model.PartialPlainObject<IntroductionContentLookup>): AxiosPromise<Model.PlainObject<IntroductionContentLookup>> {
        return this.axios.post(`${this.apiPath}`, intro);
    }

    // Client only properties / methods
}