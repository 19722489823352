import { List, NeoModel } from "@singularsystems/neo-core";
import { AppService } from '../CommonIdentityTypes';
import { Types } from '../IdentityTypes';
import UserProfileLookup from "../Models/Users/Lookups/UserProfileLookup";
import TDPUser from "../Models/Security/TDPUser";
import { Views } from "@singularsystems/neo-react";
import { NotificationDuration } from "../../App/Models/Enums/NotificationDuration.enum";
import BadgeLookup from "../../Learning/Models/Badges/BadgeLookup";
import SystemSettingsModel from "../Models/SystemSettings/SystemSettingsModel";

@NeoModel
export class VerificationModalVM extends Views.ViewModelBase {

    public currentUser: UserProfileLookup;
    private user: TDPUser | null = null;

    public verificationModal = false;
    public verificationPasswordModal = false;
    public userGuid: string = "";
    public isEmail: boolean = true;
    public oneTimePassword: number | null = null;

    //Badge Stuff
    public showBadgeAward: boolean = false;
    public badgeAwarded = new BadgeLookup();
    public blobUrl: string = "";
    public thereAreSomeMoreBadges: boolean = false;
    public badgeAwardedList = new List(BadgeLookup);
    public numberOfBadgesEarned: number = 0;
    public listOfBadgeIdsToShow: number[] = [];
    public alldone: boolean = false;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private userQueryApiClient = AppService.get(Types.Identity.ApiClients.UserProfileQueryApiClient),
        private userApiClient = AppService.get(Types.Identity.ApiClients.UserProfileApiClient),
        private authenticationService = AppService.get(Types.Identity.Services.TDPAuthenticationService),
        private oneTimePasswordApiClient = AppService.get(Types.Identity.ApiClients.OneTimePasswordApiClient),
        private badgeApiClient = AppService.get(Types.Learning.ApiClients.BadgeQueryApiClient),
        private systemsSettingsQueryApiClient = AppService.get(Types.Identity.ApiClients.SystemsSettingsQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        super(taskRunner);
        this.user = this.authenticationService.user;
        this.currentUser = new UserProfileLookup();
        this.getSystemSettings();
    }

    public systemSettings: SystemSettingsModel | null = null;

    public radioListItems: any = [];
    private async getSystemSettings() {
        const response = await this.systemsSettingsQueryApiClient.getNotificationSettings();
        if (response.data) {
            if (response.data.allowEmailVerification && response.data.allowSmsVerification) {
                this.radioListItems = [{ id: true, text: "Email Address" }, { id: false, text: "Phone Number" }]
            } else if (response.data.allowEmailVerification && !response.data.allowSmsVerification) {
                this.radioListItems = [{ id: true, text: "Email Address" }]
            }
            else if (!response.data.allowEmailVerification && response.data.allowSmsVerification) {
                this.radioListItems = [{ id: false, text: "Phone Number" }]
            }
        }
    }

    public async show(userGuid: string) {
        this.verificationPasswordModal = false;
        this.userGuid = userGuid;
        await this.GetCurrentUser();
        this.verificationModal = true;
    }

    public async GetCurrentUser() {
        let response = await this.userQueryApiClient.getRegisteredUser(this.userGuid);
        let userData = response.data;
        this.currentUser.set(userData);
        return userData;
    }

    public async SendOTP() {
        await this.taskRunner.run(async () => {
            await this.oneTimePasswordApiClient.generateAndSendOTPAsync(this.isEmail, this.currentUser.userGuid)
            this.notifications.addSuccess("One Time password has been sent", null, NotificationDuration.Standard);
            this.verificationModal = false;
            this.verificationPasswordModal = true;
        });
    }

    public async showPasswordModal(userGuid: string) {
        this.verificationPasswordModal = true;
        this.userGuid = userGuid;
        await this.GetCurrentUser();
    }

    public async VerifyUser() {
        await this.taskRunner.run(async () => {
            let password = this.oneTimePassword;
            if (password != null && password > 0) {
                await this.userApiClient.verifyUserProfile(this.currentUser.userGuid, password);
                this.notifications.addSuccess("You have been successfully verified", null, NotificationDuration.Standard)
                this.verificationPasswordModal = false
            }
            await this.authenticationService.signinSilent();
        });

        if (this.oneTimePassword != null && this.oneTimePassword > 0) {
            const response = await this.badgeApiClient.getAwardUserBadges()

            this.badgeAwardedList.set(response.data);
            this.numberOfBadgesEarned = response.data.length;

            if (this.numberOfBadgesEarned === 0) {
                this.alldone = true;
            }
            this.badgeAwardedList.forEach(async badge => {
                this.listOfBadgeIdsToShow.push(badge.badgeId);
            });
            await this.showNextBadge();
        }

        return true;
    }

    public showNextBadge = async () => {
        //If we click next badge and there are no more badges to show, we say we are all done. 
        if (this.listOfBadgeIdsToShow.length === 0) {
            this.alldone = true;
            return this.numberOfBadgesEarned;
        }
        var currentBadge = new BadgeLookup();

        var nextBadgeId = this.listOfBadgeIdsToShow[0];

        if (nextBadgeId !== null || nextBadgeId > 0) {
            currentBadge = this.badgeAwardedList.find(c => c.badgeId === nextBadgeId)!
            this.badgeAwarded = currentBadge;
            this.listOfBadgeIdsToShow.remove(currentBadge.badgeId);
            const bloblUrlResp = await this.taskRunner.waitFor(this.badgeApiClient.getBadgeImageByDescriptorId(this.badgeAwarded.mediaObject?.fileDescriptorId as string));
            this.badgeAwarded.blobUrl = bloblUrlResp.data;
            this.numberOfBadgesEarned = this.numberOfBadgesEarned > 0 ? this.numberOfBadgesEarned - 1 : 0;
            this.showBadgeAward = true;
        } else {
            this.numberOfBadgesEarned = 0;
        }

        return this.numberOfBadgesEarned;
    }
}