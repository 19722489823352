import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../LearningTypes';
import Tag3 from '../../Models/Tags/Tag3';

export interface ITag3QueryApiClient {

    /**
     * This method will return a sepcific circuit based on the circuitId with children
     * @returns Circuit List with Children
     */
    getTag2List(): AxiosPromise<Model.PlainTrackedObject<Tag3>>;

    /**
     * This method will return a sepcific circuit based on the circuitId with children
     * @param tag3Id The page request
     * @returns Tag3 List with Children
     */
    getTag3(tag3Id: number): AxiosPromise<Model.PartialPlainObject<Tag3>>;


    /** 
     * Get Tag 3 by tag2 id
     * @param tag2Id tag2Id
     * @returns Tag3 list
     */
    getTag3ListByTag2Id(tag2Id: number): AxiosPromise<Array<Model.PlainTrackedObject<Tag3>>>;

    // Client only properties / methods
}

@injectable()
export default class Tag3QueryApiClient extends Data.ApiClientBase implements ITag3QueryApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/Tag3Query`);
    }

    public getTag2List(): AxiosPromise<Model.PlainTrackedObject<Tag3>> {
        return this.axios.get(`${this.apiPath}/Tags`);
    }

    public getTag3(tag3Id: number): AxiosPromise<Model.PartialPlainObject<Tag3>> {
        return this.axios.get(`${this.apiPath}/Tag3/${tag3Id}`);
    }

    public getTag3ListByTag2Id(tag2Id: number): AxiosPromise<Array<Model.PlainTrackedObject<Tag3>>> {
        return this.axios.get(`${this.apiPath}/GetTag3ListByTag2Id/${tag2Id}`);
    }

    // Client only properties / methods
}