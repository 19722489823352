import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes';
import LearningModuleLookup from '../../Models/Lookups/LearningModuleLookup';
import LearningModuleSearchCriteria from '../../Models/Criteria/LearningModuleSearchCriteria';
import LearningModuleTagLookup from '../../Models/Lookups/LearningModuleTagLookup';
import { Url } from 'url';
import LearningObjectTagLookup from '../../Models/Lookups/LearningObjectTagLookup';
import LearningObjectContentLookup from '../../Models/Lookups/LearningObjectContentLookup';
import LearningObjectDragableModel from '../../Models/Lookups/LearningObjectDragableModel';
import FileLookup from '../../Models/Lookups/FileLookup';
import InformationLearningObject from '../../Models/LearningObjects/InformationLearningObject';
import LearningObjectLookup from '../../Models/Lookups/LearningObjectLookup';
import LearningModuleWithTagsLookup from '../../Models/Lookups/LearningModuleWithTagsLookup';
import LearningObjectSearchCriteria from '../../Models/Criteria/LearningObjectSearchCriteria';
import QuizLearningObject from '../../Models/LearningObjects/QuizLearningObject';

export interface ILearningModuleQueryApiClient {

    /**
     * This method will return the paged learning modules
     * @param request the paged search criteria request
     * @returns A collection of learning modules
     */
    getPagedLearningModuleLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>>;

    /**
     * This method will return a sepcific Learning Module based on the LearningModuleId with children
     * @param learningModuleId The page request
     * @returns Get learningModule
     */
    getLearningModuleById(learningModuleId: number): AxiosPromise<Model.PlainTrackedObject<LearningModuleWithTagsLookup>>;

    /**
     * Save new learning module with its tags
     * @param learningModule The page request
     * @param tag2List The page request
     * @param userId The page request
     * @returns Create learningModule
     */
    createLearningModule(learningModuleWithTagList: Model.PartialPlainObject<LearningModuleWithTagsLookup>, userId: string): AxiosPromise<Model.PlainTrackedObject<LearningModuleWithTagsLookup>>;

    /**
     * Create information Learning Object With Content
     * @param learningObject learnig Object
     * @param userId User id
     * @returns Test
     */
    createLearningObjectWithContent(learningObject: Model.PartialPlainObject<LearningObjectLookup>, userId: string): AxiosPromise<Model.PlainObject<LearningObjectLookup>>;

    /**
     * saves quiz learning object
     * @param learningObject learnig Object
     * @param userId User id
     * @returns Test
     */
    createQuizLearningObjectWithContent(learningObject: Model.PartialPlainObject<LearningObjectLookup>, userId: string): AxiosPromise<Model.PlainTrackedObject<LearningObjectLookup>>;

    /**
     * Update learning module with its tags
     * @param learningObject The page request
     * @param userId The page request
     * @returns Update Learning Module
     */
    updateLearningObjectWithContent(learningObjectContent: Model.PartialPlainObject<LearningObjectLookup>, userId: string): AxiosPromise<Model.PlainTrackedObject<LearningObjectLookup>>;
    /**
     * Save new learning module with its tags
     * @param moduleWithTagList The page request
     * @param userId The page request
     * @returns Create learningModule
     */
    updateLearningModule(moduleWithTagList: Model.PartialPlainObject<LearningModuleWithTagsLookup>, userId: string): AxiosPromise<Model.PlainTrackedObject<LearningModuleWithTagsLookup>>;

    /** 
     * Update Information Learning Object
     * @param learningObject The page request
     * @returns updated LearningObject
     */
    updateInformationLearningObject(learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<Model.PlainTrackedObject<InformationLearningObject>>;

    /** 
     * Update Quiz Learning Object
     * @param learningObject The page request
     * @returns updated LearningObject
     */
    updateQuizLearningObject(learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<Model.PlainTrackedObject<QuizLearningObject>>;

    /**
     * Get all LO for specific LM
     * @param learningObjectId The page request
     * @returns LOs for specific LM
     */
    getLearningObjectTagList(learningObjectId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectTagLookup>>>;

    /**
     * Get all linked Tag1s and 2s
     * @param learningModuleId The page request
     * @returns List of linked Tag2s and Tag1s
     */
    getLearningModuleTagList(learningModuleId: number): AxiosPromise<Array<Model.PlainObject<LearningModuleTagLookup>>>;

    /**
     * Get all tags for LO
     * @param learningModuleId The page request
     * @returns List of linked Tag2s and Tag1s
     */
    getLearningObjectLookup(learningModuleId: number): AxiosPromise<Array<Model.PlainTrackedObject<LearningObjectLookup>>>;

    /**
     * Get all tag 3s based on already selected tag 2s
     * @param learningModuleId The page request
     * @returns List of linked Tag2s and Tag1s
     */
    getLearningObjectTagSelectList(learningModuleId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectTagLookup>>>;

    /**
     * Uploads file to azure blob storage
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @param learningContentMediaType Learning Content Media Type.
     * @returns A FileDescriptorId will be returned .
     */
    getUploadFileToBlobUrl(learningContentMediaType: number): string;
    
    getUploadBigVideoFileToBlobUrl(learningContentMediaType: number): string;
   
    getUploadBigSCORMFileToBlobUrl(learningContentMediaType: number): string ;

    /**
     * Uploads file to azure blob storage
     * @param fileDescriptorId File Descriptor Id.
     * @param learningContentMediaType Learning Content Media Type.
     * @returns A FileDescriptorId will be returned .
     */
    getFileDownloadURL(fileDescriptorId: string, learningContentMediaType: number): AxiosPromise<Model.PlainTrackedObject<Url>>;

    /**
     * Get all learning content linked to specific learning content.
     * @param learningObjectId The page request
     * @returns Returns a learning content list.
     */
    getLearningContentByObject(learningObjectId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectContentLookup>>>;

    /**
    * Get all learning content linked to specific question.
    * @param learningContentId: The page request
    * @returns Returns a learning content list.
    */
    getLearningContentById(learningContentId: number): AxiosPromise<Model.PlainObject<LearningObjectContentLookup>>

    /**
     * Get file with fileDescriptorId
     * @param fileDescriptorId The page request
     * @param mediaTypeId The page request
     * @returns Returns a file.
     */
    getFileByDescriptorId(fileDescriptorId: string, mediaTypeId: number): AxiosPromise<Array<Model.PlainObject<FileLookup>>>;

    /**
     * Delete file with file descriptor , we need the learning content media type to determine the context
     * @param fileDescriptor The page request
     * @param learningContentMediaType The page request
     * @returns Deletes a file
     */
    deleteFile(fileDescriptorId: string, learningContentMediaType: number): AxiosPromise;

    /**
     * Delete learning object learning module link (deletes a learning object from a specific learning module but does nt delete the actual learing object itself)
     * @param learningModuleId The page request
     * @param learningObjectId The page request
     * @returns b.
     */
    deleteLearningObjectLearningModuleLink(learningModuleId: number, learningObjectId: number): AxiosPromise<boolean>;

    /**
     * Get all tags for LO
     * @param learningModuleId The page request
     * @param learningObjectId The page request
     * @returns List of linked Tag2s and Tag1s
     */
    deleteLearningModuleTag2(learningModuleId: number, tag2Id: number): AxiosPromise<boolean>

    /** 
     * Delete learning content
     * @param learningContentId The page request
     * @returns b.
     */
    deleteLearningContent(learningContentId: number): AxiosPromise<boolean>;

    /**
     * Get all tags for LO
     * @param learningModuleId The page request
     * @returns List of linked Tag2s and Tag1s
     */
    deleteLearningModule(learningModuleId: number): AxiosPromise<boolean>

    /**
     * Creates a basic lo object
     * @param learningModuleId The page request
     * @returns List of linked Tag2s and Tag1s
     */
    createLearningObject(learningObject: Model.PartialPlainObject<LearningObjectLookup>, userId: string): AxiosPromise<Model.PlainTrackedObject<InformationLearningObject>>

    /**
    * Creates a basic lo object
    * @param learningModuleId The page request
    * @returns learing object
    */
    createQuizLearningObject(learningObject: Model.PartialPlainObject<LearningObjectLookup>, userId: string): AxiosPromise<Model.PlainTrackedObject<InformationLearningObject>>

    /**
    * Gets a list of learning Object to be used in the drag and drop
    * @param learningModuleId The page request
    * @returns List of linked Tag2s and Tag1s
    */
    getLearningModuleDNDObjects(learningModuleId: number): AxiosPromise<Array<LearningObjectDragableModel>>

    /**
     * This method will return a list of questions linked to a quiz learning object (Used for DRAG AND DROP)
     * @param learningObjectId The page request
     * @returns List of LearningObjectDragableModel
     */
    getLearningObjectDNDObjects(learningObjectId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectDragableModel>>>;

    /**
     * Updates the lO order numnber based on Drag and Drop
     * @param learningObject The page request
     * @returns List of linked Tag2s and Tag1s
     */
    updateLearningObjectOrder(learningObjectId: number, order: number): AxiosPromise<boolean>

    /**
     * Updates the question order numnber based on Drag and Drop
     * @param learningObject The page request
     * @returns true or false
     */
    updateQuestionOrder(questionId: number, order: number): AxiosPromise<boolean>

    /**
     * update the order of an questions
     * @param learningModuleId learningObjectId
     * @param userGuid UserGuid
     * @returns true
     */
    submitModuleForModeration(learningModuleId: number, userGuid: string): AxiosPromise<boolean>;

    /**
     * Resubmit Module for moderation
     * @param learningModuleId learningObjectId
     * @param userGuid UserGuid
     * @returns true
     */
    resubmitModuleForModeration(learningModuleId: number, userGuid: string): AxiosPromise<boolean>;

    /** 
     * Check to see if Object has Linked resource. We do a lazy load on expand but they want to see on top level if it has a linked resource.
     * @param learningObjectId learningObjectId
     * @returns boolean value
     */
    checkObjectHasLinkedResource(learningObjectId: number): AxiosPromise<boolean>;

    /** 
     * Delete learning content linked to specific question
     * @param questionId The page request
     * @returns b.
     */
    deleteLearningContentOnQuestion(questionId: number): AxiosPromise<boolean>;

    /** 
     * This method will return the paged learning modules
     * @param request the paged search criteria request
     * @returns A collection of learning modules
     */
    getPagedLearningObjectsLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningObjectSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningObjectLookup>>>;

    /** 
     * Creates new Module Object Link
     * @param learningModuleId learningModuleId
     * @param learningObject learningObject
     * @returns Boolean
     */
    addExistingLearningObjectToModule(learningModuleId: number, learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<boolean>;
}

@injectable()
export default class LearningModuleQueryApiClient extends Data.ApiClientBase implements ILearningModuleQueryApiClient {

    // Client only properties / methods
    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/LearningModuleQuery`);
    }

    public getPagedLearningModuleLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningModuleSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningModuleLookup>>> {
        return this.axios.get(`${this.apiPath}/LearningModulesPaged?${Utils.getQueryString(request)}`);
    }

    public getLearningModuleById(learningModuleId: number): AxiosPromise<Model.PlainTrackedObject<LearningModuleWithTagsLookup>> {
        return this.axios.get(`${this.apiPath}/LearningModule/${learningModuleId}`);
    }

    public getLearningModuleObjects(learningModuleId: number): AxiosPromise<Array<Model.PartialPlainObject<LearningObjectDragableModel>>> {
        return this.axios.get(`${this.apiPath}/LearningModuleObject/${learningModuleId}`);
    }

    public createLearningModule(learningModuleWithTagList: Model.PartialPlainObject<LearningModuleWithTagsLookup>): AxiosPromise<Model.PlainTrackedObject<LearningModuleWithTagsLookup>> {
        return this.axios.put(`${this.apiPath}/CreateLearningModule`, learningModuleWithTagList);
    }

    public createLearningObjectWithContent(learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<Model.PlainTrackedObject<LearningObjectLookup>> {
        return this.axios.post(`${this.apiPath}/CreateLearningObjectWithContent`, learningObject);
    }

    public createQuizLearningObjectWithContent(learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<Model.PlainTrackedObject<LearningObjectLookup>> {
        return this.axios.post(`${this.apiPath}/CreateQuizLearningObjectWithContent`, learningObject);
    }

    public createLearningObject(learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<Model.PlainTrackedObject<InformationLearningObject>> {
        return this.axios.post(`${this.apiPath}/CreateLearningObject`, learningObject);
    }

    public createQuizLearningObject(learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<Model.PlainTrackedObject<InformationLearningObject>> {
        return this.axios.post(`${this.apiPath}/CreateQuizLearningObject`, learningObject);
    }

    public updateLearningObjectWithContent(learningObjectContent: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<Model.PlainTrackedObject<LearningObjectLookup>> {
        return this.axios.post(`${this.apiPath}/UpdateLearningObjectWithContent`, learningObjectContent);
    }

    public updateLearningModule(moduleWithTagList: Model.PartialPlainObject<LearningModuleWithTagsLookup>): AxiosPromise<Model.PlainTrackedObject<LearningModuleWithTagsLookup>> {
        return this.axios.put(`${this.apiPath}/UpdateLearningModule`, moduleWithTagList);
    }

    public updateInformationLearningObject(learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<Model.PlainTrackedObject<InformationLearningObject>> {
        return this.axios.put(`${this.apiPath}/InformationLearningObejct`, learningObject);
    }

    public updateQuizLearningObject(learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<Model.PlainTrackedObject<QuizLearningObject>> {
        return this.axios.put(`${this.apiPath}/QuizLearningObject`, learningObject);
    }

    public getLearningObjectTagList(learningObjectId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectTagLookup>>> {
        return this.axios.get(`${this.apiPath}/GetLearningObjectTagList/${learningObjectId}`);
    }

    public getLearningModuleTagList(learningModuleId: number): AxiosPromise<Array<Model.PlainTrackedObject<LearningModuleTagLookup>>> {
        return this.axios.get(`${this.apiPath}/GetLearningModuleTags/${learningModuleId}`);
    }

    public getLearningObjectLookup(learningModuleId: number): AxiosPromise<Array<Model.PlainTrackedObject<LearningObjectLookup>>> {
        return this.axios.get(`${this.apiPath}/getLearningObjectLookup/${learningModuleId}`);
    }

    public getLearningObjectTagSelectList(learningModuleId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectTagLookup>>> {
        return this.axios.get(`${this.apiPath}/GetLearningObjectFilteredTagList/${learningModuleId}`);
    }

    public getUploadFileToBlobUrl(learningContentMediaType: number): string {
        return `${this.apiPath}/uploadFile/${encodeURIComponent(learningContentMediaType)}`;
    }

    public getUploadBigVideoFileToBlobUrl(): string {
        return `${this.apiPath}/uploadBigVideoFile`;
    }
    
    public getUploadBigSCORMFileToBlobUrl(): string {
        return `${this.apiPath}/uploadBigSCORMFile`;
    }
    public getFileDownloadURL(fileDescriptorId: string, learningContentMediaType: number): AxiosPromise<Model.PlainTrackedObject<Url>> {
        return this.axios.get(`${this.apiPath}/downloadFile/${encodeURIComponent(fileDescriptorId)}/${learningContentMediaType}`);
    }

    public getLearningContentByObject(learningObjectId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectContentLookup>>> {
        return this.axios.get(`${this.apiPath}/GetLearningObjectContentList/${learningObjectId}`);
    }

    public getLearningContentById(learningContentId: number): AxiosPromise<Model.PlainObject<LearningObjectContentLookup>> {
        return this.axios.get(`${this.apiPath}/GetLearningObjectContent/${learningContentId}`);
    }

    public getFileByDescriptorId(fileDescriptorId: string, mediaTypeId: number): AxiosPromise<Array<Model.PlainObject<FileLookup>>> {
        return this.axios.get(`${this.apiPath}/GetFile/${encodeURIComponent(fileDescriptorId)}?mediaTypeId=${mediaTypeId}`);
    }

    public deleteFile(fileDescriptorId: string, learningContentMediaType: number): AxiosPromise {
        return this.axios.get(`${this.apiPath}/DeleteFile/${fileDescriptorId}/${learningContentMediaType}`);
    }

    public deleteLearningContent(learningContentId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/DeleteLearningContent/${learningContentId}`);
    }

    public deleteLearningObjectLearningModuleLink(learningModuleId: number, learningObjectId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/DeleteLOLMLink/${learningModuleId}/${learningObjectId}`);
    }

    public deleteLOLMLink(learningModuleId: number, learningObjectId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/DeleteLOLMLink/${learningModuleId}/${learningObjectId}`);
    }

    public deleteLearningModuleTag2(learningModuleId: number, tag2Id: number): AxiosPromise<boolean> {

        return this.axios.get(`${this.apiPath}/DeleteLMT2/${learningModuleId}/${tag2Id}`);
    }

    public deleteLearningModule(learningModuleId: number): AxiosPromise<boolean> {

        return this.axios.get(`${this.apiPath}/DeleteModule/${learningModuleId}`);
    }

    public getLearningModuleDNDObjects(learningModuleId: number): AxiosPromise<Array<LearningObjectDragableModel>> {
        return this.axios.get(`${this.apiPath}/GetLearningModuleDNDObjects/${learningModuleId}`);
    }

    public getLearningObjectDNDObjects(learningObjectId: number): AxiosPromise<Array<Model.PlainObject<LearningObjectDragableModel>>> {
        return this.axios.get(`${this.apiPath}/GetLearningObjectDNDObjects/${learningObjectId}`);
    }

    public getQuestionDNDObjects(learningObjectId: number): AxiosPromise<Array<LearningObjectDragableModel>> {
        return this.axios.get(`${this.apiPath}/GetLearningObjectDNDObjects/${learningObjectId}`);
    }

    public updateLearningObjectOrder(learningObjectId: number, order: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/UpdateLearningObjectOrder/${learningObjectId}/${order}`);
    }

    public updateQuestionOrder(questionId: number, order: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/UpdateQuestionOrder/${questionId}/${order}`);
    }

    public submitModuleForModeration(learningModuleId: number, userGuid: string): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/SubmitModuleForModeration/${learningModuleId}/${encodeURIComponent(userGuid)}`);
    }

    public resubmitModuleForModeration(learningModuleId: number, userGuid: string): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/ResubmitModuleForModeration/${learningModuleId}/${encodeURIComponent(userGuid)}`);
    }

    public checkObjectHasLinkedResource(learningObjectId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/CheckObjectHasLinkedResource/${learningObjectId}`);
    }

    public checkHasLinkedResource(learningObjectId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/CheckObjectHasLinkedResource/${learningObjectId}`);
    }

    public deleteLearningContentOnQuestion(questionId: number): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/DeleteLearningContentOnQuestion/${questionId}`);
    }

    public getPagedLearningObjectsLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningObjectSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningObjectLookup>>> {
        return this.axios.get(`${this.apiPath}/LearningObjectsPaged?${Utils.getQueryString(request)}`);
    }

    public addExistingLearningObjectToModule(learningModuleId: number, learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/AddExistingLearningObjectToModule/${learningModuleId}`, learningObject);
    }

    public getPagedLearningObjectLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<LearningObjectSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<LearningObjectLookup>>> {
        return this.axios.get(`${this.apiPath}/LearningObjectsPaged?${Utils.getQueryString(request)}`);
    }

    public addExistingLOToModule(learningModuleId: number, learningObject: Model.PartialPlainObject<LearningObjectLookup>): AxiosPromise<boolean> {
        return this.axios.put(`${this.apiPath}/AddExistingLearningObjectToModule/${learningModuleId}`, learningObject);
    }
}