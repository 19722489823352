import { NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class BadUserProfilesCriteria extends ValueObject {

    public isUserProfileMissing: boolean = true;

    public isRegistrationMissing: boolean = true;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Bad User Profiles Criteria";
    }
}