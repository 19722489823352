import { Attributes, Misc, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import IRoleItem from './IRoleItem';

@NeoModel
export default class Role extends ModelBase implements IRoleItem {

    public roleId : number = 0;

    public roleName : string = "";

    // Client only properties / methods

    @Attributes.Serialisation(Misc.SerialiseType.Never)
    public selected : boolean | undefined = false;

    
    @Attributes.Serialisation(Misc.SerialiseType.Never)
    public assignedRoleId : number | null = null;

    public get itemName() {
        return this.roleName;
    }

    public get children() {
        return null;
    }

    public get level() { return 3; };

    public get fullChildrenCount() {
        return 0;
    }

    public get selectedChildrenCount() {
        return 0;
    }

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString() {
        if (this.isNew) {
            return "New role"
        } else {
            return "Role";
        }
    }
}