import QuestionLookupBase from './QuestionLookupBase';
import { Attributes, List, NeoModel, Validation } from '@singularsystems/neo-core';
import { Sentiment } from "../Questions/Sentiment.enum";
import SelfAssessmentOptions from "../Questions/Assessment/SelfAssessmentOptions";
import { ModerationState } from '../LearningObjects/ModerationState.enum';
import { ModeratableLongStringField, ModeratableTag3 } from '../Moderation/ModeratableFieldValues';


@NeoModel
export default class SelfAssessmentQuestionModerationLookup extends QuestionLookupBase {

    constructor() {
        super();
        this.selfAssessmentOptions = new ModeratableLongStringField();
        this.questionText = new ModeratableLongStringField();
        this.tag3 = new ModeratableTag3();
    }

    public selfAssessmentQuestionId: number = 0;

    public selfAssessmentId: number = 0;

    @Attributes.ChildObject(ModeratableLongStringField)
    public selfAssessmentOptions: ModeratableLongStringField;

    @Attributes.ChildObject(ModeratableTag3)
    public tag3: ModeratableTag3;

    public moderatableTag3Name: string = "";

    public sentiment: Sentiment = Sentiment.NotSet;

    public numberOfScales: number = 0;

    @Attributes.ChildObject(ModeratableLongStringField)
    public questionText: ModeratableLongStringField;

    public plainText: string = "";

    public selfAssessmentOptionList = new List(SelfAssessmentOptions);

    public moderationNotes: string = "";

    public fieldState: ModerationState = ModerationState.Unmoderated;

    public contentApprovedByModerator: boolean = false;

    public contentTitle: string = "";

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}