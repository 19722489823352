import { AppServices } from '@singularsystems/neo-core';

import OrganisationApiClient from './ApiClients/Maintenance/OrganisationApiClient'

import CircuitApiClient from './ApiClients/Maintenance/CircuitApiClient';
import DistrictApiClient from './ApiClients/Maintenance/DistrictApiClient';
import ProvinceApiClient from './ApiClients/Maintenance/ProvinceApiClient';
import SchoolApiClient from './ApiClients/Maintenance/SchoolApiClient';
import SchoolManagementTeamApiClient from './ApiClients/Maintenance/SchoolManagementTeamApiClient';
import SchoolImportApiClient from './ApiClients/Imports/SchoolsImportApiClient';
import SchoolQueryApiClient from './ApiClients/Queries/SchoolQueryApiClient';
import DistrictQueryApiClient from './ApiClients/Queries/DistrictQueryApiClient';
import CircuitQueryApiClient from './ApiClients/Queries/CircuitQueryApiClient';
import OrganisationQueryApiClient from './ApiClients/Queries/OrganisationQueryApiClient';
import ProvinceQueryApiClient from './ApiClients/Queries/ProvinceQueryApiClient';
import OrganisationsDataCache from "./Services/OrganisationsDataCache";
import SchoolManagementTeamQueryApiClient from './ApiClients/Queries/SchoolManagementTeamQueryApiClient';

export const OrganisationExportedTypes = {
    ApiClients: {
        OrganisationApiClient: new AppServices.ServiceIdentifier<OrganisationApiClient>("Organisation.ApiClient.OrganisationApiClient"),
        OrganisationQueryApiClient: new AppServices.ServiceIdentifier<OrganisationQueryApiClient>("Organisation.ApiClient.OrganisationQueryApiClient"),

        ProvinceApiClient: new AppServices.ServiceIdentifier<ProvinceApiClient>("Organisation.ApiClient.ProvinceApiClient"),
        ProvinceQueryApiClient: new AppServices.ServiceIdentifier<ProvinceQueryApiClient>("Organisation.ApiClient.ProvinceQueryApiClient"),

        DistrictApiClient: new AppServices.ServiceIdentifier<DistrictApiClient>("Organisation.ApiClient.DistrictApiClient"),
        DistrictQueryApiClient: new AppServices.ServiceIdentifier<DistrictQueryApiClient>("Organisation.ApiClient.DistrictQueryApiClient"),

        CircuitApiClient: new AppServices.ServiceIdentifier<CircuitApiClient>("Organisation.ApiClient.CircuitApiClient"),
        CircuitQueryApiClient: new AppServices.ServiceIdentifier<CircuitQueryApiClient>("Organisation.ApiClient.CircuitQueryApiClient"),

        SchoolApiClient: new AppServices.ServiceIdentifier<SchoolApiClient>("Organisation.ApiClient.SchoolApiClient"),
        SchoolQueryApiClient: new AppServices.ServiceIdentifier<SchoolQueryApiClient>("Organisation.ApiClient.SchoolQueryApiClient"),
        SchoolImportApiClient: new AppServices.ServiceIdentifier<SchoolImportApiClient>("Organisation.ApiClient.SchoolImportApiClient"),

        SchoolManagementTeamApiClient: new AppServices.ServiceIdentifier<SchoolManagementTeamApiClient>("Organisation.ApiClient.SchoolManagementTeamApiClient"),
        SchoolManagementTeamQueryApiClient: new AppServices.ServiceIdentifier<SchoolManagementTeamQueryApiClient>("Organisation.ApiClient.SchoolManagementTeamApiClient"),
   },
    Services: {
         OrganisationDataCache: new AppServices.ServiceIdentifier<OrganisationsDataCache>("Organisations.Services.OrganisationsDataCache"),
    },
};
