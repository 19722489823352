import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'
import Tag1 from '../../Models/Tags/Tag1';

export interface ITag1ApiClient extends Data.IUpdateableApiClient<Tag1, number> {

    // Client only properties / methods
}

@injectable()
export default class Tag1ApiClient extends Data.UpdateableApiClient<Tag1, number> implements ITag1ApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.LearningApi.ApiPath}/Tag1`);
    }

    // Client only properties / methods
}