import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService } from "../../../App/Services/AppService";
import ModuleSuggestionLookup from '../../../Dashboard/Models/ModuleSuggestionLookup';
import UserAssessmentReviewLookup from '../../../Dashboard/Models/UserAssessmentReviewLookup';
import { Types } from '../../../Identity/IdentityTypes';
import UserLearningModuleLookup from '../../Models/UserLearning/UserLearningModuleLookup';

@NeoModel
export default class ReviewSelfAssessmentVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private dashboardApiClient = AppService.get(Types.Dashboard.ApiClients.DashboardQueryApiClient),
    private contentConsumptionApiClient = AppService.get(Types.Learning.ApiClients.ContentConsumptionQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  public userAssessmentId: number | null = null;
  public confirmStartNewModule = false;
  public selectedModelId = 0;
  public userAssessment: UserAssessmentReviewLookup = new UserAssessmentReviewLookup();
  public moduleSuggestions = new List(ModuleSuggestionLookup);

  public showPreviewDrawer = false;
  public previewDrawerMaximised = true;

  public module = new UserLearningModuleLookup();

  public PreviewModule = async (moduleId: number) => {
    const response = await this.contentConsumptionApiClient.getUserModulebyIdForPreview(moduleId);
    if (response.data) {
      this.module.set(response.data);
      this.showPreviewDrawer = true;
    }
  }

  public GetAssessmentInfo = async (id: number) => {
    const assessmentResponse = await this.taskRunner.waitFor(this.dashboardApiClient.getUserAssessmentReviewInfo(id));
    this.userAssessment.set(assessmentResponse.data);

    const suggestionResponse = await this.taskRunner.waitFor(this.dashboardApiClient.getSuggestedModulesForUserAssessment(id));
    this.moduleSuggestions.set(suggestionResponse.data);
  }

  public startNewModule = async (id: number) => {
    await this.dashboardApiClient.startNewModule(id);
  }

}