import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import SchoolImportResult from '../../Models/Organisations/SchoolsImport/SchoolImportResult';
import { AppService } from '../../../App/Services/AppService';
import Types from '../../../App/AppTypes'

export interface ISchoolsImportApiClient {

    /** 
     * Uploads an excel file with participant data.
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @returns A file key to be passed into CheckImportFile().
     */
    getUploadOrganisationProvinceFileUrl(): string;

    /** 
     * Processes an import for a previously uploaded file.
     * @param key Key returned from upload educator file.
     * @returns Import Result file.
     */
    doImport(key: string): AxiosPromise<Model.PlainObject<SchoolImportResult>>;

    // Client only properties / methods
}

@injectable()
export default class SchoolsImportApiClient extends Data.ApiClientBase implements ISchoolsImportApiClient {

    constructor(config = AppService.get(Types.App.Config)) {
        super(`${config.OrganisationApi.ApiPath}/OrganisationsImport`);
    }

    public getUploadOrganisationProvinceFileUrl(): string {
        return `${this.apiPath}/uploadFile`;
    }

    public doImport(key: string): AxiosPromise<Model.PlainObject<SchoolImportResult>> {
        return this.axios.post(`${this.apiPath}/doImport/${encodeURIComponent(key)}`);
    }

    // Client only properties / methods
}