import { AppServices } from '@singularsystems/neo-core';
import Tag1ApiClient from './ApiClients/Maintenance/Tag1ApiClient'
import Tag2ApiClient from './ApiClients/Maintenance/Tag2ApiClient'
import Tag3ApiClient from './ApiClients/Maintenance/Tag3ApiClient'
import Tag1QueryApiClient from './ApiClients/Queries/Tag1QueryApiClient'
import Tag2QueryApiClient from './ApiClients/Queries/Tag2QueryApiClient'
import Tag3QueryApiClient from './ApiClients/Queries/Tag3QueryApiClient'
import LearningModuleQueryApiClient from './ApiClients/Queries/LearningModuleQueryApiClient'
import LearningPathwayQueryApiClient from './ApiClients/Queries/LearningPathwayQueryApiClient';
import QuizLearningObjectApiClient from './ApiClients/Maintenance/QuizLearningObjectApiClient'
import SelfAssessmentApiClient from './ApiClients/Maintenance/SelfAssessmentApiClient'
import ModerationQueryApiClient from './ApiClients/Queries/ModerationQueryApiClient'
import ContentConsumptionQueryApiClient from './ApiClients/Queries/ContentConsumptionQueryApiClient'
import DownloadContentApiClient from './ApiClients/Downloads/DownloadContentApiClient';
import BadgeQueryApiClient from './ApiClients/Queries/BadgesQueryServiceApiClient';
import LearningPathwayImportApiClient from './ApiClients/Import/LearningPathwayImportApiClient';

export const LearningExportedTypes = {
    ApiClients: {
        Tag1ApiClient: new AppServices.ServiceIdentifier<Tag1ApiClient>("Learning.ApiClient.Tag1ApiClient"),
        Tag2ApiClient: new AppServices.ServiceIdentifier<Tag2ApiClient>("Learning.ApiClient.Tag2ApiClient"),
        Tag2QueryApiClient: new AppServices.ServiceIdentifier<Tag2QueryApiClient>("Learning.ApiClient.Tag2QueryApiClient"),
        Tag3QueryApiClient: new AppServices.ServiceIdentifier<Tag3QueryApiClient>("Learning.ApiClient.Tag3QueryApiClient"),
        Tag3ApiClient: new AppServices.ServiceIdentifier<Tag3ApiClient>("Learning.ApiClient.Tag3ApiClient"),
        Tag1QueryApiClient: new AppServices.ServiceIdentifier<Tag1QueryApiClient>("Learning.ApiClient.Tag1QueryApiClient"),
        LearningModuleQueryApiClient: new AppServices.ServiceIdentifier<LearningModuleQueryApiClient>("Learning.ApiClient.LearningModuleQueryApiClient"),
        LearningPathwayQueryApiClient: new AppServices.ServiceIdentifier<LearningPathwayQueryApiClient>("Learning.ApiClient.LearningPathwayQueryApiClient"),
        QuizLearningObjectApiClient: new AppServices.ServiceIdentifier<QuizLearningObjectApiClient>("Learning.ApiClient.QuizLearningObjectApiClient"),
        SelfAssessmentApiClient: new AppServices.ServiceIdentifier<SelfAssessmentApiClient>("Learning.ApiClient.SelfAssessmentApiClient"),
        ModerationQueryApiClient: new AppServices.ServiceIdentifier<ModerationQueryApiClient>("Learning.ApiClient.ModerationQueryApiClient"),
        ContentConsumptionQueryApiClient: new AppServices.ServiceIdentifier<ContentConsumptionQueryApiClient>("Learning.ApiClient.ContentConsumptionQueryApiClient"),
        DownloadContentApiClient: new AppServices.ServiceIdentifier<DownloadContentApiClient>("Learning.ApiClient.DownloadContentApiClient"),
        BadgeQueryApiClient: new AppServices.ServiceIdentifier<BadgeQueryApiClient>("Learning.ApiClient.BadgeQueryApiClient"),

        //Add Api Clients here
        LearningPathwayImportApiClient: new AppServices.ServiceIdentifier<LearningPathwayImportApiClient>("Learning.ApiClient.LearningPathwayImportApiClient"),
    },
    Services: {
        // Add Services here
    },
};
