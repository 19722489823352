import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { ModeratableLongStringField } from '../Moderation/ModeratableFieldValues';
import { QuestionType } from '../Questions/QuestionType.enum';
import ModerationObject from '../Moderation/ModerationObject';
import OptionLookup from './OptionLookup';
import LearningObjectContentLookup from './LearningObjectContentLookup';

@NeoModel
export default class ModeratableQuizQuestionLookup extends ModelBase {

  public questionId: number = 0;

  public learningObjectId: number = 0;

  public question: ModeratableLongStringField = new ModeratableLongStringField();

  public textHtml: string = "";

  public options: string[] = [];

  public optionList = new List(OptionLookup);

  @Attributes.Integer()
  public correctAnswer: number = -1;

  public correctAnswers: string[] = [];

  public questionTypeId: QuestionType | null = null;

  @Attributes.ChildObject(ModerationObject)
  public moderation: ModerationObject = new ModerationObject();

  @Attributes.Nullable()
  public linkedContentId: number | null = null;

  public questionContent: LearningObjectContentLookup = new LearningObjectContentLookup();
  public quizContent: LearningObjectContentLookup = new LearningObjectContentLookup();
  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New moderatable quiz question lookup";
    } else {
      return "Moderatable Quiz Question Lookup";
    }
  }
}