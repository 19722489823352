import { List, ModelBase, NeoModel, Utils, Validation } from '@singularsystems/neo-core';
import { QuestionType } from '../Questions/QuestionType.enum';
import Tag2 from '../Tags/Tag2';
import SelfAssessmentQuestionsLookup from './SelfAssessmentQuestionsLookup';

@NeoModel
export default class SelfAssessmentConsumptionLookup extends ModelBase {

  public selfAssessmentId: number = 0;

  public title: string = "";

  public description: string = "";

  public questionType: QuestionType | null = null;

  public createdby: string = Utils.emptyGuid();

  public tag2: Tag2 = new Tag2();

  public selfAssessmentQuestions = new List(SelfAssessmentQuestionsLookup);

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.title) {
      return "New self assessment consumption lookup";
    } else {
      return this.title;
    }
  }
}